import React from "react";
import { Dropdown, DropdownOption } from "@backslashbuild/pp-ui";
import { useGet } from "./api";

function StatutoryPayGroupTypePicker({ value, onChange }) {
  const optionsQuery = useGet("configuration/statutory-pay-group-types");
  const options = optionsQuery.data ?? [];

  return (
    <Dropdown value={value} onChange={(e) => onChange(e.target.value)}>
      <DropdownOption value="">Choose:</DropdownOption>
      {options.map((x) => (
        <DropdownOption value={x.id}>{x.name}</DropdownOption>
      ))}
    </Dropdown>
  );
}

export default StatutoryPayGroupTypePicker;
