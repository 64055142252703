import React, { useState } from "react";
import { Panel, PageLoading, PanelActions, PanelHeading, Button } from "@backslashbuild/pp-ui";
import FormField from "./FormField";
import { usePost, useGetNewTopicFields } from "./api";
import { isTextNullOrEmpty } from "./utils";
import { useHistory } from "react-router-dom";

export default function NewTopicForm({ onClose }) {
  const [payload, storePayload] = useState({});
  const { data, loading, error } = useGetNewTopicFields();
  const createTopic = usePost("topics/create");
  const history = useHistory();

  if (loading) return <PageLoading />;

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    return data.value.map((field) => (
      <FormField key={field.key} field={field} onSave={getOnSaveHandler(field.key)} />
    ));
  }

  function getOnSaveHandler(key) {
    return (value) => {
      storePayload({ ...payload, [key]: value });
    };
  }

  return (
    <Panel>
      <PanelHeading>Create new topic</PanelHeading>
      {handleRender()}
      <PanelActions>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            createTopic({
              ...payload,
            }).then((res) => {
              if (res?.isSuccess) {
                history.goBack();
              }
            });
          }}
        >
          Save
        </Button>
      </PanelActions>
    </Panel>
  );
}
