import React from "react";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { subscribe, publish, unsubscribe } from "./pubSub";
import { useQuery, queryCache } from "react-query";
import getApiUrl from "../../getApiUrl";

const apiHost = getApiUrl();
const service = "contractors";

export function useGet(cacheKey, path, serviceOverride) {
  if (!path) {
    path = cacheKey;
  }
  if (!serviceOverride) {
    serviceOverride = service;
  }

  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };

  const { data, error, isLoading: loading, updatedAt: queryId } = useQuery(
    cacheKey,
    async (key, id) => {
      return fetch(`${apiHost}/${serviceOverride}/${path}`, {
        headers: {
          ...authHeaders,
        },
      }).then((r) => r.json());
    }
  );

  const runQuery = () => queryCache.invalidateQueries(path);

  React.useEffect(() => {
    runQuery();
  }, [path]);

  return { data, loading, error, queryId, refetch: runQuery };
}

export function usePost(path) {
  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };

  return React.useCallback(
    (body) => {
      return fetch(`${apiHost}/${service}/${path}`, {
        method: "POST",
        headers: {
          ...authHeaders,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }).then((x) => {
        return x.json();
      });
    },
    [path, authHeaders.Authorization]
  );
}

export function useGetFields({ id, formName }) {
  const query = useGet(`${id}/fields/${formName}`);

  React.useEffect(() => {
    subscribe(`${service}.fields.update`, query.refetch);
    return () => unsubscribe(`${service}.fields.update`, query.refetch);
  });

  return query;
}

export function useGetPersonalDetailsFields({ id, formName }) {
  const query = useGet(`${id}/personal-details-fields/${formName}`);

  React.useEffect(() => {
    subscribe(`${service}.fields.update`, query.refetch);
    return () => unsubscribe(`${service}.fields.update`, query.refetch);
  });

  return query;
}

export function useGetStudentLoansFields({ id, formName }) {
  const query = useGet(`${id}/student-loans-fields/${formName}`);

  React.useEffect(() => {
    subscribe(`${service}.fields.update`, query.refetch);
    return () => unsubscribe(`${service}.fields.update`, query.refetch);
  });

  return query;
}

export function useGetContactDetailsFields({ id, formName }) {
  const query = useGet(`${id}/contact-details-fields/${formName}`);

  React.useEffect(() => {
    subscribe(`${service}.fields.update`, query.refetch);
    return () => unsubscribe(`${service}.fields.update`, query.refetch);
  });

  return query;
}

export function useGetBankDetailsFields({ id, formName }) {
  const query = useGet(`${id}/bank-fields/${formName}`);

  React.useEffect(() => {
    subscribe(`${service}.fields.update`, query.refetch);
    return () => unsubscribe(`${service}.fields.update`, query.refetch);
  });

  return query;
}

export function useGetVisaFields({ id, formName }) {
  const query = useGet(`${id}/visa-fields/${formName}`);

  React.useEffect(() => {
    subscribe(`${service}.fields.update`, query.refetch);
    return () => unsubscribe(`${service}.fields.update`, query.refetch);
  });

  return query;
}

export function useGetLoginDetailsFields({ id, formName }) {
  const query = useGet(`${id}/login-details-fields/${formName}`);

  React.useEffect(() => {
    subscribe(`${service}.fields.update`, query.refetch);
    return () => unsubscribe(`${service}.fields.update`, query.refetch);
  });

  return query;
}

export function useGetPointsBalance({ id }) {
  const query = useGet(`contractor-points-balance?contractorId=${id}`);

  return query;
}

export function useGetAWSBalance({ id }) {
  const query = useGet(`contractor-aws-balance?contractorId=${id}`);

  return query;
}

export function useGetNewFields({ contractorId, formName }) {
  const query = useGet(`${contractorId}/new/fields/${formName}`);

  React.useEffect(() => {
    subscribe(`${service}.fields.getNew`, query.refetch);
    return () => unsubscribe(`${service}.fields.getNew`, query.refetch);
  });

  return query;
}

export function useUpdateField({ id, formName, subFormName }) {
  const post = usePost(`${id}/fields/${formName}`);

  return React.useCallback(
    (body) => {
      return post(body).then((x) => {
        publish(`${service}.fields.update`, x);
        publish(`contractor.${subFormName}.updated`, x);
        return x;
      });
    },
    [post]
  );
}

export function useProcessContractorProduct({ id }) {
  const post = usePost(`${id}/process/contractor-product`);

  return React.useCallback(
    (body) => {
      return post(body).then((x) => {
        publish(`${service}.fields.update`, x);
        return x;
      });
    },
    [post]
  );
}

export function useUpdateFields({ id, formName, subFormName, fields }) {
  let path = `${id}/fields-multi`;
  if (formName) path = `${path}/${formName}`;
  if (fields) path = `${path}/${fields}`;
  const post = usePost(path);

  return React.useCallback(
    (body) => {
      return post(body).then((x) => {
        publish(`contractor.${subFormName}.updated`, x);
        return x;
      });
    },
    [post]
  );
}

export function useSendContractorSMS({ id }) {
  const post = usePost(`${id}/send-sms`);

  return React.useCallback(
    (body) => {
      return post(body).then((x) => {
        publish(`${service}.fields.update`, x);
        return x;
      });
    },
    [post]
  );
}

export function useGetItems(path = "") {
  return useGet(`${path}`);
}

export function useSearchItems(path = "", serviceOverride = true) {
  return useGet(`index/search${path}`, `index/search${path}`, serviceOverride);
}
