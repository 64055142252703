import React from "react";
import SyncButton from "./SyncButton";
import SyncDialog from "./SyncDialog";

import { PanelDialog } from "@backslashbuild/pp-ui";

export default function SyncNotifications() {
  const [showSyncDialog, setShowSyncDialog] = React.useState(false);

  return (
    <>
      <SyncButton onClick={() => setShowSyncDialog(true)} />
      <PanelDialog
        open={showSyncDialog}
        onClose={() => {
          setShowSyncDialog(false);
        }}
      >
        <SyncDialog
          onClose={() => {
            setShowSyncDialog(false);
          }}
        />
      </PanelDialog>
    </>
  );
}
