import React from "react";
import QueryFiltering from "../../containers/QueryFiltering";
import { PanelRow, Label, TextInput, RadioGroup, Radio } from "@backslashbuild/pp-ui";
import AgencyPicker from "../agencies/AgencyPicker";
import AgencyName from "../agencies/AgencyName";

function MatchesFilter({ small }) {
  return (
    <QueryFiltering
      pathname="/schedules-c-matches"
      small={small}
      searchStringKey="search"
      getTagsFromState={(state) => {
        const tags = [];
        if (state.isApproved == "true") tags.push({ key: "isApproved", value: "Only" });
        if (state.isApproved == "false") tags.push({ key: "isApproved", value: "Exclude" });
        if (state.hasAmountPending == "true") tags.push({ key: "hasAmountPending", value: "Only" });
        if (state.hasAmountPending == "false")
          tags.push({ key: "hasAmountPending", value: "Exclude" });
        if (state.agencyId) tags.push({ key: "agency", value: <AgencyName id={state.agencyId} /> });
        return tags;
      }}
      getNextStateFromDeleteTag={(state, key) => {
        if (key === "isApproved") return { ...state, isApproved: undefined };
        if (key === "hasAmountPending") return { ...state, hasAmountPending: undefined };
        if (key === "agency") return { ...state, agencyId: undefined };
        return state;
      }}
      placeholder="Search matches"
      renderForm={(state, setState) => (
        <>
          <PanelRow>
            <Label label="Search Text">
              <TextInput
                value={state.search}
                onChange={(e) => setState({ ...state, search: e.target.value })}
              />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Agency">
              <AgencyPicker
                value={state.agencyId}
                onChange={(agencyId) => setState({ ...state, agencyId })}
              />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Is approved">
              <RadioGroup
                value={state.isApproved}
                onChange={(e) => setState({ ...state, isApproved: e.target.value })}
              >
                <Radio value="" label="Any" />
                <Radio value={"true"} label="Yes" />
                <Radio value={"false"} label="No" />
              </RadioGroup>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Has Pending Balance">
              <RadioGroup
                value={state.hasAmountPending}
                onChange={(e) => setState({ ...state, hasAmountPending: e.target.value })}
              >
                <Radio value="" label="Any" />
                <Radio value={"true"} label="Yes" />
                <Radio value={"false"} label="No" />
              </RadioGroup>
            </Label>
          </PanelRow>
        </>
      )}
    />
  );
}

export default MatchesFilter;
