import React from "react";

import TaxWeekPicker from "../tax-calendar/TaxWeekPicker";
import { useCreateBACSRun, invalidateBACSRuns } from "./api";
import { publish } from "./pubSub";

import {
  PanelDialog,
  PanelActions,
  Button,
  PanelRow,
  BoldText,
  PanelHeading,
} from "@backslashbuild/pp-ui";

export default function AddBACSRunDialog({ open, onClose }) {
  const [showResult, setShowResult] = React.useState(false);
  const [taxWeek, setTaxWeek] = React.useState();
  const [result, setResult] = React.useState();
  const createBacs = useCreateBACSRun();

  function handleSubmit() {
    setShowResult(true);
    createBacs({
      taxWeekNumber: taxWeek?.weekNumber,
      payrollDate: taxWeek?.payrollEndDate,
    }).then((res) => {
      if (res?.isSuccess) {
        publish("notification", "Added BACS run.");
        onClose();
        invalidateBACSRuns();
      } else {
        publish("notification", "Failed to add BACS run.");
        setResult(res);
      }
    });
  }

  return (
    <PanelDialog open={open} onClose={onClose}>
      <PanelHeading>Add a BACS Run</PanelHeading>
      <PanelRow>
        {!showResult && (
          <BoldText>{`Select the tax date to perform the BACS Run. The associated tax week, month and year will be recorded on
          the BACS run item.`}</BoldText>
        )}
      </PanelRow>
      {!showResult && (
        <PanelRow>
          <TaxWeekPicker
            value={taxWeek?.id}
            onChange={(id, taxWeek) => {
              setTaxWeek(taxWeek);
            }}
          />
        </PanelRow>
      )}
      {result && !result?.isSuccess && (
        <PanelRow>
          {result?.errors?.map((error) => (
            <div>{error}</div>
          ))}
        </PanelRow>
      )}
      <PanelActions>
        <Button variant="secondary" onClick={() => onClose()}>
          Close
        </Button>
        {!showResult && <Button onClick={() => handleSubmit()}>Submit</Button>}
      </PanelActions>
    </PanelDialog>
  );
}
