import React from "react";
import {
  Panel,
  PanelHeading,
  PanelSpacer,
  Button,
  Label,
  CheckBox,
  PanelRow,
  PanelActions,
  DatePicker,
} from "@backslashbuild/pp-ui";
import { Link, useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { utcToYmd } from "../../utils";
import RFPTypePicker from "./RFPTypePicker";
import AgencyPicker from "../agencies/AgencyPicker";

function RFPFilterPanel() {
  const history = useHistory();
  const location = useLocation();

  const getStateFromSearch = (s) => queryString.parse(s);

  const [state, setState] = React.useState(getStateFromSearch(location.search));

  function handleSubmit() {
    history.replace({
      search: queryString.stringify(
        {
          ...state,
          skip: undefined,
        },
        { skipEmptyString: false, skipNull: false }
      ),
    });
  }

  function addOrRemoveStatus(status) {
    let arr = state?.statuses ?? [];

    setState({
      ...state,
      statuses: arr.includes(status) ? arr.filter((x) => x != status) : [...arr, status],
    });
  }

  function includesStatus(status) {
    return state?.statuses?.indexOf(status) > -1;
  }

  return (
    <Panel>
      <PanelHeading>Filters</PanelHeading>
      <PanelSpacer />
      <PanelRow>
        <Label label="Ready">
          <CheckBox
            checked={includesStatus("ready")}
            onChange={() => {
              addOrRemoveStatus("ready");
            }}
          />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Locked">
          <CheckBox
            checked={includesStatus("locked")}
            onChange={() => {
              addOrRemoveStatus("locked");
            }}
          />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Cancelled">
          <CheckBox
            checked={includesStatus("cancelled")}
            onChange={() => {
              addOrRemoveStatus("cancelled");
            }}
          />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Finalised">
          <CheckBox
            checked={includesStatus("finalised")}
            onChange={() => {
              addOrRemoveStatus("finalised");
            }}
          />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Is Due">
          <CheckBox
            checked={state.isDue == "true"}
            onChange={() => {
              setState({ ...state, isDue: state.isDue == "true" ? "false" : "true" });
            }}
          />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Start Date">
          <DatePicker
            value={state.rfpStartDate}
            onChange={(v) => {
              setState({ ...state, rfpStartDate: utcToYmd(v) });
            }}
          />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="End Date">
          <DatePicker
            value={state.rfpEndDate}
            onChange={(v) => {
              setState({ ...state, rfpEndDate: utcToYmd(v) });
            }}
          />
        </Label>
      </PanelRow>
      {state?.scheduleId && (
        <PanelRow>
          <Label label="Schedule id">
            <div>
              <Link
                onClick={() => {
                  history.replace(`/schedule/${state?.scheduleId}`);
                }}
              >
                {state?.scheduleId}
              </Link>{" "}
              <Link onClick={() => setState({ ...state, scheduleId: null })}>Remove</Link>
            </div>
          </Label>
        </PanelRow>
      )}
      {state?.lockedOnPayRunId && (
        <PanelRow>
          <Label label="Locked on Payrun">
            <div>
              {state?.lockedOnPayRunId}{" "}
              <Link onClick={() => setState({ ...state, lockedOnPayRunId: null })}>Remove</Link>
            </div>
          </Label>
        </PanelRow>
      )}
      {state?.lockedOnPayslipId && (
        <PanelRow>
          <Label label="Locked on Payslip">
            <div>
              {state?.lockedOnPayslipId}{" "}
              <Link onClick={() => setState({ ...state, lockedOnPayslipId: null })}>Remove</Link>
            </div>
          </Label>
        </PanelRow>
      )}
      <PanelRow>
        <Label label="Type">
          <RFPTypePicker value={state.typeId} onChange={(v) => setState({ ...state, typeId: v })} />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Agency">
          <AgencyPicker
            value={state.agencyId}
            onChange={(v) => setState({ ...state, agencyId: v })}
          />
        </Label>
      </PanelRow>
      <PanelActions>
        <Button onClick={handleSubmit}>Apply filters</Button>
      </PanelActions>
    </Panel>
  );
}

export default RFPFilterPanel;
