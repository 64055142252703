import React from "react";

import {
  Button,
  Content,
  PageHeadingArea,
  PageTitle,
  PanelColumnSet,
  PageHeadingAreaSpacer,
} from "@backslashbuild/pp-ui";

import MatchesTable from "../modules/schedules-core/MatchesTable";
import MatchesFiltering from "../modules/schedules-core/MatchesFiltering";

export default function Matches() {
  return (
    <Content scrollable>
      <PageHeadingArea>
        <PageTitle>Matches</PageTitle>
        <PageHeadingAreaSpacer grow />
        <MatchesFiltering />
        <PageHeadingAreaSpacer />
      </PageHeadingArea>
      <PanelColumnSet>
        <MatchesTable />
      </PanelColumnSet>
    </Content>
  );
}
