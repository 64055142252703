import { PageTitle } from "@backslashbuild/pp-ui";
import React from "react";
import { useGet } from "./api";
import { isTextNullOrEmpty } from "./utils";
import { subscribe, unsubscribe } from "./pubSub";

export default function AgencyPageTitle({ id }) {
  const { data, loading, error, refetch } = useGet(id);

  React.useEffect(() => {
    subscribe("agency.agencyDetails.updated", refetch);
    return () => unsubscribe("agency.agencyDetails.updated", refetch);
  }, [id]);

  if (loading) return <div>Loading...</div>;

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    return (
      <>
        <PageTitle>
          {data?.value?.agencyNumber} {data?.value?.name}
        </PageTitle>
      </>
    );
  }

  return <>{handleRender()}</>;
}
