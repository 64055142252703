import React from "react";
import { isTextNullOrEmpty, utcToLocalText } from "./utils";
import { useHistory } from "react-router-dom";
import { useGetDraftFpsItems } from "./api";
import DraftFpsDialog from "./DraftFpsDialog";

import {
  TableRow,
  Table,
  TableCell,
  TableBody,
  TableFooter,
  PageLoading,
  Panel,
  PanelHeading,
  PanelSpacer,
  Button,
  PanelActions,
  PanelDialog,
  BoldText,
} from "@backslashbuild/pp-ui";

const c = {
  dateCreated: "50%",
  companyName: "50%",
};

const totalWidth = Object.keys(c).reduce((total, key) => (total += c[key]), 0);

function ParseQuery(isClosed) {
  return `?isClosed=${isClosed}`;
}

function ClickWrapper({ children, draft }) {
  const history = useHistory();
  return (
    <div onClick={() => history.push(`/hmrc-draft-fps-employees?draftId=${draft?.id}`)}>
      {children}
    </div>
  );
}

function PureTable({ data, queryId }) {
  return React.useMemo(() =>
    data?.value?.items?.map(
      (draft) => (
        <TableRow>
          <TableCell width={c.dateCreated}>
            <ClickWrapper draft={draft}>
              {utcToLocalText(draft.dateCreated, "dd/MM/yyyy HH:mm")}
            </ClickWrapper>
          </TableCell>
          <TableCell width={c.companyName}>
            <ClickWrapper draft={draft}>{draft.companyName}</ClickWrapper>
          </TableCell>
        </TableRow>
      ),
      [queryId]
    )
  );
}

export default function DraftFpsTable() {
  const [isClosed, setIsClosed] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);
  const query = ParseQuery(isClosed);
  const { data, loading, error, queryId, refetch } = useGetDraftFpsItems(query);

  if (loading) return <PageLoading />;
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  return (
    <Panel>
      <PanelHeading>Draft Submissions</PanelHeading>
      {data?.isSuccess && data?.value?.itemsRetrieved > 0 && (
        <>
          <PanelSpacer />
          <Table standardTable width={totalWidth}>
            <TableBody fullPageTable={false} footerHeight={TableFooter.height}>
              <PureTable data={data} queryId={queryId} />
            </TableBody>
            <PanelSpacer />
            <TableFooter>
              <BoldText>{`Showing ${data?.value?.itemsRetrieved} of ${data?.value?.itemsTotal}`}</BoldText>
            </TableFooter>
          </Table>
          <PanelSpacer />
        </>
      )}
      <PanelActions>
        <Button onClick={() => setShowDialog(true)}>Draft</Button>
      </PanelActions>
      <PanelDialog
        open={showDialog}
        onClose={() => {
          setShowDialog(false);
        }}
      >
        <DraftFpsDialog
          onClose={() => {
            setShowDialog(false);
          }}
        />
      </PanelDialog>
    </Panel>
  );
}
