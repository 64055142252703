import React from "react";
import { Button } from "@backslashbuild/pp-ui";
import { usePost } from "./api";
import { publish } from "../../pubSub";

export default function ReindexInvoicesButton() {
  const reindex = usePost();

  async function handleReindex() {
    publish("notification", "Reindexing all invoices...");
    const result = await reindex("reindex");

    if (!result.isSuccess) {
      var errors = result?.errors;
      publish(
        "notification",
        <ul>
          Unable to reindex invoices. Errors:
          {errors?.length > 0 &&
            errors?.map((error) => {
              return <li>{error}</li>;
            })}
        </ul>
      );
      return;
    }

    if (result?.isSuccess) publish("notification", `Reindex completed. ${result?.value}`);
  }

  return (
    <>
      <Button small onClick={() => handleReindex()} style={{ width: 200 }}>
        Reindex Invoices
      </Button>
    </>
  );
}
