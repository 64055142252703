import React from "react";
import { Dropdown, DropdownOption } from "@backslashbuild/pp-ui";
import { useGetAuthors } from "./api";

export default function AuthorPicker({ value, onChange, disabled = false }) {
  const { data, loading, error } = useGetAuthors();
  const options = data?.value ?? [];

  return (
    <Dropdown value={value} onChange={onChange} disabled={disabled}>
      <DropdownOption value="">Choose:</DropdownOption>
      {options?.map((x) => (
        <DropdownOption value={x.userId}>{x.name}</DropdownOption>
      ))}
    </Dropdown>
  );
}
