import React from "react";
import { SearchBar, Panel, PanelActions, Button } from "@backslashbuild/pp-ui";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { trimWhitespace } from "../utils";

function QueryFiltering({
  searchStringKey = "search",
  renderForm,
  getTagsFromState,
  getNextStateFromDeleteTag,
  small,
  pathname,
  placeholder,
}) {
  const history = useHistory();
  const location = useLocation();
  const onSearchPage = !pathname || location.pathname === pathname;

  const getStateFromSearch = (s) => (onSearchPage ? queryString.parse(s) : {});

  const [state, setState] = React.useState(getStateFromSearch(location.search));

  function handleSubmit() {
    history.replace({
      pathname,
      search: queryString.stringify(
        {
          ...state,
          [searchStringKey]: trimWhitespace(state[searchStringKey]),
          skip: undefined,
        },
        { skipEmptyString: false, skipNull: false }
      ),
    });
  }

  function handleClear() {
    setState({ ...state, [searchStringKey]: "" });
    history.replace({
      pathname,
      search: queryString.stringify(
        {
          ...state,
          [searchStringKey]: undefined,
          skip: undefined,
        },
        { skipEmptyString: false, skipNull: false }
      ),
    });
  }

  function handleDeleteTag(key) {
    const newState = getNextStateFromDeleteTag?.(state, key);
    if (newState) {
      setState(newState);
      history.replace({
        search: queryString.stringify(newState, { skipEmptyString: true, skipNull: true }),
      });
    }
  }

  React.useEffect(() => {
    setState(getStateFromSearch(location.search));
  }, [location.search]);

  const tags = getTagsFromState?.(getStateFromSearch(location.search));

  return (
    <SearchBar
      placeholder={placeholder}
      small={small}
      value={state[searchStringKey]}
      onChange={(v) => setState({ ...state, [searchStringKey]: v })}
      onSubmit={handleSubmit}
      onClear={() => {
        handleClear();
      }}
      enableDropdown
      tags={tags}
      onDeleteTag={handleDeleteTag}
      renderForm={({ close }) => (
        <>
          {renderForm?.(state, setState)}
          <PanelActions>
            <Button
              onClick={() => {
                handleSubmit();
                close();
              }}
            >
              Search
            </Button>
          </PanelActions>
        </>
      )}
    />
  );
}

export default QueryFiltering;
