import React from "react";
import { Content, PageHeadingArea, PageTitle } from "@backslashbuild/pp-ui";
import ContributionScheduleExportsTable from "../modules/nest/ContributionScheduleExportsTable";
import CreateContributionScheduleExportButton from "../modules/nest/CreateContributionScheduleExportButton";
import { PageHeadingAreaSpacer } from "@backslashbuild/pp-ui/dist/index.es";

export default function NESTContributionSchedule() {
  return (
    <Content>
      <PageHeadingArea>
        <PageTitle>Schedule Exports</PageTitle>
        <PageHeadingAreaSpacer grow />
        <CreateContributionScheduleExportButton />
      </PageHeadingArea>
      <ContributionScheduleExportsTable />
    </Content>
  );
}
