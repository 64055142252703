import React from "react";
import {
  Panel,
  PanelHeading,
  TextInput,
  Label,
  PanelRow,
  PanelActions,
  Button,
  PanelSpacer,
  Link,
  PanelDialog,
} from "@backslashbuild/pp-ui";
import { usePost, useGet } from "./api";
import { useHistory } from "react-router-dom";
import { publish } from "../../pubSub";
import { utcToYmd } from "./utils";
import AgencyBranchName from "../agencies/AgencyBranchName";

export default function UmbrellaRateForm({ umbrellaRateId, onClose = () => {} }) {
  const [submitting, setSubmitting] = React.useState(false);
  const [edits, setEdits] = React.useState({
    startDate: null,
    endDate: null,
    notes: null,
    rate: null,
  });

  const { data, loading, error } = useGet(`umbrella-rates/${umbrellaRateId}`);
  const update = usePost("umbrella-rates/update");
  const history = useHistory();

  async function handleSubmit() {
    setSubmitting(true);
    try {
      const response = await update({ ...edits, Id: umbrellaRateId });
      if (response.isSuccess) {
        publish("notification", "Updated");
      } else {
        publish(
          "notification",
          <>
            <ul>
              {response.errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </>
        );
      }
    } catch (e) {
      console.error(e, "error updating umbrella rates");
      publish("notification", "A network error occured.");
    } finally {
      setSubmitting(false);
    }
  }

  function handleRender() {
    if (data?.isSuccess) {
      return (
        <>
          <PanelRow>
            <Label label="Branch">
              {data?.value?.branchId ? (
                <AgencyBranchName id={data?.value?.branchId} />
              ) : (
                "Not branch specific."
              )}
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Start Date">
              <TextInput
                value={edits.startDate ?? utcToYmd(data?.value?.startDate)}
                type="date"
                onChange={(e) => {
                  setEdits({ ...edits, startDate: utcToYmd(e.target.value) });
                }}
              ></TextInput>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Calculated On Type Id">
              <TextInput
                value={edits.calculatedOnTypeId ?? data?.value?.calculatedOnTypeId}
                onChange={(e) => setEdits({ ...edits, calculatedOnTypeId: e.target.value })}
              />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Cap On Percentage">
              <TextInput
                type="number"
                value={edits.capOnPercentage ?? data?.value?.capOnPercentage}
                onChange={(e) => setEdits({ ...edits, capOnPercentage: Number(e.target.value) })}
              />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Contractors Starting From">
              <TextInput
                type="number"
                value={edits.contractorsStartingFrom ?? data?.value?.contractorsStartingFrom}
                onChange={(e) =>
                  setEdits({ ...edits, contractorsStartingFrom: Number(e.target.value) })
                }
              />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Description">
              <TextInput
                value={edits.description ?? data?.value?.description}
                onChange={(e) => setEdits({ ...edits, description: e.target.value })}
              />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Different Weeks">
              <TextInput
                type="number"
                value={edits.differentWeeks ?? data?.value?.differentWeeks}
                onChange={(e) => setEdits({ ...edits, differentWeeks: Number(e.target.value) })}
              />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Fixed Margin">
              <TextInput
                type="number"
                value={edits.fixedMargin ?? data?.value?.fixedMargin}
                onChange={(e) => setEdits({ ...edits, fixedMargin: Number(e.target.value) })}
              />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Gross From">
              <TextInput
                type="number"
                value={edits.grossFrom ?? data?.value?.grossFrom}
                onChange={(e) => setEdits({ ...edits, grossFrom: Number(e.target.value) })}
              />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Gross To">
              <TextInput
                type="number"
                value={edits.grossTo ?? data?.value?.grossTo}
                onChange={(e) => setEdits({ ...edits, grossTo: Number(e.target.value) })}
              />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Legacy Rates Added By User">
              <TextInput
                type="number"
                value={edits.legacyRatesAddedByUser ?? data?.value?.legacyRatesAddedByUser}
                onChange={(e) =>
                  setEdits({ ...edits, legacyRatesAddedByUser: Number(e.target.value) })
                }
              />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Percentage">
              <TextInput
                type="number"
                value={edits.percentage ?? data?.value?.percentage}
                onChange={(e) => setEdits({ ...edits, percentage: Number(e.target.value) })}
              />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="RatesAddedByUserId">
              <TextInput
                value={edits.ratesAddedByUserId ?? data?.value?.ratesAddedByUserId}
                onChange={(e) => setEdits({ ...edits, ratesAddedByUserId: e.target.value })}
              />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Rebate">
              <TextInput
                type="number"
                value={edits.rebate ?? data?.value?.rebate}
                onChange={(e) => setEdits({ ...edits, rebate: Number(e.target.value) })}
              />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Rebate Description">
              <TextInput
                value={edits.rebateDescription ?? data?.value?.rebateDescription}
                onChange={(e) => setEdits({ ...edits, rebateDescription: e.target.value })}
              />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Rebate Percentage">
              <TextInput
                type="number"
                value={edits.rebatePercentage ?? data?.value?.rebatePercentage}
                onChange={(e) => setEdits({ ...edits, rebatePercentage: Number(e.target.value) })}
              />
            </Label>
          </PanelRow>
        </>
      );
    }
  }

  return (
    <>
      <Panel>
        <PanelHeading>Edit Umbrella Rate</PanelHeading>
        <PanelSpacer />
        {handleRender()}
        <PanelSpacer />

        <PanelActions>
          <Button onClick={handleSubmit}>Save</Button>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
        </PanelActions>
      </Panel>
    </>
  );
}
