import React from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useGetDraftFpsEmployeeData } from "./api";
import { isTextNullOrEmpty, utcToLocalText } from "./utils";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";
import ObjectMap from "../../components/ObjectMap";

import {
  TableRow,
  Table,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableFooter,
  PageLoading,
  Panel,
  PanelColumn,
  PanelHeading,
  PanelColumnSpacer,
} from "@backslashbuild/pp-ui";

const c = {
  dateCreated: "20%",
  accNo: "19%",
  name: "30%",
  taxPeriod: "15%",
  nino: "16%",
};

const totalWidth = Object.keys(c).reduce((total, key) => (total += c[key]), 0);

function ParseQuery(search) {
  const parsedQueryString = queryString.parse(search);

  return `?${queryString.stringify({ ...parsedQueryString })}`;
}

function parseName(name) {
  return `${name?.forename} ${name?.surname}`;
}

function parseTaxPeriod(payment) {
  let taxPeriod = ``;

  if (payment?.weekNo) taxPeriod = `W ${payment?.weekNo}`;
  if (payment?.monthNo) taxPeriod = `M ${payment?.monthNo}`;

  return taxPeriod;
}

function PureTable({ data, queryId, onItemClick }) {
  return React.useMemo(() =>
    data?.value?.items?.map(
      (submission) => (
        <TableRow onClick={() => onItemClick(submission)}>
          <TableCell width={c.accNo}>{submission?.accountNumber}</TableCell>
          <TableCell width={c.name}>
            {parseName(submission?.employeeData?.employee?.employeeDetails?.name)}
          </TableCell>
          <TableCell width={c.nino}>
            {submission?.employeeData?.employee?.employeeDetails?.nationalInsuranceNumber}
          </TableCell>
          <TableCell width={c.dateCreated}>
            {utcToLocalText(submission?.dateCreated, "dd/MM/yyyy HH:mm")}
          </TableCell>
          <TableCell width={c.taxPeriod}>
            {parseTaxPeriod(submission?.employeeData?.employee?.employments[0]?.payment)}
          </TableCell>
        </TableRow>
      ),
      [queryId]
    )
  );
}

export default function DraftFpsEmployeeTable() {
  const [selectedItem, setSelectedItem] = React.useState(null);
  const location = useLocation();
  const search = usePagination(location.search);
  const query = ParseQuery(search);
  const { data, loading, error, queryId, refetch } = useGetDraftFpsEmployeeData(query);

  if (loading) return <PageLoading />;
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  return (
    <>
      <PanelColumn width={750}>
        {/* Temporary fix for Safari not rendering ui components correctly */}
        <div style={{ width: "100%", height: "77vh" }}>
          <Table standardTable width={totalWidth}>
            <TableHeader>
              <TableHeaderColumn width={c.accNo}>Account Number</TableHeaderColumn>
              <TableHeaderColumn width={c.name}>Name</TableHeaderColumn>
              <TableHeaderColumn width={c.nino}>NINO</TableHeaderColumn>
              <TableHeaderColumn width={c.dateCreated}>Date Created</TableHeaderColumn>
              <TableHeaderColumn width={c.taxPeriod}>Tax Period</TableHeaderColumn>
            </TableHeader>
            <TableBody fullPageTable={true}>
              <PureTable
                data={data}
                queryId={queryId}
                onItemClick={(item) => setSelectedItem(item)}
              />
            </TableBody>
            <TableFooter>
              <QueryPaginator
                itemsTotal={data?.value?.itemsTotal}
                itemsRetrieved={data?.value?.itemsRetrieved}
              />
            </TableFooter>
          </Table>
        </div>
      </PanelColumn>
      <PanelColumnSpacer />
      <PanelColumn width={750}>
        <Panel maxWidth={750}>
          <PanelHeading>Draft Employee Data View</PanelHeading>
          <ObjectMap data={selectedItem} hideEmptyValues />
          {!Boolean(selectedItem) && "Select an item to view the data."}
        </Panel>
      </PanelColumn>
    </>
  );
}
