import React from "react";

import AddBACSRunSection from "./AddBACSRunSection";
import LockToBACSRunSection from "./LockToBACSRunSection";
import { useHistory } from "react-router-dom";

import {
  PageHeadingArea,
  BackArrow,
  PageHeadingAreaSpacer,
  PageTitle,
} from "@backslashbuild/pp-ui";

export default function PayslipsHeading() {
  const history = useHistory();

  return (
    <PageHeadingArea>
      <BackArrow onClick={() => history.goBack()} />
      <PageHeadingAreaSpacer />
      <PageTitle>Manage Payslips</PageTitle>
      <PageHeadingAreaSpacer grow />
      <LockToBACSRunSection />
      <AddBACSRunSection />
    </PageHeadingArea>
  );
}
