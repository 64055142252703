import React from "react";

import { Content } from "@backslashbuild/pp-ui";

import StatutoryPayGroupsTable from "../modules/payroll/StatutoryPayGroupsTable";

function StatutoryPayments() {
  return (
    <Content>
      <StatutoryPayGroupsTable />
    </Content>
  );
}

export default StatutoryPayments;
