import React from "react";
import { useHistory } from "react-router-dom";
import {
  Content,
  PanelColumnSpacer,
  PanelColumn,
  PageHeadingArea,
  PanelColumnSet,
  SidebarWrapper,
  BackArrow,
  PageHeadingAreaSpacer,
  PageTitle,
  PanelSpacer,
} from "@backslashbuild/pp-ui";

import DpsFilters from "../modules/hmrc/DpsFilter";
import DpsTable from "../modules/hmrc/DpsTable";
import SyncNotifications from "../modules/hmrc/SyncNotifications";
import ProcessNotification from "../modules/hmrc/ProcessNotifications";
import SyncsTable from "../modules/hmrc/SyncsTable";

export default function DPS() {
  const history = useHistory();

  return (
    <SidebarWrapper>
      <Content scrollable>
        <PageHeadingArea>
          <BackArrow onClick={() => history.goBack()} />
          <PageHeadingAreaSpacer />
          <PageTitle>HMRC Notifications</PageTitle>
          <PageHeadingAreaSpacer grow />
          <ProcessNotification />
          <PageHeadingAreaSpacer />
          <SyncNotifications />
        </PageHeadingArea>
        <PanelColumnSet>
          <PanelColumn width={320}>
            <DpsFilters />
            <PanelSpacer />
            <PanelSpacer />
            <PanelSpacer />
            <SyncsTable />
          </PanelColumn>
          <PanelColumnSpacer />
          <DpsTable />
        </PanelColumnSet>
      </Content>
    </SidebarWrapper>
  );
}
