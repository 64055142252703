import React from "react";
import { Button, PanelActions, PanelDialog, PanelHeading } from "@backslashbuild/pp-ui";
import { useParams } from "react-router-dom";
import { usePost, invalidateContributions, useGetContributionScheduleExport } from "./api";
import { publish } from "../../pubSub";
import { PageLoading, PanelRow } from "@backslashbuild/pp-ui/dist/index.es";

export default function PreProcessContributionsExport() {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const { exportId } = useParams();

  const { data, loading, error, queryId, refetch } = useGetContributionScheduleExport({
    exportId,
  });

  const preProcess = usePost(`contributions/schedule-exports/pre-process`);

  function handleSubmit() {
    preProcess({ contributionScheduleExportId: exportId }).then((res) => {
      if (res?.isSuccess) {
        publish("notification", "Completed");
        setDialogOpen(false);
        refetch();
      } else {
        publish("notification", "Unable to pre-process export");
      }
    });
    invalidateContributions();
  }

  function handleRender() {
    if (loading) return <PageLoading />;

    if (error) return <PanelRow>Unable to load pre-processing status</PanelRow>;

    if (data?.value?.preProcessingErrors?.length == 0) {
      return <PanelRow>All export rows valid. No errors to display.</PanelRow>;
    }

    return (
      data?.value?.preProcessingErrors?.length > 0 &&
      data?.value?.preProcessingErrors?.map((error) => <PanelRow>{error}</PanelRow>)
    );
  }

  return (
    <>
      <Button
        onClick={() => setDialogOpen(true)}
        style={{ marginRight: 10 }}
        variant={data?.value?.passedPreProcessing ? "secondary" : "primary"}
      >
        Pre-Processing
      </Button>
      <PanelDialog onClose={() => setDialogOpen(false)} open={dialogOpen}>
        <PanelHeading>Pre-processing</PanelHeading>
        {handleRender()}
        <PanelActions>
          <Button variant="secondary" onClick={() => setDialogOpen(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Pre-Process Export
          </Button>
        </PanelActions>
      </PanelDialog>
    </>
  );
}
