import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  TableRow,
  Table,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableFooter,
  TableBody,
  BoldText,
  Pill,
} from "@backslashbuild/pp-ui";
import { Link as RouterLink } from "react-router-dom";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";
import { useGet } from "./api";
import { utcToLocalText } from "../../utils";

const c = {
  payRunNumber: 300,
  company: 300,
  status: 300,
  dateCreated: 100,
};
const totalWidth = Object.keys(c).reduce((total, key) => (total += c[key]), 0);

function PureTable({ data, queryId }) {
  return React.useMemo(() =>
    data.value.items.map(
      (payrun) => (
        <TableRow key={payrun.id} component={RouterLink} to={`/payrun/${payrun.id}`}>
          <TableCell width={c.payRunNumber}>{payrun.payRunNumber}</TableCell>
          <TableCell width={c.company}>{payrun.company?.name}</TableCell>
          <TableCell width={c.status}>
            {payrun.isFinalised ? (
              <Pill status="success">Finalised at {utcToLocalText(payrun.finalisedAt)}</Pill>
            ) : payrun.isCancelled ? (
              <Pill status="cancelled">Cancelled at {utcToLocalText(payrun.cancelledAt)}</Pill>
            ) : (
              <Pill status="info">Staged</Pill>
            )}
          </TableCell>
          <TableCell width={c.dateCreated}>{utcToLocalText(payrun.dateCreated)}</TableCell>
        </TableRow>
      ),
      [queryId]
    )
  );
}

function TableSkeleton() {
  return (
    <TableRow>
      <TableCell width={c.payRunNumber}>-</TableCell>
      <TableCell width={c.company}>-</TableCell>
      <TableCell width={c.status}>-</TableCell>
      <TableCell width={c.dateCreated}>-</TableCell>
    </TableRow>
  );
}

function PayRunsTable() {
  const { data, loading, error, queryId } = useGet("payruns");

  const isLoaded = Boolean(data?.value);

  return (
    <Table standardTable width={totalWidth}>
      <TableHeader>
        <TableHeaderColumn width={c.payRunNumber}>Pay run number</TableHeaderColumn>
        <TableHeaderColumn width={c.company}>Company</TableHeaderColumn>
        <TableHeaderColumn width={c.status}>Status</TableHeaderColumn>
        <TableHeaderColumn width={c.dateCreated}>Date Created</TableHeaderColumn>
      </TableHeader>
      <TableBody fullPageTable={true} footerHeight={TableFooter.height}>
        {isLoaded ? (
          <PureTable data={data} queryId={queryId} />
        ) : data?.errors ? (
          data?.errors.map((error) => <div>{error}</div>)
        ) : (
          <TableSkeleton />
        )}
      </TableBody>
      <TableFooter>
        {loading && <BoldText>Loading...</BoldText>}
        {error && <BoldText>A network error occured.</BoldText>}
        <QueryPaginator
          itemsTotal={data?.value?.itemsTotal}
          itemsRetrieved={data?.value?.itemsRetrieved}
        />
      </TableFooter>
    </Table>
  );
}

export default PayRunsTable;
