import React from "react";
import { PopoverMenu } from "@backslashbuild/pp-ui";
import ObjectMap from "./ObjectMap";

function DebugPopover({ data, children, component: Component = "span" }) {
  const [open, setOpen] = React.useState(false);

  return (
    <PopoverMenu
      open={open}
      onClose={() => setOpen(false)}
      renderAnchor={(ref) => (
        <Component ref={ref} onClick={() => setOpen(true)}>
          {children}
        </Component>
      )}
    >
      <div style={{ height: 600, overflow: "scroll" }}>
        <ObjectMap data={data} />
      </div>
    </PopoverMenu>
  );
}

export default DebugPopover;
