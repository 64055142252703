import React from "react";
import { useParams, Link, useHistory } from "react-router-dom";

import {
  Content,
  Panel,
  PanelSpacer,
  PanelActions,
  PageHeadingArea,
  Table,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableRow,
  TableCell,
  BackArrow,
} from "@backslashbuild/pp-ui";

import Border from "../components/Border";

import ContractorProductPageTitle from "../modules/contractors/ContractorProductPageTitle";
import { Button, PageHeadingAreaSpacer, PanelDialog } from "@backslashbuild/pp-ui/dist/index.es";
import EmploymentHolidayCalendar from "../modules/payroll/EmploymentHolidayCalendar";
import HolidayTransactionsTable from "../modules/payroll/HolidayTransactionsTable";

function ContractorProductHolidayEntitlement() {
  const [bookHoliday, setBookHoliday] = React.useState(null);
  const history = useHistory();
  const { contractorProductId } = useParams();

  return (
    <Content scrollable>
      <PageHeadingArea>
        <BackArrow onClick={() => history.goBack()} />
        <ContractorProductPageTitle
          contractorProductId={contractorProductId}
          title={"Holiday Entitlement"}
        />
      </PageHeadingArea>

      <HolidayTransactionsTable
        employmentId={contractorProductId}
        holidayYear={2021}
        openDialog={() => setBookHoliday(2021)}
      />
      <PanelSpacer large />
      <HolidayTransactionsTable
        employmentId={contractorProductId}
        holidayYear={2020}
        openDialog={() => setBookHoliday(2020)}
      />
      <PanelSpacer large />
      <HolidayTransactionsTable
        employmentId={contractorProductId}
        holidayYear={2019}
        openDialog={() => setBookHoliday(2019)}
      />
      <PanelSpacer large />
      <HolidayTransactionsTable
        employmentId={contractorProductId}
        holidayYear={2018}
        openDialog={() => setBookHoliday(2018)}
      />
      <PanelSpacer large />
      <PanelDialog open={bookHoliday}>
        <EmploymentHolidayCalendar
          contractorProductId={contractorProductId}
          holidayYear={bookHoliday}
          onClose={() => setBookHoliday(null)}
        />
      </PanelDialog>
    </Content>
  );
}

export default ContractorProductHolidayEntitlement;
