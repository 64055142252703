import React from "react";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { subscribe, publish, unsubscribe } from "./pubSub";
import getApiUrl from "../../../getApiUrl";
import { useQuery, queryCache } from "react-query";

const apiHost = getApiUrl();
const service = "internal-comms";

export function useGet(cacheKey, path, serviceOverride) {
  if (!path) {
    path = cacheKey;
  }
  if (!serviceOverride) {
    serviceOverride = service;
  }

  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };

  const { data, error, isLoading: loading, updatedAt: queryId } = useQuery(
    cacheKey,
    async (key, id) => {
      return fetch(`${apiHost}/${serviceOverride}/${path}`, {
        headers: {
          ...authHeaders,
        },
      }).then((r) => r.json());
    }
  );

  const runQuery = () => queryCache.invalidateQueries(path);

  React.useEffect(() => {
    runQuery();
  }, [path]);

  return { data, loading, error, queryId, refetch: runQuery };
}

export function usePost(path) {
  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };

  return React.useCallback(
    (body) => {
      return fetch(`${apiHost}/${service}/${path}`, {
        method: "POST",
        headers: {
          ...authHeaders,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }).then((x) => {
        return x.json();
      });
    },
    [path, authHeaders.Authorization]
  );
}

export function useGetComms({ itemId }) {
  const query = useGet(`?itemId=${itemId}`);
  React.useEffect(() => {
    subscribe("internal-comms.create", query.refetch);
    return () => unsubscribe("internal-comms.create", query.refetch);
  });
  return query;
}

export function useCreateComm() {
  const post = usePost("create");
  return React.useCallback((body) => {
    return post(body).then((p) => {
      publish("internal-comms.create", p);
      return p;
    });
  });
}

export function useGetTopics(configuration) {
  const query = useGet(`topics?${configuration}=true`);
  React.useEffect(() => {
    subscribe("internal-comms.create", query.refetch);
    return () => unsubscribe("internal-comms.create", query.refetch);
  });
  return query;
}

export function useGetFields({ id, formName }) {
  const query = useGet(`${id}/fields/${formName}`);

  React.useEffect(() => {
    subscribe(`internal-comms.fields.update`, query.refetch);
    return () => unsubscribe(`internal-comms.fields.update`, query.refetch);
  });

  return query;
}

export function useGetNewTopicFields() {
  const query = useGet(`fields/new/topic`);

  React.useEffect(() => {
    subscribe(`internal-comms.fields.update`, query.refetch);
    return () => unsubscribe(`internal-comms.fields.update`, query.refetch);
  });

  return query;
}

export function useUpdateFields({ id, formName }) {
  const post = usePost(`${id}/fields-multi/${formName}`);

  return React.useCallback(
    (body) => {
      return post(body).then((x) => {
        publish(`internal-comms.fields.update`, x);
        return x;
      });
    },
    [post]
  );
}
