import React from "react";

import { Content } from "@backslashbuild/pp-ui";

import UsersTable from "../modules/identity/UsersTable";

export default function Invoices() {
  return (
    <Content>
      <UsersTable />
    </Content>
  );
}
