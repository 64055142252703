import React from "react";
import {
  BoldText,
  Label,
  PanelRow,
  PanelActions,
  Button,
  PanelSpacer,
  Dropdown,
  CheckBox,
  DropdownOption,
  BackArrow,
  PageTitle,
} from "@backslashbuild/pp-ui";
import { utcToLocalText } from "./utils";
import { usePost } from "./api";
import { useHistory } from "react-router-dom";
import { publish } from "../../pubSub";
import {
  PageHeadingArea,
  PageSubtitle,
  PanelColumnSpacer,
} from "@backslashbuild/pp-ui/dist/index.es";

const HolidayBooking = ({ holidayTransaction }) => {
  const cancelHoliday = usePost(`rfps/cancel`);

  function handleCancelButton() {
    cancelHoliday({
      reference: holidayTransaction?.requestForProcessingId,
    }).then((res) => {
      if (res?.isSuccess) {
        publish("notification", "Holiday cancelled successfully.");
      } else {
        if (res?.errors?.length > 0) {
          res.errors.forEach((e) => {
            publish("notification", e);
          });
        }
      }
    });
  }

  return (
    <>
      <PanelRow>
        <Label label="Days booked">
          <BoldText>{holidayTransaction?.amountInDays}</BoldText>
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Amount">
          <BoldText>£{holidayTransaction?.amountInMoney}</BoldText>
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Status">
          <BoldText>{holidayTransaction?.status}</BoldText>
        </Label>
        {!(holidayTransaction?.isFinalised == true) && (
          <Button style={{ marginLeft: 16 }} onClick={() => handleCancelButton()}>
            Cancel
          </Button>
        )}
      </PanelRow>
    </>
  );
};

const BookHolidayForm = ({ date, employmentId, holidayYear }) => {
  const bookHoliday = usePost(`holiday-transactions/book-holiday`);

  function handleSubmit(isHalfDay) {
    bookHoliday({
      employmentId,
      dueDate: date,
      holidayYear: holidayYear,
      days: [
        {
          date,
          isHalfDay,
        },
      ],
    }).then((res) => {
      if (res?.isSuccess) {
        publish("notification", "Holiday booked successfully.");
      } else {
        if (res?.errors?.length > 0) {
          res.errors.forEach((e) => {
            publish("notification", e);
          });
        }
      }
    });
  }

  return (
    <PanelRow>
      <PageSubtitle>Book Holiday</PageSubtitle>
      <Button onClick={() => handleSubmit(true)}>Book Half Day</Button>
      <Button onClick={() => handleSubmit(false)} style={{ marginLeft: 16 }}>
        Book Full Day
      </Button>
    </PanelRow>
  );
};

function EmploymentHolidayDialog({
  date,
  employmentId,
  holidayTransactions,
  holidayYear,
  onClose,
}) {
  const [submitting, setSubmitting] = React.useState(false);
  const [values, setValues] = React.useState(null);

  function handleRenderHolidayBookings() {
    if (holidayTransactions == null || !holidayTransactions?.length > 0) {
      return (
        <PanelRow>
          <div>No holiday currently booked for this date.</div>
        </PanelRow>
      );
    }

    return (
      <>
        {holidayTransactions.map((holiday) => {
          return <HolidayBooking holidayTransaction={holiday} />;
        })}
      </>
    );
  }

  return (
    <div style={{ height: 600 }}>
      <PanelRow>
        <PageHeadingArea>
          <BackArrow onClick={() => onClose()} />
          <PageTitle>{utcToLocalText(date)}</PageTitle>
        </PageHeadingArea>
      </PanelRow>
      <PanelRow>
        <PageSubtitle>Holiday Booked</PageSubtitle>
      </PanelRow>
      {handleRenderHolidayBookings()}
      <PanelSpacer />
      <BookHolidayForm date={date} employmentId={employmentId} holidayYear={holidayYear} />
    </div>
  );
}

export default EmploymentHolidayDialog;
