import React from "react";
import {
  Panel,
  PanelRow,
  Label,
  TextInput,
  PanelHeading,
  PanelSpacer,
  Button,
  PageHeadingAreaSpacer,
  PanelActions,
  DatePicker,
} from "@backslashbuild/pp-ui";
import AgencyPicker from "./AgencyPicker";
import InvoiceCategoryPicker from "./InvoiceCategoryPicker";
import { usePost } from "./api";
import { publish } from "../../pubSub";
import { currentDateUtc } from "./utils";
import useCurrentTaxWeek from "../tax-calendar/useCurrentTaxWeek";

export default function CreateStandaloneCreditNoteForm({ onClose = () => {} }) {
  const [invoiceDate, setInvoiceDate] = React.useState(currentDateUtc());
  const [weekNumber, setWeekNumber] = React.useState(useCurrentTaxWeek());
  const [categoryId, setCategoryId] = React.useState(null);
  const [agencyNumber, setAgencyNumber] = React.useState("");

  const create = usePost();

  function handleSubmit() {
    create(`create-credit-note`, {
      invoiceCategoryId: categoryId,
      agencyNumber,
      invoiceDate,
      weekNumber: weekNumber.toString(),
    }).then((res) => {
      if (res?.isSuccess) {
        publish("notification", "Invoice created");
        onClose();
      } else {
        publish("notification", "Unable to create invoice: " + res.errors);
        console.log(res);
      }
    });
  }

  return (
    <>
      <Panel>
        <PanelHeading>Credit Note</PanelHeading>
        <PanelRow>
          <Label label="Invoice Date">
            <DatePicker value={invoiceDate} onChange={(e) => setInvoiceDate(e)} />
          </Label>
        </PanelRow>
        <PanelSpacer />
        <PanelRow>
          <Label label="Week Number">
            <TextInput value={weekNumber} onChange={(e) => setWeekNumber(e.target.value)} />
          </Label>
        </PanelRow>
        <PanelSpacer />
        <PanelRow>
          <Label label="Category">
            <InvoiceCategoryPicker value={categoryId} onChange={setCategoryId} />
          </Label>
        </PanelRow>
        <PanelSpacer />
        <PanelRow>
          <Label label="Agency">
            <AgencyPicker value={agencyNumber} onChange={setAgencyNumber} />
          </Label>
        </PanelRow>
        <PanelSpacer />
        <PanelActions>
          <Button large variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <PageHeadingAreaSpacer />
          <Button large onClick={() => handleSubmit()}>
            Submit
          </Button>
        </PanelActions>
      </Panel>
    </>
  );
}
