import React from "react";
import { usePostSync } from "./api";

import {
  Panel,
  PanelActions,
  Button,
  PanelRow,
  BoldText,
  PanelHeading,
} from "@backslashbuild/pp-ui";

export default function SyncDialog({ onClose }) {
  const sync = usePostSync();

  function handleConfirm() {
    sync();
  }

  return (
    <Panel>
      <PanelHeading>Load Notifications from HMRC</PanelHeading>
      <PanelRow>
        <BoldText>{`Click on 'Confirm' if you wish to load new notifications from HMRC for each company. The whole
        process will take at least a few minutes to complete so you won't see the changes immediately.`}</BoldText>
      </PanelRow>
      <PanelActions>
        <Button variant="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleConfirm();
            onClose();
          }}
        >
          Confirm
        </Button>
      </PanelActions>
    </Panel>
  );
}
