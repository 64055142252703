import { Link } from "@backslashbuild/pp-ui";
import React from "react";
import { useGetWalletBalance } from "./api";
import { isTextNullOrEmpty } from "../contractors/utils";
import { Link as RouterLink } from "react-router-dom";

export default function ContractorPointsBalance({ id }) {
  const { data, loading, error } = useGetWalletBalance({ contractorId: id });

  if (loading) return <div>Loading</div>;

  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

  if (data != null && !data.isSuccess) {
    return data.errors.map((error) => <div>{error}</div>);
  }

  return (
    <Link
      component={RouterLink}
      to={`/contractor-points-statement/${id}`}
    >{`My Wallet: ${data?.value} points`}</Link>
  );
}
