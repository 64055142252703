import React from "react";
import { Panel, PanelActions, PanelHeading, Button, PanelRow } from "@backslashbuild/pp-ui";
import { usePost } from "./api";
import { publish } from "./pubSub";

export default function CreditInvoiceForm({ invoice, onClose }) {
  const createCreditNote = usePost();

  async function handleSubmit() {
    createCreditNote(`${invoice}/credit`).then((res) => {
      if (res.isSuccess) {
        publish("notification", "Created successfully");
        onClose();
      } else {
        publish(
          "notification",
          <>
            <ul>
              {res?.errors?.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </>
        );
      }
    });
  }

  return (
    <>
      <Panel>
        <PanelHeading>Create Credit Note</PanelHeading>
        <PanelRow>This will create a credit note for the selected invoice.</PanelRow>
        <PanelActions>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSubmit}>Create</Button>
        </PanelActions>
      </Panel>
    </>
  );
}
