import React from "react";

import LockPayslipsButton from "./LockPayslipsButton";
import LockPayslipsDialog from "./LockPayslipsDialog";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { isTextNullOrEmpty } from "./utils";

import { PageHeadingAreaSpacer } from "@backslashbuild/pp-ui";

export default function LockToBACSRunSection() {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const [state, setState] = React.useState(params);
  const [showDialog, setShowDialog] = React.useState(false);

  React.useEffect(() => {
    setState(queryString.parse(location.search));
  }, [location]);

  return (
    <>
      {isTextNullOrEmpty(state?.bacsRunId) && (
        <>
          <LockPayslipsButton onClick={() => setShowDialog(true)} />
          <PageHeadingAreaSpacer />
          <LockPayslipsDialog
            open={showDialog}
            onClose={() => {
              setShowDialog(false);
            }}
          />
        </>
      )}
    </>
  );
}
