import React from "react";
import { Panel, PanelHeading, Pill, PanelRow, PanelSpacer } from "@backslashbuild/pp-ui";

function PayslipLogPanel({ payslip }) {
  return (
    <Panel>
      <PanelHeading>Log</PanelHeading>
      <PanelSpacer />
      {payslip.errors.map((error) => (
        <PanelRow>
          <Pill status="error">{error}</Pill>
        </PanelRow>
      ))}
      {payslip.warnings.map((warning) => (
        <PanelRow>
          <Pill status="warning">{warning}</Pill>
        </PanelRow>
      ))}
      {payslip.log.split(/\r?\n/).map((line) =>
        line ? (
          <PanelRow small>
            <Pill status="info">{line}</Pill>
          </PanelRow>
        ) : null
      )}
    </Panel>
  );
}

export default PayslipLogPanel;
