import React from "react";
import { DateTime, Interval } from "luxon";
import clsx from "clsx";

import { makeStyles } from "@backslashbuild/pp-ui";
import generateDates from "./generateDates";

const useStyles = makeStyles({
  root: {
    padding: 20,
  },
  subtitle: {
    padding: "16px 0 8px",
    fontSize: 10,
    fontWeight: "bold",
  },
  days: {
    display: "flex",
    "& > :not(:first-child)": {
      marginLeft: 8,
    },
  },
  day: {
    background: "#dadce0",
    padding: 2,
    width: 60,
    height: 60,
    borderRadius: 4,
    padding: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    "& small": {
      fontSize: 10,
    },
    fontSize: 11,
    lineHeight: "12px",
  },
  today: {
    // background: "#1D979D",
    // color: "white",
    // color: "#1D979D",
    background: "#1d979d33",
    border: "1px solid #1D979D",
  },
  weekNumber: {
    // color: "#1D979D",
    background: "#1d979d33",
    padding: "8px 16px",
    borderRadius: 4,
    display: "inline-block",
    border: "1px solid #1D979D",
  },
});

function Day({ d }) {
  const classes = useStyles();

  const now = DateTime.local();
  return (
    <div
      className={clsx(classes.day, {
        [classes.today]: now.startOf("day").equals(d.startOf("day")),
      })}
    >
      <div>
        <small>{d.toFormat("EEE").toUpperCase()}</small>
        <br />
        <br />
        <strong>{d.toFormat("dd")}</strong> {d.toFormat("MMM").toUpperCase()}{" "}
        <small>{d.toFormat("yyyy").toUpperCase()}</small>
      </div>
    </div>
  );
}

function TaxWeekDetailsDisplay({ value }) {
  const classes = useStyles();

  const now = DateTime.local();
  const workingDates = generateDates(value.workingStartDate, value.workingEndDate);
  const payrollDates = generateDates(value.payrollStartDate, value.payrollEndDate);

  return (
    <div className={classes.root}>
      <div className={classes.subtitle}>WEEK NUMBER</div>

      <div className={classes.weekNumber}>{value.weekNumber}</div>

      <div>
        <div className={classes.subtitle}>WORK WEEK</div>
        <div className={classes.days}>
          {workingDates.map((d) => (
            <Day d={d} />
          ))}
        </div>
      </div>
      <div>
        <div className={classes.subtitle}>PAYROLL WEEK</div>
        <div className={classes.days}>
          {payrollDates.map((d) => (
            <Day d={d} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default TaxWeekDetailsDisplay;
