import { FormTitle, PageLoading, Panel, PanelHeading, PanelSpacer } from "@backslashbuild/pp-ui";
import React from "react";
import { useGetFields, useUpdateField } from "./api";
import FormField from "./FormField";
import { buildQuery, isTextNullOrEmpty } from "./utils";

const defaultQuery = { take: 50, isActive: true, pattern: "a" };

export default function AgencyForm({ id }) {
  const [query, setQuery] = React.useState(buildQuery(defaultQuery));
  const { data, loading, error } = useGetFields({ id, formName: "agency", queryParams: query });
  const updateField = useUpdateField({ id, formName: "" });

  if (loading) return <PageLoading />;

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    return data.value
      .filter((field) => !field.hidden)
      .map((field) => (
        <FormField
          key={field.key}
          field={field}
          onSave={getOnSaveHandler(field.key)}
          onQuery={getOnQueryHandler}
        />
      ));
  }

  function getOnSaveHandler(key) {
    return (value) => {
      updateField({ key, value });
    };
  }

  function getOnQueryHandler(value) {
    const dict = isTextNullOrEmpty(value) ? defaultQuery : { pattern: value };
    const query = buildQuery(dict);
    setQuery(query);
  }

  return (
    <Panel>
      <PanelHeading>Agency Details</PanelHeading>
      <PanelSpacer />
      {handleRender()}
      <PanelSpacer />
    </Panel>
  );
}
