import React from "react";
import { Button, PanelActions, PanelDialog, PanelHeading } from "@backslashbuild/pp-ui";
import { useParams } from "react-router-dom";
import { usePost, invalidateContributions, useGetContributionScheduleExportRow } from "./api";
import { publish } from "../../pubSub";
import {
  BoldText,
  HorizontalRule,
  PageLoading,
  PanelRow,
} from "@backslashbuild/pp-ui/dist/index.es";

export default function EditContributionScheduleExportRowDialog({ id, onClose }) {
  const { data, loading, error, queryId, refetch } = useGetContributionScheduleExportRow(id);

  const updateRow = usePost(`contributions/schedule-export-rows/ignore-row`);

  function handleSubmit() {
    try {
      updateRow({ contributionScheduleExportRowId: id }).then((res) => {
        if (res?.isSuccess) {
          publish("notification", "Successful. This row will be ignored.");
          onClose();
        } else {
          publish("notification", "Unable to edit row.");
        }
      });
      invalidateContributions();
      refetch();
    } catch {
      publish("notification", "Unable to edit row.");
    }
  }

  function handleRender() {
    if (loading) return <PageLoading />;

    if (error) return <PanelRow>Unable to load row</PanelRow>;

    return (
      <>
        {data?.value?.errors?.length > 0 && (
          <>
            <PanelRow>
              <BoldText>This row has the following errors:</BoldText>
            </PanelRow>
            {data?.value?.errors?.map((error) => (
              <PanelRow>{error}</PanelRow>
            ))}
            <HorizontalRule />
          </>
        )}
        {data?.value?.markedAsSuccessful && (
          <PanelRow>This row has been marked as successful</PanelRow>
        )}
        {data?.value?.rowIsIgnored && (
          <PanelRow>
            <BoldText>This row has been marked as ignored</BoldText>
          </PanelRow>
        )}
      </>
    );
  }

  return (
    <>
      <PanelDialog onClose={onClose} open={id}>
        <PanelHeading>Update Export Row</PanelHeading>
        {handleRender()}
        <PanelActions>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit} disabled={data?.value?.rowIsIgnored}>
            Mark As Ignore
          </Button>
        </PanelActions>
      </PanelDialog>
    </>
  );
}
