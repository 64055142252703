import React from "react";
import { Content, PageHeadingArea, PageTitle, PageHeadingAreaSpacer } from "@backslashbuild/pp-ui";
import EmploymentsTable from "../modules/nest/EmploymentsTable";
import EmploymentsFiltering from "../modules/nest/EmploymentsFiltering";

export default function NESTEmployments() {
  return (
    <Content>
      <PageHeadingArea>
        <PageTitle>NEST Employments</PageTitle>
        <PageHeadingAreaSpacer grow />
        <EmploymentsFiltering />
      </PageHeadingArea>
      <EmploymentsTable />
    </Content>
  );
}
