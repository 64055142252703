import {
  Panel,
  PanelActions,
  Button,
  PanelRow,
  Label,
  PanelDialog,
  PanelHeading,
} from "@backslashbuild/pp-ui";
import { BoldText } from "@backslashbuild/pp-ui/dist/index.es";
import React from "react";
import { useGet, usePost } from "./api";
import ContractTemplatePicker from "./ContractTemplatePicker";
import { publish } from "./pubSub";

export default function ContractTypeForm({ name, onClose }) {
  const { data, loading, error } = useGet(`contract-type/${name}`);
  const updateTrigger = usePost(`contract-type/${name}/update`);
  const [edits, setEdits] = React.useState({
    fK_TemplateId: null,
  });
  const [dialogOpen, setDialogOpen] = React.useState(false);

  function handleRender() {
    if (loading || !data) return <>Loading...</>;

    if (data?.errors?.length > 0)
      return (
        <>
          {data?.errors?.map((error) => {
            return <>{error}</>;
          })}
        </>
      );

    return (
      <>
        <PanelRow>
          <Label label="Contract">
            <div>{data?.value?.name}</div>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Template">
            <ContractTemplatePicker
              value={edits.fK_TemplateId ?? data?.value?.fK_TemplateId}
              onChange={(e) => setEdits({ ...edits, fK_TemplateId: e.target.value })}
            />
          </Label>
        </PanelRow>
      </>
    );
  }

  function handleSave() {
    updateTrigger(edits)
      .then((res) => {
        if (res?.isSuccess) {
          publish("notification", "Contract type updated.");
        }
      })
      .finally(() => {
        onClose();
      });
  }

  return (
    <>
      <Panel>
        {handleRender()}
        <PanelActions>
          <Button variant="secondary" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              setDialogOpen(true);
            }}
          >
            Save
          </Button>
        </PanelActions>
      </Panel>
      <PanelDialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <PanelHeading>Save template</PanelHeading>
        <PanelRow>
          <BoldText>
            Updating a template will cause any existing contracts which use this template to be
            cancelled and new contracts to be generated.
          </BoldText>
        </PanelRow>
        <PanelActions>
          <Button variant="secondary" onClick={() => setDialogOpen(false)}>
            Cancel
          </Button>
          <Button onClick={() => handleSave()}>Save and update</Button>
        </PanelActions>
      </PanelDialog>
    </>
  );
}
