import React from "react";

import { useGet } from "./api";

function PayRunTitle({ id }) {
  const { data, loading, errors } = useGet(`payruns/${id}`);

  const payRun = data?.value;

  return <span>Pay run {payRun?.payRunNumber}</span>;
}

export default PayRunTitle;
