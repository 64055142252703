import React from "react";
import { usePostApproveDraft } from "./api";
import { useHistory } from "react-router-dom";
import { publish } from "./pubSub";

import { PanelDialog, PanelActions, Button, PanelRow, PanelHeading } from "@backslashbuild/pp-ui";

export default function ApproveDraftDialog({ open, onClose, draftId }) {
  const history = useHistory();
  const post = usePostApproveDraft(draftId);

  function handleConfirm() {
    post().then((res) => {
      if (res.isSuccess) {
        publish(
          "notification",
          "Attempt Successful! The draft has been closed and the preparation protocol has begun."
        );
        history.push("/hmrc-fps");
      } else {
        publish(
          "notification",
          <div>
            Attempt Failed
            <ul>
              {res?.errors?.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </div>
        );
      }
    });
  }

  return (
    <PanelDialog open={open} onClose={onClose}>
      <PanelHeading>Approve Draft FPS</PanelHeading>
      <PanelRow>
        <p>
          Click on 'Confirm' if the draft data is ready to be reported to HMRC. Once approval is
          given, the background services will begin the preparation protocol. This involves
          converting the draft data into a format acceptable by HMRC. The newly converted data is
          automatically submitted to HMRC.
        </p>
        <p>
          You will be able to check the status of the submission on the FPS page. The preparation
          protocol requires a few minutes to complete.
        </p>
      </PanelRow>
      <PanelActions>
        <Button variant="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleConfirm();
            onClose();
          }}
        >
          Confirm
        </Button>
      </PanelActions>
    </PanelDialog>
  );
}
