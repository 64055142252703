import { usePost } from "./api";
import { publish } from "../../pubSub";
import getApiUrl from "../../getApiUrl";

const apiUrl = getApiUrl();

function useRedirectToSelectedInvoices(path) {
  const process = usePost();

  async function handleSubmit() {
    const result = await process(`documents/sign-request-selected?${path}`);

    if (result?.isSuccess) {
      var { token, fileName } = result.value;

      window.location = `${apiUrl}/invoicing/documents/view-selected/${fileName}?${path}&token=${token}`;
    } else {
      publish("notification", `Error - could not open pdf.`);
    }
  }

  return handleSubmit;
}

export default useRedirectToSelectedInvoices;
