import {
  CheckBox,
  FormTitle,
  PageLoading,
  Panel,
  PanelHeading,
  PanelSpacer,
} from "@backslashbuild/pp-ui";
import { Label, PanelRow } from "@backslashbuild/pp-ui/dist/index.es";
import React from "react";
import { useGetFields, useUpdateField } from "./api";
import FormField from "./FormField";
import { isTextNullOrEmpty } from "./utils";

export default function BankDetailsForm({ id }) {
  const { data, loading, error } = useGetFields({ id, formName: "bank-details" });
  const [showInternationalDetailsFields, setShowInternationalDetailsFields] = React.useState(false);
  const updateField = useUpdateField({ id, formName: "" });

  if (loading) return <PageLoading />;

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    return data.value.map((field) => (
      <>
        {field?.key == "Iban" || field?.key == "BicSwift" ? (
          <>
            {showInternationalDetailsFields && (
              <FormField key={field.key} field={field} onSave={getOnSaveHandler(field.key)} />
            )}
          </>
        ) : field?.key == "BSRollNumber" ? (
          <>
            <FormField key={field.key} field={field} onSave={getOnSaveHandler(field.key)} />
            <PanelRow>
              <Label>
                <CheckBox
                  checked={showInternationalDetailsFields}
                  onChange={() =>
                    setShowInternationalDetailsFields(!showInternationalDetailsFields)
                  }
                  label="International details"
                />
              </Label>
            </PanelRow>
          </>
        ) : (
          <FormField key={field.key} field={field} onSave={getOnSaveHandler(field.key)} />
        )}
      </>
    ));
  }

  function getOnSaveHandler(key) {
    return (value) => {
      updateField({ key, value });
    };
  }

  return (
    <Panel>
      <PanelHeading>Bank Details</PanelHeading>
      <PanelSpacer />
      {handleRender()}
      <PanelSpacer />
    </Panel>
  );
}
