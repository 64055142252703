import { PanelHeading, PageLoading, Panel, PanelSpacer, PanelRow } from "@backslashbuild/pp-ui";
import React from "react";
import { useGetFields, useUpdateField } from "./api";
import FormField from "./FormField";
import { isTextNullOrEmpty, buildQuery } from "./utils";
import AddressPicker from "./AddressPicker";
import { Label } from "@backslashbuild/pp-ui/dist/index.es";

const defaultPattern = { pattern: "a" };

export default function ContractorForm({ id }) {
  const [query, setQuery] = React.useState(buildQuery(defaultPattern));
  const { data, loading, error } = useGetFields({ id, formName: "contractor", queryParams: query });
  const updateField = useUpdateField({ id, formName: "" });

  if (loading) return <PageLoading />;

  const hintHandlers = {
    address_lookup_picker: () => ({
      customSuffix: <AddressPicker id={id} />,
    }),
  };

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    return data?.value
      .filter(
        (field) =>
          field?.key != "IsContactByPhone" &&
          field?.key != "IsContactByMobile" &&
          field?.key != "IsContactByText" &&
          field?.key != "IsContactByEmail" &&
          field?.key != "Notes"
      )
      ?.map((field) => (
        <FormField
          key={field.key}
          field={field}
          onSave={getOnSaveHandler(field.key)}
          onQuery={getOnQueryHandler}
          hintHandlers={hintHandlers}
        />
      ));
  }

  function handleRenderContactMethods() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    return (
      <>
        <PanelRow>
          <Label label={"Contact methods"}>
            {data?.value
              ?.filter(
                (field) =>
                  field?.key == "IsContactByPhone" ||
                  field?.key == "IsContactByMobile" ||
                  field?.key == "IsContactByText" ||
                  field?.key == "IsContactByEmail"
              )
              .map((field) => (
                <FormField
                  key={field.key}
                  field={field}
                  onSave={getOnSaveHandler(field.key)}
                  onQuery={getOnQueryHandler}
                  hintHandlers={hintHandlers}
                  hideLabel
                />
              ))}
          </Label>
        </PanelRow>
      </>
    );
  }

  function handleRenderNotes() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    return (
      <>
        {data?.value
          ?.filter((field) => field?.key == "Notes")
          .map((field) => (
            <FormField
              key={field.key}
              field={field}
              onSave={getOnSaveHandler(field.key)}
              onQuery={getOnQueryHandler}
              hintHandlers={hintHandlers}
            />
          ))}
      </>
    );
  }

  function getOnSaveHandler(key) {
    return (value) => {
      updateField({ key, value });
    };
  }

  function getOnQueryHandler(value) {
    const dict = isTextNullOrEmpty(value) ? defaultPattern : { pattern: value };
    const query = buildQuery(dict);
    setQuery(query);
  }

  return (
    <Panel>
      <PanelHeading>Personal Details</PanelHeading>
      <PanelSpacer />
      {handleRender()}
      {handleRenderContactMethods()}
      {handleRenderNotes()}
      <PanelSpacer />
    </Panel>
  );
}
