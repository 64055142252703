import React from "react";
import { useGet } from "../contractors/api";
import { isTextNullOrEmpty } from "../../utils";
import { Link as RouterLink } from "react-router-dom";
import { Link, Rollover } from "@backslashbuild/pp-ui";
import ContractorBusinessCard from "../contractors/ContractorBusinessCard";
// import { su bscribe, unsubscribe } from "./pubSub";

export default function ContractorProductName({ id, fallback, link, productOnly }) {
  const { data, loading, error, refetch } = useGet(`contractor-products/${id}`, id);

  if (loading) return <>{fallback ?? "Loading..."}</>;

  if (!isTextNullOrEmpty(error)) return <>{String(error)}</>;

  if (data != null && !data?.isSuccess && typeof data?.errors == Array) {
    return data?.errors?.map((error) => <>{error}</> ?? "error");
  }

  const content = productOnly ? (
    <>{data?.value?.accountNumber}</>
  ) : (
    <>
      {data?.value?.accountNumber} {data?.value?.contractor?.firstName}{" "}
      {data?.value?.contractor?.surname}
    </>
  );

  if (link) {
    return (
      <Rollover
        renderAnchor={() => (
          <Link component={RouterLink} to={`/contractor/${data?.value?.contractorUserId}`}>
            {content}
          </Link>
        )}
      >
        <ContractorBusinessCard id={data?.value?.contractorUserId} />
      </Rollover>
    );
  }

  return content;
}
