import React from "react";
import { DateTime, Settings } from "luxon";

Settings.defaultZoneName = "Europe/London";
Settings.defaultLocale = "en-GB";

export function formatCurrency(numeric) {
  if (numeric === 0) return "£0.00";
  var value = Number(numeric);
  return value && !isNaN(value)
    ? "£" + value.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    : "";
}

export function utcToMillis(date) {
  return DateTime.fromISO(date).toMillis();
}

export function utcToLocalText(date, format) {
  if (date === undefined || isTextNullOrEmpty(date)) return "";
  if (isTextNullOrEmpty(format)) format = "dd/MM/yyyy";

  return DateTime.fromISO(date, { zone: "utc" }).toLocal().toFormat(format);
}

export function utcToLocal(date) {
  if (date === undefined) return undefined;

  return DateTime.fromISO(date).toLocal();
}

export function utcToYmd(date) {
  if (date === undefined || isTextNullOrEmpty(date)) return null;

  return DateTime.fromISO(date).toLocal().toFormat("yyyy-MM-dd");
}

export function localToUtcIso(date, format) {
  if (date === undefined) return undefined;
  if (isTextNullOrEmpty(format)) format = "dd/MM/yyyy";

  return DateTime.fromFormat(date, format).toUTC().toISO();
}

export function isTextNullOrEmpty(text) {
  return !Boolean(text);
}

export function textToBoolean(text) {
  let result = null;

  if (!isTextNullOrEmpty(String(text))) {
    result = String(text).toLowerCase() == "true";
  }

  return result;
}

export function trimWhitespace(text) {
  if (!isTextNullOrEmpty(text) && String(text).trim() != "undefined") {
    return String(text).trim();
  }

  return text;
}

export function buildQuery(dict) {
  let beginQuery = true;
  let query = "";

  if (dict != null) {
    Object.keys(dict).map((key) => {
      if (!isTextNullOrEmpty(dict[key])) {
        if (beginQuery) {
          query += "?";
          beginQuery = false;
        } else query += "&";

        query += `${key}=${dict[key]}`;
      }
    });

    return query;
  }
}

// Hook
export default function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

export function useDebounceCallback(value, delay, callback) {
  React.useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        callback(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
}

/**
 * Used to match `RegExp`
 * [syntax characters](http://ecma-international.org/ecma-262/7.0/#sec-patterns).
 */
const reRegExpChar = /[\\^$.*+?()[\]{}|]/g;
const reHasRegExpChar = RegExp(reRegExpChar.source);

/**
 * Escapes the `RegExp` special characters "^", "$", "\", ".", "*", "+",
 * "?", "(", ")", "[", "]", "{", "}", and "|" in `string`.
 *
 * @since 3.0.0
 * @category String
 * @param {string} [string=''] The string to escape.
 * @returns {string} Returns the escaped string.
 * @see escape, escapeRegExp, unescape
 * @example
 *
 * escapeRegExp('[lodash](https://lodash.com/)')
 * // => '\[lodash\]\(https://lodash\.com/\)'
 */
export function escapeRegExp(string) {
  return string && reHasRegExpChar.test(string)
    ? string.replace(reRegExpChar, "\\$&")
    : string || "";
}

export function getHighlightedText(text, highlight) {
  // Split on highlight term and include term into parts, ignore case
  const parts = (text || "").split(new RegExp(`(${escapeRegExp(highlight || "")})`, "gi"));
  return (
    <span>
      {parts.map((part, i) =>
        part.toLowerCase() === (highlight || "").toLowerCase() ? (
          <mark key={i}>{part}</mark>
        ) : (
          <span key={i}>{part}</span>
        )
      )}
    </span>
  );
}
