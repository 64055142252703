import React from "react";
import QueryFiltering from "../../containers/QueryFiltering";
import { PanelRow, Label, TextInput, RadioGroup, Radio } from "@backslashbuild/pp-ui";

function AgenciesFiltering() {
  return (
    <QueryFiltering
      searchStringKey="search"
      getTagsFromState={(state) => {
        const tags = [];
        if (state.isProspect === "true") tags.push({ key: "Lead", value: "Only" });
        if (state.isProspect === "false") tags.push({ key: "Lead", value: "Exclude" });
        if (state.isActive === "true") tags.push({ key: "Active", value: "Only" });
        if (state.isActive === "false") tags.push({ key: "Active", value: "Exclude" });
        if (state.isSuspect === "true") tags.push({ key: "Suspect", value: "Only" });
        if (state.isSuspect === "false") tags.push({ key: "Suspect", value: "Exclude" });
        if (state.isPayOnHold === "true") tags.push({ key: "On Hold", value: "Only" });
        if (state.isPayOnHold === "false") tags.push({ key: "On Hold", value: "Exclude" });
        return tags;
      }}
      getNextStateFromDeleteTag={(state, key) => {
        if (key === "Lead") return { ...state, isProspect: undefined };
        if (key === "Active") return { ...state, isActive: undefined };
        if (key === "Suspect") return { ...state, isSuspect: undefined };
        if (key === "On Hold") return { ...state, isPayOnHold: undefined };
        return state;
      }}
      renderForm={(state, setState) => (
        <>
          <PanelRow>
            <Label label="Search string">
              <TextInput
                value={state.search}
                onChange={(e) => setState({ ...state, search: e.target.value })}
              />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Agency Status">
              <RadioGroup
                value={state.isProspect}
                onChange={(e) => setState({ ...state, isProspect: e.target.value })}
              >
                <Radio value="" label="Any" />
                <Radio value={"true"} label="Lead" />
                <Radio value={"false"} label="Live" />
              </RadioGroup>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Active Status">
              <RadioGroup
                value={state.isActive}
                onChange={(e) => setState({ ...state, isActive: e.target.value })}
              >
                <Radio value="" label="Any" />
                <Radio value={"true"} label="Active" />
                <Radio value={"false"} label="Inactive" />
              </RadioGroup>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Suspect Status">
              <RadioGroup
                value={state.isSuspect}
                onChange={(e) => setState({ ...state, isSuspect: e.target.value })}
              >
                <Radio value="" label="Any" />
                <Radio value={"true"} label="Suspect" />
                <Radio value={"false"} label="Not Suspect" />
              </RadioGroup>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Pay Status">
              <RadioGroup
                value={state.isPayOnHold}
                onChange={(e) => setState({ ...state, isPayOnHold: e.target.value })}
              >
                <Radio value="" label="Any" />
                <Radio value={"true"} label="On Hold" />
                <Radio value={"false"} label="Not On Hold" />
              </RadioGroup>
            </Label>
          </PanelRow>
        </>
      )}
    />
  );
}

export default AgenciesFiltering;
