import {
  Label,
  Panel,
  StatusIndicator,
  PanelDialog,
  Button,
  PanelHeading,
  PanelActions,
} from "@backslashbuild/pp-ui";
import React from "react";
import { useGet, usePost } from "./api";
import { isTextNullOrEmpty } from "../contractors/utils";
import { PanelRow } from "@backslashbuild/pp-ui/dist/index.es";
import { publish } from "./pubSub";

export default function EmploymentIgnoredStatus({ accountNumber, open, onClose = () => {} }) {
  let [dialogOpen, setDialogOpen] = React.useState(open);
  const { data, loading, error, refetch } = useGet("employments/employment/" + accountNumber);

  const update = usePost("employments/update-ignored-status");

  const handleClose = () => {
    setDialogOpen(false);
    onClose();
    refetch();
  };

  async function handleSubmit(isIgnored) {
    try {
      const response = await update({ accountNumber, isIgnored });

      if (response.isSuccess) {
        publish("notification", "Updated successfully");
        handleClose();
      } else {
        publish(
          "notification",
          <div>
            Unable to update employment
            <ul>
              {response.errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </div>
        );
      }
    } catch (e) {
      console.error(e, "error updating");
      publish("notification", "A network error occured.");
    }
  }

  if (loading) return <div>Loading</div>;

  if (!isTextNullOrEmpty(error))
    return <Label long label="Failed to load employment ignored status" />;

  if (data != null && !data.isSuccess) {
    return data.errors.map((error) => <div>{error}</div>);
  }

  return (
    <>
      <div onClick={() => setDialogOpen(true)}>
        <Label label="Ignored">
          <StatusIndicator value={data?.value?.isIgnored} />
        </Label>
      </div>
      <PanelDialog open={dialogOpen || open} onClose={handleClose}>
        <Panel>
          <PanelHeading>Update NEST Enrollment Status</PanelHeading>
          <PanelRow>
            {`Current status for this employment: ${
              data?.value?.isIgnored
                ? "Ignored from NEST calculations and schedules creation"
                : "Included in NEST calculations and schedules creation"
            }`}
          </PanelRow>
          <PanelActions>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            {data?.value?.isIgnored ? (
              <Button onClick={() => handleSubmit(false)} disabled={!data?.value?.isIgnored}>
                Do not ignore employment
              </Button>
            ) : (
              <Button onClick={() => handleSubmit(true)} disabled={data?.value?.isIgnored}>
                Ignore this employment
              </Button>
            )}
          </PanelActions>
        </Panel>
      </PanelDialog>
    </>
  );
}
