import React from "react";
import { useLocation } from "react-router-dom";
import {
  TableRow,
  Table,
  TableCell,
  TableHeaderColumn,
  TableFooter,
  TableBody,
  BoldText,
} from "@backslashbuild/pp-ui";
import { Link as RouterLink } from "react-router-dom";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";
import { useGet } from "./api";
import queryString from "query-string";
import Paginator from "../../containers/Paginator";
import { StatusIndicator, TableHeader } from "@backslashbuild/pp-ui/dist/index.es";
import { utcToYmd } from "./utils";

const c = {
  employee: 200,
  niNumber: 100,
  hasBeenEnrolled: 100,
  nestStartDate: 100,
  nestStopDate: 100,
  nestOptOutDate: 100,
  groupOverride: 100,
  niNumberOverride: 100,
  accountNumberOverride: 100,
  isIgnored: 80,
};

const totalWidth = Object.keys(c).reduce((total, key) => (total += c[key]), 0);

function PureTable({ data, queryId }) {
  return React.useMemo(() =>
    data.value.items.map(
      (employment) => (
        <>
          <TableRow
            key={employment.accountNumber}
            component={RouterLink}
            to={`/nest-employment/${employment.accountNumber}`}
          >
            <TableCell width={c.employee}>
              {`${employment.title} ${employment?.forename} ${employment?.surname} (${employment?.accountNumber})`}
            </TableCell>
            <TableCell width={c.niNumber}>{employment.niNumber}</TableCell>
            <TableCell width={c.hasBeenEnrolled}>
              <StatusIndicator value={employment.hasBeenEnrolled} />
            </TableCell>
            <TableCell width={c.nestStartDate}>{utcToYmd(employment.nestStartDate)}</TableCell>
            <TableCell width={c.nestStopDate}>{utcToYmd(employment.nestStopDate)}</TableCell>
            <TableCell width={c.nestOptOutDate}>{utcToYmd(employment.nestOptOutDate)}</TableCell>
            <TableCell width={c.groupOverride}>{employment.groupOverride}</TableCell>
            <TableCell width={c.niNumberOverride}>{employment.niNumberOverride}</TableCell>
            <TableCell width={c.accountNumberOverride}>
              {employment.accountNumberOverride}
            </TableCell>
            <TableCell width={c.isIgnored}>
              <StatusIndicator value={employment.isIgnored} />
            </TableCell>
          </TableRow>
        </>
      ),
      [queryId]
    )
  );
}

function TableSkeleton() {
  return (
    <TableRow>
      <TableCell width={c.employee}>-</TableCell>
      <TableCell width={c.niNumber}>-</TableCell>
      <TableCell width={c.hasBeenEnrolled}>-</TableCell>
      <TableCell width={c.nestStartDate}>-</TableCell>
      <TableCell width={c.nestStopDate}>-</TableCell>
      <TableCell width={c.nestOptOutDate}>-</TableCell>
      <TableCell width={c.groupOverride}>-</TableCell>
      <TableCell width={c.niNumberOverride}>-</TableCell>
      <TableCell width={c.accountNumberOverride}>-</TableCell>
      <TableCell width={c.isIgnored}>-</TableCell>
    </TableRow>
  );
}

function formatYesNo(yesno) {
  if (yesno === "yes") return true;
  if (yesno === "no") return false;
  return undefined;
}

function EmploymentsTable({ useStatePaginator, take = 50 }) {
  const location = useLocation();
  const queryPagingString = usePagination(location.search);
  const [statePaging, setStatePaging] = React.useState({ skip: 0, take });

  const queryPaging = queryString.parse(queryPagingString);

  const stringifiedQuery = queryString.stringify({
    ...(useStatePaginator
      ? statePaging
      : {
          ...queryPaging,
          needsEnrolling: queryPaging.needsEnrolling,
          enrolled: formatYesNo(queryPaging.enrolled),
          isActive: formatYesNo(queryPaging.isActive),
        }),
  });

  const { data, loading, error, queryId } = useGet(`employments?${stringifiedQuery}`);

  const isLoaded = Boolean(data?.value);

  return (
    <Table fullPageTable={true}>
      <TableHeader>
        <TableHeaderColumn width={c.employee}>Employee</TableHeaderColumn>
        <TableHeaderColumn width={c.niNumber}>NI Number</TableHeaderColumn>
        <TableHeaderColumn width={c.hasBeenEnrolled}>Enrolled into NEST</TableHeaderColumn>
        <TableHeaderColumn width={c.nestStartDate}>NEST Start Date</TableHeaderColumn>
        <TableHeaderColumn width={c.nestStopDate}>NEST Stop Date</TableHeaderColumn>
        <TableHeaderColumn width={c.nestOptOutDate}>NEST Opt Out Date</TableHeaderColumn>
        <TableHeaderColumn width={c.groupOverride}>Group Override</TableHeaderColumn>
        <TableHeaderColumn width={c.niNumberOverride}>NI Number Override</TableHeaderColumn>
        <TableHeaderColumn width={c.accountNumberOverride}>
          Account Number Override
        </TableHeaderColumn>
        <TableHeaderColumn width={c.isIgnored}>Ignored</TableHeaderColumn>
      </TableHeader>
      <TableBody fullPageTable={true} footerHeight={TableFooter.height}>
        {isLoaded ? (
          <PureTable data={data} queryId={queryId} />
        ) : data?.errors ? (
          data?.errors.map((error) => <div>{error}</div>)
        ) : (
          <TableSkeleton />
        )}
      </TableBody>
      <TableFooter>
        {loading && <BoldText>Loading...</BoldText>}
        {error && <BoldText>A network error occured.</BoldText>}
        {useStatePaginator ? (
          <Paginator
            itemsTotal={data?.value?.itemsTotal}
            itemsRetrieved={data?.value?.itemsRetrieved}
            skip={statePaging.skip}
            take={statePaging.take}
            onPrev={() =>
              setStatePaging({
                ...statePaging,
                skip: Math.max(0, statePaging.skip - statePaging.take),
              })
            }
            onNext={() =>
              setStatePaging({ ...statePaging, skip: statePaging.skip + statePaging.take })
            }
            setStatePaging
          />
        ) : (
          <QueryPaginator
            itemsTotal={data?.value?.itemsTotal}
            itemsRetrieved={data?.value?.itemsRetrieved}
          />
        )}
      </TableFooter>
    </Table>
  );
}

export default EmploymentsTable;
