import React from "react";
import QueryFiltering from "../../containers/QueryFiltering";
import { PanelRow, Label, TextInput } from "@backslashbuild/pp-ui";

function UsersFiltering() {
  return (
    <QueryFiltering
      searchStringKey="name"
      getTagsFromState={(state) => {
        const tags = [];
        if (state.peopleId) tags.push({ key: "number", value: state.peopleId });
        if (state.nino) tags.push({ key: "nino", value: state.nino });
        return tags;
      }}
      getNextStateFromDeleteTag={(state, key) => {
        if (key === "number") return { ...state, peopleId: undefined };
        if (key === "nino") return { ...state, nino: undefined };
        // if (key === "week") return { ...state, weekNumber: undefined };
        return state;
      }}
      renderForm={(state, setState) => (
        <>
          <PanelRow>
            <Label label="Name">
              <TextInput value={state.search} onChange={(e) => setState({ ...state, name: e.target.value })} />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Contractor Number">
              <TextInput value={state.search} onChange={(e) => setState({ ...state, peopleId: e.target.value })} />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="National Insurance Number">
              <TextInput value={state.search} onChange={(e) => setState({ ...state, nino: e.target.value })} />
            </Label>
          </PanelRow>
        </>
      )}
    />
  );
}

export default UsersFiltering;
