import React from "react";
import {
  Button,
  PanelDialog,
  PanelHeading,
  PanelRow,
  PanelSpacer,
  PanelActions,
  BoldText,
} from "@backslashbuild/pp-ui";
import { useProcessSchedule } from "./api";
import { publish } from "../../pubSub";
import { useHistory } from "react-router-dom";

function ProcessScheduleButton({ id }) {
  const history = useHistory();

  // you have to query to see if there are any currently staged pay runs and then disable the button.
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const process = useProcessSchedule();

  async function handleSubmit() {
    try {
      const response = await process({ scheduleId: id });

      if (response.isSuccess) {
        publish("notification", "Processed");
      } else {
        publish(
          "notification",
          <>
            Unable to process
            <ul>
              {response.errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </>
        );
      }

      setDialogOpen(false);
    } catch (e) {
      console.error(e, "error processing");
      publish("notification", "A network error occured.");
    }
  }

  return (
    <>
      <Button large onClick={() => setDialogOpen(true)}>
        Process Schedule
      </Button>
      <PanelDialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <PanelHeading>Process Schedule</PanelHeading>
        <PanelSpacer large />
        <PanelRow>
          <BoldText>Groupings will be made for the rows and totals will be calculated.</BoldText>
        </PanelRow>
        <PanelSpacer large />
        <PanelActions>
          <Button variant="secondary" onClick={() => setDialogOpen(false)}>
            No, cancel
          </Button>
          <Button onClick={handleSubmit}>Yes, Process Schedule</Button>
        </PanelActions>
        <PanelSpacer large />
      </PanelDialog>
    </>
  );
}

export default ProcessScheduleButton;
