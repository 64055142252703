import React from "react";

import { Content } from "@backslashbuild/pp-ui";

import AdvancesTable from "../modules/advances/AdvancesTable";

function AdvancesOutstanding() {
  return (
    <Content>
      <AdvancesTable />
    </Content>
  );
}

export default AdvancesOutstanding;
