import React from "react";
import {
  TableRow,
  Table,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  PanelActions,
  Panel,
  Button,
  PanelColumnSet,
} from "@backslashbuild/pp-ui";
import { useGet, useGetSettingsSnapshots, usePost } from "./api";
import { utcToYmd } from "../contractors/utils";
import {
  Label,
  PageLoading,
  PanelColumn,
  Link,
  PanelHeading,
  PanelRow,
  PanelSubheading,
  PanelDialog,
} from "@backslashbuild/pp-ui/dist/index.es";
import ObjectMap from "../../components/ObjectMap";
import { publish } from "../../pubSub";

function EmploymentPanel({ employmentId }) {
  const [syncAll, setSyncAll] = React.useState(null);
  const [syncCurrent, setSyncCurrent] = React.useState(null);

  const { data, loading, error, queryId } = useGet(`employments/employment/${employmentId}`);
  const sync = usePost("employments/sync");

  const contractorProductIds = [employmentId];

  function handleSyncCurrentEmployment() {
    sync({ contractorProductIds }).then((res) => {
      if (res?.isSuccess) {
        publish("notification", "Synchronisation triggered");
        setSyncCurrent(false);
      } else {
        publish("notification", "Unable trigger a synchronisation");
      }
    });
  }

  function handleSyncAllEmployments() {
    sync({ contractorProductIds: [] }).then((res) => {
      if (res?.isSuccess) {
        publish("notification", "Synchronisation triggered");
        setSyncCurrent(false);
      } else {
        publish("notification", "Unable trigger a synchronisation");
      }
    });
  }

  function handleRender() {
    if (loading) return <PageLoading />;

    if (error) return <PanelRow>Unable to load employment snapshot</PanelRow>;

    if (data?.error) return <PanelRow>This contractor product has not been synchronised.</PanelRow>;

    if (data?.isSuccess) {
      return (
        <>
          <PanelRow>
            <Label label="Company Id">{data?.value?.companyId}</Label>
          </PanelRow>
          <PanelRow>
            <Label label="Employee Name">{data?.value?.employeeName}</Label>
          </PanelRow>
          <PanelRow>
            <Label label="Employee Number">{data?.value?.employeeNumber}</Label>
          </PanelRow>
          <PanelRow>
            <Label label="Pay frequency">{data?.value?.payFrequency}</Label>
          </PanelRow>
        </>
      );
    }
  }

  return (
    <>
      <Panel maxWidth={500}>
        <PanelHeading>Employment Snapshot</PanelHeading>
        {handleRender()}
        <PanelSubheading>SYNCHRONISE ACTIONS</PanelSubheading>
        <PanelActions>
          <Button onClick={() => setSyncCurrent(true)}>Synchronise Current Employment</Button>
          <Button onClick={() => setSyncAll(true)}>Synchronise All Employments</Button>
        </PanelActions>
      </Panel>
      <PanelDialog open={syncCurrent} onClose={() => setSyncCurrent(false)}>
        <Panel>
          <PanelHeading>Synchronise Current Employment</PanelHeading>
          <PanelRow>
            This will sychronise and create settings snapshots for the current employment.
          </PanelRow>
          <PanelActions>
            <Button variant="secondary" onClick={() => setSyncCurrent(false)}>
              Cancel
            </Button>
            <Button onClick={handleSyncCurrentEmployment}>Synchronise</Button>
          </PanelActions>
        </Panel>
      </PanelDialog>
      <PanelDialog open={syncAll} onClose={() => setSyncAll(false)}>
        <Panel>
          <PanelHeading>Synchronise All Employments</PanelHeading>
          <PanelRow>
            This will sychronise and create settings snapshots for all employment records.
          </PanelRow>
          <PanelActions>
            <Button variant="secondary" onClick={() => setSyncAll(false)}>
              Cancel
            </Button>
            <Button onClick={handleSyncAllEmployments}>Synchronise</Button>
          </PanelActions>
        </Panel>
      </PanelDialog>
    </>
  );
}

function SettingsSnapshotsTable({ employmentId, setSettingsSnapshotId }) {
  const { data, loading, error, queryId } = useGetSettingsSnapshots({ employmentId });

  console.log("settings snapshots table", data);

  return (
    <Panel maxWidth={500}>
      <Table maxWidth={500}>
        <TableHeader>
          <TableHeaderColumn>Settings Snapshots</TableHeaderColumn>
        </TableHeader>
        <TableHeader>
          <TableHeaderColumn>Date created</TableHeaderColumn>
        </TableHeader>
        {data?.value?.errors && (
          <div>Unable to retrieve settings snapshots for this contractor product.</div>
        )}
        {data?.value?.itsmsTotal == 0 && (
          <TableRow>
            No settings snapshots have been synchronised for this contractor product.
          </TableRow>
        )}
        {data?.value?.items?.map((x, i) => (
          <TableRow key={i} onClick={() => setSettingsSnapshotId(x.id)}>
            <TableCell width={450}>
              <small>{utcToYmd(x.dateCreated)}</small>
            </TableCell>

            <TableCell width={50}>
              <small>
                <Link onClick={() => setSettingsSnapshotId(x.id)}>View</Link>
              </small>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </Panel>
  );
}

function SettingsSnapshotPanel({ settingsSnapshotId }) {
  const { data, loading, error, queryId } = useGet(
    `settings-snapshots/settings-snapshot/${settingsSnapshotId}`
  );

  console.log("settings snapshot", data);

  function handleRender() {
    if (!settingsSnapshotId) {
      return <PanelRow>Select a settings snapshot to view its details</PanelRow>;
    }

    if (loading) return <PageLoading />;

    if (error) return <PanelRow>Unable to load settings snapshot</PanelRow>;

    return <ObjectMap data={data?.value} />;
  }

  return (
    <Panel grow maxWidth={750}>
      <PanelHeading>Settings Snapshot Details</PanelHeading>
      {handleRender()}
    </Panel>
  );
}

export default function EmploymentSyncsPanel({ employmentId, onClose }) {
  const [settingsSnapshotId, setSettingsSnapshotId] = React.useState(null);

  return (
    <Panel maxWidth={1250} height={500}>
      <PanelColumnSet>
        <PanelColumn width={500}>
          <EmploymentPanel employmentId={employmentId} />
          <SettingsSnapshotsTable
            employmentId={employmentId}
            setSettingsSnapshotId={setSettingsSnapshotId}
          />
        </PanelColumn>
        <PanelColumn width={750}>
          <SettingsSnapshotPanel settingsSnapshotId={settingsSnapshotId} />
        </PanelColumn>
      </PanelColumnSet>
      <PanelActions>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </PanelActions>
    </Panel>
  );
}
