import { useState } from "react";
import {
  PageLoading,
  PanelSpacer,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  PanelHeading,
  Button,
  PanelDialog,
  Link,
} from "@backslashbuild/pp-ui";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useGet, useProcessContractorProduct } from "./api";
import { isTextNullOrEmpty, utcToLocalText } from "./utils";

function ContractorProductTimesheetRows({ contractorProductId }) {
  // const { data, loading, error } = useGet(`branches/${agencyId}`);
  // const [editBranch, setEditBranch] = useState(null);
  // const [createNewBranch, setCreateNewBranch] = useState(false);

  // if (loading) return <PageLoading />;

  // if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

  // if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  return <></>;
}

export default function ContractorTimesheetsTable({ contractorId }) {
  const { data, loading, error, refetch } = useGet(
    `contractor-products?contractorId=${contractorId}`
  );

  if (loading) return <PageLoading />;
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (!data?.isSuccess) return data?.errors?.map((error) => <div>{error}</div>);
  if (data?.value?.itemsRetrieved === 0) return <></>;

  return (
    <>
      <Table standardTable height={600} width={800}>
        <TableHeader>
          <TableHeaderColumn width="80%">Timesheets</TableHeaderColumn>
          <TableHeaderColumn>
            <RouterLink
              to={`/contractor-timesheets/${contractorId}?contractorProductIds=${data?.value?.items
                ?.map(function (o) {
                  return o.id;
                })
                .slice(",")}`}
              style={{ textDecorationLine: "none" }}
            >
              <div style={{ width: "100%", textAlign: "right" }}>
                <Link>View all</Link>
              </div>
            </RouterLink>
          </TableHeaderColumn>
        </TableHeader>
        <TableHeader>
          <TableHeaderColumn width="100px">Schedule Date</TableHeaderColumn>
          <TableHeaderColumn width="100px">Product Reference</TableHeaderColumn>
          <TableHeaderColumn width="100px">Agency</TableHeaderColumn>
          <TableHeaderColumn width="100px">Date Worked</TableHeaderColumn>
          <TableHeaderColumn width="100px">Amount</TableHeaderColumn>
          <TableHeaderColumn width="100px">Rate</TableHeaderColumn>
          <TableHeaderColumn width="100px">Deductions</TableHeaderColumn>
          <TableHeaderColumn width="100px">Additions</TableHeaderColumn>
          <TableHeaderColumn width="100px">Subtotal</TableHeaderColumn>
        </TableHeader>
        <TableBody fullPageTable={false} footerHeight={0}>
          {data?.value?.items?.map((product, index) => (
            <ContractorProductTimesheetRows key={index} product={product} />
          ))}
        </TableBody>
      </Table>
      <PanelSpacer large />
    </>
  );
}
