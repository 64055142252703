import React from "react";
import {
  PanelColumnSet,
  PanelColumn,
  Panel,
  PanelHeading,
  PanelSpacer,
  PanelRow,
  Label,
  BoldText,
  PageLoading,
  TableBody,
  Table,
  TableHeaderColumn,
  TableHeader,
  PanelColumnSpacer,
  TableRow,
  TableCell,
  Pill,
} from "@backslashbuild/pp-ui";
import { useGet } from "./api";
import { formatCurrency } from "../../utils";
import { subscribe, unsubscribe } from "../../pubSub";
import SettingsSnapshotPanel from "./SettingsSnapshotPanel";
import ObjectMap from "../../components/ObjectMap";
import PayslipRFPsPanel from "./PayslipRFPsPanel";

function PayslipDebugPanels({ id }) {
  // todo query payslip api and get payslip data
  const { data, loading, errors, refetch } = useGet(`payslips/${id}`);

  React.useEffect(() => {
    subscribe("payslip.updated", refetch);
    return () => unsubscribe("payslip.updated", refetch);
  }, [id]);

  if (loading) {
    return <PageLoading />;
  }

  if (data?.errors) {
    return (
      <ul>
        {data?.errors?.map((error) => (
          <li>{error}</li>
        ))}
      </ul>
    );
  }

  const payslip = data.value;

  return (
    <PanelColumnSet>
      <PanelColumn>
        <Panel>
          <PanelHeading>Payslip Information</PanelHeading>
          <PanelSpacer />
          <PanelRow>
            <Label label="Payslip Number">
              <BoldText>{data?.value?.id}</BoldText>
            </Label>
          </PanelRow>
        </Panel>

        <PanelSpacer large />
        <SettingsSnapshotPanel settingsSnapshot={payslip?.settingsSnapshot} />
        <PanelSpacer large />
      </PanelColumn>

      <PanelColumnSpacer />

      <PanelColumn width={474 * 2}>
        <Panel maxWidth={474 * 2}>
          <PanelHeading>Salary items</PanelHeading>
          <Table width={800}>
            <TableHeader>
              <TableHeaderColumn width="200px">Agency/Client</TableHeaderColumn>
              <TableHeaderColumn width="200px">Name of Workplace</TableHeaderColumn>
              <TableHeaderColumn width="100px" alignRight>
                Hours
              </TableHeaderColumn>
              <TableHeaderColumn width="100px" alignRight>
                Days
              </TableHeaderColumn>
              <TableHeaderColumn width="100px" alignRight>
                Rate
              </TableHeaderColumn>
              <TableHeaderColumn width="100px" alignRight>
                Total
              </TableHeaderColumn>
            </TableHeader>
            <TableBody>
              {payslip?.salaryEntries?.map((entry) => (
                <TableRow disableHover>
                  <TableCell width="200px">{entry?.agencyOrClient}</TableCell>
                  <TableCell width="200px">{entry?.nameOfWorkplace}</TableCell>
                  <TableCell width="100px" alignRight>
                    {entry?.hours}
                  </TableCell>
                  <TableCell width="100px" alignRight>
                    {entry?.days}
                  </TableCell>
                  <TableCell width="100px" alignRight>
                    {entry?.rate}
                  </TableCell>
                  <TableCell width="100px" alignRight>
                    {formatCurrency(entry?.total)}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow disableHover>
                <TableCell shaded mini width={600} colSpan={5} alignRight>
                  Invoice Total
                </TableCell>
                <TableCell summary alignRight width={100}>
                  {formatCurrency(payslip?.invoiceTotal)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Panel>
        <PanelSpacer large />
        <Panel maxWidth={474 * 2}>
          <PanelHeading>Line items</PanelHeading>
          <Table width={800}>
            <TableHeader>
              <TableHeaderColumn width="200px">Type</TableHeaderColumn>
              <TableHeaderColumn width="200px">Description</TableHeaderColumn>
              <TableHeaderColumn width="100px" alignRight>
                Deductions
              </TableHeaderColumn>
              <TableHeaderColumn width="100px" alignRight>
                Additions
              </TableHeaderColumn>
              <TableHeaderColumn width="100px" alignRight>
                Balance
              </TableHeaderColumn>
            </TableHeader>
            <TableBody>
              <LineItemsSection
                lineItems={payslip?.lineItems?.filter(
                  (lineItem) => lineItem?.section?.id === "for_gross_taxable_pay"
                )}
              />
              <TableRow disableHover>
                <TableCell shaded mini width={600} colSpan={4} alignRight>
                  <BoldText>Gross taxable pay</BoldText>
                </TableCell>
                <TableCell summary alignRight width={100}>
                  {formatCurrency(payslip?.grossTaxablePay)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Panel>
        <PanelSpacer large />
        <Panel maxWidth={474 * 2}>
          <Table width={800}>
            <TableBody>
              <LineItemsSection
                lineItems={payslip?.lineItems?.filter(
                  (lineItem) => lineItem?.section?.id === "for_net_pay"
                )}
                balance={payslip?.invoiceTotal}
              />
              <TableRow disableHover>
                <TableCell shaded mini width={600} colSpan={4} alignRight>
                  <BoldText>Net pay</BoldText>
                </TableCell>
                <TableCell summary alignRight width={100}>
                  {formatCurrency(payslip?.netPay)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Panel>
        <PanelSpacer large />
        <Panel maxWidth={474 * 2}>
          <Table width={800}>
            <TableBody>
              <LineItemsSection
                lineItems={payslip?.lineItems?.filter(
                  (lineItem) => lineItem?.section?.id === "for_total_pay"
                )}
                balance={payslip?.invoiceTotal}
              />
              <TableRow disableHover>
                <TableCell shaded mini width={600} colSpan={4} alignRight>
                  <BoldText>Total pay</BoldText>
                </TableCell>
                <TableCell summary alignRight width={100}>
                  {formatCurrency(payslip?.totalPay)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Panel>
        <PanelSpacer large />
        <Panel maxWidth={474 * 2}>
          <Table width={800}>
            <TableBody>
              <LineItemsSection
                lineItems={payslip?.lineItems?.filter(
                  (lineItem) => lineItem?.section?.id === "for_banked"
                )}
                balance={payslip?.invoiceTotal}
              />
              <TableRow disableHover>
                <TableCell shaded mini width={600} colSpan={4} alignRight>
                  <BoldText>Banked</BoldText>
                </TableCell>
                <TableCell summary alignRight width={100}>
                  {formatCurrency(payslip?.banked)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Panel>
        <PanelSpacer large />
        <PanelSpacer large />

        <SettingsPanel data={data} />
      </PanelColumn>
      <PanelColumnSpacer />
      <PanelColumn>
        <Panel>
          <PanelHeading>Log</PanelHeading>
          <PanelSpacer />
          {payslip?.errors?.map((error) => (
            <PanelRow>
              <Pill status="error">{error}</Pill>
            </PanelRow>
          ))}
          {payslip?.warnings?.length > 0 && payslip?.ignoreWarnings == true && (
            <PanelRow>
              <BoldText>Warnings have been marked as ignored</BoldText>
            </PanelRow>
          )}
          {payslip?.warnings?.map((warning) => (
            <PanelRow>
              <Pill status="warning">{warning}</Pill>
            </PanelRow>
          ))}
          {payslip?.log?.split(/\r?\n/)?.map((line) =>
            line ? (
              <PanelRow small>
                <Pill status="info">{line}</Pill>
              </PanelRow>
            ) : null
          )}
        </Panel>
        <PanelSpacer large />
        <PayslipRFPsPanel payslipId={id} employmentId={data?.value?.employmentId} />
      </PanelColumn>
    </PanelColumnSet>
  );
}

export default PayslipDebugPanels;

function LineItemsSection({ lineItems }) {
  if (!lineItems) return null;

  return lineItems?.map((lineItem) => (
    <TableRow disableHover>
      <TableCell width="200px">{lineItem.type?.name}</TableCell>
      <TableCell width="200px">{lineItem?.description}</TableCell>
      <TableCell width="100px" alignRight>
        {lineItem?.isDeduction && formatCurrency(lineItem?.amount)}
      </TableCell>
      <TableCell width="100px" alignRight>
        {!lineItem?.isDeduction && formatCurrency(lineItem?.amount)}
      </TableCell>
      <TableCell width="100px" alignRight>
        {formatCurrency(lineItem?.balance)}
      </TableCell>
    </TableRow>
  ));
}

function SettingsPanel({ data }) {
  const [open, setOpen] = React.useState(false);

  return (
    <Panel maxWidth={474 * 2}>
      <PanelHeading onClick={() => setOpen(!open)}>Full settings used.</PanelHeading>
      <PanelSpacer />
      {open && <ObjectMap data={data?.value} />}
    </Panel>
  );
}
