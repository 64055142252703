import React from "react";

import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableFooter,
  Link,
} from "@backslashbuild/pp-ui";

import { useLocation, useHistory } from "react-router-dom";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";
import queryString from "query-string";
import { utcToLocalText, isTextNullOrEmpty } from "./utils";
import { useGetItems } from "./api";

const columns = {
  itemName: 60,
  itemType: 10,
  viewDate: 10,
  user: 40,
};

const totalWidth = Object.keys(columns).reduce((total, key) => (total += columns[key]), 0);

export default function ViewingsTable() {
  const location = useLocation();
  const search = usePagination(location.search);
  const parsedQueryString = queryString.parse(search);
  const { data, loading, error, queryId } = useGetItems(
    `?${queryString.stringify({ ...parsedQueryString })}`
  );

  const isLoaded = Boolean(data?.value);
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (data && !data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  function parseItemType(value) {
    if (isTextNullOrEmpty(value)) return null;
    if (value === "[contractor]") return "Contractor";
    if (value === "[agency]") return "Agency";
  }

  function PureTable({ data, queryId }) {
    const history = useHistory();

    return React.useMemo(
      () =>
        data.items.map((item) => (
          <>
            <TableRow onClick={() => history.push(`${item.url}`)}>
              <TableCell width={columns.itemName}>{item.itemName}</TableCell>
              <TableCell width={columns.itemType}>{parseItemType(item.type)}</TableCell>
              <TableCell width={columns.viewDate}>
                {utcToLocalText(item.dateCreated, "dd/MM/yyyy HH:mm")}
              </TableCell>
              <TableCell width={columns.user}>
                <Link href={`/user/${item?.userId}`}>{item.userName}</Link>
              </TableCell>
            </TableRow>
          </>
        )),
      [queryId]
    );
  }

  function TableSkeleton() {
    return (
      <TableRow>
        <TableCell colSpan={7} width="100%">
          Loading
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      <Table standardTable width={totalWidth}>
        <TableHeader>
          <TableHeaderColumn width={columns.itemName}>Name</TableHeaderColumn>
          <TableHeaderColumn width={columns.itemType}>View Type</TableHeaderColumn>
          <TableHeaderColumn width={columns.viewDate}>View Date</TableHeaderColumn>
          <TableHeaderColumn width={columns.user}>User Full Name</TableHeaderColumn>
        </TableHeader>
        <TableBody fullPageTable={true} footerHeight={TableFooter.height}>
          {isLoaded ? <PureTable data={data?.value} queryId={queryId} /> : <TableSkeleton />}
        </TableBody>
        <TableFooter>
          <QueryPaginator
            itemsTotal={data?.value?.itemsTotal}
            itemsRetrieved={data?.value?.itemsRetrieved}
          />
        </TableFooter>
      </Table>
    </>
  );
}
