import React from "react";

import AddBACSRunButton from "./AddBACSRunButton";
import AddBACSRunDialog from "./AddBACSRunDialog";

import { PanelDialog } from "@backslashbuild/pp-ui";

export default function AddBACSRunSection() {
  const [showDialog, setShowDialog] = React.useState(false);

  return (
    <>
      <AddBACSRunButton onClick={() => setShowDialog(true)} />
      <AddBACSRunDialog
        open={showDialog}
        onClose={() => {
          setShowDialog(false);
        }}
      />
    </>
  );
}
