import React from "react";
import DeactivateButton from "./DeactivateButton";
import DeactivateDialog from "./DeactivateDialog";

import { PageHeadingAreaSpacer } from "@backslashbuild/pp-ui";

export default function DeactivateDraft({ draft }) {
  const [showDialog, setShowDialog] = React.useState(false);

  return (
    <>
      {draft && !draft?.isClosed && (
        <>
          <DeactivateButton onClick={() => setShowDialog(true)} />
          <PageHeadingAreaSpacer />
          <DeactivateDialog
            open={showDialog}
            draftId={draft?.id}
            onClose={() => {
              setShowDialog(false);
            }}
          />
        </>
      )}
    </>
  );
}
