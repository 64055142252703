import React from "react";
import { Content } from "@backslashbuild/pp-ui";

import ProductRatesTable from "../modules/products/ProductRatesTable";

export default function Product() {
  return (
    <Content>
      <ProductRatesTable />
    </Content>
  );
}
