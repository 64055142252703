import React from "react";
import { useGetSchedule } from "./api";
import { Link } from "@backslashbuild/pp-ui";
import { Link as RouterLink } from "react-router-dom";

export default function ScheduleTitle({ id }) {
  const { data, loading, error } = useGetSchedule(id);

  if (loading) {
    return <>Schedule: Loading...</>;
  }

  return (
    <>
      Schedule:{" "}
      <Link component={RouterLink} to={`/agency/${data?.value?.agencyId}`}>
        {data?.value?.agencyNumber} {data?.value?.agencyName}{" "}
      </Link>{" "}
      - {data?.value?.weekNumber}
    </>
  );
}
