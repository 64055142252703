import React from "react";
import {
  Content,
  PanelColumnSet,
  PanelColumn,
  PageHeadingArea,
  PageTitle,
} from "@backslashbuild/pp-ui";
import CreateIllustrationForm from "../modules/payroll/CreateIllustrationForm";

function IllustrationCreation() {
  return (
    <Content scrollable>
      <PageHeadingArea>
        <PageTitle>New Illustration</PageTitle>
      </PageHeadingArea>
      <PanelColumnSet>
        <PanelColumn>
          <CreateIllustrationForm />
        </PanelColumn>
      </PanelColumnSet>
    </Content>
  );
}

export default IllustrationCreation;
