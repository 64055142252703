import React from "react";
import {
  PanelDialog,
  PanelHeading,
  PanelActions,
  TableHeaderColumn,
  TableHeader,
  TableBody,
  TableRow,
  Table,
  Button,
  TableCell,
  Panel,
} from "@backslashbuild/pp-ui";
import { useGet } from "./api";

export default function UnsuccessfulEnrolmentsDialog({ enrolmentExport, disabled }) {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const { data, loading, error } = useGet(`enrolments/${enrolmentExport.id}/not-successful`);
  const numberOfIssues = data?.value?.length;

  if (!loading && (error || !data))
    return (
      <Button small disabled={true} style={{ marginRight: 10 }}>
        Unable to load
      </Button>
    );

  if (disabled || !numberOfIssues > 0) {
    return (
      <Button small disabled={true} style={{ marginRight: 10 }}>
        No issues
      </Button>
    );
  }

  return (
    <>
      <Button
        small
        onClick={() => setDialogOpen(true)}
        disabled={disabled}
        style={{ marginRight: 10 }}
      >
        {numberOfIssues > 1 ? `View ${numberOfIssues} Issues` : `View ${numberOfIssues} Issue`}
      </Button>

      <PanelDialog width={900} onClose={() => setDialogOpen(false)} open={dialogOpen}>
        <Panel maxWidth={900}>
          <PanelHeading>Unsuccessful Enrolments</PanelHeading>
          <div style={{ height: 500, overflow: "scroll" }}>
            <Table width={900}>
              <TableHeader>
                <TableHeaderColumn width="20%">NI Number</TableHeaderColumn>
                <TableHeaderColumn width="20%">Name</TableHeaderColumn>
                <TableHeaderColumn width="60%">Error</TableHeaderColumn>
              </TableHeader>
              <TableBody>
                {data?.value?.map((item) => (
                  <TableRow disableHover>
                    <TableCell width="20%">{item.niNumber}</TableCell>
                    <TableCell width="20%">{`${item.forename} ${item.surname}`}</TableCell>
                    <TableCell width="60%">
                      {item?.errors?.map((error) => (
                        <div>{error}</div>
                      ))}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <PanelActions>
            <Button variant="secondary" onClick={() => setDialogOpen(false)}>
              Close
            </Button>
          </PanelActions>
        </Panel>
      </PanelDialog>
    </>
  );
}
