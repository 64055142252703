import {
  PageLoading,
  Panel,
  Table,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableCell,
  TableRow,
  PanelHeading,
  Link,
  PanelDialog,
} from "@backslashbuild/pp-ui";
import React from "react";
import { useGetItems } from "./api";
import { buildQuery, isTextNullOrEmpty } from "./utils";
import { subscribe, unsubscribe } from "./pubSub";

import ConsultantForm from "./ConsultantForm";
import NewConsultantForm from "./NewConsultantForm";

export default function ConsultantsTable({ agencyId, branchId }) {
  const query = buildQuery({ agencyId: agencyId, branchId: branchId });
  const path = `consultants${query}`;
  const { data, loading, error, refetch } = useGetItems(path);
  const [createConsultant, setCreateConsultant] = React.useState(null);
  const [editConsultant, setEditConsultant] = React.useState(null);

  React.useEffect(() => {
    subscribe("agency.consultant.updated", refetch);
    return () => unsubscribe("agency.consultant.updated", refetch);
  }, [agencyId]);

  if (loading) return <PageLoading />;
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  return (
    <>
      <Table standardTable height={600} width={600}>
        <TableHeader>
          <TableHeaderColumn width="90%">Consultants</TableHeaderColumn>
          <TableHeaderColumn width="10%">
            <Link onClick={() => setCreateConsultant(agencyId)}>Add consultant</Link>
          </TableHeaderColumn>
        </TableHeader>
        <TableHeader>
          <TableHeaderColumn width="70%">Name</TableHeaderColumn>
        </TableHeader>
        <TableBody>
          {data.value.items.map((consultant) => (
            <TableRow onClick={() => setEditConsultant(consultant?.id)}>
              <TableCell width="100%">{consultant.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <PanelDialog open={editConsultant}>
        <PanelHeading>Edit consultant</PanelHeading>
        {editConsultant && (
          <ConsultantForm id={editConsultant} onClose={() => setEditConsultant(null)} />
        )}
      </PanelDialog>
      <PanelDialog open={createConsultant}>
        <PanelHeading>Add Consultant</PanelHeading>
        {createConsultant && (
          <NewConsultantForm
            agencyId={createConsultant}
            onClose={() => setCreateConsultant(null)}
          />
        )}
      </PanelDialog>
    </>
  );
}
