import React from "react";
import { Panel, PageLoading, PanelActions, PanelHeading, Button } from "@backslashbuild/pp-ui";
import FormField from "./FormField";
import { useUpdateFields, useGetFields, useGet } from "./api";
import { isTextNullOrEmpty } from "./utils";

export default function PensionDetailsForm({ accountNumber, onClose }) {
  const { data, loading, error } = useGetFields({
    accountNumber: accountNumber,
    formName: "contractor-product-pension",
  });
  const updateFields = useUpdateFields({
    accountNumber: accountNumber,
    formName: `contractor-product`,
    subFormName: `contractorProduct`,
    fields: `pension`,
  });
  const [edits, setEdits] = React.useState([]);

  if (loading) return <PageLoading />;

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data?.isSuccess) {
      return data?.errors?.map((error) => <div>{error}</div>);
    }

    return data?.value?.map((field) => (
      <FormField key={field.key} field={field} onSave={getOnSaveHandler(field.key)} />
    ));
  }

  function getOnSaveHandler(key) {
    return (value) => {
      setEdits([...edits.filter((e) => e.key !== key), { key, value }]);
    };
  }

  function handleSave() {
    let fields = data?.value?.map((field) => {
      return { key: field.key, value: field.value };
    });
    edits.map((edit) => {
      fields = [...fields.filter((e) => e.key !== edit.key), { key: edit.key, value: edit.value }];
    });
    updateFields(fields);
  }

  return (
    <>
      <Panel>
        <PanelHeading>Update Pension Details</PanelHeading>
        {handleRender()}
        <PanelActions>
          <Button variant="secondary" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleSave();
              onClose();
            }}
          >
            Update
          </Button>
        </PanelActions>
      </Panel>
    </>
  );
}
