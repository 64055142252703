import React from "react";
import { Dropdown, DropdownOption } from "@backslashbuild/pp-ui";
import { useGet } from "./api";

function CompanyPicker({ value, onChange }) {
  const optionsQuery = useGet("companies"); // TODO urlencode
  const options = optionsQuery.data?.value?.items ?? [];

  return (
    <Dropdown value={value} onChange={(e) => onChange(e.target.value)}>
      <DropdownOption value="">Choose:</DropdownOption>
      {options.map((company) => (
        <DropdownOption value={company.id}>{company.name}</DropdownOption>
      ))}
    </Dropdown>
  );
}

export default CompanyPicker;
