import React from "react";
import { Icons } from "@backslashbuild/pp-ui";
import { usePost } from "./api";
import { publish } from "../../pubSub";

const Icon = Icons.DeleteIcon;

export default function DeleteStatutoryPayButton({ statutoryPayId }) {
  const post = usePost("statutory-pays/delete");

  async function handleSubmit() {
    const result = await post({
      statutoryPayId,
    });
    if (!result?.isSuccess) {
      var errors = result?.errors;
      publish(
        "notification",
        <ul>
          {errors?.map((error) => {
            return <li>{error}</li>;
          })}
        </ul>
      );
      return;
    }
    publish("notification", "Statutory Pay Deleted");
  }

  return <Icon onClick={() => handleSubmit()} />;
}
