import React from "react";
import { QueryDropdown } from "@backslashbuild/pp-ui";
import { useGetItems, useGet } from "../agencies/api";

// function AgencyPicker({ value, onChange }) {
//   //   const useGetAgencies();

//   return (
//     <QueryDropdown
//       placeholder="Choose"
//       search={agencySearch}
//       onSearchChange={(search) => {
//         agencySearch = search;
//       }}
//       value={request.agencyId}
//       onChange={handleChange}
//       options={agencyOptions.map((option) => option.value)}
//       renderOption={(value) => {
//         const option = agencyOptions.find((option) => value == option.value);
//         return option ? option.displayName : "";
//       }}
//     />
//   );
// }

function format(value) {
  return value ? `${value.agencyNumber} ${value.name}` : "-";
}

function AgencyPicker({ value, onChange, width }) {
  const [search, setSearch] = React.useState("");
  const optionsQuery = useGetItems(search ? `?name=${search}&take=25` : "?take=25"); // TODO urlencode

  const currentItemQuery = useGet(value);

  const options = optionsQuery.data?.value?.items ?? [];

  // Debounce the search
  var searchTimeout = null;
  const setSearchTerm = (searchTerm) => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      setSearch(searchTerm);
    }, 500);
  };

  return (
    <QueryDropdown
      placeholder="Choose"
      // search={search}
      width={width}
      onSearchChange={setSearchTerm}
      value={value}
      onChange={onChange}
      options={options.map((o) => o.id)}
      renderOption={(v) => {
        if (v == value) return format(currentItemQuery?.data?.value);

        var optionsMatch = options.find((o) => o.id == v);

        if (optionsMatch) {
          return format(optionsMatch);
        }
        return "loading...";
      }}
    />
  );
}

export default AgencyPicker;
