import React from "react";
import { Panel, PageLoading, Snack } from "@backslashbuild/pp-ui";
import FormField from "./FormField";
import { useGetFields, useUpdateField } from "./api";
import { isTextNullOrEmpty } from "./utils";

export default function ProductForm({ id }) {
  const { data, loading, error } = useGetFields({ id, formName: "" });
  const updateField = useUpdateField({ id, formName: "" });

  if (loading) {
    return <PageLoading />;
  }

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    return data.value.map((field) => (
      <FormField key={field.key} field={field} onSave={getOnSaveHandler(field.key)} />
    ));
  }

  function getOnSaveHandler(key) {
    return (value) => {
      updateField({ key, value });
    };
  }

  return <Panel>{handleRender()}</Panel>;
}
