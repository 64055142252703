import React, { useState } from "react";
import {
  Content,
  PageTitle,
  PanelColumn,
  PageHeadingArea,
  PanelColumnSet,
} from "@backslashbuild/pp-ui";

import NewTopicForm from "../modules/internal-comms/comms-topics/NewTopicForm";

export default function NewCommsTopic() {
  return (
    <Content scrollable>
      <PageHeadingArea>
        <PageTitle>New Topic</PageTitle>
      </PageHeadingArea>

      <PanelColumnSet>
        <PanelColumn>
          <NewTopicForm />
        </PanelColumn>
      </PanelColumnSet>
    </Content>
  );
}
