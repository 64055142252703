import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@backslashbuild/pp-ui";
import {
  Content,
  PageTitle,
  PanelColumn,
  PageHeadingArea,
  PanelColumnSet,
} from "@backslashbuild/pp-ui";

import TopicForm from "../modules/internal-comms/comms-topics/TopicForm";

export default function EditCommsTopic() {
  const { id } = useParams();

  return (
    <Content scrollable>
      <PageHeadingArea>
        <PageTitle>Update Topic</PageTitle>
      </PageHeadingArea>

      <PanelColumnSet>
        <PanelColumn>
          <TopicForm id={id} />
        </PanelColumn>
      </PanelColumnSet>
    </Content>
  );
}
