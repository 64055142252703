import React from "react";
import {
  PanelRow,
  PanelSubheading,
  Link,
  Label,
  PanelDialog,
  PanelHeading,
  TextInput,
  PanelActions,
  Button,
} from "@backslashbuild/pp-ui/dist/index.es";
import { useGet, usePost } from "./api";
import { isTextNullOrEmpty } from "./utils";
import { publish } from "./pubSub";

export default function AccountNumberOverridePanelRow({ accountNumber }) {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [value, setValue] = React.useState("");
  const { data, loading, error, refetch } = useGet("employments/employment/" + accountNumber);

  const update = usePost("employments/update-account-number-override");

  async function handleSubmit() {
    try {
      const response = await update({ accountNumber, accountNumberOverride: value });

      if (response.isSuccess) {
        publish("notification", "Updated successfully");
        setDialogOpen(false);
        refetch();
      } else {
        publish(
          "notification",
          <div>
            Unable to update employment
            <ul>
              {response.errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </div>
        );
      }
    } catch (e) {
      console.error(e, "error updating");
      publish("notification", "A network error occured.");
    }
  }

  return (
    <>
      <PanelSubheading
        rightAction={
          <Link component={Link} onClick={() => setDialogOpen(true)}>
            Edit
          </Link>
        }
      >
        Account Number (Alternative Unique Identifier)
      </PanelSubheading>
      <PanelRow>
        <Label label="Default">{data?.value?.accountNumber}</Label>
      </PanelRow>
      <PanelRow>
        <Label label="Override">
          {isTextNullOrEmpty(data?.value?.accountNumberOverride)
            ? "No override"
            : data?.value?.accountNumberOverride}
        </Label>
      </PanelRow>
      <PanelDialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <PanelHeading>Edit Account Number Override</PanelHeading>
        <PanelRow>
          <Label label="Account Number Override">
            <TextInput value={value} onChange={(e) => setValue(e.target.value)} />
          </Label>
        </PanelRow>
        <PanelActions>
          <Button variant="secondary" onClick={() => setDialogOpen(false)}>
            Cancel
          </Button>
          <Button onClick={() => handleSubmit()}>Submit</Button>
        </PanelActions>
      </PanelDialog>
    </>
  );
}
