import React from "react";
import {
  PageHeadingArea,
  PageHeadingAreaSpacer,
  PageTitle,
  PageLoading,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  Button,
  Link,
  SearchBar,
  TableFooter,
} from "@backslashbuild/pp-ui";
import { utcToLocalText, isTextNullOrEmpty } from "./utils";
import { useGet } from "./api";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import UsersFiltering from "./UsersFiltering";
import queryString from "query-string";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";

const c = {
  name: 280,
  active: 65,
  email: 420,
  department: 150,
  sales: 150,
  isContractor: 150,
  lastOn: 280,
  history: 130,
  roles: 280,
};

function PureTable({ data, queryId }) {
  const history = useHistory();

  return React.useMemo(
    () =>
      data.value.items.map((user) => (
        <TableRow key={user.id} onClick={() => history.push(`/user/${user.id}`)}>
          <TableCell width={c.name}>{user.name}</TableCell>
          <TableCell width={c.active}>yes</TableCell>
          <TableCell width={c.email}>{user.email}</TableCell>
          <TableCell width={c.department}>{user.department}</TableCell>
          <TableCell width={c.isContractor}>{user.isContractor ? "yes" : ""} </TableCell>
          <TableCell width={c.sales}>{user.isSales}</TableCell>
          <TableCell width={c.lastOn}>{utcToLocalText(user.lastActive) || "Never"}</TableCell>
          <TableCell width={c.history}>
            <Link href={`/view-history?userId=${user.id}`}>View</Link>
          </TableCell>
          <TableCell width={c.roles}>{user.roles?.map((r) => r.roleName).join(",")}</TableCell>
        </TableRow>
      )),
    [queryId]
  );
}

function formatYesNo(yesno) {
  if (yesno === "yes") return true;
  if (yesno === "no") return false;
  return undefined;
}

export default function UsersTable() {
  const location = useLocation();
  const search = usePagination(location.search);
  const parsedQueryString = queryString.parse(search);
  const { data, loading, error, queryId } = useGet(
    `users?${queryString.stringify({ ...parsedQueryString })}`
  );

  if (loading || !data) return <PageLoading />;
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  return (
    <>
      <PageHeadingArea>
        <PageTitle>Users</PageTitle>
        <PageHeadingAreaSpacer grow />
        <UsersFiltering />
        <PageHeadingAreaSpacer />
        <Button large component={RouterLink} to="/create-user">
          Add new user
        </Button>
      </PageHeadingArea>
      <Table standardTable>
        <TableHeader>
          <TableHeaderColumn width={c.name}>Name</TableHeaderColumn>
          <TableHeaderColumn width={c.active}>Active</TableHeaderColumn>
          <TableHeaderColumn width={c.email}>Email</TableHeaderColumn>
          <TableHeaderColumn width={c.department}>Department</TableHeaderColumn>
          <TableHeaderColumn width={c.isContractor}>Is Contractor</TableHeaderColumn>
          <TableHeaderColumn width={c.sales}>Sales</TableHeaderColumn>
          <TableHeaderColumn width={c.lastOn}>Last on</TableHeaderColumn>
          <TableHeaderColumn width={c.history}>View History</TableHeaderColumn>
          <TableHeaderColumn width={c.roles}>Roles</TableHeaderColumn>
        </TableHeader>
        <TableBody fullPageTable footerHeight={TableFooter.height}>
          <PureTable data={data} queryId={queryId} />
        </TableBody>
        <TableFooter>
          <QueryPaginator
            itemsRetrieved={data?.value?.itemsRetrieved}
            itemsTotal={data?.value?.itemsTotal}
          />
        </TableFooter>
      </Table>
    </>
  );
}
