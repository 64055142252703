import React from "react";
import { DateTime } from "luxon";

import { makeStyles } from "@backslashbuild/pp-ui";

const useStyles = makeStyles({
  key: {
    fontSize: 10,
    background: "#fafafa",
  },
  objectMap: {
    fontSize: 11,
    margin: "10px 0",
    padding: 20,
    // border: "1px solid #bbb",
    width: "100%",
    borderCollapse: "collapse",
    "& td": {
      border: "1px solid #bbb",

      verticalAlign: "top",
      padding: "4px 8px",
    },
  },
});

function ObjectMapItem({ value, hideEmptyValues, noFormat }) {
  if (typeof value === "number") {
    return <span>{value}</span>;
  }
  if (Number(value).isNAN && DateTime.fromISO(value).isValid) {
    return DateTime.fromISO(value, {}).toFormat("dd MMM yyyy -- HH:mm:ss");
  }
  if (typeof value === "string") {
    return <span>{value}</span>;
  }

  if (Array.isArray(value)) {
    if (value.length == 0) return "none";

    return value.map((x, i) => (
      <div>
        <ObjectMapItem key={i} value={x} />
      </div>
    ));
  }

  if (typeof value === "object") {
    return <ObjectMap data={value} hideEmptyValues={hideEmptyValues} noFormat={noFormat} />;
  }

  return JSON.stringify(value);
}

function formatKey(key) {
  return key.replace(/([a-z])([A-Z])/g, "$1 $2").toUpperCase();
}

function ObjectMap({ data, hideEmptyValues = false, noFormat = false }) {
  const classes = useStyles();

  return (
    <table className={classes.objectMap}>
      <tbody>
        {data &&
          Object.keys(data)
            .filter((key) => (hideEmptyValues ? data[key] != undefined && data[key] != null : true))
            .map((key) => (
              <tr>
                <td className={classes.key}>
                  <strong>{noFormat ? key : formatKey(key)}</strong>
                </td>
                <td>
                  <ObjectMapItem
                    value={data[key]}
                    hideEmptyValues={hideEmptyValues}
                    noFormat={noFormat}
                  />
                </td>
              </tr>
            ))}
      </tbody>
    </table>
  );
}

export default ObjectMap;
