import React from "react";
import { TableRow, Table, TableCell, TableHeaderColumn, TableBody } from "@backslashbuild/pp-ui";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useGetContributionScheduleExports } from "./api";
import { utcToYmd } from "./utils";

const c = {
  dateCreated: 200,
  actions: 600,
};

function ContributionScheduleExportsTable() {
  const { contributionScheduleId } = useParams();

  const { data, loading, error, queryId } = useGetContributionScheduleExports(
    contributionScheduleId
  );

  const ScrollableHeaderRow = TableRow;

  return (
    <Table standardTable>
      <TableBody fullPageTable={true}>
        <ScrollableHeaderRow>
          <TableHeaderColumn width={c.dateCreated}>Date Created</TableHeaderColumn>
          <TableHeaderColumn width={c.actions}>Actions</TableHeaderColumn>
        </ScrollableHeaderRow>
        {data?.value?.length > 0 &&
          data?.value?.map((contributionScheduleExport) => (
            <TableRow
              key={contributionScheduleExport.id}
              component={RouterLink}
              to={`/contribution-schedule-export/${contributionScheduleExport.id}`}
            >
              <TableCell width={c.dateCreated}>
                {utcToYmd(contributionScheduleExport.dateCreated)}
              </TableCell>
              <TableCell width={c.actions}></TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}

export default ContributionScheduleExportsTable;
