import React from "react";
import { usePostProcess } from "./api";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { getYmdDate } from "./utils";

import {
  Panel,
  PanelActions,
  Button,
  PanelRow,
  BoldText,
  PanelHeading,
  PageLoading,
} from "@backslashbuild/pp-ui";

function ParseQuery(search) {
  const parsedQueryString = queryString.parse(search);

  if (
    !parsedQueryString?.issueDateFrom &&
    !parsedQueryString?.issueDateTo &&
    !parsedQueryString?.dateCreatedFrom &&
    !parsedQueryString?.dateCreatedTo
  ) {
    return `?${queryString.stringify({
      issueDateFrom: getYmdDate({ minusDays: 1 }),
      ...parsedQueryString,
    })}`;
  } else {
    return `?${queryString.stringify({
      ...parsedQueryString,
    })}`;
  }
}

export default function ProcessDialog({ onClose }) {
  const location = useLocation();
  const search = location.search;
  const query = ParseQuery(search);
  const process = usePostProcess(query);
  const [showResult, setShowResult] = React.useState(false);
  const [result, setResult] = React.useState();

  function handleConfirm() {
    setShowResult(true);
    return process().then((result) => setResult(result));
  }

  return (
    <Panel>
      <PanelHeading>Process Notifications</PanelHeading>
      <PanelRow>
        {showResult && !result && <PageLoading />}
        {showResult &&
          result?.isSuccess &&
          result?.value?.map((message) => <div>{String(message)}</div>)}
        {showResult &&
          result?.errors?.length > 0 &&
          result?.errors?.map((error) => <div>{String(error)}</div>)}
        {!showResult && (
          <BoldText>{`Click on 'Confirm' if you wish to process notifications. Contractors or contractor products will be modified as specified by each
        notification where possible. Not all notifications can be automatically processed. This action is irreversible.`}</BoldText>
        )}
      </PanelRow>
      <PanelActions>
        <Button variant="secondary" onClick={() => onClose()}>
          Close
        </Button>
        {!showResult && <Button onClick={() => handleConfirm()}>Confirm</Button>}
      </PanelActions>
    </Panel>
  );
}
