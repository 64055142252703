import { useState } from "react";
import {
  PanelDialog,
  PageLoading,
  Pill,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  Link,
  ExpandButton,
  PanelRow,
  BoldText,
  Label,
  Button,
  Panel,
} from "@backslashbuild/pp-ui";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useGet, useProcessContractorProduct } from "./api";
import { isTextNullOrEmpty, utcToLocalText } from "./utils";
import ContractorProductAWRForm from "./ContractorProductAWRForm";
import EditContractorProductForm from "./EditContractorProductForm";
import { subscribe, unsubscribe } from "./pubSub";
import DisciplinaryReasonForm from "./DisciplinaryReasonForm";
import JobDetailsForm from "./JobDetailsForm";
import PensionDetailsForm from "./PensionDetailsForm";
import AddP45Form from "./AddP45Form";
import HolidaySettingsForm from "./HolidaySettingsForm";
import PreviousEmploymentForm from "./PreviousEmploymentForm";
import EmploymentHolidayBalance from "../payroll/EmploymentHolidayBalance";
import EmploymentCurrentHolidayYear from "../payroll/EmploymentCurrentHolidayYear";
import { makeStyles } from "@backslashbuild/pp-ui";
import EmploymentLegalDeductionsPanel from "../payroll/EmploymentLegalDeductionsTable";
import EmploymentSyncsPanel from "../payroll/EmploymentSyncsPanel";
import EmploymentEnrolmentStatus from "../nest/EmploymentEnrolmentStatus";
import { Icons, PanelSubheading } from "@backslashbuild/pp-ui/dist/index.es";

const useStyles = makeStyles({
  flexWrap: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  halfWidth: {
    width: "50%",
  },
  paddingRight: {
    paddingRight: "10px",
  },
  flexGrow: {
    flexGrow: 1,
  },
  edit: {
    justifyContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  spacer: {
    width: 8,
  },
});

const productToBackgroundColor = (product) => {
  let colors = {
    warning: "#eeb83d",
    error: "#db1729",
    info: "#5ccbd9",
    success: "#5ac775",
  };

  if (product?.leaveDate) return colors.error;
  if (product?.leaveRequestDate) return colors.warning;
  if (product?.liveDate) return colors.info;
  return null;
};

function ExpandableRow({
  product,
  openAWRDialog,
  openJobDialog,
  openLegalDeductionsDialog,
  openEmploymentDialog,
}) {
  const [open, setOpen] = React.useState(false);
  const processContractorProduct = useProcessContractorProduct({ id: product?.id });
  const [showAddP45, setShowAddP45] = useState(false);
  const [showUpdateDisciplinary, setShowUpdateDisciplinary] = useState(false);
  const [showEditJob, setShowEditJob] = useState(false);
  const [showEditPension, setShowEditPension] = useState(false);
  const [showEditHolidaySettings, setShowEditHolidaySettings] = useState(false);
  const [showEditPrevEmployments, setShowEditPrevEmployments] = useState(false);

  const classes = useStyles();

  function handleProcess() {
    processContractorProduct().then((res) => {
      alert(res?.isSuccess);
    });
    //window.location.reload();
  }

  function renderHolidayPercentCharge() {
    let result = `${product?.holidaySettings?.percentageCharge}%`;
    if (product?.holidaySettings)
      result = `${result} (${product?.holidaySettings?.daysHoliday} days)`;

    return result;
  }

  function HeaderColumn({ children, width }) {
    return (
      <TableHeaderColumn width={width} backgroundColor={productToBackgroundColor(product)}>
        <div onClick={() => setOpen(!open)} className={classes.flexGrow}>
          {children}
        </div>
      </TableHeaderColumn>
    );
  }

  return (
    <>
      <TableBody>
        <TableRow>
          <TableHeader>
            {product?.isProcessed ? (
              <>
                <HeaderColumn width="40px">
                  <ExpandButton open={open} onClick={() => setOpen(!open)} />
                </HeaderColumn>

                <HeaderColumn width="100px">
                  <div>{product?.accountNumber}</div>
                </HeaderColumn>

                <HeaderColumn width="300px">
                  {product?.leaveDate ? (
                    <Pill status={"error"}>
                      <div className={classes.paddingRight}>{`Leave: ${utcToLocalText(
                        product?.leaveDate
                      )}`}</div>
                    </Pill>
                  ) : product?.leaveRequestDate ? (
                    <Pill status={"warning"}>
                      <div className={classes.paddingRight}>{`Req Leave: ${utcToLocalText(
                        product?.leaveRequestDate
                      )}`}</div>
                    </Pill>
                  ) : (
                    product?.liveDate && (
                      <div style={{ paddingRight: "10px" }}>{`Live: ${utcToLocalText(
                        product?.liveDate
                      )}`}</div>
                    )
                  )}
                </HeaderColumn>
              </>
            ) : (
              <HeaderColumn>
                <ExpandButton open={open} onClick={() => setOpen(!open)} />
                Processing
              </HeaderColumn>
            )}
            <HeaderColumn width="20%">{`Tax Code: ${product?.taxCode}`}</HeaderColumn>
            <HeaderColumn>
              <div className={classes.edit}>
                <Button onClick={() => openEmploymentDialog()} icon>
                  {<Icons.InfoIcon />}
                </Button>
              </div>
            </HeaderColumn>
          </TableHeader>
        </TableRow>
      </TableBody>
      {open && (
        <TableBody>
          <TableRow disableHover>
            <TableCell width="50%">
              <div className={classes.flexWrap}>
                <div className={classes.halfWidth}>
                  <PanelSubheading>Product Details</PanelSubheading>

                  <PanelRow>
                    <Label label="Ref">
                      <BoldText>
                        {!product?.isProcessed ? (
                          <Link onClick={() => handleProcess()}>Processssss</Link>
                        ) : (
                          product?.accountNumber
                        )}
                      </BoldText>
                    </Label>
                  </PanelRow>
                  <PanelRow>
                    <Label label="Live Date">
                      <BoldText>{utcToLocalText(product?.liveDate)}</BoldText>
                    </Label>
                  </PanelRow>
                  <PanelRow>
                    <Label label="Last Paid Date">
                      <BoldText>{utcToLocalText(product?.dateOfMostRecentPayslip)}</BoldText>
                    </Label>
                  </PanelRow>
                  <PanelRow>
                    <Label label="Imported By">
                      <BoldText>{product?.importAuthorName}</BoldText>
                    </Label>
                  </PanelRow>
                  <PanelRow>
                    <Label label="Welcome Pack Sent">
                      <BoldText>
                        {product?.welcomePackSentDate ? (
                          <>{utcToLocalText(product?.welcomePackSentDate)} </>
                        ) : (
                          "Not sent"
                        )}
                      </BoldText>
                    </Label>
                  </PanelRow>

                  <PanelSubheading
                    rightAction={<Link onClick={() => setShowEditJob(true)}>Edit</Link>}
                  >
                    Job Details
                  </PanelSubheading>

                  <PanelRow>
                    <Label label="Occupation">
                      <BoldText>{product?.occupationName}</BoldText>
                    </Label>
                  </PanelRow>
                  <PanelRow>
                    <Label label="Occupation Confirmed">
                      {product?.occupationConfirmedDate ? (
                        <BoldText>{utcToLocalText(product?.occupationConfirmedDate)}</BoldText>
                      ) : (
                        "Not confirmed"
                      )}
                    </Label>
                  </PanelRow>
                  <PanelRow>
                    <Label label="AWR Completed Date">
                      {product?.awrCompletionDate && (
                        <Link onClick={() => openAWRDialog()}>
                          <>{utcToLocalText(product?.awrCompletionDate)} </>
                        </Link>
                      )}
                    </Label>
                  </PanelRow>
                  <PanelRow>
                    <Label label="Tax Code">
                      <BoldText>{product?.taxCode}</BoldText>
                    </Label>
                  </PanelRow>
                  <PanelRow>
                    <Label label="P46 Information">
                      <BoldText>{product?.p46Status}</BoldText>
                    </Label>
                  </PanelRow>
                  <PanelRow>
                    <Label label="Payment Frequency">
                      <BoldText>{product?.paymentFrequency}</BoldText>
                    </Label>
                  </PanelRow>

                  <PanelSubheading
                    rightAction={<Link onClick={() => setShowUpdateDisciplinary(true)}>Edit</Link>}
                  >
                    Discipinary
                  </PanelSubheading>

                  <PanelRow>
                    <Label label="Disciplinary Reason">
                      <BoldText>{product?.disciplinaryReason?.description}</BoldText>
                    </Label>
                  </PanelRow>
                </div>
                <div className={classes.halfWidth}>
                  <PanelSubheading
                    rightAction={<Link onClick={() => setShowEditHolidaySettings(true)}>Edit</Link>}
                  >
                    Holiday Settings
                  </PanelSubheading>
                  <PanelRow>
                    <Label label="Holiday Rate">
                      <BoldText>{renderHolidayPercentCharge()}</BoldText>
                    </Label>
                  </PanelRow>
                  <PanelRow>
                    <Label label="Holiday Pay">
                      <BoldText>{`${product?.holidayPayOutPercent}%`}</BoldText>
                    </Label>
                  </PanelRow>
                  {product?.isHolidayPaidEachWeek != null && (
                    <PanelRow>
                      <Label label="Holiday Pay Frequency">
                        <BoldText>
                          {product?.isHolidayPaidEachWeek
                            ? "Paid every week"
                            : "Accrued until requested"}
                        </BoldText>
                      </Label>
                    </PanelRow>
                  )}

                  <EmploymentHolidayBalance contractorProductId={product?.id} />
                  <EmploymentCurrentHolidayYear contractorProductId={product?.id} />

                  <PanelSubheading
                    rightAction={<Link onClick={() => setShowEditPrevEmployments(true)}>Edit</Link>}
                  >
                    Previous Employment
                  </PanelSubheading>

                  <PanelRow>
                    <Label label="Previous Tax">
                      <BoldText>{`£${product?.taxFromPrevEmployments}`}</BoldText>
                    </Label>
                  </PanelRow>
                  <PanelRow>
                    <Label label="Previous Gross">
                      <BoldText>{`£${product?.grossFromPrevEmployments}`}</BoldText>
                    </Label>
                  </PanelRow>

                  <PanelSubheading
                    rightAction={<Link onClick={() => setShowEditPension(true)}>Edit</Link>}
                  >
                    Pension details
                  </PanelSubheading>

                  {product?.privatePensionEmployeeContribution && (
                    <PanelRow>
                      <Label label="Private Pension Employee Contribution">
                        <BoldText>{product?.privatePensionEmployeeContribution}</BoldText>
                      </Label>
                    </PanelRow>
                  )}
                  {product?.privatePensionProvider && (
                    <PanelRow>
                      <Label label="Private Pension Provider">
                        <BoldText>{product?.privatePensionProvider}</BoldText>
                      </Label>
                    </PanelRow>
                  )}
                  {product?.privatePensionPlanNo && (
                    <PanelRow>
                      <Label label="Private Pension Plan No.">
                        <BoldText>{product?.privatePensionPlanNo}</BoldText>
                      </Label>
                    </PanelRow>
                  )}
                  {product?.privatePensionBankAccount && (
                    <PanelRow>
                      <Label label="Private Pension Bank Account.">
                        <BoldText>{product?.privatePensionBankAccount}</BoldText>
                      </Label>
                    </PanelRow>
                  )}
                  {product?.privatePensionSortCode && (
                    <PanelRow>
                      <Label label="Private Pension Sort Code.">
                        <BoldText>{product?.privatePensionSortCode}</BoldText>
                      </Label>
                    </PanelRow>
                  )}
                  {product?.isProcessed && (
                    <>
                      <PanelRow>
                        <EmploymentEnrolmentStatus contractorProductId={product.id} />
                      </PanelRow>
                      <PanelRow>
                        <Label label="NEST Start Date">
                          <BoldText>{utcToLocalText(product?.nestStartDate)}</BoldText>
                        </Label>
                      </PanelRow>
                      <PanelRow>
                        <Label label="NEST Opt Out Date">
                          <BoldText>{utcToLocalText(product?.nestOptOutDate)}</BoldText>
                        </Label>
                      </PanelRow>
                      <PanelRow>
                        <Label label="NEST Stop Date">
                          <BoldText>{utcToLocalText(product?.nestStopDate)}</BoldText>
                        </Label>
                      </PanelRow>
                      {product?.leaveDate && (
                        <PanelRow>
                          <Label label="Leave Date">
                            <BoldText>{utcToLocalText(product?.leaveDate)}</BoldText>
                          </Label>
                        </PanelRow>
                      )}
                    </>
                  )}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    marginTop: "16px",
                  }}
                >
                  <PanelRow>
                    <Button onClick={() => handleProcess()} style={{ marginRight: "10px" }}>
                      Send Welcome Pack
                    </Button>
                    {!product?.leaveDate && (
                      <Button style={{ marginRight: "10px" }} onClick={() => setShowAddP45(true)}>
                        P45
                      </Button>
                    )}
                    <Link
                      component={RouterLink}
                      to={`/statutory-pay-groups/${product?.id}`}
                      style={{ marginRight: 10 }}
                    >
                      Statutory Pay
                    </Link>
                    <Link component={RouterLink} onClick={openLegalDeductionsDialog}>
                      Legal Deductions
                    </Link>
                  </PanelRow>
                </div>
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      )}
      <PanelDialog open={showUpdateDisciplinary}>
        <DisciplinaryReasonForm
          contractorProductId={product?.id}
          onClose={() => setShowUpdateDisciplinary(false)}
        />
      </PanelDialog>
      <PanelDialog open={showEditJob}>
        <JobDetailsForm contractorProductId={product?.id} onClose={() => setShowEditJob(false)} />
      </PanelDialog>
      <PanelDialog open={showEditPension}>
        <PensionDetailsForm
          contractorProductId={product?.id}
          onClose={() => setShowEditPension(false)}
        />
      </PanelDialog>
      <PanelDialog open={showAddP45}>
        <AddP45Form contractorProductId={product?.id} onClose={() => setShowAddP45(false)} />
      </PanelDialog>
      <PanelDialog open={showEditHolidaySettings}>
        <HolidaySettingsForm
          contractorProductId={product?.id}
          onClose={() => setShowEditHolidaySettings(false)}
        />
      </PanelDialog>
      <PanelDialog open={showEditPrevEmployments}>
        <PreviousEmploymentForm
          contractorProductId={product?.id}
          onClose={() => setShowEditPrevEmployments(false)}
        />
      </PanelDialog>
    </>
  );
}

export default function ContractorProductsTable({ contractorId }) {
  const { data, loading, error, refetch } = useGet(
    `contractor-products?contractorId=${contractorId}`
  );
  const [editAwr, setEditAwr] = React.useState(null);
  const [editJob, setEditJob] = React.useState(null);
  const [editContractorProduct, setEditContractorProduct] = React.useState(null);
  const [legalDeductionsDialog, setLegalDeductionsDialog] = React.useState(null);
  const [employmentDialog, setEmploymentDialog] = React.useState(null);

  React.useEffect(() => {
    subscribe("contractor.contractorProduct.updated", refetch);
    return () => unsubscribe("contractor.contractorProduct.updated", refetch);
  }, [contractorId]);

  if (loading) return <PageLoading />;

  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

  if (!data?.isSuccess) return data?.errors?.map((error) => <div>{error}</div>);

  return (
    <>
      <Panel maxWidth="100%">
        <Table width={1000}>
          <TableHeader>
            <TableHeaderColumn width="100%">Products</TableHeaderColumn>
          </TableHeader>
          <TableBody>
            {data?.value?.items?.map((product, index) => (
              <ExpandableRow
                key={index}
                product={product}
                openAWRDialog={() => setEditAwr(product?.id)}
                closeAWRDialog={() => setEditAwr(null)}
                openJobDialog={() => setEditJob(product?.id)}
                closeJobDialog={() => setEditJob(null)}
                openLegalDeductionsDialog={() => setLegalDeductionsDialog(product?.id)}
                closeLegalDeductionsDialog={() => setLegalDeductionsDialog(null)}
                openEmploymentDialog={() => setEmploymentDialog(product?.id)}
                closeEmploymentDialog={() => setEmploymentDialog(null)}
              />
            ))}
          </TableBody>
        </Table>
        <PanelDialog open={editAwr} onClose={() => setEditAwr(null)}>
          {editAwr && <ContractorProductAWRForm id={editAwr} onClose={() => setEditAwr(null)} />}
        </PanelDialog>
        <PanelDialog open={employmentDialog} onClose={() => setEmploymentDialog(null)} width={1250}>
          {employmentDialog && (
            <EmploymentSyncsPanel
              employmentId={employmentDialog}
              contractorId={contractorId}
              onClose={() => setEmploymentDialog(null)}
            />
          )}
        </PanelDialog>
        <PanelDialog open={legalDeductionsDialog} onClose={() => setLegalDeductionsDialog(null)}>
          <EmploymentLegalDeductionsPanel
            employmentId={legalDeductionsDialog}
            contractorId={contractorId}
            onClose={() => setLegalDeductionsDialog(null)}
          />
        </PanelDialog>
        <PanelDialog open={editContractorProduct}>
          {editContractorProduct && (
            <EditContractorProductForm
              id={editContractorProduct}
              onClose={() => setEditContractorProduct(null)}
            />
          )}
        </PanelDialog>
      </Panel>
    </>
  );
}
