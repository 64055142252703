import {
  PageLoading,
  Panel,
  PanelHeading,
  PanelSpacer,
  PanelRow,
  Label,
  BoldText,
  Link,
  DateFormatted,
  PanelDialog,
  PageHeadingAreaSpacer,
} from "@backslashbuild/pp-ui";
import React from "react";
import { useGet } from "./api";
import { isTextNullOrEmpty, shortenString } from "./utils";
import ContractorContactDetailsForm from "./ContractorContactDetailsForm";
import { subscribe, unsubscribe } from "./pubSub";

export default function ContractorContactDetailsPanel({ id }) {
  const [editContactDetails, setEditContactDetails] = React.useState(null);
  const { data, loading, error, refetch } = useGet(id);

  React.useEffect(() => {
    subscribe("contractor.contactDetails.updated", refetch);
    return () => unsubscribe("contractor.contactDetails.updated", refetch);
  }, [id]);

  if (loading) return <PageLoading />;

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    function renderContactMethods() {
      let contactMethods = [];

      if (data?.value?.isContactByPhone) contactMethods.push("Phone");
      if (data?.value?.isContactByMobile) contactMethods.push("Mobile");
      if (data?.value?.isContactByEmail) contactMethods.push("Email");
      if (data?.value?.isContactByText) contactMethods.push("Text");

      return contactMethods.join(", ");
    }

    return (
      <>
        <PanelRow>
          <Label label="Mobile Number">
            <BoldText>{data?.value?.mobileNumber}</BoldText>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Home Phone Number">
            <BoldText>{data?.value?.homePhoneNumber}</BoldText>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Address">
            <BoldText>{data?.value?.address?.line1}</BoldText>
            <BoldText>{data?.value?.address?.line2}</BoldText>
            <BoldText>{data?.value?.address?.line3}</BoldText>
            <BoldText>{data?.value?.address?.town}</BoldText>
            <BoldText>{data?.value?.address?.postCode}</BoldText>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Preferred Contact Method">{renderContactMethods()}</Label>
        </PanelRow>
      </>
    );
  }

  return (
    <>
      <Panel>
        <PanelHeading rightAction={<Link onClick={() => setEditContactDetails(id)}>Edit</Link>}>
          Contact details
        </PanelHeading>
        <PanelSpacer />
        {handleRender()}
        <PanelSpacer />
      </Panel>
      <PanelDialog open={editContactDetails}>
        <PanelHeading>Edit Contact Details</PanelHeading>
        {editContactDetails && (
          <ContractorContactDetailsForm
            id={editContactDetails}
            onClose={() => {
              setEditContactDetails(null);
            }}
          />
        )}
      </PanelDialog>
    </>
  );
}
