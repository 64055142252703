import React from "react";
import { Button, Link } from "@backslashbuild/pp-ui";
import { usePost } from "./api";

function GenerateButton({ type }) {
  const generate = usePost(`${type}/generate`);

  async function handleClick() {
    const reault = await generate();
    alert("done");
  }

  return <Link onClick={handleClick}>Generate</Link>;
}

export default GenerateButton;
