import React from "react";

import { Button } from "@backslashbuild/pp-ui";

export default function LockToBACSRunButton({ onClick }) {
  return (
    <Button
      large
      onClick={() => {
        onClick();
      }}
    >
      Lock Selected
    </Button>
  );
}
