import React from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
  makeStyles,
  TextInputMultiline,
  Button,
  TextInput,
  BoldText,
  CheckBox,
  Rollover,
  Icons,
  PanelDialog,
  PanelHeading,
  PanelRow,
  PanelActions,
} from "@backslashbuild/pp-ui";
import { useGet, usePost } from "./api";
import { publish } from "./pubSub";

const useStyles = makeStyles({
  wrapper: {
    width: "100% !important",
    display: "block !important",
    marginBottom: "25px !important",
    height: "600px !important",
    marginBottom: "100px",
  },
  editor: {
    width: "100% !important",
    padding: "6px 5px",
    display: "block !important",
    marginBottom: "25px !important",
    flexGrow: 1,
    boxSizing: "content-box",
    border: "1px solid #e3e9ef",
  },
  toolbar: {
    width: "100%",
    padding: "6px 5px",
    borderRadius: "2px",
    border: "1px solid #F1F1F1",
    display: "flex",
    justifyContent: "flex-start",
    background: "white",
    flexWrap: "wrap",
    fontSize: "15px",
    marginBottom: "5px",
    userSelect: "none",
  },
  preview: {
    width: "60%",
    height: "200px",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
  },
  detailsFormFields: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    padding: "8px",
  },
  viewSource: {
    width: "calc(100% + 12px)",
    marginLeft: "12px",
    maxHeight: 200,
    overflowY: "scroll",
  },
});

const TemplateEditorFormContainer = ({ id }) => {
  const { data, loading, error } = useGet(`template/${id}`);
  const updateTemplate = usePost(`template/${id}/update`);

  if (loading || !data) return <>Loading...</>;

  if (data?.errors)
    return (
      <>
        {data?.errors?.map((error) => {
          return <>{error}</>;
        })}
      </>
    );

  return (
    <TemplateEditorForm template={data.value} loading={loading} updateTemplate={updateTemplate} />
  );
};

const TemplateEditorForm = ({ template, loading, updateTemplate }) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);

  const [name, setName] = React.useState(template.name);
  const [content, setContent] = React.useState(template.content);
  const [isActive, setIsActive] = React.useState(template.isActive);
  const [type, setType] = React.useState(template.type);

  async function handleSave() {
    updateTemplate({ content: content, name: name, isActive: isActive, type: type })
      .then((r) => {
        if (r?.isSuccess) {
          publish("notification", "Template updated");
        } else {
          publish(
            "notification",
            <div>
              Unable to save template
              <ul>
                {r?.errors?.map((error) => (
                  <li>{error}</li>
                ))}
              </ul>
            </div>
          );
        }
      })
      .catch((e) => {
        console.log("a network error occurred");
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  return (
    <>
      <div className={classes.root}>
        <DetailsFormFields
          name={name}
          setName={setName}
          isActive={isActive}
          setIsActive={setIsActive}
        >
          <Button disabled={loading} onClick={() => setDialogOpen(true)}>
            Save
          </Button>
        </DetailsFormFields>
        <ContentEditor content={content} setContent={setContent} submitting={submitting} />
      </div>
      <PanelDialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <PanelHeading>Save template</PanelHeading>
        <PanelRow>
          Updating a template will cause any existing contracts which use this template to be
          cancelled and new contracts to be generated.
        </PanelRow>
        <PanelActions>
          <Button variant="secondary" onClick={() => setDialogOpen(false)}>
            Cancel
          </Button>
          <Button onClick={() => handleSave()}>Save and update</Button>
        </PanelActions>
      </PanelDialog>
    </>
  );
};

const Spacer = ({ grow, height }) => {
  return <div style={{ width: "10px", height, flexGrow: grow ? 1 : 0 }}></div>;
};

const DetailsFormFields = ({ name, setName, isActive, setIsActive, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.detailsFormFields}>
      <BoldText>Document Name</BoldText>
      <Spacer />
      <TextInput
        value={name}
        onChange={(e) => {
          setName(e.target.value);
        }}
      ></TextInput>
      <Spacer />

      <CheckBox
        checked={isActive}
        label={"Active"}
        onChange={(e) => {
          setIsActive(e.target.checked);
        }}
      ></CheckBox>
      <Spacer grow />
      {children}
    </div>
  );
};

const ContentEditor = ({ content, setContent, submitting }) => {
  const blocksFromHtml = htmlToDraft(content);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  const editorState = EditorState.createWithContent(contentState);
  const [state, setState] = React.useState(editorState);

  const classes = useStyles();

  const Icon = Icons.InfoIcon;

  React.useEffect(() => {
    setContent(draftToHtml(convertToRaw(state.getCurrentContent())));
  }, [state]);

  React.useEffect(() => {
    setState(EditorState.createWithContent(contentState));
  }, [submitting]);

  return (
    <>
      <Editor
        editorState={state}
        wrapperClassName={classes.wrapper}
        editorClassName={classes.editor}
        toolbarClassName={classes.toolbar}
        onEditorStateChange={(e) => {
          setState(e);
          setContent(draftToHtml(convertToRaw(state.getCurrentContent())));
        }}
      />
      <Spacer height={8} />
      <Rollover
        renderAnchor={() => (
          <div className={classes.detailsFormFields}>
            <BoldText>Edit Source</BoldText>
            <Icon />
            <Spacer grow />
          </div>
        )}
        placement="right"
      >
        <div className={classes.infoBox}>
          The HTML source for the contract can be manually edited below. Select "Save" to update the
          preview above.
        </div>
      </Rollover>
      <div className={classes.viewSource}>
        <TextInputMultiline
          width={"100%"}
          value={content}
          onChange={(e) => {
            setContent(e.target.value);
          }}
          rows={10}
        />
      </div>
    </>
  );
};

export default TemplateEditorFormContainer;
