import React from "react";
import {
  PageLoading,
  Panel,
  Table,
  TableCell,
  PanelDialog,
  TableBody,
  BoldText,
  TableRow,
  PanelHeading,
  Button,
  Label,
  PanelRow,
  Link,
} from "@backslashbuild/pp-ui";
import { useGetAgencyUmbrellaRates } from "./api";
import { isTextNullOrEmpty } from "./utils";

import UmbrellaRateForm from "./UmbrellaRateForm";

export default function AgencyUmbrellaRatesPanel({ agencyId }) {
  const [editUmbrellaRate, setEditUmbrellaRate] = React.useState(null);
  const { data, loading, error, queryId } = useGetAgencyUmbrellaRates({ agencyId });

  console.log(data);
  // const rfp = useRead(reference, async function (ref, write) {
  //   const result = await (await fetch(`http://localhost:5000/payroll/rfps/${ref}`)).json();
  //   write({
  //     [ref]: result,
  //   });
  // });

  if (loading) return <PageLoading />;
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (!data.isSuccess) return data?.errors?.map((error) => <div>{error}</div>);

  return (
    <>
      <Panel>
        <PanelHeading>Umbrella Rates</PanelHeading>
        <Table>
          <TableBody>
            {data.value.items.map((item) => (
              <TableRow onClick={() => setEditUmbrellaRate(item?.id)}>
                <PanelRow>
                  <Label label="Description">
                    <BoldText>{item?.description}</BoldText>
                  </Label>
                </PanelRow>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Panel>
      <PanelDialog open={editUmbrellaRate}>
        {editUmbrellaRate && (
          <UmbrellaRateForm
            umbrellaRateId={editUmbrellaRate}
            onClose={() => setEditUmbrellaRate(null)}
          />
        )}
      </PanelDialog>
    </>
  );
}
