import React from "react";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { subscribe, publish, unsubscribe } from "./pubSub";
import getApiUrl from "../../getApiUrl";
import { useQuery, queryCache } from "react-query";

const apiHost = getApiUrl();
const service = "nest";

export function useGet(cacheKey, path, serviceOverride) {
  if (!path) {
    path = cacheKey;
  }
  if (!serviceOverride) {
    serviceOverride = service;
  }

  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };

  const { data, error, isLoading: loading, updatedAt: queryId } = useQuery(
    cacheKey,
    async (key, id) => {
      return fetch(`${apiHost}/${serviceOverride}/${path}`, {
        headers: {
          ...authHeaders,
        },
      }).then((r) => r.json());
    }
  );

  const runQuery = () => queryCache.invalidateQueries(path);

  React.useEffect(() => {
    runQuery();
  }, [path]);

  return { data, loading, error, queryId, refetch: runQuery };
}

export function usePost(path) {
  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };

  return React.useCallback(
    (body) => {
      return fetch(`${apiHost}/${service}/${path}`, {
        method: "POST",
        headers: {
          ...authHeaders,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }).then((x) => {
        return x?.json();
      });
    },
    [path, authHeaders.Authorization]
  );
}

export function useGetEnrolmentExports() {
  const query = useGet(["enrolment-exports"], `enrolments`);
  return query;
}

export function useGetContributionSchedules(searchString) {
  const query = useGet([`contributions-schedules`], `contributions/schedules?${searchString}`);
  return query;
}

export function useGetContributionScheduleExport({ exportId }) {
  const query = useGet(
    [`contributions-schedule-export`],
    `contributions/schedule-export/${exportId}`
  );
  return query;
}

export function useGetContributionScheduleExports(contributionScheduleId) {
  const query = useGet(
    [`contributions-schedule-exports`],
    `contributions/schedule-exports/${contributionScheduleId}`
  );
  return query;
}

export function useGetContributionScheduleExportRow(id) {
  const query = useGet(
    [`contributions-schedule-export-row`],
    `contributions/schedule-export-row/${id}`
  );
  return query;
}

export function useGetContributionScheduleExportRows(path) {
  const query = useGet(
    [`contributions-schedule-export-rows`],
    `contributions/schedule-export-rows?${path}`
  );
  return query;
}

export function invalidateEnrolmentExports() {
  queryCache.invalidateQueries((query) => {
    const [cacheType] = query.queryKey;
    return cacheType === "enrolment-exports";
  });
}

export function invalidateContributions() {
  queryCache.invalidateQueries((query) => {
    const [cacheType] = query.queryKey;
    return cacheType === "contributions";
  });
}

export function useGetItems(path = "") {
  return useGet(`${path}`);
}

export function useUploadUnsuccessfulEnrolment(enrolmentExportId) {
  const post = usePostFile(`enrolments/${enrolmentExportId}/unsuccessful-enrolments`);

  return React.useCallback((body) => {
    return post(body).then((x) => {
      return x;
    });
  }, []);
}

export function useUploadUnsuccessfulContributions(contributionExportId) {
  const post = usePostFile(
    `contributions/schedule-exports/${contributionExportId}/unsuccessful-contribution`
  );

  return React.useCallback((body) => {
    return post(body).then((x) => {
      return x;
    });
  }, []);
}

export function useUploadEnrolmentErrorLog(enrolmentExportId) {
  const post = usePostFile(`enrolments/${enrolmentExportId}/enrolments-error-log`);

  return React.useCallback((body) => {
    return post(body).then((x) => {
      return x;
    });
  }, []);
}

export function useUploadContributionScheduleErrorLog(contributionExportId) {
  const post = usePostFile(
    `contributions/schedule-exports/${contributionExportId}/contributions-error-log`
  );

  return React.useCallback((body) => {
    return post(body).then((x) => {
      return x;
    });
  }, []);
}

export function usePostFile(path) {
  const { oidcUser } = useReactOidc();
  const authHeaders = { Authorization: `${oidcUser.token_type} ${oidcUser.access_token}` };

  return React.useCallback(
    (file) => {
      const body = new FormData();
      body.append("file", file);
      return fetch(`${apiHost}/${service}/${path}`, {
        method: "POST",
        headers: {
          ...authHeaders,
        },
        body,
      }).then((r) => r.json());
    },
    [path, authHeaders.Authorization]
  );
}
