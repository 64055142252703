import React from "react";
import { Button } from "@backslashbuild/pp-ui";
import { usePost, invalidateAllStatPayGroups, invalidateAllStatPays } from "./api";
import { publish } from "../../pubSub";

export default function CreateStatPayRFPButton({ statutoryPayId }) {
  const post = usePost("statutory-pays/create-rfp");

  async function handleSubmit() {
    const result = await post({
      statutoryPayId,
    });
    if (!result?.isSuccess) {
      var errors = result?.errors;
      publish(
        "notification",
        <ul>
          {errors?.map((error) => {
            return <li>{error}</li>;
          })}
        </ul>
      );
      return;
    }
    publish("notification", "RFP Created");
    invalidateAllStatPayGroups();
    invalidateAllStatPays();
  }

  return <Button onClick={() => handleSubmit()}>Create</Button>;
}
