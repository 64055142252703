import React from "react";
import {
  Panel,
  PanelHeading,
  Table,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableRow,
  TableCell,
} from "@backslashbuild/pp-ui";
import { formatCurrency } from "../../utils";

function SalaryEntryTable({ payslip }) {
  return (
    <Panel maxWidth={474 * 2}>
      <PanelHeading>Salary items</PanelHeading>
      <Table width={800}>
        <TableHeader>
          <TableHeaderColumn width="200px">Agency/Client</TableHeaderColumn>
          <TableHeaderColumn width="200px">Name of Workplace</TableHeaderColumn>
          <TableHeaderColumn width="100px" alignRight>
            Hours
          </TableHeaderColumn>
          <TableHeaderColumn width="100px" alignRight>
            Days
          </TableHeaderColumn>
          <TableHeaderColumn width="100px" alignRight>
            Rate
          </TableHeaderColumn>
          <TableHeaderColumn width="100px" alignRight>
            Total
          </TableHeaderColumn>
        </TableHeader>
        <TableBody>
          {payslip.salaryEntries.map((entry) => (
            <TableRow disableHover>
              <TableCell width="200px">{entry.agencyOrClient}</TableCell>
              <TableCell width="200px">{entry.nameOfWorkplace}</TableCell>
              <TableCell width="100px" alignRight>
                {entry.hours}
              </TableCell>
              <TableCell width="100px" alignRight>
                {entry.days}
              </TableCell>
              <TableCell width="100px" alignRight>
                {entry.rate}
              </TableCell>
              <TableCell width="100px" alignRight>
                {formatCurrency(entry.total)}
              </TableCell>
            </TableRow>
          ))}
          <TableRow disableHover>
            <TableCell shaded mini width={600} colSpan={5} alignRight>
              Invoice Total
            </TableCell>
            <TableCell summary alignRight width={100}>
              {formatCurrency(payslip.invoiceTotal)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Panel>
  );
}

export default SalaryEntryTable;
