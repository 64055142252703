import React from "react";
import { Panel, PanelActions, PanelHeading, Button, PanelRow } from "@backslashbuild/pp-ui";
import { useClearInvoice } from "./api";
import { BoldText } from "@backslashbuild/pp-ui/dist/index.es";
import { publish } from "../../pubSub";

export default function ClearInvoiceForm({ invoice, onClose }) {
  const clear = useClearInvoice();

  async function handleSave() {
    clear(invoice.invoiceNumber).then((res) => {
      if (res?.isSuccess) {
        publish("notification", "Invoice cleared");
        onClose();
      } else {
        publish("notification", "Unable to clear invoice: " + res.errors);
        console.log(res.errors);
      }
    });
  }

  return (
    <>
      <Panel>
        <PanelHeading>Clear Invoice</PanelHeading>
        <PanelRow>
          <BoldText>This will clear the invoice. Are you sure you want to continue?</BoldText>
        </PanelRow>
        <PanelActions>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSave}>Confirm</Button>
        </PanelActions>
      </Panel>
    </>
  );
}
