import React from "react";
import { Table, TableHeader, TableHeaderColumn, TableRow, TableBody } from "@backslashbuild/pp-ui";
import CustomTableCell from "../../components/CustomTableCell";
import GreenTick from "../../components/GreenTick";
import { useGetPEOValues } from "../internal-comms/CommsPanelApp/api";

export default function PEOSalesPipelineTable({ id }) {
  const { data, loading, error } = useGetPEOValues({ itemId: id });

  if (loading) return "Loading...";

  if (error) return <div>Unable to load PEO Sales Pipeline</div>;

  return (
    <>
      <Table standardTable height={600}>
        <TableHeader>
          <TableHeaderColumn width="100%">PEO Sales Pipeline</TableHeaderColumn>
        </TableHeader>
        <TableBody>
          <TableRow disableHover>
            <CustomTableCell white backgroundColour={"#000000"}>
              10
            </CustomTableCell>
            <CustomTableCell white backgroundColour={"#0d0d0d"}>
              9
            </CustomTableCell>
            <CustomTableCell white backgroundColour={"#1a1a1a"}>
              8
            </CustomTableCell>
            <CustomTableCell white backgroundColour={"#262626"}>
              7
            </CustomTableCell>
            <CustomTableCell white backgroundColour={"#333333"}>
              6
            </CustomTableCell>
            <CustomTableCell white backgroundColour={"#404040"}>
              5
            </CustomTableCell>
            <CustomTableCell white backgroundColour={"#4d4d4d"}>
              4
            </CustomTableCell>
            <CustomTableCell white backgroundColour={"#595959"}>
              3
            </CustomTableCell>
            <CustomTableCell white backgroundColour={"#666666"}>
              2
            </CustomTableCell>
            <CustomTableCell white backgroundColour={"#737373"}>
              1
            </CustomTableCell>
          </TableRow>
          <TableRow disableHover>
            <CustomTableCell>
              {data?.achievements?.indexOf(10) !== -1 && <GreenTick />}
            </CustomTableCell>
            <CustomTableCell>
              {data?.achievements?.indexOf(9) !== -1 && <GreenTick />}
            </CustomTableCell>
            <CustomTableCell>
              {data?.achievements?.indexOf(8) !== -1 && <GreenTick />}
            </CustomTableCell>
            <CustomTableCell>
              {data?.achievements?.indexOf(7) !== -1 && <GreenTick />}
            </CustomTableCell>
            <CustomTableCell>
              {data?.achievements?.indexOf(6) !== -1 && <GreenTick />}
            </CustomTableCell>
            <CustomTableCell>
              {data?.achievements?.indexOf(5) !== -1 && <GreenTick />}
            </CustomTableCell>
            <CustomTableCell>
              {data?.achievements?.indexOf(4) !== -1 && <GreenTick />}
            </CustomTableCell>
            <CustomTableCell>
              {data?.achievements?.indexOf(3) !== -1 && <GreenTick />}
            </CustomTableCell>
            <CustomTableCell>
              {data?.achievements?.indexOf(2) !== -1 && <GreenTick />}
            </CustomTableCell>
            <CustomTableCell>
              {data?.achievements?.indexOf(1) !== -1 && <GreenTick />}
            </CustomTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
}
