import React from "react";

import {
  Content,
  PageHeadingArea,
  PageTitle,
  PageHeadingAreaSpacer,
  BackArrow,
} from "@backslashbuild/pp-ui";

import FPSEmploymentsTable from "../modules/payroll/FPSEmploymentsTable";
import { useHistory } from "react-router-dom";

function FPSs() {
  const history = useHistory();

  return (
    <Content>
      <PageHeadingArea>
        <BackArrow onClick={() => history.goBack()} />
        <PageHeadingAreaSpacer />
        <PageTitle>RTI - Full Payment Submission (FPS)</PageTitle>
        <PageHeadingAreaSpacer grow />
      </PageHeadingArea>
      <FPSEmploymentsTable />
    </Content>
  );
}

export default FPSs;
