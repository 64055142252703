import React from "react";

import { Content } from "@backslashbuild/pp-ui";

import SMSTemplatesTable from "../modules/emails/SMSTemplatesTable";

function SMSManagement() {
  return (
    <Content>
      <SMSTemplatesTable />
    </Content>
  );
}

export default SMSManagement;
