import React from "react";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { useQuery, queryCache } from "react-query";
import getApiUrl from "../../getApiUrl";

const apiHost = getApiUrl();
const service = "tax-calendar";

export function useGet(cacheKey, path, serviceOverride, cacheTime, enabled = true) {
  if (!path) {
    path = cacheKey;
  }
  if (!serviceOverride) {
    serviceOverride = service;
  }

  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };

  const { data, error, isLoading: loading, updatedAt: queryId } = useQuery(
    cacheKey,
    async (key, id) => {
      return fetch(`${apiHost}/${serviceOverride}/${path}`, {
        headers: {
          ...authHeaders,
        },
      }).then((r) => r.json());
    },
    {
      cacheTime,
      enabled,
    }
  );

  const runQuery = () => queryCache.invalidateQueries(path);

  React.useEffect(() => {
    runQuery();
  }, [path]);

  return { data, loading, error, queryId, refetch: runQuery };
}

export function usePost(path) {
  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };

  return React.useCallback(
    (body) => {
      return fetch(`${apiHost}/${service}/${path}`, {
        method: "POST",
        headers: {
          ...authHeaders,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }).then((x) => {
        return x.json();
      });
    },
    [path, authHeaders.Authorization]
  );
}

export function useGetTaxWeeks(path) {
  const query = useGet([`tax-weeks`], `tax-weeks${path}`);
  return query;
}

export function useGetTaxMonths(path) {
  const query = useGet([`tax-months`], `tax-months${path}`);
  return query;
}

export function useGetTaxYear(path) {
  const query = useGet([`tax-years`], `tax-years${path}`);
  return query;
}

export function useGetTaxPeriodsByTaxDate(taxDate) {
  const query = useGet([`tax-periods`], `tax-periods/${taxDate}`);
  return query;
}
