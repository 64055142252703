import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Link,
  TableRow,
  Table,
  TableCell,
  TableHeaderColumn,
  TableBody,
  Pill,
  PanelHeading,
  PopoverMenu,
  PanelSpacer,
  Panel,
} from "@backslashbuild/pp-ui";
import { useGetSchedule, useGetRows } from "./api";
import { formatCurrency, utcToYmd } from "../../utils";
import { DateTime } from "luxon";
import RFPStatusPill from "../payroll/RFPStatusPill";
import ContractorProductName from "./ContractorProductName";
import ObjectMap from "../../components/ObjectMap";
import { utcToMillis } from "../../utils";
import RowSettingsPicker from "./RowSettingsPicker";
import DeleteManualRowButton from "./DeleteManualRowButton";
import RowDescriptionDialog from "./RowDescriptionDialog";
import AdditionalPeriodsDialog from "./AdditionalPeriodsDialog";
import ContractorProductContractorNameRollOver from "../contractors/ContractorProductContractorNameRollOver";

const c = {
  groupIndex: 10,
  contractor: 120,
  age: 60,
  invoiceMode: 60,
  type: 80,
  timesheetDate: 100,
  item: 120,
  rowIndex: 10,
  description: 80,
  subTotal: 90,
  errors: 100,
  settings: 200,
  additionalPeriods: 80,
  matchEditor: 80,
  delete: 80,

  product: 80,
  grossPay: 50,
  peoUplift: 50,
  empNic: 50,
  appLevy: 50,
  empPens: 50,
  payeFee: 50,
  weeklyHours: 60,
  vat: 80,
  invoiceTotal: 80,
  numberOfPeriods: 80,
  // info: 200,
};

const columnCount = Object.keys(c).length;
const totalWidth = Object.keys(c).reduce((agg, r) => agg + c[r], 0);

// const totalWidth = Object.keys(c).reduce((total, key) => (total += c[key]), 0);

function PureTable({ groups, rows, queryId, scheduleId }) {
  return groups
    .sort((g1, g2) => {
      const g1Name = (g1.contractorForenames + g1.contractorSurname).toLowerCase();
      const g2Name = (g2.contractorForenames + g2.contractorSurname).toLowerCase();

      if (g1Name > g2Name) {
        return 1;
      }
      if (g2Name > g1Name) {
        return -1;
      }
      return 0;
    })
    .map((group, index) => (
      <TimesheetHeaderGroup
        scheduleId={scheduleId}
        index={index + 1}
        group={group}
        rows={rows
          .filter((r) => r.groupId === group.id)
          .sort((a, b) => {
            return utcToMillis(a.dateCreated) - utcToMillis(b.dateCreated);
          })}
      />
    ));
}

function TableSkeleton() {
  return (
    <TableRow>
      <TableCell width={totalWidth}>loading</TableCell>
    </TableRow>
  );
}

function ScheduleTimesheetsTable({ id }) {
  const schedule = useGetSchedule(id);
  const rows = useGetRows({ scheduleId: id });

  const isLoaded = Boolean(rows.data?.value && schedule.data?.value);

  return (
    <>
      {rows.data?.value?.items?.filter((r) => r.matchedContractorProductId)?.length > 0 && (
        <>
          <Panel maxWidth="100%">
            <PanelHeading>Matched Contractors</PanelHeading>
            <Table width={totalWidth} standardTable>
              <TableBody>
                <TimesheetTableHeaders />
                {isLoaded ? (
                  <PureTable
                    scheduleId={id}
                    groups={schedule.data?.value?.groups.filter(
                      (r) => r.matchedContractorProductId
                    )}
                    rows={rows.data?.value?.items.filter((r) => r.matchedContractorProductId)}
                    queryId={rows.queryId}
                  />
                ) : rows.data?.errors ? (
                  rows.data?.errors.map((error) => <div>{error}</div>)
                ) : (
                  <TableSkeleton />
                )}
              </TableBody>
            </Table>
          </Panel>
          <PanelSpacer large />
        </>
      )}
      {rows.data?.value?.items?.filter((r) => !r.matchedContractorProductId)?.length > 0 && (
        <Panel maxWidth="100%">
          <PanelHeading>Unmatched contractors</PanelHeading>
          <Table width={totalWidth}>
            <TableBody>
              <TimesheetTableHeaders />

              {isLoaded ? (
                <PureTable
                  scheduleId={id}
                  groups={schedule.data?.value?.groups.filter((r) => !r.matchedContractorProductId)}
                  rows={rows.data?.value?.items.filter((r) => !r.matchedContractorProductId)}
                  queryId={rows.queryId}
                />
              ) : rows.data?.errors ? (
                rows.data?.errors.map((error) => <div>{error}</div>)
              ) : (
                <TableSkeleton />
              )}
            </TableBody>
          </Table>
        </Panel>
      )}
    </>
  );
}

function TimesheetTableHeaders() {
  return (
    <TableRow disableHover>
      {/* Group header stuff */}
      <TableHeaderColumn width={c.groupIndex}></TableHeaderColumn>
      <TableHeaderColumn width={c.contractor}>
        <small>Contractor</small>
      </TableHeaderColumn>
      <TableHeaderColumn width={c.age}>
        <small>Age</small>
      </TableHeaderColumn>

      {/* Row stuff  */}
      {/* <TableHeaderColumn width={c.type}>Row Type</TableHeaderColumn> */}
      <TableHeaderColumn width={c.rowIndex}></TableHeaderColumn>
      <TableHeaderColumn width={c.timesheetDate}>
        <small>T/S Date</small>
      </TableHeaderColumn>
      <TableHeaderColumn width={c.item}>
        <small>Item</small>
      </TableHeaderColumn>
      <TableHeaderColumn width={c.description} alignRight>
        <small>Description</small>
      </TableHeaderColumn>
      <TableHeaderColumn width={c.subTotal} alignRight>
        <small>Sub Total</small>
      </TableHeaderColumn>
      <TableHeaderColumn width={c.errors}>
        <small>Errors</small>
      </TableHeaderColumn>
      <TableHeaderColumn width={c.settings}>
        <small>Settings</small>
      </TableHeaderColumn>
      <TableHeaderColumn width={c.additionalPeriods}>
        <small>
          Additional
          <br />
          Periods
        </small>
      </TableHeaderColumn>
      <TableHeaderColumn width={c.matchEditor}></TableHeaderColumn>
      <TableHeaderColumn width={c.delete}></TableHeaderColumn>

      {/* Extra row shit. */}
      {/* <TableHeaderColumn width={c.errors}>Issues</TableHeaderColumn>
    <TableHeaderColumn width={c.rfp}>RFP</TableHeaderColumn> */}

      <TableHeaderColumn width={c.product}>
        <small>Product</small>
      </TableHeaderColumn>
      <TableHeaderColumn width={c.grossPay} alignRight>
        <small>Gross</small>
      </TableHeaderColumn>
      <TableHeaderColumn width={c.peoUplift} alignRight>
        <small>Uplift</small>
      </TableHeaderColumn>

      {/* Employer costs for PAYE */}
      <TableHeaderColumn width={c.empNic} alignRight>
        <small>NIC</small>
      </TableHeaderColumn>
      <TableHeaderColumn width={c.appLevy} alignRight>
        <small>App Levy</small>
      </TableHeaderColumn>
      <TableHeaderColumn width={c.empPens} alignRight>
        <small>Pens</small>
      </TableHeaderColumn>
      <TableHeaderColumn width={c.payeFee} alignRight>
        <small>PAYE Fee</small>
      </TableHeaderColumn>

      <TableHeaderColumn width={c.weeklyHours} alignRight>
        <small>
          NMW
          <br />
          Hours
        </small>
      </TableHeaderColumn>
      <TableHeaderColumn width={c.vat} alignRight>
        <small>VAT</small>
      </TableHeaderColumn>
      <TableHeaderColumn width={c.invoiceTotal} alignRight>
        <small>
          Invoice
          <br />
          Total
        </small>
      </TableHeaderColumn>
      <TableHeaderColumn width={c.numberOfPeriods} alignRight>
        <small>
          Total <br /> Periods
        </small>
      </TableHeaderColumn>
      {/* <TableHeaderColumn width={c.info}>Info</TableHeaderColumn> */}

      {/* Summary stuff */}
    </TableRow>
  );
}

function TimesheetHeaderGroup({ index, group, rows, scheduleId, open, setOpen }) {
  const shadedContractorCells = (
    <>
      <TableCell thickBorder width={c.groupIndex} shaded></TableCell>
      <TableCell thickBorder width={c.contractor} shaded></TableCell>
      <TableCell thickBorder width={c.age} shaded borderRight></TableCell>
    </>
  );
  const shadedContractorCellsWithNoBottomBorder = (
    <>
      <TableCell width={c.groupIndex} shaded noBorderBottom></TableCell>
      <TableCell width={c.contractor} shaded noBorderBottom></TableCell>
      <TableCell width={c.age} shaded borderRight noBorderBottom></TableCell>
    </>
  );

  const shadedSummaryCells = (
    <>
      <TableCell shaded width={c.product}></TableCell>
      <TableCell shaded width={c.grossPay} alignRight></TableCell>
      <TableCell shaded width={c.peoUplift} alignRight></TableCell>

      {/* Employer costs for PAYE */}
      <TableCell shaded width={c.empNic} alignRight></TableCell>
      <TableCell shaded width={c.appLevy} alignRight></TableCell>
      <TableCell shaded width={c.empPens} alignRight></TableCell>
      <TableCell shaded width={c.payeFee} alignRight></TableCell>

      <TableCell shaded width={c.weeklyHours} alignRight></TableCell>
      <TableCell shaded width={c.vat} alignRight></TableCell>
      <TableCell shaded width={c.invoiceTotal} alignRight></TableCell>
      <TableCell shaded width={c.numberOfPeriods} alignRight></TableCell>
      {/* <TableCell shaded width={c.info}></TableCell> */}
    </>
  );

  const rowCells = rows.map((row, rowIndex) => (
    <>
      {/* Row stuff  */}

      {/* <TableCell width={c.type}>
        <RowTypePicker row={row} />
        
      </TableCell> */}
      <TableCell thickBorder={rowIndex + 1 == rows?.length} width={c.rowIndex}>
        <SettingsPopover data={row}>{rowIndex + 1}</SettingsPopover>
      </TableCell>
      <TableCell thickBorder={rowIndex + 1 == rows?.length} width={c.timesheetDate}>
        <small>{row.timesheetDate ? utcToYmd(row.timesheetDate) : "-"}</small>
      </TableCell>
      <TableCell thickBorder={rowIndex + 1 == rows?.length} width={c.item}>
        <small>{row.item}</small>
      </TableCell>
      <TableCell thickBorder={rowIndex + 1 == rows?.length} width={c.description} alignRight>
        <RowDescriptionDialog row={row} scheduleId={scheduleId} />
      </TableCell>
      <TableCell thickBorder={rowIndex + 1 == rows?.length} width={c.subTotal} alignRight>
        <small>{formatCurrency(row.subTotal)}</small>
      </TableCell>
      <TableCell thickBorder={rowIndex + 1 == rows?.length} width={c.errors}>
        {row.errors.length > 0 && <ErrorsMenu errors={row.errors} />}
      </TableCell>
      <TableCell thickBorder={rowIndex + 1 == rows?.length} width={c.settings}>
        <RowSettingsPicker row={row} scheduleId={scheduleId} />
        {row.rfpReference && <RFPStatusPill reference={row.rfpReference} />}
      </TableCell>
      <TableCell thickBorder={rowIndex + 1 == rows?.length} width={c.description} alignRight>
        <AdditionalPeriodsDialog row={row} scheduleId={scheduleId} />
      </TableCell>
      <TableCell thickBorder={rowIndex + 1 == rows?.length} width={c.matchEditor}>
        {row?.matchId && (
          <Link component={RouterLink} to={`/schedules-c-matches?matchId=${row?.matchId}`}>
            M
          </Link>
        )}
      </TableCell>
      <TableCell thickBorder={rowIndex + 1 == rows?.length} width={c.delete} borderRight>
        <DeleteManualRowButton row={row} scheduleId={scheduleId} />
      </TableCell>
    </>
  ));

  const summaryCells = (
    <>
      <TableCell thickBorder width={c.product}>
        <small>{group.productName}</small>
      </TableCell>
      <TableCell thickBorder width={c.grossPay} alignRight>
        <small>{formatCurrency(group.gross)} </small>{" "}
      </TableCell>
      <TableCell thickBorder width={c.peoUplift} alignRight>
        <small>{formatCurrency(group.peoUplift)} </small>{" "}
      </TableCell>

      {/* Employer costs for PAYE */}
      <TableCell thickBorder width={c.empNic} alignRight>
        <small>{formatCurrency(group.employerNIC)} </small>{" "}
      </TableCell>
      <TableCell thickBorder width={c.appLevy} alignRight>
        <small>{formatCurrency(group.apprenticeshipLevy)} </small>{" "}
      </TableCell>
      <TableCell thickBorder width={c.empPens} alignRight>
        <small>{formatCurrency(group.employerPension)} </small>{" "}
      </TableCell>
      <TableCell thickBorder width={c.payeFee} alignRight>
        <small>{formatCurrency(group.payeFee)} </small>{" "}
      </TableCell>

      <TableCell thickBorder width={c.weeklyHours} alignRight>
        <small>{group.totalHours} </small>{" "}
      </TableCell>
      <TableCell thickBorder width={c.vat} alignRight>
        <small>{formatCurrency(group.vat)} </small>{" "}
      </TableCell>
      <TableCell thickBorder width={c.invoiceTotal} alignRight>
        <small>{formatCurrency(group.invoiceTotal)} </small>{" "}
      </TableCell>
      <TableCell thickBorder width={c.numberOfPeriods} alignRight>
        <small>{group.numberOfPeriods} </small>{" "}
      </TableCell>
    </>
  );

  const missingRows = rowCells.length === 0;
  const isMultipleRows = rowCells.length > 1;

  return (
    <>
      <TableRow disableHover>
        <TableCell
          shaded
          noBorderBottom={isMultipleRows}
          thickBorder={!isMultipleRows}
          width={c.groupIndex}
        >
          <SettingsPopover data={group}>{index}</SettingsPopover>
        </TableCell>
        <TableCell
          shaded
          noBorderBottom={isMultipleRows}
          thickBorder={!isMultipleRows}
          width={c.contractor}
        >
          {/* <ExpandButton open /> */}
          {group.matchedContractorProductId ? (
            <small>
              <ContractorProductContractorNameRollOver
                contractorProductId={group.matchedContractorProductId}
              />
            </small>
          ) : (
            <small>
              {group.contractorNumber} {group.contractorForenames} {group.contractorSurname}
            </small>
          )}

          {/* {JSON.stringify(group)} */}
        </TableCell>
        <TableCell
          shaded
          noBorderBottom={isMultipleRows}
          thickBorder={!isMultipleRows}
          borderRight
          width={c.age}
        >
          <small>
            {group.contractorAge} {formatCurrency(group.contractorMinimumWage)}
          </small>
        </TableCell>
        {missingRows ? (
          <TableCell
            colSpan={columnCount - 3}
            shaded
            width={totalWidth - c.contractor - c.age - c.groupIndex}
            thickBorder={!isMultipleRows}
          />
        ) : (
          rowCells[0]
        )}
        {isMultipleRows ? shadedSummaryCells : summaryCells}
      </TableRow>
      {rowCells.slice(1).map((row, rowIndex) => (
        <TableRow disableHover>
          {rowIndex + 2 === rowCells.length
            ? shadedContractorCells
            : shadedContractorCellsWithNoBottomBorder}
          {row}
          {rowIndex + 2 === rowCells.length ? summaryCells : shadedSummaryCells}
        </TableRow>
      ))}
    </>
  );
}

function ErrorsMenu({ errors }) {
  const [open, setOpen] = React.useState(false);

  return (
    <PopoverMenu
      open={open}
      onClose={() => setOpen(false)}
      renderAnchor={(ref) => (
        <Pill ref={ref} onClick={() => setOpen(true)} status="error">
          {errors.length}
        </Pill>
      )}
    >
      <div style={{ padding: 20 }}>
        {errors.map((error) => (
          <div>
            <Pill status="error">{error}</Pill>
          </div>
        ))}
      </div>
    </PopoverMenu>
  );
}

function SettingsPopover({ data, children }) {
  const [open, setOpen] = React.useState(false);

  return (
    <PopoverMenu
      open={open}
      onClose={() => setOpen(false)}
      renderAnchor={(ref) => (
        <small ref={ref} onClick={() => setOpen(true)}>
          {children}
        </small>
      )}
    >
      <ObjectMap data={data} />
    </PopoverMenu>
  );
}

export default ScheduleTimesheetsTable;
