import React from "react";
import { DateTime } from "luxon";
import {
  Button,
  PanelDialog,
  PanelHeading,
  PanelRow,
  PanelSpacer,
  Label,
  PanelActions,
  Dropdown,
  DropdownOption,
  DatePicker,
  BoldText,
} from "@backslashbuild/pp-ui";
import { usePost } from "./api";
import CompanyPicker from "./CompanyPicker";
import { publish } from "../../pubSub";
import { useHistory } from "react-router-dom";
import PayFrequencyPicker from "./PayFrequencyPicker";

function NewPayRunButton() {
  const history = useHistory();

  // you have to query to see if there are any currently staged pay runs and then disable the button.
  // const { data, loading, error, queryId } = useGet("payruns");
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [request, setRequest] = React.useState({
    payRunDate: DateTime.utc().toFormat("yyyy-MM-dd"),
  });

  const createPayRun = usePost("payruns/stage");

  const readyToSubmit = request.payRunDate !== null && !submitting;

  async function handleSubmit() {
    setSubmitting(true);
    try {
      const response = await createPayRun(request);

      if (response.isSuccess) {
        publish("notification", "Pay Run Created");
        history.push(`/payrun/${response.value}`);
        // TODO navigate to /payrun/{response.value}
      } else {
        publish(
          "notification",
          <div>
            <p>Error:</p>
            <ul>
              {response.errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </div>
        );
      }
    } catch (e) {
      console.error(e, "error making new pay run");
      publish("notification", "A network error occured.");
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <>
      <Button large onClick={() => setDialogOpen(true)}>
        New Pay Run
      </Button>
      <PanelDialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <PanelHeading>Stage New Pay Run</PanelHeading>
        <PanelSpacer large />
        <PanelRow>
          <BoldText>
            This will stage a new empty pay run for the date specified. You can only have one pay
            run staged at a time. You will be able to add RFPs to this pay run from the RFP page,
            which will then create payslips for those employees.
          </BoldText>
        </PanelRow>

        <PanelSpacer large />

        <PanelRow>
          <Label label="Pay Run Date">
            <DatePicker
              value={request.payRunDate}
              onChange={(v) => setRequest({ ...request, payRunDate: v })}
            />
          </Label>
        </PanelRow>
        <PanelSpacer large />
        <PanelActions>
          <Button loading={submitting} variant="secondary" onClick={() => setDialogOpen(false)}>
            Cancel
          </Button>
          <Button loading={submitting} disabled={!readyToSubmit} onClick={handleSubmit}>
            Stage Pay Run
          </Button>
        </PanelActions>
        <PanelSpacer />
      </PanelDialog>
    </>
  );
}

export default NewPayRunButton;
