import {
  Label,
  Panel,
  PanelDialog,
  Button,
  PanelHeading,
  PanelActions,
} from "@backslashbuild/pp-ui";
import React from "react";
import { useGet, usePost } from "./api";
import { isTextNullOrEmpty } from "../contractors/utils";
import { PanelRow } from "@backslashbuild/pp-ui/dist/index.es";
import { publish } from "./pubSub";

export default function UpdateEnrolmentStatusDialog({ accountNumber }) {
  const [dialogOpen, setDialogOpen] = React.useState(null);
  const { data, loading, error, refetch } = useGet("employments/employment/" + accountNumber);

  const update = usePost("employments/update-enrollment");

  async function handleSubmit(hasBeenEnrolled) {
    try {
      const response = await update({ accountNumber, hasBeenEnrolled });

      if (response.isSuccess) {
        publish("notification", "Updated successfully");
        setDialogOpen(false);
        refetch();
      } else {
        publish(
          "notification",
          <div>
            Unable to update employment
            <ul>
              {response.errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </div>
        );
      }
    } catch (e) {
      console.error(e, "error updating");
      publish("notification", "A network error occured.");
    }
  }

  if (loading) return <div>Loading</div>;

  if (!isTextNullOrEmpty(error)) return <Label long label="Failed to load NEST Enrolment Status" />;

  if (data != null && !data.isSuccess) {
    return data.errors.map((error) => <div>{error}</div>);
  }

  return (
    <>
      <div onClick={() => setDialogOpen(true)}>
        <Button onClick={() => setDialogOpen(true)}>
          {data?.value?.hasBeenEnrolled ? "Mark as Not Enrolled" : "Mark as Enrolled"}
        </Button>
      </div>
      <PanelDialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <Panel>
          <PanelHeading>Update NEST Enrollment Status</PanelHeading>
          <PanelRow>
            {`Current status for this enrolment: ${
              data?.value?.hasBeenEnrolled ? "enrolled with NEST" : "not enrolled with NEST"
            }`}
          </PanelRow>
          <PanelActions>
            <Button variant="secondary" onClick={() => setDialogOpen(false)}>
              Cancel
            </Button>
            {data?.value?.hasBeenEnrolled ? (
              <Button onClick={() => handleSubmit(false)} disabled={!data?.value?.hasBeenEnrolled}>
                Mark as Not Enrolled
              </Button>
            ) : (
              <Button onClick={() => handleSubmit(true)} disabled={data?.value?.hasBeenEnrolled}>
                Mark as Enrolled
              </Button>
            )}
          </PanelActions>
        </Panel>
      </PanelDialog>
    </>
  );
}
