import React from "react";
import { Tree, TreeItem, Icons } from "@backslashbuild/pp-ui";
import { useGet } from "./api";
import queryString from "query-string";
import { useLocation, Link as RouterLink, useHistory } from "react-router-dom";

function useTotalFromQuery(query) {
  const stringifiedQuery = queryString.stringify({
    ...query,
    take: 0,
  });

  const { data } = useGet(`payslips?${stringifiedQuery}`);

  return data?.value?.itemsTotal ?? "-";
}

function PayslipTableFilterLinks({ id }) {
  const totalAll = useTotalFromQuery({
    payRunId: id,
  });
  const totalErrors = useTotalFromQuery({
    payRunId: id,
    hasErrors: true,
  });
  const totalWarnings = useTotalFromQuery({
    payRunId: id,
    hasWarnings: true,
  });

  const location = useLocation();
  const selected = location.search || "all";
  const history = useHistory();
  return (
    <Tree
      selected={selected}
      onNodeSelect={(event, nodeIds) => {
        if (nodeIds === "all") {
          history.replace(location.pathname);
        } else {
          history.replace({ search: nodeIds });
        }
      }}
    >
      <TreeItem
        nodeId="all"
        labelIcon={Icons.LabelIcon}
        labelText="View all"
        labelInfo={totalAll}
      ></TreeItem>
      <TreeItem
        nodeId="?hasWarnings=yes"
        labelIcon={Icons.WarningIcon}
        labelText="With warnings"
        labelInfo={totalWarnings}
        replace
        component={RouterLink}
        to={`/payrun/${id}?hasWarnings=yes`}
      ></TreeItem>
      <TreeItem
        nodeId="?hasErrors=yes"
        labelIcon={Icons.ErrorIcon}
        labelText="With Errors"
        labelInfo={totalErrors}
        component={RouterLink}
        to={`/payrun/${id}?hasWarnings=yes`}
      />
    </Tree>
  );
}

export default PayslipTableFilterLinks;
