import React from "react";
import {
  PanelRow,
  TextInput,
  Label,
  Radio,
  RadioGroup,
  Dropdown,
  DropdownOption,
  CheckBox,
} from "@backslashbuild/pp-ui";
import { utcToLocalText, localToUtcIso, textToBoolean } from "./utils";

export default function FormField({ field, onSave }) {
  let SubComponent = TextField;

  if (field.schema.type === "date") SubComponent = DateField;
  if (field.schema.type === "radio") SubComponent = RadioField;
  if (field.schema.type === "dropdown") SubComponent = DropdownField;
  if (field.schema.type === "checkbox") SubComponent = CheckboxField;
  if (field.schema.type === "textbox") SubComponent = TextBoxField;

  return (
    <PanelRow>
      <Label label={field.displayName}>
        <SubComponent field={field} onSave={onSave} />
      </Label>
    </PanelRow>
  );
}

function TextField({ field, onSave }) {
  const [value, setValue] = React.useState(field.value);

  React.useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  return (
    <TextInput
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      onBlur={() => {
        onSave(value);
      }}
    ></TextInput>
  );
}

function RadioField({ field, onSave }) {
  const [value, setValue] = React.useState(field.value);

  React.useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  return (
    <RadioGroup
      value={value}
      onChange={(e) => {
        onSave(e.target.value);
        setValue(e.target.value);
      }}
    >
      {field.schema.options.map((option) => (
        <Radio value={option.value} label={option.displayName} />
      ))}
    </RadioGroup>
  );
}

function DropdownField({ field, onSave }) {
  const [value, setValue] = React.useState(field.value);

  React.useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  return (
    <Dropdown
      value={value}
      onChange={(e) => {
        onSave(e.target.value);
        setValue(e.target.value);
      }}
    >
      {field.schema.options.map((option) => (
        <DropdownOption value={option.value}>{option.displayName}</DropdownOption>
      ))}
    </Dropdown>
  );
}

function CheckboxField({ field, onSave }) {
  const [value, setValue] = React.useState(textToBoolean(field.value));
  const label = field.schema.options[0].displayName;

  React.useEffect(() => {
    setValue(textToBoolean(field.value));
  }, [field.value]);

  return (
    <CheckBox
      checked={value}
      label={label}
      onChange={(e) => {
        onSave(String(e.target.checked));
        setValue(e.target.checked);
      }}
    ></CheckBox>
  );
}

function DateField({ field, onSave }) {
  const [value, setValue] = React.useState(utcToLocalText(field.value));

  React.useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  return (
    <TextInput
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      onBlur={() => {
        onSave(localToUtcIso(value));
      }}
    ></TextInput>
  );
}

function TextBoxField({ field, onSave }) {
  const [value, setValue] = React.useState(field.value);

  React.useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  return (
    <TextInput
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      onBlur={() => {
        onSave(value);
      }}
    ></TextInput>
  );
}
