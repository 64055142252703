import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useGetDraftFpsById } from "./api";
import queryString from "query-string";
import { utcToLocalText } from "./utils";
import ApproveDraft from "./ApproveDraft";
import ReloadDraft from "./ReloadDraft";
import DeactivateDraft from "./DeactivateDraft";
import ViewDraftErrors from "./ViewDraftErrors";

import {
  Button,
  PageHeadingArea,
  BackArrow,
  PageHeadingAreaSpacer,
  PageTitle,
} from "@backslashbuild/pp-ui";

export default function DraftFPSEmployeeHeading() {
  const history = useHistory();
  const location = useLocation();
  const [state, setState] = React.useState(queryString.parse(location.search));
  const { data, loading, error, queryId, refetch } = useGetDraftFpsById(state?.draftId);

  return (
    <PageHeadingArea>
      <BackArrow onClick={() => history.goBack()} />
      <PageHeadingAreaSpacer />
      <PageTitle>
        {data?.isSuccess &&
          `Draft FPS: ${data?.value?.companyName} for ${utcToLocalText(
            data?.value?.taxDate,
            "dd MMM yyyy"
          )}`}
        {!data?.isSuccess && `Draft FPS:`}
      </PageTitle>
      <PageHeadingAreaSpacer grow />
      <ViewDraftErrors data={data} />
      <DeactivateDraft draft={data?.value} />
      <ReloadDraft draft={data?.value} refetch={refetch} />
      <ApproveDraft draft={data?.value} refetch={refetch} />
      <Button large onClick={() => history.push("/hmrc-fps")}>
        Back to FPS
      </Button>
    </PageHeadingArea>
  );
}
