import React from "react";

import PayslipsHeading from "../modules/bacs/PayslipsHeading";
import PayslipsFilter from "../modules/bacs/PayslipsFilter";
import PayslipsTable from "../modules/bacs/PayslipsTable";

import {
  Content,
  PanelColumnSet,
  PanelColumn,
  PanelColumnSpacer,
  SidebarWrapper,
} from "@backslashbuild/pp-ui";

export default function BACSPayslips() {
  return (
    <SidebarWrapper>
      <Content scrollable>
        <PayslipsHeading />
        <PanelColumnSet>
          <PanelColumn width={320}>
            <PayslipsFilter />
          </PanelColumn>
          <PanelColumnSpacer />
          <PayslipsTable />
        </PanelColumnSet>
      </Content>
    </SidebarWrapper>
  );
}
