import React from "react";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import getApiUrl from "../../getApiUrl";
import { useQuery, queryCache } from "react-query";

const apiHost = getApiUrl();
const service = "reporting";

export function useGet(cacheKey, path, serviceOverride, refetchOnWindowFocus = true) {
  if (!path) {
    path = cacheKey;
  }
  if (!serviceOverride) {
    serviceOverride = service;
  }

  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };

  const { data, error, isLoading: loading, updatedAt: queryId } = useQuery(
    cacheKey,
    async (key, id) => {
      return fetch(`${apiHost}/${serviceOverride}/${path}`, {
        headers: {
          ...authHeaders,
        },
      }).then((r) => r.json());
    },
    { refetchOnWindowFocus }
  );

  const runQuery = () => queryCache.invalidateQueries(cacheKey);

  return { data, loading, error, queryId, refetch: runQuery };
}

export function usePost(path) {
  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };

  return React.useCallback(
    (body) => {
      return fetch(`${apiHost}/${service}/${path}`, {
        method: "POST",
        headers: {
          ...authHeaders,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }).then((x) => {
        return x.json();
      });
    },
    [path, authHeaders.Authorization]
  );
}

export function useGetReportByName(reportName) {
  return useGet([reportName], `${reportName}`, null, false);
}
