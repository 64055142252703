import { PageLoading, Link, PageTitle, PanelDialog, PanelHeading } from "@backslashbuild/pp-ui";
import React from "react";
import { useGet } from "./api";
import { isTextNullOrEmpty, utcToLocalText } from "./utils";
import { subscribe, unsubscribe } from "./pubSub";
import fallbackImage from "../../images/profile.png";
import { makeStyles } from "@backslashbuild/pp-ui";
import ContractorPersonalDetailsForm from "./ContractorPersonalDetailsForm";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    padding: "24px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
  },
  header: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  content: {
    width: "100%",
    flex: 7,
    display: "flex",
    flexDirection: "row",
    marginTop: 16,
    backgroundColor: "#FFFFFF",
  },
  flexGrow: {
    flexGrow: 1,
    padding: 8,
  },
  bottomMargin: {
    marginBottom: "10px",
  },
  imageWrapper: {
    width: "125px",
    overflow: "hidden",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
});

export default function ContractorBusinessCard({ id, shrink, showEdit = false }) {
  const classes = useStyles();
  const [editPersonalDetails, setEditPersonalDetails] = React.useState(null);
  const { data, loading, error, refetch } = useGet(id);

  React.useEffect(() => {
    subscribe("contractor.personalDetails.updated", refetch);
    return () => unsubscribe("contractor.personalDetails.updated", refetch);
  }, [id]);

  if (loading) return <PageLoading />;

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors?.map?.((error) => <div>{error}</div>) ?? <div>Unknown error</div>;
    }

    let backgroundColor = "#FFFFFF";
    if (data?.value?.contractorProducts?.length == 0) backgroundColor = "rgb(255, 244, 229)";

    function addDefaultSrc(ev) {
      ev.target.src = fallbackImage;
    }

    function renderEmail() {
      if (data?.value?.email?.length > 20) {
        return String(`${data?.value?.email}`).substring(0, 20) + `...`;
      }

      return data?.value?.email;
    }

    return (
      <>
        <div className={classes.root}>
          <div className={classes.header}>
            <PageTitle>
              {`${data?.value?.title} ${data?.value.firstName} ${data?.value?.surname}`}
              {data?.value?.alias?.length > 0 && ` (${data?.value?.alias})`}
            </PageTitle>
            {showEdit && <Link onClick={() => setEditPersonalDetails(id)}>Edit</Link>}
          </div>
          <div className={classes.content}>
            <div className={classes.flexGrow}>
              <div className={classes.bottomMargin}>{data?.value?.nationality}</div>
              <div className={classes.bottomMargin}>
                {data?.value?.gender == "M" && "Male"}
                {data?.value?.gender == "F" && "Female"}
              </div>
              <div className={classes.bottomMargin}>{`DOB ${utcToLocalText(
                data?.value?.dateOfBirth
              )} (${data?.value?.age})`}</div>
              <div className={classes.bottomMargin}>{`Joined ${utcToLocalText(
                data?.value?.dateCreated
              )}`}</div>
              <div className={classes.bottomMargin}>
                <div>{data?.value?.nino}</div>
                {data?.value?.isLackingBankAccount && (
                  <div className={classes.bottomMargin}>Needs a bank account</div>
                )}
              </div>
            </div>
            <div className={classes.flexGrow}>
              {data?.value?.mobileNumber && (
                <div className={classes.bottomMargin}>{data?.value?.mobileNumber}</div>
              )}
              <div className={classes.bottomMargin}>
                <Link href={`mailto:${data?.value?.email}`}>{renderEmail()}</Link>
              </div>
              <div className={classes.bottomMargin}>
                {data?.value?.address?.line1?.trim()}
                {data?.value?.address?.line2?.trim()?.length > 0 &&
                  `, ${data?.value?.address?.line2?.trim()}`}
              </div>
              <div className={classes.bottomMargin}></div>
              <div className={classes.bottomMargin}>{data?.value?.address?.line3?.trim()}</div>
              <div className={classes.bottomMargin}>
                {data?.value?.address?.town?.trim()}
                {data?.value?.address?.postCode?.trim()?.length > 0 &&
                  `, ${data?.value?.address?.postCode?.trim()}`}
              </div>
            </div>
          </div>
        </div>
        {!shrink && (
          <div className={classes.imageWrapper}>
            <img
              //src={`https://people-payroll-profile-pictures.s3.eu-west-2.amazonaws.com/${data?.value?.peopleId}.jpg`}
              src={fallbackImage}
              style={{ height: "inherit" }}
              onError={addDefaultSrc}
            />
          </div>
        )}
        <PanelDialog open={editPersonalDetails}>
          <PanelHeading>Edit Personal Details</PanelHeading>
          {editPersonalDetails && (
            <ContractorPersonalDetailsForm
              id={editPersonalDetails}
              onClose={() => {
                setEditPersonalDetails(null);
              }}
            />
          )}
        </PanelDialog>
      </>
    );
  }

  return (
    <div
      style={{
        width: "100%",
        marginBottom: "24px",
        display: "flex",
        border: "1px solid #e3e9ef",
      }}
    >
      {handleRender()}
    </div>
  );
}
