import React from "react";
import {
  TableRow,
  Table,
  TableCell,
  Content,
  TableHeaderColumn,
  TableFooter,
  TableBody,
  BoldText,
  PageHeadingAreaSpacer,
  PageHeadingArea,
  BackArrow,
  PageTitle,
  TableHeader,
  PanelDialog,
  Panel,
  PanelActions,
  PanelHeading,
  PanelRow,
  Button,
} from "@backslashbuild/pp-ui";
import { useHistory, useLocation, Link as RouterLink } from "react-router-dom";
import { useGet as useGetPayslipReporting } from "../modules/payslip-reporting/api";
import QueryPaginator, { usePagination } from "../containers/QueryPaginator";
import queryString from "query-string";

require("dotenv").config();

export default function DebugPayslips() {
  const [resyncPayslips, setResyncPayslips] = React.useState([]);
  const [failedRequests, setFailedRequests] = React.useState([]);
  const history = useHistory();
  const location = useLocation();

  const search = usePagination(location.search, 25);

  const parsedQueryString = queryString.parse(search);
  const { data, loading, errors, queryId } = useGetPayslipReporting(
    `debug/banked?${queryString.stringify({ ...parsedQueryString })}`
  );

  async function doFetch(payslipNumber) {
    const cookie = process.env.REACT_APP_NELSONIMPORTTOOLCOOKIE;

    if (!cookie) {
      console.log("no cookie");
      return;
    }

    fetch(
      `https://portal.peoplegroupservices.com/admin/nelson-import.asp?topPayslips=1&idfromPayslips=${parseInt(
        payslipNumber
      )}&dev=y&exportType=Payslips`,
      {
        headers: {
          cookie: cookie,
        },
        referrer:
          "https://portal.peoplegroupservices.com/admin/nelson-import.asp?topPayslips=10&idfromPayslips=7289460&dev=y&exportType=Payslips",
        body: null,
        method: "GET",
        mode: "cors",
      }
    )
      .then((res) => {
        console.log(`${payslipNumber} ${res?.status == 200 ? "👌" : "💀"}`);
        res?.status != 200 && setFailedRequests((st) => st.concat([payslipNumber]));
      })
      .catch((err) => console.log(err));
  }

  function handleClick() {
    resyncPayslips.forEach((item) => doFetch(item));
  }

  return (
    <Content scrollable>
      <PageHeadingArea>
        <BackArrow onClick={() => history.goBack()} />
        <PageHeadingAreaSpacer />
        <PageTitle>{`Debug Payslips`}</PageTitle>
        <PageHeadingAreaSpacer grow />
        {process.env.REACT_APP_NELSONIMPORTTOOLCOOKIE && (
          <Button
            onClick={() =>
              setResyncPayslips(data?.value?.items?.map((payslip) => payslip.payslipNumber))
            }
          >
            Resync
          </Button>
        )}
      </PageHeadingArea>
      <Table standardTable>
        <TableHeader>
          <TableHeaderColumn width={100}>Payslip Number</TableHeaderColumn>
          <TableHeaderColumn width={100}>Category</TableHeaderColumn>
          <TableHeaderColumn width={100}>Week</TableHeaderColumn>
          <TableHeaderColumn width={100}>Account Number</TableHeaderColumn>
          <TableHeaderColumn width={100}>Legacy Banked</TableHeaderColumn>
          <TableHeaderColumn width={100}>Calc'd Banked</TableHeaderColumn>
        </TableHeader>
        <TableBody fullPageTable={true} footerHeight={TableFooter.height}>
          {data?.value?.items?.map((payslip) => (
            <TableRow
              key={payslip.payslipNumber}
              component={RouterLink}
              to={`/debug-payslip/${payslip.payslipNumber}`}
            >
              <TableCell width={100}>{payslip.payslipNumber}</TableCell>
              <TableCell width={100}>{payslip.category}</TableCell>
              <TableCell width={100}>{payslip.week}</TableCell>
              <TableCell width={100}>{payslip.accountNumber}</TableCell>
              <TableCell width={100}>{payslip.legacy}</TableCell>
              <TableCell width={100}>{payslip.banked}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          {loading && <BoldText>Loading...</BoldText>}
          {errors && <BoldText>A network error occured.</BoldText>}
          <QueryPaginator
            itemsTotal={data?.value?.itemsTotal}
            itemsRetrieved={data?.value?.itemsRetrieved}
          />
        </TableFooter>
      </Table>

      <PanelDialog open={resyncPayslips?.length > 0} onClose={() => setResyncPayslips([])}>
        <Panel>
          <PanelHeading>Resync Payslips</PanelHeading>
          <PanelRow>
            This will send a request to PeopleGroupServices to resync {resyncPayslips?.length}{" "}
            payslips
          </PanelRow>
          {failedRequests?.length > 0 && (
            <>
              <PanelRow>Failed requests:</PanelRow>
              {failedRequests.map((req) => (
                <PanelRow>req</PanelRow>
              ))}
            </>
          )}
          <PanelActions>
            <Button onClick={() => setResyncPayslips([])}>Cancel</Button>
            <Button onClick={handleClick}>Resync</Button>
          </PanelActions>
        </Panel>
      </PanelDialog>
    </Content>
  );
}
