import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  TableRow,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  Button,
  CheckBox,
  Dropdown,
  DropdownOption,
  Link,
} from "@backslashbuild/pp-ui";
import { useUpdateMatch } from "./api";
import { publish } from "../../pubSub";
import ContractorProductSearchPicker from "./ContractorProductSearchPicker";
import OverrideProductSearchPicker from "./OverrideProductSearchPicker";
const w = {
  contractorForenames: 70,
  contractorSurname: 70,
  amountPending: 70,
  suppliedContractorProductNumber: 70,
  agencysOwnId: 70,
  matchEditor: 50,
  delete: 50,
  dropdown: 100,
  approve: 80,
};

export function MatchRowEditorTableHeading() {
  return (
    <TableHeader>
      <TableHeaderColumn width={w.contractorForenames}>Forename(s)</TableHeaderColumn>
      <TableHeaderColumn width={w.contractorSurname}>Surname</TableHeaderColumn>
      <TableHeaderColumn width={w.amountPending}>Amount Pending</TableHeaderColumn>
      <TableHeaderColumn width={w.suppliedContractorProductNumber}>
        Supplied contractor number
      </TableHeaderColumn>
      <TableHeaderColumn width={w.agencysOwnId}>Agency's own ID</TableHeaderColumn>
      <TableHeaderColumn width={w.delete}></TableHeaderColumn>
      <TableHeaderColumn width={w.dropdown}>Actions</TableHeaderColumn>
      <TableHeaderColumn width={w.dropdown}></TableHeaderColumn>
      <TableHeaderColumn width={w.approve} />
    </TableHeader>
  );
}

export function MatchRowSkeleton() {
  return (
    <TableRow>
      <TableCell width={w.contractorForenames}>-</TableCell>
      <TableCell width={w.contractorSurname}>-</TableCell>
      <TableCell width={w.amountPending}>-</TableCell>
      <TableCell width={w.suppliedContractorProductNumber}>- </TableCell>
      <TableCell width={w.agencysOwnId}>-</TableCell>
      <TableCell width={w.matchEditor}>-</TableCell>
      <TableCell width={w.delete}>
        <CheckBox label="Delete" disabled />
      </TableCell>
      <TableCell width={w.dropdown}>
        <Dropdown disabled>
          <DropdownOption>-</DropdownOption>
        </Dropdown>
      </TableCell>
      <TableCell width={w.dropdown}>
        <Dropdown disabled>
          <DropdownOption>-</DropdownOption>
        </Dropdown>
      </TableCell>
      <TableCell alignRight width={w.approve}>
        <Button disabled>Approve</Button>
      </TableCell>
    </TableRow>
  );
}

export function MatchRowEditor({ match }) {
  const [open, setOpen] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(match.isDelete);
  const [matchedContractorProductId, setMatchedContractorProductId] = React.useState(
    match.matchedContractorProductId
  );
  const [productIdIfNotMatched, setProductIdIfNotMatched] = React.useState(
    match?.productIdIfNotMatched
  );

  const post = useUpdateMatch();

  const locked = match.approved;
  let disabled =
    match.isDelete == isDelete && match.matchedContractorProductId == matchedContractorProductId;

  async function handleSubmit({ unlock }) {
    try {
      const response = await post({
        matchId: match.id,
        isDelete,
        matchedContractorProductId: matchedContractorProductId || null,
        productIdIfNotMatched: productIdIfNotMatched || null,
        approved: unlock ? false : null,
      });

      if (response.isSuccess) {
        publish("notification", "Match updated");
      } else {
        publish(
          "notification",
          <div>
            Unable to update match
            <ul>
              {response.errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </div>
        );
      }
    } catch (e) {
      console.error(e, "error updating match");
      publish("notification", "A network error occured.");
    }
  }

  return (
    <>
      <TableRow
        backgroundColor={locked ? "rgb(208,223,223)" : open ? "#F3F9FA" : null}
        disableHover={locked}
        onClick={() => setOpen(!open)}
      >
        <TableCell width={w.contractorForenames}>{match.input.contractorForenames}</TableCell>
        <TableCell width={w.contractorSurname}>{match.input.contractorSurname}</TableCell>
        <TableCell
          width={w.amountPending}
        >{`£${match.amountPending} (${match.rowsPending})`}</TableCell>
        <TableCell width={w.suppliedContractorProductNumber}>
          {match.input.suppliedContractorProductNumber}
        </TableCell>
        <TableCell width={w.agencysOwnId}>{match.input.agencysOwnId}</TableCell>
        <TableCell width={w.delete}>
          <CheckBox
            label="Delete"
            checked={isDelete}
            disabled={locked}
            onChange={(e) => setIsDelete(e.target.checked)}
          />
        </TableCell>
        <TableCell width={w.dropdown}>
          <ContractorProductSearchPicker
            value={matchedContractorProductId}
            onChange={setMatchedContractorProductId}
            agencyId={match.agencyId}
            disabled={locked}
          />
        </TableCell>
        <TableCell width={w.dropdown}>
          <OverrideProductSearchPicker
            value={productIdIfNotMatched}
            onChange={(e) => setProductIdIfNotMatched(e.target.value)}
            disabled={locked}
          />
        </TableCell>
        <TableCell alignRight width={w.approve}>
          {locked ? (
            <Button
              onClick={() => {
                handleSubmit({ unlock: true });
              }}
              variant={"secondary"}
            >
              Unapprove
            </Button>
          ) : (
            <Button onClick={handleSubmit}>Approve</Button>
          )}
        </TableCell>
      </TableRow>
      {open &&
        match?.rowsWithAmountPending?.map((row) => (
          <TableRow disableHover>
            <TableCell width={100}>
              {row?.scheduleId && (
                <Link component={RouterLink} to={`/schedule/${row?.scheduleId}`}>
                  Schedule
                </Link>
              )}
            </TableCell>
            <TableCell width={100}>{row?.item}</TableCell>
            <TableCell width={100}></TableCell>
            <TableCell width={100}></TableCell>
            <TableCell width={100}></TableCell>
            <TableCell width={100}></TableCell>
            <TableCell width={100}></TableCell>
            <TableCell width={100}></TableCell>
          </TableRow>
        ))}
    </>
  );
}
