import React from "react";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { subscribe, publish, unsubscribe } from "./pubSub";
import getApiUrl from "../../getApiUrl";
import { useQuery, queryCache } from "react-query";

const apiHost = getApiUrl();
const service = "hmrc";

export function useGet(cacheKey, path, serviceOverride) {
  if (!path) {
    path = cacheKey;
  }
  if (!serviceOverride) {
    serviceOverride = service;
  }

  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };

  const { data, error, isLoading: loading, updatedAt: queryId } = useQuery(
    cacheKey,
    async (key, id) => {
      return fetch(`${apiHost}/${serviceOverride}/${path}`, {
        headers: {
          ...authHeaders,
        },
      }).then((r) => r.json());
    }
  );

  const runQuery = () => queryCache.invalidateQueries(path);

  React.useEffect(() => {
    runQuery();
  }, [path]);

  return { data, loading, error, queryId, refetch: runQuery };
}

export function usePost(path) {
  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };

  return React.useCallback(
    (body) => {
      return fetch(`${apiHost}/${service}/${path}`, {
        method: "POST",
        headers: {
          ...authHeaders,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }).then((x) => {
        return x.json();
      });
    },
    [path, authHeaders.Authorization]
  );
}

export function useGetCompanies() {
  return useGet(`companies/all`);
}

export function useGetNotifications(params = "") {
  return useGet(`dps/notifications${params}`);
}

export function useGetDpsSyncs(params = "") {
  return useGet(`dps/syncs${params}`);
}

export function useGetDpsSyncErrors(syncId) {
  return useGet(`dps/errors/${syncId}`);
}

export function useGetFpsItems(params = "") {
  return useGet(`fps/get${params}`);
}

export function useGetFpsEmployeeData(params = "") {
  return useGet(`fps/employees/get${params}`);
}

export function useGetFpsBaseRtiInformationById(rtiInformationId) {
  return useGet(`fps/rti-base-information/${rtiInformationId}`);
}

export function useGetEpsItems(params = "") {
  return useGet(`eps/get${params}`);
}

export function useGetEpsJsonRequestById(requestId) {
  return useGet(`eps/json-request/${requestId}`);
}

export function useGetDraftFpsItems(params = "") {
  return useGet(`fps/draft/get${params}`);
}

export function useGetDraftFpsEmployeeData(params = "") {
  return useGet(`fps/draft/employees/get${params}`);
}

export function useGetDraftFpsById(id) {
  return useGet(`fps/draft/${id}`);
}

export function usePostSync() {
  const post = usePost(`dps/sync`);

  return React.useCallback(
    (body) => {
      return post(body).then((x) => {
        publish(`${service}.notifications.sync`, x);
        return x;
      });
    },
    [post]
  );
}

export function usePostProcess(params = "") {
  const post = usePost(`dps/process${params}`);

  return React.useCallback(
    (body) => {
      return post(body).then((x) => {
        publish(`${service}.notifications.process`, x);
        return x;
      });
    },
    [post]
  );
}

export function usePostDraftFps() {
  const post = usePost(`fps/draft`);

  return React.useCallback(
    (body) => {
      return post(body).then((x) => {
        publish(`${service}.fps.draft`, x);
        return x;
      });
    },
    [post]
  );
}

export function usePostApproveDraft(id) {
  const post = usePost(`fps/draft/prepare/${id}`);

  return React.useCallback(
    (body) => {
      return post(body).then((x) => {
        publish(`${service}.fps.approve`, x);
        return x;
      });
    },
    [post]
  );
}

export function usePostReloadDraft(id) {
  const post = usePost(`fps/draft/reload/${id}`);

  return React.useCallback(
    (body) => {
      return post(body).then((x) => {
        publish(`${service}.fps.reload`, x);
        return x;
      });
    },
    [post]
  );
}

export function usePostCloseDraft(id) {
  const post = usePost(`fps/draft/close/${id}`);

  return React.useCallback(
    (body) => {
      return post(body).then((x) => {
        publish(`${service}.fps.close`, x);
        return x;
      });
    },
    [post]
  );
}
