import React from "react";
import {
  BoldText,
  Panel,
  PanelHeading,
  Table,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableRow,
  TableCell,
  PanelSpacer,
} from "@backslashbuild/pp-ui";
import { formatCurrency } from "../../utils";

function PayslipLineItemsTable({ payslip }) {
  return (
    <>
      <Panel maxWidth={474 * 2}>
        <PanelHeading>Line items</PanelHeading>
        <Table width={800}>
          <TableHeader>
            <TableHeaderColumn width="200px">Type</TableHeaderColumn>
            <TableHeaderColumn width="200px">Description</TableHeaderColumn>
            <TableHeaderColumn width="100px" alignRight>
              Deductions
            </TableHeaderColumn>
            <TableHeaderColumn width="100px" alignRight>
              Additions
            </TableHeaderColumn>
            <TableHeaderColumn width="100px" alignRight>
              Balance
            </TableHeaderColumn>
          </TableHeader>
          <TableBody>
            <LineItemsSection
              lineItems={payslip?.lineItems?.filter(
                (lineItem) => lineItem.section.id === "for_gross_taxable_pay"
              )}
            />
            <TableRow disableHover>
              <TableCell shaded mini width={600} colSpan={4} alignRight>
                <BoldText>Gross taxable pay</BoldText>
              </TableCell>
              <TableCell summary alignRight width={100}>
                {formatCurrency(payslip.grossTaxablePay)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Panel>
      <PanelSpacer large />
      <Panel maxWidth={474 * 2}>
        <Table width={800}>
          <TableBody>
            <LineItemsSection
              lineItems={payslip?.lineItems?.filter(
                (lineItem) => lineItem.section.id === "for_net_pay"
              )}
              balance={payslip.invoiceTotal}
            />
            <TableRow disableHover>
              <TableCell shaded mini width={600} colSpan={4} alignRight>
                <BoldText>Net pay</BoldText>
              </TableCell>
              <TableCell summary alignRight width={100}>
                {formatCurrency(payslip.netPay)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Panel>
      <PanelSpacer large />
      <Panel maxWidth={474 * 2}>
        <Table width={800}>
          <TableBody>
            <LineItemsSection
              lineItems={payslip?.lineItems?.filter(
                (lineItem) => lineItem.section.id === "for_total_pay"
              )}
              balance={payslip.invoiceTotal}
            />
            <TableRow disableHover>
              <TableCell shaded mini width={600} colSpan={4} alignRight>
                <BoldText>Total pay</BoldText>
              </TableCell>
              <TableCell summary alignRight width={100}>
                {formatCurrency(payslip.totalPay)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Panel>
      <PanelSpacer large />
      <Panel maxWidth={474 * 2}>
        <Table width={800}>
          <TableBody>
            <LineItemsSection
              lineItems={payslip?.lineItems?.filter(
                (lineItem) => lineItem.section.id === "for_banked"
              )}
              balance={payslip.invoiceTotal}
            />
            <TableRow disableHover>
              <TableCell shaded mini width={600} colSpan={4} alignRight>
                <BoldText>Banked</BoldText>
              </TableCell>
              <TableCell summary alignRight width={100}>
                {formatCurrency(payslip.banked)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Panel>
    </>
  );
}

function LineItemsSection({ lineItems }) {
  return lineItems?.map((lineItem) => (
    <TableRow disableHover>
      <TableCell width="200px">{lineItem.type?.name}</TableCell>
      <TableCell width="200px">{lineItem.description}</TableCell>
      <TableCell width="100px" alignRight>
        {lineItem.isDeduction && formatCurrency(lineItem.amount)}
      </TableCell>
      <TableCell width="100px" alignRight>
        {!lineItem.isDeduction && formatCurrency(lineItem.amount)}
      </TableCell>
      <TableCell width="100px" alignRight>
        {formatCurrency(lineItem.balance)}
      </TableCell>
    </TableRow>
  ));
}

export default PayslipLineItemsTable;
