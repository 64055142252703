import React from "react";
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  PageLoading,
  PageHeadingArea,
  PageTitle,
  PanelDialog,
  PanelHeading,
} from "@backslashbuild/pp-ui";
import { isTextNullOrEmpty } from "./utils";
import { useGetItems } from "./api";
import SMSTriggerForm from "./SMSTriggerForm";

function SMSTriggersTable() {
  const { data, loading, error, queryId } = useGetItems("sms-triggers");
  const [editTrigger, setEditTrigger] = React.useState(null);

  if (loading || !data) return <PageLoading />;
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  return (
    <>
      <PageHeadingArea>
        <PageTitle>SMS triggers</PageTitle>
      </PageHeadingArea>
      <div style={{ height: 400 }}>
        <Table standardTable>
          <TableHeader>
            <TableHeaderColumn width="20%">Alias</TableHeaderColumn>
            <TableHeaderColumn width="60%">Description</TableHeaderColumn>
            <TableHeaderColumn width="20">Template Name</TableHeaderColumn>
          </TableHeader>
          <TableBody fullPageTable={false} footerHeight={0}>
            {data.value.items.map((trigger) => (
              <TableRow onClick={() => setEditTrigger(trigger?.alias)}>
                <TableCell width="20%">{trigger?.alias}</TableCell>
                <TableCell width="60%">{trigger?.description}</TableCell>
                <TableCell width="20%">{trigger?.template?.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <PanelDialog open={editTrigger}>
        <PanelHeading>Edit SMS Trigger</PanelHeading>
        <SMSTriggerForm alias={editTrigger} onClose={() => setEditTrigger(null)} />
      </PanelDialog>
    </>
  );
}

export default SMSTriggersTable;
