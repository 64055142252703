import React from "react";
import { TableRow, Table, TableCell, TableHeaderColumn, TableBody } from "@backslashbuild/pp-ui";
import { Link as RouterLink, useLocation, useParams } from "react-router-dom";
import { useGetContributionScheduleExportRows } from "./api";
import { utcToYmd } from "./utils";
import { BoldText, StatusIndicator, TableFooter } from "@backslashbuild/pp-ui/dist/index.es";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";
import queryString from "query-string";
import EditContributionScheduleExportRowDialog from "./EditContributionScheduleExportRowDialog";

const c = {
  niNumber: 100,
  pensionableEarnings: 100,
  employerContribution: 100,
  memberContribution: 100,
  isPartialOrNonPayment: 100,
  errors: 200,
  successful: 100,
  rowIsIgnored: 100,
};

function formatYesNo(yesno) {
  if (yesno === "yes") return true;
  if (yesno === "no") return false;
  return undefined;
}

function ContributionScheduleExportRowsTable() {
  const [editRow, setEditRow] = React.useState(null);
  const { exportId } = useParams();

  const location = useLocation();
  const queryPagingString = usePagination(location.search);

  const queryPaging = queryString.parse(queryPagingString);

  const stringifiedQuery = queryString.stringify({
    exportId,
    ...queryPaging,
    successful: formatYesNo(queryPaging.successful),
    unsuccessful: formatYesNo(queryPaging.unsuccessful),
  });

  const { data, loading, error, queryId } = useGetContributionScheduleExportRows(stringifiedQuery);

  const ScrollableHeaderRow = TableRow;

  return (
    <>
      <Table standardTable>
        <TableBody fullPageTable={true}>
          <ScrollableHeaderRow>
            <TableHeaderColumn width={c.niNumber}>NI Number</TableHeaderColumn>
            <TableHeaderColumn width={c.pensionableEarnings}>
              Pensionable Earnings
            </TableHeaderColumn>
            <TableHeaderColumn width={c.employerContribution}>
              Employer Contribution
            </TableHeaderColumn>
            <TableHeaderColumn width={c.memberContribution}>Member Contribution</TableHeaderColumn>
            <TableHeaderColumn width={c.isPartialOrNonPayment}>
              Is Partial/Non-payment
            </TableHeaderColumn>
            <TableHeaderColumn width={c.errors}>Errors</TableHeaderColumn>
            <TableHeaderColumn width={c.successful}>Successful</TableHeaderColumn>
            <TableHeaderColumn width={c.rowIsIgnored}>Ignore Row</TableHeaderColumn>
          </ScrollableHeaderRow>
          {data?.value?.items?.map((row) => (
            <TableRow key={row.id} onClick={() => setEditRow(row.id)}>
              <TableCell width={c.niNumber}>{row.niNumber}</TableCell>
              <TableCell width={c.pensionableEarnings}>{row.pensionableEarnings}</TableCell>
              <TableCell width={c.employerContribution}>{row.employerContribution}</TableCell>
              <TableCell width={c.memberContribution}>{row.memberContribution}</TableCell>
              <TableCell width={c.isPartialOrNonPayment}>
                <StatusIndicator value={row.isPartialOrNonPayment} />
              </TableCell>
              <TableCell width={c.errors}>
                {row.errors?.map((error) => (
                  <div>{error}</div>
                ))}
              </TableCell>
              <TableCell width={c.successful}>
                <StatusIndicator value={row.markedAsSuccessful} />
              </TableCell>
              <TableCell width={c.rowIsIgnored}>
                <StatusIndicator value={row.rowIsIgnored} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          {loading && <BoldText>Loading...</BoldText>}
          {error && <BoldText>A network error occured.</BoldText>}
          <QueryPaginator
            itemsTotal={data?.value?.itemsTotal}
            itemsRetrieved={data?.value?.itemsRetrieved}
          />
        </TableFooter>
      </Table>
      {editRow && (
        <EditContributionScheduleExportRowDialog id={editRow} onClose={() => setEditRow(null)} />
      )}
    </>
  );
}

export default ContributionScheduleExportRowsTable;
