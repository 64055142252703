import React from "react";
import {
  TableRow,
  Table,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableFooter,
  TableBody,
  BoldText,
  PageHeadingArea,
  PageHeadingAreaSpacer,
  PageTitle,
} from "@backslashbuild/pp-ui";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";
import { useGetStatPayGroups } from "./api";
import { formatCurrency, utcToLocalText } from "../../utils";
import ContractorProductContractorNameRollOver from "../contractors/ContractorProductContractorNameRollOver";
import StatutoryPayGroupsFiltering from "./StatutoryPayGroupsFiltering";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

const c = {
  employment: 200,
  dateCreated: 200,
  startDate: 200,
  endDate: 200,
  rate: 200,
  notes: 200,
  type: 200,
};
const totalWidth = Object.keys(c).reduce((total, key) => (total += c[key]), 0);

function PureTable({ data, queryId }) {
  return React.useMemo(() =>
    data.value.items.map(
      (item) => (
        <TableRow component={RouterLink} to={`/statutory-pay-group/${item.id}`}>
          <TableCell width={c.employment}>
            <ContractorProductContractorNameRollOver contractorProductId={item?.employmentId} />
          </TableCell>
          <TableCell width={c.type}>{item?.type?.name}</TableCell>
          <TableCell width={c.dateCreated}>{utcToLocalText(item.dateCreated)}</TableCell>
          <TableCell width={c.startDate}>{utcToLocalText(item.startDate)}</TableCell>
          <TableCell width={c.endDate}>{utcToLocalText(item.endDate)}</TableCell>
          <TableCell width={c.rate}>{formatCurrency(item.rate)}</TableCell>
          <TableCell width={c.notes}>{item.notes}</TableCell>
        </TableRow>
      ),
      [queryId]
    )
  );
}

function TableSkeleton() {
  return (
    <TableRow>
      <TableCell width={c.employment}>-</TableCell>
      <TableCell width={c.type}>-</TableCell>
      <TableCell width={c.dateCreated}>-</TableCell>
      <TableCell width={c.startDate}>-</TableCell>
      <TableCell width={c.endDate}>-</TableCell>
      <TableCell width={c.rate}>-</TableCell>
      <TableCell width={c.notes}>-</TableCell>
    </TableRow>
  );
}

function StatutoryPayGroupsTable() {
  const location = useLocation();
  const search = location.search;
  const parsedQueryString = queryString.parse(search);

  const { data, loading, error, queryId } = useGetStatPayGroups({
    contractorProductId: parsedQueryString?.contractorProductId,
    search: parsedQueryString?.search,
    type: parsedQueryString?.type,
  });

  const isLoaded = Boolean(data?.value);

  return (
    <>
      <PageHeadingArea>
        <PageTitle>Statutory Payments</PageTitle>
        <PageHeadingAreaSpacer grow />
        <StatutoryPayGroupsFiltering />
      </PageHeadingArea>
      <Table standardTable width={totalWidth}>
        <TableHeader>
          <TableHeaderColumn width={c.employment}>Employment</TableHeaderColumn>
          <TableHeaderColumn width={c.type}>Type</TableHeaderColumn>
          <TableHeaderColumn width={c.dateCreated}>Date Created</TableHeaderColumn>
          <TableHeaderColumn width={c.startDate}>Start Date</TableHeaderColumn>
          <TableHeaderColumn width={c.endDate}>End Date</TableHeaderColumn>
          <TableHeaderColumn width={c.rate}>Rate</TableHeaderColumn>
          <TableHeaderColumn width={c.notes}>Notes</TableHeaderColumn>
        </TableHeader>
        <TableBody fullPageTable footerHeight={TableFooter.height}>
          {isLoaded ? (
            <PureTable data={data} queryId={queryId} />
          ) : data?.errors && typeof data?.errors == Array ? (
            data?.errors?.map((error) => <div>{error}</div>)
          ) : (
            <TableSkeleton />
          )}
        </TableBody>
        <TableFooter>
          {loading && <BoldText>Loading...</BoldText>}
          {error && <BoldText>A network error occured.</BoldText>}
          <QueryPaginator
            itemsTotal={data?.value?.itemsTotal}
            itemsRetrieved={data?.value?.itemsRetrieved}
          />
        </TableFooter>
      </Table>
    </>
  );
}

export default StatutoryPayGroupsTable;
