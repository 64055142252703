import React from "react";
import { Icons } from "@backslashbuild/pp-ui";
import { usePost } from "./api";
import { publish } from "../../pubSub";

const Icon = Icons.DeleteIcon;

export default function DeleteHolidayYearOverrideButton({ id, onClose = () => {} }) {
  const post = usePost("holiday-year-overrides/delete");

  async function handleSubmit() {
    const result = await post({
      holidayYearOverrideId: id,
    });
    if (!result?.isSuccess) {
      var errors = result?.errors;
      publish(
        "notification",
        <ul>
          {errors?.map((error) => {
            return <li>{error}</li>;
          })}
        </ul>
      );
      return;
    }
    publish("notification", "Deleted");
    onClose();
  }

  return <Icon onClick={() => handleSubmit()} />;
}
