import {
  Button,
  PageHeadingArea,
  PageHeadingAreaSpacer,
  PageTitle,
  PageLoading,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  SearchBar,
  TableFooter,
  Pill,
} from "@backslashbuild/pp-ui";
import React from "react";
import { useCreateItem, useGetItems } from "./api";
import { isTextNullOrEmpty, utcToLocalText } from "./utils";
import { useHistory, useLocation } from "react-router-dom";
import { publish } from "../../pubSub";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";
import RegistrationsFiltering from "./RegistrationsFiltering";
import queryString from "query-string";

import { useCheckUserPermission } from "../../permissions";

function formatYesNo(yesno) {
  if (yesno === "yes") return true;
  if (yesno === "no") return false;
  return undefined;
}

const defaultTags = {
  isDead: "no",
  isImported: "no",
  isLive: "yes",
};

function formatQueryString(qs) {
  const o = queryString.parse(qs);
  o.isDead = formatYesNo(o.isDead);
  o.isImported = formatYesNo(o.isImported);
  o.isLive = formatYesNo(o.isLive);
  o.take = 100;
  return `?${queryString.stringify(o)}`;
}

export default function RegistrationsTable() {
  const history = useHistory();
  const location = useLocation();
  const search = usePagination(location.search);

  React.useEffect(() => {
    if (location?.search?.length < 1) {
      history.replace({
        search: queryString.stringify({ ...defaultTags }),
      });
    }
  }, []);

  const hasPermissionToCreateQuickIllustration = useCheckUserPermission(
    "General.CanCreateContractorQuickIllustration"
  );

  const { data, loading, error, refetch } = useGetItems(formatQueryString(search)); // TODO urlencode
  const post = useCreateItem();

  if (loading) return <PageLoading />;
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  async function handleCreate(isLead) {
    const result = await post({ isLead: isLead });
    const id = result.value;
    publish("notification", isLead ? "New Lead Created" : "Quick Illustration Created");
    history.push(
      isLead
        ? `/registration/${id}?productdialog=true&matchdialog=true`
        : `/registration-illustration/${id}?productdialog=true&matchdialog=true`
    );
  }

  return (
    <>
      <PageHeadingArea>
        <PageTitle>Registrations</PageTitle>
        <PageHeadingAreaSpacer grow />
        <RegistrationsFiltering />
        <PageHeadingAreaSpacer />
        <Button large onClick={async () => await handleCreate(true)}>
          New Lead
        </Button>
        <PageHeadingAreaSpacer />
        {/* {hasPermissionToCreateQuickIllustration && ( */}
        <Button large variant="secondary" onClick={async () => await handleCreate(false)}>
          Quick Illustration
        </Button>
        {/* )} */}
      </PageHeadingArea>
      <Table standardTable>
        <TableHeader>
          <TableHeaderColumn width="20%">Contractor</TableHeaderColumn>
          <TableHeaderColumn width="20%">Agency</TableHeaderColumn>
          <TableHeaderColumn width="20%">Product</TableHeaderColumn>
          <TableHeaderColumn width="10%">Date Created</TableHeaderColumn>
          <TableHeaderColumn width="10%">Status</TableHeaderColumn>
          <TableHeaderColumn width="20%">Author</TableHeaderColumn>
        </TableHeader>
        <TableBody fullPageTable={true} footerHeight={TableFooter.height}>
          {data.value.items?.length < 1 && (
            <TableRow>
              <TableCell>
                <div>No registrations matching this query</div>
              </TableCell>
            </TableRow>
          )}
          {data.value.items.map((registration) => (
            <TableRow
              onClick={() =>
                history.push(
                  registration.isLead
                    ? `/registration/${registration.id}?matchdialog=${!registration.isImported}`
                    : `/registration-illustration/${registration.id}`
                )
              }
            >
              <TableCell width="20%">{registration.contractorName}</TableCell>
              <TableCell width="20%">{registration.agencyName}</TableCell>
              <TableCell width="20%">{registration.productName}</TableCell>
              <TableCell width="10%">
                {utcToLocalText(registration.dateCreated, "dd/MM/yyyy HH:mm")}
              </TableCell>
              <TableCell width="10%">
                {registration.isImported ? (
                  <Pill status="success">Imported</Pill>
                ) : registration.isDead ? (
                  <Pill status="cancelled">Dead</Pill>
                ) : (
                  <Pill status="info">Live</Pill>
                )}
              </TableCell>
              <TableCell width="20%">{registration?.author?.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <QueryPaginator
            itemsTotal={data?.value?.itemsTotal}
            itemsRetrieved={data?.value?.itemsRetrieved}
          />
        </TableFooter>
      </Table>
    </>
  );
}
