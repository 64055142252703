import { Label, Link, PanelRow } from "@backslashbuild/pp-ui";
import React from "react";
import { useGetHolidayBalance } from "./api";
import { isTextNullOrEmpty } from "./utils";
import { Link as RouterLink } from "react-router-dom";

export default function EmploymentHolidayBalance({ contractorProductId }) {
  const { data, loading, error } = useGetHolidayBalance({ contractorProductId });

  if (loading) return <div>Loading</div>;

  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

  if (data != null && !data?.isSuccess) {
    return data?.errors?.map((error) => <div>{error}</div>);
  }

  return (
    <>
      <PanelRow>
        <Label label="Holiday Remaining">
          <Link
            component={RouterLink}
            to={`/holiday-entitlement/${contractorProductId}`}
          >{`${data?.value?.remainingHolidayDays} days (£${data?.value?.remainingHolidayMoney})`}</Link>
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Holiday Pending">
          <Link
            component={RouterLink}
            to={`/holiday-entitlement/${contractorProductId}`}
          >{`${data?.value?.pendingHolidayDays} days (£${data?.value?.pendingHolidayMoney})`}</Link>
        </Label>
      </PanelRow>
    </>
  );
}
