import {
  FormTitle,
  PageLoading,
  Panel,
  PanelHeading,
  PanelActions,
  Button,
} from "@backslashbuild/pp-ui";
import React from "react";
import { useGetFields, useUpdateFields } from "./api";
import FormField from "./FormField";
import { isTextNullOrEmpty } from "./utils";
import { publish } from "../../pubSub";

export default function ContractorAgencyForm({ id, contractorId, onClose }) {
  const { data, loading, error } = useGetFields({ id, formName: "contractor-agency" });
  const updateFields = useUpdateFields({
    id,
    formName: `contractor-agency?contractorId=${contractorId}`,
    subFormName: `contractorAgencies`,
  });
  const [edits, setEdits] = React.useState([]);

  if (loading) return <PageLoading />;

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    return data.value.map((field) => (
      <>
        {field?.key == "NewBranch" ? (
          <>
            {!edits?.BranchId && (
              <FormField
                key={field.key}
                field={field}
                onSave={getOnSaveHandler(field.key)}
                showSelect={false}
              />
            )}
          </>
        ) : field?.key == "NewConsultantName" ||
          field?.key == "NewConsultantEmail" ||
          field?.key == "NewConsultantNumber" ? (
          <>
            {!edits?.ConsultantName && (
              <FormField
                key={field.key}
                field={field}
                onSave={getOnSaveHandler(field.key)}
                showSelect={false}
              />
            )}
          </>
        ) : (
          <FormField
            key={field.key}
            field={field}
            onSave={getOnSaveHandler(field.key)}
            showSelect={false}
            disabled={field?.key == "BranchId" && edits?.NewBranch?.length > 0}
          />
        )}
      </>
    ));
  }

  function getOnSaveHandler(key) {
    return (value) => {
      setEdits([...edits.filter((e) => e.key !== key), { key, value }]);
    };
  }

  function handleSave() {
    let fields = data?.value?.map((field) => {
      return { key: field.key, value: field.value };
    });
    edits.map((edit) => {
      fields = [...fields.filter((e) => e.key !== edit.key), { key: edit.key, value: edit.value }];
    });
    updateFields(fields, { contractorAgencyId: id })
      .then((r) => {
        if (r?.isSuccess) {
          publish("notification", "Agency updated");
        } else {
          publish(
            "notification",
            <div>
              Unable to update agency
              <ul>
                {r?.errors?.map((error) => (
                  <li>{error}</li>
                ))}
              </ul>
            </div>
          );
        }
      })
      .finally(() => onClose());
  }

  return (
    <Panel>
      <PanelHeading>Edit agency</PanelHeading>
      {handleRender()}
      <PanelActions>
        <Button variant="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleSave();
          }}
        >
          Save
        </Button>
      </PanelActions>
    </Panel>
  );
}
