import { usePost } from "./api";
import { publish } from "../../pubSub";

import getApiUrl from "../../getApiUrl";

const apiUrl = getApiUrl();

function useRedirectToPayslip(
  payslipNumber,
  isIllustration = false,
  isRemittanceAdvice = false,
  isScraped = false
) {
  const process = usePost();

  async function handleSubmit() {
    const result = await process(
      `${isIllustration ? "illustrations/" : ""}sign-request/${payslipNumber}`
    );

    if (result?.isSuccess) {
      window.location = `${apiUrl}/payslip-reporting${
        isIllustration ? "/illustrations" : ""
      }/render/${payslipNumber}?token=${result.value}&mode=${
        isRemittanceAdvice ? "remittance-advice" : "assignment-reconciliation"
      }&isScraped=${isScraped}`;
    } else {
      publish("notification", `Error - could not open Payslip.`);
    }
  }

  return handleSubmit;
}

export default useRedirectToPayslip;
