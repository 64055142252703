import React from "react";
import { QueryDropdown } from "@backslashbuild/pp-ui";
import { useGetItems, useGet } from "../agencies/api";

function format(value) {
  if (value && value?.agencyNumber && value?.name) {
    return `${value?.agencyNumber} ${value?.name}`;
  }
  return "-";
}

function AgencyPicker({ value, onChange }) {
  const [search, setSearch] = React.useState("");
  const optionsQuery = useGetItems(
    `?pattern=${search}&take=30&isActive=true&isSuspect=false&isProspect=false`
  ); // TODO urlencode

  const currentItemQuery = useGet(value);

  const options = optionsQuery.data?.value?.items ?? [];

  // Debounce the search
  var searchTimeout = null;
  const setSearchTerm = (searchTerm) => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      setSearch(searchTerm);
    }, 500);
  };

  return (
    <QueryDropdown
      placeholder="Choose"
      onSearchChange={setSearchTerm}
      value={value}
      onChange={onChange}
      options={options.map((o) => o.id)}
      width={"439px"}
      renderOption={(v) => {
        if (v == value) return format(currentItemQuery?.data?.value);

        var optionsMatch = options.find((o) => o.id == v);

        if (optionsMatch) {
          return format(optionsMatch);
        }
        return "loading...";
      }}
    />
  );
}

export default AgencyPicker;
