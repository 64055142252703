import { useState } from "react";
import {
  Panel,
  PageLoading,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  PanelHeading,
  Button,
  PanelDialog,
  Link,
  StatusIndicator,
} from "@backslashbuild/pp-ui";
import React from "react";

import { useGetItems } from "./api";
import { isTextNullOrEmpty, formatCurrency, utcToLocalText } from "../../utils";

export default function ContractorAWSTable({ id }) {
  const { data, loading, error } = useGetItems(
    `contractor-anytimeWageRequests?contractorId=${id}&status=confirmed`
  );

  if (loading) return <PageLoading />;

  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

  if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  if (data?.value?.items?.length == 0) {
    return null;
  }

  return (
    <>
      <Table standardTable height={600} width={800}>
        <TableHeader>
          <TableHeaderColumn width="100%">AWS Advances</TableHeaderColumn>
        </TableHeader>
        <TableHeader>
          <TableHeaderColumn width="100px">Status</TableHeaderColumn>
          <TableHeaderColumn width="100px">Requested Date</TableHeaderColumn>
          <TableHeaderColumn width="100px">Advance</TableHeaderColumn>
          <TableHeaderColumn width="100px">Fee</TableHeaderColumn>
          <TableHeaderColumn width="100px">Points</TableHeaderColumn>
          <TableHeaderColumn width="100px">Balance</TableHeaderColumn>
          <TableHeaderColumn width="100px">Paid Out</TableHeaderColumn>
          <TableHeaderColumn width="100px">Recovered</TableHeaderColumn>
          <TableHeaderColumn width="100px">Emergency Payment</TableHeaderColumn>
        </TableHeader>
        <TableBody fullPageTable={false} footerHeight={0}>
          {data.value.items.map((contractorAnytimeWageRequest) => {
            const remainingAmount =
              contractorAnytimeWageRequest.amountAccepted +
              contractorAnytimeWageRequest.feeInMoney -
              contractorAnytimeWageRequest.amountPaidBackByContractor;
            const percentPaidBack =
              (contractorAnytimeWageRequest.amountPaidBackByContractor /
                (contractorAnytimeWageRequest.amountAccepted +
                  contractorAnytimeWageRequest.feeInMoney)) *
              100;

            return (
              <TableRow disableHover>
                <TableCell width="100px">Paid</TableCell>
                <TableCell width="100px">
                  {utcToLocalText(contractorAnytimeWageRequest.acceptedAt)}
                </TableCell>
                <TableCell width="100px">
                  {formatCurrency(contractorAnytimeWageRequest.amountAccepted)}
                </TableCell>
                <TableCell width="100px">
                  {formatCurrency(contractorAnytimeWageRequest.feeInMoney)}
                </TableCell>
                <TableCell width="100px">{contractorAnytimeWageRequest.feeInPoints}</TableCell>
                <TableCell width="100px">{formatCurrency(remainingAmount)}</TableCell>
                <TableCell width="100px">
                  {utcToLocalText(contractorAnytimeWageRequest.acceptedAt)}
                </TableCell>
                <TableCell width="100px">
                  {formatCurrency(contractorAnytimeWageRequest.amountPaidBackByContractor)}
                </TableCell>
                <TableCell width="100px">
                  <StatusIndicator value={contractorAnytimeWageRequest.isEmergencyPayment} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}
