import React from "react";
import {
  Button,
  PanelDialog,
  PanelHeading,
  PanelRow,
  PanelSpacer,
  PanelActions,
  Label,
  TextInput,
} from "@backslashbuild/pp-ui";
import { usePost } from "./api";
import { publish } from "../../pubSub";
import { useHistory } from "react-router-dom";

function UpdateNumberOfPeriodsPayslipButton({ id }) {
  const history = useHistory();

  // you have to query to see if there are any currently staged pay runs and then disable the button.
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [value, setValue] = React.useState(1);

  const updatePayslip = usePost("payslips/update-periods");

  async function handleSubmit() {
    try {
      const response = await updatePayslip({ payslipId: id, numberOfPeriods: Number(value) });

      if (response.isSuccess) {
        publish("notification", "Payslip updated.");
      } else {
        publish(
          "notification",
          <>
            Unable to update payslip
            <ul>
              {response.errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </>
        );
      }
    } catch (e) {
      console.error(e, "error updating payslip");
      publish("notification", "A network error occured.");
    }
  }

  return (
    <>
      <Button variant="secondary" large onClick={() => setDialogOpen(true)}>
        Edit Periods
      </Button>
      <PanelDialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <PanelHeading>Update number of periods</PanelHeading>
        <PanelSpacer large />
        <PanelRow>
          <Label label="Number of periods">
            <TextInput type="number" value={value} onChange={(e) => setValue(e.target.value)} />
          </Label>
        </PanelRow>
        <PanelSpacer large />
        <PanelActions>
          <Button variant="secondary" onClick={() => setDialogOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleSubmit}>Update</Button>
        </PanelActions>
        <PanelSpacer large />
      </PanelDialog>
    </>
  );
}

export default UpdateNumberOfPeriodsPayslipButton;
