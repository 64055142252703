import { useState } from "react";
import {
  Panel,
  PageLoading,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  Link,
  PanelDialog,
} from "@backslashbuild/pp-ui";
import React from "react";
import { useGetItems } from "./api";
import { isTextNullOrEmpty } from "./utils";
import { subscribe, unsubscribe } from "./pubSub";

import ContractorAgencyForm from "./ContractorAgencyForm";
import NewContractorAgencyForm from "./NewContractorAgencyForm";
import SelectAgencyForm from "./SelectAgencyForm";
import { DateTime } from "luxon";

export default function ContractorAgenciesTable({ contractorId }) {
  const { data, loading, error, refetch } = useGetItems(
    `contractor-agencies?contractorId=${contractorId}`
  );
  const [editContractorAgency, setEditContractorAgency] = useState(null);
  const [addNewContractorAgency, setAddNewContractorAgency] = useState(false);
  const [selectedAgency, setSelectedAgency] = useState(null);

  React.useEffect(() => {
    subscribe("contractor.contractor-agencies/add.updated", refetch);
    return () => unsubscribe("contractor.contractor-agencies/add.updated", refetch);
  }, [contractorId]);

  React.useEffect(() => {
    subscribe("contractor.contractorAgencies.updated", refetch);
    return () => unsubscribe("contractor.contractorAgencies.updated", refetch);
  }, [contractorId]);

  if (loading) return <PageLoading />;

  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

  if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  function contractorAgencyToBackgroundColor(contractorAgency) {
    let colors = {
      warning: "#eeb83d",
      error: "#db1729",
      info: "#5ccbd9",
      success: "#5ac775",
    };
    if (contractorAgency?.leftAgencyDate) {
      let leftDate = new DateTime.fromISO(contractorAgency.leftAgencyDate);

      return leftDate > DateTime.local() ? colors.warning : colors.error;
    }

    return null;
  }

  return (
    <Panel maxWidth="100%">
      <Table>
        <TableHeader>
          <TableHeaderColumn width="90%">Agencies</TableHeaderColumn>
          <TableHeaderColumn>
            <div style={{ width: "100%", textAlign: "right" }}>
              <Link onClick={() => setAddNewContractorAgency(true)}>Add</Link>
            </div>
          </TableHeaderColumn>
        </TableHeader>
        <TableHeader>
          <TableHeaderColumn width="33%">Name</TableHeaderColumn>
          <TableHeaderColumn width="33%">Branch</TableHeaderColumn>
          <TableHeaderColumn width="33%">Product</TableHeaderColumn>
        </TableHeader>
        <TableBody>
          {data.value.items.map((contractorAgency) => (
            <TableRow
              onClick={() => setEditContractorAgency(contractorAgency.id)}
              backgroundColor={contractorAgencyToBackgroundColor(contractorAgency)}
            >
              <TableCell width="33%">{contractorAgency?.agencyName}</TableCell>
              <TableCell width="33%">{contractorAgency?.branchName}</TableCell>
              <TableCell width="33%">{contractorAgency?.preferredContractorProductName}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <PanelDialog open={editContractorAgency}>
        {editContractorAgency && (
          <ContractorAgencyForm
            id={editContractorAgency}
            contractorId={contractorId}
            onClose={() => setEditContractorAgency(null)}
          />
        )}
      </PanelDialog>

      <PanelDialog
        open={addNewContractorAgency}
        onClose={() => {
          setAddNewContractorAgency(false);
          setSelectedAgency(null);
        }}
      >
        {!selectedAgency && (
          <SelectAgencyForm
            onSave={(value) => setSelectedAgency(value)}
            onClose={() => {
              setAddNewContractorAgency(false);
              setSelectedAgency(null);
            }}
          />
        )}
        {selectedAgency && (
          <NewContractorAgencyForm
            contractorId={contractorId}
            agencyId={selectedAgency}
            onClose={() => {
              setAddNewContractorAgency(false);
              setSelectedAgency(null);
            }}
          />
        )}
      </PanelDialog>
    </Panel>
  );
}
