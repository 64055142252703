import React from "react";
import {
  Content,
  PanelColumnSet,
  PanelColumn,
  Panel,
  PanelHeading,
  PanelSpacer,
  PanelColumnSpacer,
  PageHeadingArea,
  PageTitle,
} from "@backslashbuild/pp-ui";

import ViewingsTable from "../modules/history/ViewingsTable";
import HistoryFilter from "../modules/history/HistoryFilter";

export default function Viewings() {
  return (
    <Content>
      <PageHeadingArea>
        <PageTitle>History</PageTitle>
      </PageHeadingArea>
      <PanelColumnSet>
        <PanelColumn>
          <Panel>
            <PanelHeading>Quick Filters </PanelHeading>
            <PanelSpacer large />
            <HistoryFilter />
            <PanelSpacer large />
          </Panel>
        </PanelColumn>
        <PanelColumnSpacer />
        {/* Temporary fix for Safari not rendering ui components correctly */}
        <div style={{ width: "100%", height: "77vh" }}>
          <ViewingsTable />
        </div>
      </PanelColumnSet>
    </Content>
  );
}
