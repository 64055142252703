import React from "react";

import CloseButton from "./CloseButton";
import CloseDialog from "./CloseDialog";

import { PageHeadingAreaSpacer } from "@backslashbuild/pp-ui";

export default function CloseBACSRunActions({ bacsRun }) {
  const [showDialog, setShowDialog] = React.useState(false);

  return (
    <>
      {bacsRun && !bacsRun?.isClosed && !bacsRun?.isPaid && (
        <>
          <CloseButton onClick={() => setShowDialog(true)} />
          <PageHeadingAreaSpacer />
          <CloseDialog
            open={showDialog}
            bacsRunId={bacsRun?.id}
            onClose={() => {
              setShowDialog(false);
            }}
          />
        </>
      )}
    </>
  );
}
