import React from "react";
import {
  Button,
  PageHeadingArea,
  PageHeadingAreaSpacer,
  PageTitle,
  PageLoading,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  SearchBar,
  StatusIndicator,
} from "@backslashbuild/pp-ui";
import { utcToLocalText, isTextNullOrEmpty } from "./utils";
import { useGetItems } from "./api";

export default function ProductsTable() {
  const { data, loading, error } = useGetItems("product-rates");

  if (loading || !data) return <PageLoading />;
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  return (
    <>
      <PageHeadingArea>
        <PageTitle>Product Rates</PageTitle>
        <PageHeadingAreaSpacer grow />
      </PageHeadingArea>
      <Table fullPageTable={true}>
        <TableHeader>
          <TableHeaderColumn width="5%">Product</TableHeaderColumn>
          <TableHeaderColumn width="5%">Approved</TableHeaderColumn>
          <TableHeaderColumn width="5%">Complimentary Margin</TableHeaderColumn>
          <TableHeaderColumn width="5%">Fee</TableHeaderColumn>
          <TableHeaderColumn width="5%">Gross from</TableHeaderColumn>
          <TableHeaderColumn width="5%">Gross to</TableHeaderColumn>
          <TableHeaderColumn width="5%">Insurance</TableHeaderColumn>
          <TableHeaderColumn width="5%">Pay Frequency </TableHeaderColumn>
          <TableHeaderColumn width="5%">People ID </TableHeaderColumn>
          <TableHeaderColumn width="5%">Rebate </TableHeaderColumn>
          <TableHeaderColumn width="5%">Start Week</TableHeaderColumn>
          <TableHeaderColumn width="5%">Date Created</TableHeaderColumn>
          <TableHeaderColumn width="5%">Is Active</TableHeaderColumn>
          <TableHeaderColumn width="5%">Explanation</TableHeaderColumn>
        </TableHeader>
        <TableBody fullPageTable={true}>
          {data.value.items.map((productRate) => (
            <TableRow key={productRate.id}>
              <TableCell width="5%">{productRate.productId}</TableCell>
              <TableCell width="5%">
                {productRate.approvedBy} ({utcToLocalText(productRate.approvedDate)})
              </TableCell>
              <TableCell width="5%">{productRate.complimentaryMarginCount}</TableCell>
              <TableCell width="5%">
                £{productRate.fixedFee} or {productRate.percentageFee}% (&lt; £
                {productRate.minimumFee}, &gt; £{productRate.maximumFee})
              </TableCell>
              <TableCell width="5%">{productRate.grossFrom}</TableCell>
              <TableCell width="5%">{productRate.grossTo}</TableCell>
              <TableCell width="5%">
                {productRate.insurance} <StatusIndicator value={productRate.isInsuranceUsed} />
              </TableCell>
              <TableCell width="5%">{productRate.payFrequency}</TableCell>
              <TableCell width="5%">{productRate.peopleId}</TableCell>
              <TableCell width="5%">
                £{productRate.rebateFlatRateValue} or {productRate.rebatePercentageRateValue}% up to
                £{productRate.rebateCap}
              </TableCell>
              <TableCell width="5%">{productRate.startWeek}</TableCell>

              <TableCell width="5%">{utcToLocalText(productRate.dateCreated)}</TableCell>
              <TableCell width="5%">
                <StatusIndicator value={productRate.isActive} />
              </TableCell>
              <TableCell width="5%">{productRate.explanation}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}

// gencyId: "00000000-0000-0000-0000-000000000000";
// approvedBy: "Test";
// approvedDate: "2018-10-20T00:00:00";
// complimentaryMarginCount: 1;
// dateCreated: "2018-10-20T00:00:00";
// fixedFee: 50;
// grossFrom: 0;
// grossTo: 0;
// id: "eccc0bcb-0750-489c-be0d-133efebe16f4";
// insurance: 10;
// isActive: true;
// isEmploymentCostsInvoicedSeparately: false;
// isInsuranceUsed: false;
// maximumFee: 50;
// minimumFee: 6;
// payFrequency: "weekly";
// peopleId: "APR001";
// percentageFee: 12.07;
// productId: "04ec7c3c-0fff-44b5-b5f5-9e479861713c";
// rebateCap: 50;
// rebateFlatRateValue: 50;
// rebatePercentageRateValue: 13.07;
// startWeek: 2010;
