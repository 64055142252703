import React from "react";
import { Content } from "@backslashbuild/pp-ui";

import ProductsTable from "../modules/products/ProductsTable";

export default function Product() {
  return (
    <Content>
      <ProductsTable />
    </Content>
  );
}
