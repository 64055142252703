import React from "react";
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableFooter,
  PageLoading,
  Snack,
  PageHeadingArea,
  PageTitle,
  SearchBar,
  PageHeadingAreaSpacer,
  BoldText,
  Button,
} from "@backslashbuild/pp-ui";
import { utcToLocalText, isTextNullOrEmpty, formatCurrency } from "../../utils";
import { useGetItems } from "./api";
import ContractorsFilter from "./ContractorsFilter";
import { useLocation, useHistory } from "react-router-dom";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";

function PureTable({ data, queryId }) {
  const history = useHistory();

  return React.useMemo(
    () =>
      data.value.items.map((anytimeWageRequest) => [
        <TableRow>
          <TableCell width="17%">{anytimeWageRequest.id}</TableCell>
          <TableCell width="17%">{anytimeWageRequest.contractorName}</TableCell>
          <TableCell width="17%">{formatCurrency(anytimeWageRequest.minAmountAvailable)}</TableCell>
          <TableCell width="17%">{formatCurrency(anytimeWageRequest.maxAmountAvailable)}</TableCell>
          <TableCell width="17%">{formatCurrency(anytimeWageRequest.amountAccepted)}</TableCell>
          <TableCell width="17%">
            {anytimeWageRequest.acceptedAt !== "0001-01-01T00:00:00" &&
              utcToLocalText(anytimeWageRequest.acceptedAt)}
          </TableCell>
        </TableRow>,
        <TableRow>
          <TableCell width="17%"></TableCell>
          <TableCell width="17%" colSpan={5}>
            {anytimeWageRequest.rationale}
          </TableCell>
        </TableRow>,
      ]),
    [queryId]
  );
}

function AdvanceFeedbackTable() {
  const location = useLocation();
  const search = usePagination(location.search);
  const { data, loading, error, queryId } = useGetItems("contractor-anytimeWageRequests");

  if (loading || !data) return <PageLoading />;
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  return (
    <>
      <PageHeadingArea>
        <PageTitle>Advances Feedback</PageTitle>
        <PageHeadingAreaSpacer grow />
        <ContractorsFilter />
      </PageHeadingArea>
      <Table standardTable>
        <TableHeader>
          <TableHeaderColumn width="17%">ID</TableHeaderColumn>
          <TableHeaderColumn width="17%">Contractor Name</TableHeaderColumn>
          <TableHeaderColumn width="17%">Min</TableHeaderColumn>
          <TableHeaderColumn width="17%">Max</TableHeaderColumn>
          <TableHeaderColumn width="17%">Accepted</TableHeaderColumn>
          <TableHeaderColumn width="17%">When</TableHeaderColumn>
        </TableHeader>
        <TableBody fullPageTable={true} footerHeight={TableFooter.height}>
          <PureTable data={data} queryId={queryId} />
        </TableBody>
        <TableFooter>
          <QueryPaginator
            itemsTotal={data?.value?.itemsTotal}
            itemsRetrieved={data?.value?.itemsRetrieved}
          />
        </TableFooter>
      </Table>
    </>
  );
}

export default AdvanceFeedbackTable;
