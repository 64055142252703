import React from "react";
import { subscribe, publish, unsubscribe } from "./pubSub";
import { isTextNullOrEmpty } from "./utils";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import getApiUrl from "../../getApiUrl";
import { useQuery, queryCache } from "react-query";

const apiHost = getApiUrl();
const service = "registrations";

export function useGet(cacheKey, path, serviceOverride) {
  if (!path) {
    path = cacheKey;
  }
  const { oidcUser } = useReactOidc();
  const authHeaders = { Authorization: `${oidcUser.token_type} ${oidcUser.access_token}` };
  const [{ data, loading, error }, setState] = React.useState({
    data: null,
    loading: true,
    error: null,
  });

  function runQuery() {
    return fetch(`${apiHost}/${service}/${path}`, {
      headers: {
        ...authHeaders,
      },
    })
      .then((r) => r.json())
      .then((r) =>
        setState({
          data: r,
          loading: false,
        })
      )
      .catch((e) =>
        setState({
          loading: false,
          error: e,
        })
      );
  }
  React.useEffect(() => {
    runQuery();
  }, [path]);
  return { data, loading, error, refetch: runQuery };
}

export function useLazyGet(path) {
  const { oidcUser } = useReactOidc();
  const authHeaders = { Authorization: `${oidcUser.token_type} ${oidcUser.access_token}` };
  const [{ data, loading, error }, setState] = React.useState({
    data: null,
    loading: true,
    error: null,
  });

  function runQuery() {
    return fetch(`${apiHost}/${service}/${path}`, {
      headers: {
        ...authHeaders,
      },
    })
      .then((r) => r.json())
      .then((r) =>
        setState({
          data: r,
          loading: false,
        })
      )
      .catch((e) =>
        setState({
          loading: false,
          error: e,
        })
      );
  }
  return [runQuery, { data, loading, error, refetch: runQuery }];
}

export function usePost(path) {
  const { oidcUser } = useReactOidc();
  const authHeaders = { Authorization: `${oidcUser.token_type} ${oidcUser.access_token}` };

  return React.useCallback(
    (body) => {
      return fetch(`${apiHost}/${service}/${path}`, {
        method: "POST",
        headers: {
          ...authHeaders,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }).then((r) => r.json());
    },
    [path, authHeaders.Authorization]
  );
}

export function useGetFields({ id, formName, queryParams }) {
  let path = "";
  if (!isTextNullOrEmpty(formName)) path += formName;
  if (!isTextNullOrEmpty(queryParams)) path += `/${queryParams}`;
  const query = useGet(`${id}/fields/${path}`);

  React.useEffect(() => {
    subscribe(`${service}.fields.update`, query.refetch);
    return () => unsubscribe(`${service}.fields.update`, query.refetch);
  }, []);

  return query;
}

export function useUpdateField({ id, formName }) {
  const post = usePost(`${id}/fields/${formName}`);

  return React.useCallback(
    (body) => {
      return post(body).then((x) => {
        publish(`${service}.fields.update`, x);
        return x;
      });
    },
    [post]
  );
}

export function useGetItems(path = "") {
  return useGet(`${path}`);
}

export function useCreateItem() {
  const post = usePost(`create`);

  return React.useCallback(
    (body) => {
      return post(body).then((x) => {
        publish(`${service}.create`, x);
        return x;
      });
    },
    [post]
  );
}

export function useLazyGetAddresses({ id }) {
  return useLazyGet(`${id}/addresses-for-postcode`);
}

export function useUpdateAddress({ id }) {
  const post = usePost(`${id}/address`);

  return React.useCallback(
    (body) => {
      return post(body).then((x) => {
        publish(`${service}.fields.update`, x);
        return x;
      });
    },
    [post]
  );
}

export function useUpdateMatchedContractor({ id }) {
  const post = usePost(`${id}/matched-contractor`);

  return React.useCallback(
    (body) => {
      return post(body).then((x) => {
        publish(`${service}.fields.update`, x);
        return x;
      });
    },
    [post]
  );
}

export function useImport({ id }) {
  const post = usePost(`${id}/import`);

  return React.useCallback(
    (body) => {
      return post(body).then((x) => {
        publish(`${service}.import`, x);
        return x;
      });
    },
    [post]
  );
}

export function useGetAuthors() {
  return useGet("authors", "authors");
}
