import React from "react";
import { Link } from "@backslashbuild/pp-ui";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

export default function QueryFilter({ query, value, display = value, ...rest }) {
  const history = useHistory();
  const location = useLocation();
  const getStateFromSearch = (s) => queryString.parse(s);
  const [state, setState] = React.useState(getStateFromSearch(location.search));

  React.useEffect(() => {
    setState(getStateFromSearch(location.search));
  }, [location]);

  return (
    <Link
      onClick={(e) => {
        e.stopPropagation();
        history.replace({
          search: queryString.stringify(
            {
              ...state,
              [query]: value,
              skip: undefined,
            },
            { skipEmptyString: false, skipNull: false }
          ),
        });
      }}
      {...rest}
    >
      {display ?? ""}
    </Link>
  );
}
