import React from "react";
import { PanelActions, PanelRow, Button, Label } from "@backslashbuild/pp-ui";
import { useHistory } from "react-router-dom";

import { usePost } from "./api";

import { publish } from "../../pubSub";
import StatutoryPayGroupTypePicker from "./StatutoryPayGroupTypePicker";

export default function CreateStatutoryPayGroupDialog({ onCancel, contractorProductId }) {
  const [value, setValue] = React.useState(null);
  const history = useHistory();

  const post = usePost("statutory-pay-groups");

  async function handleSubmit() {
    try {
      const result = await post({ employmentId: contractorProductId, typeId: value });

      if (!result?.isSuccess) {
        var errors = result.errors;

        publish(
          "notification",
          <ul>
            {errors.map((error) => {
              return <li>{error}</li>;
            })}
          </ul>
        );

        return;
      }

      if (result?.isSuccess) {
        history.push(`/statutory-pay-group/${result?.value}`);
      }

      const id = result.value;
      // now navigate to edit page.
      publish("notification", "Statutory Pay Group Created");
    } catch (error) {
      publish("notification", "Network error occured.");
    }
  }

  return (
    <>
      <PanelRow>
        <Label label="Statutory Pay Type">
          <StatutoryPayGroupTypePicker onChange={setValue} />
        </Label>
      </PanelRow>
      <PanelActions>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="primary" disabled={!value} onClick={handleSubmit}>
          Save
        </Button>
      </PanelActions>
    </>
  );
}
