import React from "react";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { useQuery, queryCache } from "react-query";
import { publish } from "../agencies/pubSub";
import getApiUrl from "../../getApiUrl";

const apiHost = getApiUrl();
const service = "invoicing";

export function useGet(cacheKey, path, serviceOverride) {
  if (!path) {
    path = cacheKey;
  }
  if (!serviceOverride) {
    serviceOverride = service;
  }

  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };

  const {
    data,
    error,
    isLoading: loading,
    updatedAt: queryId,
  } = useQuery(cacheKey, async (key, id) => {
    return fetch(`${apiHost}/${serviceOverride}/${path}`, {
      headers: {
        ...authHeaders,
      },
    }).then((r) => r.json());
  });

  const runQuery = () => queryCache.invalidateQueries(path);

  return { data, loading, error, queryId, refetch: runQuery };
}

export function useGetInvoices(path) {
  return useGet([`invoices`, path], path);
}

// export function usePost(subFormName) {
//   const { oidcUser } = useReactOidc();
//   const authHeaders = { Authorization: `${oidcUser.token_type} ${oidcUser.access_token}` };

//   const postRef = React.useRef((path, body) => {
//     return fetch(`${apiHost}/${service}/${path}`, {
//       method: "POST",
//       headers: {
//         ...authHeaders,
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(body),
//     }).then((r) => {
//       publish(`invoice.${subFormName}.updated`, r);
//       return r.json();
//     });
//   });

//   return postRef.current;
// }

export function useGetBlob(path, filename) {
  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };

  return React.useCallback(() => {
    return fetch(`${apiHost}/${service}/${path}`, {
      method: "GET",
      headers: {
        ...authHeaders,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
  }, [path, filename]);
}

export function useDownloadExportCSV(batchNumber, nelsonGenerated = false) {
  return useGetBlob(
    `exports/export/${batchNumber}/csv?nelsonGenerated=${nelsonGenerated}`,
    nelsonGenerated ? `export-${batchNumber}-nelson-generated.csv` : `export-${batchNumber}.csv`
  );
}

export function useUpdateInvoiceDetails() {
  const update = usePost();

  return React.useCallback(async (invoiceNumber, body) => {
    const res = await update(`${invoiceNumber}/update-details`, body);
    invalidateInvoices();
    return res;
  });
}

export function useClearInvoice() {
  const update = usePost();

  return React.useCallback(async (invoiceNumber) => {
    const res = await update(`${invoiceNumber}/clear`);
    invalidateInvoices();
    return res;
  });
}

export function useUpdateInvoiceIsPaid() {
  const update = usePost();

  return React.useCallback(async (invoiceNumber, body) => {
    const res = await update(`${invoiceNumber}/update-is-paid`, body);
    invalidateInvoices();
    return res;
  });
}

export function usePost() {
  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };

  return React.useCallback(
    (path, body) => {
      return fetch(`${apiHost}/${service}/${path}`, {
        method: "POST",
        headers: {
          ...authHeaders,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }).then((x) => {
        return x.json();
      });
    },
    [authHeaders.Authorization]
  );
}

export function useGetInvoice(invoiceNumber) {
  return useGet("invoice", invoiceNumber);
}

export function invalidateInvoices() {
  queryCache.invalidateQueries((query) => {
    const [cacheType] = query.queryKey;
    return cacheType === "invoices";
  });
}

export function useGetCategories() {
  return useGet("invoice-categories", "definitions/categories");
}

export function useGetInputItemDefinitions() {
  return useGet("invoice-input-item-defs", "definitions/inputItems");
}
