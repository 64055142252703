import React from "react";

import { Content } from "@backslashbuild/pp-ui";

import EmergencyPaymentsTable from "../modules/emergencyPayments/EmergencyPaymentsTable";

function EmergencyPayments() {
  return (
    <Content>
      <EmergencyPaymentsTable />
    </Content>
  );
}

export default EmergencyPayments;
