import React from "react";
import {
  Content,
  PanelColumnSpacer,
  PanelColumn,
  PanelColumnSet,
  SidebarWrapper,
  PanelSpacer,
} from "@backslashbuild/pp-ui";

import DraftFpsEmployeeFilter from "../modules/hmrc/DraftFpsEmployeeFilter";
import DraftFpsEmployeeTable from "../modules/hmrc/DraftFpsEmployeeTable";
import DraftFPSEmployeeHeading from "../modules/hmrc/DraftFPSEmployeeHeading";
import DraftAttemptTable from "../modules/hmrc/DraftAttemptTable";

export default function DraftFPSEmployeeData() {
  return (
    <SidebarWrapper>
      <Content scrollable>
        <DraftFPSEmployeeHeading />
        <PanelColumnSet>
          <PanelColumn width={320}>
            <DraftFpsEmployeeFilter />
            <PanelSpacer />
            <PanelSpacer />
            <DraftAttemptTable />
          </PanelColumn>
          <PanelColumnSpacer />
          <DraftFpsEmployeeTable />
        </PanelColumnSet>
      </Content>
    </SidebarWrapper>
  );
}
