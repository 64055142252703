import React from "react";

import { Button } from "@backslashbuild/pp-ui";

export default function AddBACSRunButton({ onClick }) {
  return (
    <Button
      large
      onClick={() => {
        onClick();
      }}
    >
      Add BACS Run
    </Button>
  );
}
