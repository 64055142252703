import React, { useState } from "react";
import {
  Panel,
  PageLoading,
  PanelActions,
  PanelHeading,
  Button,
  PanelRow,
  PanelSubheading,
  BoldText,
  Label,
} from "@backslashbuild/pp-ui";
import FormField from "./FormField";
import { usePost, useGetNewFields } from "./api";
import { isTextNullOrEmpty } from "./utils";
import { publish } from "./pubSub";
import AgencyName from "../agencies/AgencyName";

export default function NewContractorAgencyForm({ contractorId, agencyId, onClose }) {
  const [payload, storePayload] = useState({});
  const { data, loading, error } = useGetNewFields({
    contractorId,
    formName: `contractor-agency?agencyId=${agencyId}`,
  });
  const createContractorAgency = usePost("contractor-agencies/add");

  if (loading) return <PageLoading />;

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    return data.value.map((field) => (
      <>
        {field?.key == "NewBranch" ? (
          <>
            {!payload?.BranchId && (
              <FormField key={field.key} field={field} onSave={getOnSaveHandler(field.key)} />
            )}
          </>
        ) : field?.key == "NewConsultantName" ||
          field?.key == "NewConsultantEmail" ||
          field?.key == "NewConsultantNumber" ? (
          <>
            {!payload?.ConsultantName && (
              <FormField key={field.key} field={field} onSave={getOnSaveHandler(field.key)} />
            )}
          </>
        ) : (
          <FormField key={field.key} field={field} onSave={getOnSaveHandler(field.key)} />
        )}
      </>
    ));
  }

  function getOnSaveHandler(key) {
    return (value) => {
      storePayload({ ...payload, [key]: value });
    };
  }

  console.log("payload", payload);
  return (
    <Panel>
      <PanelHeading>Add new agency</PanelHeading>
      <PanelRow>
        <PanelSubheading>
          <>{`Agency: `}</>
          <AgencyName id={agencyId} />
        </PanelSubheading>
      </PanelRow>
      {handleRender()}
      <PanelActions>
        <Button variant="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            createContractorAgency({
              contractorId,
              agencyId,
              ...payload,
            })
              .then((r) => {
                if (r?.isSuccess) {
                  publish("notification", "Agency added");
                } else {
                  publish(
                    "notification",
                    <div>
                      Unable to add agency
                      <ul>
                        {r?.errors?.map((error) => (
                          <li>{error}</li>
                        ))}
                      </ul>
                    </div>
                  );
                }
              })
              .catch((e) => {
                console.log("a network error occurred");
              })
              .finally(() => {
                onClose();
              });
          }}
        >
          Save
        </Button>
      </PanelActions>
    </Panel>
  );
}
