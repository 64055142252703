import {
  PageLoading,
  Panel,
  PanelHeading,
  PanelSpacer,
  PanelRow,
  Label,
  BoldText,
  Link,
  PanelDialog,
  StatusIndicator,
} from "@backslashbuild/pp-ui";
import React from "react";
import { useGet } from "./api";
import { isTextNullOrEmpty } from "./utils";
import { subscribe, unsubscribe } from "./pubSub";
import AgencySettingsForm from "./AgencySettingsForm";

export default function AgencySettingsPanel({ id }) {
  const [editSettings, setEditSettings] = React.useState(null);
  const { data, loading, error, refetch } = useGet(id);

  console.log(data);
  React.useEffect(() => {
    subscribe("agency.settings.updated", refetch);
    return () => unsubscribe("agency.settings.updated", refetch);
  }, [id]);

  function handlePanelRender() {
    if (loading) return <PageLoading />;
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    return (
      <>
        <PanelRow>
          <Label label="Default Product">
            <BoldText>{data?.value?.defaultProductName}</BoldText>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="PAYE Employed">
            <BoldText>
              <StatusIndicator value={data?.value?.isUsingPAYEEmployed} />
            </BoldText>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="VAT Exempt">
            <BoldText>
              <StatusIndicator value={data?.value?.isVatExempt} />
            </BoldText>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Umbrella Professions">
            <BoldText>
              <StatusIndicator value={data?.value?.useProfessionsContractIfPossible} />
            </BoldText>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="PEO Joint Employment">
            <BoldText>
              <StatusIndicator value={data?.value?.isUsingJointEmployment} />
            </BoldText>
          </Label>
        </PanelRow>
      </>
    );
  }

  return (
    <>
      <Panel>
        <PanelHeading rightAction={<Link onClick={() => setEditSettings(id)}>Edit</Link>}>
          Product Settings
        </PanelHeading>
        <PanelSpacer />
        {handlePanelRender()}
        <PanelSpacer />
      </Panel>
      <PanelDialog open={editSettings}>
        <PanelHeading>Edit Product Settings</PanelHeading>
        {editSettings && (
          <AgencySettingsForm
            id={editSettings}
            onClose={() => {
              setEditSettings(null);
            }}
          />
        )}
      </PanelDialog>
    </>
  );
}
