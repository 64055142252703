import React from "react";
import {
  Panel,
  PanelRow,
  Label,
  BoldText,
  PageLoading,
  PanelSpacer,
  PanelHeading,
  PanelActions,
} from "@backslashbuild/pp-ui";
import { useGet } from "./api";
import { utcToLocalText } from "../../utils";
import FinalisePayRunButton from "./FinalisePayRunButton";
import CancelPayRunButton from "./CancelPayRunButton";
import ReleasePayslipsButton from "./ReleasePayslipsButton";
import DownloadBACSFileButton from "./DownloadBACSFileButton";

function PayRunInfoPanel({ id }) {
  // todo query payslip api and get payslip data
  const { data, loading, errors } = useGet(`payruns/${id}`);

  if (loading) {
    return <PageLoading />;
  }

  if (data?.errors) {
    return (
      <ul>
        {data?.errors.map((error) => (
          <li>{error}</li>
        ))}
      </ul>
    );
  }

  const payRun = data?.value;

  if (payRun == null) {
    return (
      <Panel>
        <PanelHeading>Pay run details</PanelHeading>
        <PanelSpacer /> <PanelRow>Pay run not found.</PanelRow>
      </Panel>
    );
  }

  return (
    <Panel>
      <PanelHeading>Pay run details</PanelHeading>
      <PanelSpacer />
      <PanelRow small>
        <Label small label="Pay run date">
          <BoldText>{utcToLocalText(payRun?.payRunDate)}</BoldText>
        </Label>
      </PanelRow>
      <PanelRow small>
        <Label small label="Tax period">
          {`Tax year:  `}
          <b>{payRun?.taxYear}</b>
          <br />
          {`Tax month: `}
          <b>{payRun?.taxMonth}</b>
          <br />
          {`Tax week:  `}
          <b>{payRun?.taxWeek}</b>
        </Label>
      </PanelRow>
      <PanelRow small>
        <Label small label="Date created">
          <BoldText>{utcToLocalText(payRun?.dateCreated)}</BoldText>
        </Label>
      </PanelRow>
      <PanelRow small>
        <Label small label="Status">
          <BoldText>
            {payRun.isFinalised
              ? `Finalised at ${utcToLocalText(payRun.finalisedAt)}`
              : payRun.isCancelled
              ? `Cancelled at ${utcToLocalText(payRun.cancelledAt)}`
              : "Staged"}
          </BoldText>
        </Label>
      </PanelRow>
      {!payRun.isFinalised && !payRun.isCancelled && (
        <PanelActions>
          <FinalisePayRunButton id={payRun.id} />
          <CancelPayRunButton id={payRun.id} />
        </PanelActions>
      )}
      {payRun.isFinalised && (
        <>
          <PanelActions>
            <DownloadBACSFileButton id={payRun.id} />
          </PanelActions>
          <PanelActions>
            <ReleasePayslipsButton id={payRun.id} />
          </PanelActions>
        </>
      )}

      {/* <PanelRow small>
        <pre>{JSON.stringify({ data }, null, 2)}</pre>
      </PanelRow> */}
    </Panel>
  );
}

export default PayRunInfoPanel;
