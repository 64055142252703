import React from "react";
import { StatusIndicator } from "@backslashbuild/pp-ui";
import { useMarkScheduleAsChecked } from "./api";
import { publish } from "../../pubSub";
import { useHistory } from "react-router-dom";

function MarkScheduleAsCheckedButton({ id, checked }) {
  const history = useHistory();

  // you have to query to see if there are any currently staged pay runs and then disable the button.
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const process = useMarkScheduleAsChecked();

  async function handleSubmit() {
    try {
      const response = await process({ scheduleId: id });

      if (response.isSuccess) {
        publish("notification", "Schedule updated");
        setDialogOpen(false);
      } else {
        publish(
          "notification",
          <div>
            Unable to mark as checked
            <ul>
              {response.errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </div>
        );
      }
    } catch (e) {
      console.error(e, "error processing");
      publish("notification", "A network error occured.");
    }
  }

  return (
    <>
      <div onClick={() => handleSubmit()}>
        <StatusIndicator value={checked} />
      </div>
    </>
  );
}

export default MarkScheduleAsCheckedButton;
