import React from "react";
import { PageLoading } from "@backslashbuild/pp-ui";
import { useParams } from "react-router-dom";
import useRedirectToSelectedInvoices from "../modules/invoicing/useRedirectToSelectedInvoices";

function RedirectToSelectedInvoices() {
  const { path } = useParams();
  const open = useRedirectToSelectedInvoices(path);

  React.useEffect(() => {
    open();
  }, []);

  return <PageLoading />;
}

export default RedirectToSelectedInvoices;
