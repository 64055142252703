import React from "react";
import {
  Button,
  PanelDialog,
  PanelHeading,
  PanelRow,
  PanelSpacer,
  PanelActions,
  BoldText,
} from "@backslashbuild/pp-ui";
import { usePost } from "./api";
import { publish } from "../../pubSub";
import { useHistory } from "react-router-dom";

function ReleasePayslipsButton({ id }) {
  const history = useHistory();

  // you have to query to see if there are any currently staged pay runs and then disable the button.
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const releasePayslips = usePost("payruns/release-payslips");

  async function handleSubmit() {
    try {
      const response = await releasePayslips({ payRunId: id });
      publish("notification", "Payslips sent");
    } catch (e) {
      console.error(e, "error sending payslips");
      publish("notification", "A network error occured.");
    }
  }

  return (
    <>
      <Button onClick={() => setDialogOpen(true)}>Release payslips</Button>
      <PanelDialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <PanelHeading>Release payslips</PanelHeading>
        <PanelSpacer large />
        <PanelRow>
          <BoldText>
            This sends emails to all the contractors with a link to their payslip
            <br />
            <br />
            You can do this action as many times as you want.
          </BoldText>
        </PanelRow>
        <PanelSpacer large />
        <PanelActions>
          <Button variant="secondary" onClick={() => setDialogOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleSubmit}>Release payslips</Button>
        </PanelActions>
        <PanelSpacer large />
      </PanelDialog>
    </>
  );
}

export default ReleasePayslipsButton;
