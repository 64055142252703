import React from "react";
import {
  PageLoading,
  PanelDialog,
  PanelHeading,
  PanelActions,
  PanelRow,
  Button,
  Label,
  QueryDropdown,
  TextInput,
  Dropdown,
  DropdownOption,
  CheckBox,
  PanelSpacer,
} from "@backslashbuild/pp-ui";
import { usePost } from "./api";
import { publish } from "../../pubSub";
import { isTextNullOrEmpty } from "../../utils";
import TaxWeekPicker from "../tax-calendar/TaxWeekPicker";
import * as uuid from "uuid";
import CompanyPicker from "./CompanyPicker";
import EmploymentPicker from "./EmploymentPicker";
import RFPTypePicker from "./RFPTypePicker";
import AgencyPicker from "../agencies/AgencyPicker";

export default function AddRFPDialog() {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  function handleCancel() {
    setDialogOpen(false);
  }

  return (
    <>
      <Button large onClick={() => setDialogOpen(true)}>
        Add new RFP
      </Button>
      <PanelDialog onClose={handleCancel} open={dialogOpen}>
        <PanelHeading>New RFP</PanelHeading>
        <PanelSpacer />
        <AddRFPForm onCancel={handleCancel} />
        <PanelSpacer />
      </PanelDialog>
    </>
  );
}

function AddRFPForm({ onCancel }) {
  const [taxWeek, setTaxWeek] = React.useState(null);

  const [request, setRequest] = React.useState({
    reference: uuid.v4(),
    // companyId: "",
    employmentId: null,
    typeId: "",
    description: "",
    clientName: "",
    workplaceName: "",
    requestedAmount: 0,
    hours: 0,
    days: 0,
    rate: 0,
    isPartialResolutionAllowed: false,
  });

  const post = usePost("rfps");

  async function handleSubmit() {
    const result = await post({
      ...request,
      requestedAmount: Number(request.requestedAmount),
      hours: Number(request.hours),
      days: Number(request.days),
      rate: Number(request.rate),
    });
    if (!result.isSuccess) {
      var errors = result.errors;
      publish(
        "notification",
        <ul>
          {errors.map((error) => {
            return <li>{error}</li>;
          })}
        </ul>
      );
      return;
    }
    const id = result.value;
    publish("notification", "New RFP added");
  }

  const disableBtn =
    !(request.requestedAmount > 0) || !request.employmentId || !request.description;

  return (
    <>
      <PanelRow>
        <Label label="Week Number">
          <TaxWeekPicker value={taxWeek} onChange={setTaxWeek} />
        </Label>
      </PanelRow>
      {/* <PanelRow>
        <Label label="Company">
          <CompanyPicker
            value={request.companyId}
            onChange={(v) => setRequest({ ...request, companyId: v })}
          />
        </Label>
      </PanelRow> */}
      <PanelRow>
        <Label label="Employment">
          <EmploymentPicker
            value={request.employmentId}
            onChange={(v) => setRequest({ ...request, employmentId: v })}
          />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="RFP Type">
          <RFPTypePicker
            value={request.typeId}
            onChange={(v) => setRequest({ ...request, typeId: v })}
          />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Description">
          <TextInput
            value={request.description}
            onChange={(e) => setRequest({ ...request, description: e.target.value })}
          />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Agency">
          <AgencyPicker
            value={request.agencyId}
            onChange={(e) => setRequest({ ...request, agencyId: e })}
          />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Client Name">
          <TextInput
            value={request.clientName}
            onChange={(e) => setRequest({ ...request, clientName: e.target.value })}
          />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Workplace Name">
          <TextInput
            value={request.workplaceName}
            onChange={(e) => setRequest({ ...request, workplaceName: e.target.value })}
          />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Amount">
          <TextInput
            value={request.requestedAmount}
            type="number"
            step=".01"
            onBlur={() =>
              setRequest({
                ...request,
                requestedAmount: Number(request.requestedAmount).toFixed(2),
              })
            }
            onChange={(e) => {
              if (/(^$)|(^[0-9]+\.?[0-9]{0,2}$)/.test(e.target.value))
                setRequest({ ...request, requestedAmount: e.target.value });
            }}
          />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Hours">
          <TextInput
            value={request.hours}
            type="number"
            onChange={(e) => setRequest({ ...request, hours: e.target.value })}
          />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Days">
          <TextInput
            value={request.days}
            type="number"
            onChange={(e) => setRequest({ ...request, days: e.target.value })}
          />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Rate">
          <TextInput
            value={request.rate}
            type="number"
            onChange={(e) => setRequest({ ...request, rate: e.target.value })}
          />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Is partial resolution allowed">
          <CheckBox
            checked={request.isPartialResolutionAllowed}
            onChange={(e) =>
              setRequest({ ...request, isPartialResolutionAllowed: e.target.checked })
            }
          />
        </Label>
      </PanelRow>
      <PanelActions>
        <Button disabled={disableBtn} variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={disableBtn} variant="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </PanelActions>
    </>
  );
}
