import React from "react";
import { useParams } from "react-router-dom";

import { Content, PageHeadingArea, PageTitle } from "@backslashbuild/pp-ui";

import SMSTemplateEditor from "../modules/emails/SMSTemplateEditor";

function EditTemplatePage() {
  const { id } = useParams();

  return (
    <Content scrollable>
      <PageHeadingArea>
        <PageTitle>Edit SMS Template</PageTitle>
      </PageHeadingArea>
      <SMSTemplateEditor id={id} />
    </Content>
  );
}

export default EditTemplatePage;
