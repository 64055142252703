import React from "react";

import { Content } from "@backslashbuild/pp-ui";

import EmailTemplatesTable from "../modules/emails/EmailTemplatesTable";

function EmailManagement() {
  return (
    <Content>
      <EmailTemplatesTable />
    </Content>
  );
}

export default EmailManagement;
