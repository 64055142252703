import React from "react";
import {
  Panel,
  PanelHeading,
  TextInput,
  Label,
  PanelRow,
  PanelActions,
  Button,
  PanelSpacer,
  Dropdown,
  CheckBox,
  DropdownOption,
} from "@backslashbuild/pp-ui";
import { usePost } from "./api";
import { useHistory } from "react-router-dom";
import { publish } from "../../pubSub";

function CreateIllustrationForm() {
  const [submitting, setSubmitting] = React.useState(false);
  const [values, setValues] = React.useState({
    taxYear: 2020,
    taxPeriod: 1,
    payFrequency: "Weekly",
    taxCode: "1250L",
    salary: 1000,
    hours: 20,
    days: 0,
    hoursPerDay: 8,

    numberOfPeriods: 1,

    // pension settings
    employeeContributionPercentage: 5,
    employerContributionPercentage: 3,

    // holiday pay settings
    holidayContributionPercentage: "12.07",
    holidayReleasePercentage: "0",

    // margin settings
    enableUmbrellaMode: false,
    marginTaken: 10,

    // student loan settings
    hasPlan1: false,
    hasPlan2: false,
    hasPostgrad: false,

    // legal deductions settings
    hasLegalDeductions: false,

    // app levy settings
    apprenticeshipLevyContributionPercentage: "0.5",

    nationalMinimumWage: "8.72",
  });

  const createIllustration = usePost("illustrations/create");
  const history = useHistory();

  async function handleSubmit() {
    setSubmitting(true);
    try {
      const response = await createIllustration(makeRequest(values));

      if (response.isSuccess) {
        publish("notification", "Illustration Created");
        history.push(`/illustration/${response.value}`);
      } else {
        publish(
          "notification",
          <>
            <ul>
              {response.errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </>
        );
      }
    } catch (e) {
      console.error(e, "error making new pay run");
      publish("notification", "A network error occured.");
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Panel>
      <PanelHeading>New illustration</PanelHeading>
      <PanelSpacer />
      <PanelRow>
        <Label label="Tax year">
          <TextInput
            type="number"
            value={values.taxYear}
            onChange={(e) => setValues({ ...values, taxYear: e.target.value })}
          />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Tax Period">
          <TextInput
            type="number"
            value={values.taxPeriod}
            onChange={(e) => setValues({ ...values, taxPeriod: e.target.value })}
          />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Number of periods">
          <TextInput
            type="number"
            value={values.numberOfPeriods}
            onChange={(e) => setValues({ ...values, numberOfPeriods: e.target.value })}
          />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Pay Frequency">
          <Dropdown
            value={values.payFrequency}
            onChange={(e) => setValues({ ...values, payFrequency: e.target.value })}
          >
            <DropdownOption value="Weekly">Weekly</DropdownOption>
            <DropdownOption value="Monthly">Monthly</DropdownOption>
          </Dropdown>
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Tax code">
          <TextInput
            value={values.taxCode}
            onChange={(e) => setValues({ ...values, taxCode: e.target.value })}
          />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Is Week1/Month1">
          <CheckBox
            checked={values.isWeek1Month1}
            onChange={(e) => setValues({ ...values, isWeek1Month1: e.target.checked })}
          />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Salary">
          <TextInput
            type="number"
            value={values.salary}
            onChange={(e) =>
              setValues({
                ...values,
                salary: e.target.value,
              })
            }
          />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Hours">
          <TextInput
            type="number"
            value={values.hours}
            onChange={(e) =>
              setValues({
                ...values,
                hours: e.target.value,
              })
            }
          />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Days">
          <TextInput
            type="number"
            value={values.days}
            onChange={(e) =>
              setValues({
                ...values,
                days: e.target.value,
              })
            }
          />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Hours per day">
          <TextInput
            type="number"
            value={values.hoursPerDay}
            onChange={(e) =>
              setValues({
                ...values,
                hoursPerDay: e.target.value,
              })
            }
          />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Employee pension contribution">
          <TextInput
            type="number"
            value={values.employeeContributionPercentage}
            suffix="%"
            onChange={(e) =>
              setValues({
                ...values,
                employeeContributionPercentage: e.target.value,
              })
            }
          />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Employer pension contribution">
          <TextInput
            type="number"
            value={values.employerContributionPercentage}
            suffix="%"
            onChange={(e) =>
              setValues({
                ...values,
                employerContributionPercentage: e.target.value,
              })
            }
          />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Holiday Accrual Contribution Percentage">
          <Dropdown
            value={values.holidayContributionPercentage}
            onChange={(v) =>
              setValues({ ...values, holidayContributionPercentage: v.target.value })
            }
          >
            <DropdownOption value="0">0%</DropdownOption>
            <DropdownOption value="12.07">12.07% (20 days)</DropdownOption>
            <DropdownOption value="13.04">13.04% (22 days)</DropdownOption>
            <DropdownOption value="13.54">13.54% (23 days)</DropdownOption>
            <DropdownOption value="14.04">14.04% (24 days)</DropdownOption>
            <DropdownOption value="14.54">14.54% (25 days)</DropdownOption>
            <DropdownOption value="14.36">14.36% (20 days for education)</DropdownOption>
          </Dropdown>
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Holiday Release Percentage">
          <Dropdown
            value={values.holidayReleasePercentage}
            onChange={(v) => setValues({ ...values, holidayReleasePercentage: v.target.value })}
          >
            <DropdownOption value="0">0% (Accrue until requested)</DropdownOption>
            <DropdownOption value="100">100% (Release every payslip)</DropdownOption>
          </Dropdown>
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Apprenticeship levy contribution percentage">
          <Dropdown
            value={values.apprenticeshipLevyContributionPercentage}
            onChange={(v) =>
              setValues({ ...values, apprenticeshipLevyContributionPercentage: v.target.value })
            }
          >
            <DropdownOption value="0">0%</DropdownOption>
            <DropdownOption value="0.5">0.5%</DropdownOption>
          </Dropdown>
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="National Minimum Wage">
          <Dropdown
            value={values.nationalMinimumWage}
            onChange={(v) => setValues({ ...values, nationalMinimumWage: v.target.value })}
          >
            <DropdownOption value="8.72">£8.72 (National Living Wage)</DropdownOption>
            <DropdownOption value="8.20">£8.20 (21-24 Year Old Rate)</DropdownOption>
            <DropdownOption value="6.45">£6.45 (18-20 Year Old Rate)</DropdownOption>
          </Dropdown>
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Enable Umbrella Mode">
          <CheckBox
            checked={values.enableUmbrellaMode}
            onChange={(e) =>
              setValues({
                ...values,
                enableUmbrellaMode: e.target.checked,
              })
            }
          />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Umbrella Margin">
          <TextInput
            disabled={values.enableUmbrellaMode === false}
            value={values.marginTaken}
            onChange={(e) =>
              setValues({
                ...values,
                marginTaken: e.target.value,
              })
            }
          />
        </Label>
      </PanelRow>
      {/* <PanelRow>
        <Label label="Apprenticeship Levy Contribution">
          <TextInput
            disabled={values.enableUmbrellaMode === false}
            value={values.marginTaken}
            onChange={(e) =>
              setValues({
                ...values,
                marginTaken: e.target.value,
              })
            }
          />
        </Label>
      </PanelRow> */}
      <PanelSpacer />

      <PanelActions>
        <Button onClick={handleSubmit}>Create new illustration.</Button>
      </PanelActions>
    </Panel>
  );
}

export default CreateIllustrationForm;

function makeRequest(values) {
  const initialRequest = {
    input: {
      taxYear: 2020,
      taxPeriod: 1,
      payFrequency: 0,
      taxCode: "1250L",
      isWeek1Month1: true,
      niCategory: "A",
      numberOfPeriods: 1,
      employmentSettings: {
        taxCode: "1250L",
        niCategory: "C",
        isWeek1Month1: true,
        hoursPerDay: 6,
        nationalMinimumWage: 8.71,
        leaveDate: null,
        accNo: "TEST123456",
        niNumber: "AA111122B",
        title: "Mr",
        surname: "Biscuit",
        firstName: "Cheesy",
        middleNames: "",
        dob: "1990-01-01",
        gender: "M",
        address1: "",
        address2: "",
        address3: "",
        town: "",
        postCode: "",
        startDate: "2016-01-01",
        startDeclaration: "",
        payrollID: "",
        irregularEmployment: "",
        endDate: "",
      },
      pensionSettings: {
        employerContributionPercentage: 3,
        employeeContributionPercentage: 5,
        isEnabled: true,
      },
      studentLoanSettings: {
        hasPlan1: true,
        hasPlan2: false,
        hasPostgrad: false,
      },
      holidayPaySettings: {
        holidayContributionPercentage: 12.07,
        holidayReleasePercentage: 0,
      },
      apprenticeshipLevySettings: {
        apprenticeshipLevyContributionPercentage: 0.5,
      },
      umbrellaMarginSettings: {
        isEnabled: false,
        maximumMargin: null,
        rates: [
          {
            //id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            startWeek: 0,
            grossBandMinimum: null,
            grossBandMaximum: null,
            isAbsoluteAmount: true,
            absoluteMargin: 10,
            variableMinimum: null,
            variableMaximum: null,
            variablePercentage: 0,
          },
        ],
      },
      legalDeductionsSettings: {
        hasLegalDeductions: true,
      },
      salaryEntries: [
        {
          agencyOrClient: "Agency 1",
          nameOfWorkplace: "Workplace 1",
          hours: 12,
          days: 0,
          rate: 10,
          total: 120,
        },
      ],
      grossTaxablePayAdjustments: [
        // {
        //   amount: 120,
        //   isDeduction: true,
        //   description: "string",
        //   lineItemType: {},
        // },
      ],
      advancesRecoveryAdjustments: [
        // {
        //   amount: 0,
        //   isDeduction: true,
        //   description: "string",
        //   lineItemType: {},
        // },
      ],
      otherBankedAdjustments: [
        // {
        //   amount: 0,
        //   isDeduction: true,
        //   description: "string",
        //   lineItemType: {},
        // },
      ],
    },
  };

  initialRequest.input.taxYear = Number(values.taxYear);
  initialRequest.input.taxPeriod = Number(values.taxPeriod);
  initialRequest.input.numberOfPeriods = Number(values.numberOfPeriods);
  initialRequest.input.payFrequency = String(values.payFrequency);
  initialRequest.input.taxCode = String(values.taxCode);
  initialRequest.input.isWeek1Month1 = Boolean(values.isWeek1Month1);
  initialRequest.input.umbrellaMarginSettings.isEnabled = Boolean(values.enableUmbrellaMode);
  initialRequest.input.umbrellaMarginSettings.rates[0].absoluteMargin = Number(values.marginTaken);

  initialRequest.input.salaryEntries[0].total = Number(values.salary);
  initialRequest.input.salaryEntries[0].hours = Number(values.hours);
  initialRequest.input.salaryEntries[0].days = Number(values.days);
  initialRequest.input.employmentSettings.hoursPerDay = Number(values.hoursPerDay);

  initialRequest.input.apprenticeshipLevySettings.apprenticeshipLevyContributionPercentage = Number(
    values.apprenticeshipLevyContributionPercentage
  );

  initialRequest.input.pensionSettings.employeeContributionPercentage = Number(
    values.employeeContributionPercentage
  );
  initialRequest.input.pensionSettings.employerContributionPercentage = Number(
    values.employerContributionPercentage
  );

  initialRequest.input.holidayPaySettings.holidayContributionPercentage = Number(
    values.holidayContributionPercentage
  );
  initialRequest.input.holidayPaySettings.holidayReleasePercentage = Number(
    values.holidayReleasePercentage
  );

  initialRequest.input.studentLoanSettings.hasPlan2 = Boolean(values.hasPlan2);
  initialRequest.input.studentLoanSettings.hasPlan1 = Boolean(values.hasPlan1);
  initialRequest.input.studentLoanSettings.hasPostgrad = Boolean(values.hasPostgrad);

  initialRequest.input.legalDeductionsSettings.hasLegalDeductions = Boolean(
    values.hasLegalDeductions
  );
  initialRequest.input.employmentSettings.nationalMinimumWage = Number(values.nationalMinimumWage);

  // TODO app levy initialRequest.input.legalDeductionsSettings.hasLegalDeductions = values.hasLegalDeductions;

  //   // app levy settings
  //   apprenticeshipLevyContributionPercentage: 0.5,

  return initialRequest;
}
