import React from "react";
import {
  Panel,
  PanelHeading,
  TextInput,
  Label,
  PanelRow,
  PanelActions,
  Button,
  PanelSpacer,
} from "@backslashbuild/pp-ui";
import { usePost } from "./api";
import { publish } from "../../pubSub";
import { utcToYmd } from "./utils";

export default function NewHolidayYearOverrideForm({ employmentId, onClose = () => {} }) {
  const [submitting, setSubmitting] = React.useState(false);
  const [payload, setPayload] = React.useState({
    employmentId,
    holidayYear: null,
    startDate: null,
  });

  const create = usePost("holiday-year-overrides");

  async function handleSubmit() {
    setSubmitting(true);
    try {
      const response = await create({ ...payload });
      if (response.isSuccess) {
        publish("notification", "Override created");
      } else {
        publish(
          "notification",
          <>
            <ul>
              {response.errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </>
        );
      }
    } catch (e) {
      console.error(e, "error creating a holiday year override");
      publish("notification", "A network error occured.");
    } finally {
      onClose();
      setSubmitting(false);
    }
  }

  function handleRender() {
    return (
      <>
        <PanelRow>
          <Label label="Start Date">
            <TextInput
              value={payload.startDate}
              type="date"
              onChange={(e) => {
                setPayload({ ...payload, startDate: utcToYmd(e.target.value) });
              }}
            ></TextInput>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Holiday Year">
            <TextInput
              type="number"
              value={payload.holidayYear}
              onChange={(e) => setPayload({ ...payload, holidayYear: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
      </>
    );
  }

  return (
    <>
      <Panel>
        <PanelHeading>New Holiday Year Override</PanelHeading>
        <PanelSpacer />
        {handleRender()}
        <PanelSpacer />

        <PanelActions>
          <Button onClick={handleSubmit}>Create</Button>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
        </PanelActions>
      </Panel>
    </>
  );
}
