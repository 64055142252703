import React from "react";
import { useParams } from "react-router-dom";
import { isTextNullOrEmpty } from "./utils";
import { useGetReportByName } from "./api";

import {
  PageLoading,
  PageHeadingAreaSpacer,
  PageHeadingArea,
  PageTitle,
} from "@backslashbuild/pp-ui";

export default function ViewReport() {
  const { reportName } = useParams();
  const { data, loading, error, queryId, refetch } = useGetReportByName(reportName);

  if (loading) {
    return <PageLoading />;
  }

  if (!isTextNullOrEmpty(error)) {
    return <div>{String(error)}</div>;
  }

  function handleRender() {
    if (data?.isSuccess) {
      return (
        <div style={{ width: "100%", height: "100%", overflow: "hidden" }}>
          <iframe
            seamless="seamless"
            frameBorder={0}
            style={{
              width: "100%",
              height: "100%",

              border: "none",
            }}
            src={data?.value?.url}
          />
        </div>
      );
    }
  }

  return (
    <>
      <PageHeadingArea>
        <PageTitle>{data?.value?.title}</PageTitle>
        <PageHeadingAreaSpacer grow />
      </PageHeadingArea>
      {data?.errors?.map((error) => (
        <div>{error}</div>
      ))}
      {handleRender()}
    </>
  );
}
