import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Content,
  PanelColumn,
  PanelColumnSpacer,
  Panel,
  PanelSpacer,
  PanelRow,
  PanelHeading,
} from "@backslashbuild/pp-ui";
import EmploymentPageHeader from "../modules/nest/EmploymentPageHeader";
import EmploymentEnrolmentStatusPanelRow from "../modules/nest/EmploymentEnrolmentStatusPanelRow";
import EmploymentIgnoredStatusPanelRow from "../modules/nest/EmploymentIgnoredStatusPanelRow";
import ContractorProductNESTPanelRows from "../modules/contractors/ContractorProductNESTPanelRows";
import NINumberOverridePanelRow from "../modules/nest/NINumberOverridePanelRow";
import AccountNumberOverridePanelRow from "../modules/nest/AccountNumberOverridePanelRow";
import GroupOverridePanelRow from "../modules/nest/GroupOverridePanelRow";

function NESTEmployment() {
  const { accountNumber } = useParams();

  return (
    <Content scrollable>
      <EmploymentPageHeader accountNumber={accountNumber} />
      <PanelColumn>
        <Panel>
          <PanelHeading>Employment Details</PanelHeading>
          <EmploymentEnrolmentStatusPanelRow accountNumber={accountNumber} />
          <EmploymentIgnoredStatusPanelRow accountNumber={accountNumber} />
          {/* <ContractorProductNESTPanelRows accountNumber={accountNumber} /> */}
        </Panel>
        <PanelSpacer large />
        <Panel>
          <PanelHeading>Employment Overrides</PanelHeading>
          <NINumberOverridePanelRow employmentId={accountNumber} />
          <GroupOverridePanelRow employmentId={accountNumber} />
          <AccountNumberOverridePanelRow employmentId={accountNumber} />
        </Panel>
      </PanelColumn>
    </Content>
  );
}

export default NESTEmployment;
