import React from "react";
import { useParams, useHistory } from "react-router-dom";

import { Content, PageHeadingArea, BackArrow } from "@backslashbuild/pp-ui";

import Border from "../components/Border";

import ContractorProductPageTitle from "../modules/contractors/ContractorProductPageTitle";
import {
  Button,
  PageHeadingAreaSpacer,
  PanelDialog,
  PanelHeading,
} from "@backslashbuild/pp-ui/dist/index.es";
import EmploymentStatutoryPayGroupsTable from "../modules/payroll/EmploymentStatutoryPayGroupsTable";
import CreateStatutoryPayGroupDialog from "../modules/payroll/CreateStatutoryPayGroupDialog";

function ContractorProductStatutoryPayGroups() {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const history = useHistory();
  const { contractorProductId } = useParams();

  return (
    <Content scrollable>
      <PageHeadingArea>
        <BackArrow onClick={() => history.goBack()} />
        <ContractorProductPageTitle
          contractorProductId={contractorProductId}
          title={"Statutory Pay"}
        />
        <PageHeadingAreaSpacer grow />
        <Button onClick={() => setDialogOpen(true)}>Add Statutory Pay</Button>
      </PageHeadingArea>

      <Border>
        <EmploymentStatutoryPayGroupsTable contractorProductId={contractorProductId} />
      </Border>

      <PanelDialog open={dialogOpen}>
        <PanelHeading>Create Statutory Pay Group</PanelHeading>
        <CreateStatutoryPayGroupDialog
          contractorProductId={contractorProductId}
          onCancel={() => setDialogOpen(false)}
        />
      </PanelDialog>
    </Content>
  );
}

export default ContractorProductStatutoryPayGroups;
