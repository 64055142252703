import { Button } from "@backslashbuild/pp-ui";
import React from "react";

const apiHost = process.env.REACT_APP_AGENCY_PORTAL_HOST;

export default function EmulateUserButton({ agencyId }) {
  return (
    <Button
      large
      onClick={() => {
        window.open(`${apiHost}/your-agency?agencyId=${agencyId}`, "_blank", "noopener,noreferrer");
      }}
    >
      Emulate
    </Button>
  );
}
