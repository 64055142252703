import React from "react";
import { Panel, PanelActions, PanelHeading, Button, PanelRow } from "@backslashbuild/pp-ui";
import { usePost, useDownloadExportCSV, invalidateInvoices } from "./api";
import { publish } from "../../pubSub";

export default function CreateInvoiceExportForm({ pendingExport, onClose = () => {} }) {
  const [submitting, setSubmitting] = React.useState(false);
  const [batchNumber, setBatchNumber] = React.useState(null);
  const create = usePost();
  const download = useDownloadExportCSV(batchNumber);

  function handleCreate() {
    setSubmitting(true);
    create("exports/export/create", {
      categoryId: pendingExport.categoryId,
      weekNumber: pendingExport.weekNumber,
    })
      .then((res) => {
        if (res?.isSuccess) {
          setBatchNumber(res.value);
          publish("notification", "Export created");
          onClose();
        } else {
          publish("notification", "Unable to create export");
          console.log(res);
        }
      })
      .finally((res) => {
        setSubmitting(false);
        invalidateInvoices();
      });
  }

  React.useEffect(() => {
    if (batchNumber) {
      download();
    }
  }, batchNumber);

  function handleRender() {
    if (pendingExport) {
      return (
        <PanelRow>
          <div>{`This will export ${pendingExport?.numberOfItems} invoices.`}</div>
        </PanelRow>
      );
    } else {
      return <PanelRow>Error loading invoices.</PanelRow>;
    }
  }

  return (
    <>
      <Panel>
        <PanelHeading>Create Invoice Export</PanelHeading>
        {handleRender()}
        <PanelActions>
          <Button variant="secondary" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleCreate();
            }}
            disabled={submitting}
          >
            Export
          </Button>
        </PanelActions>
      </Panel>
    </>
  );
}
