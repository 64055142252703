import React from "react";
import { QueryDropdown } from "@backslashbuild/pp-ui";
import { useGetItems, useGet } from "../contractors/api";

function ContractorProductPicker({ value, onChange, agencyId }) {
  const [search, setSearch] = React.useState("");
  const optionsQuery = useGetItems(
    search
      ? `contractor-products?searchTerm=${search}&take=25${agencyId ? `&agencyId=${agencyId}` : ""}`
      : `contractor-products?take=25${agencyId ? `&agencyId=${agencyId}` : ""}`
  ); // TODO urlencode

  const options = optionsQuery.data?.value?.items ?? [];

  // Debounce the search
  var searchTimeout = null;
  const setSearchTerm = (searchTerm) => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      setSearch(searchTerm);
    }, 500);
  };

  return (
    <QueryDropdown
      placeholder="Choose"
      // search={search}
      onSearchChange={setSearchTerm}
      value={value}
      onChange={onChange}
      options={options.map((o) => o.id)}
      renderOption={(v) => {
        var optionsMatch = options.find((o) => o.id == v);

        if (optionsMatch) {
          return `${optionsMatch?.accountNumber} ${optionsMatch?.contractorName} (${optionsMatch?.productName})`;
        }

        return "loading...";
      }}
    />
  );
}

export default ContractorProductPicker;
