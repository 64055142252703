import React from "react";
import { usePostCloseDraft } from "./api";
import { useHistory } from "react-router-dom";
import { publish } from "./pubSub";

import { PanelDialog, PanelActions, Button, PanelRow, PanelHeading } from "@backslashbuild/pp-ui";

export default function DeactivateDialog({ open, onClose, draftId }) {
  const history = useHistory();
  const post = usePostCloseDraft(draftId);

  function handleConfirm() {
    post().then((res) => {
      if (res.isSuccess) {
        publish("notification", "Success! The draft has been deactivated.");
        history.push("/hmrc-fps");
      } else {
        publish(
          "notification",
          <div>
            Attempt Failed
            <ul>
              {res?.errors?.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </div>
        );
      }
    });
  }

  return (
    <PanelDialog open={open} onClose={onClose}>
      <PanelHeading>Deactivate Draft</PanelHeading>
      <PanelRow>
        <p>
          Click on 'Confirm' to permanently deactivate this draft. You will be taken back to the FPS
          page. This draft will no longer be eligible for HMRC submissions.
        </p>
      </PanelRow>
      <PanelActions>
        <Button variant="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleConfirm();
            onClose();
          }}
        >
          Confirm
        </Button>
      </PanelActions>
    </PanelDialog>
  );
}
