import React from "react";
import { Panel, PanelHeading, PanelSpacer, PanelRow, Label, BoldText } from "@backslashbuild/pp-ui";
import { formatCurrency } from "../../utils";

function SettingsSnapshotPanel({ settingsSnapshot }) {
  return (
    <Panel>
      <PanelHeading>Settings used</PanelHeading>
      <PanelSpacer />

      <hr />

      <PanelRow small>
        <Label small label="Tax Code">
          <BoldText>
            {settingsSnapshot?.employmentSettings?.taxCode}{" "}
            {settingsSnapshot?.employmentSettings?.isWeek1Month1 && "(W1)"}
          </BoldText>
        </Label>
      </PanelRow>
      <PanelRow small>
        <Label small label="NI Number">
          <BoldText>{settingsSnapshot?.employmentSettings?.niNumber}</BoldText>
        </Label>
      </PanelRow>

      <PanelRow small>
        <Label small label="NI Letter">
          <BoldText>{settingsSnapshot?.employmentSettings?.taxCode}</BoldText>
        </Label>
      </PanelRow>

      <PanelRow small>
        <Label small label="Hours per day">
          <BoldText>{settingsSnapshot?.employmentSettings?.hoursPerDay}</BoldText>
        </Label>
      </PanelRow>

      <PanelRow small>
        <Label small label="National minimum wage">
          <BoldText>
            {formatCurrency(settingsSnapshot?.employmentSettings?.nationalMinimumWage)}
          </BoldText>
        </Label>
      </PanelRow>

      <PanelRow small>
        <Label small label="Hours per day">
          <BoldText>{settingsSnapshot?.employmentSettings?.hoursPerDay}</BoldText>
        </Label>
      </PanelRow>

      <hr />

      <PanelRow>Student loans</PanelRow>

      <PanelRow small>
        <Label small label="Plan 1">
          <BoldText>{settingsSnapshot?.studentLoanSettings?.hasPlan1 ? "yes" : ""}</BoldText>
        </Label>
      </PanelRow>
      <PanelRow small>
        <Label small label="Plan 2">
          <BoldText>{settingsSnapshot?.studentLoanSettings?.hasPlan2 ? "yes" : ""}</BoldText>
        </Label>
      </PanelRow>
      <PanelRow small>
        <Label small label="Postgrad">
          <BoldText>{settingsSnapshot?.studentLoanSettings?.hasPostgrad ? "yes" : ""}</BoldText>
        </Label>
      </PanelRow>

      <hr />

      <PanelRow>Pension</PanelRow>

      <PanelRow small>
        <Label small label="Employer contribution">
          <BoldText>{settingsSnapshot?.pensionSettings?.employerContributionPercentage}%</BoldText>
        </Label>
      </PanelRow>

      <PanelRow small>
        <Label small label="Employee contribution">
          <BoldText>{settingsSnapshot?.pensionSettings?.employeeContributionPercentage}%</BoldText>
        </Label>
      </PanelRow>

      <hr />

      <PanelRow>Holiday pay</PanelRow>

      <PanelRow small>
        <Label small label="Contribution">
          <BoldText>
            {settingsSnapshot?.holidayPaySettings?.holidayContributionPercentage}%
          </BoldText>
        </Label>
      </PanelRow>

      <PanelRow small>
        <Label small label="Release">
          <BoldText>{settingsSnapshot?.holidayPaySettings?.holidayReleasePercentage}%</BoldText>
        </Label>
      </PanelRow>

      <hr />

      <PanelRow small>
        <Label small label="Hours per day">
          <BoldText>{settingsSnapshot?.employmentSettings?.hoursPerDay}</BoldText>
        </Label>
      </PanelRow>

      {/* "taxCode": "BR",
          "niCategory": "A",
          "isWeek1Month1": true,
          "hoursPerDay": 6,
          "nationalMinimumWage": 6.45,
          "leaveDate": null,
          "accNo": "test2-00000001",
          "niNumber": "vpof1514f",
          "title": "Dr",
          "surname": "Volkman",
          "firstName": "Nikko",
          "middleNames": "Mina",
          "dob": "1986-09-11",
          "gender": "M",
          "address1": "208 Sylvan Orchard",
          "address2": "",
          "address3": "",
          "town": "Delphaville",
          "postCode": "FI0 4NT",
          "startDate": "2020-03-01",
          "startDeclaration": "YES",
          "payrollID": "13241234",
          "irregularEmployment": "YES",
          "endDate": "" */}
    </Panel>
  );
}

export default SettingsSnapshotPanel;
