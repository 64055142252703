import React from "react";
import { useParams } from "react-router-dom";

import { Content, PageHeadingArea, PageTitle } from "@backslashbuild/pp-ui";

import EmailTemplateEditor from "../modules/emails/EmailTemplateEditor";

function EditTemplatePage() {
  const { id } = useParams();

  return (
    <Content scrollable>
      <PageHeadingArea>
        <PageTitle>Edit Email Template</PageTitle>
      </PageHeadingArea>
      <EmailTemplateEditor id={id} />
    </Content>
  );
}

export default EditTemplatePage;
