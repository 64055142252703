import React from "react";
import {
  PageLoading,
  PanelDialog,
  PanelHeading,
  PanelActions,
  PanelRow,
  Button,
  Label,
  QueryDropdown,
  TextInput,
  Dropdown,
  DropdownOption,
  CheckBox,
  PanelSpacer,
} from "@backslashbuild/pp-ui";
import { usePost } from "./api";
import { publish } from "../../pubSub";
import TaxWeekPicker from "../tax-calendar/TaxWeekPicker";
import * as uuid from "uuid";
import CompanyPicker from "./CompanyPicker";
import PayFrequencyPicker from "./PayFrequencyPicker";
import { useHistory } from "react-router-dom";

export default function AddRFPDialog() {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  function handleCancel() {
    setDialogOpen(false);
  }

  return (
    <>
      <Button large onClick={() => setDialogOpen(true)}>
        Add new FPS
      </Button>
      <PanelDialog onClose={handleCancel} open={dialogOpen}>
        <PanelHeading>New FPS</PanelHeading>
        <PanelSpacer />
        <AddRFPForm onCancel={handleCancel} />
        <PanelSpacer />
      </PanelDialog>
    </>
  );
}

function AddRFPForm({ onCancel }) {
  const history = useHistory();
  const [taxWeek, setTaxWeek] = React.useState(null);

  const [request, setRequest] = React.useState({
    reference: uuid.v4(),
    // companyId: "",
    employmentId: null,
    typeId: "",
    description: "",
    clientName: "",
    workplaceName: "",
    requestedAmount: 0,
    hours: 0,
    days: 0,
    rate: 0,
    isPartialResolutionAllowed: false,
  });

  const post = usePost("fps");

  async function handleSubmit() {
    const result = await post({
      ...request,
      requestedAmount: Number(request.requestedAmount),
      hours: Number(request.hours),
      days: Number(request.days),
      rate: Number(request.rate),
    });
    if (!result.isSuccess) {
      var errors = result.errors;
      publish(
        "notification",
        <ul>
          {errors.map((error) => {
            return <li>{error}</li>;
          })}
        </ul>
      );
      return;
    }
    const id = result.value;
    publish("notification", "New FPS added");
    history.push(`/fps/${id}`);
  }

  const disableBtn = !request.companyId;

  return (
    <>
      <PanelRow>
        <Label label="Week Number">
          <TaxWeekPicker value={taxWeek} onChange={setTaxWeek} />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Company">
          <CompanyPicker
            value={request.companyId}
            onChange={(v) => setRequest({ ...request, companyId: v })}
          />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Employee Pay Frequency">
          <PayFrequencyPicker
            value={request.payFrequency}
            onChange={(v) => setRequest({ ...request, payFrequency: v })}
          />
        </Label>
      </PanelRow>
      <PanelActions>
        <Button disabled={disableBtn} variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={disableBtn} variant="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </PanelActions>
    </>
  );
}
