import React from "react";
import {
  Panel,
  PanelActions,
  PanelHeading,
  Button,
  PanelRow,
  Label,
  DatePicker,
  TextInput,
} from "@backslashbuild/pp-ui";
import { useUpdateInvoiceDetails } from "./api";
import { BoldText } from "@backslashbuild/pp-ui/dist/index.es";
import { formatCurrency } from "../../utils";
import HtmlEditor from "./HtmlEditor";

export default function EditInvoiceForm({ invoice, onClose }) {
  const [invoiceDate, setInvoiceDate] = React.useState(invoice.invoiceDate);
  const [weekNumber, setWeekNumber] = React.useState(invoice.weekNumber);
  const [comments, setComments] = React.useState(invoice.comments ?? "");
  const [subComments, setSubComments] = React.useState(invoice.subComments ?? "");

  const update = useUpdateInvoiceDetails();

  async function handleSave() {
    await update(invoice.invoiceNumber, {
      invoiceDate,
      comments,
      subComments,
      weekNumber,
    });
    onClose();
  }

  return (
    <>
      <Panel maxWidth={1000}>
        <PanelHeading>Update Invoice Details</PanelHeading>
        <PanelRow>
          <Label label="Agency">
            <BoldText>{invoice?.client}</BoldText>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Invoice Number">
            <BoldText>{invoice?.invoiceNumber}</BoldText>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Total Amount">
            <BoldText>{formatCurrency(invoice?.totalInvoiceValue)}</BoldText>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Invoice Date">
            <DatePicker value={invoiceDate} onChange={(e) => setInvoiceDate(e)} />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Week Number">
            <TextInput value={weekNumber} onChange={(e) => setWeekNumber(e.target.value)} />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Details">
            <HtmlEditor content={comments} setContent={setComments} submitting={false} />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Breakdown Summary">
            <HtmlEditor content={subComments} setContent={setSubComments} submitting={false} />
          </Label>
        </PanelRow>
        <PanelActions>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSave}>Update</Button>
        </PanelActions>
      </Panel>
    </>
  );
}
