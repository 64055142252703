import React from "react";
import { Button } from "@backslashbuild/pp-ui";
import { usePost } from "./api";
import { publish } from "../../pubSub";

export default function ResetInvoiceIndexButton() {
  const reset = usePost("invoices/index/reset");

  async function handleResetIndex() {
    publish("notification", "Resetting the invoice index...");
    const result = await reset();

    if (!result.isSuccess) {
      var errors = result?.errors;
      publish(
        "notification",
        <ul>
          Unable to reset invoice index. Errors:
          {errors?.length > 0 &&
            errors?.map((error) => {
              return <li>{error}</li>;
            })}
        </ul>
      );
      return;
    }

    if (result?.isSuccess) publish("notification", "Reset completed.");
  }

  return (
    <>
      <Button small onClick={() => handleResetIndex()} style={{ width: 200 }}>
        Reset Invoice Index
      </Button>
    </>
  );
}
