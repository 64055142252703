import React from "react";
import { useLocation } from "react-router-dom";
import {
  TableRow,
  Table,
  TableCell,
  TableHeaderColumn,
  TableFooter,
  TableBody,
  BoldText,
  TableHeader,
} from "@backslashbuild/pp-ui";
import { Link as RouterLink } from "react-router-dom";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";
import { useGetContributionSchedules } from "./api";
import queryString from "query-string";
import { utcToYmd } from "./utils";

const c = {
  dateCreated: 150,
  nestEmployerReferenceNumber: 150,
  paymentSource: 150,
  paymentDueDate: 200,
  paymentFrequency: 150,
  earningsPeriodStartDate: 200,
  earningsPeriodEndDate: 200,
  // isBulkUpdateForInsufficientEarners: 200,
  // isExceptionSchedule: 200,
  // reasonForExceptionSchedule: 200,
};

function PureTable({ data, queryId }) {
  return React.useMemo(() =>
    data.value.items.map(
      (contributionSchedule) => (
        <TableRow
          key={contributionSchedule.id}
          component={RouterLink}
          to={`/contribution-schedule/${contributionSchedule.id}`}
        >
          <TableCell width={c.dateCreated}>{utcToYmd(contributionSchedule.dateCreated)}</TableCell>
          <TableCell width={c.nestEmployerReferenceNumber}>
            {contributionSchedule.nestEmployerReferenceNumber}
          </TableCell>
          <TableCell width={c.paymentSource}>{contributionSchedule.paymentSource}</TableCell>
          <TableCell width={c.paymentDueDate}>
            {utcToYmd(contributionSchedule.paymentDueDate)}
          </TableCell>
          <TableCell width={c.paymentFrequency}>{contributionSchedule.paymentFrequency}</TableCell>
          <TableCell width={c.earningsPeriodStartDate}>
            {utcToYmd(contributionSchedule.earningsPeriodStartDate)}
          </TableCell>
          <TableCell width={c.earningsPeriodEndDate}>
            {utcToYmd(contributionSchedule.earningsPeriodEndDate)}
          </TableCell>
          {/* <TableCell width={c.isBulkUpdateForInsufficientEarners}>
            <StatusIndicator value={contributionSchedule.isBulkUpdateForInsufficientEarners} />
          </TableCell>
          <TableCell width={c.isExceptionSchedule}>
            <StatusIndicator value={contributionSchedule.isExceptionSchedule} />
          </TableCell>
          <TableCell width={c.reasonForExceptionSchedule}>
            {contributionSchedule.reasonForExceptionSchedule}
          </TableCell> */}
        </TableRow>
      ),
      [queryId]
    )
  );
}

function TableSkeleton() {
  return (
    <TableRow>
      <TableCell width={c.dateCreated}>-</TableCell>
      <TableCell width={c.nestEmployerReferenceNumber}>-</TableCell>
      <TableCell width={c.paymentSource}>-</TableCell>
      <TableCell width={c.paymentDueDate}>-</TableCell>
      <TableCell width={c.paymentFrequency}>-</TableCell>
      <TableCell width={c.earningsPeriodStartDate}>-</TableCell>
      <TableCell width={c.earningsPeriodEndDate}>-</TableCell>
    </TableRow>
  );
}

function ContributionSchedulesTable() {
  const location = useLocation();
  const queryPagingString = usePagination(location.search);
  const queryPaging = queryString.parse(queryPagingString);

  const stringifiedQuery = queryString.stringify({
    ...queryPaging,
  });

  const { data, loading, error, queryId } = useGetContributionSchedules(stringifiedQuery);

  const isLoaded = Boolean(data?.value);

  return (
    <Table fullPageTable={true}>
      <TableHeader>
        <TableHeaderColumn width={c.dateCreated}>Date Created</TableHeaderColumn>
        <TableHeaderColumn width={c.nestEmployerReferenceNumber}>
          Employer Reference
        </TableHeaderColumn>
        <TableHeaderColumn width={c.paymentSource}>Payment Source</TableHeaderColumn>
        <TableHeaderColumn width={c.paymentDueDate}>Payment Due Date</TableHeaderColumn>
        <TableHeaderColumn width={c.paymentFrequency}>Payment Frequency</TableHeaderColumn>
        <TableHeaderColumn width={c.earningsPeriodStartDate}>
          Earnings Period Start Date
        </TableHeaderColumn>
        <TableHeaderColumn width={c.earningsPeriodEndDate}>
          Earnings Period End Date
        </TableHeaderColumn>
      </TableHeader>
      <TableBody fullPageTable={true} footerHeight={TableFooter.height}>
        {isLoaded ? (
          <PureTable data={data} queryId={queryId} />
        ) : data?.errors ? (
          data?.errors.map((error) => <div>{error}</div>)
        ) : (
          <TableSkeleton />
        )}
      </TableBody>
      <TableFooter>
        {loading && <BoldText>Loading...</BoldText>}
        {error && <BoldText>A network error occured.</BoldText>}
        <QueryPaginator
          itemsTotal={data?.value?.itemsTotal}
          itemsRetrieved={data?.value?.itemsRetrieved}
        />
      </TableFooter>
    </Table>
  );
}

export default ContributionSchedulesTable;
