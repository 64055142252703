/* eslint-disable */
import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { useGetFpsItems } from "./api";
import { isTextNullOrEmpty, utcToLocalText } from "./utils";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";
import ViewHmrcErrors from "./ViewHmrcErrors";
import GreenTick from "../../components/GreenTick";

import {
  TableRow,
  Table,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableFooter,
  PageLoading,
} from "@backslashbuild/pp-ui";

function ParseQuery(search) {
  const parsedQueryString = queryString.parse(search);

  return `?${queryString.stringify({ ...parsedQueryString })}`;
}

function parseSubmissionStatus(submission) {
  let status = "";

  if (submission?.hasErrors) {
    status = "Failed";
  } else {
    if (!submission?.isSubmitted) {
      status = "Preparing submission";
    } else if (submission?.isSubmitted && !submission?.isPollingDone) {
      status = "Processing";
    } else if (submission?.isPollingDone) status = "Done";
  }

  return status;
}

function parseErrors(errors) {
  let result = <></>;

  if (errors) {
    errors?.map((error) => {
      if (error.message.includes("found HMRC errors")) {
        const messageComponents = error.message?.split("=");
        const rtiInformationId = messageComponents[1]?.replace(" ", "").replace(".", "");

        result = <ViewHmrcErrors rtiInformationId={rtiInformationId} />;
      } else {
        result = <div>{`${error.requestType}: ${error.message}`}</div>;
      }
    });
  }

  return result;
}

const c = {
  dateCreated: "12%",
  companyName: "10%",
  employeeNo: "7%",
  status: "8%",
  isFinalSubmission: "10%",
  errors: "53%",
};

const totalWidth = Object.keys(c).reduce((total, key) => (total += c[key]), 0);

function ClickWrapper({ children, submission, isErrorColumn }) {
  const history = useHistory();
  let result = (
    <div onClick={() => history.push(`/hmrc-fps-employees?requestId=${submission?.id}`)}>
      {children}
    </div>
  );

  if (isErrorColumn && submission.hasErrors) {
    result = <div>{children}</div>;
  }

  return result;
}

function PureTable({ data, queryId }) {
  return React.useMemo(() =>
    data?.value?.items?.map(
      (submission) => (
        <TableRow>
          <TableCell width={c.dateCreated}>
            <ClickWrapper submission={submission}>
              {utcToLocalText(submission.dateCreated, "dd/MM/yyyy HH:mm")}
            </ClickWrapper>
          </TableCell>
          <TableCell width={c.companyName}>
            <ClickWrapper submission={submission}>{submission.company?.name}</ClickWrapper>
          </TableCell>
          <TableCell width={c.employeeNo}>
            <ClickWrapper submission={submission}>{submission.numberOfEmployees}</ClickWrapper>
          </TableCell>
          <TableCell width={c.status}>
            <ClickWrapper submission={submission}>{parseSubmissionStatus(submission)}</ClickWrapper>
          </TableCell>
          <TableCell width={c.isFinalSubmission}>
            {submission?.isFinalSubmission && <GreenTick />}
          </TableCell>
          <TableCell width={c.errors}>
            <ClickWrapper submission={submission} isErrorColumn>
              {parseErrors(submission.errors)}
            </ClickWrapper>
          </TableCell>
        </TableRow>
      ),
      [queryId]
    )
  );
}

export default function FpsTable() {
  const location = useLocation();
  const search = usePagination(location.search);
  const query = ParseQuery(search);
  const { data, loading, error, queryId, refetch } = useGetFpsItems(query);

  if (loading) return <PageLoading />;
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  return (
    <>
      <Table standardTable width={totalWidth}>
        <TableHeader>
          <TableHeaderColumn width={c.dateCreated}>Date Created</TableHeaderColumn>
          <TableHeaderColumn width={c.companyName}>Company</TableHeaderColumn>
          <TableHeaderColumn width={c.employeeNo}>Employees</TableHeaderColumn>
          <TableHeaderColumn width={c.status}>Status</TableHeaderColumn>
          <TableHeaderColumn width={c.isFinalSubmission}>Final Submission</TableHeaderColumn>
          <TableHeaderColumn width={c.errors}>Errors</TableHeaderColumn>
        </TableHeader>
        <TableBody fullPageTable={true} footerHeight={TableFooter.height}>
          <PureTable data={data} queryId={queryId} />
        </TableBody>
        <TableFooter>
          <QueryPaginator
            itemsTotal={data?.value?.itemsTotal}
            itemsRetrieved={data?.value?.itemsRetrieved}
          />
        </TableFooter>
      </Table>
    </>
  );
}
