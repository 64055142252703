import React from "react";
import { useGetCSVImports, useDownloadCSVImport } from "./api";
import {
  Panel,
  PanelRow,
  Link,
  Table,
  TableRow,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  StatusIndicator,
} from "@backslashbuild/pp-ui";
import { DateTime } from "luxon";
import { Link as RouterLink } from "react-router-dom";

function DownloadLink({ id }) {
  const download = useDownloadCSVImport(id);

  return (
    <Link
      onClick={(event) => {
        event.preventDefault();
        download();
      }}
    >
      Download
    </Link>
  );
}

function CSVImportsList({ id }) {
  const importsQuery = useGetCSVImports({ scheduleId: id });

  if (importsQuery.loading) {
    return <PanelRow>loading...</PanelRow>;
  }

  return (
    <Table>
      <TableHeader>
        <TableHeaderColumn width={274}>Date added</TableHeaderColumn>
        <TableHeaderColumn width={100}>Matches created</TableHeaderColumn>
        <TableHeaderColumn width={100}>Imported</TableHeaderColumn>
        <TableHeaderColumn width={100}>Actions</TableHeaderColumn>
      </TableHeader>
      {importsQuery.data?.value?.items?.map((x, i) => (
        <TableRow component={RouterLink} to={`/schedule-c-import/${x.id}`}>
          <TableCell width={274}>
            {DateTime.fromISO(x.dateCreated).toFormat("yyyy-MM-dd HH:mm")}
          </TableCell>
          <TableCell width={100}>
            <StatusIndicator value={x.isMatchesCreated} />
          </TableCell>
          <TableCell width={100}>
            <StatusIndicator value={x.isImported} />
          </TableCell>
          <TableCell width={100}>
            <DownloadLink id={x.id} />
          </TableCell>
        </TableRow>
      ))}
    </Table>
  );
}

export default CSVImportsList;
