import React from "react";
import { useParams } from "react-router-dom";

import { Content, PageHeadingArea, PageTitle } from "@backslashbuild/pp-ui";

import TemplateEditor from "../modules/contracts/TemplateEditor";

function EditTemplatePage() {
  const { id } = useParams();

  return (
    <Content scrollable>
      <PageHeadingArea>
        <PageTitle>Edit Contract Template</PageTitle>
      </PageHeadingArea>
      <TemplateEditor id={id} />
    </Content>
  );
}

export default EditTemplatePage;
