import {
  PageLoading,
  Panel,
  PanelHeading,
  PanelSpacer,
  PanelRow,
  BoldText,
  Label,
  Table,
  TableBody,
  TableRow,
} from "@backslashbuild/pp-ui";
import React from "react";
import getApiUrl from "../../getApiUrl";
import { useGet } from "./api";
import { isTextNullOrEmpty } from "./utils";

const P45 = ({ p45 }) => {
  return (
    <>
      <TableRow
        onClick={() => {
          window.open(
            `${getApiUrl()}/contractors/generate-p45?p45RequestId=${p45?.id}`,
            "_blank",
            "noopener,noreferrer"
          );
        }}
      >
        <PanelRow>
          <Label label="Contractor Product">
            <BoldText>{p45?.contractorProductAccountNumber}</BoldText>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Document Type">
            <BoldText>P45</BoldText>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Status">
            <BoldText>
              {p45?.documentEmailedDate
                ? "Emailed"
                : p45?.documentGeneratedDate
                ? "Document generated"
                : "Pending"}
            </BoldText>
          </Label>
        </PanelRow>
      </TableRow>
    </>
  );
};

export default function ContractorDocumentsPanel({ contractorId }) {
  const { data, loading, error } = useGet(`contractor-p45Requests?contractorId=${contractorId}`);

  if (loading) return <PageLoading />;

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    return (
      <>
        <Table>
          <TableBody>
            {data.value.items.map((p45, index) => (
              <P45 p45={p45} key={index} />
            ))}
          </TableBody>
        </Table>
      </>
    );
  }

  if (data?.value?.items?.length < 1) return null;

  return (
    <>
      <Panel>
        <PanelHeading>Documents</PanelHeading>
        <PanelSpacer />
        {handleRender()}
        <PanelSpacer />
      </Panel>
      <PanelSpacer large />
    </>
  );
}
