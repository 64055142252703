import React from "react";
import { utcToLocalText } from "./utils";

import { PanelDialog, PanelActions, Button, PanelRow, PanelHeading } from "@backslashbuild/pp-ui";

export default function ViewDraftErrorsDialog({ open, onClose, data }) {
  return (
    <PanelDialog open={open} onClose={onClose} width={800}>
      <PanelHeading>Draft Errors</PanelHeading>
      <PanelRow>
        {!data?.isSuccess && data?.errors?.map((error) => <div>{error}</div>)}
        {data?.isSuccess &&
          data?.value?.errors?.map((error) => (
            <>
              <PanelRow>
                <b>
                  {error.identity ? `${error.identity}: ` : ""}
                  {utcToLocalText(error.dateCreated, "dd/MM/yyyy HH:mm")}
                </b>{" "}
                {error.message}
              </PanelRow>
            </>
          ))}
      </PanelRow>
      <PanelActions>
        <Button variant="secondary" onClick={() => onClose()}>
          Close
        </Button>
      </PanelActions>
    </PanelDialog>
  );
}
