import React from "react";
import { useGetTopics } from "../modules/internal-comms/comms-topics/api";
import {
  Content,
  PageTitle,
  Button,
  PageHeadingArea,
  BackArrow,
  Panel,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableRow,
  TableCell,
  PageHeadingAreaSpacer,
} from "@backslashbuild/pp-ui";
import GreenTick from "../components/GreenTick";
import { Link as RouterLink } from "react-router-dom";

export default function CommsTopics() {
  const { data, loading, error } = useGetTopics("");

  if (loading) {
    return (
      <Content scrollable>
        <PageHeadingArea>
          <PageTitle>Communication Log Topics</PageTitle>
        </PageHeadingArea>
        Loading topics...
      </Content>
    );
  }

  if (error || !data) {
    return (
      <Content scrollable>
        <PageHeadingArea>
          <PageTitle>Communication Log Topics</PageTitle>
        </PageHeadingArea>
        Could not load topics.
      </Content>
    );
  }

  return (
    <Content scrollable>
      <PageHeadingArea>
        <PageTitle>Communication Log Topics</PageTitle>
        <PageHeadingAreaSpacer grow />
        <Button large component={RouterLink} to="/new-comms-topic">
          Add Topic
        </Button>
      </PageHeadingArea>

      <Panel>
        <TableHeader>
          <TableHeaderColumn width="20%">Contractor</TableHeaderColumn>
          <TableHeaderColumn width="20%">Agency</TableHeaderColumn>
          <TableHeaderColumn width="20%">Leads</TableHeaderColumn>
          <TableHeaderColumn width="60%">Topic</TableHeaderColumn>
        </TableHeader>
        <TableBody fullPageTable={false} footerHeight={0}>
          {data?.map((t) => (
            <TableRow onClick={() => (window.location = `/edit-comms-topic/${t.id}`)}>
              <TableCell width="20%">{t?.isContractor && <GreenTick />}</TableCell>
              <TableCell width="20%">{t?.isAgency && <GreenTick />}</TableCell>
              <TableCell width="20%">{t?.isLead && <GreenTick />}</TableCell>
              <TableCell width="60%">{t?.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Panel>
      {/* <TopicForm /> */}
    </Content>
  );
}
