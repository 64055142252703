import {
  PageLoading,
  Panel,
  PanelHeading,
  PanelSpacer,
  PanelRow,
  Label,
  BoldText,
  Link,
  PanelDialog,
  StatusIndicator,
} from "@backslashbuild/pp-ui";
import React from "react";
import { useGet } from "./api";
import { isTextNullOrEmpty, utcToLocalText } from "./utils";
import AgencyDetailsForm from "./AgencyDetailsForm";
import { subscribe, unsubscribe } from "./pubSub";
import { useAddViewingToHistory } from "../history/api";

export default function AgencyDetailsPanel({ id }) {
  const [editAgencyDetails, setEditAgencyDetails] = React.useState(null);
  const { data, loading, error, refetch } = useGet(id);
  const addViewingToHistory = useAddViewingToHistory({ itemType: "agency" });

  React.useEffect(() => {
    if (data != null && data.isSuccess) {
      recordViewing();
    }
  }, [id, data?.isSuccess]);

  React.useEffect(() => {
    subscribe("agency.agencyDetails.updated", refetch);
    return () => unsubscribe("agency.agencyDetails.updated", refetch);
  }, [id]);

  if (loading) return <PageLoading />;

  function recordViewing() {
    const fullName = `${data?.value?.name}`;
    const url = `agency/${id}`;

    const body = {
      itemId: id,
      type: "[agency]",
      name: fullName,
      url: url,
    };
    addViewingToHistory(body);
  }

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    return (
      <>
        <PanelRow>
          <Label label="Agency Name">
            <BoldText>{data?.value?.name}</BoldText>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Address">
            <BoldText>{data?.value?.address?.line1}</BoldText>
            <BoldText>{data?.value?.address?.line2}</BoldText>
            <BoldText>{data?.value?.address?.line3}</BoldText>
            <BoldText>{data?.value?.address?.town}</BoldText>
            <BoldText>{data?.value?.address?.postCode}</BoldText>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Telephone Number">
            <BoldText>{data?.value?.phoneNumber}</BoldText>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Company Number">
            <BoldText>{data?.value?.companyRegistrationNumber}</BoldText>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="VAT Number">
            <BoldText>{data?.value?.vatRegistrationNumber}</BoldText>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Email">
            <BoldText>{data?.value?.email}</BoldText>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Website URL">
            <BoldText>{data?.value?.websiteURL}</BoldText>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Incorporation Date">
            <BoldText>{utcToLocalText(data?.value?.incorporationDate)}</BoldText>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Origin of Lead">
            <BoldText>{data?.value?.originOfLead}</BoldText>
          </Label>
        </PanelRow>
      </>
    );
  }

  return (
    <>
      <Panel>
        <PanelHeading rightAction={<Link onClick={() => setEditAgencyDetails(id)}>Edit</Link>}>
          Agency details
        </PanelHeading>
        <PanelSpacer />
        {handleRender()}
        <PanelSpacer />
      </Panel>
      <PanelDialog open={editAgencyDetails}>
        <PanelHeading>Edit agency details</PanelHeading>
        {editAgencyDetails && (
          <AgencyDetailsForm id={editAgencyDetails} onClose={() => setEditAgencyDetails(null)} />
        )}
      </PanelDialog>
    </>
  );
}
