import React from "react";
import { QueryDropdown } from "@backslashbuild/pp-ui";
import { useSearchContractors, useGetContractorProduct } from "./api";

import queryString from "query-string";
import useDebounce from "../../utils";

function ContractorProductPicker({ value, onChange, agencyId, disabled = false }) {
  const [search, setSearch] = React.useState("");
  const debouncedSearch = useDebounce(search, 500);

  const currentItemQuery = useGetContractorProduct(value);

  const optionsQuery = useSearchContractors(
    `?${queryString.stringify({ search: debouncedSearch, agencyId })}`
  );

  const contractors = optionsQuery.data?.value?.items ?? [];

  const options = contractors?.reduce((agg, contractor) => {
    return agg.concat(
      contractor.contractorProducts.map((contractorProduct) => ({
        id: contractorProduct.id,
        contractorProduct,
        contractor,
      }))
    );
  }, []);

  return (
    <QueryDropdown
      width={"180px"}
      disabled={disabled}
      placeholder="Choose"
      search={search}
      loading={optionsQuery.loading}
      error={optionsQuery.error || optionsQuery.data?.errors?.map((e) => <div>{e}</div>)}
      fallback={`No matching contractor products found.`}
      onSearchChange={setSearch}
      value={value}
      onChange={onChange}
      options={options.map((o) => o.id)}
      renderOption={(v) => {
        if (v == value) {
          const msg = formatContractorProductViewModel(currentItemQuery?.data?.value);
          if (msg) return msg;
        }

        var optionsMatch = options?.find((o) => o.id == v);

        if (optionsMatch) {
          return formatContractorSearchViewModel(optionsMatch);
        }

        return "loading...";
      }}
    />
  );
}

function formatContractorProductViewModel(v) {
  if (!v) return null;
  return `${v.accountNumber} ${v.contractor?.firstName} ${v.contractor?.surname} (${v?.productName})`;
}

function formatContractorSearchViewModel(v) {
  return `${v.contractorProduct?.accountNumber} ${v.contractor?.firstName} ${v.contractor?.surname} (${v.contractorProduct?.productName})`;
}

export default ContractorProductPicker;
