import React from "react";
import {
  Table,
  TableHeader,
  TableBody,
  TableHeaderColumn,
  TableRow,
  TableCell,
  TextInput,
} from "@backslashbuild/pp-ui";

const c = {
  item: 300,
  collective: 300,
  paye: 300,
  total: 300,
};

function PeopleUmbrellaTable() {
  const [state, setState] = React.useState({
    grossEarningsCollective: 0,
    grossEarningsPAYE: 0,

    employersNICollective: 0,
    employersNIPAYE: 0,

    pension: 0,

    payeFee: 0,

    vatExemptGross: 0,

    vatableGross: 0,

    vatCollective: 0,
    vatPaye: 0,

    appLevyCollective: 0,
    appLevyPaye: 0,

    appLevyVatCollective: 0,
    appLevyVatPaye: 0,
  });

  const grossEarningsTotal =
    Number(state.grossEarningsCollective) + Number(state.grossEarningsPAYE);
  const employersNiTotal = Number(state.employersNICollective) + Number(state.employersNIPAYE);

  const vatTotal = Number(state.vatCollective) + Number(state.vatPaye);
  const appLevyTotal = Number(state.appLevyCollective) + Number(state.appLevyPaye);
  const appLevyVatTotal = Number(state.appLevyVatCollective) + Number(state.appLevyVatPaye);

  const invoiceTotal =
    grossEarningsTotal +
    employersNiTotal +
    Number(state.pension) +
    Number(state.payeFee) +
    Number(state.vatExemptGross) +
    Number(state.vatableGross) +
    vatTotal +
    appLevyTotal +
    appLevyVatTotal;

  function getStateUpdateHandler(key) {
    return (event) => setState({ ...state, [key]: Number(Number(event.target.value).toFixed(2)) });
  }

  return (
    <Table fullPageTable={true}>
      <TableHeader>
        <TableHeaderColumn width={c.item}>Item</TableHeaderColumn>
        <TableHeaderColumn width={c.collective}>Collective</TableHeaderColumn>
        <TableHeaderColumn width={c.paye}>PAYE</TableHeaderColumn>
        <TableHeaderColumn width={c.total}>Total</TableHeaderColumn>
      </TableHeader>
      <TableBody fullPageTable={true}>
        <TableRow disableHover>
          <TableCell width={c.item}>Gross Earnings</TableCell>
          <TableCell width={c.collective}>
            <TextInput
              type="number"
              value={state.grossEarningsCollective}
              onChange={getStateUpdateHandler("grossEarningsCollective")}
            />
          </TableCell>
          <TableCell width={c.paye}>
            <TextInput
              type="number"
              value={state.grossEarningsPAYE}
              onChange={getStateUpdateHandler("grossEarningsPAYE")}
            />
          </TableCell>
          <TableCell width={c.total}>
            <TextInput type="number" disabled value={grossEarningsTotal} />
          </TableCell>
        </TableRow>
        <TableRow disableHover>
          <TableCell width={c.item}>Employers NI</TableCell>
          <TableCell width={c.collective}>
            <TextInput
              type="number"
              value={state.employersNICollective}
              onChange={getStateUpdateHandler("employersNICollective")}
            />
          </TableCell>
          <TableCell width={c.paye}>
            <TextInput
              type="number"
              value={state.employersNIPAYE}
              onChange={getStateUpdateHandler("employersNIPAYE")}
            />
          </TableCell>
          <TableCell width={c.total}>
            <TextInput type="number" disabled value={employersNiTotal} />
          </TableCell>
        </TableRow>
        <TableRow disableHover>
          <TableCell width={c.item}>Pension</TableCell>
          <TableCell width={c.collective}></TableCell>
          <TableCell width={c.paye}></TableCell>
          <TableCell width={c.total}>
            <TextInput
              type="number"
              value={state.pension}
              onChange={getStateUpdateHandler("pension")}
            />
          </TableCell>
        </TableRow>
        <TableRow disableHover>
          <TableCell width={c.item}>PAYE Fee</TableCell>
          <TableCell width={c.collective}></TableCell>
          <TableCell width={c.paye}>
            <TextInput
              type="number"
              value={state.payeFee}
              onChange={getStateUpdateHandler("payeFee")}
            />
          </TableCell>
          <TableCell width={c.total}>
            <TextInput
              type="number"
              disabled
              value={state.payeFee}
              onChange={getStateUpdateHandler("payeFee")}
            />
          </TableCell>
        </TableRow>
        <TableRow disableHover>
          <TableCell width={c.item}>VAT Exempt Gross</TableCell>
          <TableCell width={c.collective}></TableCell>
          <TableCell width={c.paye}></TableCell>
          <TableCell width={c.total}>
            <TextInput
              type="number"
              value={state.vatExemptGross}
              onChange={getStateUpdateHandler("vatExemptGross")}
            />
          </TableCell>
        </TableRow>
        <TableRow disableHover>
          <TableCell width={c.item}>VATable Gross</TableCell>
          <TableCell width={c.collective}></TableCell>
          <TableCell width={c.paye}></TableCell>
          <TableCell width={c.total}>
            <TextInput
              type="number"
              value={state.vatableGross}
              onChange={getStateUpdateHandler("vatableGross")}
            />
          </TableCell>
        </TableRow>
        <TableRow disableHover>
          <TableCell width={c.item}>VAT</TableCell>
          <TableCell width={c.collective}>
            <TextInput
              type="number"
              value={state.vatCollective}
              onChange={getStateUpdateHandler("vatCollective")}
            />
          </TableCell>
          <TableCell width={c.paye}>
            <TextInput
              type="number"
              value={state.vatPaye}
              onChange={getStateUpdateHandler("vatPaye")}
            />
          </TableCell>
          <TableCell width={c.total}>
            <TextInput type="number" value={vatTotal} />
          </TableCell>
        </TableRow>
        <TableRow disableHover>
          <TableCell width={c.item}>App Levy</TableCell>
          <TableCell width={c.collective}>
            <TextInput
              type="number"
              value={state.appLevyCollective}
              onChange={getStateUpdateHandler("appLevyCollective")}
            />
          </TableCell>
          <TableCell width={c.paye}>
            <TextInput
              type="number"
              value={state.appLevyPaye}
              onChange={getStateUpdateHandler("appLevyPaye")}
            />
          </TableCell>
          <TableCell width={c.total}>
            <TextInput type="number" value={appLevyTotal} />
          </TableCell>
        </TableRow>
        <TableRow disableHover>
          <TableCell width={c.item}>App Levy VAT</TableCell>
          <TableCell width={c.collective}>
            <TextInput
              type="number"
              value={state.appLevyVatCollective}
              onChange={getStateUpdateHandler("appLevyVatCollective")}
            />
          </TableCell>
          <TableCell width={c.paye}>
            <TextInput
              type="number"
              value={state.appLevyVatPaye}
              onChange={getStateUpdateHandler("appLevyVatPaye")}
            />
          </TableCell>
          <TableCell width={c.total}>
            <TextInput type="number" value={appLevyTotal} />
          </TableCell>
        </TableRow>
        <TableRow disableHover>
          <TableCell width={c.item}>Invoice Total</TableCell>
          <TableCell width={c.collective}></TableCell>
          <TableCell width={c.paye}></TableCell>
          <TableCell width={c.total}>
            <TextInput type="number" disabled value={invoiceTotal} />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

export default PeopleUmbrellaTable;
