import React from "react";
import { publish } from "./pubSub";
import { useRecalculateBACSRows } from "./api";
import { PanelDialog, PanelActions, Button, PanelRow, PanelHeading } from "@backslashbuild/pp-ui";

export default function RecalculateDialog({ open, onClose, bacsRunId }) {
  const recalculate = useRecalculateBACSRows();

  async function handleConfirm() {
    const addRes = await recalculate({ bacsRunId: bacsRunId });

    if (addRes?.isSuccess) {
      publish("notification", "Success! BACS Rows have been added to BACS Run.");
      onClose();
    } else {
      publish(
        "notification",
        <div>
          Attempt Failed
          <ul>
            {addRes?.errors?.map((error) => (
              <li>{error}</li>
            ))}
          </ul>
        </div>
      );
    }
  }

  return (
    <PanelDialog open={open} onClose={onClose}>
      <PanelHeading>Recalculate BACS Run</PanelHeading>
      <PanelRow>
        <p>
          Click on 'Confirm' to recalculate the row data of this BACS Run. Any existing rows will be
          removed then calculated again from payslips. Perform this operation if your BACS Run was
          blocked by missing employments, but only if the employment data is now present.
        </p>
      </PanelRow>
      <PanelActions>
        <Button variant="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleConfirm();
            onClose();
          }}
        >
          Confirm
        </Button>
      </PanelActions>
    </PanelDialog>
  );
}
