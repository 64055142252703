import {
  FormTitle,
  PageLoading,
  Panel,
  PanelHeading,
  PanelSpacer,
  PanelSubheading,
  HorizontalRule,
} from "@backslashbuild/pp-ui";
import React from "react";
import { useGetFields, useUpdateField } from "./api";
import FormField from "./FormField";
import { isTextNullOrEmpty } from "./utils";

export default function FeesForm({ id }) {
  const [query, setQuery] = React.useState("");
  const { data, loading, error } = useGetFields({ id, formName: "fees", queryParams: query });
  const updateField = useUpdateField({ id, formName: "" });

  if (loading) return <PageLoading />;

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    return data.value.map((field) => (
      <>
        {field?.displayName == "OVERRIDE FEES" ? (
          <>
            <PanelSubheading>
              OVERRIDE FEES
              <FormField
                key={field.key}
                field={field}
                onSave={getOnSaveHandler(field.key)}
                hideLabel
              />
            </PanelSubheading>
          </>
        ) : (
          <>
            {field?.displayName == "Rates to use" && <HorizontalRule />}
            <FormField key={field.key} field={field} onSave={getOnSaveHandler(field.key)} />
          </>
        )}
      </>
    ));
  }

  function getOnSaveHandler(key) {
    return (value) => {
      updateField({ key, value });
    };
  }

  return (
    <Panel>
      <PanelHeading>Umbrella Fees</PanelHeading>
      <PanelSpacer />
      {handleRender()}
      <PanelSpacer />
    </Panel>
  );
}
