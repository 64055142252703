import React from "react";
import { useParams, useHistory } from "react-router-dom";

import {
  Button,
  Content,
  PageHeadingArea,
  PageTitle,
  PanelColumnSet,
  PanelColumn,
  PanelColumnSpacer,
  PageHeadingAreaSpacer,
  PanelSpacer,
  BackArrow,
  Panel,
  PanelHeading,
  PanelActions,
} from "@backslashbuild/pp-ui";

import ManualRowEditor from "../modules/schedules-core/ManualRowEditor";
import ScheduleTimesheetsTable from "../modules/schedules-core/ScheduleTimesheetsTable";
import ScheduleTitle from "../modules/schedules-core/ScheduleTitle";
import CreateRFPsForSchedule from "../modules/schedules-core/CreateRFPsForSchedule";
import UploadCsvDialog from "../modules/schedules-core/UploadCSVDialog";
import CSVImportsList from "../modules/schedules-core/CSVImportsList";
import ProcessScheduleButton from "../modules/schedules-core/ProcessScheduleButton";
import ResyncMatchesButton from "../modules/schedules-core/ResyncMatchesButton";
import ScheduleTotals from "../modules/schedules-core/ScheduleTotals";
import CreateInvoicesButton from "../modules/schedules-core/CreateScheduleInvoices";
import CancelScheduleButton from "../modules/schedules-core/CancelScheduleButton";
import ScheduleDetails from "../modules/schedules-core/ScheduleDetails";

export default function Schedule() {
  const { id } = useParams();
  const history = useHistory();

  return (
    <Content scrollable>
      <PageHeadingArea>
        <BackArrow onClick={() => history.goBack()} />
        <PageHeadingAreaSpacer />
        <PageTitle>
          <ScheduleTitle id={id} />
        </PageTitle>
        <PageHeadingAreaSpacer grow />
        <CreateInvoicesButton id={id} />
        <PageHeadingAreaSpacer />
        <ProcessScheduleButton id={id} />
        <PageHeadingAreaSpacer />
        <ResyncMatchesButton id={id} />
        <PageHeadingAreaSpacer />
        <CreateRFPsForSchedule id={id} />
        <PageHeadingAreaSpacer />
        <CancelScheduleButton id={id} />
      </PageHeadingArea>
      {/* <PanelColumnSet> */}

      <div>
        <PanelColumnSet style={{ flexGrow: "initial" }}>
          <PanelColumn>
            <ScheduleDetails id={id} />

            <PanelSpacer large />
            <Panel>
              <PanelHeading>Imports</PanelHeading>
              {/* list the csv imports here */}
              <CSVImportsList id={id} />
              <PanelActions>
                <UploadCsvDialog id={id} />
                <ManualRowEditor id={id} />
              </PanelActions>
            </Panel>
          </PanelColumn>
          <PanelColumnSpacer />
          <PanelColumn width="calc(100% - 474px)">
            <Panel maxWidth="100%">
              <PanelHeading>Totals</PanelHeading>
              <ScheduleTotals id={id} />
              {/* <Table>
                <TableHeader>
                  <TableHeaderColumn width={100}>Product</TableHeaderColumn>
                  <TableHeaderColumn width={50}>Emp NIC</TableHeaderColumn>
                  <TableHeaderColumn width={50}>Emp Pens</TableHeaderColumn>
                  <TableHeaderColumn width={50}>App Levy</TableHeaderColumn>
                  <TableHeaderColumn width={50}>Fee</TableHeaderColumn>
                  <TableHeaderColumn width={50}>Uplift</TableHeaderColumn>
                  <TableHeaderColumn width={50}>Net</TableHeaderColumn>
                  <TableHeaderColumn width={50}>VAT Exempt</TableHeaderColumn>
                  <TableHeaderColumn width={50}>VATable</TableHeaderColumn>
                  <TableHeaderColumn width={50}>VAT</TableHeaderColumn>
                  <TableHeaderColumn width={50}>Invoice Total</TableHeaderColumn>
                  <TableHeaderColumn width={100}>Breakdown</TableHeaderColumn>

                </TableHeader>
                <TableBody>
                  <TableRow>
                    <TableCell width={100}>PAYE Bureau</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={100}>
                      <Button>Breakdown</Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={100}>PAYE Employed</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={100}>
                      <Button>Breakdown</Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={100}>Umbrella</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={100}>
                      <Button>Breakdown</Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={100}>PEO</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={50}>-</TableCell>
                    <TableCell width={100}>
                      <Button>Breakdown</Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table> */}
            </Panel>
          </PanelColumn>
        </PanelColumnSet>
      </div>
      <PanelSpacer large />
      <ScheduleTimesheetsTable id={id} />
      {/* </PanelColumnSet> */}
    </Content>
  );
}
