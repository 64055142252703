import React, { useState, useEffect } from "react";
import { Panel, PageLoading, PanelActions, PanelHeading, Button } from "@backslashbuild/pp-ui";
import FormField from "./FormField";
import { usePost, useGetNewToggleModePeriodFields } from "./api";
import { isTextNullOrEmpty } from "./utils";

export default function NewAnytimeWageToggleModePeriodForm({ onClose }) {
  const [payload, storePayload] = useState({});
  const { data, loading, error } = useGetNewToggleModePeriodFields();
  const createAnytimeWageToggleModePeriod = usePost("anytimeWageToggleModePeriods/create");

  useEffect(() => {
    const setInitialValues = () => {
      if (data && data.value) {
        let initialPayload = {};
        data.value.forEach((field) => {
          if (field.schema.type === "dropdown") {
            initialPayload = { ...initialPayload, [field.key]: field.value };
          } else if (field.schema.type === "multiSelectDropdown") {
            initialPayload = { ...initialPayload, [field.key]: JSON.parse(field.value) };
          }
        });
        storePayload(initialPayload);
      }
    };
    setInitialValues();
  }, [data]);

  if (loading) return <PageLoading />;

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    return data.value.map((field) => (
      <FormField key={field.key} field={field} onSave={getOnSaveHandler(field.key)} />
    ));
  }

  function getOnSaveHandler(key) {
    return (value) => {
      storePayload({ ...payload, [key]: value });
    };
  }

  return (
    <Panel>
      <PanelHeading>Create new toggle mode period</PanelHeading>
      {handleRender()}
      <PanelActions>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            createAnytimeWageToggleModePeriod({
              ...payload,
            }).then((res) => {
              if (!res?.isSuccess) {
                alert(res?.errors);
              } else {
                onClose();
              }
            });
          }}
        >
          Save
        </Button>
      </PanelActions>
    </Panel>
  );
}
