import React from "react";
import ApproveDraftButton from "./ApproveDraftButton";
import ApproveDraftDialog from "./ApproveDraftDialog";

import { PageHeadingAreaSpacer } from "@backslashbuild/pp-ui";

export default function ApproveDraft({ draft, refetch }) {
  const [showDialog, setShowDialog] = React.useState(false);

  return (
    <>
      {draft && !draft?.isClosed && draft?.errors?.length === 0 && (
        <>
          <ApproveDraftButton onClick={() => setShowDialog(true)} />
          <PageHeadingAreaSpacer />
          <ApproveDraftDialog
            open={showDialog}
            draftId={draft?.id}
            onClose={() => {
              setShowDialog(false);
              setTimeout(() => {
                refetch();
              }, 1000);
            }}
          />
        </>
      )}
    </>
  );
}
