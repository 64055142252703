import React from "react";
import { QueryDropdown } from "@backslashbuild/pp-ui";
import { useGet } from "./api";

function EmploymentPicker({ value, onChange }) {
  const [search, setSearch] = React.useState("");
  const optionsQuery = useGet(search ? `employments?pattern=${search}` : "employments"); // TODO urlencode
  const currentItemQuery = useGet(`employments/${value}`);

  const options = optionsQuery.data?.value?.items ?? [];

  return (
    <QueryDropdown
      placeholder="Choose"
      search={search}
      onSearchChange={setSearch}
      value={value}
      onChange={onChange}
      options={options.map((o) => o.id)}
      renderOption={(v) => {
        if (v == value) {
          const currenItemMatch = currentItemQuery?.data?.value;
          if (currenItemMatch) {
            return `${currenItemMatch.employeeNumber} ${currenItemMatch?.latestSettingsSnapshot?.employmentSettings?.firstName} ${currenItemMatch?.latestSettingsSnapshot?.employmentSettings?.surname}`;
          }
        }

        var optionsMatch = options.find((o) => o.id == v);

        if (optionsMatch) {
          return `${optionsMatch.employeeNumber} ${optionsMatch?.latestSettingsSnapshot?.employmentSettings?.firstName} ${optionsMatch?.latestSettingsSnapshot?.employmentSettings?.surname}`;
        }

        return "loading...";
      }}
    />
  );
}

export default EmploymentPicker;
