import React from "react";
import { Radio, RadioGroup } from "@backslashbuild/pp-ui";

const getBackgroundColor = (product) => {
  if (product === "People Umbrella") {
    return "#49C2BA";
  } else if (product === "PAYE") {
    return "#5AC775";
  } else if (product === "PEO") {
    return "#5CCBD9";
  } else if (product === "People Plus") {
    return "#5CCBD9";
  } else if (product === "PS Pro") {
    return "#5A5D9F";
  } else if (product === "Construction Industry Service" || product === "CIS") {
    return "#89649F";
  } else if (product === "PSC") {
    return "#C00";
  } else return "";
};

export default function FormField({ field, onSave, onClick, onQuery, hintHandlers }) {
  let SubComponent = RadioField;

  let customProps = {};
  if (field.schema.hints) {
    field.schema.hints.forEach((hint) => {
      if (hintHandlers && hintHandlers[hint]) {
        Object.assign(customProps, hintHandlers[hint]());
      }
    });
  }

  return (
    <SubComponent
      field={field}
      onSave={onSave}
      onClick={() => onClick && onClick()}
      onQuery={onQuery}
      {...customProps}
    />
  );
}

function RadioField({ field, onSave }) {
  const [value, setValue] = React.useState(field.value);
  const [isDisabled, setIsDisabled] = React.useState(field.isDisabled);

  React.useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  React.useEffect(() => {
    setIsDisabled(field.isDisabled);
  }, [field.isDisabled]);

  return (
    <RadioGroup
      value={value}
      disabled={isDisabled}
      onChange={(e) => {
        onSave(e.target.value);
        setValue(e.target.value);
      }}
    >
      {field.schema.options
        .filter(
          (x) => x.displayName != "PSC" && x.displayName != "CIS" && x.displayName != "PS Pro"
        )
        .map((option) => (
          <div
            style={{
              width: "100%",
              backgroundColor: getBackgroundColor(option.displayName),
              color: getBackgroundColor(option.displayName) == "" ? "#000000" : "#FFFFFF",
            }}
          >
            <Radio value={option.value} label={option.displayName} disabled={option?.disabled} />
          </div>
        ))}
    </RadioGroup>
  );
}
