import React from "react";
import { useGetComms, useCreateComm, useGetTopics } from "./api";
import { useGet as useGetAudit } from "../../audit/api";
import {
  Dropdown,
  DropdownOption,
  PanelRow,
  Label,
  TextInput,
  Button,
  PanelHeading,
  PanelSpacer,
  SidePanel,
  TextInputMultiline,
} from "@backslashbuild/pp-ui";
import CommsItem from "./CommsItem";
import EventPanel from "../../audit/EventPanel";

const actions = {
  isAgency: [
    { value: "25", label: "Account reconciliation" },
    { value: "34", label: "Call or email from Agency Owner or Director" },
    { value: "8", label: "Delivered" },
    { value: "19", label: "Email from Consultant" },
    { value: "3", label: "Email from contractor" },
    { value: "4", label: "Email Sent" },
    { value: "12", label: "Follow Up" },
    { value: "27", label: "Meeting" },
    { value: "11", label: "Override access" },
    { value: "18", label: "Phone call from Consultant" },
    { value: "2", label: "Phone call from contractor" },
    { value: "1", label: "Phone Call Made" },
    { value: "7", label: "Posted" },
    { value: "9", label: "Promotional Goods Delivered" },
    { value: "30", label: "Request" },
    { value: "5", label: "Visit" },
    { value: "6", label: "Visited Us" },
    { value: "10", label: "Web Chat" },
    { value: "24", label: "Marketing" },
  ],
  isContractor: [
    { value: "8", label: "Delivered" },
    { value: "19", label: "Email from Consultant" },
    { value: "3", label: "Email from contractor" },
    { value: "4", label: "Email Sent" },
    { value: "12", label: "Follow Up" },
    { value: "11", label: "Override access" },
    { value: "18", label: "Phone call from Consultant" },
    { value: "2", label: "Phone call from contractor" },
    { value: "1", label: "Phone Call Made" },
    { value: "7", label: "Posted" },
    { value: "9", label: "Promotional Goods Delivered" },
    { value: "30", label: "Request" },
    { value: "31", label: "SMS Received" },
    { value: "32", label: "SMS Sent" },
    { value: "5", label: "Visit" },
    { value: "6", label: "Visited Us" },
    { value: "10", label: "Web Chat" },
  ],
  isLead: [
    { value: "3", label: "Email from contractor" },
    { value: "4", label: "Email Sent" },
    { value: "12", label: "Follow Up" },
    { value: "2", label: "Phone call from contractor" },
    { value: "1", label: "Phone Call Made" },
    { value: "10", label: "Web Chat" },
  ],
  default: [
    { value: "25", label: "Account reconciliation" },
    { value: "34", label: "Call or email from Agency Owner or Director" },
    { value: "8", label: "Delivered" },
    { value: "19", label: "Email from Consultant" },
    { value: "3", label: "Email from contractor" },
    { value: "4", label: "Email Sent" },
    { value: "12", label: "Follow Up" },
    { value: "24", label: "Marketing" },
    { value: "27", label: "Meeting" },
    { value: "11", label: "Override access" },
    { value: "18", label: "Phone call from Consultant" },
    { value: "2", label: "Phone call from contractor" },
    { value: "1", label: "Phone Call Made" },
    { value: "7", label: "Posted" },
    { value: "9", label: "Promotional Goods Delivered" },
    { value: "30", label: "Request" },
    { value: "31", label: "SMS Received" },
    { value: "32", label: "SMS Sent" },
    { value: "5", label: "Visit" },
    { value: "6", label: "Visited Us" },
    { value: "10", label: "Web Chat" },
  ],
};

const departments = [
  "Agency Care Department",
  "Information Only",
  "All Sales",
  "AsOne Developers",
  "Brians World",
  "Client Care",
  "Client Services",
  "Credit Control",
  "DSC - Finance",
  "Expenses",
  "Finance",
  "Human Resources",
  "Internal Sales",
  "IT",
  "Legal",
  "Management",
  "Marketing",
  "New Contractor Registrations",
  "Payroll",
  "PSC",
  "RACS Advances",
  "Registrations",
];

const statuses = [
  "Completed and closed",
  "Escalated",
  "In Progress",
  "Not Commenced",
  "Requires action",
];

function CommsForm({ itemId, configuration }) {
  const { data, loading, error } = useGetTopics(configuration);
  const [submitting, setSubmitting] = React.useState(false);
  const [newComm, setNewComm] = React.useState({
    action: "",
    topic: "",
    description: "",
    department: "",
    status: "",
    subject: "",
    itemId,
  });

  const post = useCreateComm();

  async function handleSubmit() {
    setSubmitting(true);
    const result = await post({ ...newComm, itemId }).then((res) => {
      if (!res?.isSuccess && res?.errors?.length > 0) {
        alert(res?.errors[0]);
      } else {
        setNewComm({
          action: "",
          topic: "",
          description: "",
          department: "",
          status: "",
          subject: "",
          itemId,
        });
      }
    });
    setSubmitting(false);
  }

  const topicOptions =
    data?.length > 0
      ? data.sort((a, b) => {
          if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
          return -1;
        })
      : [];

  return (
    <>
      <PanelHeading>Add Communication</PanelHeading>
      <PanelSpacer />
      <PanelRow>
        <Label label="Action">
          <Dropdown
            value={newComm.action}
            onChange={(e) => setNewComm({ ...newComm, action: e.target.value })}
          >
            <DropdownOption value="">-- Choose --</DropdownOption>
            {actions[`${configuration ?? "default"}`].map((o, index) => (
              <DropdownOption key={index} value={o.label}>
                {o.label}
              </DropdownOption>
            ))}
          </Dropdown>
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Topic">
          <Dropdown
            value={newComm.topic}
            onChange={(e) => setNewComm({ ...newComm, topic: e.target.value })}
          >
            <DropdownOption value="">-- Choose --</DropdownOption>
            {topicOptions.map((o, index) => (
              <DropdownOption value={o.id} key={index}>
                {o.agencyProgress > 0 && `${o.agencyProgress} - `}
                {o.name}
              </DropdownOption>
            ))}
          </Dropdown>
        </Label>
      </PanelRow>
      {configuration && configuration == "agency" && (
        <PanelRow>
          <Label label="Subject">
            <TextInput
              value={newComm.subject}
              onChange={(e) => setNewComm({ ...newComm, subject: e.target.value })}
            />
          </Label>
        </PanelRow>
      )}
      <PanelRow>
        <Label label="Description">
          <TextInputMultiline
            value={newComm.description}
            onChange={(e) => {
              setNewComm({ ...newComm, description: e.target.value });
            }}
            rows={10}
          />
        </Label>
      </PanelRow>
      {configuration && configuration == "isContractor" && (
        <PanelRow>
          <Label label="Department">
            <Dropdown
              value={newComm.department}
              onChange={(e) => setNewComm({ ...newComm, department: e.target.value })}
            >
              <DropdownOption value="">-- Choose --</DropdownOption>
              {departments.map((o, index) => (
                <DropdownOption key={index} value={o}>
                  {o}
                </DropdownOption>
              ))}
            </Dropdown>
          </Label>
        </PanelRow>
      )}
      {configuration && configuration == "isContractor" && (
        <PanelRow>
          <Label label="Status">
            <Dropdown
              value={newComm.status}
              onChange={(e) => setNewComm({ ...newComm, status: e.target.value })}
            >
              <DropdownOption value="">-- Choose --</DropdownOption>
              {statuses.map((o, index) => (
                <DropdownOption key={index} value={o}>
                  {o}
                </DropdownOption>
              ))}
            </Dropdown>
          </Label>
        </PanelRow>
      )}
      <PanelSpacer large />
      <PanelRow>
        <Button onClick={handleSubmit}>Add Communication</Button>
        {submitting && "Saving..."}
      </PanelRow>
      <PanelSpacer />
    </>
  );
}

function getAuditUrlPath({ id, type }) {
  let service = "contractors";
  let idParam = "contractorId";

  if (type === "isAgency") {
    service = "agencies";
    idParam = "agencyId";
  }

  if (type === "isProduct") {
    service = "products";
    idParam = "productId";
  }

  return `${service}?${idParam}=${id}`;
}

function CommsPanelApp({ itemId, open, configuration }) {
  const { data: commsData, loading: commsLoading, error: commsError } = useGetComms({ itemId });
  const auditPath = getAuditUrlPath({ id: itemId, type: configuration });
  const { data: auditData, loading: auditLoading, error: auditError } = useGetAudit(auditPath);

  if (commsLoading || auditLoading) {
    return <SidePanel open={open}>Loading the internal comms.</SidePanel>;
  }

  if (commsError || auditError) {
    return <SidePanel open={open}>Error while loading the comms.</SidePanel>;
  }

  if (!commsData || !auditData) {
    return (
      <SidePanel open={open}>
        <CommsForm itemId={itemId} configuration={configuration} />
        <PanelRow>No previous comms.</PanelRow>
      </SidePanel>
    );
  }

  // Temporary fix due to Safari rendering ui lib components weirdly. I know.
  const navBarHeight = 54;
  const commsFormHeight = 475;
  const viewPortHeight = window.visualViewport.height;

  var commsAndAudits = commsData
    ?.map((comm) => {
      return { type: "comm", data: comm };
    })
    .concat(
      auditData?.value?.items?.map((audit) => {
        return { type: "audit", data: audit };
      })
    );

  return (
    <SidePanel open={open}>
      <div style={{ height: `${commsFormHeight}px` }}>
        <CommsForm itemId={itemId} configuration={configuration} />
      </div>
      {/* this addresses weird scrolling in Safari */}
      <div
        style={{
          height: `${viewPortHeight - commsFormHeight - navBarHeight}px`,
          overflowY: "scroll",
        }}
      >
        <div style={{ height: `${viewPortHeight - commsFormHeight - navBarHeight}px` }}>
          {commsAndAudits?.length == 0 && <>No communications to display</>}
          {commsAndAudits.map((item, index) => (
            <>
              {item?.type == "comm" && (
                <CommsItem
                  key={index}
                  name={item?.data?.author?.name}
                  dateCreated={item?.data?.dateCreated}
                  topic={item?.data?.topic}
                  subject={item?.data?.subject}
                  description={item?.data?.description}
                  action={item?.data?.action}
                />
              )}
              {item?.type == "audit" && <EventPanel item={item.data} />}
            </>
          ))}
        </div>
      </div>
    </SidePanel>
  );
}

export default CommsPanelApp;
