import React from "react";
import {
  Panel,
  PanelHeading,
  PanelSpacer,
  PanelRow,
  Label,
  PageLoading,
  HorizontalRule,
  typeOf,
  PanelSubheading,
  Button,
  PanelActions,
  PanelDialog,
} from "@backslashbuild/pp-ui";
import { useGet, usePost } from "./api";
import { subscribe, unsubscribe } from "../../pubSub";
import { utcToYmd } from "../../utils";
import { publish } from "../../pubSub";

function AddDueRFPToStagedPayslipButton({ rfp, setAddRFPReference }) {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  if (
    rfp?.isLocked == false &&
    rfp?.isCancelled == false &&
    rfp?.isFinalised == false &&
    rfp?.lockedOnPayslipId == null
  ) {
    return (
      <>
        <PanelActions>
          <Button onClick={() => setAddRFPReference(rfp?.reference)}>Add to payslip</Button>
        </PanelActions>
        <PanelSpacer />
      </>
    );
  }

  return null;
}

function PayslipRFPsPanel({ payslipId, employmentId }) {
  // todo query payslip api and get payslip data
  const { data, loading, errors, refetch } = useGet(`rfps?employmentId=${employmentId}`);
  const [addRFPReference, setAddRFPReference] = React.useState(null);

  const addRFPToPayslip = usePost("rfps/add-to-payslip");

  async function handleSubmit() {
    try {
      const response = await addRFPToPayslip({ reference: addRFPReference, payslipId });

      if (response.isSuccess) {
        publish("notification", "RFP Added to payslip");
      } else {
        publish(
          "notification",
          <>
            Unable to add RFP to payslip
            <ul>
              {response.errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </>
        );
      }
    } catch (e) {
      console.error(e, "error adding RFP to payslip");
      publish("notification", "A network error occured.");
    }
  }

  console.log("ddata", data);
  React.useEffect(() => {
    subscribe("payslip.updated", refetch);
    return () => unsubscribe("payslip.updated", refetch);
  }, [payslipId]);

  if (loading) {
    return <PageLoading />;
  }

  if (data?.errors) {
    return (
      <ul>{typeof data?.errors == Array && data?.errors?.map((error) => <li>{error}</li>)}</ul>
    );
  }

  return (
    <>
      <Panel>
        <PanelHeading>RFPs</PanelHeading>
        {data?.value?.items?.map((rfp, i) => (
          <div style={rfp?.lockedOnPayslipId == payslipId ? { backgroundColor: "#00800066" } : {}}>
            {rfp?.lockedOnPayslipId == payslipId && (
              <PanelSubheading>Locked on this payslip</PanelSubheading>
            )}
            <PanelRow>
              <Label label="Ref">{rfp?.reference}</Label>
            </PanelRow>
            <PanelRow>
              <Label label="Date created">{utcToYmd(rfp?.dateCreated)}</Label>
            </PanelRow>
            <PanelRow>
              <Label label="Description">{rfp?.description}</Label>
            </PanelRow>
            <PanelRow>
              <Label label="Requested amount">{rfp?.requestedAmount}</Label>
            </PanelRow>
            <PanelRow>
              <Label label="Finalised amount">{rfp?.finalisedAmount}</Label>
            </PanelRow>
            <AddDueRFPToStagedPayslipButton rfp={rfp} setAddRFPReference={setAddRFPReference} />
            {i < data?.value?.items?.length - 1 && <HorizontalRule />}
          </div>
        ))}
      </Panel>
      <PanelDialog open={addRFPReference} onClose={() => setAddRFPReference(null)}>
        <Panel>
          <PanelHeading>Add RFP to payslip</PanelHeading>
          <PanelRow>Are you sure? This will add the RFP to, and reprocess the payslip</PanelRow>
          <PanelActions>
            <Button variant="secondary" onClick={() => setAddRFPReference(null)}>
              No, cancel
            </Button>
            <Button onClick={handleSubmit}>Add to payslip</Button>
          </PanelActions>
        </Panel>
      </PanelDialog>
    </>
  );
}

export default PayslipRFPsPanel;
