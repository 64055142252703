import React from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Content,
  PageTitle,
  PanelColumn,
  PageHeadingArea,
  PanelColumnSet,
  PanelColumnSpacer,
  PageHeadingAreaSpacer,
  BackArrow,
} from "@backslashbuild/pp-ui";

import ProductForm from "../modules/products/ProductForm";

export default function Product() {
  const { id } = useParams();
  const history = useHistory();

  return (
    <Content>
      <PageHeadingArea>
        <BackArrow onClick={() => history.goBack()} />
        <PageHeadingAreaSpacer />
        <PageTitle>Product</PageTitle>
      </PageHeadingArea>
      <PanelColumnSet>
        <PanelColumn>
          <ProductForm id={id} />
        </PanelColumn>
      </PanelColumnSet>
    </Content>
  );
}
