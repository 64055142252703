import React, { useState, useEffect } from "react";
import { Panel, PanelActions, PanelHeading, Button } from "@backslashbuild/pp-ui";
import AgencyPicker from "../schedules-core/AgencyPicker";

export default function SelectAgencyForm({ contractorId, onSave, onClose }) {
  const [selectedAgency, setSelectedAgency] = useState(null);

  function handleRender() {
    return <AgencyPicker value={selectedAgency} onChange={setSelectedAgency} width={"430px"} />;
  }

  return (
    <Panel>
      <PanelHeading>Add new agency</PanelHeading>
      <div style={{ padding: 20 }}>{handleRender()}</div>
      <PanelActions>
        <Button variant="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            onSave(selectedAgency);
          }}
        >
          Next
        </Button>
      </PanelActions>
    </Panel>
  );
}
