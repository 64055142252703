import {
  PageLoading,
  Panel,
  PanelHeading,
  PanelSpacer,
  PanelRow,
  PanelDialog,
  Label,
  Link,
  BoldText,
} from "@backslashbuild/pp-ui";
import React from "react";
import { useGet } from "./api";
import { isTextNullOrEmpty, utcToLocalText } from "./utils";
import { subscribe, unsubscribe } from "./pubSub";
import { StatusIndicator } from "@backslashbuild/pp-ui/dist/index.es";
import ContractorVisaForm from "./ContractorVisaForm";

export default function ContractorVisaPanel({ id }) {
  const [editBankDetails, setEditBankDetails] = React.useState(null);
  const { data, loading, error, refetch } = useGet(id);

  React.useEffect(() => {
    subscribe("contractor.visaDetails.updated", refetch);
    return () => unsubscribe("contractor.visaDetails.updated", refetch);
  }, [id]);

  if (loading) return <PageLoading />;

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    if (
      data?.value?.isUkResidentSince6April2018 != null ||
      data?.value?.isUkVisitorSince6April2018 != null ||
      data?.value?.isForeignExpensesAllowed != null ||
      data?.value?.isInternationalContractor != null ||
      data?.value?.isVisaRequired != null ||
      data?.value?.isLetterOfInvitationRequired != null ||
      data?.value?.ukArrivalDate != null ||
      data?.value?.visaExpiryDate != null ||
      data?.value?.visaType != null
    ) {
      return (
        <>
          <PanelRow>
            <Label label="Resident in UK since 6th April 2018">
              <StatusIndicator value={data?.value?.isUkResidentSince6April2018} />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Visited the UK since 6th April 2018">
              <StatusIndicator value={data?.value?.isUkVisitorSince6April2018} />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Foreign expenses allowed">
              <StatusIndicator value={data?.value?.isForeignExpensesAllowed} />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Is international contractor">
              <StatusIndicator value={data?.value?.isInternationalContractor} />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Is VISA required">
              <StatusIndicator value={data?.value?.isVisaRequired} />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Is LOI required">
              <StatusIndicator value={data?.value?.isLetterOfInvitationRequired} />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="UK arrival date">
              {data?.value?.ukArrivalDate && (
                <BoldText>{utcToLocalText(data?.value?.ukArrivalDate)}</BoldText>
              )}
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="VISA expiry date">
              {data?.value?.visaExpiryDate && (
                <BoldText>{utcToLocalText(data?.value?.visaExpiryDate)}</BoldText>
              )}
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="VISA type">
              <BoldText>{data?.value?.visaType}</BoldText>
            </Label>
          </PanelRow>
        </>
      );
    }
    return null;
  }

  return (
    <>
      <Panel>
        <PanelHeading rightAction={<Link onClick={() => setEditBankDetails(id)}>Edit</Link>}>
          VISA Information
        </PanelHeading>
        <PanelSpacer />
        {handleRender()}
        <PanelSpacer />
      </Panel>
      <PanelDialog open={editBankDetails}>
        <PanelHeading>Edit Visa information</PanelHeading>
        {editBankDetails && (
          <ContractorVisaForm id={editBankDetails} onClose={() => setEditBankDetails(null)} />
        )}
      </PanelDialog>
    </>
  );
}
