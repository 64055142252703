import React from "react";
import { useHistory } from "react-router-dom";
import {
  Content,
  PanelColumnSpacer,
  PanelColumn,
  PageHeadingArea,
  PanelColumnSet,
  SidebarWrapper,
  BackArrow,
  PageHeadingAreaSpacer,
  PageTitle,
} from "@backslashbuild/pp-ui";

import NeedsEnrollingPanel from "../modules/nest/NeedsEnrollingPanel";
import EnrolmentExportsTable from "../modules/nest/EnrolmentExportsTable";

export default function NESTEnrolments() {
  const history = useHistory();

  return (
    <SidebarWrapper>
      <Content scrollable>
        <PageHeadingArea>
          <BackArrow onClick={() => history.goBack()} />
          <PageHeadingAreaSpacer />
          <PageTitle>NEST Enrollments</PageTitle>
          <PageHeadingAreaSpacer grow />
        </PageHeadingArea>
        <PanelColumnSet>
          <PanelColumn width={320}>
            <NeedsEnrollingPanel />
          </PanelColumn>
          <PanelColumnSpacer />
          <EnrolmentExportsTable />
        </PanelColumnSet>
      </Content>
    </SidebarWrapper>
  );
}
