import React from "react";
import {
  Button,
  PanelDialog,
  PanelHeading,
  PanelRow,
  PanelSpacer,
  PanelActions,
  BoldText,
} from "@backslashbuild/pp-ui";
import { useResyncMatches } from "./api";
import { publish } from "../../pubSub";

function ResyncMatchesButton({ id }) {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const process = useResyncMatches();

  async function handleSubmit() {
    try {
      const response = await process({ scheduleId: id });

      if (response.isSuccess) {
        publish("notification", "Matches synchronised");
      } else {
        publish(
          "notification",
          <>
            Unable to synchronise matches
            <ul>
              {response.errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </>
        );
      }
    } catch (e) {
      console.error(e, "error resynchronising matches");
      publish("notification", "A network error occured.");
    }
  }

  return (
    <>
      <Button large onClick={() => setDialogOpen(true)}>
        Resync Matches
      </Button>
      <PanelDialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <PanelHeading>Resync Matches</PanelHeading>
        <PanelSpacer large />
        <PanelRow>
          <BoldText>This will resynchronise matches.</BoldText>
        </PanelRow>
        <PanelSpacer large />
        <PanelActions>
          <Button variant="secondary" onClick={() => setDialogOpen(false)}>
            No, Cancel
          </Button>
          <Button onClick={handleSubmit}>Yes, Resync</Button>
        </PanelActions>
        <PanelSpacer large />
      </PanelDialog>
    </>
  );
}

export default ResyncMatchesButton;
