import React from "react";
import {
  Panel,
  PanelColumn,
  PanelRow,
  Label,
  TextInput,
  TextInputMultiline,
  PanelHeading,
  Table,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableRow,
  TableCell,
  PanelSpacer,
  PanelColumnSpacer,
} from "@backslashbuild/pp-ui";

import AgencyPicker from "../schedules-core/AgencyPicker";
import PeoTable from "./PeoTable";
import DscTable from "./DscTable";
import PeopleUmbrellaTable from "./PeopleUmbrellaTable";

// function UmbrellaFields() {
//   return <>
//     <PanelRow>
//     <PanelRow>
//         <Label label="Gross Earnings">
//           <TextInput type="number" />
//         </Label>
//       </PanelRow>
//     <PanelRow>
//         <Label label="Gross Earnings">
//           <TextInput type="number" />
//         </Label>
//       </PanelRow>
//     </PanelRow>
//   </>
// }

export default function AddInvoiceForm({ isCreditNote }) {
  return (
    <>
      <PanelColumn>
        <Panel>
          <PanelHeading>New fields</PanelHeading>
          <PanelSpacer />
          <PanelRow>
            <Label label="Week number">
              {/* TODO week number picker */}
              <TextInput />
            </Label>
          </PanelRow>

          <PanelRow>
            <Label label="Agency">
              {/* TODO agency picker */}
              <AgencyPicker />
              <TextInput />
            </Label>
          </PanelRow>

          <PanelRow>
            <Label label="Product">
              {/* TODO product picker */}
              <TextInput />
            </Label>
          </PanelRow>

          <PanelRow>
            <Label label="Notes">
              <TextInputMultiline />
            </Label>
          </PanelRow>
          <PanelSpacer />
        </Panel>
      </PanelColumn>
      <PanelColumnSpacer />
      <PeopleUmbrellaTable />
      <PeoTable />
      <DscTable />
    </>
  );
}
