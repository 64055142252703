import React from "react";
import QueryFiltering from "../../containers/QueryFiltering";
import { PanelRow, Label, TextInput, RadioGroup, Radio } from "@backslashbuild/pp-ui";

function UsersFiltering() {
  return (
    <QueryFiltering
      searchStringKey="search"
      getTagsFromState={(state) => {
        const tags = [];
        if (state.isContractor === "yes") tags.push({ key: "contractor", value: "Only" });
        if (state.isContractor === "no") tags.push({ key: "contractor", value: "Exclude" });
        return tags;
      }}
      getNextStateFromDeleteTag={(state, key) => {
        if (key === "contractor") return { ...state, isContractor: undefined };
        return state;
      }}
      renderForm={(state, setState) => (
        <>
          <PanelRow>
            <Label label="Search string">
              <TextInput
                value={state.search}
                onChange={(e) => setState({ ...state, search: e.target.value })}
              />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Is Contractor">
              <RadioGroup
                value={state.isContractor}
                onChange={(e) => setState({ ...state, isContractor: e.target.value })}
              >
                <Radio value="" label="Any" />
                <Radio value="yes" label="Yes" />
                <Radio value="no" label="No" />
              </RadioGroup>
            </Label>
          </PanelRow>
        </>
      )}
    />
  );
}

export default UsersFiltering;
