import React from "react";
import {
  Content,
  SidebarWrapper,
  Sidebar,
  PanelSpacer,
  PanelDialog,
  PageHeadingArea,
  PageHeadingAreaSpacer,
  Button,
  Icons,
  PageTitle,
  SidebarIconButton,
  SidePanel,
} from "@backslashbuild/pp-ui";

import InvoicesTable from "../modules/invoicing/InvoicesTable";
import InvoiceFiltering from "../modules/invoicing/InvoiceFiltering2";
import { FeatureFlag } from "react-unleash-flags";
import PendingExportsTable from "../modules/invoicing/PendingExportsTable";
import ExportBatchCsvButton from "../modules/invoicing/ExportBatchCsvButton";
import ExportInvoicesCsvButton from "../modules/invoicing/ExportInvoicesCsvButton";
import PrintInvoicesButton from "../modules/invoicing/PrintInvoicesButton";
import { publish } from "../pubSub";
import CreateInvoiceForm from "../modules/invoicing/CreateInvoiceForm";
import CreateCreditNoteForm from "../modules/invoicing/CreateCreditNoteForm";
import useCurrentTaxWeek from "../modules/tax-calendar/useCurrentTaxWeek";

export default function Invoices() {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const [createDialogOpen, setCreateDialogOpen] = React.useState(false);
  const [createCreditDialogOpen, setCreateCreditDialogOpen] = React.useState(false);
  const [selectedInvoices, setSelectedInvoices] = React.useState([]);

  const currentTaxWeek = useCurrentTaxWeek();

  return (
    <SidebarWrapper
      panel={
        <SidePanel open={sidebarOpen}>
          <FeatureFlag name="invoices-create-export">
            <PendingExportsTable />
          </FeatureFlag>
          <PanelSpacer large />
          <Button
            large
            style={{ marginRight: 16, marginLeft: 16 }}
            onClick={() => {
              window.open(
                `https://portal.peoplegroupservices.com/admin/finance/invoice-editor.asp?cn=n`,
                "blank"
              );
            }}
          >
            Add new invoice
          </Button>
          <FeatureFlag name="invoices-create-manual">
            <Button large onClick={() => setCreateDialogOpen(true)} style={{ marginRight: 16 }}>
              Add new invoice (Nelson)
            </Button>
            {/* <Button
              large
              onClick={() => {
                window.open(
                  `https://portal.peoplegroupservices.com/admin/finance/invoice-editor.asp?cn=y`,
                  "blank"
                );
              }}
            >
              Add new credit note
            </Button> */}
            <Button
              large
              onClick={() => {
                setCreateCreditDialogOpen(true);
              }}
              style={{ margin: 16 }}
            >
              Add new credit note
            </Button>
          </FeatureFlag>
          <ExportBatchCsvButton nelsonGenerated={false} />
          <ExportBatchCsvButton nelsonGenerated={true} />
        </SidePanel>
      }
      sidebar={
        <Sidebar>
          <SidebarIconButton onClick={() => setSidebarOpen(!sidebarOpen)}>
            {sidebarOpen ? <Icons.ChevronRightIcon /> : <Icons.ChevronLeftIcon />}
          </SidebarIconButton>
        </Sidebar>
      }
    >
      <Content scrollable>
        <PageHeadingArea>
          <PageTitle>Invoices</PageTitle>
          <PageHeadingAreaSpacer grow />
          <InvoiceFiltering currentTaxWeek={currentTaxWeek} />
          <PageHeadingAreaSpacer />
          <ExportInvoicesCsvButton invoices={selectedInvoices} />
          <PageHeadingAreaSpacer />
          <PrintInvoicesButton large invoices={selectedInvoices}>
            Print Selected
          </PrintInvoicesButton>
          {createDialogOpen && (
            <PanelDialog open={createDialogOpen}>
              <CreateInvoiceForm
                onClose={() => {
                  setCreateDialogOpen(false);
                  publish("invoice.details.updated");
                }}
              />
            </PanelDialog>
          )}
          <PanelDialog open={createCreditDialogOpen}>
            <CreateCreditNoteForm
              onClose={() => {
                setCreateCreditDialogOpen(false);
                publish("invoice.details.updated");
              }}
            />
          </PanelDialog>
        </PageHeadingArea>
        <InvoicesTable
          currentTaxWeek={currentTaxWeek}
          selectedInvoices={selectedInvoices}
          setSelectedInvoices={setSelectedInvoices}
        />
      </Content>
    </SidebarWrapper>
  );
}
