import React, { useState } from "react";
import { Panel, PageLoading, PanelActions, Button } from "@backslashbuild/pp-ui";
import FormField from "./FormField";
import { usePost, useGetNewFields } from "./api";
import { isTextNullOrEmpty } from "./utils";

export default function NewContactForm({ agencyId, onClose = () => {} }) {
  const [payload, storePayload] = useState({});
  const { data, loading, error } = useGetNewFields({
    agencyId,
    formName: "contact",
  });
  const createContact = usePost("contacts/create");

  if (loading) return <PageLoading />;

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    return data.value.map((field) => (
      <>
        {field.key != "IsActive" && (
          <FormField key={field.key} field={field} onSave={getOnSaveHandler(field.key)} />
        )}
      </>
    ));
  }

  function getOnSaveHandler(key) {
    return (value) => {
      storePayload({ ...payload, [key]: value });
    };
  }

  return (
    <Panel>
      {handleRender()}
      <PanelActions>
        <Button variant="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            createContact({
              agencyId,
              ...payload,
            }).then(() => {
              window.history.back();
            });
            onClose();
          }}
        >
          Save
        </Button>
      </PanelActions>
    </Panel>
  );
}
