import React from "react";
import { Content, PageHeadingArea, BackArrow, PanelColumnSet } from "@backslashbuild/pp-ui";
import TimesheetsTable from "../modules/schedules-core/TimesheetsTable";
import { useParams, useHistory } from "react-router-dom";
import ContractorPageTitle from "../modules/contractors/ContractorPageTitle";

function ContractorTimesheets() {
  const { id } = useParams();
  const history = useHistory();

  return (
    <Content>
      <PageHeadingArea>
        <BackArrow onClick={() => history.goBack()} />
        <ContractorPageTitle id={id} title="Timesheets" />
      </PageHeadingArea>
      <PanelColumnSet>
        <TimesheetsTable contractorId={id} />
      </PanelColumnSet>
    </Content>
  );
}

export default ContractorTimesheets;
