import { PageLoading, Panel, PanelHeading, PanelSpacer } from "@backslashbuild/pp-ui";
import React from "react";
import { useGetAccountOwnerFields, useUpdateFields } from "./api";
import FormField from "./FormField";
import { isTextNullOrEmpty } from "./utils";

export default function AgencyAccountOwnerForm({ id }) {
  const { data, loading, error } = useGetAccountOwnerFields({ id, formName: "" });
  const updateFields = useUpdateFields({ id, formName: "" });
  const [edits, setEdits] = React.useState([]);

  if (loading) return <PageLoading />;

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    return data.value.map((field) => (
      <FormField key={field.key} field={field} onSave={getOnSaveHandler(field.key)} />
    ));
  }

  function getOnSaveHandler(key) {
    return (value) => {
      setEdits([...edits.filter((e) => e.key !== key), { key, value }]);
    };
  }

  function handleSave() {
    updateFields(edits);
  }

  const SaveButton = () => {
    return <div onClick={handleSave}>Save</div>;
  };

  return (
    <Panel>
      <PanelHeading rightAction={<SaveButton />}>Account Owner</PanelHeading>
      <PanelSpacer />
      {handleRender()}
    </Panel>
  );
}
