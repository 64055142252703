import {
  Label,
  Panel,
  PanelDialog,
  Button,
  PanelHeading,
  PanelActions,
} from "@backslashbuild/pp-ui";
import React from "react";
import { usePost, invalidateEnrolmentExports } from "./api";
import { isTextNullOrEmpty } from "../contractors/utils";
import { PanelRow } from "@backslashbuild/pp-ui/dist/index.es";
import { publish } from "./pubSub";

export default function MarkAllEnrolledDialog({ enrolmentExport }) {
  const [dialogOpen, setDialogOpen] = React.useState(null);

  const update = usePost("enrolments/mark-all-enrolled");

  async function handleSubmit() {
    try {
      const response = await update({ enrolmentExportId: enrolmentExport.id });

      if (response.isSuccess) {
        publish("notification", "Updated successfully");
        setDialogOpen(false);
        invalidateEnrolmentExports();
      } else {
        publish(
          "notification",
          <div>
            Unable to update employment
            <ul>
              {response.errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </div>
        );
      }
    } catch (e) {
      console.error(e, "error updating");
      publish("notification", "A network error occured.");
    }
  }

  return (
    <>
      <Button onClick={() => setDialogOpen(true)}>Mark All Enrolled</Button>
      <PanelDialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <Panel>
          <PanelHeading>Update NEST Enrollment Status</PanelHeading>
          <PanelRow>This will mark all of these employments as enrolled.</PanelRow>
          <PanelActions>
            <Button variant="secondary" onClick={() => setDialogOpen(false)}>
              Cancel
            </Button>
            <Button onClick={handleSubmit}>Mark as Enrolled</Button>
          </PanelActions>
        </Panel>
      </PanelDialog>
    </>
  );
}
