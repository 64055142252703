import React from "react";
import { QueryDropdown } from "@backslashbuild/pp-ui";
import { useGetAgenciesPicker } from "./../search/api";
import { isTextNullOrEmpty } from "./utils";
import { getHighlightedText } from "../../utils";

function format(value) {
  return value
    ? `${value.agencyNumber} ${value.agencyName} ${formatAlias(value.alias)}`.trim()
    : "-";
}

function formatAlias(value) {
  if (isTextNullOrEmpty(value)) return "";

  if (value.trim()?.[0] == "(") return value;

  return `(${value})`;
}

function AgencyPicker({ value, onChange, width }) {
  const [search, setSearch] = React.useState("");
  const optionsQuery = useGetAgenciesPicker(search);
  const currentItemQuery = useGetAgenciesPicker(value);

  const options = optionsQuery.data?.value?.items ?? [];
  const currentItems = currentItemQuery.data?.value?.items ?? [];

  // Debounce the search
  var searchTimeout = null;
  const setSearchTerm = (searchTerm) => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      setSearch(searchTerm);
    }, 500);
  };

  return (
    <QueryDropdown
      placeholder="Choose"
      width={width}
      popoverWidth={600}
      onSearchChange={setSearchTerm}
      value={value}
      onChange={onChange}
      options={options.map((o) => o.agencyNumber)}
      renderValue={(v) => {
        var agency = currentItems.find((o) => o.agencyNumber == v);
        return agency ? `${agency.agencyNumber} ${agency.agencyName}` : null;
      }}
      renderOption={(v) => {
        var optionsMatch = options.find((o) => o.agencyNumber === v);

        if (optionsMatch) {
          return getHighlightedText(format(optionsMatch), search);
        }

        if (search) {
          return "loading...";
        }

        return "";
      }}
    />
  );
}

export default AgencyPicker;
