import React from "react";
import { useGetDpsSyncErrors } from "./api";
import { isTextNullOrEmpty, utcToLocalText } from "./utils";

import {
  Panel,
  PanelActions,
  Button,
  PanelHeading,
  PageLoading,
  Table,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  PanelDialog,
} from "@backslashbuild/pp-ui";

const c = {
  dateCreated: "13%",
  statusCode: "9%",
  actionType: "13%",
  code: "22%",
  description: "44%",
};

const totalWidth = Object.keys(c).reduce((total, key) => (total += c[key]), 0);

export default function HmrcErrorsDialog({ open, onClose, syncId }) {
  const { data, loading, error, queryId, refetch } = useGetDpsSyncErrors(syncId);

  function PureTable({ data, queryId }) {
    return React.useMemo(() =>
      data?.value?.map(
        (item) => (
          <TableRow>
            <TableCell width={c.dateCreated}>
              {utcToLocalText(item?.dateCreated, "dd/MM/yyyy HH:mm")}
            </TableCell>
            <TableCell width={c.statusCode}>{item?.statusCode}</TableCell>
            <TableCell width={c.actionType}>{item?.postType}</TableCell>
            <TableCell width={c.code}>{item?.code}</TableCell>
            <TableCell width={c.description}>{item?.description}</TableCell>
          </TableRow>
        ),
        [queryId]
      )
    );
  }

  return (
    <PanelDialog width={1200} open={open} onClose={onClose}>
      <Panel maxWidth={1200}>
        <PanelHeading>Errors</PanelHeading>
        {loading && <PageLoading />}
        {!isTextNullOrEmpty(error) && <div>{String(error)}</div>}
        {!data?.isSuccess && data?.errors?.map((error) => <div>{error}</div>)}
        {data?.isSuccess && (
          <Table width={totalWidth}>
            <TableHeader>
              <TableHeaderColumn width={c.dateCreated}>Date Created</TableHeaderColumn>
              <TableHeaderColumn width={c.statusCode}>Status Code</TableHeaderColumn>
              <TableHeaderColumn width={c.actionType}>Action Type</TableHeaderColumn>
              <TableHeaderColumn width={c.code}>Code</TableHeaderColumn>
              <TableHeaderColumn width={c.description}>Description</TableHeaderColumn>
            </TableHeader>
            <TableBody fullPageTable={false} footerHeight={TableFooter.height}>
              <PureTable data={data} queryId={queryId} />
            </TableBody>
          </Table>
        )}
        <PanelActions>
          <Button variant="secondary" onClick={() => onClose()}>
            Close
          </Button>
        </PanelActions>
      </Panel>
    </PanelDialog>
  );
}
