import React from "react";
import { Link, Icons } from "@backslashbuild/pp-ui";
import { usePostDeleteRow } from "./api";
import { publish } from "../../pubSub";

function DeleteManualRowButton({ row, scheduleId }) {
  const deleteRow = usePostDeleteRow(scheduleId);

  async function handleDelete() {
    try {
      const response = await deleteRow({ rowId: row.id, scheduleId });

      if (response.isSuccess) {
        publish("notification", <div>Row deleted</div>);
      } else {
        publish(
          "notification",
          <div>
            Unable to update row settings
            <ul>
              {response.errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </div>
        );
      }
    } catch (e) {
      console.error(e, "error deleting a row");
      publish("notification", "A network error occured.");
    }
  }

  if (row.isManualEntry) return <Icons.DeleteIcon onClick={handleDelete} />;

  return null;
}

export default DeleteManualRowButton;
