import React from "react";
import { useGet } from "./api";
import { isTextNullOrEmpty } from "./utils";

export default function AuthorName({ id }) {
  const { data, loading, error, refetch } = useGet(`author/${id}`, `author/${id}`);

  if (loading) return <>Loading...</>;

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <>{String(error)}</>;

    if (data != null && !data?.isSuccess && data?.errors) {
      if (typeof data.errors == Array) return data?.errors?.map((error) => <>{error}</>);
      return <>{data.errors.toString()}</>;
    }

    return <>{data?.value?.name}</>;
  }

  return <>{handleRender()}</>;
}
