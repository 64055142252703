import React from "react";

import AddBACSRunSection from "../modules/bacs/AddBACSRunSection";
import BACSRunsFilter from "../modules/bacs/BACSRunsFilter";
import BACSRunsTable from "../modules/bacs/BACSRunsTable";

import {
  Content,
  PageHeadingArea,
  PageTitle,
  PageHeadingAreaSpacer,
  PanelColumnSet,
  PanelColumn,
  PanelColumnSpacer,
  PanelSpacer,
} from "@backslashbuild/pp-ui";

export default function BACSRuns() {
  return (
    <Content scrollable>
      <PageHeadingArea>
        <PageTitle>BACS Runs</PageTitle>
        <PageHeadingAreaSpacer grow />
        <AddBACSRunSection />
      </PageHeadingArea>
      <PanelColumnSet>
        <PanelColumn>
          <BACSRunsFilter />
          <PanelSpacer large />
        </PanelColumn>
        <PanelColumnSpacer />
        <BACSRunsTable />
      </PanelColumnSet>
    </Content>
  );
}
