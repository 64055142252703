import React from "react";
import { PageLoading } from "@backslashbuild/pp-ui";
import { useParams, useLocation } from "react-router-dom";
import useRedirectToPayslip from "../modules/payslip-reporting/useRedirectToPayslip";
import queryString from "query-string";
import { textToBoolean } from "../utils";

function RedirectToPayslip({ isIllustration }) {
  const { payslipNumber } = useParams();
  const params = queryString.parse(useLocation().search);

  const open = useRedirectToPayslip(
    payslipNumber,
    isIllustration,
    textToBoolean(params.remittanceAdvice),
    textToBoolean(params.isScraped)
  );

  React.useEffect(() => {
    open();
  }, []);

  return <PageLoading />;
}

export default RedirectToPayslip;
