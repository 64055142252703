import React from "react";
import {
  PanelDialog,
  PanelHeading,
  PanelActions,
  PanelRow,
  Button,
  Label,
  TextInput,
} from "@backslashbuild/pp-ui";
import {
  useUploadContributionScheduleErrorLog,
  invalidateContributions,
  useGetContributionScheduleExport,
} from "./api";
import { publish } from "../../pubSub";
import { useParams } from "react-router-dom";

export default function UploadContributionsErrorLogDialog() {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [file, setFile] = React.useState(null);

  const { exportId } = useParams();

  const { data, loading, error, queryId } = useGetContributionScheduleExport({
    exportId,
  });

  const disabled = loading || !data || error || data?.value?.errorsIdentified;

  const upload = useUploadContributionScheduleErrorLog(exportId);

  function handleCancel() {
    setDialogOpen(false);
  }

  async function handleSubmit() {
    publish("notification", "Uploading file...");

    const result = await upload(file);
    publish("notification", "Upload complete");

    invalidateContributions();
    handleCancel();
  }

  if (data?.value?.errorsIdentified)
    return (
      <Button disabled style={{ marginRight: 10 }}>
        Error Log Processed
      </Button>
    );

  return (
    <>
      <Button onClick={() => setDialogOpen(true)} disabled={disabled} style={{ marginRight: 10 }}>
        Submit Contributions Error Log
      </Button>

      <PanelDialog onClose={handleCancel} open={dialogOpen}>
        <PanelHeading>Upload Contributions Error Log File</PanelHeading>
        <PanelRow>
          <Label label="Choose file">
            <TextInput type="file" onChange={(e) => setFile(e.target.files[0])} />
          </Label>
        </PanelRow>
        <PanelActions>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="primary" disabled={!file} onClick={handleSubmit}>
            Submit
          </Button>
        </PanelActions>
      </PanelDialog>
    </>
  );
}
