import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  PageHeadingArea,
  PageHeadingAreaSpacer,
  PageTitle,
  PageLoading,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  SearchBar,
  StatusIndicator,
} from "@backslashbuild/pp-ui";
import { utcToLocalText, isTextNullOrEmpty } from "./utils";
import { makeStyles } from "@backslashbuild/pp-ui";
import { useGetItems } from "./api";

export default function ProductsTable() {
  const { data, loading, error } = useGetItems();

  if (loading || !data) return <PageLoading />;
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  return (
    <>
      <PageHeadingArea>
        <PageTitle>Products</PageTitle>
        <PageHeadingAreaSpacer grow />
      </PageHeadingArea>
      <Table fullPageTable={true}>
        <TableHeader>
          <TableHeaderColumn width="30%">Name</TableHeaderColumn>
          <TableHeaderColumn width="30%">Short Name</TableHeaderColumn>
          <TableHeaderColumn width="20%">Date Created</TableHeaderColumn>
          <TableHeaderColumn width="10%">Is PAYE</TableHeaderColumn>
          <TableHeaderColumn width="10%">Is Active</TableHeaderColumn>
        </TableHeader>
        <TableBody fullPageTable={true}>
          {data.value.items.map((product) => (
            <TableRow to={`/product/${product.id}`} component={RouterLink}>
              <TableCell width="30%">{product.name}</TableCell>
              <TableCell width="30%">{product.shortName}</TableCell>
              <TableCell width="20%">{utcToLocalText(product.dateCreated)}</TableCell>
              <TableCell width="10%">
                <StatusIndicator value={product.isPAYE} />
              </TableCell>
              <TableCell width="10%">
                <StatusIndicator value={product.isActive} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
