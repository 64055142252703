import React from "react";
import {
  Content,
  PageHeadingArea,
  PageTitle,
  PageHeadingAreaSpacer,
  Button,
  BackArrow,
  Panel,
  PanelColumn,
  PageLoading,
  PanelColumnSpacer,
} from "@backslashbuild/pp-ui";
import { useParams, useHistory } from "react-router-dom";
import { useGet as useGetPayslipReporting } from "../modules/payslip-reporting/api";
import { useGetItems as useGetItemsLegacyIntegration } from "../modules/legacy-integration/api";
import ObjectMap from "../components/ObjectMap";
import { PanelHeading } from "@backslashbuild/pp-ui/dist/index.es";

export default function DebugPayslip() {
  const { payslipNumber } = useParams();
  const history = useHistory();

  const { data: dataPayslips, loading, errors } = useGetPayslipReporting(`${payslipNumber}`);

  const payslip = dataPayslips?.value ?? {};
  const { eventId, eventDate, metaData, payslipCategory, lineItems, figures, entries } = payslip;

  const { data: dataSyncMessages } = useGetItemsLegacyIntegration(
    `sync-messages?syncMessageId=${payslip?.eventId}&search=${payslip.payslipNumber}`
  );
  const { items: syncMessages } = dataSyncMessages?.value ?? [];

  function flattenFigures(lineItems) {
    let output = {};

    lineItems?.length > 0 &&
      lineItems.forEach((item, index) => {
        const { typeId, value } = item;

        output = { ...output, [typeId]: value };
      });

    return output;
  }

  function handleRenderPayslipData() {
    if (loading) {
      return <PageLoading />;
    }

    if (errors) {
      return (
        <div>
          {errors?.map((err) => (
            <div>{err}</div>
          ))}
        </div>
      );
    }

    if (payslip) {
      return (
        <>
          <PanelColumn>
            <div style={{ width: 400 }}>
              <Panel>
                <PanelHeading>Details</PanelHeading>
                <ObjectMap noFormat data={{ eventId, eventDate, ...metaData }} />
              </Panel>
            </div>
          </PanelColumn>
          <PanelColumnSpacer />
          <PanelColumn>
            <div style={{ width: 400 }}>
              <Panel>
                <PanelHeading>Line Items</PanelHeading>
                <ObjectMap noFormat data={lineItems} />
              </Panel>
            </div>
          </PanelColumn>
          <PanelColumnSpacer />
          <PanelColumn>
            <div style={{ width: 400 }}>
              <Panel>
                <PanelHeading>Figures</PanelHeading>
                <ObjectMap noFormat data={flattenFigures(figures)} />
              </Panel>
            </div>
          </PanelColumn>
          <PanelColumnSpacer />
          <PanelColumn>
            <div style={{ width: 400 }}>
              <Panel>
                <PanelHeading>Entries</PanelHeading>
                <ObjectMap noFormat data={entries} />
              </Panel>
            </div>
          </PanelColumn>
          <PanelColumnSpacer />
        </>
      );
    }
  }

  function handleRenderSyncData() {
    const { inputModel } = syncMessages?.[0] ?? {};
    if (syncMessages) {
      return (
        <>
          <PanelColumn>
            <div style={{ width: 400 }}>
              <Panel>
                <PanelHeading>
                  Sync Message: Primary Lookup{" "}
                  <Button
                    small
                    onClick={() => {
                      window.open(
                        `/legacy-sync-messages?syncMessageId=${payslip.eventId}&search=${payslip.payslipNumber}`,
                        "_blank"
                      );
                    }}
                  >
                    View
                  </Button>
                </PanelHeading>
                <ObjectMap noFormat data={inputModel?.primaryItem} />
              </Panel>
            </div>
          </PanelColumn>
          <PanelColumnSpacer />
          <PanelColumn>
            <div style={{ width: 400 }}>
              <Panel>
                <PanelHeading>Sync Message: Payslip Details</PanelHeading>
                <ObjectMap noFormat data={inputModel?.secondaryItems?.[0]?.items} />
              </Panel>
            </div>
          </PanelColumn>
        </>
      );
    }

    return null;
  }

  return (
    <Content scrollable>
      <PageHeadingArea>
        <BackArrow onClick={() => history.goBack()} />
        <PageHeadingAreaSpacer />
        <PageTitle>{`Payslip ${payslipNumber}`}</PageTitle>
        <PageHeadingAreaSpacer grow />
        <Button
          small
          onClick={() => {
            window.open(
              `/payslip-redirect/${payslip?.payslipNumber}?remittanceAdvice=${
                payslip.payslipCategory == "CIS"
              }`,
              "_blank"
            );
          }}
        >
          View
        </Button>
        <PageHeadingAreaSpacer />
        {payslipCategory == "CIS" ? (
          <Button
            small
            onClick={(e) => {
              e.stopPropagation();
              window.open(
                `https://portal.peoplegroupservices.com/payslips-docs/payslip-recon.asp?payslipnum=${payslip?.payslipNumber}`,
                "_blank"
              );
            }}
          >
            Legacy RA
          </Button>
        ) : (
          <Button
            small
            onClick={(e) => {
              e.stopPropagation();
              window.open(
                `https://portal.peoplegroupservices.com/payslips-docs/payslip-recon.asp?payslipnum=${payslip?.payslipNumber}`,
                "_blank"
              );
            }}
          >
            Legacy AR
          </Button>
        )}
      </PageHeadingArea>
      <div style={{ display: "flex", overflowX: "scroll" }}>
        {handleRenderPayslipData()}
        {handleRenderSyncData()}
      </div>
    </Content>
  );
}
