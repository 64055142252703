import React from "react";
import {
  PanelRow,
  Label,
  TextInput,
  Dropdown,
  DropdownOption,
  Panel,
  PanelHeading,
  PanelSpacer,
  PanelActions,
  Button,
  CheckBox,
  PageLoading,
} from "@backslashbuild/pp-ui";
import { usePost, usePut, useGet } from "./api";
import { publish } from "../../pubSub";
import { useHistory } from "react-router-dom";

function CreateUserForm({ isUpdate, id }) {
  const history = useHistory();
  const [state, setState] = React.useState({
    name: "",
    email: "",
    password: "",
    department: "",
    roles: [],
  });

  const userQuery = useGet(`users/${id}`);

  const createUser = usePost("users");
  const updateUser = usePut(`users/${id}`);

  React.useEffect(() => {
    setState({
      name: userQuery?.data?.value?.name,
      email: userQuery?.data?.value?.email,
      department: userQuery?.data?.value?.department,
      roles: userQuery?.data?.value?.roles?.map((r) => r.roleName) ?? [],
      isContractor: userQuery?.data?.value?.isContractor,
    });
  }, [userQuery.data]);

  async function handleUpdateUser(data) {
    const result = await updateUser(data);

    if (result.isSuccess) {
      publish("notification", "Saved");
    } else {
      publish("notification", result.errors.join(","));
    }
  }

  async function handleCreateUser(data) {
    const result = await createUser(data);

    if (result.isSuccess) {
      publish("notification", "Created New User");
      history.push(`/user/${result.value}`);
    } else {
      publish("notification", result.errors.join(","));
    }
  }

  async function handleSubmit() {
    const data = {
      name: state.name,
      email: state.email,
      password: state.password,
      department: state.department,
      roles: state.roles.map((roleName) => ({ roleName })),
      isContractor: state.isContractor,
    };
    try {
      if (isUpdate) {
        await handleUpdateUser(data);
      } else {
        await handleCreateUser(data);
      }
    } catch (error) {
      console.error("Something went wrong accessing the server.");
      publish("notification", "Sorry, there was an error connecting to our servers.");
    }
  }

  if (isUpdate && userQuery.loading) {
    return (
      <Panel>
        <PanelHeading>User</PanelHeading>
        <PageLoading />
      </Panel>
    );
  }

  return (
    <Panel>
      <PanelHeading>User</PanelHeading>
      <PanelSpacer />
      <PanelRow>
        <Label label="Name">
          <TextInput
            value={state.name}
            onChange={(e) => setState({ ...state, name: e.target.value })}
          />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Email">
          <TextInput
            value={state.email}
            onChange={(e) => setState({ ...state, email: e.target.value })}
          />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Password">
          <TextInput
            value={state.password}
            onChange={(e) => setState({ ...state, password: e.target.value })}
          />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Is Contractor">
          <CheckBox
            checked={state.isContractor}
            onChange={(e) => setState({ ...state, isContractor: e.target.checked })}
          />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Department">
          <Dropdown
            onChange={(v) => setState({ ...state, department: v.target.value })}
            value={state.department}
          >
            <DropdownOption value="" children="-" />

            {[
              "Agency Care Department",
              "All Sales",
              "Client Care",
              "Expenses",
              "Finance",
              "Human Resources",
              "Internal Sales",
              "IT",
              "Marketing",
              "Payroll",
              "Registrations",
            ].map((x) => (
              <DropdownOption key={x} value={x} children={x} />
            ))}
          </Dropdown>
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Roles">
          {[
            "Agency Branch Consultant",
            "Agency National Consultant",
            "Agency Branch Manager",
            "Agency Manager",
            "Agency",
            "BAM Access Only",
            "Outsource Worker Trainee",
            "Outsource Worker",
            "RACS Staff",
            "Account Manager",
            "Administrator",
            "Developer",
          ].map((role) => (
            <>
              <CheckBox
                label={role}
                checked={state.roles.includes(role)}
                onChange={(e) => {
                  if (e.target.checked && !state.roles.includes(role)) {
                    setState({ ...state, roles: [...state.roles, role] });
                  } else if (!e.target.checked && state.roles.includes(role)) {
                    setState({ ...state, roles: state.roles.filter((r) => r != role) });
                  }
                }}
              />
              <br />
            </>
          ))}
        </Label>
      </PanelRow>
      <PanelSpacer />
      <PanelActions>
        {isUpdate ? (
          <Button onClick={handleSubmit}>Update user</Button>
        ) : (
          <Button onClick={handleSubmit}>Create user</Button>
        )}
      </PanelActions>
      <PanelSpacer />
    </Panel>
  );
}

export default CreateUserForm;
