import React from "react";

import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { useGetBACSRuns } from "./api";
import { isTextNullOrEmpty, utcToLocalText } from "./utils";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";
import GreenTick from "../../components/GreenTick";

import {
  TableRow,
  Table,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableFooter,
  PageLoading,
} from "@backslashbuild/pp-ui";

const c = {
  dateCreated: "12.5%",
  payrollDate: "12.5%",
  weekNo: "15%",
  payslipNo: "15%",
  rowsNo: "15%",
  isLocked: "10%",
  isPaid: "10%",
  isClosed: "10%",
};

export default function BACSRunsTable() {
  const location = useLocation();
  const search = usePagination(location.search);
  const query = ParseQuery(search);
  const { data, loading, error, queryId, refetch } = useGetBACSRuns(query);

  if (loading) return <PageLoading />;
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  return (
    <>
      <Table
        sticky
        stickyFooter={
          <TableFooter sticky>
            <QueryPaginator
              itemsTotal={data?.value?.itemsTotal}
              itemsRetrieved={data?.value?.itemsRetrieved}
            />
          </TableFooter>
        }
        footerHeight={TableFooter.height}
      >
        <TableHeader sticky>
          <TableHeaderColumn sticky>Date Created</TableHeaderColumn>
          <TableHeaderColumn sticky>Payroll Date</TableHeaderColumn>
          <TableHeaderColumn sticky>Week No</TableHeaderColumn>
          <TableHeaderColumn sticky>Payslips</TableHeaderColumn>
          <TableHeaderColumn sticky>BACS Rows</TableHeaderColumn>
          <TableHeaderColumn sticky>Closed</TableHeaderColumn>
          <TableHeaderColumn sticky>Locked</TableHeaderColumn>
          <TableHeaderColumn sticky>Paid</TableHeaderColumn>
        </TableHeader>
        <TableBody sticky footerHeight={TableFooter.height}>
          <PureTable data={data} queryId={queryId} />
        </TableBody>
      </Table>
    </>
  );
}

function PureTable({ data, queryId }) {
  return React.useMemo(() =>
    data?.value?.items?.map(
      (bacsRun) => (
        <TableRow sticky>
          <TableCell sticky>
            <ClickWrapper bacsRun={bacsRun}>
              {utcToLocalText(bacsRun.dateCreated, "dd/MM/yyyy HH:mm")}
            </ClickWrapper>
          </TableCell>
          <TableCell sticky>
            <ClickWrapper bacsRun={bacsRun}>
              {utcToLocalText(bacsRun.payrollDate, "dd/MM/yyyy")}
            </ClickWrapper>
          </TableCell>
          <TableCell sticky>
            <ClickWrapper bacsRun={bacsRun}>{bacsRun?.weekNo}</ClickWrapper>
          </TableCell>
          <TableCell sticky>
            <ClickWrapper bacsRun={bacsRun}>{bacsRun?.lockedPayslips}</ClickWrapper>
          </TableCell>
          <TableCell sticky>
            <ClickWrapper bacsRun={bacsRun}>{bacsRun?.lockedRows}</ClickWrapper>
          </TableCell>
          <TableCell sticky>
            <ClickWrapper bacsRun={bacsRun}>{bacsRun?.isClosed && <GreenTick />}</ClickWrapper>
          </TableCell>
          <TableCell sticky>
            <ClickWrapper bacsRun={bacsRun}>{bacsRun?.isLocked && <GreenTick />}</ClickWrapper>
          </TableCell>
          <TableCell sticky>
            <ClickWrapper bacsRun={bacsRun}>{bacsRun?.isPaid && <GreenTick />}</ClickWrapper>
          </TableCell>
        </TableRow>
      ),
      [queryId]
    )
  );
}

function ParseQuery(search) {
  const parsedQueryString = queryString.parse(search);

  return `?${queryString.stringify({ ...parsedQueryString })}`;
}

function ClickWrapper({ children, bacsRun }) {
  const history = useHistory();

  return <div onClick={() => history.push(`/bacs-run/${bacsRun?.id}`)}>{children}</div>;
}
