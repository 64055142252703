import React from "react";
import QueryFiltering from "../../containers/QueryFiltering";
import { PanelRow, Label, TextInput, RadioGroup, Radio } from "@backslashbuild/pp-ui";
import AgencyPicker from "../agencies/AgencyPicker";
import AgencyName from "../agencies/AgencyName";

function ContractorsFilter({ small }) {
  return (
    <QueryFiltering
      pathname="/contractors"
      small={small}
      searchStringKey="search"
      getTagsFromState={(state) => {
        const tags = [];
        if (state.unprocessed == "true") tags.push({ key: "unprocessed", value: "Only" });
        if (state.unprocessed == "false") tags.push({ key: "unprocessed", value: "Exclude" });
        if (state.agencyId) tags.push({ key: "agency", value: <AgencyName id={state.agencyId} /> });
        return tags;
      }}
      getNextStateFromDeleteTag={(state, key) => {
        if (key === "unprocessed") return { ...state, unprocessed: undefined };
        if (key === "agency") return { ...state, agencyId: undefined };
        return state;
      }}
      placeholder="Search contractors"
      renderForm={(state, setState) => (
        <>
          <PanelRow>
            <Label label="Search Text">
              <TextInput
                value={state.search}
                onChange={(e) => setState({ ...state, search: e.target.value })}
              />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Agency">
              <AgencyPicker
                value={state.agencyId}
                onChange={(agencyId) => setState({ ...state, agencyId })}
              />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Needs processing">
              <RadioGroup
                value={state.unprocessed}
                onChange={(e) => setState({ ...state, unprocessed: e.target.value })}
              >
                <Radio value="" label="Any" />
                <Radio value={"true"} label="Yes" />
                <Radio value={"false"} label="No" />
              </RadioGroup>
            </Label>
          </PanelRow>
        </>
      )}
    />
  );
}

export default ContractorsFilter;
