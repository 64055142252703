import React from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useGetNotifications } from "./api";
import { getYmdDate, isTextNullOrEmpty, utcToLocalText } from "./utils";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";

import ObjectMap from "../../components/ObjectMap";

import {
  TableRow,
  Table,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableFooter,
  PageLoading,
  PanelColumn,
  PanelHeading,
  Panel,
  PanelColumnSpacer,
} from "@backslashbuild/pp-ui";

const c = {
  formType: 2,
  issueDate: 7,
  seqNo: 7,
  empRef: 7,
  taxYearEnd: 7,
  employerName: 10,
  nino: 7,
  workNo: 7,
};

const totalWidth = Object.keys(c).reduce((total, key) => (total += c[key]), 0);

function PureTable({ data, queryId, onItemClick }) {
  return React.useMemo(() =>
    data?.value?.items?.map(
      (notification) => (
        <TableRow
          backgroundColor={notificationToBackgroundColor(notification)}
          onClick={() => onItemClick(notification)}
        >
          <TableCell width={c.formType}>{notification.formType}</TableCell>
          <TableCell width={c.issueDate}>{utcToLocalText(notification.issueDate)}</TableCell>
          <TableCell width={c.seqNo}>{notification.sequenceNumber}</TableCell>
          <TableCell width={c.empRef}>{notification.employerRef}</TableCell>
          <TableCell width={c.nino}>{notification.nationalInsuranceNumber}</TableCell>
          <TableCell width={c.taxYearEnd}>{notification.taxYearEnd}</TableCell>
          <TableCell width={c.workNo}>{notification.worksNumber}</TableCell>
          <TableCell width={c.employerName}>{notification.employerName}</TableCell>
        </TableRow>
      ),
      [queryId]
    )
  );
}

const notificationToBackgroundColor = (notification) => {
  let colors = {
    processed: "#5ac775",
  };

  if (notification?.isProcessed) return colors.processed;
  return null;
};

function ParseQuery(search) {
  const parsedQueryString = queryString.parse(search);

  if (
    !parsedQueryString?.issueDateFrom &&
    !parsedQueryString?.issueDateTo &&
    !parsedQueryString?.dateCreatedFrom &&
    !parsedQueryString?.dateCreatedTo
  ) {
    return `?${queryString.stringify({
      issueDateFrom: getYmdDate({ minusDays: 1 }),
      ...parsedQueryString,
    })}`;
  } else {
    return `?${queryString.stringify({
      ...parsedQueryString,
    })}`;
  }
}

export default function DpsTable() {
  const [selectedItem, setSelectedItem] = React.useState(null);
  const location = useLocation();
  const search = usePagination(location.search);
  const query = ParseQuery(search);
  const { data, loading, error, queryId, refetch } = useGetNotifications(query);

  if (loading) return <PageLoading />;
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  return (
    <>
      <PanelColumn width={1000}>
        {/* Temporary fix for Safari not rendering ui components correctly */}
        <div style={{ width: "100%", height: "77vh" }}>
          <Table standardTable width={totalWidth}>
            <TableHeader>
              <TableHeaderColumn width={c.formType}>Form Type</TableHeaderColumn>
              <TableHeaderColumn width={c.issueDate}>Issue Date</TableHeaderColumn>
              <TableHeaderColumn width={c.seqNo}>Sequence Number</TableHeaderColumn>
              <TableHeaderColumn width={c.empRef}>Employer Ref</TableHeaderColumn>
              <TableHeaderColumn width={c.nino}>NINO</TableHeaderColumn>
              <TableHeaderColumn width={c.taxYearEnd}>Tax Year End</TableHeaderColumn>
              <TableHeaderColumn width={c.workNo}>Works Number</TableHeaderColumn>
              <TableHeaderColumn width={c.employerName}>Employer Name</TableHeaderColumn>
            </TableHeader>
            <TableBody fullPageTable={true}>
              <PureTable
                data={data}
                queryId={queryId}
                onItemClick={(item) => setSelectedItem(item)}
              />
            </TableBody>
            <TableFooter>
              <QueryPaginator
                itemsTotal={data?.value?.itemsTotal}
                itemsRetrieved={data?.value?.itemsRetrieved}
              />
            </TableFooter>
          </Table>
        </div>
      </PanelColumn>
      <PanelColumnSpacer />
      <PanelColumn>
        <Panel>
          <PanelHeading>Data view</PanelHeading>
          <ObjectMap data={selectedItem} hideEmptyValues />
          {!Boolean(selectedItem) && "Select a notification to view the data."}
        </Panel>
      </PanelColumn>
    </>
  );
}
