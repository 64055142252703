import React from "react";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { useQuery, queryCache } from "react-query";
import { publish } from "../agencies/pubSub";
import getApiUrl from "../../getApiUrl";

const apiHost = getApiUrl();
const service = "search";

export function useGet(cacheKey, path, serviceOverride) {
  if (!path) {
    path = cacheKey;
  }
  if (!serviceOverride) {
    serviceOverride = service;
  }

  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };

  const { data, error, isLoading: loading, updatedAt: queryId } = useQuery(
    cacheKey,
    async (key, id) => {
      return fetch(`${apiHost}/${serviceOverride}/${path}`, {
        headers: {
          ...authHeaders,
        },
      }).then((r) => r.json());
    }
  );

  const runQuery = () => queryCache.invalidateQueries(path);

  return { data, loading, error, queryId, refetch: runQuery };
}

export function useGetAgenciesPicker(search) {
  return useGet(
    [`agencies-picker`, `agencies/picker?search=${encodeURIComponent(search)}`],
    `agencies/picker?search=${encodeURIComponent(search)}`
  );
}

export function usePost(path) {
  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };

  return React.useCallback(
    (body) => {
      return fetch(`${apiHost}/${service}/${path}`, {
        method: "POST",
        headers: {
          ...authHeaders,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }).then((x) => {
        return x?.json();
      });
    },
    [path, authHeaders.Authorization]
  );
}
