import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Content,
  PanelColumnSpacer,
  PanelColumn,
  PageHeadingArea,
  PanelColumnSet,
  Panel,
  PanelSpacer,
  SidebarWrapper,
  Sidebar,
  CommentsIconButton,
  Root,
  BackArrow,
  PageHeadingAreaSpacer,
} from "@backslashbuild/pp-ui";

import AgencyDetailsPanel from "../modules/agencies/AgencyDetailsPanel";
import BranchesTable from "../modules/agencies/BranchesTable";
import CommsPanelApp from "../modules/internal-comms/CommsPanelApp/CommsPanelApp";
import AgencyAccountOwnerPanel from "../modules/agencies/AgencyAccountOwnerPanel";
import AgencyPageTitle from "../modules/agencies/AgencyPageTitle";
import AgencyContactsPanel from "../modules/agencies/AgencyContactsPanel";
import AgencyGuidelinePanel from "../modules/agencies/AgencyGuidelinePanel";
import AgencySettingsPanel from "../modules/agencies/AgencySettingsPanel";
import PaidContractorsTable from "../modules/agencies/PaidContractorsTable";
import PEOSalesPipelineTable from "../modules/agencies/PEOSalesPipelineTable";
import ConsultantsTable from "../modules/agencies/ConsultantsTable";
import EmulateAgencyButton from "../modules/agencies/EmulateAgencyButton";
import AgencyCreditPanel from "../modules/agencies/AgencyCreditPanel";
import AgencyNewStarterPanel from "../modules/agencies/AgencyNewStarterPanel";

import AgencyUmbrellaRatesPanel from "../modules/payroll/AgencyUmbrellaRatesPanel";
import AgencyPEOBandsPanel from "../modules/schedules-core/AgencyPEOBandsPanel";

export default function Agency() {
  const { id } = useParams();
  const history = useHistory();
  const [editContact, setEditContact] = React.useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <SidebarWrapper
      panel={<CommsPanelApp open={sidebarOpen} itemId={id} configuration={"isAgency"} />}
      sidebar={
        <Sidebar>
          <CommentsIconButton onClick={() => setSidebarOpen(!sidebarOpen)} />
        </Sidebar>
      }
    >
      <Content scrollable>
        <PageHeadingArea>
          <BackArrow onClick={() => history.goBack()} />
          <PageHeadingAreaSpacer />
          <AgencyPageTitle id={id} />
          <PageHeadingAreaSpacer grow />
          <EmulateAgencyButton />
        </PageHeadingArea>
        <PanelColumnSet>
          <PanelColumn>
            <AgencyDetailsPanel id={id} />
            <PanelSpacer large />
            <AgencyCreditPanel id={id} />
            <PanelSpacer large />
            <AgencyNewStarterPanel id={id} />
            <PanelSpacer large />
            <AgencyAccountOwnerPanel id={id} />
            <PanelSpacer large />
            <AgencyGuidelinePanel id={id} />
            <PanelSpacer large />
            <AgencySettingsPanel id={id} />
            <PanelSpacer large />
            <AgencyUmbrellaRatesPanel agencyId={id} />
            <PanelSpacer large />
            <AgencyPEOBandsPanel agencyId={id} />
            <PanelSpacer large />
            <AgencyContactsPanel agencyId={id} />
          </PanelColumn>
          <PanelColumnSpacer />
          <PanelColumn grow>
            <PEOSalesPipelineTable id={id} />
            <PanelSpacer large />
            <PaidContractorsTable />
            <PanelSpacer large />
            <BranchesTable agencyId={id} />
            <PanelSpacer large />
            <ConsultantsTable agencyId={id} />
          </PanelColumn>
        </PanelColumnSet>
      </Content>
    </SidebarWrapper>
  );
}
