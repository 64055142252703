import React from "react";
import { makeStyles } from "@backslashbuild/pp-ui";
import {
  Button,
  TextInput,
  BoldText,
  CheckBox,
  TextInputMultiline,
  Icons,
} from "@backslashbuild/pp-ui";
import { useGet, usePost } from "./api";
import { publish } from "./pubSub";
import { Rollover } from "@backslashbuild/pp-ui/dist/index.es";

const useStyles = makeStyles({
  wrapper: {
    width: "100% !important",
    display: "block !important",
    marginBottom: "25px !important",
    height: "600px !important",
    marginBottom: "100px",
  },
  editor: {
    width: "100% !important",
    padding: "6px 5px",
    display: "block !important",
    marginBottom: "25px !important",
    flexGrow: 1,
    boxSizing: "templateBody-box",
    border: "1px solid #e3e9ef",
  },
  toolbar: {
    width: "100%",
    padding: "6px 5px",
    borderRadius: "2px",
    border: "1px solid #F1F1F1",
    display: "flex",
    justifyContent: "flex-start",
    background: "white",
    flexWrap: "wrap",
    fontSize: "15px",
    marginBottom: "5px",
    userSelect: "none",
  },
  preview: {
    width: "60%",
    height: "200px",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
  },
  detailsFormFields: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    padding: "8px",
  },
  infoBox: {
    border: "1px solid grey",
    width: 400,
  },
});

const TemplateEditorFormContainer = ({ id }) => {
  const { data, loading, error } = useGet(`sms-template/${id}`);
  const updateTemplate = usePost(`sms-template/${id}/update`);

  if (loading || !data) return <>Loading...</>;

  if (data?.errors)
    return (
      <>
        {data?.errors?.map((error) => {
          return <>{error}</>;
        })}
      </>
    );

  return (
    <TemplateEditorForm template={data.value} loading={loading} updateTemplate={updateTemplate} />
  );
};

const TemplateEditorForm = ({ template, loading, updateTemplate }) => {
  const classes = useStyles();

  const [name, setName] = React.useState(template.name);
  const [description, setDescription] = React.useState(template.description);
  const [templateBody, setTemplateBody] = React.useState(template.templateBody);
  const [isActive, setIsActive] = React.useState(template.isActive);

  async function handleSave() {
    try {
      const response = await updateTemplate({
        templateBody: templateBody,
        name: name,
        description: description,
        isActive: isActive,
      });

      if (response.isSuccess) {
        publish("notification", "Template updated");
      } else {
        publish(
          "notification",
          <div>
            Unable to save template
            <ul>
              {response.errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </div>
        );
      }
    } catch (e) {
      console.error(e, "error saving template");
      publish("notification", "A network error occured.");
    }
  }

  return (
    <div className={classes.root}>
      <DetailsFormFields
        name={name}
        setName={setName}
        description={description}
        setDescription={setDescription}
        isActive={isActive}
        setIsActive={setIsActive}
      >
        <Button disabled={loading} onClick={() => handleSave()}>
          Save
        </Button>
      </DetailsFormFields>
      <ContentEditor templateBody={templateBody} setTemplateBody={setTemplateBody} />
    </div>
  );
};

const Spacer = ({ grow, width }) => {
  return <div style={{ width: width ?? "10px", flexGrow: grow ? 1 : 0 }}></div>;
};

const DetailsFormFields = ({
  name,
  setName,
  description,
  setDescription,
  isActive,
  setIsActive,
  children,
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.detailsFormFields}>
        <BoldText>Document Name</BoldText>
        <Spacer />
        <TextInput
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        ></TextInput>
        <Spacer />
        <BoldText>Subject</BoldText>
        <Spacer />
        <TextInput
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        ></TextInput>
        <Spacer />
        <CheckBox
          checked={isActive}
          label={"Active"}
          onChange={(e) => {
            setIsActive(e.target.checked);
          }}
        ></CheckBox>
        <Spacer grow /> {children}
      </div>
    </>
  );
};

const ContentEditor = ({ templateBody, setTemplateBody }) => {
  const classes = useStyles();

  const Icon = Icons.InfoIcon;

  return (
    <>
      <div className={classes.detailsFormFields}>
        <TextInputMultiline
          value={templateBody}
          onChange={(e) => {
            setTemplateBody(e.target.value);
          }}
          width={"100%"}
          rows={10}
        ></TextInputMultiline>
      </div>
      <Rollover
        renderAnchor={() => (
          <div className={classes.detailsFormFields}>
            <div>
              SMS Length: <b>{templateBody?.length}</b> chars
            </div>
            <Spacer grow />
            <Icon />
            <Spacer width={5} />
            <div>How to use</div>
          </div>
        )}
        placement="left"
      >
        <div className={classes.infoBox}>
          SMS messages exceeding 160 characters are split into 153 character segments and sent
          individually.
          <br />
          {`Tags can be used in the format {{ FULLNAME }}. If using tags, the actual SMS length will
          vary from the length displayed.`}
        </div>
      </Rollover>
    </>
  );
};

export default TemplateEditorFormContainer;
