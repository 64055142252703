import React from "react";
import {
  Button,
  PanelDialog,
  PanelHeading,
  PanelRow,
  PanelSpacer,
  PanelActions,
  BoldText,
} from "@backslashbuild/pp-ui";
import { usePost } from "./api";
import { publish } from "../../pubSub";
import { useHistory } from "react-router-dom";

function RemovePayslipButton({ id }) {
  const history = useHistory();

  // you have to query to see if there are any currently staged pay runs and then disable the button.
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const removePayslip = usePost("payslips/remove");

  async function handleSubmit() {
    try {
      const response = await removePayslip({ payslipId: id });

      if (response.isSuccess) {
        publish("notification", "Payslip Removed");
      } else {
        publish(
          "notification",
          <>
            Unable to remove payslip
            <ul>
              {response.errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </>
        );
      }
    } catch (e) {
      console.error(e, "error removing payslip");
      publish("notification", "A network error occured.");
    }
  }

  return (
    <>
      <Button variant="secondary" large onClick={() => setDialogOpen(true)}>
        Remove Payslip
      </Button>
      <PanelDialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <PanelHeading>Cancel Payslip</PanelHeading>
        <PanelSpacer large />
        <PanelRow>
          <BoldText>
            Are you sure you want to remove this payslip?
            <br />
            It will be cancelled on the next pay run and the associated requests-for-processing will
            be unlocked.
          </BoldText>
        </PanelRow>
        <PanelSpacer large />
        <PanelActions>
          <Button variant="secondary" onClick={() => setDialogOpen(false)}>
            No, Don't Remove
          </Button>
          <Button onClick={handleSubmit}>Yes, Remove Payslip</Button>
        </PanelActions>
        <PanelSpacer large />
      </PanelDialog>
    </>
  );
}

export default RemovePayslipButton;
