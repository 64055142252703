import React from "react";
import {
  Content,
  PanelColumnSet,
  PanelColumn,
  PageHeadingArea,
  PageTitle,
  PanelColumnSpacer,
  Button,
  PageHeadingAreaSpacer,
} from "@backslashbuild/pp-ui";
import CreateIllustrationForm from "../modules/payroll/CreateIllustrationForm";
import { useParams, Link as RouterLink } from "react-router-dom";
import IllustrationView from "../modules/payroll/IllustrationView";

function Illustration() {
  const { id } = useParams();

  return (
    <Content scrollable>
      <PageHeadingArea>
        <PageTitle>Illustration {id}</PageTitle>
        <PageHeadingAreaSpacer grow />

        <Button
          large
          component="a"
          target="_blank"
          href={`mailto:steven+illustrationreports@backslash.build?subject=${encodeURIComponent(
            "Illustration Report"
          )}&body=${encodeURIComponent("Report for illustration: ")}${encodeURIComponent(
            window.location.toString()
          )}${encodeURIComponent("\n\nPlease describe the issue here:")}`}
        >
          Report this illustration
        </Button>
        <PageHeadingAreaSpacer />

        <Button large component={RouterLink} to={`/illustration-redirect/${id}`} target="_blank">
          Open File
        </Button>
      </PageHeadingArea>
      <PanelColumnSet>
        <PanelColumn>
          <CreateIllustrationForm />
        </PanelColumn>
        <PanelColumnSpacer />
        <IllustrationView id={id} />
      </PanelColumnSet>
    </Content>
  );
}

export default Illustration;
