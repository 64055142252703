import {
  PageLoading,
  Panel,
  PanelHeading,
  PanelSpacer,
  PanelRow,
  BoldText,
  Link,
} from "@backslashbuild/pp-ui";
import React from "react";
import { useGet } from "./api";
import { isTextNullOrEmpty } from "./utils";
import { Link as RouterLink } from "react-router-dom";

export default function AgencyContactsPanel({ agencyId }) {
  const { data, loading, error, refetch } = useGet(`contacts?agencyId=${agencyId}`);

  if (loading) return <PageLoading />;

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    return (
      <>
        {data.value.items.map((contact) => (
          <PanelRow>
            {contact?.role && <BoldText>{contact?.role}</BoldText>}
            <div>{`${contact?.name}`}</div>
            <Link href={`mailto:${contact?.email}`}>{contact?.email}</Link>
            {contact?.mobileNumber && <div>{contact?.mobileNumber}</div>}
            {contact?.telephoneNumber && <div>{contact?.telephoneNumber}</div>}
            {contact?.faxNumber && <div>Fax: {contact?.faxNumber}</div>}
          </PanelRow>
        ))}
      </>
    );
  }

  return (
    <Panel>
      <PanelHeading
        rightAction={
          <Link component={RouterLink} to={`/create-agency-contact/${agencyId}`}>
            Add Contact
          </Link>
        }
      >
        Contacts
      </PanelHeading>
      <PanelSpacer />
      {handleRender()}
      <PanelSpacer />
    </Panel>
  );
}
