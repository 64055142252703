import {
  PageLoading,
  Panel,
  PanelHeading,
  PanelSpacer,
  PanelRow,
  Label,
  BoldText,
  Link,
  PanelDialog,
  StatusIndicator,
} from "@backslashbuild/pp-ui";
import React from "react";
import { useGet } from "./api";
import { isTextNullOrEmpty } from "./utils";
import { subscribe, unsubscribe } from "./pubSub";
import AgencyGuidelineForm from "./AgencyGuidelineForm";

export default function AgencyGuidelinePanel({ id }) {
  const [editGuidelines, setEditGuidelines] = React.useState(null);
  const { data, loading, error, refetch } = useGet(id);

  React.useEffect(() => {
    subscribe("agency.guideline.updated", refetch);
    return () => unsubscribe("agency.guideline.updated", refetch);
  }, [id]);

  function handlePanelRender() {
    if (loading) return <PageLoading />;
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    return (
      <>
        <PanelRow>
          <Label label="Allow PEO">
            <BoldText>
              <StatusIndicator value={data?.value?.isAllowPEO} />
            </BoldText>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Allow Umbrella">
            <BoldText>
              <StatusIndicator value={data?.value?.isAllowUmbrella} />
            </BoldText>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Allow PAYE">
            <BoldText>
              <StatusIndicator value={data?.value?.isAllowPAYE} />
            </BoldText>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Allow PS Pro">
            <BoldText>
              <StatusIndicator value={data?.value?.isAllowPSPro} />
            </BoldText>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Allow CIS">
            <BoldText>
              <StatusIndicator value={data?.value?.isAllowCIS} />
            </BoldText>
          </Label>
        </PanelRow>
      </>
    );
  }

  return (
    <>
      <Panel>
        <PanelHeading rightAction={<Link onClick={() => setEditGuidelines(id)}>Edit</Link>}>
          Product Guidelines
        </PanelHeading>
        <PanelSpacer />
        {handlePanelRender()}
        <PanelSpacer />
      </Panel>
      <PanelDialog open={editGuidelines}>
        <PanelHeading>Edit Product Guidelines</PanelHeading>
        {editGuidelines && (
          <AgencyGuidelineForm
            id={editGuidelines}
            onClose={() => {
              setEditGuidelines(null);
            }}
          />
        )}
      </PanelDialog>
    </>
  );
}
