import {
  PageLoading,
  Panel,
  PanelHeading,
  PanelSpacer,
  PanelRow,
  BoldText,
  Label,
  Link,
  PanelSubheading,
  HorizontalRule,
} from "@backslashbuild/pp-ui";
import React from "react";
import { useGet, useUpdateField } from "./api";
import { isTextNullOrEmpty, utcToLocalText } from "./utils";
import { subscribe, unsubscribe } from "./pubSub";
import { useGet as useGetContractsApi } from "../contracts/api";
import getApiUrl from "../../getApiUrl";

const Contract = ({ contract }) => {
  const { data, loading, error } = useGetContractsApi(
    `contracts?contractorContractId=${contract?.id}`
  );

  const updateField = useUpdateField({
    id: contract?.id,
    formName: "contractor-contract",
    subFormName: "contractorContract",
  });

  function handleRenderContracts() {
    if (loading)
      return (
        <PanelRow>
          <div>Loading contract documents...</div>
        </PanelRow>
      );

    if (error)
      return (
        <PanelRow>
          <div>Error retrieving contract documents.</div>
        </PanelRow>
      );

    return (
      <>
        <PanelSubheading>DOCUMENTS</PanelSubheading>
        {data?.value?.items?.map((item) => (
          <PanelRow>
            <Label label="Date created">
              <BoldText>
                <Link
                  onClick={() => {
                    window.open(
                      `${getApiUrl()}/contracts/contract/${item?.id}/render`,
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }}
                >
                  {utcToLocalText(item.dateCreated, "dd/MM/yyyy hh:mm")}
                </Link>
              </BoldText>
            </Label>
          </PanelRow>
        ))}
      </>
    );
  }

  return (
    <>
      <PanelRow>
        <Label label="Contract Type">
          <BoldText>
            {/* <Link
              onClick={() => {
                window.open(
                  `${getApiUrl()}/contracts/contract/${contract?.id}/render`,
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
            > */}
            {contract?.name}
            {/* </Link> */}
          </BoldText>
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Agency">
          <BoldText>{contract?.agencyName}</BoldText>
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Date Created">
          <BoldText>{utcToLocalText(contract?.dateCreated, "dd/MM/yyyy hh:mm")}</BoldText>
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Date Accepted">
          <BoldText>
            {contract?.dateAccepted ? (
              <>{utcToLocalText(contract.dateAccepted, "dd/MM/yyyy hh:mm")}</>
            ) : (
              <Link
                onClick={() => {
                  updateField({ isAccepted: "true" });
                }}
              >
                Accept Contract
              </Link>
            )}
          </BoldText>
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Date Cancelled">
          <BoldText>
            {contract?.dateCancelled ? (
              <>{utcToLocalText(contract.dateCancelled, "dd/MM/yyyy hh:mm")}</>
            ) : (
              <Link
                onClick={() => {
                  updateField({ isCancelled: "true" });
                }}
              >
                Cancel Contract
              </Link>
            )}
          </BoldText>
        </Label>
      </PanelRow>
      {handleRenderContracts()}
    </>
  );
};

export default function ContractorContractsPanel({ contractorId }) {
  const { data, loading, error, refetch } = useGet(
    `contractor-contracts?contractorId=${contractorId}`
  );

  React.useEffect(() => {
    subscribe("contractor.contractorContract.updated", refetch);
    return () => unsubscribe("contractor.contractorContract.updated", refetch);
  }, [contractorId]);

  if (loading) return <PageLoading />;

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    return (
      <>
        {data.value.items.map((contract, index) => (
          <>
            <Contract contract={contract} key={index} />
            {index < data?.value?.items?.length - 1 && <HorizontalRule />}
          </>
        ))}
      </>
    );
  }

  return (
    <Panel>
      <PanelHeading>Contracts</PanelHeading>
      <PanelSpacer />
      {handleRender()}
      <PanelSpacer />
    </Panel>
  );
}
