import React from "react";
import { useUnlockBACSRun } from "./api";
import { publish } from "./pubSub";

import { PanelDialog, PanelActions, Button, PanelRow, PanelHeading } from "@backslashbuild/pp-ui";

export default function UnlockDialog({ open, onClose, bacsRunId }) {
  const post = useUnlockBACSRun();

  async function handleConfirm() {
    const res = await post({ bacsRunId });

    if (res.isSuccess) {
      publish("notification", "Success! The bacs run has been unlocked.");
      onClose();
    } else {
      publish(
        "notification",
        <div>
          Attempt Failed
          <ul>
            {res?.errors?.map((error) => (
              <li>{error}</li>
            ))}
          </ul>
        </div>
      );
    }
  }

  return (
    <PanelDialog open={open} onClose={onClose}>
      <PanelHeading>Unlock BACS Run</PanelHeading>
      <PanelRow>
        <p>
          Click on 'Confirm' to unlock this BACS Run. While unlocked, payslips can be added or
          removed from the BACS Run. A BACS Run can be unlocked as long as it has not been already
          marked as paid or closed.
        </p>
      </PanelRow>
      <PanelActions>
        <Button variant="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleConfirm();
            onClose();
          }}
        >
          Confirm
        </Button>
      </PanelActions>
    </PanelDialog>
  );
}
