import React from "react";
import { useGetTemplates } from "./api";
import {
  Button,
  PanelDialog,
  PanelHeading,
  PanelRow,
  BoldText,
  PanelActions,
  PanelSpacer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@backslashbuild/pp-ui";
import { DateTime } from "luxon";

function TemplateEditor({
  agencyId,
  numberOfHeaderRows,
  columnTypes,
  setNumberOfHeaderRows,
  setColumnTypes,
}) {
  const templateQuery = useGetTemplates({ agencyId });
  const [open, setOpen] = React.useState(true);

  async function deleteTemplate(id) {
    alert("delete template.");
  }

  if (templateQuery.loading) {
    return (
      <Button large disabled>
        Loading templates...
      </Button>
    );
  }

  const activeTemplates = templateQuery.data?.value?.items?.filter((t) => t.isActive);
  const inactiveTemplates = templateQuery.data?.value?.items?.filter((t) => !t.isActive);

  return (
    <>
      <PanelDialog open={open} onClose={() => setOpen(false)} width={800}>
        <PanelHeading>Templates</PanelHeading>
        <PanelSpacer large />

        <PanelRow>
          <BoldText>
            There are {activeTemplates?.length ?? 0} active templates available for this agency.
          </BoldText>
        </PanelRow>

        <PanelSpacer large />

        <Table>
          <TableBody>
            {activeTemplates?.map((template) => (
              <TemplateRow
                template={template}
                onApply={() => {
                  setNumberOfHeaderRows(template.numberOfHeaderRows);
                  setColumnTypes(template.columnTypeIds);
                }}
                onDelete={() => deleteTemplate(template.id)}
              />
            ))}
          </TableBody>
        </Table>

        <PanelSpacer large />
        <PanelSpacer large />

        <PanelRow>
          <BoldText>
            There are {inactiveTemplates?.length ?? 0} inactive templates available for this agency.
          </BoldText>
        </PanelRow>
        <PanelSpacer large />

        <Table>
          <TableBody>
            {inactiveTemplates?.map((template) => (
              <TemplateRow
                template={template}
                onApply={() => {
                  setNumberOfHeaderRows(template.numberOfHeaderRows);
                  setColumnTypes(template.columnTypeIds);
                }}
                onDelete={() => deleteTemplate(template.id)}
              />
            ))}
          </TableBody>
        </Table>

        <PanelSpacer large />

        <PanelActions>
          <Button onClick={() => setOpen(false)}>OK</Button>
        </PanelActions>
        <PanelSpacer large />
      </PanelDialog>
      <Button large onClick={() => setOpen(true)}>
        Pick template
      </Button>
    </>
  );
}

function TemplateRow({ template, onApply, onDelete }) {
  return (
    <TableRow disableHover>
      <TableCell>
        <BoldText>{template.name || "(untitiled)"}</BoldText>
      </TableCell>
      <TableCell>
        <small>(created {DateTime.fromISO(template.dateCreated).toFormat("dd/MM/yyyy")}</small>)
      </TableCell>

      <TableCell>
        <Button onClick={onApply}>Apply</Button>
      </TableCell>
      <TableCell>
        <Button variant="secondary" onClick={onDelete}>
          Delete
        </Button>
      </TableCell>
    </TableRow>
  );
}

export default TemplateEditor;
