import React from "react";
import { useLocation, useHistory, Link as RouterLink } from "react-router-dom";
import {
  TableRow,
  Table,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableFooter,
  TableBody,
  BoldText,
  Link,
} from "@backslashbuild/pp-ui";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";
import { useGet } from "./api";
import { formatCurrency } from "../../utils";

const c = {
  id: 50,
  taxWeek: 100,
  reference: 100,
  schedule: 100,
  employment: 100,
  type: 100,
  company: 120,
  adjustmentType: "taxable_pay",
  payRun: 100,
  payslip: 100,
  isLocked: false,
  isFinalised: false,
  isPartialResolutionAllowed: false,
  finalisedByPayRunId: null,
  finalisedOnPayslipId: null,
  requestedAmount: 200,
  hours: 80,
  finalisedAmount: 200,
  description: 300,
  dateCreated: "2020-08-06T11:32:59.398244",
};
const totalWidth = Object.keys(c).reduce((total, key) => (total += c[key]), 0);

function PureTable({ data, queryId }) {
  return React.useMemo(() =>
    data.value.items.map(
      (fps) => (
        <TableRow component={RouterLink} to={`/fps/${fps.id}`}>
          <TableCell width={c.id}>{fps.id}</TableCell>
          <TableCell width={c.company}>{fps.company?.name}</TableCell>
          <TableCell width={c.employment}>-</TableCell>
        </TableRow>
      ),
      [queryId]
    )
  );
}

function TableSkeleton() {
  return (
    <TableRow>
      <TableCell width={c.id}>-</TableCell>
      <TableCell width={c.company}>-</TableCell>
      <TableCell width={c.taxWeek}>-</TableCell>
    </TableRow>
  );
}

function RFPsTable() {
  const { data, loading, error, queryId } = useGet("fps");

  const isLoaded = Boolean(data?.value);

  return (
    <Table standardTable width={totalWidth}>
      <TableHeader>
        <TableHeaderColumn width={c.id}>ID</TableHeaderColumn>
        <TableHeaderColumn width={c.company}>Company</TableHeaderColumn>
        <TableHeaderColumn width={c.taxWeek}>Week</TableHeaderColumn>
      </TableHeader>
      <TableBody fullPageTable={true} footerHeight={TableFooter.height}>
        {isLoaded ? (
          <PureTable data={data} queryId={queryId} />
        ) : data?.errors ? (
          data?.errors.map((error) => <div>{error}</div>)
        ) : (
          <TableSkeleton />
        )}
      </TableBody>
      <TableFooter>
        {loading && <BoldText>Loading...</BoldText>}
        {error && <BoldText>A network error occured.</BoldText>}
        <QueryPaginator
          itemsTotal={data?.value?.itemsTotal}
          itemsRetrieved={data?.value?.itemsRetrieved}
        />
      </TableFooter>
    </Table>
  );
}

export default RFPsTable;
