import {
  PageLoading,
  Panel,
  PanelHeading,
  PanelSpacer,
  PanelRow,
  PanelSubheading,
  HorizontalRule,
  Link,
} from "@backslashbuild/pp-ui";
import { BoldText, Label } from "@backslashbuild/pp-ui/dist/index.es";
import React from "react";
import { useGetFields, useUpdateField, useGet } from "./api";
import { useGet as useGetContractors } from "../contractors/api";
import FormField from "./FormField";
import { buildQuery, isTextNullOrEmpty } from "./utils";

export default function ContractForm({ id }) {
  const [query, setQuery] = React.useState(buildQuery({ pattern: "a" }));
  const { data, loading, error } = useGetFields({ id, formName: "contract", queryParams: query });
  const {
    data: dataOccupations,
    loading: loadingOccupations,
    error: errorOccupations,
  } = useGetContractors("occupations");
  const { data: dataRegistration, loading: loadingRegistration, error: errorRegistration } = useGet(
    `registration/${id}`,
    `registration/${id}`
  );

  const updateField = useUpdateField({ id, formName: "" });

  if (loading) return <PageLoading />;

  function getOnSaveHandler(key) {
    return (value) => {
      updateField({ key, value });
    };
  }

  function getOnClickHandler(key, value) {
    return () => {
      updateField({ key, value });
    };
  }

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    let calculateRates = data?.value?.filter((x) => x.key == "IsUseExtraContractDetails")[0]?.value;

    return data.value.map((field) => (
      <>
        {field?.key == "IsUseExtraContractDetails" ? (
          <>
            <PanelRow>
              <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                <Link
                  onClick={getOnClickHandler(
                    field?.key,
                    calculateRates == "False" ? "True" : "False"
                  )}
                >
                  <BoldText>CALCULATE RATES</BoldText>
                </Link>
              </div>
            </PanelRow>
          </>
        ) : (
          <>
            {field?.displayName == "Starting Rate" && <HorizontalRule />}
            <FormField
              key={field.key}
              field={field}
              onSave={getOnSaveHandler(field.key)}
              onQuery={getOnQueryHandler}
            />
            {field?.displayName == "Adjust Rates" && <HorizontalRule />}
          </>
        )}
      </>
    ));
  }

  function getOnQueryHandler(value) {
    const dict = isTextNullOrEmpty(value) ? { pattern: "a" } : { pattern: value };
    const query = buildQuery(dict);
    setQuery(query);
  }

  function handleRenderQuickPicks() {
    return (
      <PanelRow>
        {dataRegistration?.value?.isImported === false &&
          dataOccupations?.value?.items
            ?.filter(
              (occupation) => occupation?.isQuicklink === true && occupation?.shortName?.length > 0
            )
            .sort()
            .map((item, index) => (
              <>
                {index > 0 && ` | `}
                <Link onClick={getOnClickHandler("OccupationId", item?.id)}>{item?.shortName}</Link>
              </>
            ))}
      </PanelRow>
    );
  }

  return (
    <Panel>
      <PanelHeading>Contract Details</PanelHeading>
      <PanelSpacer />
      {handleRenderQuickPicks()}
      {handleRender()}
      <PanelSpacer />
    </Panel>
  );
}
