import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  TableRow,
  Table,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableFooter,
  TableBody,
  BoldText,
  Link,
} from "@backslashbuild/pp-ui";
import QueryPaginator from "../../containers/QueryPaginator";
import { useGetStatPayGroups } from "./api";
import { formatCurrency, utcToLocalText } from "../../utils";
import ContractorProductContractorNameRollOver from "../contractors/ContractorProductContractorNameRollOver";

const c = {
  employment: 200,
  dateCreated: 200,
  startDate: 200,
  endDate: 200,
  rate: 200,
  notes: 200,
  type: 200,
};
const totalWidth = Object.keys(c).reduce((total, key) => (total += c[key]), 0);

function PureTable({ data, queryId }) {
  return React.useMemo(() =>
    data.value.items.map(
      (item) => (
        <TableRow component={RouterLink} to={`/statutory-pay-group/${item.id}`}>
          <TableCell width={c.employment}>
            <ContractorProductContractorNameRollOver contractorProductId={item?.employmentId} />
          </TableCell>
          <TableCell width={c.type}>{item?.type?.name}</TableCell>
          <TableCell width={c.dateCreated}>{utcToLocalText(item.dateCreated)}</TableCell>
          <TableCell width={c.startDate}>{utcToLocalText(item.startDate)}</TableCell>
          <TableCell width={c.endDate}>{utcToLocalText(item.endDate)}</TableCell>
          <TableCell width={c.rate}>{formatCurrency(item.rate)}</TableCell>
          <TableCell width={c.notes}>{item.notes}</TableCell>
        </TableRow>
      ),
      [queryId]
    )
  );
}

function TableSkeleton() {
  return (
    <TableRow>
      <TableCell width={c.employment}>-</TableCell>
      <TableCell width={c.type}>-</TableCell>
      <TableCell width={c.dateCreated}>-</TableCell>
      <TableCell width={c.startDate}>-</TableCell>
      <TableCell width={c.endDate}>-</TableCell>
      <TableCell width={c.rate}>-</TableCell>
      <TableCell width={c.notes}>-</TableCell>
    </TableRow>
  );
}

function EmploymentStatutoryPayGroupsTable({ contractorProductId }) {
  const { data, loading, error, queryId } = useGetStatPayGroups({ contractorProductId });

  const isLoaded = Boolean(data?.value);

  return (
    <Table standardTable width={totalWidth}>
      <TableHeader>
        <TableHeaderColumn width={c.employment}>Employment</TableHeaderColumn>
        <TableHeaderColumn width={c.type}>Type</TableHeaderColumn>
        <TableHeaderColumn width={c.dateCreated}>Date Created</TableHeaderColumn>
        <TableHeaderColumn width={c.startDate}>Start Date</TableHeaderColumn>
        <TableHeaderColumn width={c.endDate}>End Date</TableHeaderColumn>
        <TableHeaderColumn width={c.rate}>Rate</TableHeaderColumn>
        <TableHeaderColumn width={c.notes}>Notes</TableHeaderColumn>
      </TableHeader>
      <TableBody>
        {isLoaded ? (
          <PureTable data={data} queryId={queryId} />
        ) : data?.errors ? (
          data?.errors.map((error) => <div>{error}</div>)
        ) : (
          <TableSkeleton />
        )}
      </TableBody>
      <TableFooter>
        {loading && <BoldText>Loading...</BoldText>}
        {error && <BoldText>A network error occured.</BoldText>}
        <QueryPaginator
          itemsTotal={data?.value?.itemsTotal}
          itemsRetrieved={data?.value?.itemsRetrieved}
        />
      </TableFooter>
    </Table>
  );
}

export default EmploymentStatutoryPayGroupsTable;
