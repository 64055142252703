import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { makeStateParamCorrectionForUrl } from "./utils";

import {
  Panel,
  PanelRow,
  PanelSpacer,
  Label,
  PanelHeading,
  Button,
  PanelActions,
  TextInput,
  Dropdown,
  DropdownOption,
} from "@backslashbuild/pp-ui";

const operatorOptions = [
  { displayName: "<", value: "1" },
  { displayName: "<=", value: "2" },
  { displayName: "=", value: "0" },
  { displayName: ">=", value: "3" },
  { displayName: ">", value: "4" },
];

export default function BACSRowsFilter() {
  const history = useHistory();
  const location = useLocation();
  const getStateFromSearch = (s) => queryString.parse(s);
  const [state, setState] = React.useState(getStateFromSearch(location.search));

  function handleSubmit() {
    history.replace({
      search: queryString.stringify(state),
    });
  }

  return (
    <Panel>
      <PanelHeading>Filters</PanelHeading>
      <PanelSpacer />
      <PanelRow>
        <Label label="Surname"></Label>
        <TextInput
          value={state?.surname}
          placeholder="Dorsey"
          onChange={(e) =>
            setState({ ...state, surname: makeStateParamCorrectionForUrl(e.target.value) })
          }
        />
      </PanelRow>
      <PanelRow>
        <Label label="Amount"></Label>
        <TextInput
          value={state?.amount}
          placeholder="1750"
          onChange={(e) =>
            setState({ ...state, amount: makeStateParamCorrectionForUrl(e.target.value) })
          }
        />
      </PanelRow>
      <PanelRow>
        <Label label="Amount Operator"></Label>
        <Dropdown
          value={state?.amountOperator ?? "0"}
          onChange={(e) =>
            setState({ ...state, amountOperator: makeStateParamCorrectionForUrl(e.target.value) })
          }
        >
          {operatorOptions.map((option) => (
            <DropdownOption value={option.value}>{option.displayName} </DropdownOption>
          ))}
        </Dropdown>
      </PanelRow>
      <PanelSpacer />
      <PanelSpacer />
      <PanelSpacer />
      <PanelActions>
        <Button onClick={handleSubmit}>Apply filters</Button>
      </PanelActions>
      <PanelSpacer large />
    </Panel>
  );
}
