import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  TableRow,
  Table,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableFooter,
  TableBody,
  BoldText,
  Pill,
  StatusIndicator,
} from "@backslashbuild/pp-ui";
import { Link as RouterLink } from "react-router-dom";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";
import { useGetScheduleRows } from "./api";
import { DateTime } from "luxon";
import { MatchRowEditor } from "./MatchRowEditor";
import { formatCurrency } from "../../utils";
import ContractorProductName from "./ContractorProductName";

const c = {
  contractorProduct: "20%",
  timesheetDate: "20%",
  item: "20%",
  description: "20%",
  subtotal: "20%",
};

const totalWidth = Object.keys(c).reduce((total, key) => (total += c[key]), 0);

const getBackground = ({ schedule }) => {
  if (schedule?.isClosed) {
    return "rgb(90, 199, 117)";
  }

  if (schedule?.isLocked) {
    return "rgb(255, 178, 0)";
  }

  return "transparent";
};

function PureTable({ data, queryId }) {
  return React.useMemo(() =>
    data.value.items.map(
      (row) => (
        <TableRow
          key={row.id}
          component={RouterLink}
          to={`/schedule-c/${row.scheduleId}`}
          backgroundColor={getBackground({ row })}
        >
          <TableCell width={c.contractorProduct}>
            {row?.matchedContractorProductId && (
              <ContractorProductName id={row?.matchedContractorProductId} productOnly />
            )}
          </TableCell>
          <TableCell width={c.timesheetDate}>
            {row.timesheetDate ? DateTime.fromISO(row.timesheetDate).toFormat("yyyy-MM-dd") : "-"}
          </TableCell>
          <TableCell width={c.item}>{row.item}</TableCell>
          <TableCell width={c.description}>{row.description}</TableCell>
          <TableCell width={c.subTotal}>{formatCurrency(row.subTotal)}</TableCell>
        </TableRow>
      ),
      [queryId]
    )
  );
}

function TableSkeleton() {
  return (
    <TableRow>
      <TableCell width={c.contractorProduct}>-</TableCell>
      <TableCell width={c.timesheetDate}>-</TableCell>
      <TableCell width={c.item}>-</TableCell>
      <TableCell width={c.description}>-</TableCell>
      <TableCell width={c.subtotal}>-</TableCell>
    </TableRow>
  );
}

function TimesheetsTable() {
  const location = useLocation();
  const search = usePagination(location.search);
  const { data, loading, error, queryId } = useGetScheduleRows(search);

  const isLoaded = Boolean(data?.value);

  return (
    <Table standardTable width={totalWidth}>
      <TableHeader>
        <TableHeaderColumn width={c.contractorProduct}>Product</TableHeaderColumn>
        <TableHeaderColumn width={c.timesheetDate}>T/S Date</TableHeaderColumn>
        <TableHeaderColumn width={c.item}>Item</TableHeaderColumn>
        <TableHeaderColumn width={c.description}>Description</TableHeaderColumn>
        <TableHeaderColumn width={c.subtotal}>Subtotal</TableHeaderColumn>
      </TableHeader>
      <TableBody fullPageTable={true} footerHeight={TableFooter.height}>
        {isLoaded ? (
          <PureTable data={data} queryId={queryId} />
        ) : data?.errors ? (
          data?.errors.map((error) => <div>{error}</div>)
        ) : (
          <TableSkeleton />
        )}
      </TableBody>
      <TableFooter>
        {loading && <BoldText>Loading...</BoldText>}
        {error && <BoldText>A network error occured.</BoldText>}
        <QueryPaginator
          itemsTotal={data?.value?.itemsTotal}
          itemsRetrieved={data?.value?.itemsRetrieved}
        />
      </TableFooter>
    </Table>
  );
}

export default TimesheetsTable;
