import { Rollover } from "@backslashbuild/pp-ui";
import React from "react";
import { useGet } from "./api";
import { isTextNullOrEmpty } from "./utils";
import ContractorBusinessCard from "./ContractorBusinessCard";

function ContractorName({ id }) {
  const { data, loading, error, refetch } = useGet(id);

  if (loading) return <p>Loading...</p>;

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data?.isSuccess) {
      return data?.errors?.map((error) => <div>{error}</div>);
    }

    return <div>{`${data?.value?.title} ${data?.value?.firstName} ${data?.value?.surname}`}</div>;
  }

  return <>{handleRender()}</>;
}

export default function ContractorProductContractorNameRollOver({ contractorProductId, title }) {
  const { data, loading, error } = useGet(`contractor-products/${contractorProductId}`);

  if (loading) return <div>Loading...</div>;

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data?.isSuccess) {
      return data?.errors?.map((error) => <div>{error}</div>);
    }

    return (
      <Rollover
        renderAnchor={() => (
          <div>
            <ContractorName id={data?.value?.contractorUserId} />
          </div>
        )}
      >
        <ContractorBusinessCard id={data?.value?.contractorUserId} />
      </Rollover>
    );
  }

  return <>{handleRender()}</>;
}
