import React from "react";

import queryString from "query-string";
import { useGetDpsSyncs } from "./api";
import { isTextNullOrEmpty, utcToLocalText } from "./utils";
import ViewCombinedErrors from "./ViewCombinedErrors";

import {
  Panel,
  TableRow,
  Table,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableFooter,
  PageLoading,
  BoldText,
} from "@backslashbuild/pp-ui";

export default function SyncsTable() {
  const [params, setParams] = React.useState({ skip: null, take: 10 });
  const query = `?${queryString.stringify({ ...params })}`;
  const { data, loading, error, queryId, refetch } = useGetDpsSyncs(query);

  if (loading) return <PageLoading />;
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  function PureTable({ data, queryId }) {
    return React.useMemo(() =>
      data?.value?.items?.map(
        (item) => (
          <TableRow>
            <TableCell>
              <small>{utcToLocalText(item?.dateCreated, "dd/MM/yyyy HH:mm")}</small>
            </TableCell>
            <TableCell>
              <small>{item?.companyName}</small>
            </TableCell>
            <TableCell>
              <small>{parseErrors(item)}</small>
            </TableCell>
          </TableRow>
        ),
        [queryId]
      )
    );
  }

  return (
    <Panel>
      <Table>
        <TableHeader>
          <TableHeaderColumn>Date Created</TableHeaderColumn>
          <TableHeaderColumn>Company</TableHeaderColumn>
          <TableHeaderColumn>Errors</TableHeaderColumn>
        </TableHeader>
        <TableBody footerHeight={TableFooter.height}>
          <PureTable data={data} queryId={queryId} />
        </TableBody>
        <TableFooter>
          <BoldText>{`Showing ${data?.value?.itemsRetrieved} of ${data?.value?.itemsTotal}`}</BoldText>
        </TableFooter>
      </Table>
    </Panel>
  );
}

function parseErrors(item) {
  if (item?.hasErrors === true) return <ViewCombinedErrors syncId={item?.id} />;
  return <></>;
}
