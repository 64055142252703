import React from "react";
import {
  PanelDialog,
  PanelHeading,
  PanelActions,
  PanelRow,
  Button,
  Label,
  TextInput,
} from "@backslashbuild/pp-ui";
import { useUploadEnrolmentErrorLog, invalidateEnrolmentExports } from "./api";
import { publish } from "../../pubSub";

export default function UploadEnrolmentsErrorLogDialog({ enrolmentExport, disabled }) {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [file, setFile] = React.useState(null);

  const upload = useUploadEnrolmentErrorLog(enrolmentExport.id);

  function handleCancel() {
    setDialogOpen(false);
  }

  async function handleSubmit() {
    publish("notification", "Uploading file...");

    const result = await upload(file);
    publish("notification", "Upload complete");

    invalidateEnrolmentExports();
    handleCancel();
  }

  return (
    <>
      <Button small onClick={() => setDialogOpen(true)} disabled={disabled}>
        Submit Enrolment Error Log
      </Button>

      <PanelDialog onClose={handleCancel} open={dialogOpen}>
        <PanelHeading>Upload Enrolment Error Log File</PanelHeading>
        <PanelRow>
          <Label label="Choose file">
            <TextInput type="file" onChange={(e) => setFile(e.target.files[0])} />
          </Label>
        </PanelRow>
        <PanelActions>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="primary" disabled={!file} onClick={handleSubmit}>
            Submit
          </Button>
        </PanelActions>
      </PanelDialog>
    </>
  );
}
