import React from "react";
import {
  Button,
  PanelActions,
  PanelDialog,
  PanelHeading,
  PanelRow,
} from "@backslashbuild/pp-ui";
import { usePost } from "./api";
import { publish } from "../../pubSub";

export default function ResetAgencyIndexButton() {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const reset = usePost("agencies/index/reset");

  function handleClose() {
    setDialogOpen(false);
  }

  async function handleResetIndex() {
    publish("notification", "Resetting the agency index...");

    handleClose();

    const result = await reset();

    if (!result.isSuccess) {
      var errors = result?.errors;
      publish(
        "notification",
        <ul>
          Unable to reset agency index. Errors:
          {errors?.length > 0 &&
            errors?.map((error) => {
              return <li>{error}</li>;
            })}
        </ul>
      );
      return;
    }

    if (result?.isSuccess) publish("notification", "Reset completed.");
  }

  return (
    <>
      <Button small onClick={() => setDialogOpen(true)} style={{ width: 200 }}>
        Reset Agencies Index
      </Button>
      <PanelDialog onClose={handleClose} open={dialogOpen}>
        <PanelHeading>Reset Agencies Index</PanelHeading>
        <PanelRow>
          <div>Are you sure you want to reset the agencies index?</div>
        </PanelRow>
        <PanelActions>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleResetIndex}>
            Reset
          </Button>
        </PanelActions>
      </PanelDialog>
    </>
  );
}
