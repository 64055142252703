import React, { useEffect, useState } from "react";
import { Panel, PageLoading, PanelActions, PanelHeading, Button } from "@backslashbuild/pp-ui";
import FormField from "./FormField";
import { makeStyles } from "@backslashbuild/pp-ui";
import { usePost, useGetNewFields } from "./api";
import { isTextNullOrEmpty } from "./utils";

export default function NewContractorAgencyForm({ contractorId, agencyId, onClose }) {
  const [payload, storePayload] = useState({});
  const { data, loading, error } = useGetNewFields({
    contractorId,
    formName: "contractor-emergencyPayment",
  });
  const createContractorEmergencyPayment = usePost("contractor-emergencyPayments/create");

  useEffect(() => {
    const setInitialValues = () => {
      if (data && data.value) {
        let initialPayload = {};
        data.value.forEach((field) => {
          if (field.schema.type === "dropdown") {
            initialPayload = { ...initialPayload, [field.key]: field.value };
          }
        });
        storePayload(initialPayload);
      }
    };
    setInitialValues();
  }, [data]);

  if (loading) return <PageLoading />;

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    return data.value.map((field) => (
      <FormField key={field.key} field={field} onSave={getOnSaveHandler(field.key)} />
    ));
  }

  function getOnSaveHandler(key) {
    return (value) => {
      storePayload({ ...payload, [key]: value });
    };
  }

  return (
    <Panel>
      <PanelHeading>Create emergency payment</PanelHeading>
      {handleRender()}
      <PanelActions>
        <Button variant="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            createContractorEmergencyPayment({
              contractorId,
              ...payload,
            });
            onClose();
          }}
        >
          Save
        </Button>
      </PanelActions>
    </Panel>
  );
}
