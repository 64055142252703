import {
  PageLoading,
  Panel,
  PanelHeading,
  PanelSpacer,
  PanelRow,
  Label,
  BoldText,
  Link,
  PanelDialog,
  StatusIndicator,
} from "@backslashbuild/pp-ui";
import React from "react";
import { useGet } from "./api";
import { isTextNullOrEmpty, utcToLocalText } from "./utils";
import ContractorStudentLoansForm from "./ContractorStudentLoansForm";
import { subscribe, unsubscribe } from "./pubSub";
import { useAddViewingToHistory } from "../history/api";

export default function ContractorPersonalDetailsPanel({ id }) {
  const [editStudentLoans, setEditStudentLoans] = React.useState(null);
  const { data, loading, error, refetch } = useGet(id);
  const addViewingToHistory = useAddViewingToHistory({ itemType: "contractor" });

  React.useEffect(() => {
    if (data != null && data.isSuccess) {
      recordViewing();
    }
  }, [id, data?.isSuccess]);

  React.useEffect(() => {
    subscribe("contractor.studentLoans.updated", refetch);
    return () => unsubscribe("contractor.studentLoans.updated", refetch);
  }, [id]);

  if (loading) return <PageLoading />;

  function recordViewing() {
    const fullName = `${data?.value?.firstName} ${data?.value?.middleNames} ${data?.value?.surname}`
      .trim()
      .replace("  ", " ");
    const url = `contractor/${id}`;

    const body = {
      itemId: id,
      type: "[contractor]",
      name: fullName,
      url: url,
    };
    addViewingToHistory(body);
  }

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    return (
      <>
        <PanelRow>
          <Label label="Student Loan Plan">
            <BoldText>
              {data?.value?.studentLoanPlan == "01"
                ? "1"
                : data?.value?.studentLoanPlan == "02"
                ? "2"
                : "None"}
            </BoldText>
          </Label>
        </PanelRow>
        {(data?.value?.studentLoanPlan == "01" || data?.value?.studentLoanPlan == "02") &&
          data?.value?.studentLoanStartDate && (
            <PanelRow>
              <Label label="SL Start Date">
                <BoldText>{utcToLocalText(data?.value?.studentLoanStartDate)}</BoldText>
              </Label>
            </PanelRow>
          )}
        {(data?.value?.studentLoanPlan == "01" || data?.value?.studentLoanPlan == "02") &&
          data?.value?.studentLoanEndDate && (
            <PanelRow>
              <Label label="SL End Date">
                <BoldText>{utcToLocalText(data?.value?.studentLoanEndDate)}</BoldText>
              </Label>
            </PanelRow>
          )}
        <PanelRow>
          <Label label="Postgraduate Loan Plan">
            <BoldText>
              {data?.value?.isPostgradLoan ? <StatusIndicator value={true} /> : "None"}
            </BoldText>
          </Label>
        </PanelRow>
        {data?.value?.isPostgradLoan && data?.value?.postGradLoanStartDate && (
          <PanelRow>
            <Label label="PGL Start Date">
              <BoldText>{utcToLocalText(data?.value?.postGradLoanStartDate)}</BoldText>
            </Label>
          </PanelRow>
        )}
        {data?.value?.isPostgradLoan && data?.value?.postGradLoanEndDate && (
          <PanelRow>
            <Label label="PGL End Date">
              <BoldText>{utcToLocalText(data?.value?.postGradLoanEndDate)}</BoldText>
            </Label>
          </PanelRow>
        )}
      </>
    );
  }

  return (
    <>
      <Panel>
        <PanelHeading rightAction={<Link onClick={() => setEditStudentLoans(id)}>Edit</Link>}>
          Student Loans
        </PanelHeading>
        <PanelSpacer />
        {handleRender()}
        <PanelSpacer />
      </Panel>
      <PanelDialog open={editStudentLoans}>
        <PanelHeading>Edit Student Loans</PanelHeading>
        {editStudentLoans && (
          <ContractorStudentLoansForm
            id={editStudentLoans}
            onClose={() => {
              setEditStudentLoans(null);
            }}
          />
        )}
      </PanelDialog>
    </>
  );
}
