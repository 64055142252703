import { PageTitle } from "@backslashbuild/pp-ui";
import React from "react";
import { useGet } from "./api";
import { subscribe, unsubscribe } from "./pubSub";
import { isTextNullOrEmpty } from "./utils";
import { Link as RouterLink } from "react-router-dom";

export default function ContractorPageTitle({ id, title }) {
  const { data, loading, error, refetch } = useGet(id);

  React.useEffect(() => {
    subscribe("contractor.personalDetails.updated", refetch);
    return () => unsubscribe("contractor.personalDetails.updated", refetch);
  }, [id]);

  if (loading) return <div>Loading...</div>;

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    return (
      <>
        <PageTitle>
          {`${data?.value?.title} ${data?.value?.firstName} ${data?.value?.surname}`}
          {data?.value?.alias?.length > 0 && ` (${data?.value?.alias})`}
          {title && ` - ${title}`}
        </PageTitle>
      </>
    );
  }

  return <>{handleRender()}</>;
}
