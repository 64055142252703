import React from "react";
import QueryFiltering from "../../containers/QueryFiltering";
import { PanelRow, Label, TextInput, RadioGroup, Radio } from "@backslashbuild/pp-ui";
import AgencyName from "../agencies/AgencyName";
import AuthorName from "./AuthorName";
import AgencyPicker from "../agencies/AgencyPicker";
import AuthorPicker from "./AuthorPicker";

function RegistrationsFiltering() {
  return (
    <QueryFiltering
      pathname=""
      searchStringKey="contractorName"
      getTagsFromState={(state) => {
        const tags = [];
        if (state.isLive === "yes") tags.push({ key: "live", value: "Only" });
        if (state.isLive === "no") tags.push({ key: "live", value: "Exclude" });
        if (state.isImported === "yes") tags.push({ key: "imported", value: "Only" });
        if (state.isImported === "no") tags.push({ key: "imported", value: "Exclude" });
        if (state.isDead === "yes") tags.push({ key: "dead", value: "Only" });
        if (state.isDead === "no") tags.push({ key: "dead", value: "Exclude" });
        if (state.agencyId) tags.push({ key: "agency", value: <AgencyName id={state.agencyId} /> });
        if (state.authorId) tags.push({ key: "author", value: <AuthorName id={state.authorId} /> });
        if (state.importAuthorId)
          tags.push({ key: "imported by", value: <AuthorName id={state.importAuthorId} /> });
        return tags;
      }}
      getNextStateFromDeleteTag={(state, key) => {
        if (key === "live") return { ...state, isLive: undefined };
        if (key === "imported") return { ...state, isImported: undefined };
        if (key === "dead") return { ...state, isDead: undefined };
        if (key === "agency") return { ...state, agencyId: undefined };
        if (key === "author") return { ...state, authorId: undefined };
        if (key === "imported by") return { ...state, importAuthorId: undefined };
        return state;
      }}
      renderForm={(state, setState) => (
        <>
          <PanelRow>
            <Label label="Search string">
              <TextInput
                value={state.search}
                onChange={(e) => setState({ ...state, search: e.target.value })}
              />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Agency">
              <AgencyPicker
                value={state.agencyId}
                onChange={(v) => setState({ ...state, agencyId: v })}
              />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Live Lead">
              <RadioGroup
                value={state.isLive}
                onChange={(e) => setState({ ...state, isLive: e.target.value })}
              >
                <Radio value="" label="Any" />
                <Radio value="yes" label="Yes" />
                <Radio value="no" label="No" />
              </RadioGroup>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Imported Lead">
              <RadioGroup
                value={state?.importAuthorId?.length > 0 ? "yes" : state.isImported}
                onChange={(e) =>
                  setState({
                    ...state,
                    isImported: e.target.value,
                    importAuthorId: e.target.value == "yes" ? state?.importAuthorId : null,
                  })
                }
              >
                <Radio value="" label="Any" disabled={state?.importAuthorId?.length > 0} />
                <Radio value="yes" label="Yes" disabled={state?.importAuthorId?.length > 0} />
                <Radio value="no" label="No" disabled={state?.importAuthorId?.length > 0} />
              </RadioGroup>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Dead Lead">
              <RadioGroup
                value={state.isDead}
                onChange={(e) => setState({ ...state, isDead: e.target.value })}
              >
                <Radio value="" label="Any" />
                <Radio value="yes" label="Yes" />
                <Radio value="no" label="No" />
              </RadioGroup>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Author">
              <AuthorPicker
                value={state.authorId}
                onChange={(e) => setState({ ...state, authorId: e.target.value })}
              />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Imported By">
              <AuthorPicker
                value={state.importAuthorId}
                onChange={(e) =>
                  setState({
                    ...state,
                    isImported: "yes",
                    importAuthorId: e.target.value,
                  })
                }
                disabled={state?.isImported == "no"}
              />
            </Label>
          </PanelRow>
        </>
      )}
    />
  );
}

export default RegistrationsFiltering;
