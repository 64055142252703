import React from "react";
import { Button, BoldText } from "@backslashbuild/pp-ui";
import { useLocation, useHistory, Redirect } from "react-router-dom";
import queryString from "query-string";
import Paginator from "./Paginator";

function parseNumber(numeric) {
  if (isNaN(numeric)) {
    return "-";
  } else {
    return numeric;
  }
}

function QueryPaginator({ itemsRetrieved, itemsTotal, spacers, defaultTake }) {
  const history = useHistory();
  const params = queryString.parse(useLocation().search);
  const skip = Number(params.skip || 0);
  const take = Number(params.take || defaultTake || 25);

  function prev() {
    history.push({ search: queryString.stringify({ ...params, skip: Math.max(0, skip - take) }) });
  }

  function next() {
    history.push({ search: queryString.stringify({ ...params, skip: skip + take, take }) });
  }

  function goto(newSkip) {
    history.push({
      search: queryString.stringify({ ...params, skip: Math.max(0, newSkip), take }),
    });
  }

  return (
    <Paginator
      skip={skip}
      take={take}
      onNext={next}
      onPrev={prev}
      onGoto={goto}
      itemsRetrieved={itemsRetrieved}
      itemsTotal={itemsTotal}
      spacers={spacers}
    />
  );
}

export function usePagination(search = "", defaultTake = 25) {
  const params = queryString.parse(useLocation().search);
  const take = Number(params.take || defaultTake);

  if (search) {
    return `?${queryString.stringify({ ...queryString.parse(search), take })}`;
  }

  return `?${queryString.stringify({ take })}`;
}

export default QueryPaginator;
