import React from "react";
import { usePayBACSRun, usePayPayslips } from "./api";
import { publish } from "./pubSub";

import {
  PanelDialog,
  PanelActions,
  Button,
  PanelRow,
  PanelHeading,
  BoldText,
} from "@backslashbuild/pp-ui";

export default function PayDialog({ open, onClose, bacsRunId }) {
  const payBACSRun = usePayBACSRun();
  const payPayslips = usePayPayslips();

  async function handleConfirm() {
    const bacsRunResult = await payBACSRun({ bacsRunId });
    let payslipResult = {};
    let tempRes = {};

    if (bacsRunResult?.isSuccess) {
      publish("notification", "Success! The bacs run has been paid.");
      payslipResult = await payPayslips({ bacsRunId });

      if (payslipResult?.isSuccess) {
        publish("notification", "Success! The payslips has been marked as paid.");
        onClose();
      }
    }

    if (!bacsRunResult?.isSuccess || !payslipResult?.isSuccess) {
      tempRes = !bacsRunResult?.isSuccess ? bacsRunResult : payslipResult;

      publish(
        "notification",
        <div>
          Attempt Failed
          <ul>
            {tempRes?.errors?.map((error) => (
              <li>{error}</li>
            ))}
          </ul>
        </div>
      );
    }
  }

  return (
    <PanelDialog open={open} onClose={onClose}>
      <PanelHeading>Mark BACS Run as Paid</PanelHeading>
      <PanelRow>
        <p>
          Click on 'Confirm' to permanently mark this BACS Run and all payslips locked to it as
          paid. While paid, no payslips can be added or removed from the BACS Run. This is the final
          modification that can be made on this item.
        </p>
        <BoldText>
          DO NOT DO THIS UNLESS THE BANK HAS CONFIRMED THE COMPLETION OF PAYMENTS.
        </BoldText>
      </PanelRow>
      <PanelActions>
        <Button variant="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleConfirm();
            onClose();
          }}
        >
          Confirm
        </Button>
      </PanelActions>
    </PanelDialog>
  );
}
