import React from "react";
import {
  Content,
  PageHeadingArea,
  PageTitle,
  PageHeadingAreaSpacer,
  Button,
  BackArrow,
} from "@backslashbuild/pp-ui";
import { useParams, useHistory, Link as RouterLink } from "react-router-dom";
import PayslipDebugPanels from "../modules/payroll/PayslipDebugPanels";
import ReProcessPayslipButton from "../modules/payroll/ReProcessPayslipButton";
import RemovePayslipButton from "../modules/payroll/RemovePayslipButton";
import IgnoreWarningsPayslipButton from "../modules/payroll/IgnoreWarningsPayslipButton";
import UpdateNumberOfPeriodsPayslipButton from "../modules/payroll/UpdateNumberOfPeriodsPayslipButton";

function Payslip() {
  const { id } = useParams();
  const history = useHistory();

  return (
    <Content scrollable>
      <PageHeadingArea>
        <BackArrow onClick={() => history.goBack()} />
        <PageHeadingAreaSpacer />
        <PageTitle>Payslip</PageTitle>
        <PageHeadingAreaSpacer grow />
        <ReProcessPayslipButton id={id} />
        <PageHeadingAreaSpacer />
        <RemovePayslipButton id={id} />
        <PageHeadingAreaSpacer />
        <IgnoreWarningsPayslipButton id={id} />
        <PageHeadingAreaSpacer />
        <UpdateNumberOfPeriodsPayslipButton id={id} />
        <PageHeadingAreaSpacer />
        <Button large component={RouterLink} to={`/payslip-redirect/${id}`} target="_blank">
          Open File
        </Button>
      </PageHeadingArea>
      <PayslipDebugPanels id={id} />
    </Content>
  );
}

export default Payslip;
