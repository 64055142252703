import React from "react";
import {
  PanelDialog,
  PanelHeading,
  PanelActions,
  PanelRow,
  Button,
  Label,
  TextInput,
} from "@backslashbuild/pp-ui";
import { useUploadAgenciesCsv } from "./api";
import { publish } from "../../pubSub";

export default function UploadAgenciesCsvDialog() {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [file, setFile] = React.useState(null);

  const upload = useUploadAgenciesCsv();

  function handleClose() {
    setDialogOpen(false);
  }

  async function handleUploadCsv() {
    publish("notification", "Uploading file...");
    const result = await upload(file);

    if (!result.isSuccess) {
      publish("notification", "Unable to upload CSV.");
      return;
    }

    if (result?.isSuccess) publish("notification", "Upload complete.");
    handleClose();
  }

  return (
    <>
      <Button small onClick={() => setDialogOpen(true)} style={{ width: 200 }}>
        Upload Agencies
      </Button>

      <PanelDialog onClose={handleClose} open={dialogOpen}>
        <PanelHeading>Upload Agencies CSV File</PanelHeading>
        <PanelRow>
          <Label label="Choose file">
            <TextInput type="file" onChange={(e) => setFile(e.target.files[0])} />
          </Label>
        </PanelRow>
        <PanelActions>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" disabled={!file} onClick={handleUploadCsv}>
            Submit
          </Button>
        </PanelActions>
      </PanelDialog>
    </>
  );
}
