import React from "react";

import ViewReport from "../modules/reports/ViewReport";

import { Content } from "@backslashbuild/pp-ui";

export default function Report() {
  return (
    <Content>
      <ViewReport />
    </Content>
  );
}
