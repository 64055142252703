import React from "react";

import { Content } from "@backslashbuild/pp-ui";

import ContractTypesTable from "../modules/contracts/ContractTypesTable";

function ContractsManagement() {
  return (
    <Content scrollable>
      <ContractTypesTable />
    </Content>
  );
}

export default ContractsManagement;
