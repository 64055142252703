import React from "react";
import { useGet } from "./api";
import {
  Button,
  PanelDialog,
  Table,
  TableRow,
  TableCell,
  PanelColumn,
  TableHeader,
  TableHeaderColumn,
  PageLoading,
} from "@backslashbuild/pp-ui";
import CreateInvoiceExportForm from "./CreateInvoiceExportForm";
import { isTextNullOrEmpty } from "./utils";
import { TableBody } from "@backslashbuild/pp-ui/dist/index.es";

export default function PendingExportsTable() {
  const [pendingExport, setPendingExport] = React.useState(false);
  const { data, loading, error, queryId, refetch } = useGet(`exports/pending`);

  if (loading || !data) return <PageLoading />;
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (!data?.isSuccess)
    return data?.errors?.map?.((error) => <div>{error}</div>) ?? <div>Unknown error</div>;

  return (
    <PanelColumn>
      <Table standardTable maxWidth={950}>
        <TableHeader>
          <TableHeaderColumn width={950}>Pending Exports</TableHeaderColumn>
        </TableHeader>
        <TableHeader>
          <TableHeaderColumn width="25%">Category</TableHeaderColumn>
          <TableHeaderColumn width="15%">Week No.</TableHeaderColumn>
          <TableHeaderColumn width="15%">Items</TableHeaderColumn>
          <TableHeaderColumn width="25%">Actions</TableHeaderColumn>
        </TableHeader>
      </Table>
      <div style={{ height: 400, width: "100%", overflow: "scroll" }}>
        <Table standardTable maxWidth={950}>
          <TableBody>
            {data?.value?.map((x, i) => (
              <TableRow key={i} disableHover>
                <TableCell width="25%">{x?.category}</TableCell>
                <TableCell width="15%">{x?.weekNumber}</TableCell>
                <TableCell width="15%">{x?.numberOfItems}</TableCell>
                <TableCell width="25%">
                  <Button
                    onClick={() => {
                      setPendingExport(x);
                    }}
                  >
                    Create
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <PanelDialog open={pendingExport}>
        <CreateInvoiceExportForm
          pendingExport={pendingExport}
          onClose={() => setPendingExport(null)}
        />
      </PanelDialog>
    </PanelColumn>
  );
}
