import React from "react";

import { Content } from "@backslashbuild/pp-ui";

import EmailTriggersTable from "../modules/emails/EmailTriggersTable";
import SMSTriggersTable from "../modules/emails/SMSTriggersTable";

function CommsManagement() {
  return (
    <Content scrollable>
      <EmailTriggersTable />
      <SMSTriggersTable />
    </Content>
  );
}

export default CommsManagement;
