import {
  FormTitle,
  PageLoading,
  Panel,
  PanelHeading,
  PanelActions,
  Button,
} from "@backslashbuild/pp-ui";
import React from "react";
import { useHistory } from "react-router-dom";
import { useGetFields, useUpdateFields } from "./api";
import FormField from "./FormField";
import { isTextNullOrEmpty } from "./utils";

export default function TopicForm({ id }) {
  const { data, loading, error } = useGetFields({ id, formName: "topic" });
  const updateFields = useUpdateFields({
    id,
    formName: `topic`,
  });
  const [edits, setEdits] = React.useState([]);
  const history = useHistory();

  if (loading) return <PageLoading />;

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    return data.value.map((field) => (
      <FormField key={field.key} field={field} onSave={getOnSaveHandler(field.key)} />
    ));
  }

  function getOnSaveHandler(key) {
    return (value) => {
      setEdits([...edits.filter((e) => e.key !== key), { key, value }]);
    };
  }

  function handleSave() {
    updateFields(edits).then((res) => {
      if (res?.isSuccess) {
        history.goBack();
      }
    });
  }

  return (
    <Panel>
      <PanelHeading>Edit agency</PanelHeading>
      {handleRender()}
      <PanelActions>
        <Button
          onClick={() => {
            handleSave();
          }}
        >
          Save
        </Button>
      </PanelActions>
    </Panel>
  );
}
