import React, { useState } from "react";
import {
  Panel,
  TextInput,
  PanelActions,
  PanelHeading,
  Button,
  PanelRow,
  Label,
} from "@backslashbuild/pp-ui";
import { usePost } from "./api";
import { publish } from "./pubSub";

export default function PimpMyPointsForm({ contractorId, onClose, refreshTransactions }) {
  const [description, setDescription] = useState("");
  const [numberOfPoints, setNumberOfPoints] = React.useState(0);

  const createContractorPoints = usePost("transactions/accrual");

  function handleSubmit() {
    createContractorPoints({
      contractorId,
      description,
      numberOfPoints,
    }).then((res) => {
      if (res?.isSuccess) {
        publish("notification", "Points added");
        refreshTransactions();
        onClose();
      } else {
        publish("notification", res?.errors);
      }
    });
  }

  return (
    <Panel>
      <PanelHeading>Pimp my points</PanelHeading>
      <PanelRow>
        <Label label="Description">
          <TextInput value={description} onChange={(e) => setDescription(e.target.value)} />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Number of points">
          <TextInput
            value={numberOfPoints}
            type="number"
            onChange={(e) => setNumberOfPoints(Number.parseInt(e.target.value))}
          />
        </Label>
      </PanelRow>
      <PanelActions>
        <Button variant="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Save</Button>
      </PanelActions>
    </Panel>
  );
}
