import React from "react";
import { Content, PageHeadingArea, PageTitle, Button } from "@backslashbuild/pp-ui";
import ContributionScheduleExportRowsTable from "../modules/nest/ContributionScheduleExportRowsTable";
import { PageHeadingAreaSpacer } from "@backslashbuild/pp-ui/dist/index.es";
import DownloadContributionScheduleExportCSVButton from "../modules/nest/DownloadContributionScheduleExportCSVButton";
import PreProcessContributionScheduleExportCSVButton from "../modules/nest/PreProcessContributionScheduleExportCSVButton";
import UploadUnsuccessfulContributionsDialog from "../modules/nest/UploadUnsuccessfulContributionsDialog";
import UploadContributionsErrorLogDialog from "../modules/nest/UploadContributionsErrorLogDialog";

export default function NESTContributionScheduleExport() {
  return (
    <Content>
      <PageHeadingArea>
        <PageTitle>Contribution Schedule Export</PageTitle>
        <PageHeadingAreaSpacer grow />
        <DownloadContributionScheduleExportCSVButton />
        <PreProcessContributionScheduleExportCSVButton />
        <UploadUnsuccessfulContributionsDialog />
        <UploadContributionsErrorLogDialog />
        <Button disabled>Generate Resolutions Export</Button>
      </PageHeadingArea>
      <ContributionScheduleExportRowsTable />
    </Content>
  );
}
