import React from "react";

import { useParams } from "react-router-dom";
import { useGetBACSRunById } from "./api";
import GreenTick from "../../components/GreenTick";
import { isTextNullOrEmpty } from "./utils";

import {
  TableRow,
  Table,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableFooter,
  PageLoading,
  Panel,
  PanelHeading,
  PanelSpacer,
  PanelRow,
  PanelColumn,
  makeStyles,
} from "@backslashbuild/pp-ui";

const useStyles = makeStyles({
  row: {
    textAlign: "center",
  },
});

export default function BACSRunPanel() {
  const { id } = useParams();
  const { data, loading, error, queryId, refetch } = useGetBACSRunById(id);

  return (
    <Panel>
      {loading && <PageLoading />}
      {!isTextNullOrEmpty(error) && <div>{String(error)}</div>}
      {!data?.isSuccess && data?.errors?.map((error) => <div>{error}</div>)}
      {data?.isSuccess && (
        <table maxWidth={320}>
          <TableHeader>
            <TableHeaderColumn width={80}>Closed</TableHeaderColumn>
            <TableHeaderColumn width={80}>Locked</TableHeaderColumn>
            <TableHeaderColumn width={80}>Paid</TableHeaderColumn>
            <TableHeaderColumn width={80}>Blocked</TableHeaderColumn>
          </TableHeader>
          <TableBody width={320} footerHeight={TableFooter.height}>
            <PureTable data={data} queryId={queryId} />
          </TableBody>
        </table>
      )}
    </Panel>
  );
}

function PureTable({ data, queryId }) {
  const classes = useStyles();

  return React.useMemo(
    () => (
      <TableRow width={320} disableHover>
        <TableCell width={80}>
          <div className={classes.row}>{data?.value?.isClosed && <GreenTick />}</div>
        </TableCell>
        <TableCell width={80}>
          <div className={classes.row}>{data?.value?.isLocked && <GreenTick />}</div>
        </TableCell>
        <TableCell width={80}>
          <div className={classes.row}>{data?.value?.isPaid && <GreenTick />}</div>
        </TableCell>
        <TableCell width={80}>
          <div className={classes.row}>{data?.value?.isBlocked && <GreenTick />}</div>
        </TableCell>
      </TableRow>
    ),
    [queryId]
  );
}
