import React from "react";

import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useGetBACSRuns } from "./api";
import { makeStateParamCorrectionForUrl, utcToYmd, isTextNullOrEmpty } from "./utils";
import AgencyPicker from "../invoicing/AgencyPicker";

import {
  Panel,
  PanelRow,
  PanelSpacer,
  Label,
  PanelHeading,
  Button,
  PanelActions,
  TextInput,
  Dropdown,
  DropdownOption,
  PageLoading,
  DatePicker,
  Radio,
  RadioGroup,
} from "@backslashbuild/pp-ui";

const operatorOptions = [
  { displayName: "<", value: "1" },
  { displayName: "<=", value: "2" },
  { displayName: "=", value: "0" },
  { displayName: ">=", value: "3" },
  { displayName: ">", value: "4" },
];

export default function PayslipsFilter() {
  const history = useHistory();
  const location = useLocation();
  const getStateFromSearch = (s) => queryString.parse(s);
  const [state, setState] = React.useState(getStateFromSearch(location.search));
  const query = getQuery();
  const { data, loading, error, queryId, refetch } = useGetBACSRuns(query);

  function handleSubmit() {
    history.replace({
      search: queryString.stringify(state),
    });
  }

  return (
    <Panel>
      <PanelHeading>Filters</PanelHeading>
      <PanelSpacer />
      {loading && <PageLoading />}
      {!isTextNullOrEmpty(error) && <div>{String(error)}</div>}
      {!data?.isSuccess && data?.errors?.map((error) => <div>{error}</div>)}
      <PanelRow>
        <Label label="Agency" />
        <AgencyPicker
          value={state?.agencyNumber}
          onChange={(v) => setState({ ...state, agencyNumber: v })}
        />
      </PanelRow>
      <PanelRow>
        <Label label="Surname" />
        <TextInput
          value={state?.surname}
          placeholder="Dorsey"
          onChange={(e) =>
            setState({ ...state, surname: makeStateParamCorrectionForUrl(e.target.value) })
          }
        />
      </PanelRow>
      <PanelRow>
        <Label label="Amount" />
        <TextInput
          value={state?.amount}
          placeholder="1750"
          onChange={(e) =>
            setState({ ...state, amount: makeStateParamCorrectionForUrl(e.target.value) })
          }
        />
      </PanelRow>
      <PanelRow>
        <Label label="Amount Operator" />
        <Dropdown
          value={state?.amountOperator ?? "0"}
          onChange={(e) =>
            setState({ ...state, amountOperator: makeStateParamCorrectionForUrl(e.target.value) })
          }
        >
          {operatorOptions.map((option) => (
            <DropdownOption value={option.value}>{option.displayName} </DropdownOption>
          ))}
        </Dropdown>
      </PanelRow>
      <PanelRow>
        <Label label="Locked" />
        <RadioGroup
          value={state?.isLocked}
          onChange={(e) =>
            setState({ ...state, isLocked: makeStateParamCorrectionForUrl(e.target.value) })
          }
        >
          <Radio value={"true"} label="Yes" />
          <Radio value={"false"} label="No" />
          <Radio value={""} label="Any" />
        </RadioGroup>
      </PanelRow>
      <PanelRow>
        <Label label="Blocked" />
        <RadioGroup
          value={state?.isBlocked}
          onChange={(e) =>
            setState({ ...state, isBlocked: makeStateParamCorrectionForUrl(e.target.value) })
          }
        >
          <Radio value={"true"} label="Yes" />
          <Radio value={"false"} label="No" />
          <Radio value={""} label="Any" />
        </RadioGroup>
      </PanelRow>
      <PanelRow>
        <Label label="Create Date From" />
        <DatePicker
          value={state?.dateCreatedFrom ?? null}
          onChange={(value) => {
            setState({ ...state, dateCreatedFrom: utcToYmd(value) });
          }}
        />
      </PanelRow>
      <PanelRow>
        <Label label="Create Date To" />
        <DatePicker
          value={state?.dateCreatedTo ?? null}
          onChange={(value) => {
            setState({ ...state, dateCreatedTo: utcToYmd(value) });
          }}
        />
      </PanelRow>
      <PanelRow>
        <Label label="Date Last Updated" />
        <DatePicker
          value={state?.dateLastUpdated ?? null}
          onChange={(value) => {
            setState({ ...state, dateLastUpdated: utcToYmd(value) });
          }}
        />
      </PanelRow>
      <PanelSpacer />
      <PanelSpacer />
      <PanelSpacer />
      <PanelActions>
        <Button onClick={handleSubmit}>Apply filters</Button>
      </PanelActions>
      <PanelSpacer large />
    </Panel>
  );
}

function getQuery() {
  return "?isClosed=false&isPaid=false&isLocked=false";
}
