import React from "react";
import { useGetFpsBaseRtiInformationById } from "./api";
import { isTextNullOrEmpty } from "./utils";

import {
  Panel,
  PanelActions,
  Button,
  PanelRow,
  PanelHeading,
  PageLoading,
} from "@backslashbuild/pp-ui";

export default function HmrcErrorsDialog({ onClose, rtiInformationId }) {
  const { data, loading, error, queryId, refetch } = useGetFpsBaseRtiInformationById(
    rtiInformationId
  );

  return (
    <Panel>
      <PanelHeading>HMRC Errors</PanelHeading>
      <PanelRow>
        {loading && <PageLoading />}
        {!isTextNullOrEmpty(error) && <div>{String(error)}</div>}
        {!data?.isSuccess && data?.errors?.map((error) => <div>{error}</div>)}
        {data?.isSuccess &&
          data?.value?.hmrcErrors?.map((error) => (
            <PanelRow>
              {error.code}: {error.description}
            </PanelRow>
          ))}
      </PanelRow>
      <PanelActions>
        <Button variant="secondary" onClick={() => onClose()}>
          Close
        </Button>
      </PanelActions>
    </Panel>
  );
}
