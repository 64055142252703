import React from "react";
import { Icons } from "@backslashbuild/pp-ui";

const CloseIcon = Icons.CloseIcon;

const ClearAdornment = ({ onClick = () => {} }) => {
  return <CloseIcon style={{ color: "rgb(95, 99, 104)" }} onClick={onClick} />;
};

export default ClearAdornment;
