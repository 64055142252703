import { Button, PageLoading, Panel, PanelActions, PanelHeading } from "@backslashbuild/pp-ui";
import React from "react";
import { useGetFields, useUpdateFields } from "./api";
import FormField from "./FormField";
import { isTextNullOrEmpty } from "./utils";

export default function EditP45Form({ id, onClose = () => {} }) {
  const { data, loading, error } = useGetFields({ id, formName: "contractor-p45Request" });
  const updateFields = useUpdateFields({ id, formName: "contractor-p45Request" });
  const [edits, setEdits] = React.useState([]);

  if (loading) return <PageLoading />;

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess && typeof data?.errors == Array) {
      return data?.errors?.map((error) => <div>{error}</div>);
    }

    return data?.value?.map((field) => (
      <FormField key={field.key} field={field} onSave={getOnSaveHandler(field.key)} />
    ));
  }

  function getOnSaveHandler(key) {
    return (value) => {
      setEdits([...edits.filter((e) => e.key !== key), { key, value }]);
    };
  }

  function handleSave() {
    updateFields(edits);
  }

  return (
    <Panel>
      <PanelHeading>Edit document details</PanelHeading>
      {handleRender()}
      <PanelActions>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleSave();
          }}
        >
          Save
        </Button>
      </PanelActions>
    </Panel>
  );
}
