import {
  CheckBox,
  Link,
  makeStyles,
  Panel,
  Root,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from "@backslashbuild/pp-ui/dist/index.es";
import React from "react";
import { useParams } from "react-router";
import { useGetInvoices, usePost } from "../modules/invoicing/api";
import { formatCurrency } from "../modules/invoicing/utils";
import PrintInvoicesButton from "../modules/invoicing/PrintInvoicesButton";
import getApiUrl from "../getApiUrl";
import { useFlag } from "react-unleash-flags";
import { publish } from "../pubSub";
import { isTextNullOrEmpty } from "../utils";

const useStyles = makeStyles({
  marginLeft: {
    marginLeft: 8,
    fontWeight: "bold",
  },
  flex: {
    display: "flex",
  },
  redText: {
    color: "red",
  },
  greyText: {
    color: "grey",
  },
  contractor: {
    padding: 0,
    margin: 0,
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
  },
  action: {
    padding: 5,
    margin: 5,
  },
});

function Flex({ children }) {
  const classes = useStyles();
  return <span className={classes.flex}>{children}</span>;
}

function RedText({ children }) {
  const classes = useStyles();
  return <span className={classes.redText}>{children}</span>;
}
function GreyText({ children }) {
  const classes = useStyles();
  return <span className={classes.greyText}>{children}</span>;
}
function CreditNoteC() {
  const classes = useStyles();
  return <span className={classes.creditNoteC}>C</span>;
}

const formatContractorName = (invoice) => {
  if (!isTextNullOrEmpty(invoice?.forenames) && !isTextNullOrEmpty(invoice?.surname))
    return `${invoice?.forenames[0]}. ${invoice?.surname}`;
  if (!isTextNullOrEmpty(invoice?.contractorName)) return invoice?.contractorName;
  return "";
};

function ScheduleInvoicesTable() {
  const { scheduleNumber } = useParams();
  const classes = useStyles();

  const [showCancelled, setShowCancelled] = React.useState(false);

  const signRequest = usePost();
  const viewInvoicesFF = useFlag("invoices-view-invoice");

  const { data, loading, error, queryId, refetch } = useGetInvoices(
    `?scheduleId=${scheduleNumber}&isSageOnly=${showCancelled ? "false" : "true"}`
  );

  if (loading || !data) {
    return <Panel>loading...</Panel>;
  }

  const apiUrl = getApiUrl();

  async function handleClickEdit(legacyInvoiceId) {
    window.open(
      `https://portal.peoplegroupservices.com/payroll/invcomm.asp?idref=${legacyInvoiceId}`,
      "_blank"
    );
  }

  async function handleClickRow(invoiceNumber) {
    const blank = window.open("", "_blank");

    signRequest(`documents/sign-request/${invoiceNumber}`).then((result) => {
      if (result?.isSuccess) {
        var { token, fileName } = result.value;

        blank.location = `${apiUrl}/invoicing/documents/${invoiceNumber}/view/${fileName}?token=${token}`;
      } else {
        let error = `Error - could not open invoice.`;
        if (typeof result?.errors == "string") error = result.error;
        else if (Array.isArray(result?.errors)) error = result.errors;
        publish("notification", error);
      }
    });
  }

  return (
    <Root>
      <Table
        sticky
        stickyFooter={
          <TableFooter sticky>
            <PrintInvoicesButton invoices={data.value.items}>Print all</PrintInvoicesButton>

            <CheckBox
              label="Show cancelled"
              checked={showCancelled}
              onChange={(e) => setShowCancelled(e.target.checked)}
            />
          </TableFooter>
        }
        footerHeight={TableFooter.height}
      >
        <TableHeader sticky>
          <TableHeaderColumn mini narrow sticky>
            Invoice No.
          </TableHeaderColumn>
          <TableHeaderColumn mini narrow sticky alignRight>
            Net
          </TableHeaderColumn>
          <TableHeaderColumn mini narrow sticky alignRight>
            VAT
          </TableHeaderColumn>
          <TableHeaderColumn mini narrow sticky alignRight>
            Total
          </TableHeaderColumn>
          <TableHeaderColumn mini narrow sticky>
            Contractor
          </TableHeaderColumn>
          <TableHeaderColumn mini narrow sticky>
            Type
          </TableHeaderColumn>
          <TableHeaderColumn mini narrow sticky>
            Actions
          </TableHeaderColumn>
        </TableHeader>
        <TableBody sticky>
          {data.value.items.map((invoice) => {
            const Text = invoice.isCreditNote ? GreyText : "span";

            return (
              <TableRow onClick={() => handleClickRow(invoice.invoiceNumber)}>
                <TableCell mini narrow>
                  <Flex>
                    {invoice.isCreditNote ? (
                      <RedText>
                        {invoice.invoiceNumber}
                        <creditNoteC />
                      </RedText>
                    ) : (
                      <>{invoice.invoiceNumber}</>
                    )}
                  </Flex>
                </TableCell>
                <TableCell mini narrow alignRight>
                  <Text>
                    {invoice.isCreditNote && "-"}
                    {formatCurrency(invoice.totalInvoiceNet)}
                  </Text>
                </TableCell>
                <TableCell mini narrow alignRight>
                  <Text>
                    {invoice.isCreditNote && "-"}
                    {formatCurrency(invoice.totalInvoiceVat)}
                  </Text>
                </TableCell>
                <TableCell mini narrow alignRight>
                  <Text>
                    {invoice.isCreditNote && "-"}
                    {formatCurrency(invoice.totalInvoiceValue)}
                  </Text>
                </TableCell>
                <TableCell mini narrow>
                  <Text>{formatContractorName(invoice)}</Text>
                </TableCell>
                <TableCell mini narrow>
                  <Text>{invoice.invoiceCategoryId}</Text>
                </TableCell>
                <TableCell mini narrow>
                  {/* <Link
                    style={{ marginRight: 40 }}
                    onClick={() => {
                      if (viewInvoicesFF) {
                        handleClickRow(invoice.invoiceNumber);
                      }
                    }}
                  >
                    View
                  </Link> */}
                  <Link
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClickEdit(invoice.legacyInvoiceId);
                    }}
                  >
                    Edit
                  </Link>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Root>
  );
}

export default ScheduleInvoicesTable;
