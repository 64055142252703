import React, { useState } from "react";
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableFooter,
  PageLoading,
  PageHeadingArea,
  PageTitle,
  PageHeadingAreaSpacer,
  PanelDialog,
} from "@backslashbuild/pp-ui";
import { utcToLocalText, isTextNullOrEmpty } from "./utils";
import { useGetItems } from "./api";
import ContractorsFilter from "./ContractorsFilter";
import { useLocation, useHistory } from "react-router-dom";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";

import EditOutstandingAnytimeWageRequestForm from "./EditOutstandingAnytimeWageRequestForm";
import { formatCurrency } from "../../utils";

function PureTable({ data, queryId, onClick }) {
  const history = useHistory();

  return React.useMemo(
    () =>
      data.value.items.map((contractorAnytimeWageRequest) => {
        const remainingAmount =
          contractorAnytimeWageRequest.amountAccepted +
          contractorAnytimeWageRequest.feeInMoney -
          contractorAnytimeWageRequest.amountPaidBackByContractor;
        const percentPaidBack =
          (contractorAnytimeWageRequest.amountPaidBackByContractor /
            (contractorAnytimeWageRequest.amountAccepted +
              contractorAnytimeWageRequest.feeInMoney)) *
          100;

        return (
          <TableRow onClick={() => onClick(contractorAnytimeWageRequest)}>
            <TableCell width="7%">{contractorAnytimeWageRequest.id}</TableCell>
            <TableCell width="7%">{contractorAnytimeWageRequest.completedAdvancesCount}</TableCell>
            <TableCell width="7%">{contractorAnytimeWageRequest.contractorName}</TableCell>
            <TableCell width="7%">
              {formatCurrency(contractorAnytimeWageRequest.amountAccepted)}
            </TableCell>
            <TableCell width="7%">
              {formatCurrency(contractorAnytimeWageRequest.feeInMoney)}
            </TableCell>
            <TableCell width="7%">{formatCurrency(remainingAmount)}</TableCell>
            <TableCell width="7%">{percentPaidBack}%</TableCell>
            <TableCell width="7%">TO-SYNC</TableCell>
            <TableCell width="7%">
              {contractorAnytimeWageRequest.oustandingFeeNextContact}
            </TableCell>
            <TableCell width="7%">{contractorAnytimeWageRequest.oustandingFeeStatus}</TableCell>
            <TableCell width="7%">TO-SYNC</TableCell>
            <TableCell width="7%">TO-SYNC</TableCell>
            <TableCell width="7%">{contractorAnytimeWageRequest.oustandingFeeNotes}</TableCell>
          </TableRow>
        );
      }),
    [queryId]
  );
}

function AdvancesTable() {
  const location = useLocation();
  const search = usePagination(location.search);
  const { data, loading, error, queryId } = useGetItems(
    "contractor-anytimeWageRequests/outstanding"
  );
  const [
    showEditOutstandingAnytimeWageRequest,
    setShowEditOutstandingAnytimeWageRequest,
  ] = useState(false);

  if (loading || !data) return <PageLoading />;
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  return (
    <>
      <PageHeadingArea>
        <PageTitle>Advances Outstanding</PageTitle>
        <PageHeadingAreaSpacer grow />
        <ContractorsFilter />
      </PageHeadingArea>
      <Table standardTable>
        <TableHeader>
          <TableHeaderColumn width="7%">ID</TableHeaderColumn>
          <TableHeaderColumn width="7%">Advances Completed</TableHeaderColumn>
          <TableHeaderColumn width="7%">Name</TableHeaderColumn>
          <TableHeaderColumn width="7%">Advance</TableHeaderColumn>
          <TableHeaderColumn width="7%">Fee</TableHeaderColumn>
          <TableHeaderColumn width="7%">Unpaid</TableHeaderColumn>
          <TableHeaderColumn width="7%">%</TableHeaderColumn>
          <TableHeaderColumn width="7%">Taken Out</TableHeaderColumn>
          <TableHeaderColumn width="7%">Last Paid</TableHeaderColumn>
          <TableHeaderColumn width="7%">Last Contact</TableHeaderColumn>
          <TableHeaderColumn width="7%">Next Contact</TableHeaderColumn>
          <TableHeaderColumn width="7%">Action</TableHeaderColumn>
          <TableHeaderColumn width="7%">HP</TableHeaderColumn>
          <TableHeaderColumn width="7%">CP</TableHeaderColumn>
          <TableHeaderColumn width="7%">Notes</TableHeaderColumn>
        </TableHeader>
        <TableBody fullPageTable={true} footerHeight={TableFooter.height}>
          <PureTable
            data={data}
            queryId={queryId}
            onClick={(contractorAnytimeWageRequest) =>
              setShowEditOutstandingAnytimeWageRequest(contractorAnytimeWageRequest)
            }
          />
        </TableBody>
        <TableFooter>
          <QueryPaginator
            itemsTotal={data?.value?.itemsTotal}
            itemsRetrieved={data?.value?.itemsRetrieved}
          />
        </TableFooter>
      </Table>

      <PanelDialog
        open={showEditOutstandingAnytimeWageRequest}
        onClose={() => setShowEditOutstandingAnytimeWageRequest(false)}
      >
        <EditOutstandingAnytimeWageRequestForm
          contractorOutstandingAnytimeWageRequestId={showEditOutstandingAnytimeWageRequest.id}
          onClose={() => setShowEditOutstandingAnytimeWageRequest(false)}
        />
      </PanelDialog>
    </>
  );
}

export default AdvancesTable;
