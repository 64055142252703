import React from "react";
import {
  QueryDropdown,
  Rollover,
  PopoverMenu,
  Table,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
} from "@backslashbuild/pp-ui";
import { useGet } from "./api";
import clsx from "clsx";
import TaxWeekDetailsDisplay from "./TaxWeekDetailsDisplay";
import generateDates from "./generateDates";
import { utcToMillis } from "../../utils";

const useStyles = makeStyles({
  heading: {
    display: "flex",
    flexDirection: "column",
  },
  headingWeekNumber: {
    width: 64,
  },
  headingRow: {
    display: "flex",
  },
  headingWeek: {
    width: 30 * 5,
    display: "flex",
    justifyContent: "center",
  },
  headingDay: {
    width: 30,
    display: "flex",
    justifyContent: "center",
  },
  option: {
    height: 40,
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  weekNumber: {
    width: 64,
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  day: {
    width: 30,
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 11,
    lineHeight: 0.9,
  },
  borderRight: {
    borderRight: "1px solid #ddd",
  },
  borderBottom: {
    borderBottom: "1px solid #ddd",
  },
});

const TaxWeek = React.memo(function ({ value }) {
  const classes = useStyles();

  const workingDates = React.useMemo(() =>
    generateDates(value.workingStartDate, value.workingEndDate)
  );
  const payrollDates = generateDates(value.payrollStartDate, value.payrollEndDate);

  return (
    <div className={classes.option}>
      <div className={clsx(classes.weekNumber, classes.borderRight)}>{value.weekNumber}</div>

      {workingDates.map((d, i) => (
        <div
          className={clsx(classes.day, { [classes.borderRight]: i + 1 === workingDates.length })}
        >
          <div>
            <strong>{d.toFormat("dd")}</strong>
            <br />
            <small>{d.toFormat("MMM").toUpperCase()}</small>
          </div>
        </div>
      ))}
      {payrollDates.map((d, i) => (
        <div className={classes.day}>
          <div>
            <strong>{d.toFormat("dd")}</strong>
            <br />
            <small>{d.toFormat("MMM").toUpperCase()}</small>
          </div>
        </div>
      ))}
    </div>
  );
});

function TaxWeekPicker({ value, onChange }) {
  const [search, setSearch] = React.useState("");
  const optionsQuery = useGet("tax-weeks");

  const currentItemQuery = useGet(`tax-weeks/date`);
  const options = optionsQuery.data?.value?.items ?? [];
  const classes = useStyles();

  function handleChange(val) {
    var optionsMatch = options.find((o) => o.id == val);
    void onChange?.(val, optionsMatch);
  }

  // if the current item is null, then we want to set the value back to the current week.
  React.useEffect(() => {
    if (!value) {
      void onChange?.(currentItemQuery?.data?.value?.id, currentItemQuery?.data?.value);
    }
  }, [currentItemQuery?.data?.value?.id]);

  return (
    <QueryDropdown
      placeholder="Choose"
      search={search}
      onSearchChange={setSearch}
      value={value}
      onChange={handleChange}
      itemHeight={40}
      itemStyles={{ padding: 0 }}
      options={options
        .filter(
          (o) => !search || o.weekNumber.toString().slice(0, search.length) === search.slice()
        )
        .sort((a, b) => utcToMillis(a.payrollStartDate) - utcToMillis(b.payrollStartDate))
        .map((o) => o.id)}
      listHeading={
        <div className={classes.heading}>
          <div className={clsx(classes.headingRow)}>
            <div className={clsx(classes.headingWeekNumber, classes.borderRight)}></div>
            <div className={clsx(classes.headingWeek, classes.borderRight)}>Working</div>
            <div className={classes.headingWeek}>Payroll</div>
          </div>
          <div className={clsx(classes.headingRow, classes.borderBottom)}>
            <div className={clsx(classes.headingWeekNumber, classes.borderRight)}></div>
            <div className={classes.headingDay}>M</div>
            <div className={classes.headingDay}>T</div>
            <div className={classes.headingDay}>W</div>
            <div className={classes.headingDay}>T</div>
            <div className={clsx(classes.headingDay, classes.borderRight)}>F</div>
            <div className={classes.headingDay}>M</div>
            <div className={classes.headingDay}>T</div>
            <div className={classes.headingDay}>W</div>
            <div className={classes.headingDay}>T</div>
            <div className={classes.headingDay}>F</div>
          </div>
        </div>
      }
      renderValue={(v) => {
        var optionsMatch = options.find((o) => o.id == v);
        return optionsMatch?.weekNumber;
      }}
      renderOption={(v) => {
        var optionsMatch = options.find((o) => o.id == v);

        if (optionsMatch) {
          return <TaxWeek value={optionsMatch} />;
        }

        return "loading...";
      }}
    />
  );
}

export default TaxWeekPicker;
