import React from "react";

import { Button } from "@backslashbuild/pp-ui";

export default function RecalculateButton({ onClick }) {
  return (
    <Button
      large
      onClick={() => {
        onClick();
      }}
    >
      Recalculate
    </Button>
  );
}
