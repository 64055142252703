import React from "react";

import { publish } from "./pubSub";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { isTextNullOrEmpty } from "./utils";
import { useLockPayslips, invalidatePayslips, useRemoveBACSRows, useAddBACSRows } from "./api";

import { PanelDialog, PanelActions, Button, PanelRow, PanelHeading } from "@backslashbuild/pp-ui";

export default function LockPayslipsDialog({ open, onClose }) {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const [state, setState] = React.useState(params);
  const lockPayslips = useLockPayslips();

  React.useEffect(() => {
    setState(queryString.parse(location.search));
  }, [location]);

  async function handleConfirm() {
    const payslipResult = await lockPayslips({ payslipFilters: state });

    if (payslipResult.isSuccess) {
      publish("notification", "Success! The payslips have been locked.");
    } else {
      publish(
        "notification",
        <div>
          Failed to lock payslips.
          <ul>
            {payslipResult?.errors?.map((error) => (
              <li>{error}</li>
            ))}
          </ul>
        </div>
      );
    }
  }

  return (
    <PanelDialog open={open} onClose={onClose}>
      <PanelHeading>Lock Payslips</PanelHeading>
      <PanelRow>
        <p>This will lock the selected Payslips</p>
      </PanelRow>
      <PanelActions>
        <Button variant="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          disabled={isTextNullOrEmpty(state.bacsRunId)}
          onClick={() => {
            handleConfirm();
            onClose();
          }}
        >
          Confirm
        </Button>
      </PanelActions>
    </PanelDialog>
  );
}
