import React from "react";
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  PageLoading,
  Panel,
  PanelHeading,
  PanelSpacer,
  PageHeadingArea,
  BackArrow,
  PageTitle,
  PageHeadingAreaSpacer,
} from "@backslashbuild/pp-ui";
import ErrorBoundary from "../../ErrorBoundary";

import { useGetCsvImport, useCreateMatchesForCSV } from "./api";
import { publish } from "../../pubSub";
import { useHistory } from "react-router-dom";
import { MatchRowEditorTableHeading, MatchRowEditor } from "./MatchRowEditor";
import ApproveMatchesButton from "./ApproveMatchesButton";
function CSVImportMatchReviewer({ id }) {
  const query = useGetCsvImport(id);
  const post = useCreateMatchesForCSV();
  const history = useHistory();

  if (!query?.data?.value) {
    return <PageLoading />;
  }

  const { matches } = query.data.value;

  return (
    <>
      <PageHeadingArea>
        <BackArrow onClick={() => history.goBack()} />
        <PageHeadingAreaSpacer />
        <PageTitle>CSV Matching {id}</PageTitle>
        <PageHeadingAreaSpacer grow />
        <ApproveMatchesButton id={id} />
      </PageHeadingArea>

      <Panel maxWidth="100%">
        <PanelHeading>New matches</PanelHeading>
        <Table>
          <MatchRowEditorTableHeading />
          <TableBody>
            {matches
              ?.filter((m) => m.approved === false)
              ?.map((match) => (
                <MatchRowEditor match={match} />
              ))}
          </TableBody>
        </Table>
      </Panel>
      <PanelSpacer large />
      <Panel maxWidth="100%">
        <PanelHeading>Existing matches</PanelHeading>
        <Table>
          <MatchRowEditorTableHeading />
          <TableBody>
            {matches
              ?.filter((m) => m.approved === true)
              ?.map((match) => (
                <ErrorBoundary
                  fallback={
                    <TableRow>
                      <TableCell>There was an error displaying this match</TableCell>
                    </TableRow>
                  }
                >
                  <MatchRowEditor match={match} />
                </ErrorBoundary>
              ))}
          </TableBody>
        </Table>
      </Panel>
    </>
  );
}

export default CSVImportMatchReviewer;
