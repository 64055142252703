import React from "react";
import { useHistory, useParams } from "react-router-dom";

import StatutoryPayGroupPageTitle from "../modules/payroll/StatutoryPayGroupPageTitle";
import {
  PageHeadingAreaSpacer,
  Content,
  PageHeadingArea,
  BackArrow,
  PanelColumn,
  PanelColumnSet,
  PanelColumnSpacer,
  PanelHeading,
  Panel,
} from "@backslashbuild/pp-ui/dist/index.es";
import StatutoryPayGroupForm from "../modules/payroll/StatutoryPayGroupForm";
import StatutoryPaysTable from "../modules/payroll/StatutoryPaysTable";

function StatutoryPayGroup() {
  const { statutoryPayGroupId } = useParams();

  const history = useHistory();

  return (
    <Content scrollable>
      <PageHeadingArea>
        <BackArrow onClick={() => history.goBack()} />
        <StatutoryPayGroupPageTitle
          statutoryPayGroupId={statutoryPayGroupId}
          title={"Statutory Pay"}
        />
        <PageHeadingAreaSpacer grow />
      </PageHeadingArea>
      <PanelColumnSet>
        <StatutoryPayGroupForm statutoryPayGroupId={statutoryPayGroupId} />
        <PanelColumnSpacer />
        <StatutoryPaysTable statutoryPayGroupId={statutoryPayGroupId} />
      </PanelColumnSet>
    </Content>
  );
}

export default StatutoryPayGroup;
