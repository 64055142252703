import React from "react";
import {
  Button,
  PanelRow,
  TextInput,
  DatePicker,
  Label,
  Radio,
  RadioGroup,
  Dropdown,
  DropdownOption,
  CheckBox,
  SortCodeInput,
  QueryDropdown,
  TextInputMultiline,
  Slider,
} from "@backslashbuild/pp-ui";
import { utcToYmd, textToBoolean } from "./utils";
import getApiUrl from "../../getApiUrl";

export default function FormField({
  field,
  onSave,
  onClick,
  onQuery,
  hintHandlers,
  showSelect = true,
  hideLabel,
  largeLabel = false,
}) {
  let SubComponent = TextField;

  if (field.schema.type === "date") SubComponent = DateField;
  if (field.schema.type === "radio") SubComponent = RadioField;
  if (field.schema.type === "slider") SubComponent = SliderField;
  if (field.schema.type === "dropdown") SubComponent = DropdownField;
  if (field.schema.type === "checkbox") SubComponent = CheckboxField;
  if (field.schema.type === "textbox") SubComponent = TextBoxField;
  //if (field.schema.type === "sort-code") SubComponent = SortCodeField;
  if (field.schema.type === "query-dropdown") SubComponent = QueryDropdownField;
  if (field.schema.type === "button") SubComponent = ButtonField;
  if (field.schema.type === "link") SubComponent = LinkField;
  if (field.schema.type === "info") SubComponent = InfoField;

  let customProps = {};
  if (field.schema.hints) {
    field.schema.hints.forEach((hint) => {
      if (hintHandlers && hintHandlers[hint]) {
        Object.assign(customProps, hintHandlers[hint]());
      }
    });
  }

  if (hideLabel)
    return (
      <SubComponent
        field={field}
        onSave={onSave}
        onClick={() => onClick && onClick()}
        onQuery={onQuery}
        showSelect={showSelect}
        {...customProps}
      />
    );

  return (
    <PanelRow>
      <Label label={field.displayName} long={largeLabel}>
        <SubComponent
          field={field}
          onSave={onSave}
          onClick={() => onClick && onClick()}
          onQuery={onQuery}
          showSelect={showSelect}
          {...customProps}
        />
      </Label>
    </PanelRow>
  );
}

function TextField({ field, onSave, customSuffix }) {
  const [value, setValue] = React.useState(field.value);
  const [isDisabled, setIsDisabled] = React.useState(field.isDisabled);
  const prefix = field.schema.type === "currency" ? "£" : "";
  const suffix = customSuffix || (field.schema.type === "percentage" ? "%" : "");

  const timeout = React.useRef(null);
  const textInputRef = React.useRef(null);

  React.useEffect(() => {
    if (document.activeElement != textInputRef.current) {
      setValue(field.value);
    }
  }, [field.value]);

  React.useEffect(() => {
    setIsDisabled(field.isDisabled);
  }, [field.isDisabled]);

  function queueUpdate(value) {
    window.clearTimeout(timeout.current);
    timeout.current = window.setTimeout(() => onSave(value), 1200);
  }

  const patterns =
    field.schema.settings &&
    field.schema.settings.filter((r) => r.type === "pattern").map((r) => new RegExp(r.value));
  const transform = field.schema.settings?.find((r) => r.type === "transform")?.value;

  // let mask = field.schema.settings?.find((r) => r.type === "mask")?.value;
  // let accept = field.schema.settings?.find((r) => r.type === "accept")?.value;
  // let acceptRegexp = accept ? new RegExp(accept, "g") : null;
  // let TextInputComponent = mask && accept ? TextInputMasked : TextInput;

  return (
    <TextInput
      inputRef={textInputRef}
      value={value}
      prefix={prefix}
      suffix={suffix}
      // placeholder={mask}
      // mask={mask}
      // accept={acceptRegexp}
      disabled={isDisabled}
      onChange={(e) => {
        let newValue = e.target.value;

        if (field.schema.type === "integer") {
          const integerRegex = /^[\d]*$/;
          if (integerRegex.test(value) && !integerRegex.test(newValue)) {
            return;
          }
        }

        if (field.schema.type === "currency") {
          const currencyRegex = /^[\d]*$|^[\d]+\.[\d]{0,2}$/;
          if (currencyRegex.test(value) && !currencyRegex.test(newValue)) {
            return;
          }
        }

        if (field.schema.type === "percentage" || field.schema.type === "number") {
          const numberRegex = /^[\d]*$|^[\d]+\.[\d]*$/;
          if (numberRegex.test(value) && !numberRegex.test(newValue)) {
            return;
          }
        }

        if (transform === "uppercase") {
          newValue = newValue.toUpperCase();
        }

        if (patterns) {
          for (let pattern of patterns) {
            if (pattern.test(value) && !pattern.test(newValue)) {
              return;
            }
          }
        }

        setValue(newValue);
        queueUpdate(newValue);
      }}
      onBlur={() => {
        onSave(value);
      }}
    ></TextInput>
  );
}

function RadioField({ field, onSave }) {
  const [value, setValue] = React.useState(field.value);
  const [isDisabled, setIsDisabled] = React.useState(field.isDisabled);

  React.useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  React.useEffect(() => {
    setIsDisabled(field.isDisabled);
  }, [field.isDisabled]);

  return (
    <RadioGroup
      value={value}
      disabled={isDisabled}
      onChange={(e) => {
        onSave(e.target.value);
        setValue(e.target.value);
      }}
    >
      {field.schema.options.map((option) => (
        <Radio value={option.value} label={option.displayName} disabled={isDisabled} />
      ))}
    </RadioGroup>
  );
}
function SliderField({ field, onSave }) {
  const [value, setValue] = React.useState(field.value);
  const [isDisabled, setIsDisabled] = React.useState(field.isDisabled);

  const min = Number(field.schema.settings?.find((r) => r.type === "min")?.value ?? 0);
  const max = Number(field.schema.settings?.find((r) => r.type === "max")?.value ?? 100);
  const step = Number(field.schema.settings?.find((r) => r.type === "step")?.value ?? 10);

  React.useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  React.useEffect(() => {
    setIsDisabled(field.isDisabled);
  }, [field.isDisabled]);

  return (
    <Slider
      min={min}
      max={max}
      step={step}
      value={value}
      disabled={isDisabled}
      onChange={(e, v) => {
        setValue(v);
      }}
      onBlur={() => {
        onSave(String(value));
      }}
    />
  );
}

function DropdownField({ field, onSave, showSelect = true, width }) {
  const [value, setValue] = React.useState(field.value);
  const [isDisabled, setIsDisabled] = React.useState(field.isDisabled);

  React.useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  React.useEffect(() => {
    setIsDisabled(field.isDisabled);
  }, [field.isDisabled]);

  return (
    <Dropdown
      value={value}
      disabled={isDisabled}
      onChange={(e) => {
        onSave(e.target.value);
        setValue(e.target.value);
      }}
      width={width}
    >
      {showSelect && <DropdownOption value={""}>Select</DropdownOption>}
      {field.schema.options.map((option) => (
        <DropdownOption value={option.value}>{option.displayName}</DropdownOption>
      ))}
    </Dropdown>
  );
}

function CheckboxField({ field, onSave }) {
  const [value, setValue] = React.useState(textToBoolean(field.value));
  const label = field.schema.options[0].displayName;
  const [isDisabled, setIsDisabled] = React.useState(field.isDisabled);

  React.useEffect(() => {
    setValue(textToBoolean(field.value));
  }, [field.value]);

  React.useEffect(() => {
    setIsDisabled(field.isDisabled);
  }, [field.isDisabled]);

  return (
    <CheckBox
      checked={value}
      label={label}
      disabled={isDisabled}
      onChange={(e) => {
        onSave(String(e.target.checked));
        setValue(e.target.checked);
      }}
    ></CheckBox>
  );
}

function DateField({ field, onSave }) {
  const [value, setValue] = React.useState(utcToYmd(field.value));
  const [isDisabled, setIsDisabled] = React.useState(field.isDisabled);

  React.useEffect(() => {
    setValue(utcToYmd(field.value));
  }, [field.value]);

  React.useEffect(() => {
    setIsDisabled(field.isDisabled);
  }, [field.isDisabled]);

  return (
    <DatePicker
      value={value}
      disabled={isDisabled}
      onChange={(v) => {
        setValue(utcToYmd(v));
        onSave(v?.isValid ? utcToYmd(v) : "");
      }}
    ></DatePicker>
  );
}

function TextBoxField({ field, onSave }) {
  const [value, setValue] = React.useState(field.value);
  const [isDisabled, setIsDisabled] = React.useState(field.isDisabled);

  React.useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  React.useEffect(() => {
    setIsDisabled(field.isDisabled);
  }, [field.isDisabled]);

  return (
    <TextInputMultiline
      value={value}
      disabled={isDisabled}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      onBlur={() => {
        onSave(value);
      }}
      rows={10}
    ></TextInputMultiline>
  );
}

function InfoField({ field }) {
  return <span>{field.value}</span>;
}

function SortCodeField({ field, onSave }) {
  const [value, setValue] = React.useState(field.value);
  const [isDisabled, setIsDisabled] = React.useState(field.isDisabled);

  React.useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  React.useEffect(() => {
    setIsDisabled(field.isDisabled);
  }, [field.isDisabled]);

  return (
    <SortCodeInput
      value={value}
      disabled={isDisabled}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      onBlur={() => {
        onSave(value);
      }}
    ></SortCodeInput>
  );
}

function QueryDropdownField({ field, onSave, onQuery }) {
  const [value, setValue] = React.useState(field.value);
  const [search, setSearch] = React.useState("");
  const [isDisabled, setIsDisabled] = React.useState(field.isDisabled);

  React.useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  React.useEffect(() => {
    onQuery(search);
  }, [search]);

  React.useEffect(() => {
    setIsDisabled(field.isDisabled);
  }, [field.isDisabled]);

  function handleChange(value) {
    setValue(value);
    onSave(value);
    setSearch("");
  }

  return (
    <QueryDropdown
      placeholder="Choose"
      search={search}
      onSearchChange={(search) => {
        setSearch(search);
        onQuery(search);
      }}
      value={value}
      disabled={isDisabled}
      onChange={handleChange}
      options={field.schema.options.map((option) => option.value)}
      renderOption={(value) => {
        const option = field.schema.options.find((option) => value == option.value);
        return option ? option.displayName : "";
      }}
    ></QueryDropdown>
  );
}

function ButtonField({ field, onSave }) {
  const [isDisabled, setIsDisabled] = React.useState(field.isDisabled);

  React.useEffect(() => {
    setIsDisabled(field.isDisabled);
  }, [field.isDisabled]);

  return (
    <Button
      small
      disabled={isDisabled}
      variant="primary"
      onClick={() => {
        onSave(null);
      }}
    >
      {field.displayName}
    </Button>
  );
}

function LinkField({ field, onClick }) {
  if (field.value === "") {
    return <a onClick={() => onClick()}>{field.displayName}</a>;
  }

  return (
    <a target="_blank" href={getApiUrl() + field.value}>
      {field.displayName}
    </a>
  );
}
