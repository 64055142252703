import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@backslashbuild/pp-ui";
import {
  Content,
  PageTitle,
  PanelColumn,
  PageHeadingArea,
  PanelColumnSet,
} from "@backslashbuild/pp-ui";
import NewContactForm from "../modules/agencies/NewContactForm";

export default function CreateAgencyContact() {
  const { agencyId } = useParams();

  return (
    <Content scrollable>
      <PageHeadingArea>
        <PageTitle>Create Contact</PageTitle>
      </PageHeadingArea>

      <PanelColumnSet>
        <PanelColumn>
          <NewContactForm agencyId={agencyId} />
        </PanelColumn>
      </PanelColumnSet>
    </Content>
  );
}
