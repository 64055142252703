const e = {};
window.pubSub = {
  publish: function (name, data) {
    (e[name] = e[name] || new Event(name)).data = data;
    window.dispatchEvent(e[name]);
  },
  subscribe: function (name, handler) {
    window.addEventListener(name, handler);
  },
  unsubscribe: function (name, handler) {
    window.removeEventListener(name, handler);
  },
};

export function publish(name, data) {
  (e[name] = e[name] || new Event(name)).data = data;
  window.dispatchEvent(e[name]);
}
export function subscribe(name, handler) {
  window.addEventListener(name, handler);
}
export function unsubscribe(name, handler) {
  window.removeEventListener(name, handler);
}
