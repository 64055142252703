import React from "react";
import {
  PageLoading,
  Panel,
  Table,
  PanelDialog,
  TableBody,
  BoldText,
  TableRow,
  PanelHeading,
  Label,
  PanelRow,
  Link,
} from "@backslashbuild/pp-ui";
import { useGetAgencyPEOBands } from "./api";
import { isTextNullOrEmpty } from "./utils";

import NewPEOBandForm from "./NewPEOBandForm";
import EditPEOBandForm from "./EditPEOBandForm";

export default function AgencyPEOBandsPanel({ agencyId }) {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [editPEOBand, setEditPEOBand] = React.useState(null);
  const { data, loading, error, queryId } = useGetAgencyPEOBands({ agencyId, defaults: false });

  if (loading) return <PageLoading />;
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (!data.isSuccess) return data?.errors?.map((error) => <div>{error}</div>);

  return (
    <>
      <Panel>
        <PanelHeading rightAction={<Link onClick={() => setDialogOpen(true)}>Add</Link>}>
          PEO Bands
        </PanelHeading>
        <Table>
          <TableBody>
            {data.value.items.map((item) => (
              <TableRow onClick={() => setEditPEOBand(item?.id)}>
                <PanelRow>
                  <Label label="Description">
                    <BoldText>{item?.description}</BoldText>
                  </Label>
                </PanelRow>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Panel>
      <PanelDialog open={editPEOBand}>
        {editPEOBand && (
          <EditPEOBandForm peoBandId={editPEOBand} onClose={() => setEditPEOBand(null)} />
        )}
      </PanelDialog>
      <PanelDialog open={dialogOpen}>
        <NewPEOBandForm
          agencyId={agencyId}
          onClose={() => {
            setDialogOpen(false);
          }}
        />
      </PanelDialog>
    </>
  );
}
