import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  TableRow,
  Table,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableFooter,
  TableBody,
  BoldText,
  Button,
} from "@backslashbuild/pp-ui";
import QueryPaginator from "../../containers/QueryPaginator";
import { useGetHolidayTransactions } from "./api";
import { formatCurrency, utcToYmd } from "../../utils";
import { Panel } from "@backslashbuild/pp-ui/dist/index.es";

function PureTable({ data, queryId }) {
  return React.useMemo(() =>
    data.value.items.map(
      (holidayTransaction, i) => (
        <TableRow key={i} disableHover>
          <TableCell width="200px">
            {holidayTransaction?.dateCreated ? utcToYmd(holidayTransaction?.dateCreated) : "-"}
          </TableCell>
          <TableCell width="200px">
            {holidayTransaction?.dateTaken ? utcToYmd(holidayTransaction?.dateTaken) : "-"}
          </TableCell>
          <TableCell width="200px">
            {holidayTransaction?.isAccrual
              ? formatCurrency(holidayTransaction?.amountInMoney)
              : "-"}
          </TableCell>
          <TableCell width="200px">
            {holidayTransaction?.isAccrual
              ? "-"
              : formatCurrency(holidayTransaction?.amountInMoney)}
          </TableCell>
          <TableCell width="200px">{holidayTransaction?.holidayYear}</TableCell>
          <TableCell width="200px">
            {holidayTransaction?.holidayBalance?.remainingHolidayMoney &&
              formatCurrency(holidayTransaction?.holidayBalance?.remainingHolidayMoney)}
          </TableCell>
          <TableCell width="200px">
            {holidayTransaction?.holidayBalance?.remainingHolidayDays}
          </TableCell>
        </TableRow>
      ),
      [queryId]
    )
  );
}

function TableSkeleton() {
  return (
    <TableRow disableHover>
      <TableCell width="200px">-</TableCell>
      <TableCell width="200px">-</TableCell>
      <TableCell width="200px">-</TableCell>
      <TableCell width="200px">-</TableCell>
      <TableCell width="200px">-</TableCell>
      <TableCell width="200px">-</TableCell>
      <TableCell width="200px">-</TableCell>
    </TableRow>
  );
}

export default function HolidayTransactionsTable({
  employmentId,
  holidayYear = "",
  openDialog = () => {},
}) {
  const { data, loading, error, queryId } = useGetHolidayTransactions({
    contractorProductId: employmentId,
    holidayYear,
  });
  const isLoaded = Boolean(data?.value);

  return (
    <Panel maxWidth="100%">
      <Table>
        <TableHeader>
          <TableHeaderColumn width="90%">{`Holiday Details ${holidayYear}`}</TableHeaderColumn>
          <TableHeaderColumn width="10%">
            <Button onClick={openDialog}>Book Holiday</Button>
          </TableHeaderColumn>
        </TableHeader>
        <TableHeader>
          <TableHeaderColumn width="200px">Date Created</TableHeaderColumn>
          <TableHeaderColumn width="200px">Date Taken</TableHeaderColumn>
          <TableHeaderColumn width="200px">In</TableHeaderColumn>
          <TableHeaderColumn width="200px">Out</TableHeaderColumn>
          <TableHeaderColumn width="200px">Holiday Year</TableHeaderColumn>
          <TableHeaderColumn width="200px">Balance</TableHeaderColumn>
          <TableHeaderColumn width="200px">Days</TableHeaderColumn>
        </TableHeader>
        <TableBody>
          {isLoaded ? (
            <PureTable data={data} queryId={queryId} />
          ) : data?.errors ? (
            data?.errors.map((error) => <div>{error}</div>)
          ) : (
            <TableSkeleton />
          )}
        </TableBody>
        <TableFooter>
          {loading && <BoldText>Loading...</BoldText>}
          {error && <BoldText>A network error occured.</BoldText>}
          <QueryPaginator
            itemsTotal={data?.value?.itemsTotal}
            itemsRetrieved={data?.value?.itemsRetrieved}
          />
        </TableFooter>
      </Table>
    </Panel>
  );
}
