import React from "react";

import { Content } from "@backslashbuild/pp-ui";

import AdvanceFeedbackTable from "../modules/advances/AdvanceFeedbackTable";

function AdvanceFeedback() {
  return (
    <Content>
      <AdvanceFeedbackTable />
    </Content>
  );
}

export default AdvanceFeedback;
