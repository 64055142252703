import React from "react";

function GreenTick() {
  return (
    <svg width={18} height={24} viewBox="0 0 24 24" fill="none">
      <circle cx={12} cy={12} r={12} fill="#5AC775" />
      <path
        d="M17.666 4.938l-7.332 9.665L6 10.272l-2 2 6.666 6.665 9.334-12-2.334-2z"
        fill="#FFFFFF"
      />
    </svg>
  );
}

export default GreenTick;
