/* eslint-disable */
import React from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useGetEpsItems } from "./api";
import { isTextNullOrEmpty, utcToLocalText } from "./utils";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";
import ViewHmrcErrors from "./ViewHmrcErrors";
import ViewJsonRequest from "./ViewJsonRequest";
import GreenTick from "../../components/GreenTick";

import {
  TableRow,
  Table,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableFooter,
  PageLoading,
  PanelColumn,
  PanelHeading,
  Panel,
  PanelColumnSpacer,
} from "@backslashbuild/pp-ui";

function ParseQuery(search) {
  const parsedQueryString = queryString.parse(search);

  return `?${queryString.stringify({ ...parsedQueryString })}`;
}

function parseSubmissionStatus(submission) {
  let status = "";

  if (submission?.hasErrors) {
    status = "Failed";
  } else {
    if (!submission?.isSubmitted) {
      status = "Preparing submission";
    } else if (submission?.isSubmitted && !submission?.isPollingDone) {
      status = "Processing";
    } else if (submission?.isPollingDone) status = "Done";
  }

  return status;
}

function parseErrors(errors) {
  let result = <></>;

  if (errors) {
    errors?.map((error) => {
      if (error.message.includes("found HMRC errors")) {
        const messageComponents = error.message?.split("=");
        const rtiInformationId = messageComponents[1]?.replace(" ", "").replace(".", "");

        result = <ViewHmrcErrors rtiInformationId={rtiInformationId} />;
      } else {
        result = <div>{`${error.requestType}: ${error.message}`}</div>;
      }
    });
  }

  return result;
}

const c = {
  dateCreated: "22%",
  companyName: "20%",
  status: "10%",
  isFinalSubmission: "10%",
  errors: "38%",
};

const totalWidth = Object.keys(c).reduce((total, key) => (total += c[key]), 0);

function PureTable({ data, queryId, onItemClick }) {
  return React.useMemo(() =>
    data?.value?.items?.map(
      (submission) => (
        <TableRow onClick={() => onItemClick(submission)}>
          <TableCell width={c.dateCreated}>
            {utcToLocalText(submission.dateCreated, "dd/MM/yyyy HH:mm")}
          </TableCell>
          <TableCell width={c.companyName}>{submission.company?.name}</TableCell>
          <TableCell width={c.status}>{parseSubmissionStatus(submission)}</TableCell>
          <TableCell width={c.isFinalSubmission}>
            {submission?.isFinalSubmission && <GreenTick />}
          </TableCell>
          <TableCell width={c.errors}>{parseErrors(submission.errors)}</TableCell>
        </TableRow>
      ),
      [queryId]
    )
  );
}

export default function EpsTable() {
  const [selectedItemId, setSelectedItemId] = React.useState(null);
  const location = useLocation();
  const search = usePagination(location.search);
  const query = ParseQuery(search);
  const { data, loading, error, queryId, refetch } = useGetEpsItems(query);

  if (loading) return <PageLoading />;
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  return (
    <>
      <PanelColumn width={800}>
        {/* Temporary fix for Safari not rendering ui components correctly */}
        <div style={{ width: "100%", height: "77vh" }}>
          <Table standardTable width={totalWidth}>
            <TableHeader>
              <TableHeaderColumn width={c.dateCreated}>Date Created</TableHeaderColumn>
              <TableHeaderColumn width={c.companyName}>Company</TableHeaderColumn>
              <TableHeaderColumn width={c.status}>Status</TableHeaderColumn>
              <TableHeaderColumn width={c.isFinalSubmission}>Final Submission</TableHeaderColumn>
              <TableHeaderColumn width={c.errors}>Errors</TableHeaderColumn>
            </TableHeader>
            <TableBody fullPageTable={true}>
              <PureTable
                data={data}
                queryId={queryId}
                onItemClick={(item) => setSelectedItemId(item?.id)}
              />
            </TableBody>
            <TableFooter>
              <QueryPaginator
                itemsTotal={data?.value?.itemsTotal}
                itemsRetrieved={data?.value?.itemsRetrieved}
              />
            </TableFooter>
          </Table>
        </div>
      </PanelColumn>
      <PanelColumnSpacer />
      <PanelColumn>
        <Panel>
          <PanelHeading>Data view</PanelHeading>
          {!Boolean(selectedItemId) && "Select a submission to view the request data."}
          {Boolean(selectedItemId) && <ViewJsonRequest requestId={selectedItemId} />}
        </Panel>
      </PanelColumn>
    </>
  );
}
