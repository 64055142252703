import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  TableRow,
  Table,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableFooter,
  TableBody,
  BoldText,
} from "@backslashbuild/pp-ui";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";
import { useGet } from "./api";
import { utcToLocalText } from "../../utils";
const c = {
  weekNumber: 50,
  payrollStartDate: 114,
  payrollEndDate: 114,
  workStartDate: 114,
  workEndDate: 114,
  dateCreated: 114,
};
const totalWidth = Object.keys(c).reduce((total, key) => (total += c[key]), 0);

function PureTable({ data, queryId }) {
  return React.useMemo(() =>
    data.value.items.map(
      (item) => (
        <TableRow key={item.id}>
          <TableCell width={c.weekNumber}>{item.weekNumber}</TableCell>
          <TableCell width={c.payrollStartDate}>{utcToLocalText(item.payrollStartDate)}</TableCell>
          <TableCell width={c.payrollEndDate}>{utcToLocalText(item.payrollEndDate)}</TableCell>
          <TableCell width={c.workStartDate}>{utcToLocalText(item.workingStartDate)}</TableCell>
          <TableCell width={c.workEndDate}>{utcToLocalText(item.workingEndDate)}</TableCell>
          <TableCell width={c.dateCreated}>{utcToLocalText(item.dateCreated)}</TableCell>
        </TableRow>
      ),
      [queryId]
    )
  );
}

function TableSkeleton() {
  return (
    <TableRow>
      <TableCell width={c.name}>-</TableCell>
    </TableRow>
  );
}

function TaxWeeksTable() {
  const { data, loading, error, queryId } = useGet("tax-weeks");

  const isLoaded = Boolean(data?.value);

  return (
    <Table width={totalWidth} standardTable>
      <TableHeader>
        <TableHeaderColumn width={c.weekNumber}>No.</TableHeaderColumn>
        <TableHeaderColumn width={c.payrollStartDate}>Payroll Start</TableHeaderColumn>
        <TableHeaderColumn width={c.payrollEndDate}>Payroll End</TableHeaderColumn>
        <TableHeaderColumn width={c.workStartDate}>Work Start</TableHeaderColumn>
        <TableHeaderColumn width={c.workEndDate}>Work End</TableHeaderColumn>
        <TableHeaderColumn width={c.dateCreated}>Date Created</TableHeaderColumn>
      </TableHeader>
      <TableBody>
        {isLoaded ? (
          <PureTable data={data} queryId={queryId} />
        ) : data?.errors ? (
          data?.errors.map((error) => <div>{error}</div>)
        ) : (
          <TableSkeleton />
        )}
      </TableBody>
    </Table>
  );
}

export default TaxWeeksTable;
