import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  TableRow,
  Table,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableFooter,
  TableBody,
  BoldText,
  Pill,
  Button,
  ExpandButton,
} from "@backslashbuild/pp-ui";
import { useGetSchedule, useDownloadBreakdown } from "./api";
import { formatCurrency } from "../../utils";
import DebugPopover from "../../components/DebugPopover";

function ScheduleTotals({ id }) {
  const query = useGetSchedule(id);
  const schedule = query?.data?.value;
  const isLoaded = Boolean(schedule);

  const all = {
    employerNIC: 0,
    employerPension: 0,
    apprenticeshipLevy: 0,
    payeFee: 0,
    peoUplift: 0,
    net: 0,
    vatExempt: 0,
    vaTable: 0,
    vat: 0,
    invoiceTotal: 0,
  };

  if (schedule?.productTotals) {
    schedule.productTotals.forEach((product) => {
      all.employerNIC += product.employerNIC;
      all.employerPension += product.employerPension;
      all.apprenticeshipLevy += product.apprenticeshipLevy;
      all.payeFee += product.payeFee;
      all.peoUplift += product.peoUplift;
      all.net += product.net;
      all.vatExempt += product.vatExempt;
      all.vaTable += product.vaTable;
      all.vat += product.vat;
      all.invoiceTotal += product.invoiceTotal;
    });
  }

  return (
    <Table standardTable>
      <TableHeader>
        <TableHeaderColumn width={100}>Product</TableHeaderColumn>
        <TableHeaderColumn width={50}>Gross</TableHeaderColumn>
        <TableHeaderColumn width={50}>Uplift</TableHeaderColumn>
        <TableHeaderColumn width={50}>NIC</TableHeaderColumn>
        <TableHeaderColumn width={50}>App Levy</TableHeaderColumn>
        <TableHeaderColumn width={50}>Pens</TableHeaderColumn>
        <TableHeaderColumn width={50}>Fee</TableHeaderColumn>
        <TableHeaderColumn width={50}>VAT Exempt</TableHeaderColumn>
        <TableHeaderColumn width={50}>VATable</TableHeaderColumn>
        <TableHeaderColumn width={50}>VAT</TableHeaderColumn>
        <TableHeaderColumn width={50}>Invoice Total</TableHeaderColumn>
        <TableHeaderColumn width={100}>Breakdown</TableHeaderColumn>

        {/* PEO: net uplifyt, vat total */}
        {/* PAYE  */}
        {/* PSPRO net vatexempt vatable vat app levey total */}
      </TableHeader>
      <TableBody>
        {schedule?.productTotals?.map((product) => (
          <TableRow disableHover>
            <TableCell width={100}>
              <DebugPopover data={product}>{product.productName}</DebugPopover>
            </TableCell>
            <TableCell width={50}>{formatCurrency(product.net)}</TableCell>
            <TableCell width={50}>{formatCurrency(product.peoUplift)}</TableCell>
            <TableCell width={50}>{formatCurrency(product.employerNIC)}</TableCell>
            <TableCell width={50}>{formatCurrency(product.apprenticeshipLevy)}</TableCell>
            <TableCell width={50}>{formatCurrency(product.employerPension)}</TableCell>
            <TableCell width={50}>{formatCurrency(product.payeFee)}</TableCell>
            <TableCell width={50}>{formatCurrency(product.vatExempt)}</TableCell>
            <TableCell width={50}>{formatCurrency(product.vaTable)}</TableCell>
            <TableCell width={50}>{formatCurrency(product.vat)}</TableCell>
            <TableCell width={50}>{formatCurrency(product.invoiceTotal)}</TableCell>
            <TableCell width={100}>
              <DownloadBreakdownButton
                scheduleId={id}
                productId={product.productId}
                fileName={`${schedule.agencyNumber}-${schedule.weekNumber}-${product.productName}-Breakdown`}
              />
            </TableCell>
          </TableRow>
        ))}

        <TableRow disableHover>
          <TableCell summary width={100}>
            All products
          </TableCell>
          <TableCell summary width={50}>
            {formatCurrency(all?.net)}
          </TableCell>
          <TableCell summary width={50}>
            {formatCurrency(all?.peoUplift)}
          </TableCell>
          <TableCell summary width={50}>
            {formatCurrency(all?.employerNIC)}
          </TableCell>
          <TableCell summary width={50}>
            {formatCurrency(all?.apprenticeshipLevy)}
          </TableCell>
          <TableCell summary width={50}>
            {formatCurrency(all?.employerPension)}
          </TableCell>
          <TableCell summary width={50}>
            {formatCurrency(all?.payeFee)}
          </TableCell>
          <TableCell summary width={50}>
            {formatCurrency(all?.vatExempt)}
          </TableCell>
          <TableCell summary width={50}>
            {formatCurrency(all?.vaTable)}
          </TableCell>
          <TableCell summary width={50}>
            {formatCurrency(all?.vat)}
          </TableCell>
          <TableCell summary width={50}>
            {formatCurrency(all?.invoiceTotal)}
          </TableCell>
          <TableCell summary width={100}></TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

function DownloadBreakdownButton({ scheduleId, productId, fileName }) {
  const downloadBreakdown = useDownloadBreakdown(scheduleId, productId, fileName);

  async function handleClickDownloadBreakdown() {
    downloadBreakdown();
  }

  return <Button onClick={handleClickDownloadBreakdown}>Breakdown</Button>;
}

export default ScheduleTotals;
