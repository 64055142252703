import React from "react";
import { useHistory } from "react-router-dom";
import { useGet } from "./api";
import { PageHeadingArea, PageTitle, BackArrow } from "@backslashbuild/pp-ui";

export default function EmploymentPageHeader({ accountNumber }) {
  const { data, loading, error } = useGet("employments/employment/" + accountNumber);

  const history = useHistory();

  function handleRender() {
    if (loading) return "Loading";

    if (data != null && !data.isSuccess) return "Error loading employment";

    return `NEST Employment: ${data?.value?.forename} ${data?.value?.surname}`;
  }

  return (
    <PageHeadingArea>
      <BackArrow onClick={() => history.goBack()} />
      <PageTitle>{handleRender()}</PageTitle>
    </PageHeadingArea>
  );
}
