import React from "react";
import { PanelRow, PanelSubheading, Link } from "@backslashbuild/pp-ui/dist/index.es";
import EmploymentEnrolmentStatus from "./EmploymentEnrolmentStatus";

export default function EmploymentEnrolmentStatusRow({ accountNumber }) {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  return (
    <>
      <PanelSubheading
        rightAction={
          <Link component={Link} onClick={() => setDialogOpen(true)}>
            Edit
          </Link>
        }
      >
        Enrolment Status
      </PanelSubheading>
      <PanelRow>
        <EmploymentEnrolmentStatus
          accountNumber={accountNumber}
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
        />
      </PanelRow>
    </>
  );
}
