import React from "react";

import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useGetPayslips } from "./api";
import { isTextNullOrEmpty, utcToLocalText } from "./utils";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";
import GreenTick from "../../components/GreenTick";

import {
  TableRow,
  Table,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableFooter,
  PageLoading,
} from "@backslashbuild/pp-ui";

export default function PayslipsTable() {
  const location = useLocation();
  const search = usePagination(location.search);
  const query = ParseQuery(search);
  const { data, loading, error, queryId, refetch } = useGetPayslips(query);

  if (loading) return <PageLoading />;
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  return (
    <>
      <Table
        sticky
        stickyFooter={
          <TableFooter sticky>
            <QueryPaginator
              itemsTotal={data?.value?.itemsTotal}
              itemsRetrieved={data?.value?.itemsRetrieved}
            />
          </TableFooter>
        }
        footerHeight={TableFooter.height}
      >
        <TableHeader sticky>
          <TableHeaderColumn sticky>Name</TableHeaderColumn>
          <TableHeaderColumn sticky>Employment No.</TableHeaderColumn>
          <TableHeaderColumn sticky>Account No.</TableHeaderColumn>
          <TableHeaderColumn sticky>Payslip No.</TableHeaderColumn>
          <TableHeaderColumn sticky>Date Created</TableHeaderColumn>
          <TableHeaderColumn sticky>Tax Year</TableHeaderColumn>
          <TableHeaderColumn sticky>Tax Week</TableHeaderColumn>
          <TableHeaderColumn sticky>Amount</TableHeaderColumn>
          <TableHeaderColumn sticky>Paid</TableHeaderColumn>
          <TableHeaderColumn sticky>Locked</TableHeaderColumn>
          <TableHeaderColumn sticky>Blocked</TableHeaderColumn>
          <TableHeaderColumn sticky>Agency Numbers</TableHeaderColumn>
        </TableHeader>
        <TableBody sticky footerHeight={TableFooter.height}>
          <PureTable data={data} queryId={queryId} />
        </TableBody>
      </Table>
    </>
  );
}

function PureTable({ data, queryId }) {
  return React.useMemo(() =>
    data?.value?.items?.map(
      (payslip) => (
        <TableRow>
          <TableCell>{payslip?.name}</TableCell>
          <TableCell>{payslip?.employmentNumber}</TableCell>
          <TableCell>{payslip?.accountNumber}</TableCell>
          <TableCell>{payslip?.payslipNumber}</TableCell>
          <TableCell>{utcToLocalText(payslip?.dateCreated, "dd/MM/yyyy HH:mm")}</TableCell>
          <TableCell>{payslip?.taxYear}</TableCell>
          <TableCell>{payslip?.taxWeek}</TableCell>
          <TableCell>£{payslip?.amount.toFixed(2)}</TableCell>
          <TableCell>{payslip?.isPaid && <GreenTick />}</TableCell>
          <TableCell>{payslip?.isLockedToBACSRun && <GreenTick />}</TableCell>
          <TableCell>{payslip?.isBlocked && <GreenTick />}</TableCell>
          <TableCell>{payslip?.agencyNumbers}</TableCell>
        </TableRow>
      ),
      [queryId]
    )
  );
}

function ParseQuery(search) {
  const parsedQueryString = queryString.parse(search);

  return `?${queryString.stringify({ ...parsedQueryString })}`;
}
