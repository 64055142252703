import React from "react";
import {
  PageLoading,
  PanelDialog,
  PanelHeading,
  PanelActions,
  PanelRow,
  Button,
  Label,
  QueryDropdown,
} from "@backslashbuild/pp-ui";
import { useCreateSchedule } from "./api";
import { useHistory } from "react-router-dom";
import { publish } from "../../pubSub";
import AgencyPicker from "./AgencyPicker";
import ContractorProductPicker from "./ContractorProductPicker";
import TaxWeekPicker from "../tax-calendar/TaxWeekPicker";

export default function CreateScheduleDialog() {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  function handleCancel() {
    setDialogOpen(false);
  }

  return (
    <>
      <Button large onClick={() => setDialogOpen(true)}>
        Create Schedule
      </Button>
      <PanelDialog onClose={handleCancel} open={dialogOpen}>
        <PanelHeading>Select Agency and Week before submitting</PanelHeading>
        <CreateScheduleForm onCancel={handleCancel} />
      </PanelDialog>
    </>
  );
}

function CreateScheduleForm({ onCancel }) {
  const [agencyId, setAgencyId] = React.useState(null);
  const [contractorProductId, setContractorProductId] = React.useState(null);
  const [taxWeekId, setTaxWeekId] = React.useState(null);

  const history = useHistory();
  const post = useCreateSchedule();

  async function handleSubmit() {
    try {
      const result = await post({ agencyId, taxWeekId });

      if (!result.isSuccess) {
        var errors = result.errors;

        publish(
          "notification",
          <ul>
            {errors.map((error) => {
              return <li>{error}</li>;
            })}
          </ul>
        );

        return;
      }

      const id = result.value;
      publish("notification", "New Schedule Created");
      history.push(`/schedule-c/${id}`);
    } catch (error) {
      publish("notification", "Network error occured.");
    }
  }

  return (
    <>
      <PanelRow>
        <Label label="Agency">
          <AgencyPicker value={agencyId} onChange={setAgencyId} />
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Week Number">
          <TaxWeekPicker value={taxWeekId} onChange={setTaxWeekId} />
        </Label>
      </PanelRow>
      <PanelActions>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="primary" disabled={!agencyId || !taxWeekId} onClick={handleSubmit}>
          Submit
        </Button>
      </PanelActions>
    </>
  );
}
