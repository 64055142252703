import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@backslashbuild/pp-ui";
import {
  Content,
  PageTitle,
  PanelColumn,
  PageHeadingArea,
  PanelColumnSet,
} from "@backslashbuild/pp-ui";

import ContactForm from "../modules/agencies/ContactForm";

export default function EditAgencyContact() {
  const { contactId } = useParams();

  return (
    <Content scrollable>
      <PageHeadingArea>
        <PageTitle>Edit Contact</PageTitle>
      </PageHeadingArea>

      <PanelColumnSet>
        <PanelColumn>
          <ContactForm id={contactId} />
        </PanelColumn>
      </PanelColumnSet>
    </Content>
  );
}
