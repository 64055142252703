import React from "react";
import { Button } from "@backslashbuild/pp-ui";
import { CSVDownloader } from "react-papaparse";

export default function ExportInvoicesCsvButton({ invoices }) {
  const data = invoices?.map((el) => {
    return {
      TXTypeID: el.transactionTypeId,
      TXDate: el.dateCreated,
      DocNo: el.invoiceNumber,
      agencyname: el.client,
      IDNo: el.agencyNumber,
      WeekID: el.weekNumber,
      Net: el.totalInvoiceNet,
      VAT: el.totalInvoiceVat,
      Total: el.totalInvoiceValue,
    };
  });

  return (
    <Button large>
      <CSVDownloader data={data} filename={"Invoices"} type={"link"}>
        Export
      </CSVDownloader>
    </Button>
  );
}
