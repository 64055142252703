import React from "react";
import {
  TableRow,
  Table,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  StatusIndicator,
  PanelActions,
  Panel,
  Button,
  DatePicker,
  PanelRow,
  TextInput,
  CheckBox,
} from "@backslashbuild/pp-ui";
import { useGet, usePost } from "./api";
import { utcToYmd } from "../contractors/utils";
import {
  Dropdown,
  DropdownOption,
  Label,
  TextInputMultiline,
} from "@backslashbuild/pp-ui/dist/index.es";
import { publish } from "../../pubSub";

/*
public bool IsEnabled { get; set; } 
*/

function LegalDeductionForm({ id, onClose }) {
  const { data, loading, error, queryId } = useGet(`legal-deductions/${id}`);
  const post = usePost("legal-deductions/update");

  const [edits, setEdits] = React.useState({
    legalDeductionId: id,
    contractorId: null,
    employmentId: null,
    dateOfIssue: null,
    legalDeductionTypeId: null,
    courtCaseNumber: null,
    normalDeductionRate: null,
    protectedEarningsRate: null,
    protectedEarningsProportion: null,
    totalOwed: null,
    paymentRateTypeId: null,
    isEnabled: true,
    notes: null,
  });

  function handleUpdate() {
    post(edits).then((res) => {
      if (res?.isSuccess) {
        publish("notification", "Updated successfully.");
      } else {
        if (res?.errors?.length > 0) {
          res.errors.forEach((e) => {
            publish("notification", e);
          });
        } else {
          publish("notification", "Error updating.");
        }
      }
    });
  }

  return (
    <Panel>
      <TableHeader>
        <TableHeaderColumn>Update legal deduction</TableHeaderColumn>
      </TableHeader>
      <PanelRow>
        <Label label="Date of issue">
          <DatePicker
            value={edits?.dateOfIssue ?? data?.value?.dateOfIssue}
            onChange={(v) => {
              setEdits({
                ...edits,
                dateOfIssue: utcToYmd(v),
              });
            }}
          ></DatePicker>
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Type">
          <Dropdown
            value={edits?.legalDeductionTypeId ?? data?.value?.legalDeductionTypeId}
            onChange={(e) => {
              setEdits({
                ...edits,
                legalDeductionTypeId: e.target.value,
              });
            }}
          >
            <DropdownOption value={"deo"}>Deduction from Earnings Orders</DropdownOption>
            <DropdownOption value={"priority_aeo"}>
              Priority Attachment of Earnings Orders
            </DropdownOption>
            <DropdownOption value={"non_priority_aeo"}>
              Non-priority Attachment of Earnings Orders
            </DropdownOption>
          </Dropdown>
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Court Case Number">
          <TextInput
            value={edits?.courtCaseNumber ?? data?.value?.courtCaseNumber}
            onChange={(e) =>
              setEdits({
                ...edits,
                courtCaseNumber: e.target.value,
              })
            }
          ></TextInput>
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Normal Deduction Rate">
          <TextInput
            value={edits?.normalDeductionRate ?? data?.value?.normalDeductionRate}
            type="number"
            onChange={(e) =>
              setEdits({
                ...edits,
                normalDeductionRate: Number(e.target.value),
              })
            }
          ></TextInput>
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Protected Earnings Rate">
          <TextInput
            value={edits?.protectedEarningsRate ?? data?.value?.protectedEarningsRate}
            type="number"
            onChange={(e) =>
              setEdits({
                ...edits,
                protectedEarningsRate: Number(e.target.value),
              })
            }
          ></TextInput>
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Protected Earnings Proportion">
          <TextInput
            value={edits?.protectedEarningsProportion ?? data?.value?.protectedEarningsProportion}
            type="number"
            onChange={(e) =>
              setEdits({
                ...edits,
                protectedEarningsProportion: Number(e.target.value),
              })
            }
          ></TextInput>
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Total owed">
          <TextInput
            value={edits?.totalOwed ?? data?.value?.totalOwed}
            type="number"
            onChange={(e) =>
              setEdits({
                ...edits,
                totalOwed: Number(e.target.value),
              })
            }
          ></TextInput>
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Payment rate">
          <Dropdown
            value={edits?.paymentRateTypeId ?? data?.value?.paymentRateTypeId}
            onChange={(e) => {
              setEdits({
                ...edits,
                paymentRateTypeId: e.target.value,
              });
            }}
          >
            <DropdownOption value={"weekly"}>Weekly</DropdownOption>
            <DropdownOption value={"monthly"}>Monthly</DropdownOption>
          </Dropdown>
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Notes">
          <TextInputMultiline
            value={edits?.notes ?? data?.value?.notes}
            rows={10}
            onChange={(e) =>
              setEdits({
                ...edits,
                notes: e.target.value,
              })
            }
          ></TextInputMultiline>
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Is enabled">
          <CheckBox
            checked={edits?.isEnabled ?? data?.value?.isEnabled}
            onChange={(e) => {
              setEdits({
                ...edits,
                isEnabled: !edits?.isEnabled,
              });
            }}
          ></CheckBox>
        </Label>
      </PanelRow>
      <PanelActions>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button onClick={() => handleUpdate()}>Save</Button>
      </PanelActions>
    </Panel>
  );
}

export default LegalDeductionForm;
