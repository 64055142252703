import React from "react";
import { Link as RouterLink, useLocation, useHistory } from "react-router-dom";
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  PageLoading,
  PageHeadingArea,
  PanelDialog,
  PageHeadingAreaSpacer,
  PageTitle,
  Button,
  TableFooter,
  makeStyles,
} from "@backslashbuild/pp-ui";
import { utcToLocalText, isTextNullOrEmpty } from "./utils";
import { useSearchItems } from "./api";
import NewAgencyForm from "./NewAgencyForm";
import AgenciesFiltering from "./AgenciesFiltering";
import queryString from "query-string";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";

const useStyles = makeStyles({
  newAgencyButton: {
    marginLeft: 10,
  },
});

export default function AgenciesTable() {
  const classes = useStyles();
  const location = useLocation();
  const [addNewAgency, setAddNewAgency] = React.useState(false);
  const search = usePagination(location.search);
  const parsedQueryString = queryString.parse(search);
  const { data, loading, error, queryId } = useSearchItems(
    `?${queryString.stringify({ ...parsedQueryString })}`
  );
  const history = useHistory();

  if (loading || !data) return <PageLoading />;
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  if (data.value.items?.length == 1) {
    history.push(`/agency/${data.value.items[0]?.id}`);
  }

  return (
    <>
      <PageHeadingArea>
        <PageTitle>Agencies</PageTitle>
        <PageHeadingAreaSpacer grow />
        <AgenciesFiltering />
        <div className={classes.newAgencyButton}>
          <Button
            large
            onClick={() => {
              setAddNewAgency(true);
            }}
          >
            New Agency
          </Button>
        </div>
      </PageHeadingArea>
      <Table fullPageTable={true}>
        <TableHeader>
          <TableHeaderColumn width="14%">Agency Number</TableHeaderColumn>
          <TableHeaderColumn width="14%">Name</TableHeaderColumn>
          <TableHeaderColumn width="14%">Date Joined</TableHeaderColumn>
          <TableHeaderColumn width="14%">Email</TableHeaderColumn>
          <TableHeaderColumn width="14%">Tel</TableHeaderColumn>
          <TableHeaderColumn width="14%">Account Owner</TableHeaderColumn>
          <TableHeaderColumn width="14%">Agency Status</TableHeaderColumn>
        </TableHeader>
        <TableBody fullPageTable={true} footerHeight={TableFooter.height}>
          {data.value.items?.length < 1 && (
            <TableRow>
              <TableCell>
                <div>No agencies matching this query</div>
              </TableCell>
            </TableRow>
          )}
          {data.value.items.map((agency) => (
            <TableRow to={`/agency/${agency.id}`} component={RouterLink}>
              <TableCell width="14%">{agency.agencyNumber}</TableCell>
              <TableCell width="14%">{agency.name}</TableCell>
              <TableCell width="14%">{utcToLocalText(agency.dateCreated)}</TableCell>
              <TableCell width="14%">{agency.email}</TableCell>
              <TableCell width="14%">{agency.phoneNumber}</TableCell>
              <TableCell width="14%">{agency.accountOwnerName}</TableCell>
              <TableCell width="14%">{agency?.isProspect ? "Lead" : "Live"}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <QueryPaginator
            itemsTotal={data?.value?.itemsTotal}
            itemsRetrieved={data?.value?.itemsRetrieved}
          />
        </TableFooter>
      </Table>
      <PanelDialog
        open={addNewAgency}
        onClose={() => {
          setAddNewAgency(false);
        }}
      >
        <NewAgencyForm
          onClose={() => {
            setAddNewAgency(false);
          }}
        />
      </PanelDialog>
    </>
  );
}
