import React from "react";
import QueryFiltering from "../../containers/QueryFiltering";
import { PanelRow, Label, TextInput, CheckBox, RadioGroup, Radio } from "@backslashbuild/pp-ui";

function SchedulesFiltering() {
  return (
    <QueryFiltering
      searchStringKey="agencyName"
      getTagsFromState={(state) => {
        const tags = [];
        if (state.hasErrors === "yes") tags.push({ key: "errors", value: "Only" });
        if (state.hasErrors === "no") tags.push({ key: "errors", value: "Exclude" });
        if (state.isClosed === "yes") tags.push({ key: "closed", value: "Only" });
        if (state.isClosed === "no") tags.push({ key: "closed", value: "Exclude" });
        return tags;
      }}
      getNextStateFromDeleteTag={(state, key) => {
        if (key === "errors") return { ...state, hasErrors: undefined };
        if (key === "closed") return { ...state, isClosed: undefined };
        return state;
      }}
      renderForm={(state, setState) => (
        <>
          <PanelRow>
            <Label label="Agency">
              <TextInput
                value={state.agency}
                onChange={(e) => setState({ ...state, agency: e.target.value })}
              />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Week number">
              <TextInput
                value={state.weekNumber}
                onChange={(e) => setState({ ...state, weekNumber: e.target.value })}
              />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Has Errors">
              <RadioGroup
                value={state.hasErrors}
                onChange={(e) => setState({ ...state, hasErrors: e.target.value })}
              >
                <Radio value="" label="Any" />
                <Radio value="yes" label="Yes" />
                <Radio value="no" label="No" />
              </RadioGroup>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Is Closed">
              <RadioGroup
                value={state.isClosed}
                onChange={(e) => setState({ ...state, isClosed: e.target.value })}
              >
                <Radio value="" label="Any" />
                <Radio value="yes" label="Yes" />
                <Radio value="no" label="No" />
              </RadioGroup>
            </Label>
          </PanelRow>
        </>
      )}
    />
  );
}

export default SchedulesFiltering;
