import {
  PageLoading,
  Panel,
  PanelHeading,
  PanelSpacer,
  PanelRow,
  Label,
  BoldText,
  Link,
  PanelDialog,
} from "@backslashbuild/pp-ui";
import React from "react";
import { useGet } from "./api";
import { isTextNullOrEmpty } from "./utils";
import ContractorLoginDetailsForm from "./ContractorLoginDetailsForm";
import ResetPasswordButton from "./ResetPasswordButton";
import { subscribe, unsubscribe } from "./pubSub";

export default function ContractorLoginDetailsPanel({ id }) {
  const [editLoginDetails, setEditLoginDetails] = React.useState(null);
  const { data, loading, error, refetch } = useGet(id);

  React.useEffect(() => {
    subscribe("contractor.loginDetails.updated", refetch);
    return () => unsubscribe("contractor.loginDetails.updated", refetch);
  }, [id]);

  if (loading) return <PageLoading />;

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    return (
      <>
        <PanelRow>
          <Label label="Email">
            <BoldText>{data?.value?.email}</BoldText>
          </Label>
        </PanelRow>
        <PanelRow>
          <ResetPasswordButton contractorId={id} />
        </PanelRow>
      </>
    );
  }

  return (
    <>
      <Panel>
        <PanelHeading rightAction={<Link onClick={() => setEditLoginDetails(id)}>Edit</Link>}>
          Login details
        </PanelHeading>
        <PanelSpacer />
        {handleRender()}
        <PanelSpacer />
      </Panel>
      <PanelDialog open={editLoginDetails}>
        <PanelHeading>Edit Login Details</PanelHeading>
        {editLoginDetails && (
          <ContractorLoginDetailsForm
            id={editLoginDetails}
            onClose={() => setEditLoginDetails(null)}
          />
        )}
      </PanelDialog>
    </>
  );
}
