import React from "react";
import {
  Button,
  PanelDialog,
  PanelHeading,
  PanelRow,
  PanelSpacer,
  PanelActions,
  BoldText,
} from "@backslashbuild/pp-ui";
import { useCreateInvoices, useCreateCreditNote, useGetSchedule } from "./api";
import { publish } from "../../pubSub";
import { useHistory } from "react-router-dom";

function CreateInvoicesButton({ id }) {
  const history = useHistory();

  const query = useGetSchedule(id);

  // you have to query to see if there are any currently staged pay runs and then disable the button.
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const createInvoices = useCreateInvoices();
  const createCreditNote = useCreateCreditNote();

  const loaded = Boolean(query?.data?.value);
  const isLocked = Boolean(query?.data?.value?.isLocked);

  async function handleCreateInvoice() {
    try {
      const response = await createInvoices({ scheduleId: id });

      if (response.isSuccess) {
        publish("notification", "Invoices created.");
      } else {
        publish(
          "notification",
          <div>
            Unable to create invoices
            <ul>
              {response.errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </div>
        );
      }
    } catch (e) {
      console.error(e, "error processing");
      publish("notification", "A network error occured.");
    }
  }

  async function handleCreateCreditNote() {
    try {
      const response = await createCreditNote({ scheduleId: id });

      if (response.isSuccess) {
        publish("notification", "Invoices created.");
      } else {
        publish(
          "notification",
          <div>
            Unable to create credit note
            <ul>
              {response.errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </div>
        );
      }
    } catch (e) {
      console.error(e, "error processing");
      publish("notification", "A network error occured.");
    }
  }

  return (
    <>
      <Button large disabled={!loaded} onClick={() => (loaded ? setDialogOpen(true) : null)}>
        {loaded ? (isLocked ? "Credit note" : "Create Invoices") : "loading..."}
      </Button>

      {isLocked ? (
        <PanelDialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <PanelHeading>Create Credit Note</PanelHeading>
          <PanelSpacer large />
          <PanelRow>
            <BoldText>
              All invoices for this schedule will be reversed with a credit note. Then the schedule
              will be unlocked.
            </BoldText>
          </PanelRow>
          <PanelSpacer large />
          <PanelActions>
            <Button variant="secondary" onClick={() => setDialogOpen(false)}>
              No, cancel
            </Button>
            <Button onClick={handleCreateCreditNote}>Yes, Create Credit Note</Button>
          </PanelActions>
          <PanelSpacer large />
        </PanelDialog>
      ) : (
        <PanelDialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <PanelHeading>Create Invoices</PanelHeading>
          <PanelSpacer large />
          <PanelRow>
            <BoldText>
              This schedule will be locked and invoices will be sent to the invoicing system.
            </BoldText>
          </PanelRow>
          <PanelSpacer large />
          <PanelActions>
            <Button variant="secondary" onClick={() => setDialogOpen(false)}>
              No, cancel
            </Button>
            <Button onClick={handleCreateInvoice}>Yes, Create Invoices</Button>
          </PanelActions>
          <PanelSpacer large />
        </PanelDialog>
      )}
    </>
  );
}

export default CreateInvoicesButton;
