import React from "react";
import { usePostReloadDraft } from "./api";
import { publish } from "./pubSub";

import { PanelDialog, PanelActions, Button, PanelRow, PanelHeading } from "@backslashbuild/pp-ui";

export default function ReloadDraftDialog({ open, onClose, draftId }) {
  const post = usePostReloadDraft(draftId);

  function handleConfirm() {
    post().then((res) => {
      if (res.isSuccess) {
        publish("notification", "Attempt Successful! The draft data has been reloaded.");
      } else {
        publish(
          "notification",
          <div>
            Attempt Failed
            <ul>
              {res?.errors?.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </div>
        );
      }
    });
  }

  return (
    <PanelDialog open={open} onClose={onClose}>
      <PanelHeading>Reload Draft Data</PanelHeading>
      <PanelRow>
        <p>
          Click on 'Confirm' to reload the draft employee data. You should do this if any relevant
          contractor data has been changed or if there are any new payslips issued for this tax
          period (corrections).
        </p>
        <p>
          Once the draft data has been sucessfully reloaded you will need to approve it again to
          begin the preparation protocol.
        </p>
      </PanelRow>
      <PanelActions>
        <Button variant="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleConfirm();
            onClose();
          }}
        >
          Confirm
        </Button>
      </PanelActions>
    </PanelDialog>
  );
}
