import {
  PageLoading,
  Panel,
  PanelActions,
  Button,
  PanelRow,
  Label,
  TextInput,
} from "@backslashbuild/pp-ui";
import React from "react";
import { useGet, usePost } from "./api";
import SMSTemplatePicker from "./SMSTemplatePicker";

export default function EmailTriggerForm({ alias, onClose }) {
  const { data, loading, error } = useGet(`email-trigger/${alias}`);
  const updateTrigger = usePost(`email-trigger/${alias}/update`);
  const [edits, setEdits] = React.useState({
    description: null,
    fK_TemplateId: null,
  });

  function handleRender() {
    if (loading || !data) return <>Loading...</>;

    if (data?.errors?.length > 0)
      return (
        <>
          {data?.errors?.map((error) => {
            return <>{error}</>;
          })}
        </>
      );

    return (
      <>
        <PanelRow>
          <Label label="Alias">
            <div>{data?.value?.alias}</div>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Description">
            <TextInput
              value={edits.description ?? data?.value?.description}
              onChange={(e) => setEdits({ ...edits, description: e.target.value })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="SMS Template">
            <SMSTemplatePicker
              value={edits.fK_TemplateId ?? data?.value?.fK_TemplateId}
              onChange={(e) => setEdits({ ...edits, fK_TemplateId: e.target.value })}
            />
          </Label>
        </PanelRow>
      </>
    );
  }

  function handleSave() {
    updateTrigger(edits);
  }

  return (
    <Panel>
      {handleRender()}
      <PanelActions>
        <Button variant="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleSave();
            onClose();
          }}
        >
          Save
        </Button>
      </PanelActions>
    </Panel>
  );
}
