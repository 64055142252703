import {
  Button,
  PanelActions,
  PageLoading,
  Panel,
  PanelDialog,
  PanelHeading,
  PanelSpacer,
} from "@backslashbuild/pp-ui";
import { PanelRow } from "@backslashbuild/pp-ui/dist/index.es";
import React from "react";
import { useGetFields, useUpdateField } from "./api";
import SpecialFormField from "./SpecialFormField";
import { isTextNullOrEmpty } from "./utils";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

const getBackgroundColor = (product) => {
  if (product === "People Umbrella") {
    return "#49C2BA";
  } else if (product === "PAYE") {
    return "#5AC775";
  } else if (product === "PEO") {
    return "#5CCBD9";
  } else if (product === "People Plus") {
    return "#5CCBD9";
  } else if (product === "PS Pro") {
    return "#5A5D9F";
  } else if (product === "Construction Industry Service" || product === "CIS") {
    return "#89649F";
  } else if (product === "PSC") {
    return "#C00";
  } else return "";
};

export default function RecommendationForm({
  id,
  onViewSamplePayslip,
  onEmailSamplePayslip,
  children,
}) {
  const { data, loading, error } = useGetFields({ id, formName: "recommendation" });
  const updateField = useUpdateField({ id, formName: "" });

  const history = useHistory();

  const params = queryString.parse(useLocation().search);
  const productdialog = params.productdialog || false;

  function onClose() {
    history.replace({
      search: "matchdialog=true",
    });
  }

  const productSelect = data?.value?.find(
    (field) => field.key === "ProductId" && field.schema.type === "radio"
  );
  const products = productSelect?.schema?.options;

  function onFieldClick(field) {
    if (field.schema.type === "link" && field.key.endsWith("-viewLink")) {
      onViewSamplePayslip(field.key.replace(/-viewLink/g, ""));
    } else if (field.schema.type === "link" && field.key.endsWith("-emailLink")) {
      onEmailSamplePayslip(field.key.replace(/-emailLink/g, ""));
    }
  }

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    return (
      <div>
        {data.value
          .filter(
            (field) =>
              field.schema.type === "text" &&
              field.displayName != "PSC" &&
              field.displayName != "CIS" &&
              field.displayName != "People Plus" &&
              field.displayName != "PS Pro"
          )
          .map((field) => (
            <div
              style={{
                backgroundColor: getBackgroundColor(field.displayName),
                padding: 10,
                margin: 10,
                color: getBackgroundColor(field.displayName) === "#fff" ? "#000" : "#fff",
                border: "1px solid #eee",
                borderRadius: 5,
                minWidth: 350,
              }}
            >
              <div style={{ fontSize: 20, marginBottom: 5, fontWeight: "bold" }}>
                {field.displayName}
              </div>
              <div style={{ fontSize: 17, marginBottom: 5, fontWeight: "bold" }}>{field.value}</div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  fontSize: 10,
                }}
              >
                <div
                  style={{ marginRight: 10, cursor: "pointer" }}
                  onClick={() =>
                    onViewSamplePayslip(
                      products.find((product) => product.displayName === field.displayName).value
                    )
                  }
                >
                  View
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    let r = window.confirm("Are you sure you want to email this payslip?");
                    if (r == true) {
                      onEmailSamplePayslip(
                        products.find((product) => product.displayName === field.displayName).value
                      );
                    }
                  }}
                >
                  Email
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  }

  function getOnSaveHandler(key) {
    return (value) => {
      updateField({ key, value });
    };
  }

  if (loading) return <PageLoading />;

  return (
    <>
      <Panel>
        <PanelHeading>Recommendations</PanelHeading>
        <PanelSpacer />
        {handleRender()}
        <PanelSpacer />
      </Panel>
      <PanelSpacer large />
      <Panel>
        <PanelHeading>Select a Product</PanelHeading>
        {data?.value
          ?.filter((field) => field.displayName === "Select a Product")
          ?.map((field) => (
            <SpecialFormField
              key={field.key}
              field={field}
              onSave={getOnSaveHandler(field.key)}
              onClick={() => onFieldClick(field)}
            />
          ))}
        <PanelRow>{children}</PanelRow>
      </Panel>
      <PanelDialog open={productdialog}>
        <Panel>
          <PanelHeading>Select a Product</PanelHeading>
          {data?.value
            ?.filter((field) => field.displayName === "Select a Product")
            ?.map((field) => (
              <SpecialFormField
                key={field.key}
                field={field}
                onSave={getOnSaveHandler(field.key)}
                onClick={() => onFieldClick(field)}
              />
            ))}
        </Panel>
        <PanelActions>
          <Button
            variant="secondary"
            onClick={() => {
              onClose();
            }}
          >
            Close
          </Button>
        </PanelActions>
      </PanelDialog>
    </>
  );
}
