import React from "react";
import {
  PanelDialog,
  PanelHeading,
  PanelActions,
  PanelRow,
  Button,
  Label,
} from "@backslashbuild/pp-ui";
import { useSetRowDescription } from "./api";
import { publish } from "../../pubSub";
import { Link, TextInput } from "@backslashbuild/pp-ui/dist/index.es";

export default function ScheduleDescriptionDialog({ row, scheduleId }) {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  function handleCancel() {
    setDialogOpen(false);
  }

  function handleRenderDescription(description) {
    if (description == null || description?.length < 1 || !Boolean(description)) {
      return (
        <small>
          <Link>
            <i>Add</i>
          </Link>
        </small>
      );
    }

    return (
      <small>
        <Link>{description}</Link>
      </small>
    );
  }

  return (
    <>
      <div onClick={() => setDialogOpen(true)}>{handleRenderDescription(row?.description)}</div>
      <PanelDialog onClose={handleCancel} open={dialogOpen}>
        <PanelHeading>Update description</PanelHeading>
        <ScheduleDescriptionForm onCancel={handleCancel} row={row} scheduleId={scheduleId} />
      </PanelDialog>
    </>
  );
}

function ScheduleDescriptionForm({ onCancel, row, scheduleId }) {
  const [value, setValue] = React.useState(row?.description ?? "");

  const post = useSetRowDescription(scheduleId);

  async function handleSubmit() {
    try {
      const result = await post({ rowId: row.id, description: value });

      if (!result.isSuccess) {
        var errors = result.errors;

        publish(
          "notification",
          <ul>
            {errors.map((error) => {
              return <li>{error}</li>;
            })}
          </ul>
        );

        return;
      }

      const id = result.value;
      publish("notification", "Description updated");
    } catch (error) {
      publish("notification", "Network error occured.");
    }
  }

  return (
    <>
      <PanelRow>
        <Label label="Description">
          <TextInput
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
        </Label>
      </PanelRow>
      <PanelActions>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="primary" disabled={value == row.description} onClick={handleSubmit}>
          Save
        </Button>
      </PanelActions>
    </>
  );
}
