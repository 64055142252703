import {
  PanelDialog,
  PanelHeading,
  PageLoading,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  Link,
  StatusIndicator,
} from "@backslashbuild/pp-ui";
import React from "react";
import { useGet } from "./api";
import { isTextNullOrEmpty } from "./utils";

import BranchForm from "./BranchForm";
import NewBranchForm from "./NewBranchForm";
import { subscribe, unsubscribe } from "./pubSub";

export default function BranchesTable({ agencyId }) {
  const [createBranch, setCreateBranch] = React.useState(null);
  const [editBranch, setEditBranch] = React.useState(null);
  const { data, loading, error, refetch } = useGet(`branches/${agencyId}`);

  React.useEffect(() => {
    subscribe("agency.branch.updated", refetch);
    return () => unsubscribe("agency.branch.updated", refetch);
  }, [agencyId]);

  if (loading) return <PageLoading />;

  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

  if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  return (
    <>
      <Table standardTable height={600} width={600}>
        <TableHeader>
          <TableHeaderColumn width="90%">Branches</TableHeaderColumn>
          <TableHeaderColumn width="10%">
            <Link onClick={() => setCreateBranch(agencyId)}>Add Branch</Link>
          </TableHeaderColumn>
        </TableHeader>
        <TableHeader>
          <TableHeaderColumn width="20%">Head Office</TableHeaderColumn>
          <TableHeaderColumn width="20%">Branch</TableHeaderColumn>
          <TableHeaderColumn width="20%">Telephone</TableHeaderColumn>
          <TableHeaderColumn width="20%">Email</TableHeaderColumn>
          <TableHeaderColumn width="20%">Account Manager</TableHeaderColumn>
        </TableHeader>
        <TableBody fullPageTable={false} footerHeight={0}>
          {data.value.items.map((branch) => (
            <TableRow onClick={() => setEditBranch(branch?.id)}>
              <TableCell width="20%">
                <StatusIndicator value={branch?.isHeadOffice} />
              </TableCell>
              <TableCell width="20%">{branch?.name}</TableCell>
              <TableCell width="20%">{branch?.phoneNumber}</TableCell>
              <TableCell width="20%">{branch?.email}</TableCell>
              <TableCell width="20%">{branch?.accountManagerName}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <PanelDialog open={editBranch}>
        <PanelHeading>Edit Branch</PanelHeading>
        {editBranch && <BranchForm id={editBranch} onClose={() => setEditBranch(null)} />}
      </PanelDialog>
      <PanelDialog open={createBranch}>
        <PanelHeading>Add Branch</PanelHeading>
        {createBranch && (
          <NewBranchForm agencyId={createBranch} onClose={() => setCreateBranch(null)} />
        )}
      </PanelDialog>
    </>
  );
}
