import React from "react";
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableFooter,
  PageLoading,
  PageHeadingArea,
  PageTitle,
  PageHeadingAreaSpacer,
  StatusIndicator,
  DateFormatted,
  Button,
  Link,
  PanelDialog,
} from "@backslashbuild/pp-ui";
import { isTextNullOrEmpty } from "./utils";
import { publish } from "../../pubSub";
import { useGetItems, usePost } from "./api";
import ContractorsFilter from "./ContractorsFilter";
import { useLocation, useHistory } from "react-router-dom";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";
import getApiUrl from "../../getApiUrl";
import EditP45Form from "./EditP45Form";

const c = {
  id: 100,
  contractorName: 100,
  contractorProduct: 100,
  reasonForLeaving: 100,
  isSwitchingProducts: 100,
  isProcessImmediately: 100,
  notes: 100,
};

function GenerateDocumentButton({ id, contractorProductId }) {
  const handlePost = usePost(
    `${id}/generate-document/contractor-p45Request?contractorProductId=${contractorProductId}`
  );

  return (
    <Button
      onClick={() => {
        handlePost().then((res) => {
          if (res?.isSuccess) {
            publish("notification", "P45 Generated");
            window.location.reload();
          } else {
            publish(
              "notification",
              <>
                <ul>
                  {res?.errors?.map((error) => (
                    <li>{error}</li>
                  ))}
                </ul>
              </>
            );
          }
        });
      }}
    >
      Generate
    </Button>
  );
}

function SendEmailButton({ id }) {
  const handlePost = usePost(`send-p45-email?p45RequestId=${id}`);

  return (
    <Button
      onClick={() => {
        handlePost().then((res) => {
          if (res?.isSuccess) {
            publish("notification", "P45 Generated");
            window.location.reload();
          } else {
            publish(
              "notification",
              <>
                <ul>
                  {res?.errors?.map((error) => (
                    <li>{error}</li>
                  ))}
                </ul>
              </>
            );
          }
        });
      }}
    >
      Send
    </Button>
  );
}

function ViewDocumentButton({ id }) {
  return (
    <Button
      onClick={() => {
        window.open(
          `${getApiUrl()}/contractors/generate-p45?p45RequestId=${id}`,
          "_blank",
          "noopener,noreferrer"
        );
      }}
    >
      View
    </Button>
  );
}

function PureTable({ data, queryId, setEditP45 }) {
  const history = useHistory();

  return React.useMemo(
    () =>
      data.value.items.map((contractorP45Request) => (
        <TableRow disableHover>
          <TableCell width="10%">{contractorP45Request.contractorProductAccountNumber}</TableCell>
          <TableCell width="10%">{contractorP45Request.contractorName}</TableCell>
          <TableCell width="10%">
            {contractorP45Request?.isProcessImmediately ? (
              <div>Immediate</div>
            ) : (
              contractorP45Request?.requestedLeaveDate && (
                <DateFormatted date={contractorP45Request?.requestedLeaveDate} />
              )
            )}
          </TableCell>
          <TableCell width="10%">
            {contractorP45Request?.leaveDate && (
              <DateFormatted date={contractorP45Request?.leaveDate} />
            )}
          </TableCell>
          <TableCell width="10%">
            {contractorP45Request?.documentEmailedDate && (
              <DateFormatted date={contractorP45Request?.documentEmailedDate} />
            )}
          </TableCell>
          <TableCell width="10%">{contractorP45Request.reasonForLeaving}</TableCell>
          <TableCell width="10%">
            <StatusIndicator value={contractorP45Request.isSwitchingProducts} />
          </TableCell>
          <TableCell width="10%">
            {contractorP45Request?.documentGeneratedDate ? (
              <ViewDocumentButton id={contractorP45Request?.id} />
            ) : (
              <GenerateDocumentButton
                id={contractorP45Request?.id}
                contractorProductId={contractorP45Request?.contractorProductId}
              />
            )}
          </TableCell>
          <TableCell width="10%">
            <Button onClick={() => setEditP45(contractorP45Request.id)}>Edit</Button>
          </TableCell>
          <TableCell width="10%">
            {contractorP45Request?.documentGeneratedDate && (
              <SendEmailButton id={contractorP45Request?.id} />
            )}
          </TableCell>
        </TableRow>
      )),
    [queryId]
  );
}

function P45Table() {
  const location = useLocation();
  const search = usePagination(location.search);
  const { data, loading, error, queryId } = useGetItems("contractor-p45Requests");
  const [editP45, setEditP45] = React.useState(null);

  if (loading || !data) return <PageLoading />;
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  return (
    <>
      <PageHeadingArea>
        <PageTitle>P45 Management</PageTitle>
        <PageHeadingAreaSpacer grow />
        <ContractorsFilter />
      </PageHeadingArea>
      <Table standardTable>
        <TableHeader>
          <TableHeaderColumn width="10%">ID</TableHeaderColumn>
          <TableHeaderColumn width="10%">Contractor Name</TableHeaderColumn>
          <TableHeaderColumn width="10%">Requested Date</TableHeaderColumn>
          <TableHeaderColumn width="10%">Leave Date</TableHeaderColumn>
          <TableHeaderColumn width="10%">Emailed Date</TableHeaderColumn>
          <TableHeaderColumn width="10%">Reason for Leaving</TableHeaderColumn>
          <TableHeaderColumn width="10%">Switching Products</TableHeaderColumn>
          <TableHeaderColumn width="30%">Actions</TableHeaderColumn>
        </TableHeader>
        <TableBody fullPageTable={true} footerHeight={TableFooter.height}>
          <PureTable data={data} queryId={queryId} setEditP45={setEditP45} />
        </TableBody>
        <TableFooter>
          <QueryPaginator
            itemsTotal={data?.value?.itemsTotal}
            itemsRetrieved={data?.value?.itemsRetrieved}
          />
        </TableFooter>
      </Table>
      <PanelDialog open={editP45} onClose={() => setEditP45(null)}>
        {editP45 && <EditP45Form id={editP45} onClose={() => setEditP45(null)} />}
      </PanelDialog>
    </>
  );
}

export default P45Table;
