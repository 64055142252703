import React, { useEffect, useState } from "react";
import {
  Panel,
  PageLoading,
  PanelActions,
  PanelHeading,
  Button,
  Calendar,
} from "@backslashbuild/pp-ui";
import { useGet } from "./api";
import { isTextNullOrEmpty, isSameDate, utcToLocal, utcToYmd } from "./utils";
import EmploymentHolidayDialog from "./EmploymentHolidayDialog";

const HolidayTransaction = ({ holidayTransaction }) => {
  return <div>{holidayTransaction?.amountInDays}</div>;
};

export default function EmploymentHolidayCalendar({ contractorProductId, onClose, holidayYear }) {
  const [payload, storePayload] = useState({});
  const [dateSelected, setDateSelected] = useState(null);
  const { data, loading, error } = useGet(
    `holiday-transactions?employmentId=${contractorProductId}&isCancelled=false&isAccrual=false`
  );

  if (loading) return <PageLoading />;

  function filterHolidayTransactions(dateTime) {
    if (data?.value?.itemsTotal > 0) {
      let allHolidayTransactions = data.value.items;

      let transactionsOnDay = allHolidayTransactions.filter(function (t) {
        return t?.dateTaken ? isSameDate(utcToLocal(t?.dateTaken), dateTime) : false; // dateTaken is null on accruals
      });

      return transactionsOnDay;
    }

    return [];
  }

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data?.errors?.map((error) => <div>{error}</div>);
    }

    if (dateSelected) {
      return (
        <EmploymentHolidayDialog
          date={dateSelected}
          employmentId={contractorProductId}
          holidayYear={holidayYear}
          holidayTransactions={filterHolidayTransactions(dateSelected)}
          onClose={() => setDateSelected(null)}
        />
      );
    }

    return (
      <Calendar
        renderDay={({ day, week, dayIndex, weekIndex }) => {
          return filterHolidayTransactions(day).map((t) => {
            return <HolidayTransaction holidayTransaction={t} />;
          });
        }}
        onDateClicked={({ day }) => {
          setDateSelected(day);
        }}
        height={552}
      />
    );
  }

  return (
    <Panel>
      <PanelHeading>
        {dateSelected ? `Book Holiday: ${utcToYmd(dateSelected)}` : `Holiday Calendar`}
      </PanelHeading>
      {handleRender()}
      <PanelActions>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Finish
        </Button>
      </PanelActions>
    </Panel>
  );
}
