import React from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { makeStyles } from "@backslashbuild/pp-ui";

const useStyles = makeStyles({
  wrapper: {
    width: "100% !important",
    display: "block !important",
    marginBottom: "25px !important",
    height: "125px !important",
    marginBottom: "108px",
  },
  editor: {
    width: "100% !important",
    padding: "6px 5px",
    display: "block !important",
    marginBottom: "25px !important",
    flexGrow: 1,
    boxSizing: "content-box",
    border: "1px solid #e3e9ef",
  },
  toolbar: {
    width: "100%",
    padding: "6px 5px",
    borderRadius: "2px",
    border: "1px solid #F1F1F1",
    display: "flex",
    justifyContent: "flex-start",
    background: "white",
    flexWrap: "wrap",
    fontSize: "15px",
    marginBottom: "5px",
    userSelect: "none",
  },
});

export default function HtmlEditor({ content = "", setContent = () => {}, submitting }) {
  const blocksFromHtml = htmlToDraft(content);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  const editorState = EditorState.createWithContent(contentState);
  const [state, setState] = React.useState(editorState);

  const classes = useStyles();

  React.useEffect(() => {
    setContent(draftToHtml(convertToRaw(state.getCurrentContent())));
  }, [state]);

  React.useEffect(() => {
    setState(EditorState.createWithContent(contentState));
  }, [submitting]);

  return (
    <>
      <Editor
        editorState={state}
        wrapperClassName={classes.wrapper}
        editorClassName={classes.editor}
        toolbarClassName={classes.toolbar}
        onEditorStateChange={(e) => setState(e)}
      />
    </>
  );
}
