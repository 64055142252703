import {
  PageLoading,
  Panel,
  PanelHeading,
  PanelSpacer,
  PanelRow,
  Label,
  BoldText,
  Button,
  DateFormatted,
  StatusIndicator,
} from "@backslashbuild/pp-ui";
import React from "react";
import { useGet } from "./api";
import { isTextNullOrEmpty } from "./utils";
import { useUpdateField } from "./api";
import { publish } from "./pubSub";

export default function ContractorProductDetailsPanel({ id }) {
  const { data, loading, error, refetch } = useGet(`contractor-products/${id}`);
  const updateField = useUpdateField({ id, formName: "contractor-product" });

  if (loading) return <PageLoading />;

  function handleProcess() {
    updateField({ key: "IsProcessed", value: "true" });
    refetch();
  }

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data?.isSuccess) {
      return data?.errors?.map((error, index) => <div key={index}>{error}</div>);
    }

    return (
      <>
        <PanelRow>
          <Label label="Account number">
            {data?.value?.isProcessed ? (
              <BoldText>{data?.value?.accountNumber}</BoldText>
            ) : (
              <Button
                onClick={() => {
                  handleProcess();
                }}
              >
                Process
              </Button>
            )}
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Contractor ID">
            <BoldText>{data?.value?.contractorUserId}</BoldText>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Date created">
            {data?.value?.dateCreated && (
              <BoldText>
                <DateFormatted date={data?.value?.dateCreated} />
              </BoldText>
            )}
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="First payslip week no">
            {data?.value?.firstPayslipWeek && (
              <BoldText>
                <DateFormatted date={data?.value?.firstPayslipWeek} />
              </BoldText>
            )}
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Most recent payslip">
            <BoldText>{data?.value?.dateOfMostRecentPayslip}</BoldText>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Address">
            <BoldText>{data?.value?.address}</BoldText>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Date synced">
            {data?.value?.dateSynced && (
              <BoldText>
                <DateFormatted date={data?.value?.dateSynced} />
              </BoldText>
            )}
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Is active">
            <BoldText>
              <StatusIndicator value={data?.value?.isActive} />
            </BoldText>
          </Label>
        </PanelRow>
        {data?.value?.leaveDate && (
          <PanelRow>
            <Label label="Leave date">
              {data?.value?.leaveDate && (
                <BoldText>
                  <DateFormatted date={data?.value?.leaveDate} />
                </BoldText>
              )}
            </Label>
          </PanelRow>
        )}
        <PanelRow>
          <Label label="Leave requested date">
            {data?.value?.leaveRequestDate && (
              <BoldText>
                <DateFormatted date={data?.value?.leaveRequestDate} />
              </BoldText>
            )}
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Live date">
            {data?.value?.liveDate && (
              <BoldText>
                <DateFormatted date={data?.value?.liveDate} />
              </BoldText>
            )}
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="P45 date">
            {data?.value?.p45Date && (
              <BoldText>
                <DateFormatted date={data?.value?.p45Date} />
              </BoldText>
            )}
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Payment frequency">
            <BoldText>{data?.value?.paymentFrequency}</BoldText>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Tax code">
            <BoldText>{data?.value?.taxCode}</BoldText>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Working hours per day">
            <BoldText>{data?.value?.workingHoursPerDay}</BoldText>
          </Label>
        </PanelRow>
      </>
    );
  }

  return <Panel>{handleRender()}</Panel>;
}
