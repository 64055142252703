import { PageLoading, Panel, PanelHeading, PanelRow, PanelDialog } from "@backslashbuild/pp-ui";
import React, { useState } from "react";
import { useGet } from "./api";
import ContractorPointsBalance from "../wallet-points/ContractorPointsBalance";
import ContractorAWSBalance from "./ContractorAWSBalance";
import { subscribe, unsubscribe } from "./pubSub";
import InviteUserButton from "./InviteUserButton";
import EmulateUserButton from "./EmulateUserButton";
import SendSMSButton from "./SendSMSButton";

import SendSMSForm from "./SendSMSForm";

export default function ContractorQuickLinks({ id }) {
  const [showSendSMSForm, setShowSendSMSForm] = useState(false);

  const { data, loading, error, refetch } = useGet(id);

  React.useEffect(() => {
    subscribe("contractor.personalDetails.updated", refetch);
    return () => unsubscribe("contractor.personalDetails.updated", refetch);
  }, [id]);

  if (loading) return <PageLoading />;

  return (
    <>
      <Panel>
        <PanelHeading>Quick Links</PanelHeading>
        <PanelRow>
          <div style={{ width: "220px" }}>
            <div>
              <ContractorPointsBalance id={id} />
            </div>
            <div>
              <ContractorAWSBalance id={id} />
            </div>
          </div>
        </PanelRow>
        <PanelRow>
          <InviteUserButton contractorId={id} />
        </PanelRow>
        <PanelRow>
          <EmulateUserButton contractorId={id} />
        </PanelRow>
        <PanelRow>
          <SendSMSButton onClick={() => setShowSendSMSForm(true)} />
        </PanelRow>
      </Panel>
      <PanelDialog
        open={showSendSMSForm}
        onClose={() => {
          setShowSendSMSForm(false);
        }}
      >
        <SendSMSForm
          id={id}
          onClose={() => {
            setShowSendSMSForm(false);
          }}
        />
      </PanelDialog>
    </>
  );
}
