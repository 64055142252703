import React from "react";
import { useGetEpsJsonRequestById } from "./api";
import { isTextNullOrEmpty } from "./utils";
import ObjectMap from "../../components/ObjectMap";

import { PageLoading, PanelRow } from "@backslashbuild/pp-ui";

export default function ViewJsonRequest({ requestId }) {
  const { data, loading, error, queryId, refetch } = useGetEpsJsonRequestById(requestId);

  return (
    <>
      {loading && <PageLoading />}
      {!isTextNullOrEmpty(error) && <PanelRow>{String(error)}</PanelRow>}
      {!data?.isSuccess && data?.errors?.map((error) => <PanelRow>{error}</PanelRow>)}
      {data?.isSuccess && <ObjectMap data={data?.value} hideEmptyValues />}
    </>
  );
}
