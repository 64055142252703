import React from "react";
import {
  Button,
  PanelActions,
  PanelDialog,
  PanelHeading,
  PanelRow,
  TextInput,
  Label,
  Dropdown,
  DropdownOption,
  DatePicker,
} from "@backslashbuild/pp-ui/dist/index.es";
import { usePost, invalidateContributions } from "./api";
import { publish } from "./pubSub";
import { utcToYmd } from "./utils";

export default function CreateContributionScheduleButton() {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [employerReferenceNumber, setEmployerReferenceNumber] = React.useState("");
  const [paymentDueDate, setPaymentDueDate] = React.useState(null);
  const [paymentFrequencyId, setPaymentFrequencyId] = React.useState(null);
  const [earningsPeriodEndDate, setEarningsPeriodEndDate] = React.useState(null);
  const [earningsPeriodStartDate, setEarningsPeriodStartDate] = React.useState(null);

  const isBulkUpdateForInsufficientEarners = false;
  const isExceptionSchedule = false;
  const includePayslipsBeforeEarningsPeriodEndDate = false;

  const createSchedule = usePost("contributions/schedules/create");

  function handleCreate() {
    createSchedule({
      employerReferenceNumber,
      paymentDueDate,
      paymentFrequencyId,
      earningsPeriodEndDate,
      earningsPeriodStartDate,
      isBulkUpdateForInsufficientEarners,
      isExceptionSchedule,
      includePayslipsBeforeEarningsPeriodEndDate,
    }).then((res) => {
      if (res.isSuccess) {
        publish("notification", "Created successfully");
        setDialogOpen(false);
        invalidateContributions();
      } else {
        publish(
          "notification",
          <>
            <ul>
              {res?.errors?.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </>
        );
      }
    });
  }
  return (
    <>
      <Button onClick={() => setDialogOpen(true)}>Create New Schedule</Button>
      <PanelDialog open={dialogOpen}>
        <PanelHeading>Create New Schedule</PanelHeading>
        <PanelRow>
          <Label label="Employer Reference Number">
            <TextInput
              value={employerReferenceNumber}
              onChange={(e) => setEmployerReferenceNumber(e.target.value)}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Payment Frequency">
            <Dropdown
              value={paymentFrequencyId}
              onChange={(e) => setPaymentFrequencyId(e.target.value)}
            >
              <DropdownOption value="">Choose:</DropdownOption>
              <DropdownOption value={"Weekly"}>Weekly</DropdownOption>
              <DropdownOption value={"Monthly"}>Monthly</DropdownOption>
            </Dropdown>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Payment Due Date">
            <DatePicker
              value={paymentDueDate}
              onChange={(v) => {
                setPaymentDueDate(utcToYmd(v));
              }}
            ></DatePicker>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Earnings Period Start Date">
            <DatePicker
              value={earningsPeriodStartDate}
              onChange={(v) => {
                setEarningsPeriodStartDate(utcToYmd(v));
              }}
            ></DatePicker>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Earnings Period End Date">
            <DatePicker
              value={earningsPeriodEndDate}
              onChange={(v) => {
                setEarningsPeriodEndDate(utcToYmd(v));
              }}
            ></DatePicker>
          </Label>
        </PanelRow>
        <PanelActions>
          <Button variant="secondary" onClick={() => setDialogOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleCreate}>Submit</Button>
        </PanelActions>
      </PanelDialog>
    </>
  );
}
