import React from "react";
import {
  PageHeadingArea,
  PageHeadingAreaSpacer,
  PageTitle,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableFooter,
  Button,
  TextInputMultiline,
} from "@backslashbuild/pp-ui";
import { useGetItems, usePost } from "./api";
import { useLocation } from "react-router-dom";
import SyncMessagesFiltering from "./SyncMessagesFiltering";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";
import queryString from "query-string";
import { utcToLocalText } from "../../utils";
import { publish } from "./pubSub";
import {
  PanelActions,
  PanelDialog,
  PanelHeading,
  PanelRow,
} from "@backslashbuild/pp-ui/dist/index.es";

function PureTable({ data, queryId, onClick }) {
  return React.useMemo(
    () =>
      data.value.items.map((syncMessage) => {
        return (
          <>
            <TableRow key={syncMessage?.id} disableHover>
              <TableCell>
                {syncMessage?.dateCreated &&
                  utcToLocalText(syncMessage?.dateCreated, "dd/MM/yyyy HH:mm")}
              </TableCell>
              <TableCell>
                <TextInputMultiline value={syncMessage.message} />
              </TableCell>
              <TableCell>
                {syncMessage?.lastProcessedDate &&
                  utcToLocalText(syncMessage?.lastProcessedDate, "dd/MM/yyyy HH:mm")}
              </TableCell>
              <TableCell>
                {syncMessage?.lastSuccessfullyProcessedDate &&
                  utcToLocalText(syncMessage?.lastSuccessfullyProcessedDate, "dd/MM/yyyy HH:mm")}
              </TableCell>
              <TableCell>{syncMessage?.errors?.toString()}</TableCell>
              <TableCell>
                <Button
                  small
                  onClick={(e) => {
                    e.stopPropagation();
                    onClick(syncMessage.id);
                  }}
                >
                  Resync
                </Button>
              </TableCell>
            </TableRow>
          </>
        );
      }),
    [queryId]
  );
}

function TableSkeleton() {
  return (
    <TableRow disableHover={true}>
      <TableCell>-</TableCell>
      <TableCell>-</TableCell>
      <TableCell>-</TableCell>
      <TableCell>-</TableCell>
      <TableCell>-</TableCell>
      <TableCell>-</TableCell>
    </TableRow>
  );
}

export default function PayslipsTable() {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const location = useLocation();
  const search = usePagination(location.search, 25);
  const parsedQueryString = queryString.parse(search, { parseNumbers: true, parseBooleans: true });
  const { data, queryId } = useGetItems(
    `sync-messages?${queryString.stringify({ ...parsedQueryString })}`
  );

  const isLoaded = Boolean(data?.value);

  const resyncMessages = usePost("sync-messages/resync");

  function handleResyncAll() {
    setSubmitting(true);
    resyncMessages({ ...parsedQueryString }).then((res) => {
      if (res?.isSuccess) {
        publish("notification", "Sync messages will be resynchronised");
        setDialogOpen(false);
      } else {
        publish("notification", "Unable to resync selected messages");
      }
      setSubmitting(false);
    });
  }

  function handleResync(id) {
    setSubmitting(true);
    resyncMessages({ syncMessageId: id }).then((res) => {
      if (res?.isSuccess) {
        publish("notification", "Sync message will be resynchronised");
        setDialogOpen(false);
      } else {
        publish("notification", "Unable to resync message");
      }
      setSubmitting(false);
    });
  }

  return (
    <>
      <PageHeadingArea>
        <PageTitle>Legacy Sync Messages</PageTitle>
        <PageHeadingAreaSpacer grow />
        <SyncMessagesFiltering />
        <PageHeadingAreaSpacer />
        <Button large onClick={() => setDialogOpen(true)}>
          Resync Results
        </Button>
      </PageHeadingArea>
      <Table
        sticky
        stickyFooter={
          <TableFooter sticky>
            <QueryPaginator
              defaultTake={25}
              itemsTotal={data?.value?.itemsTotal}
              itemsRetrieved={data?.value?.itemsRetrieved}
            />
          </TableFooter>
        }
        footerHeight={TableFooter.height}
      >
        <TableHeader sticky>
          <TableHeaderColumn sticky>Date Created</TableHeaderColumn>
          <TableHeaderColumn sticky>Message</TableHeaderColumn>
          <TableHeaderColumn sticky>Last Processed</TableHeaderColumn>
          <TableHeaderColumn sticky>Last Successfully Processed</TableHeaderColumn>
          <TableHeaderColumn sticky>Errors</TableHeaderColumn>
          <TableHeaderColumn sticky>Actions</TableHeaderColumn>
        </TableHeader>
        <TableBody sticky fullPageTable={true} footerHeight={TableFooter.height}>
          {isLoaded ? (
            <PureTable data={data} queryId={queryId} onClick={handleResync} />
          ) : data?.errors ? (
            data?.errors.map((error) => <div>{error}</div>)
          ) : (
            <TableSkeleton />
          )}
        </TableBody>
      </Table>
      <PanelDialog open={dialogOpen}>
        <PanelHeading>Resync Messages</PanelHeading>
        <PanelRow>This will trigger a resync of {data?.value?.itemsTotal} messages</PanelRow>
        <PanelActions>
          <Button variant="secondary" onClick={() => setDialogOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleResyncAll} disabled={submitting}>
            {!!submitting ? "Submitting" : "Resync"}
          </Button>
        </PanelActions>
      </PanelDialog>
    </>
  );
}
