import React from "react";
import { useGet } from "./api";
import { makeStyles, Rollover } from "@backslashbuild/pp-ui";
import TaxWeekDetailsDisplay from "./TaxWeekDetailsDisplay";

const useStyles = makeStyles({
  root: {
    margin: [[0, 20]],
    background: "white",
    borderRadius: 4,
    padding: 8,
    minWidth: 60,
    lineHeight: "14px",
    fontSize: 12,
    fontWeight: "bold",
    maxHeight: 38,
    cursor: "pointer",
  },
  overline: {
    fontSize: 8,
    lineHeight: "10px",
    fontWeight: "regular",
  },
});

function TaxWeekPill() {
  const classes = useStyles();

  const { data, loading, error, queryId } = useGet(`tax-weeks/date`);

  const currentTaxWeek = data?.value;

  if (!currentTaxWeek) {
    return <div className={classes.root}>-</div>;
  }

  return (
    <Rollover
      placement="bottom"
      interactive={false}
      renderAnchor={() => (
        <div className={classes.root}>
          <div className={classes.overline}>WEEK</div>
          {currentTaxWeek.weekNumber}
        </div>
      )}
    >
      <TaxWeekDetailsDisplay value={currentTaxWeek} />
    </Rollover>
  );
}

export default TaxWeekPill;
