import React from "react";
import {
  PanelDialog,
  PanelHeading,
  PanelActions,
  PanelRow,
  Button,
  Label,
} from "@backslashbuild/pp-ui";
import { useSetRowAdditionalPeriods } from "./api";
import { publish } from "../../pubSub";
import { Link, TextInput } from "@backslashbuild/pp-ui/dist/index.es";

export default function AdditionalSettingsDialog({ row, scheduleId }) {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  function handleCancel() {
    setDialogOpen(false);
  }

  return (
    <>
      <div onClick={() => setDialogOpen(true)}>
        <Link>{row?.additionalPeriods}</Link>
      </div>
      <PanelDialog onClose={handleCancel} open={dialogOpen}>
        <PanelHeading>Update Additional Periods</PanelHeading>
        <ScheduleAdditionalPeriodsForm onCancel={handleCancel} row={row} scheduleId={scheduleId} />
      </PanelDialog>
    </>
  );
}

function ScheduleAdditionalPeriodsForm({ onCancel, row, scheduleId }) {
  const [value, setValue] = React.useState(row?.additionalPeriods ?? 0);

  const post = useSetRowAdditionalPeriods(scheduleId);

  async function handleSubmit() {
    try {
      const result = await post({ rowId: row.id, additionalPeriods: Number(value) });

      if (!result.isSuccess) {
        var errors = result.errors;

        publish(
          "notification",
          <ul>
            {errors.map((error) => {
              return <li>{error}</li>;
            })}
          </ul>
        );

        return;
      }

      const id = result.value;
      publish("notification", "Additional Periods updated");
    } catch (error) {
      publish("notification", "Network error occured.");
    }
  }

  return (
    <>
      <PanelRow>
        <Label label="Additional Periods">
          <TextInput
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
        </Label>
      </PanelRow>
      <PanelActions>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="primary" disabled={value == row.additionalPeriods} onClick={handleSubmit}>
          Save
        </Button>
      </PanelActions>
    </>
  );
}
