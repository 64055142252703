import React from "react";
import ReloadButton from "./ReloadButton";
import ReloadDraftDialog from "./ReloadDraftDialog";

import { PanelDialog, PageHeadingAreaSpacer } from "@backslashbuild/pp-ui";

export default function ReloadDraft({ draft, refetch }) {
  const [showDialog, setShowDialog] = React.useState(false);

  return (
    <>
      {draft && !draft?.isClosed && (
        <>
          <ReloadButton onClick={() => setShowDialog(true)} />
          <PageHeadingAreaSpacer />
          <ReloadDraftDialog
            open={showDialog}
            draftId={draft?.id}
            onClose={() => {
              setShowDialog(false);
              setTimeout(() => {
                refetch();
              }, 1000);
            }}
          />
        </>
      )}
    </>
  );
}
