import React from "react";
import { useCreateTemplate } from "./api";
import {
  Button,
  PanelDialog,
  PanelHeading,
  PanelRow,
  BoldText,
  PanelActions,
  PanelSpacer,
  TextInput,
  Label,
} from "@backslashbuild/pp-ui";
import { publish } from "../../pubSub";

function TemplateEditor({ agencyId, numberOfHeaderRows, columnTypes }) {
  const createTemplate = useCreateTemplate();
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");

  async function handleSubmit() {
    try {
      const response = await createTemplate({
        agencyId,
        numberOfHeaderRows,
        columnTypeIds: columnTypes,
        name,
      });

      if (response.isSuccess) {
        publish("notification", "Template created");
        setOpen(false);
        setName("");
      } else {
        publish(
          "notification",
          <>
            Unable to create Template
            <ul>
              {response.errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </>
        );
      }
    } catch (e) {
      console.error(e, "error creating Template");
      publish("notification", "A network error occured.");
    }
  }

  return (
    <>
      <PanelDialog open={open} onClose={() => setOpen(false)}>
        <PanelHeading>New template</PanelHeading>
        <PanelSpacer large />

        <PanelRow>
          <BoldText>Please choose a name for this template</BoldText>
        </PanelRow>

        <PanelRow>
          <Label label="Template name">
            <TextInput value={name} onChange={(e) => setName(e.target.value)} />
          </Label>
        </PanelRow>

        <PanelSpacer large />

        <PanelActions>
          <Button onClick={() => setOpen(false)} variant="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit}>Create template</Button>
        </PanelActions>
        <PanelSpacer large />
      </PanelDialog>
      <Button large onClick={() => setOpen(true)} variant="secondary">
        Save settings as new template
      </Button>
    </>
  );
}

export default TemplateEditor;
