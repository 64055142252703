import React from "react";
import ViewHmrcErrorsButton from "./ViewHmrcErrorsButton";
import HmrcErrorsDialog from "./HmrcErrorsDialog";

import { PanelDialog } from "@backslashbuild/pp-ui";

export default function ViewHmrcErrors({ rtiInformationId }) {
  const [showDialog, setShowDialog] = React.useState(false);

  return (
    <>
      <ViewHmrcErrorsButton onClick={() => setShowDialog(true)} />
      <PanelDialog
        open={showDialog}
        onClose={() => {
          setShowDialog(false);
        }}
      >
        <HmrcErrorsDialog
          rtiInformationId={rtiInformationId}
          onClose={() => {
            setShowDialog(false);
          }}
        />
      </PanelDialog>
    </>
  );
}
