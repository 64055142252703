import React from "react";
import {
  Table,
  TableHeader,
  TableBody,
  TableHeaderColumn,
  TableRow,
  TableCell,
  TextInput,
} from "@backslashbuild/pp-ui";

const c = {
  item: 300,
  collective: 300,
  paye: 300,
  total: 300,
};

function PeoTable() {
  const [state, setState] = React.useState({
    grossEarnings: 0,
    uplift: 0,
    fee: 0,
    vat: 0,
  });

  const invoiceTotal =
    Number(state.grossEarnings) + Number(state.uplift) + Number(state.fee) + Number(state.vat);

  function getStateUpdateHandler(key) {
    return (event) => setState({ ...state, [key]: Number(Number(event.target.value).toFixed(2)) });
  }

  const box = (key) => (
    <TextInput type="number" onChange={getStateUpdateHandler(key)} value={state[key]} />
  );

  return (
    <Table fullPageTable={true}>
      <TableHeader>
        <TableHeaderColumn width={c.item}>Item</TableHeaderColumn>
        <TableHeaderColumn width={c.total}>Total</TableHeaderColumn>
      </TableHeader>
      <TableBody fullPageTable={true}>
        <TableRow disableHover>
          <TableCell width={c.item}>Gross Earnings</TableCell>
          <TableCell width={c.total}>{box("grossEarnings")}</TableCell>
        </TableRow>

        <TableRow disableHover>
          <TableCell width={c.item}>Uplift</TableCell>
          <TableCell width={c.total}>{box("uplift")}</TableCell>
        </TableRow>

        <TableRow disableHover>
          <TableCell width={c.item}>Fee</TableCell>
          <TableCell width={c.total}>{box("fee")}</TableCell>
        </TableRow>

        <TableRow disableHover>
          <TableCell width={c.item}>VAT</TableCell>
          <TableCell width={c.total}>{box("vat")}</TableCell>
        </TableRow>

        <TableRow disableHover>
          <TableCell width={c.item}>Invoice Total</TableCell>
          <TableCell width={c.total}>
            <TextInput type="number" disabled value={invoiceTotal} />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

export default PeoTable;
