import React from "react";

import { Tree, TreeItem, Icons } from "@backslashbuild/pp-ui";
import { useLocation, useHistory, Link as RouterLink } from "react-router-dom";
import queryString from "query-string";
import { useGet } from "./api";

export default function HistoryFilter() {
  const totalAll = useTotalFromQuery({
    type: null,
  });
  const totalContractors = useTotalFromQuery({
    type: "[contractor]",
  });
  const totalAgencies = useTotalFromQuery({
    type: "[agency]",
  });

  const location = useLocation();
  const selected = location.search || "all";
  const history = useHistory();

  return (
    <Tree
      selected={selected}
      onNodeSelect={(event, nodeIds) => {
        if (nodeIds === "all") {
          history.replace(location.pathname);
        } else {
          history.replace({ search: nodeIds });
        }
      }}
    >
      <TreeItem
        nodeId="all"
        labelIcon={Icons.LabelIcon}
        labelText="View all"
        labelInfo={totalAll}
      ></TreeItem>
      <TreeItem
        nodeId="?type=[contractor]"
        labelIcon={Icons.AccountBox}
        labelText="Contractors"
        labelInfo={totalContractors}
        replace
        component={RouterLink}
        to={`/view-history?type=[contractor]`}
      ></TreeItem>
      <TreeItem
        nodeId="?type=[agency]"
        labelIcon={Icons.AlbumIcon}
        labelText="Agencies"
        labelInfo={totalAgencies}
        component={RouterLink}
        to={`/view-history?type=[agency]`}
      />
    </Tree>
  );
}

function useTotalFromQuery(query) {
  const stringifiedQuery = queryString.stringify({
    ...query,
    take: 0,
  });

  const { data } = useGet(`?${stringifiedQuery}`);

  return data?.value?.itemsTotal ?? "-";
}
