import React from "react";

import { Content } from "@backslashbuild/pp-ui";

import ContractorsTable from "../modules/contractors/ContractorsTable";

function Contractors() {
  return (
    <Content>
      <ContractorsTable />
    </Content>
  );
}

export default Contractors;
