import React from "react";
import {
  Panel,
  PanelHeading,
  TextInput,
  Label,
  PanelRow,
  PanelActions,
  Button,
  PanelSpacer,
  CheckBox,
  DatePicker,
} from "@backslashbuild/pp-ui";
import { usePost, useGet } from "./api";
import { useHistory } from "react-router-dom";
import { publish } from "../../pubSub";
import { utcToYmd } from "./utils";

export default function NewPEOBandForm({ agencyId, onClose = () => {} }) {
  const [submitting, setSubmitting] = React.useState(false);
  const [isDefaultRate, setIsDefaultRate] = React.useState(false);
  const [edits, setEdits] = React.useState({
    description: null,
    isActive: null,
    approvedDate: null,
    band1: null,
    band1Fixed: null,
    band1From: null,
    band1To: null,
    band2: null,
    band2Fixed: null,
    band2From: null,
    band2To: null,
    band3: null,
    band3Fixed: null,
    band3From: null,
    band3To: null,
    band4: null,
    band4Fixed: null,
    band4From: null,
    band4To: null,
    band5: null,
    band5Fixed: null,
    band5From: null,
    band5To: null,
    band6: null,
    band6Fixed: null,
    band6From: null,
    band6To: null,
    band7: null,
    band7Fixed: null,
    band7From: null,
    band7To: null,
    band8: null,
    band8Fixed: null,
    band8From: null,
    band8To: null,
    band9: null,
    band9Fixed: null,
    band9From: null,
    band9To: null,
    isAddHolidayToSuppliedGross: null,
    isStartFromGrossAndWorkDown: null,
    downshiftGrossByThisFirst: null,
    isCalculateEmploymentCostsPlusFixedMargin: null,
    isIndividualInvoices: null,
    isPEOJointEmploymentModel: null,
    isPEOVATFreeModel: null,
    isPEOTopBandIncreases: null,
    rebate: null,
    stampTime: null,
  });

  const update = usePost("peo-bands/create");
  const history = useHistory();

  async function handleSubmit() {
    setSubmitting(true);
    try {
      const response = await update({ data: edits, agencyId: isDefaultRate ? null : agencyId });
      if (response.isSuccess) {
        publish("notification", "Updated");
      } else {
        publish(
          "notification",
          <>
            <ul>
              {response.errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </>
        );
      }
    } catch (e) {
      console.error(e, "error updating PEO band");
      publish("notification", "A network error occured.");
    } finally {
      setSubmitting(false);
    }
  }

  function handleRender() {
    return (
      <>
        <PanelRow>
          <Label label="Is default rate?">
            <CheckBox checked={isDefaultRate} onChange={(e) => setIsDefaultRate(!isDefaultRate)} />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Approved Date">
            <DatePicker
              value={edits.approvedDate}
              type="date"
              onChange={(value) => {
                setEdits({ ...edits, approvedDate: utcToYmd(value) });
              }}
            ></DatePicker>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Description">
            <TextInput
              value={edits.description}
              onChange={(e) => setEdits({ ...edits, description: e.target.value })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Is Active">
            <CheckBox
              checked={edits.isActive}
              onChange={(e) => setEdits({ ...edits, isActive: !edits.isActive })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band1">
            <TextInput
              type="number"
              value={edits.band1}
              onChange={(e) => setEdits({ ...edits, band1: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band1Fixed">
            <TextInput
              type="number"
              value={edits.band1Fixed}
              onChange={(e) => setEdits({ ...edits, band1Fixed: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band1From">
            <TextInput
              type="number"
              value={edits.band1From}
              onChange={(e) => setEdits({ ...edits, band1From: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band1To">
            <TextInput
              type="number"
              value={edits.band1To}
              onChange={(e) => setEdits({ ...edits, band1To: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band2">
            <TextInput
              type="number"
              value={edits.band2}
              onChange={(e) => setEdits({ ...edits, band2: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band2Fixed">
            <TextInput
              type="number"
              value={edits.band2Fixed}
              onChange={(e) => setEdits({ ...edits, band2Fixed: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band2From">
            <TextInput
              type="number"
              value={edits.band2From}
              onChange={(e) => setEdits({ ...edits, band2From: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band2To">
            <TextInput
              type="number"
              value={edits.band2To}
              onChange={(e) => setEdits({ ...edits, band2To: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band3">
            <TextInput
              type="number"
              value={edits.band3}
              onChange={(e) => setEdits({ ...edits, band3: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band3Fixed">
            <TextInput
              type="number"
              value={edits.band3Fixed}
              onChange={(e) => setEdits({ ...edits, band3Fixed: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band3From">
            <TextInput
              type="number"
              value={edits.band3From}
              onChange={(e) => setEdits({ ...edits, band3From: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band3To">
            <TextInput
              type="number"
              value={edits.band3To}
              onChange={(e) => setEdits({ ...edits, band3To: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band4">
            <TextInput
              type="number"
              value={edits.band4}
              onChange={(e) => setEdits({ ...edits, band4: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band4Fixed">
            <TextInput
              type="number"
              value={edits.band4Fixed}
              onChange={(e) => setEdits({ ...edits, band4Fixed: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band4From">
            <TextInput
              type="number"
              value={edits.band4From}
              onChange={(e) => setEdits({ ...edits, band4From: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band4To">
            <TextInput
              type="number"
              value={edits.band4To}
              onChange={(e) => setEdits({ ...edits, band4To: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band5">
            <TextInput
              type="number"
              value={edits.band5}
              onChange={(e) => setEdits({ ...edits, band5: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band5Fixed">
            <TextInput
              type="number"
              value={edits.band5Fixed}
              onChange={(e) => setEdits({ ...edits, band5Fixed: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band5From">
            <TextInput
              type="number"
              value={edits.band5From}
              onChange={(e) => setEdits({ ...edits, band5From: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band5To">
            <TextInput
              type="number"
              value={edits.band5To}
              onChange={(e) => setEdits({ ...edits, band5To: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band6">
            <TextInput
              type="number"
              value={edits.band6}
              onChange={(e) => setEdits({ ...edits, band6: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band6Fixed">
            <TextInput
              type="number"
              value={edits.band6Fixed}
              onChange={(e) => setEdits({ ...edits, band6Fixed: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band6From">
            <TextInput
              type="number"
              value={edits.band6From}
              onChange={(e) => setEdits({ ...edits, band6From: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band6To">
            <TextInput
              type="number"
              value={edits.band6To}
              onChange={(e) => setEdits({ ...edits, band6To: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band7">
            <TextInput
              type="number"
              value={edits.band7}
              onChange={(e) => setEdits({ ...edits, band7: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band7Fixed">
            <TextInput
              type="number"
              value={edits.band7Fixed}
              onChange={(e) => setEdits({ ...edits, band7Fixed: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band7From">
            <TextInput
              type="number"
              value={edits.band7From}
              onChange={(e) => setEdits({ ...edits, band7From: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band7To">
            <TextInput
              type="number"
              value={edits.band7To}
              onChange={(e) => setEdits({ ...edits, band7To: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band8">
            <TextInput
              type="number"
              value={edits.band8}
              onChange={(e) => setEdits({ ...edits, band8: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band8Fixed">
            <TextInput
              type="number"
              value={edits.band8Fixed}
              onChange={(e) => setEdits({ ...edits, band8Fixed: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band8From">
            <TextInput
              type="number"
              value={edits.band8From}
              onChange={(e) => setEdits({ ...edits, band8From: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band8To">
            <TextInput
              type="number"
              value={edits.band8To}
              onChange={(e) => setEdits({ ...edits, band8To: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band9">
            <TextInput
              type="number"
              value={edits.band9}
              onChange={(e) => setEdits({ ...edits, band9: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band9Fixed">
            <TextInput
              type="number"
              value={edits.band9Fixed}
              onChange={(e) => setEdits({ ...edits, band9Fixed: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band9From">
            <TextInput
              type="number"
              value={edits.band9From}
              onChange={(e) => setEdits({ ...edits, band9From: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Band9To">
            <TextInput
              type="number"
              value={edits.band9To}
              onChange={(e) => setEdits({ ...edits, band9To: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Is Add Holiday To Supplied Gross">
            <CheckBox
              checked={edits.isAddHolidayToSuppliedGross}
              onChange={(e) =>
                setEdits({
                  ...edits,
                  isAddHolidayToSuppliedGross: !edits.isAddHolidayToSuppliedGross,
                })
              }
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Is Start From Gross And Work Down">
            <CheckBox
              checked={edits.isStartFromGrossAndWorkDown}
              onChange={(e) =>
                setEdits({
                  ...edits,
                  isStartFromGrossAndWorkDown: !edits.isStartFromGrossAndWorkDown,
                })
              }
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Downshift Gross By This First">
            <TextInput
              type="number"
              value={edits.downshiftGrossByThisFirst}
              onChange={(e) =>
                setEdits({ ...edits, downshiftGrossByThisFirst: Number(e.target.value) })
              }
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Is Calculate Employment Costs Plus Fixed Margin">
            <CheckBox
              checked={edits.isCalculateEmploymentCostsPlusFixedMargin}
              onChange={(e) =>
                setEdits({
                  ...edits,
                  isCalculateEmploymentCostsPlusFixedMargin: !edits.isCalculateEmploymentCostsPlusFixedMargin,
                })
              }
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Is Individual Invoices">
            <CheckBox
              checked={edits.isIndividualInvoices}
              onChange={(e) =>
                setEdits({ ...edits, isIndividualInvoices: !edits.isIndividualInvoices })
              }
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Is PEO Joint Employment Model">
            <CheckBox
              checked={edits.isPEOJointEmploymentModel}
              onChange={(e) =>
                setEdits({ ...edits, isPEOJointEmploymentModel: !edits.isPEOJointEmploymentModel })
              }
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Is PEO VAT Free Model">
            <CheckBox
              checked={edits.isPEOVATFreeModel}
              onChange={(e) => setEdits({ ...edits, isPEOVATFreeModel: !edits.isPEOVATFreeModel })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Is PEO Top Band Increases">
            <CheckBox
              checked={edits.isPEOTopBandIncreases}
              onChange={(e) =>
                setEdits({ ...edits, isPEOTopBandIncreases: !edits.isPEOTopBandIncreases })
              }
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Rebate">
            <TextInput
              type="number"
              value={edits.rebate}
              onChange={(e) => setEdits({ ...edits, rebate: Number(e.target.value) })}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Stamp Time">
            <TextInput
              value={edits.stampTime}
              type="date"
              onChange={(e) => {
                setEdits({ ...edits, stampTime: utcToYmd(e.target.value) });
              }}
            ></TextInput>
          </Label>
        </PanelRow>
      </>
    );
  }

  return (
    <>
      <Panel>
        <PanelHeading>Add New PEO Band</PanelHeading>
        <PanelSpacer />
        {handleRender()}
        <PanelSpacer />

        <PanelActions>
          <Button onClick={handleSubmit}>Save</Button>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
        </PanelActions>
      </Panel>
    </>
  );
}
