import React from "react";
import { Button, BoldText } from "@backslashbuild/pp-ui";

function parseNumber(numeric) {
  if (isNaN(numeric)) {
    return "-";
  } else {
    return numeric;
  }
}

const Spacer = () => {
  return <div style={{ height: 10, width: 10 }} />;
};

function Paginator({ skip, take, onGoto, onPrev, onNext, itemsRetrieved, itemsTotal, spacers }) {
  const clickablePageRange = 3;
  const currentPage = 1 + skip / (take || 1);
  const lastPage = Math.ceil(itemsTotal / (take || 1));

  const ellipses = <b>...</b>;
  const p = (i) => (
    <Button
      key={i}
      small
      variant={i === currentPage ? "secondary" : "primary"}
      onClick={() => onGoto?.((i - 1) * take)}
    >
      {i}
    </Button>
  );

  const pagesToShow = [];

  if (currentPage - clickablePageRange > 1) {
    pagesToShow.push(p(1));
    pagesToShow.push(ellipses);
  }

  for (
    let i = Math.max(1, currentPage - clickablePageRange);
    i <= Math.min(currentPage + clickablePageRange, lastPage);
    i++
  ) {
    pagesToShow.push(p(i));
  }

  if (currentPage + clickablePageRange < lastPage) {
    pagesToShow.push(ellipses);
    pagesToShow.push(p(lastPage));
  }

  return (
    <>
      <div style={{ flexGrow: 1 }}>
        <BoldText>
          Showing {parseNumber(skip + 1)} to {parseNumber(skip + itemsRetrieved)} of{" "}
          {parseNumber(itemsTotal)}
        </BoldText>
      </div>
      {pagesToShow}
    </>
  );
}

export default Paginator;
