import React from "react";
import { useQuery, queryCache } from "react-query";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import getApiUrl from "../../getApiUrl";

const apiHost = getApiUrl();
const service = "payroll";

function invalidateRFPs() {
  queryCache.invalidateQueries((query) => {
    const [cacheType, cacheId] = query.queryKey;
    return cacheType === "rfps";
  });
}

export function useGet(cacheKey, path, serviceOverride) {
  if (!path) {
    path = cacheKey;
  }
  if (!serviceOverride) {
    serviceOverride = service;
  }

  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };

  const { data, error, isLoading: loading, updatedAt: queryId } = useQuery(
    cacheKey,
    async (key, id) => {
      return fetch(`${apiHost}/${serviceOverride}/${path}`, {
        headers: {
          ...authHeaders,
        },
      }).then((r) => r.json());
    }
  );

  const runQuery = () => queryCache.invalidateQueries(cacheKey);

  React.useEffect(() => {
    runQuery();
  }, [path]);

  return { data, loading, error, queryId, refetch: runQuery };
}

export function usePost(path) {
  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };

  return React.useCallback(
    (body) => {
      return fetch(`${apiHost}/${service}/${path}`, {
        method: "POST",
        headers: {
          ...authHeaders,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }).then((x) => {
        return x.json();
      });
    },
    [path, authHeaders.Authorization]
  );
}

export function usePostBlob(path, filename) {
  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };

  return React.useCallback(
    (body) => {
      return fetch(`${apiHost}/${service}/${path}`, {
        method: "POST",
        headers: {
          ...authHeaders,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => response.blob())
        .then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = filename;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        });
    },
    [path, filename, authHeaders.Authorization]
  );
}

export function useGetRFP(id) {
  const query = useGet(["rfps", id], `rfps/${id}`);
  return query;
}

export function useGetAgencyUmbrellaRates({ agencyId, branchId = "" }) {
  const query = useGet(`umbrella-rates?agencyId=${agencyId}&branchId=${branchId}`);
  return query;
}

export function useGetStatPayGroups({ contractorProductId = "", search = "", type = "" }) {
  const query = useGet(
    ["payroll-stat-pay-groups", { contractorProductId, search, type }],
    `statutory-pay-groups?employmentId=${contractorProductId}&search=${search}&type=${type}`
  );
  return query;
}

export function invalidateAllStatPayGroups() {
  queryCache.invalidateQueries((query) => {
    const [cacheType] = query.queryKey;
    return cacheType === "payroll-stat-pay-groups";
  });
}

export function invalidateAllStatPays() {
  queryCache.invalidateQueries((query) => {
    const [cacheType] = query.queryKey;
    return cacheType === "payroll-stat-pays";
  });
}

export function invalidateStatPayGroupsByEmploymentId(id) {
  queryCache.invalidateQueries(["payroll-stat-pay-groups", id]);
}

export function useGetStatPays({ statutoryPayGroupId }) {
  const query = useGet(
    ["payroll-stat-pays", statutoryPayGroupId],
    `statutory-pays/group/${statutoryPayGroupId}`
  );
  return query;
}

export function useGetHolidayBalance({ contractorProductId }) {
  const query = useGet(`holiday-transactions/holiday-balance?employmentId=${contractorProductId}`);
  return query;
}

export function useGetCurrentHolidayYear({ contractorProductId }) {
  const query = useGet(`holiday-year-overrides/holiday-year?employmentId=${contractorProductId}`);
  return query;
}

export function useGetHolidayTransactions({ contractorProductId, holidayYear = "" }) {
  const query = useGet(
    `holiday-transactions?employmentId=${contractorProductId}&holidayYear=${holidayYear}`
  );
  return query;
}

export function useGetHolidayYearOverrides({ employmentId }) {
  const query = useGet(`holiday-year-overrides?employmentId=${employmentId}`);
  return query;
}

export function useGetRFPBreakdowns() {
  const query = useGet(["rfps", "breakdowns"], `rfps/breakdown`);
  return query;
}

export function useGetSettingsSnapshots({ employmentId }) {
  const query = useGet(`settings-snapshots?employmentId=${employmentId}`);
  return query;
}
