import React from "react";
import { Button, Label, TextInput, makeStyles } from "@backslashbuild/pp-ui";
import { useDownloadExportCSV } from "./api";

const useStyles = makeStyles({
  margin: {
    margin: 16,
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
});

export default function ExportBatchCsvButton({ nelsonGenerated = false }) {
  const [batchNumber, setBatchNumber] = React.useState("");
  const download = useDownloadExportCSV(batchNumber, nelsonGenerated);

  const classes = useStyles();

  return (
    <div className={classes.margin}>
      <Label label={nelsonGenerated ? "Reprint Nelson-generated batch" : "Reprint batch"}>
        <div className={classes.flex}>
          <TextInput
            value={batchNumber}
            onChange={(e) => setBatchNumber(e.target.value)}
            width={100}
          />
          <Button
            icon
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              download();
            }}
            style={{ marginLeft: 16 }}
          >
            Go
          </Button>
        </div>
      </Label>
    </div>
  );
}
