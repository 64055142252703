import React from "react";
import {
  PopoverMenu,
  PopoverMenuItem,
  Link,
  StatusIndicatorSet,
  StatusIndicatorBase,
} from "@backslashbuild/pp-ui";
import { useGet, useSetRowSettings } from "./api";
import { publish } from "../../pubSub";
import { Rollover } from "@backslashbuild/pp-ui/dist/index.es";

const Hint = ({ children }) => {
  return (
    <div
      style={{
        backgroundColor: "#FFFFFF",
        border: "1px solid #e3e9ef",
        padding: "4px",
      }}
    >
      {children}
    </div>
  );
};

function RowSettingsPicker({ row, scheduleId }) {
  const [open, setOpen] = React.useState(false);
  const optionsQuery = useGet("configuration/row-types");
  const updateRowSettings = useSetRowSettings(scheduleId);
  const options = optionsQuery.data ?? [];

  async function handleUpdateSettings(body) {
    try {
      const response = await updateRowSettings(body);

      if (response.isSuccess) {
        setOpen(false);
      } else {
        publish(
          "notification",
          <div>
            Unable to update row settings
            <ul>
              {response.errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </div>
        );
      }
    } catch (e) {
      console.error(e, "error updating row settings");
      publish("notification", "A network error occured.");
    }
  }

  function updateType(typeId) {
    handleUpdateSettings({
      rowId: row.id,
      rowTypeId: typeId,
      isInvoiced: row.isInvoiced,
      isForPayroll: row.isForPayroll,
    });
  }

  function setIsInvoiceOnly() {
    handleUpdateSettings({
      rowId: row.id,
      rowTypeId: row.rowTypeId,
      isInvoiced: true,
      isForPayroll: false,
    });
  }

  function setIsForPayrollOnly() {
    handleUpdateSettings({
      rowId: row.id,
      rowTypeId: row.rowTypeId,
      isInvoiced: false,
      isForPayroll: true,
    });
  }

  function setIsInvoiceAndIsForPayroll() {
    handleUpdateSettings({
      rowId: row.id,
      rowTypeId: row.rowTypeId,
      isInvoiced: true,
      isForPayroll: true,
    });
  }

  function renderTypeIndicator() {
    return (
      <StatusIndicatorBase>
        <PopoverMenu
          open={open}
          onClose={() => setOpen(false)}
          renderAnchor={(ref) => (
            <Link onClick={() => setOpen(true)} ref={ref}>
              {row.rowType.symbol}
            </Link>
          )}
        >
          {options.map((o) => (
            <PopoverMenuItem onClick={() => updateType(o.id)} key={o.id}>
              {o.name}
            </PopoverMenuItem>
          ))}
        </PopoverMenu>
      </StatusIndicatorBase>
    );
  }

  function renderInvoiceIndicator() {
    if (row.isInvoiced && row.isForPayroll == false)
      return (
        <Rollover
          renderAnchor={() => (
            <div>
              <StatusIndicatorBase
                backgroundColor={"#6868ff"}
                onClick={() => setIsInvoiceAndIsForPayroll()}
              >
                I
              </StatusIndicatorBase>
            </div>
          )}
        >
          <Hint>Invoiced = true. Payroll = false. Click to set Payroll to true.</Hint>
        </Rollover>
      );

    if (row.isInvoiced)
      return (
        <Rollover
          renderAnchor={() => (
            <div>
              <StatusIndicatorBase onClick={() => setIsInvoiceOnly()}>I</StatusIndicatorBase>
            </div>
          )}
        >
          <Hint>Invoiced = true. Payroll = true. Click to set as invoice only.</Hint>
        </Rollover>
      );

    return <StatusIndicatorBase onClick={() => setIsInvoiceOnly()} />;
  }

  function renderIsForPayrollIndicator() {
    if (row.isForPayroll && row.isInvoiced == false)
      return (
        <Rollover
          renderAnchor={() => (
            <div>
              <StatusIndicatorBase
                backgroundColor={"#FFB200"}
                onClick={() => setIsInvoiceAndIsForPayroll()}
              >
                P
              </StatusIndicatorBase>
            </div>
          )}
        >
          <Hint>Payroll = true. Invoiced = false. Click to set Invoice to true.</Hint>
        </Rollover>
      );

    if (row.isForPayroll)
      return (
        <Rollover
          renderAnchor={() => (
            <div>
              <StatusIndicatorBase onClick={() => setIsForPayrollOnly()}>P</StatusIndicatorBase>
            </div>
          )}
        >
          <Hint>
            Payroll = true. Invoiced = true. Click to set Invoice to false (Payroll only).
          </Hint>
        </Rollover>
      );

    return (
      <Rollover
        renderAnchor={() => (
          <div>
            <StatusIndicatorBase onClick={() => setIsForPayrollOnly()} />
          </div>
        )}
      >
        <Hint>Click here to set as Payroll only.</Hint>
      </Rollover>
    );
  }

  return (
    <StatusIndicatorSet>
      {renderTypeIndicator()}
      {renderInvoiceIndicator()}
      {renderIsForPayrollIndicator()}
    </StatusIndicatorSet>
  );
}

export default RowSettingsPicker;
