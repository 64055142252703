import React from "react";
import QueryFiltering from "../../containers/QueryFiltering";
import {
  PanelRow,
  Label,
  TextInput,
  PanelColumn,
  PanelColumnSet,
  CheckBox,
} from "@backslashbuild/pp-ui";

const textInputWidth = 160;

function PayslipFiltering() {
  return (
    <QueryFiltering
      getTagsFromState={(state) => {
        const tags = [];
        if (state.weekNumber) tags.push({ key: "week", value: state.weekNumber });
        if (state.accountNumber) tags.push({ key: "account", value: state.accountNumber });
        if (state.employmentNumber) tags.push({ key: "employment", value: state.employmentNumber });
        if (state.payslipNumber) tags.push({ key: "payslip", value: state.payslipNumber });
        if (state.niNumber) tags.push({ key: "nino", value: state.niNumber });
        if (state.contractorName) tags.push({ key: "name", value: state.contractorName });
        if (state.adjustmentsOnly === "yes") tags.push({ key: "adjustments", value: "Only" });

        return tags;
      }}
      getNextStateFromDeleteTag={(state, key) => {
        if (key === "week") return { ...state, weekNumber: undefined };
        if (key === "account") return { ...state, accountNumber: undefined };
        if (key === "employment") return { ...state, employmentNumber: undefined };
        if (key === "payslip") return { ...state, payslipNumber: undefined };
        if (key === "nino") return { ...state, niNumber: undefined };
        if (key === "name") return { ...state, contractorName: undefined };
        if (key === "adjustments") return { ...state, adjustmentsOnly: undefined };
        return state;
      }}
      renderForm={(state, setState) => (
        <>
          <PanelColumnSet>
            <PanelColumn width="50%">
              <PanelRow>
                <Label label="Search">
                  <TextInput
                    value={state.search}
                    width={textInputWidth}
                    onChange={(e) => setState({ ...state, search: e.target.value })}
                  />
                </Label>
              </PanelRow>
              <PanelRow>
                <Label label="Contractor Name">
                  <TextInput
                    value={state.contractorName}
                    width={textInputWidth}
                    onChange={(e) => setState({ ...state, contractorName: e.target.value })}
                  />
                </Label>
              </PanelRow>
              <PanelRow>
                <Label label="Week No.">
                  <TextInput
                    value={state.weekNumber}
                    width={textInputWidth}
                    onChange={(e) => setState({ ...state, weekNumber: e.target.value })}
                  />
                </Label>
              </PanelRow>
              <PanelRow>
                <Label label="Adjustments Only">
                  <CheckBox
                    checked={state.adjustmentsOnly}
                    onChange={(e) =>
                      setState({
                        adjustmentsOnly: e.target.checked == true ? true : undefined,
                      })
                    }
                  />
                </Label>
              </PanelRow>
            </PanelColumn>
            <PanelColumn width="50%">
              <PanelRow>
                <Label label="Account Number">
                  <TextInput
                    value={state.accountNumber}
                    width={textInputWidth}
                    onChange={(e) => setState({ ...state, accountNumber: e.target.value })}
                  />
                </Label>
              </PanelRow>
              <PanelRow>
                <Label label="Employment Number">
                  <TextInput
                    value={state.employmentNumber}
                    width={textInputWidth}
                    onChange={(e) => setState({ ...state, employmentNumber: e.target.value })}
                  />
                </Label>
              </PanelRow>
              <PanelRow>
                <Label label="NINO">
                  <TextInput
                    value={state.niNumber}
                    width={textInputWidth}
                    onChange={(e) => setState({ ...state, niNumber: e.target.value })}
                  />
                </Label>
              </PanelRow>
              <PanelRow>
                <Label label="Payslip Number">
                  <TextInput
                    value={state.payslipNumber}
                    width={textInputWidth}
                    onChange={(e) => setState({ ...state, payslipNumber: e.target.value })}
                  />
                </Label>
              </PanelRow>
            </PanelColumn>
          </PanelColumnSet>
        </>
      )}
    />
  );
}

export default PayslipFiltering;
