import React from "react";
import { useParams } from "react-router-dom";
import { Content, PageTitle, PanelColumn, PageHeadingArea, PanelColumnSet, PanelColumnSpacer } from "@backslashbuild/pp-ui";

import AddInvoiceForm from "../modules/invoicing/AddInvoiceForm";

export default function InvoiceCreation({ isCreditNote }) {
  const { id } = useParams();

  return (
    <Content>
      <PageHeadingArea>
        <PageTitle>Create new {isCreditNote ? "Credit note" : "Invoice"}</PageTitle>
      </PageHeadingArea>
      <PanelColumnSet>
        <AddInvoiceForm />
      </PanelColumnSet>
    </Content>
  );
}
