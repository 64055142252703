import React from "react";
import { useGetSchedule, useUpdateSchedule } from "./api";
import {
  Panel,
  PanelRow,
  Label,
  StatusIndicator,
  Link,
  DatePicker,
  PanelHeading,
  CheckBox,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHeaderColumn,
  PanelSpacer,
  PanelActions,
  Button,
  Rollover,
  DropdownOption,
} from "@backslashbuild/pp-ui";
import { Link as RouterLink } from "react-router-dom";
import MarkScheduleAsCheckedButton from "./MarkScheduleAsCheckedButton";
import CreateInvoicesButton from "./CreateScheduleInvoices";
import CreateRFPsForSchedule from "./CreateRFPsForSchedule";
import { publish } from "../../pubSub";

import DebugPopover from "../../components/DebugPopover";
import PEOBandDetailsView from "./PEOBandDetailsView";
import { Dropdown, PopoverMenu, PopoverMenuItem } from "@backslashbuild/pp-ui/dist/index.es";

export default function ScheduleDetails({ id }) {
  const { data, loading, error } = useGetSchedule(id);

  const schedule = data?.value;

  const update = useUpdateSchedule();

  async function setPayslipReleaseDate(date) {
    await handleUpdate({ payrollDate: date });
  }

  async function setIsIndividuallyInvoiced(isIndividuallyInvoiced) {
    await handleUpdate({ isIndividuallyInvoiced });
  }

  async function setSelectedPEOBandId(selectedPEOBandId) {
    await handleUpdate({ selectedPEOBandId });
  }

  async function handleUpdate(patch) {
    try {
      const response = await update({
        scheduleId: id,
        isIndividuallyInvoiced: schedule?.isIndividuallyInvoiced,
        payrollDate: schedule?.payslipReleaseDate,
        selectedPEOBandId: schedule?.selectedPEOBandId,
        ...patch,
      });

      if (response.isSuccess) {
        publish("notification", "Schedule updated");
      } else {
        publish(
          "notification",
          <div>
            Unable to update schedule
            <ul>
              {response.errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </div>
        );
      }
    } catch (e) {
      console.error(e, "error updating schedule");
      publish("notification", "A network error occured.");
    }
  }

  const selectedPEOBandId = schedule?.selectedPEOBandId; // TODO

  return (
    <>
      <Panel>
        <PanelHeading>
          <DebugPopover data={schedule}>Schedule details</DebugPopover>
        </PanelHeading>
        <PanelRow>
          <Label label="Pay run date">
            <DatePicker
              disabled={schedule && schedule.isLocked}
              value={schedule?.payslipReleaseDate}
              onChange={(e) => setPayslipReleaseDate(e)}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Is individually invoiced">
            <CheckBox
              disabled={schedule && schedule.isLocked}
              checked={schedule?.isIndividuallyInvoiced ?? false}
              onChange={(e) => setIsIndividuallyInvoiced(e.target.checked)}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="PEO Band">
            <PeoBandPicker
              onChange={(x) => setSelectedPEOBandId(x)}
              value={schedule?.selectedPEOBandId}
              bands={schedule?.agencySettings?.peoBandSnapshots}
            />

            {/* <PopoverMenu
              open
              renderAnchor={(ref) => (
                <Link ref={ref}>{selectedPEOBand?.description || "no description"}</Link>
              )}
            >
              {schedule?.agencySettings?.peoBandSnapshots.map((b) => (
                <PopoverMenuItem>
                  <Rollover
                    renderAnchor={() => (
                      <div style={{ height: "100%", width: "100%" }}>
                        {b.description || "untitled"}
                      </div>
                    )}
                  >
                    <PEOBandDetailsView band={b} />
                  </Rollover>
                </PopoverMenuItem>
              ))}
            </PopoverMenu> */}

            {/* <Dropdown value={selectedPEOBandId}>
              {schedule?.agencySettings?.peoBandSnapshots.map((b) => (
                <DropdownOption value={b.peoBandId}>{b.description || "untitled"}</DropdownOption>
              ))}
            </Dropdown> */}

            {/* {selectedPEOBand ? (
              <Rollover
                placement="right"
                interactive={false}
                renderAnchor={() => (
                  
                )}
              >
                <PEOBandDetailsView band={selectedPEOBand} />
              </Rollover>
            ) : (
              "none"
            )} */}
          </Label>
        </PanelRow>
        <PanelRow>
          <Label width={"60px"} label={"Checked"}>
            <MarkScheduleAsCheckedButton id={id} checked={schedule?.isChecked} />
          </Label>
          <Label width={"40px"} />
          <Label width={"60px"} label={"Locked"}>
            <StatusIndicator value={schedule?.isLocked} />
          </Label>
          <Label width={"40px"} />
          <Label width={"60px"} label={"Closed"}>
            <StatusIndicator value={schedule?.isClosed} />
          </Label>
        </PanelRow>
      </Panel>
      <PanelSpacer large />
      {/* <Panel>
        <Table>
          <TableBody>
            <TableRow>
              <TableHeaderColumn>Checked</TableHeaderColumn>
              <TableHeaderColumn>Locked</TableHeaderColumn>
              <TableHeaderColumn>Closed</TableHeaderColumn>
            </TableRow>
            <TableRow>
              <TableCell>
                Checked
                <MarkScheduleAsCheckedButton id={id} checked={schedule?.isChecked} />
              </TableCell>
              <TableCell>
                Locked
                <StatusIndicator value={schedule?.isLocked} />
              </TableCell>
              <TableCell>
                Closed
                <StatusIndicator value={schedule?.isClosed} />
              </TableCell>
            </TableRow>
          </TableBody>
          {schedule && schedule.isClosed == false ? (
            <PanelActions>
              {schedule && schedule.isChecked == true ? <CreateInvoicesButton id={id} /> : null}

              {schedule && schedule.isLocked == true ? <CreateRFPsForSchedule id={id} /> : null}
            </PanelActions>
          ) : (
            <PanelActions>
              <Button>Cancel RFPs</Button>
            </PanelActions>
          )}
        </Table>
      </Panel> */}
    </>
  );

  return (
    <>
      Schedule:{" "}
      <Link component={RouterLink} to={`/agency/${data?.value?.agencyId}`}>
        {data?.value?.agencyNumber} {data?.value?.agencyName}{" "}
      </Link>{" "}
      - {data?.value?.weekNumber}
    </>
  );
}

function PeoBandPicker({ bands, value, onChange }) {
  const [open, setOpen] = React.useState(false);

  const band = bands?.find((b) => b.peoBandId === value);
  return (
    <PopoverMenu
      open={open}
      onClose={() => setOpen(false)}
      renderAnchor={(ref) => (
        <Link onClick={() => setOpen(true)} ref={ref}>
          {band?.description || "untitiled"} - ({band?.agencyId ? "Agency specific" : "Default"})
        </Link>
      )}
    >
      {bands?.map((b) => (
        <PopoverMenuItem
          onClick={() => {
            onChange(b.peoBandId);
            setOpen(false);
          }}
        >
          <Rollover
            renderAnchor={() => (
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: 8,
                }}
              >
                {b.description || "untitiled"} - ({b.agencyId ? "Agency specific" : "Default"})
              </div>
            )}
          >
            <PEOBandDetailsView band={b} />
          </Rollover>
        </PopoverMenuItem>
      ))}
    </PopoverMenu>
  );
}
