import React from "react";
import { QueryDropdown } from "@backslashbuild/pp-ui";
import { useGetItems, useGet } from "../contractors/api";

function renderName(contractor) {
  return contractor && `${contractor?.firstName} ${contractor?.surname}`;
}

function ContractorPicker({ value, agencyId, onChange }) {
  const [search, setSearch] = React.useState("");
  const params = `?isActive=${true}&agencyId=${agencyId}`;
  const optionsQuery = useGetItems(search ? `${params}&name=${search}` : params); // TODO urlencode

  const currentItemQuery = useGet(value);
  const options = optionsQuery.data?.value?.items ?? [];

  return (
    <QueryDropdown
      placeholder="Choose"
      search={search}
      onSearchChange={setSearch}
      value={value}
      onChange={onChange}
      options={options.map((o) => o.userId)}
      renderOption={(v) => {
        if (v == value) return renderName(currentItemQuery?.data?.value);

        var optionsMatch = options.find((o) => o.userId == v);

        if (optionsMatch) {
          return renderName(optionsMatch);
        }

        return "loading...";
      }}
    />
  );
}

export default ContractorPicker;
