import React from "react";
import QueryFiltering from "../../containers/QueryFiltering";
import { PanelRow, Label, RadioGroup, Radio } from "@backslashbuild/pp-ui";
import { CheckBox } from "@backslashbuild/pp-ui/dist/index.es";

function EmploymentsFiltering() {
  return (
    <QueryFiltering
      searchStringKey="pattern"
      getTagsFromState={(state) => {
        const tags = [];
        if (state.enrolled === "yes") tags.push({ key: "enrolled", value: "Only" });
        if (state.enrolled === "no") tags.push({ key: "enrolled", value: "Exclude" });
        if (state.isActive === "yes") tags.push({ key: "active", value: "Only" });
        if (state.isActive === "no") tags.push({ key: "active", value: "Exclude" });
        if (state.needsEnrolling) tags.push({ key: "needs enrolling", value: "Only" });
        return tags;
      }}
      getNextStateFromDeleteTag={(state, key) => {
        if (key === "enrolled") return { ...state, enrolled: undefined };
        if (key === "active") return { ...state, isActive: undefined };
        if (key === "needs enrolling") return { ...state, needsEnrolling: undefined };
        return state;
      }}
      renderForm={(state, setState) => (
        <>
          <PanelRow>
            <Label label="Needs Enrolling Only">
              <CheckBox
                checked={state.needsEnrolling}
                onChange={(e) =>
                  setState({
                    needsEnrolling: e.target.checked == true ? true : undefined,
                  })
                }
              />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Enrolled">
              <RadioGroup
                value={state.enrolled}
                onChange={(e) => setState({ ...state, enrolled: e.target.value })}
              >
                <Radio value="" label="Any" disabled={state.needsEnrolling} />
                <Radio value="yes" label="Yes" disabled={state.needsEnrolling} />
                <Radio value="no" label="No" disabled={state.needsEnrolling} />
              </RadioGroup>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Is Active">
              <RadioGroup
                value={state.isActive}
                onChange={(e) => setState({ ...state, isActive: e.target.value })}
              >
                <Radio value="" label="Any" disabled={state.needsEnrolling} />
                <Radio value="yes" label="Yes" disabled={state.needsEnrolling} />
                <Radio value="no" label="No" disabled={state.needsEnrolling} />
              </RadioGroup>
            </Label>
          </PanelRow>
        </>
      )}
    />
  );
}

export default EmploymentsFiltering;
