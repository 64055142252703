import { Button } from "@backslashbuild/pp-ui";
import React from "react";
import getApiUrl from "../../getContractorPortalUrl";

const apiHost = getApiUrl();

export default function EmulateUserButton({ contractorId }) {
  return (
    <Button
      large
      onClick={() => {
        window.open(
          `${apiHost}/your-account?userId=${contractorId}`,
          "_blank",
          "noopener,noreferrer"
        );
      }}
    >
      Emulate
    </Button>
  );
}
