import React from "react";

import { useHistory, useParams } from "react-router-dom";
import { utcToLocalText } from "./utils";
import { useGetBACSRunById } from "./api";
import CloseBACSRunActions from "./CloseBACSRunActions";
import LockBACSRunActions from "./LockBACSRunActions";
import PayBACSRunActions from "./PayBACSRunActions";
import ExportBACSRunActions from "./ExportBACSRunActions";
import RecalculateActions from "./RecalculateActions";

import {
  Button,
  PageHeadingArea,
  BackArrow,
  PageHeadingAreaSpacer,
  PageTitle,
} from "@backslashbuild/pp-ui";

export default function BACSRunHeading() {
  const history = useHistory();
  const { id } = useParams();
  const { data, loading, error, queryId, refetch } = useGetBACSRunById(id);

  return (
    <PageHeadingArea>
      <BackArrow onClick={() => history.goBack()} />
      <PageHeadingAreaSpacer />
      <PageTitle>
        {data?.isSuccess &&
          `BACS Run: ${utcToLocalText(data?.value?.payrollDate, "dd MMM yyyy")} with ${
            data?.value?.lockedRows
          } BACS Rows`}
        {!data?.isSuccess && `BACS Run:`}
      </PageTitle>
      <PageHeadingAreaSpacer grow />
      <PayBACSRunActions bacsRun={data?.value} refetch={refetch} />
      <ExportBACSRunActions bacsRun={data?.value} />
      <LockBACSRunActions bacsRun={data?.value} refetch={refetch} />
      <RecalculateActions bacsRun={data?.value} refetch={refetch} />
      <CloseBACSRunActions bacsRun={data?.value} />
      <Button large onClick={() => history.push("/bacs-runs")}>
        Back to BACS Runs
      </Button>
    </PageHeadingArea>
  );
}
