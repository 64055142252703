import React from "react";
import { QueryDropdown, MuiButtonBase, CloseIcon } from "@backslashbuild/pp-ui";
import { useGetItems, useGet } from "./api";
import AgencyName from "./AgencyName";

function format(value) {
  return value ? `${value.agencyNumber} ${value.name}` : "-";
}

function AgencyPicker({ value, onChange, showSelectedValueView, width }) {
  const [search, setSearch] = React.useState("");
  const optionsQuery = useGetItems(search ? `?name=${search}&take=25` : "?take=25"); // TODO urlencode

  const currentItemQuery = useGet(value);

  const options = optionsQuery.data?.value?.items ?? [];

  // Debounce the search
  var searchTimeout = null;
  const setSearchTerm = (searchTerm) => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      setSearch(searchTerm);
    }, 500);
  };

  if (value && showSelectedValueView) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "280px",
        }}
      >
        <AgencyName id={value} />
        <MuiButtonBase
          onClick={() => {
            onChange(null);
            setSearch("");
          }}
        >
          <CloseIcon />
        </MuiButtonBase>
      </div>
    );
  }

  return (
    <QueryDropdown
      placeholder="Choose"
      width={width}
      onSearchChange={setSearchTerm}
      value={value}
      onChange={onChange}
      options={options.map((o) => o.id)}
      renderOption={(v) => {
        if (value && v == value) return format(currentItemQuery?.data?.value);

        var optionsMatch = options.find((o) => o.id == v);

        if (optionsMatch) {
          return format(optionsMatch);
        }

        if (search) {
          return "loading...";
        }

        return "";
      }}
    />
  );
}

export default AgencyPicker;
