import React from "react";
import ViewDraftErrorsButton from "./ViewDraftErrorsButton";
import ViewDraftErrorsDialog from "./ViewDraftErrorsDialog";

import { PanelDialog, PageHeadingAreaSpacer } from "@backslashbuild/pp-ui";

export default function ViewDraftErrors({ data }) {
  const [showDialog, setShowDialog] = React.useState(false);

  return (
    <>
      {data?.errors ||
        (data?.value?.errors?.length > 0 && (
          <>
            <ViewDraftErrorsButton onClick={() => setShowDialog(true)} />
            <PageHeadingAreaSpacer />
            <ViewDraftErrorsDialog
              data={data}
              open={showDialog}
              onClose={() => {
                setShowDialog(false);
              }}
            />
          </>
        ))}
    </>
  );
}
