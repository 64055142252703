import React from "react";
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableFooter,
  PageLoading,
  Snack,
  PageHeadingArea,
  PageTitle,
  SearchBar,
  PageHeadingAreaSpacer,
  BoldText,
  Button,
} from "@backslashbuild/pp-ui";
import { formatCurrency, utcToLocalText, isTextNullOrEmpty } from "../../utils";
import { useGetItems } from "./api";
import { useLocation, useHistory } from "react-router-dom";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";

const c = {
  id: 100,
  contractorName: 100,
  datePaid: 100,
  amount: 100,
  reason: 100,
};

function PureTable({ data, queryId }) {
  const history = useHistory();

  return React.useMemo(
    () =>
      data.value.items.map((contractorStatutoryPayment) => (
        <TableRow>
          <TableCell width={c.id}>{contractorStatutoryPayment.id}</TableCell>
          <TableCell width={c.contractorName}>
            {contractorStatutoryPayment.contractorName}
          </TableCell>
          <TableCell width={c.datePaid}>
            {utcToLocalText(contractorStatutoryPayment.dateCreated)}
          </TableCell>
          <TableCell width={c.amount}>
            {formatCurrency(contractorStatutoryPayment.amount)}
          </TableCell>
          <TableCell width={c.reason}>{contractorStatutoryPayment.reason}</TableCell>
        </TableRow>
      )),
    [queryId]
  );
}

function EmergencyPaymentsTable() {
  const location = useLocation();
  const search = usePagination(location.search);
  const { data, loading, error, queryId } = useGetItems("contractor-emergencyPayments", search);

  if (loading || !data) return <PageLoading />;
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  return (
    <>
      <PageHeadingArea>
        <PageTitle>Emergency Payments</PageTitle>
      </PageHeadingArea>
      <Table standardTable>
        <TableHeader>
          <TableHeaderColumn width="17%">ID</TableHeaderColumn>
          <TableHeaderColumn width="17%">Contractor Name</TableHeaderColumn>
          <TableHeaderColumn width="17%">Date Paid</TableHeaderColumn>
          <TableHeaderColumn width="17%">Amount</TableHeaderColumn>
          <TableHeaderColumn width="17%">Reason</TableHeaderColumn>
        </TableHeader>
        <TableBody fullPageTable={true} footerHeight={TableFooter.height}>
          <PureTable data={data} queryId={queryId} />
        </TableBody>
        <TableFooter>
          <QueryPaginator
            itemsTotal={data?.value?.itemsTotal}
            itemsRetrieved={data?.value?.itemsRetrieved}
          />
        </TableFooter>
      </Table>
    </>
  );
}

export default EmergencyPaymentsTable;
