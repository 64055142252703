import React from "react";
import {
  PanelColumn,
  PageLoading,
  PanelSpacer,
  PanelColumnSpacer,
  PanelColumnSet,
} from "@backslashbuild/pp-ui";
import SalaryEntryTable from "./SalaryEntryTable";
import { useGet } from "./api";
import PayslipLineItemsTable from "./PayslipLineItemsTable";
import PayslipLogPanel from "./PayslipLogPanel";

function IllustrationView({ id }) {
  const { data, loading, errors } = useGet(`illustrations/${id}`);

  if (loading) return <PageLoading />;
  if (data.errors) return data?.errors.map((error) => <div>{error}</div>);

  return (
    <>
      <PanelColumn width={474 * 2}>
        <SalaryEntryTable payslip={data.value} />
        <PanelSpacer large />
        <PayslipLineItemsTable payslip={data.value} />
      </PanelColumn>
      <PanelColumnSpacer />
      <PanelColumn>
        <PayslipLogPanel payslip={data.value} />
      </PanelColumn>
    </>
  );
}

export default IllustrationView;
