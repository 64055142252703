import React from "react";
import {
  Button,
  PanelActions,
  PanelDialog,
  PanelHeading,
  PanelRow,
} from "@backslashbuild/pp-ui/dist/index.es";
import { usePost, invalidateContributions, invalidateEnrolmentExports } from "./api";
import { publish } from "./pubSub";
import { useParams } from "react-router-dom";

export default function CreateContributionScheduleExportButton({}) {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const { contributionScheduleId } = useParams();

  const createExport = usePost("contributions/schedule-exports/create");

  function handleCreate() {
    createExport({
      contributionScheduleId,
    }).then((res) => {
      if (res.isSuccess) {
        publish("notification", "Created successfully");
        setDialogOpen(false);
        invalidateContributions();
        invalidateEnrolmentExports();
      } else {
        publish(
          "notification",
          <>
            <ul>
              {res?.errors?.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </>
        );
      }
    });
  }
  return (
    <>
      <Button onClick={() => setDialogOpen(true)}>Create New Export</Button>
      <PanelDialog open={dialogOpen}>
        <PanelHeading>Create New Export</PanelHeading>
        <PanelRow>This will create a new export for the contribution schedule</PanelRow>
        <PanelActions>
          <Button variant="secondary" onClick={() => setDialogOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleCreate}>Create</Button>
        </PanelActions>
      </PanelDialog>
    </>
  );
}
