import {
  PanelRow,
  Label,
  BoldText,
  PageLoading,
  Panel,
  PanelHeading,
  PanelSpacer,
} from "@backslashbuild/pp-ui";
import { Button, PanelActions, PanelDialog, Rollover } from "@backslashbuild/pp-ui/dist/index.es";
import React from "react";
import ContractorBusinessCard from "../contractors/ContractorBusinessCard";
import { useGetFields, useUpdateMatchedContractor, useGet } from "./api";
import { isTextNullOrEmpty } from "./utils";
import { publish } from "../../pubSub";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

export default function MatchedContractorPanel({ id }) {
  const { data, loading, error } = useGetFields({ id, formName: "matching-contractor" });
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const updateMatchedContractor = useUpdateMatchedContractor({ id });

  const params = queryString.parse(useLocation().search);
  const matchdialog = params.matchdialog || false;

  const open =
    loading == false &&
    matchdialog &&
    data?.value?.match?.userId?.length > 1 &&
    data?.value?.isConfirmedMatch === false;

  React.useEffect(() => {
    setDialogOpen(open);
  }, [open]);

  if (loading) return <PageLoading />;

  function handleUpdate() {
    updateMatchedContractor(data?.value?.match?.userId).then((res) => {
      if (res?.isSuccess) {
        publish("notification", "Contractor matched successfully");
        setDialogOpen(false);
      } else {
        publish(
          "notification",
          <>
            <ul>
              {res?.errors?.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </>
        );
      }
    });
  }

  function handleRenderMatch() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    if (data?.value) {
      return (
        <>
          <PanelRow>
            <div>Possible existing contractor found:</div>
          </PanelRow>
          <PanelRow>
            <Label label="First Name">
              <BoldText>{data?.value?.match?.firstName}</BoldText>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Middle Names">
              <BoldText>{data?.value?.match?.middleNames}</BoldText>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Last Name">
              <BoldText>{data?.value?.match?.surname}</BoldText>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="NINO">
              <BoldText>{data?.value?.match?.nino}</BoldText>
            </Label>
          </PanelRow>
        </>
      );
    } else {
      return (
        <PanelRow>
          <div>No contractors matched with NINO.</div>
        </PanelRow>
      );
    }
  }

  function handleRenderDialog() {
    return (
      <PanelDialog open={dialogOpen}>
        <Rollover
          renderAnchor={() => (
            <div>
              <PanelRow>
                <div>Possible existing contractor found:</div>
              </PanelRow>
              <PanelRow>
                <Label label="First Name">
                  <BoldText>{data?.value?.match?.firstName}</BoldText>
                </Label>
              </PanelRow>
              <PanelRow>
                <Label label="Middle Names">
                  <BoldText>{data?.value?.match?.middleNames}</BoldText>
                </Label>
              </PanelRow>
              <PanelRow>
                <Label label="Last Name">
                  <BoldText>{data?.value?.match?.surname}</BoldText>
                </Label>
              </PanelRow>
              <PanelRow>
                <Label label="NINO">
                  <BoldText>{data?.value?.match?.nino}</BoldText>
                </Label>
              </PanelRow>
            </div>
          )}
        >
          <ContractorBusinessCard id={data?.value?.match?.userId} />
        </Rollover>
        <PanelActions>
          <Button onClick={handleUpdate}>Match with contractor</Button>
          <Button variant="secondary" onClick={() => setDialogOpen(false)}>
            Don't match
          </Button>
        </PanelActions>
      </PanelDialog>
    );
  }

  return (
    <>
      <Panel>
        <PanelHeading>Matched Contractor</PanelHeading>
        <PanelSpacer />
        {handleRenderMatch()}
        <PanelSpacer />
      </Panel>
      {handleRenderDialog()}
    </>
  );
}
