import {
  FormTitle,
  PageLoading,
  Panel,
  PanelHeading,
  PanelSpacer,
  CheckBox,
} from "@backslashbuild/pp-ui";
import React from "react";
import { useGetFields, useUpdateField } from "./api";
import FormField from "./FormField";
import { isTextNullOrEmpty } from "./utils";

export default function StudentLoanDetailsForm({ id }) {
  const [show, setShow] = React.useState(false);
  const { data, loading, error } = useGetFields({ id, formName: "student-loan-details" });
  const updateField = useUpdateField({ id, formName: "" });

  if (loading) return <PageLoading />;

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    return data.value.map((field) => (
      <FormField key={field.key} field={field} onSave={getOnSaveHandler(field.key)} />
    ));
  }

  function getOnSaveHandler(key) {
    return (value) => {
      updateField({ key, value });
    };
  }

  return (
    <Panel>
      <PanelHeading onClick={() => setShow(!show)}>
        <span style={{ flexGrow: 1 }}>{`Student Loans & Deductions`}</span>
        <CheckBox checked={show} onChange={(e) => setShow(e.target.checked)} />
      </PanelHeading>
      {show && (
        <>
          <PanelSpacer />
          {handleRender()}
          <PanelSpacer />
        </>
      )}
    </Panel>
  );
}
