import React from "react";
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableFooter,
  PageLoading,
  Snack,
  PageHeadingArea,
  PageTitle,
  SearchBar,
  PageHeadingAreaSpacer,
  PanelDialog,
  Button,
} from "@backslashbuild/pp-ui";
import { utcToLocalText, isTextNullOrEmpty } from "./utils";
import { useGetItems } from "./api";
import ContractorsFilter from "./ContractorsFilter";
import { useLocation, useHistory } from "react-router-dom";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";

import NewAnytimeWageToggleModePeriodForm from "./NewAnytimeWageToggleModePeriodForm";

function PureTable({ data, queryId }) {
  const history = useHistory();

  return React.useMemo(
    () =>
      data.value.items.map((anytimeWageRequest) => [
        <TableRow>
          <TableCell width="17%">{anytimeWageRequest.id}</TableCell>
          <TableCell width="17%">{anytimeWageRequest.contractorName}</TableCell>
          <TableCell width="17%">{anytimeWageRequest.minAmountAvailable}</TableCell>
          <TableCell width="17%">{anytimeWageRequest.maxAmountAvailable}</TableCell>
          <TableCell width="17%">{anytimeWageRequest.amountAccepted}</TableCell>
          <TableCell width="17%">{anytimeWageRequest.acceptedAt}</TableCell>
        </TableRow>,
        <TableRow>
          <TableCell width="17%"></TableCell>
          <TableCell width="17%" colSpan={5}>
            {anytimeWageRequest.rationale}
          </TableCell>
        </TableRow>,
      ]),
    [queryId]
  );
}

function AdvancesToggleModeTable() {
  const location = useLocation();
  const [addAnytimeWageToggleModePeriod, setAddAnytimeWageToggleModePeriod] = React.useState(false);
  const search = usePagination(location.search);
  const { data, loading, error, queryId } = useGetItems("anytimeWageToggleModePeriods");

  if (loading || !data) return <PageLoading />;
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  return (
    <>
      <PageHeadingArea>
        <PageTitle>Advances Toggle Mode</PageTitle>
        <PageHeadingAreaSpacer grow />
        <div>
          <Button
            large
            onClick={() => {
              setAddAnytimeWageToggleModePeriod(true);
            }}
          >
            Add new toggle mode
          </Button>
        </div>
      </PageHeadingArea>
      <Table standardTable>
        <TableHeader>
          <TableHeaderColumn width="17%">ID</TableHeaderColumn>
          <TableHeaderColumn width="17%">Mode</TableHeaderColumn>
          <TableHeaderColumn width="17%">Start Date</TableHeaderColumn>
          <TableHeaderColumn width="17%">End Date</TableHeaderColumn>
          <TableHeaderColumn width="17%">Occupations</TableHeaderColumn>
          <TableHeaderColumn width="17%">Sectors</TableHeaderColumn>
        </TableHeader>
        <TableBody fullPageTable={true} footerHeight={TableFooter.height}>
          <PureTable data={data} queryId={queryId} />
        </TableBody>
        <TableFooter>
          <QueryPaginator
            itemsTotal={data?.value?.itemsTotal}
            itemsRetrieved={data?.value?.itemsRetrieved}
          />
        </TableFooter>
      </Table>
      <PanelDialog
        open={addAnytimeWageToggleModePeriod}
        onClose={() => {
          setAddAnytimeWageToggleModePeriod(false);
        }}
      >
        <NewAnytimeWageToggleModePeriodForm
          onClose={() => {
            setAddAnytimeWageToggleModePeriod(false);
          }}
        />
      </PanelDialog>
    </>
  );
}

export default AdvancesToggleModeTable;
