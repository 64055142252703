import React from "react";
import {
  Panel,
  PanelHeading,
  TextInput,
  Label,
  PanelRow,
  PanelActions,
  Button,
  PanelSpacer,
  PanelDialog,
  Table,
  TableBody,
  TableHeader,
  TableCell,
  TableHeaderColumn,
  TableRow,
  PageLoading,
} from "@backslashbuild/pp-ui";
import { usePost, useGet, invalidateAllStatPayGroups, invalidateAllStatPays } from "./api";
import { useHistory } from "react-router-dom";
import { publish } from "../../pubSub";
import { localToUtcIso, utcToYmd } from "./utils";
import StatutoryPaysTable from "./StatutoryPaysTable";
import { formatCurrency } from "../../utils";
import { Icons, Rollover } from "@backslashbuild/pp-ui/dist/index.es";

const InfoIcon = Icons.InfoIcon;

function StatutoryPayGroupForm({ statutoryPayGroupId }) {
  const [submitting, setSubmitting] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [preview, setPreview] = React.useState(null);
  const [edits, setEdits] = React.useState({
    startDate: null,
    endDate: null,
    notes: null,
    rate: null,
  });

  const { data, loading, error, refetch } = useGet(`statutory-pay-groups/${statutoryPayGroupId}`);
  const update = usePost("statutory-pay-groups/update");
  const create = usePost("statutory-pays/create");
  const history = useHistory();

  async function handleSubmit() {
    setSubmitting(true);
    try {
      const response = await update({ ...edits, statutoryPayGroupId });
      if (response.isSuccess) {
        publish("notification", "Updated");
      } else {
        publish(
          "notification",
          <>
            <ul>
              {response.errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </>
        );
      }
    } catch (e) {
      console.error(e, "error updating statutory pay group");
      publish("notification", "A network error occured.");
    } finally {
      setSubmitting(false);
      invalidateAllStatPayGroups();
      invalidateAllStatPays();
    }
  }

  async function handleCreate() {
    setSubmitting(true);
    try {
      const response = await create({ dryRun: false, statutoryPayGroupId });
      if (response.isSuccess) {
        publish("notification", "Statutory Payments Created");
      } else {
        publish(
          "notification",
          <>
            <ul>
              {response.errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </>
        );
      }
    } catch (e) {
      console.error(e, "error creating statutory payments");
      publish("notification", "A network error occured.");
    } finally {
      setDialogOpen(false);
      setSubmitting(false);
      invalidateAllStatPayGroups();
      invalidateAllStatPays();
    }
  }

  async function handleDryRun() {
    setDialogOpen(true);
    setSubmitting(true);
    try {
      const response = await create({ dryRun: true, statutoryPayGroupId });
      if (response.isSuccess) {
        publish("notification", "Preview Generated");
        setPreview(response?.value);
      } else {
        publish(
          "notification",
          <>
            <ul>
              {response.errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </>
        );
      }
    } catch (e) {
      console.error(e, "error generating statutory pays");
      setDialogOpen(false);
      publish("notification", "A network error occured.");
    } finally {
      setSubmitting(false);
      invalidateAllStatPays();
      invalidateAllStatPayGroups();
    }
  }

  function handleClose() {
    setPreview(null);
    setSubmitting(false);
    setDialogOpen(false);
  }

  function handleRender() {
    if (data?.isSuccess) {
      return (
        <>
          <PanelRow>
            <Label label="Start Date">
              <TextInput
                value={edits.startDate ?? utcToYmd(data?.value?.startDate)}
                type="date"
                onChange={(e) => {
                  setEdits({ ...edits, startDate: utcToYmd(e.target.value) });
                }}
              ></TextInput>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="End Date">
              <TextInput
                value={edits.endDate ?? utcToYmd(data?.value?.endDate)}
                type="date"
                onChange={(e) => {
                  setEdits({ ...edits, endDate: utcToYmd(e.target.value) });
                }}
              ></TextInput>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Rate">
              <TextInput
                type="number"
                value={edits.rate ?? data?.value?.rate}
                onChange={(e) => setEdits({ ...edits, rate: Number(e.target.value) })}
              />
            </Label>
            {data?.value?.type?.name == "Maternity Pay" && (
              <Rollover renderAnchor={() => <InfoIcon style={{ display: "inline-block" }} />}>
                <div>
                  For Statutory Maternity Pay, this rate is applied to the first 6 weeks only.
                </div>
              </Rollover>
            )}
            {data?.value?.type?.name == "Adoption Pay" && (
              <Rollover renderAnchor={() => <InfoIcon style={{ display: "inline-block" }} />}>
                <div>
                  For Statutory Adoption Pay, this rate is applied to the first 6 weeks only.
                </div>
              </Rollover>
            )}
            {data?.value?.type?.name == "Paternity Pay" && (
              <Rollover renderAnchor={() => <InfoIcon style={{ display: "inline-block" }} />}>
                <div>
                  For Statutory Paternity Pay, this rate is applied for two 2 weeks, if lower than
                  £151.20.
                </div>
              </Rollover>
            )}
          </PanelRow>
          <PanelRow>
            <Label label="Notes">
              <TextInput
                value={edits.notes ?? data?.value?.notes}
                onChange={(e) => setEdits({ ...edits, notes: e.target.value })}
              />
            </Label>
          </PanelRow>
        </>
      );
    }
  }

  function handleRenderPreview() {
    if (submitting) {
      return <PageLoading />;
    }

    return (
      <Panel maxWidth={900}>
        <PanelHeading>Preview</PanelHeading>
        <div style={{ height: 500, overflow: "scroll" }}>
          <Table width={900}>
            <TableHeader>
              <TableHeaderColumn width="33%">Date Due</TableHeaderColumn>
              <TableHeaderColumn width="33%">Number of Days</TableHeaderColumn>
              <TableHeaderColumn width="33%">Amount</TableHeaderColumn>
            </TableHeader>
            <TableBody>
              {preview?.length == 0 && <div>No adjustments are required.</div>}
              {preview?.map((item) => (
                <TableRow>
                  <TableCell width="33%">{utcToYmd(item?.dateDue)}</TableCell>
                  <TableCell width="33%">{item?.numberOfDays}</TableCell>
                  <TableCell width="33%">{formatCurrency(item?.amountInMoney)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <PanelActions>
          <Button variant="primary" onClick={handleCreate}>
            Save and Create
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </PanelActions>
      </Panel>
    );
  }

  return (
    <>
      <Panel>
        <PanelHeading>Edit Statutory Pay Group Settings</PanelHeading>
        <PanelSpacer />
        {handleRender()}
        <PanelSpacer />

        <PanelActions>
          <Button onClick={handleSubmit}>Save Group Settings</Button>
          <Button variant="secondary" onClick={handleDryRun}>
            Preview Changes
          </Button>
        </PanelActions>
      </Panel>
      <PanelDialog open={dialogOpen}>{handleRenderPreview()}</PanelDialog>
    </>
  );
}

export default StatutoryPayGroupForm;
