import React from "react";
import { isTextNullOrEmpty, utcToLocalText } from "./utils";
import { useLocation } from "react-router-dom";
import GreenTick from "../../components/GreenTick";
import { useGetDraftFpsById } from "./api";
import queryString from "query-string";

import {
  TableRow,
  Table,
  TableCell,
  TableBody,
  TableFooter,
  PageLoading,
  Panel,
  PanelHeading,
  PanelSpacer,
  TableHeader,
  BoldText,
} from "@backslashbuild/pp-ui";

const c = {
  dateCreated: "60%",
  isEnqueued: "40%",
};

const totalWidth = Object.keys(c).reduce((total, key) => (total += c[key]), 0);

function PureTable({ data, queryId }) {
  return React.useMemo(() =>
    data?.map(
      (attempt) => (
        <TableRow>
          <TableCell width={c.dateCreated}>
            {utcToLocalText(attempt.dateCreated, "dd/MM/yyyy HH:mm")}
          </TableCell>
          <TableCell width={c.isEnqueued}>{attempt?.isEnqueued && <GreenTick />}</TableCell>
        </TableRow>
      ),
      [queryId]
    )
  );
}

export default function DraftAttemptTable() {
  const location = useLocation();
  const [state, setState] = React.useState(queryString.parse(location.search));
  const { data, loading, error, queryId, refetch } = useGetDraftFpsById(state?.draftId);

  if (loading) return <PageLoading />;
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  return (
    <Panel>
      <PanelHeading>Draft Attempts</PanelHeading>
      {data?.isSuccess && data?.value?.attempts?.length > 0 && (
        <>
          <Table standardTable width={totalWidth}>
            <TableHeader>
              <TableCell width={c.dateCreated}>
                <BoldText>Date Created</BoldText>
              </TableCell>
              <TableCell width={c.isEnqueued}>
                <BoldText>Enqueued</BoldText>
              </TableCell>
            </TableHeader>
            <TableBody fullPageTable={false} footerHeight={TableFooter.height}>
              <PureTable data={data?.value?.attempts} queryId={queryId} />
            </TableBody>
            <PanelSpacer />
            <TableFooter>
              <BoldText>{`Showing ${data?.value?.attempts?.length} Attemps`}</BoldText>
            </TableFooter>
          </Table>
          <PanelSpacer />
        </>
      )}
    </Panel>
  );
}
