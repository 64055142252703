import React from "react";
import { useParams, useHistory } from "react-router-dom";

import {
  Button,
  Content,
  PageHeadingArea,
  PageTitle,
  PanelColumnSet,
  PanelColumn,
  PanelColumnSpacer,
  PageHeadingAreaSpacer,
  PanelSpacer,
  SidebarWrapper,
  Sidebar,
  CommentsIconButton,
  BackArrow,
  Panel,
  PanelHeading,
  PanelRow,
  Label,
} from "@backslashbuild/pp-ui";

import AgencyPicker from "../modules/registrations/AgencyPicker";

import ContractorForm from "../modules/registrations/ContractorForm";
import StudentLoanDetailsForm from "../modules/registrations/StudentLoanDetailsForm";
import BankDetailsForm from "../modules/registrations/BankDetailsForm";
import PrivatePensionForm from "../modules/registrations/PrivatePensionForm";
import AgencyForm from "../modules/registrations/AgencyForm";
import AwrForm from "../modules/registrations/AwrForm";
import FeesForm from "../modules/registrations/FeesForm";
import CompareUmbrellaForm from "../modules/registrations/CompareUmbrellaForm";
import ContractForm from "../modules/registrations/ContractForm";
import ExtraContractDetailsForm from "../modules/registrations/ExtraContractDetailsForm";
import RecommendationForm from "../modules/registrations/RecommendationForm";
import {
  useImport,
  usePost,
  useGetFields,
  useUpdateField,
  useCreateItem,
} from "./../modules/registrations/api";
import { usePost as payrollUsePost } from "./../modules/payroll/api";
import { publish } from "../pubSub";
import CommsPanelApp from "../modules/internal-comms/CommsPanelApp/CommsPanelApp";
import MarkLeadDeadButton from "../modules/registrations/MarkLeadDeadButton";
import DeleteLeadButton from "../modules/registrations/DeleteLeadButton";
import MatchedContractorPanel from "../modules/registrations/MatchedContractorPanel";
import { PanelActions, PanelDialog } from "@backslashbuild/pp-ui/dist/index.es";

export default function Registration({ isLead }) {
  const { id } = useParams();
  const history = useHistory();
  const doImport = useImport({ id });
  const post = usePost(`${id}/convert-to-lead`);
  const createNewRegistration = useCreateItem();
  const generatePayslip = usePost(`${id}/generate-payslip`);
  const createNewRegistrationSameAgency = usePost(`${id}/create-new-with-agency`);
  const sendSelfServeBamEmail = usePost(`${id}/send-self-serve-bam`);

  const { data: agencyData, loading: agencyLoading, error: agencyError } = useGetFields({
    id,
    formName: "agency",
  });
  const updateField = useUpdateField({ id, formName: "" });

  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const [selectedAgency, setSelectedAgency] = React.useState(null);
  const [generatingExamplePayslip, setGeneratingExamplePayslip] = React.useState(false);
  const [emailingSamplePayslip, setEmailingSamplePayslip] = React.useState(false);
  const [importMatchDialogOpen, setImportMatchDialogOpen] = React.useState(false);
  const [disableProductModal, setDisableProductModal] = React.useState(false);

  const createIllustration = payrollUsePost("illustrations/create");

  const values = {};

  const { data: matchData, loading: matchLoading, error: matchError } = useGetFields({
    id,
    formName: "matching-contractor",
  });

  async function handleImport() {
    var result = await doImport({ Key: "IsImported", Value: "True" });

    if (!result.isSuccess) {
      var errors = result.errors;

      publish(
        "notification",
        <ul>
          {errors.map((error) => {
            return <li>{error}</li>;
          })}
        </ul>
      );
    }

    if (result?.isSuccess) {
      publish(
        "notification",
        <ul>
          <li>Import successful</li>
        </ul>
      );

      history.push(`/registrations`);
    }
  }

  async function handleCreateNewRegistrationSameAgency() {
    var result = await createNewRegistrationSameAgency();

    if (!result.isSuccess) {
      var errors = result.errors;

      publish(
        "notification",
        <ul>
          {errors.map((error) => {
            return <li>{error}</li>;
          })}
        </ul>
      );
    }

    if (result?.isSuccess) {
      publish(
        "notification",
        <ul>
          <li>New lead created</li>
        </ul>
      );
      window.location.replace(`/registration/${result?.value}`);
    }
  }

  async function handleCreateNewRegistration() {
    var result = await createNewRegistration({ isLead: isLead });

    if (!result.isSuccess) {
      var errors = result.errors;

      publish(
        "notification",
        <ul>
          {errors.map((error) => {
            return <li>{error}</li>;
          })}
        </ul>
      );
    }

    if (result?.isSuccess) {
      publish(
        "notification",
        <ul>
          <li>New lead created</li>
        </ul>
      );
      window.location.replace(`/registration/${result?.value}`);
    }
  }

  async function handleSelfServeBam() {
    var result = await sendSelfServeBamEmail();

    if (!result.isSuccess) {
      var errors = result.errors;

      publish(
        "notification",
        <ul>
          {errors.map((error) => {
            return <li>{error}</li>;
          })}
        </ul>
      );
    }

    if (result?.isSuccess) {
      publish(
        "notification",
        <ul>
          <li>Self serve email sent succesfully</li>
        </ul>
      );
    }
  }

  async function handlePost() {
    var result = await post();
    if (result?.isSuccess) {
      window.location = `/registration/${id}`;
    }
  }

  async function handleViewExamplePayslip(productId) {
    setGeneratingExamplePayslip(true);
    try {
      const response = await generatePayslip({ productId });

      if (response.isSuccess) {
        publish("notification", "Illustration Created");

        var win = window.open(`/illustration-redirect/${response.value}`, "_blank");
        win.focus();
      } else {
        publish(
          "notification",
          <>
            <ul>
              {response.errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </>
        );
      }
    } catch (e) {
      console.error(e, "error making new pay run");
      publish("notification", "A network error occured.");
    } finally {
      setGeneratingExamplePayslip(false);
    }
  }

  async function handleEmailSamplePayslip(productId) {
    setEmailingSamplePayslip(true);
    try {
      const response = await generatePayslip({ productId, sendEmail: true });

      if (response.isSuccess) {
        publish("notification", "Payslip sent");
      } else {
        publish(
          "notification",
          <>
            <ul>
              {response.errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </>
        );
      }
    } catch (e) {
      console.error(e, "error making new pay run");
      publish("notification", "A network error occured.");
    } finally {
      setEmailingSamplePayslip(false);
    }
  }

  return (
    <SidebarWrapper
      panel={<CommsPanelApp open={sidebarOpen} itemId={id} configuration={"isLead"} />}
      sidebar={
        <Sidebar>
          <CommentsIconButton onClick={() => setSidebarOpen(!sidebarOpen)} />
        </Sidebar>
      }
    >
      <Content scrollable>
        <PageHeadingArea>
          <BackArrow onClick={() => history.goBack()} />
          <PageHeadingAreaSpacer />
          {Boolean(isLead) ? (
            <PageTitle>New Lead</PageTitle>
          ) : (
            <PageTitle>Quick Illustration</PageTitle>
          )}
          <PageHeadingAreaSpacer grow />
          {Boolean(isLead) && (
            <>
              <Button large onClick={async () => await handleCreateNewRegistration()}>
                Add another
              </Button>
              <PageHeadingAreaSpacer />
            </>
          )}
          {Boolean(isLead) && (
            <>
              <Button large onClick={async () => await handleCreateNewRegistrationSameAgency()}>
                Add another with agency
              </Button>
              <PageHeadingAreaSpacer />
            </>
          )}
          {!Boolean(isLead) && (
            <Button
              large
              onClick={async () => {
                await handlePost();
              }}
            >
              Convert to Lead
            </Button>
          )}
          <Button large onClick={() => handleSelfServeBam()} style={{ marginRight: 20 }}>
            Send Self-serve BAM
          </Button>
          {/* <Button
            large
            onClick={() => handleViewExamplePayslip()}
            submitting={generatingExamplePayslip}
            style={{ marginLeft: 20 }}
          >
            View payslip
          </Button> */}
          {Boolean(isLead) && (
            <>
              <MarkLeadDeadButton id={id} />
              <PageHeadingAreaSpacer />
              <DeleteLeadButton id={id} />
            </>
          )}
        </PageHeadingArea>
        <PanelColumnSet>
          {Boolean(isLead) && (
            <>
              <PanelColumn>
                <ContractorForm id={id} />
                <PanelSpacer large />
                <BankDetailsForm id={id} />
              </PanelColumn>
              <PanelColumnSpacer />
            </>
          )}

          <PanelColumn>
            <Panel>
              <PanelHeading>Agency</PanelHeading>
              <PanelSpacer />
              <PanelRow>
                <AgencyPicker
                  value={agencyData?.value?.find((field) => field?.key === "AgencyId")?.value}
                  onChange={(value) => updateField({ key: "AgencyId", value })}
                  showSelectedValueView={true}
                />
              </PanelRow>
              <PanelSpacer />
            </Panel>
            <PanelSpacer large />
            <AgencyForm id={id} />
            <PanelSpacer large />
            <PanelColumn>
              <FeesForm id={id} />
              <PanelSpacer large />
              <CompareUmbrellaForm id={id} />
              <PanelSpacer large />
              {Boolean(isLead) && <AwrForm id={id} />}
            </PanelColumn>
          </PanelColumn>
          <PanelColumnSpacer />
          <PanelColumn>
            <ContractForm id={id} />
            <PanelSpacer large />
            <StudentLoanDetailsForm id={id} />
            <PanelSpacer large />
            <PrivatePensionForm id={id} />
          </PanelColumn>
          <PanelColumnSpacer />

          <PanelColumn>
            <RecommendationForm
              id={id}
              onViewSamplePayslip={(productId) => handleViewExamplePayslip(productId)}
              onEmailSamplePayslip={(productId) => handleEmailSamplePayslip(productId)}
              disableDialog={disableProductModal}
              setDisableDialog={setDisableProductModal}
            >
              <Button
                large
                onClick={async () => {
                  if (matchData?.value?.isConfirmedMatch == true) {
                    setImportMatchDialogOpen(true);
                  } else {
                    await handleImport();
                  }
                }}
              >
                Import
              </Button>
            </RecommendationForm>
            <PanelSpacer large />
            <MatchedContractorPanel id={id} />
          </PanelColumn>
        </PanelColumnSet>
        <PanelDialog open={importMatchDialogOpen}>
          <PanelRow>
            Any changes you’ve made will be saved to the contractor, are you sure you want to import
            as a match?
          </PanelRow>
          <PanelActions>
            <Button onClick={handleImport}>Match with contractor</Button>
            <Button variant="secondary" onClick={() => setImportMatchDialogOpen(false)}>
              Cancel
            </Button>
          </PanelActions>
        </PanelDialog>
      </Content>
    </SidebarWrapper>
  );
}
