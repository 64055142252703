import React from "react";
import { useParams } from "react-router-dom";
import {
  Content,
  PageTitle,
  PanelColumn,
  PageHeadingArea,
  PanelColumnSet,
} from "@backslashbuild/pp-ui";

import ContractorPersonalDetailsForm from "../modules/contractors/ContractorPersonalDetailsForm";

function EditContractorPersonalDetails() {
  const { id } = useParams();

  return (
    <Content scrollable>
      <PageHeadingArea>
        <PageTitle>Edit Personal Details</PageTitle>
      </PageHeadingArea>

      <PanelColumnSet>
        <PanelColumn>
          <ContractorPersonalDetailsForm id={id} />
        </PanelColumn>
      </PanelColumnSet>
    </Content>
  );
}

export default EditContractorPersonalDetails;
