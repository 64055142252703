import React from "react";
import { useQuery, useMutation, queryCache } from "react-query";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import queryString from "query-string";
import * as uuid from "uuid";
import getApiUrl from "../../getApiUrl";

const apiHost = getApiUrl();
const service = "schedules-core";

// function useGetCached(path) {
//   const { oidcUser } = useReactOidc();
//   const authHeaders = { Authorization: `${oidcUser.token_type} ${oidcUser.access_token}` };

//   const query = useQuery(`${service}/${path}`, async function (){

//   })

// }

function invalidateSchedulesList(search) {
  queryCache.invalidateQueries((query) => {
    const [cacheType, cacheList, cacheSearch] = query.queryKey;

    return cacheType === service && cacheList == "list" && (!search || cacheSearch === search);
  });
}
function invalidateTemplates() {
  queryCache.invalidateQueries((query) => {
    const [cacheType] = query.queryKey;

    return cacheType === "templates";
  });
}

function invalidateSchedule(id) {
  queryCache.invalidateQueries((query) => {
    const [cacheType, cacheId] = query.queryKey;
    return cacheType === service && cacheId == id;
  });
}

function invalidateCsvImport(id) {
  queryCache.invalidateQueries((query) => {
    const [cacheType, cacheId] = query.queryKey;
    return cacheType === "csv-import" && cacheId == id;
  });
}

export function useGet(cacheKey, path, serviceOverride) {
  if (!path) {
    path = cacheKey;
  }
  if (!serviceOverride) {
    serviceOverride = service;
  }

  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };

  const { data, error, isLoading: loading, updatedAt: queryId } = useQuery(
    cacheKey,
    async (key, id) => {
      return fetch(`${apiHost}/${serviceOverride}/${path}`, {
        headers: {
          ...authHeaders,
        },
      }).then((r) => r.json());
    }
  );

  const runQuery = () => queryCache.invalidateQueries(path);

  React.useEffect(() => {
    runQuery();
  }, [path]);

  return { data, loading, error, queryId, refetch: runQuery };
}

export function useGetAgencyPEOBands({ agencyId = "", defaults = false }) {
  const query = useGet(`peo-bands?agencyId=${agencyId}&defaults=${defaults}`);
  return query;
}

export function useGetDeprecated(path) {
  const { oidcUser } = useReactOidc();
  const authHeaders = { Authorization: `${oidcUser.token_type} ${oidcUser.access_token}` };
  const [{ data, loading, error, queryId }, setState] = React.useState({
    data: null,
    loading: true,
    error: null,
    queryId: uuid.v4(),
  });

  function runQuery() {
    return fetch(`${apiHost}/${service}/${path}`, {
      headers: {
        ...authHeaders,
      },
    })
      .then((r) => r.json())
      .then((r) =>
        setState({
          data: r,
          loading: false,
          queryId: uuid.v4(),
        })
      )
      .catch((e) =>
        setState({
          loading: false,
          error: e,
          queryId: uuid.v4(),
        })
      );
  }
  React.useEffect(() => {
    runQuery();
  }, [path]);
  return { data, loading, error, refetch: runQuery, queryId };
}

export function useGetSchedules(search) {
  return useGet([service, "list", search], `schedules${search}`);
}

export function useGetScheduleRows(search) {
  return useGet([service, "list", search], `rows${search}`);
}

export function useGetMatches(search) {
  return useGet([service, "list", search], `matches${search}`);
}

export function usePost(path) {
  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };

  return React.useCallback(
    (body) => {
      return fetch(`${apiHost}/${service}/${path}`, {
        method: "POST",
        headers: {
          ...authHeaders,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }).then((x) => {
        return x.json();
      });
    },
    [path, authHeaders.Authorization]
  );
}

export function useGetBlob(path, filename) {
  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };

  return React.useCallback(() => {
    return fetch(`${apiHost}/${service}/${path}`, {
      method: "GET",
      headers: {
        ...authHeaders,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
  }, [path, filename]);
}

export function useGetSchedule(id) {
  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };

  const { data, error, isLoading: loading } = useQuery([service, id], async (key, id) => {
    return fetch(`${apiHost}/${service}/schedules/${id}`, {
      headers: {
        ...authHeaders,
      },
    }).then((r) => r.json());
  });

  return { data, loading, error };
}

// export function useGetSchedule(id) {
//   const query = useGet(`schedules/${id}`);

//   React.useEffect(() => {
//     subscribe(`${service}.update`, query.refetch);
//     return () => unsubscribe(`${service}.update`, query.refetch);
//   });

//   return query;
// }

export function useCreateSchedule() {
  const post = usePost(`schedules`);

  return React.useCallback((body) => {
    return post(body).then((x) => {
      invalidateSchedulesList();
      return x;
    });
  }, []);
}

export function useCreateRows() {
  const post = usePost(`rows`);

  return React.useCallback((body) => {
    return post(body).then((x) => {
      invalidateSchedule(body.scheduleId);
      return x;
    });
  }, []);
}

export function useCreateRFPSForSchedule() {
  const post = usePost(`schedules/create-rfps`);

  return React.useCallback((body) => {
    return post(body).then((x) => {
      invalidateSchedule(body.scheduleId);
      return x;
    });
  }, []);
}
export function useCreateMatchesForCSV() {
  const post = usePost(`csv-import/create-matches`);

  return React.useCallback((body) => {
    return post(body).then((x) => {
      invalidateCsvImport(body.csvImportId);
      return x;
    });
  }, []);
}
export function useProcessSchedule() {
  const post = usePost(`schedules/process`);

  return React.useCallback((body) => {
    return post(body).then((x) => {
      invalidateSchedule(body.scheduleId);
      return x;
    });
  }, []);
}

export function useResyncMatches() {
  const post = usePost(`schedules/resync-matches`);

  return React.useCallback((body) => {
    return post(body).then((x) => {
      invalidateSchedule(body.scheduleId);
      return x;
    });
  }, []);
}

export function useMarkScheduleAsChecked() {
  const post = usePost(`schedules/mark-checked`);

  return React.useCallback((body) => {
    return post(body).then((x) => {
      invalidateSchedule(body.scheduleId);
      return x;
    });
  }, []);
}
export function useCancelSchedule() {
  const post = usePost(`schedules/cancel`);

  return React.useCallback((body) => {
    return post(body).then((x) => {
      invalidateSchedule(body.scheduleId);
      return x;
    });
  }, []);
}
export function useUpdateSchedule() {
  const post = usePost(`schedules/update`);

  return React.useCallback((body) => {
    return post(body).then((x) => {
      invalidateSchedule(body.scheduleId);
      return x;
    });
  }, []);
}

export function useCreateInvoices() {
  const post = usePost(`schedules/create-invoices`);

  return React.useCallback((body) => {
    return post(body).then((x) => {
      invalidateSchedule(body.scheduleId);
      return x;
    });
  }, []);
}
export function useSetRowType(scheduleId) {
  const post = usePost(`rows/set-type`);

  return React.useCallback((body) => {
    return post(body).then((x) => {
      invalidateSchedule(scheduleId);
      return x;
    });
  }, []);
}
export function usePostDeleteRow(scheduleId) {
  const post = usePost(`rows/delete-row`);

  return React.useCallback((body) => {
    return post(body).then((x) => {
      invalidateSchedule(scheduleId);
      return x;
    });
  }, []);
}
export function useSetRowInvoiceMode(scheduleId) {
  const post = usePost(`rows/set-invoice-mode`);

  return React.useCallback((body) => {
    return post(body).then((x) => {
      invalidateSchedule(scheduleId);
      return x;
    });
  }, []);
}
export function useSetRowSettings(scheduleId) {
  const post = usePost(`rows/set-row-settings`);

  return React.useCallback((body) => {
    return post(body).then((x) => {
      invalidateSchedule(scheduleId);
      return x;
    });
  }, []);
}
export function useSetRowDescription(scheduleId) {
  const post = usePost(`rows/set-row-description`);

  return React.useCallback((body) => {
    return post(body).then((x) => {
      invalidateSchedule(scheduleId);
      return x;
    });
  }, []);
}
export function useSetRowAdditionalPeriods(scheduleId) {
  const post = usePost(`rows/set-row-additional-periods`);

  return React.useCallback((body) => {
    return post(body).then((x) => {
      invalidateSchedule(scheduleId);
      return x;
    });
  }, []);
}
export function useCreateCreditNote() {
  const post = usePost(`schedules/create-credit-note`);

  return React.useCallback((body) => {
    return post(body).then((x) => {
      invalidateSchedule(body.scheduleId);
      return x;
    });
  }, []);
}
export function useApproveMatchesForCSV() {
  const post = usePost(`csv-import/approve-matches`);

  return React.useCallback((body) => {
    return post(body).then((x) => {
      console.log("should invalidate something here based on x", { x });
      //publish(`${service}.update`, x);
      return x;
    });
  }, []);
}
export function useUpdateMatch() {
  const post = usePost(`matches/update`);

  return React.useCallback((body) => {
    return post(body).then((x) => {
      //publish(`${service}.update`, x);
      return x;
    });
  }, []);
}
export function useImportIntoSchedule() {
  const post = usePost(`csv-import/import-into-schedule`);

  return React.useCallback((body) => {
    return post(body).then((x) => {
      //publish(`${service}.update`, x);
      return x;
    });
  }, []);
}

export function useGetCsvImport(id) {
  const query = useGet(["csv-import", id], `csv-import/${id}`);
  return query;
}

export function useGetRows({ scheduleId }) {
  const query = useGet(
    [service, scheduleId, "rows"],
    `rows?${queryString.stringify({ scheduleId })}`
  );

  return query;
}
export function useGetCSVImports({ scheduleId }) {
  const query = useGet(
    [service, scheduleId, "csv-imports"],
    `csv-import?${queryString.stringify({ scheduleId })}`
  );
  return query;
}
export function useGetTemplates({ agencyId }) {
  const query = useGet(["templates", agencyId], `templates?${queryString.stringify({ agencyId })}`);
  return query;
}
export function useCreateTemplate() {
  const post = usePost(`templates`);

  return React.useCallback((body) => {
    return post(body).then((x) => {
      invalidateTemplates();
      return x;
    });
  }, []);
}

export function useDownloadCSVImport(id) {
  return useGetBlob(`csv-import/download/${id}`, `schedule-upload-${id}.csv`);
}

export function useDownloadBreakdown(scheduleId, productId, name) {
  return useGetBlob(`schedules/breakdown/${scheduleId}/${productId}`, `${name}.csv`);
}

export function usePostFile(path) {
  const { oidcUser } = useReactOidc();
  const authHeaders = { Authorization: `${oidcUser.token_type} ${oidcUser.access_token}` };

  return React.useCallback(
    (file) => {
      const body = new FormData();
      body.append("file", file);
      return fetch(`${apiHost}/${service}/${path}`, {
        method: "POST",
        headers: {
          ...authHeaders,
          // "Content-Type": "multipart/form-data",
        },
        body,
      }).then((r) => r.json());
    },
    [path, authHeaders.Authorization]
  );
}

export function useUploadCsv(scheduleId) {
  const post = usePostFile(`csv-import/upload/${scheduleId}`);

  return React.useCallback((body) => {
    return post(body).then((x) => {
      //publish(`${service}.update`, x);
      return x;
    });
  }, []);
}

export function useSearchContractors(search) {
  return useGet(["schedules-core-contractors", search], `index/search${search}`, "contractors");
}

export function useGetContractorProduct(value) {
  return useGet(
    ["schedules-core-contractor-product", value],
    `contractor-products/${value}`,
    "contractors"
  );
}
