import React from "react";
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  QueryDropdown,
  TableHeader,
  TableHeaderColumn,
  PageLoading,
  PanelColumnSet,
  PanelColumn,
  Panel,
  PanelRow,
  Label,
  TextInput,
  PanelColumnSpacer,
  PanelActions,
  Button,
  Dropdown,
  DropdownOption,
  Link,
  PageHeadingArea,
  BackArrow,
  PageHeadingAreaSpacer,
  PageTitle,
} from "@backslashbuild/pp-ui";
import { useGetCsvImport, useCreateMatchesForCSV } from "./api";
import TemplatePicker from "./TemplatePicker";
import TemplateCreationDialog from "./TemplateCreationDialog";
import { publish } from "../../pubSub";
import { useHistory } from "react-router-dom";

function extendArray(array, length) {
  if (Array.isArray(array)) {
    if (array.length >= length) return array;
    return [...array, ...Array(length).fill("")].slice(0, length);
  } else {
    return Array(length).fill("");
  }
}

// These values must match those in ColumnType.cs in the Schedules.Domain project with the exception of "Ignore".
const columnTypeOptons = [
  { value: "99", name: "** Ignore **" },
  { value: "1", name: "RACS ID" },
  { value: "11", name: "Agency Contractor ID" },
  { value: "9", name: "Forename(s)" },
  { value: "10", name: "Surname" },
  { value: "80", name: "Surname Forename" },
  { value: "81", name: "Forename Surname" },
  { value: "12", name: "DOB (yyyymmdd)" },
  { value: "97", name: "Timesheet Ref" },
  { value: "98", name: "Contractor Description" },
  { value: "6", name: "Deductions" },
  { value: "7", name: "Additions" },
  { value: "28", name: "Timesheet Date (yyyymmdd)" },
  { value: "29", name: "Net total (for checking)" },
  { value: "90", name: "Hours Per Day" },
  { value: "91", name: "Hours Total" },
  { value: "2", name: "No of Days" },
  { value: "3", name: "No of Hours" },
  { value: "8", name: "Days or Hours" },
  { value: "4", name: "Rate" },
  { value: "5", name: "Rate Description" },
  { value: "13", name: "No of Days (2)" },
  { value: "14", name: "No of Hours (2)" },
  { value: "15", name: "Days or Hours (2)" },
  { value: "16", name: "Rate (2)" },
  { value: "17", name: "Rate Description (2)" },
  { value: "18", name: "No of Days (3)" },
  { value: "19", name: "No of Hours (3)" },
  { value: "20", name: "Days or Hours (3)" },
  { value: "21", name: "Rate (3)" },
  { value: "22", name: "Rate Description (3)" },
  { value: "23", name: "No of Days (4)" },
  { value: "24", name: "No of Hours (4)" },
  { value: "25", name: "Days or Hours (4)" },
  { value: "26", name: "Rate (4)" },
  { value: "27", name: "Rate Description (4)" },
  { value: "30", name: "No of Days (5)" },
  { value: "31", name: "No of Hours (5)" },
  { value: "32", name: "Days or Hours (5)" },
  { value: "33", name: "Rate (5)" },
  { value: "34", name: "Rate Description (5)" },
  { value: "35", name: "No of Days (6)" },
  { value: "36", name: "No of Hours (6)" },
  { value: "37", name: "Days or Hours (6)" },
  { value: "38", name: "Rate (6)" },
  { value: "39", name: "Rate Description (6)" },
  { value: "40", name: "No of Days (7)" },
  { value: "41", name: "No of Hours (7)" },
  { value: "42", name: "Days or Hours (7)" },
  { value: "43", name: "Rate (7)" },
  { value: "44", name: "Rate Description (7)" },
  { value: "45", name: "No of Days (8)" },
  { value: "46", name: "No of Hours (8)" },
  { value: "47", name: "Days or Hours (8)" },
  { value: "48", name: "Rate (8)" },
  { value: "49", name: "Rate Description (8)" },
  { value: "50", name: "No of Days (9)" },
  { value: "51", name: "No of Hours (9)" },
  { value: "52", name: "Days or Hours (9)" },
  { value: "53", name: "Rate (9)" },
  { value: "54", name: "Rate Description (9)" },
  { value: "55", name: "No of Days (10)" },
  { value: "56", name: "No of Hours (10)" },
  { value: "57", name: "Days or Hours (10)" },
  { value: "58", name: "Rate (10)" },
  { value: "59", name: "Rate Description (10)" },
  { value: "60", name: "No of Days (11)" },
  { value: "61", name: "No of Hours (11)" },
  { value: "62", name: "Days or Hours (11)" },
  { value: "63", name: "Rate (11)" },
  { value: "64", name: "Rate Description (11)" },
  { value: "65", name: "No of Days (12)" },
  { value: "66", name: "No of Hours (12)" },
  { value: "67", name: "Days or Hours (12)" },
  { value: "68", name: "Rate (12)" },
  { value: "69", name: "Rate Description (12)" },
  { value: "70", name: "No of Days (13)" },
  { value: "71", name: "No of Hours (13)" },
  { value: "72", name: "Days or Hours (13)" },
  { value: "73", name: "Rate (13)" },
  { value: "74", name: "Rate Description (13)" },
  { value: "75", name: "No of Days (14)" },
  { value: "76", name: "No of Hours (14)" },
  { value: "77", name: "Days or Hours (14)" },
  { value: "78", name: "Rate (14)" },
  { value: "79", name: "Rate Description (14)" },
];

function ColumnPicker({ value, onChange }) {
  const [search, setSearch] = React.useState("");

  return (
    <Dropdown value={value} onChange={(e) => onChange(e.target.value)}>
      <DropdownOption value="">Choose</DropdownOption>
      {columnTypeOptons.map((type) => (
        <DropdownOption value={type.value}>{type.name}</DropdownOption>
      ))}
    </Dropdown>
  );

  return (
    <QueryDropdown
      placeholder="Select column type"
      width="100%"
      search={search}
      onSearchChange={setSearch}
      value={value}
      onChange={onChange}
      options={columnTypeOptons
        .filter((option) => option.name.toLowerCase().match(search.toLowerCase()))
        .map((option) => {
          return option.value;
        })}
      renderOption={(value) => {
        const option = columnTypeOptons.find((option) => value == option.value);
        if (option != null && option != undefined) {
          return option.name;
        }
      }}
    />
  );
}

function CSVImportEditor({ id }) {
  const query = useGetCsvImport(id);
  const post = useCreateMatchesForCSV();
  const history = useHistory();
  const [newColumnTypes, setNewColumnTypes] = React.useState([]);
  const [newNumberOfHeaderRows, setNewNumberOfHeaderRows] = React.useState(0);
  const [newNumberOfFooterRows, setNewNumberOfFooterRows] = React.useState(0);

  React.useEffect(() => {
    if (query.data?.value) {
      if (query.data?.value?.isMatchesCreated) {
        publish("notification", "Matches already created, going to match editor.");
        history.replace(`/schedule-c-matching/${id}`);
      }

      setNewColumnTypes(query.data?.value?.columnTypeIds);
      setNewNumberOfHeaderRows(query.data?.value?.numberOfHeaderRows);
      setNewNumberOfFooterRows(query.data?.value?.numberOfFooterRows);
    }
  }, [query.data]);

  if (!query?.data?.value) {
    return <PageLoading />;
  }

  const { rows, maxColumns, dateCreated } = query.data.value;
  const tableWidth = rows.length ? maxColumns * 320 : null;

  async function handleSubmit() {
    try {
      const response = await post({
        csvImportId: id,
        numberOfHeaderRows: newNumberOfHeaderRows,
        numberOfFooterRows: newNumberOfFooterRows,
        columnTypeIds: newColumnTypes,
      });

      if (response.isSuccess) {
        publish("notification", "Matches created");
        history.replace(`/schedule-c-matching/${id}`);
      } else {
        publish(
          "notification",
          <div>
            Unable to process CSV
            <ul>
              {response.errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </div>
        );
      }
    } catch (e) {
      console.error(e, "error sending to payroll");
      publish("notification", "A network error occured.");
    }
  }

  return (
    <>
      <PageHeadingArea>
        <BackArrow onClick={() => history.goBack()} />
        <PageHeadingAreaSpacer />
        <PageTitle>CSV Import {dateCreated}</PageTitle>
        <PageHeadingAreaSpacer grow />
        <TemplateCreationDialog
          agencyId={query.data?.value?.agencyId}
          columnTypes={newColumnTypes}
          numberOfHeaderRows={newNumberOfHeaderRows}
        />
        <PageHeadingAreaSpacer />
        <TemplatePicker
          agencyId={query.data?.value?.agencyId}
          columnTypes={newColumnTypes}
          setColumnTypes={setNewColumnTypes}
          numberOfHeaderRows={newNumberOfHeaderRows}
          setNumberOfHeaderRows={setNewNumberOfHeaderRows}
        />
        <PageHeadingAreaSpacer />

        <Button large onClick={handleSubmit}>
          Process matches
        </Button>
      </PageHeadingArea>
      <PanelColumnSet>
        {/* <PanelColumn>
        <Panel>
          <PanelRow>
            <Label label="number of headers">
              <TextInput
                type="number"
                value={newNumberOfHeaderRows}
                onChange={(e) => setNewNumberOfHeaderRows(Number(e.target.value))}
              />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="number of footers">
              <TextInput
                type="number"
                value={newNumberOfFooterRows}
                onChange={(e) => setNewNumberOfFooterRows(Number(e.target.value))}
              />
            </Label>
          </PanelRow>
          <PanelActions>
            <Button onClick={handleSubmit}>Process matches</Button>
          </PanelActions>
        </Panel>
      </PanelColumn>
      <PanelColumnSpacer /> */}
        <Table width={tableWidth} standardTable={true}>
          <TableBody fullPageTable={true} headerHeight={0}>
            <TableRow disableHover>
              <TableCell width={80} shaded>
                <Button onClick={() => setNewNumberOfHeaderRows(0)} />
              </TableCell>
              {rows.length > 0
                ? Array(maxColumns)
                    .fill(0)
                    .map((c, i) => (
                      <TableCell shaded width={320}>
                        <ColumnPicker
                          value={newColumnTypes[i]}
                          onChange={(value) => {
                            const clone = [...newColumnTypes];
                            clone[i] = value;
                            setNewColumnTypes(clone);
                          }}
                        />
                      </TableCell>
                    ))
                : null}
            </TableRow>

            {rows.map((row, i) => (
              <TableRow disableHover>
                <TableCell
                  width={80}
                  shaded={i < newNumberOfHeaderRows || i > rows.length - 1 - newNumberOfFooterRows}
                  summary={i === newNumberOfHeaderRows}
                >
                  <Button small onClick={() => setNewNumberOfHeaderRows(i + 1)} />
                </TableCell>
                {extendArray(row.cells, maxColumns).map((cell) => (
                  <TableCell
                    width={320}
                    shaded={
                      i < newNumberOfHeaderRows || i > rows.length - 1 - newNumberOfFooterRows
                    }
                    summary={i === newNumberOfHeaderRows}
                  >
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </PanelColumnSet>
    </>
  );
}

// function PureTable() {
//   return React.useMemo(() =>
//     rows.map((row, i) => (
//       <TableRow disableHover>
//         {extendArray(row.cells, rows[0].length).map((cell) => (
//           <TableCell
//             width={320}
//             shaded={i < newNumberOfHeaderRows || i > rows.length - 1 - newNumberOfFooterRows}
//             summary={i === newNumberOfHeaderRows}
//           >
//             {cell}
//           </TableCell>
//         ))}
//       </TableRow>
//     ))
//   );
// }

export default CSVImportEditor;
