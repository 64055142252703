import React from "react";
import { Panel, PageLoading, PanelHeading, PanelActions, Button } from "@backslashbuild/pp-ui";
import FormField from "./FormField";
import { useGetFields, useUpdateFields } from "./api";
import { isTextNullOrEmpty } from "./utils";

export default function ContactForm({ id, onClose }) {
  const { data, loading, error } = useGetFields({ id, formName: "contact" });
  const updateFields = useUpdateFields({ id, formName: "contact" });
  const [edits, setEdits] = React.useState([]);

  if (loading) return <PageLoading />;

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    return data.value.map((field) => (
      <FormField key={field.key} field={field} onSave={getOnSaveHandler(field.key)} />
    ));
  }

  function getOnSaveHandler(key) {
    return (value) => {
      setEdits([...edits.filter((e) => e.key !== key), { key, value }]);
    };
  }

  function handleSave() {
    updateFields(edits).then(() => {
      window.location.reload();
    });
  }

  return (
    <Panel>
      {handleRender()}
      <PanelActions>
        <Button variant="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleSave();
            onClose();
          }}
        >
          Save
        </Button>
      </PanelActions>
    </Panel>
  );
}
