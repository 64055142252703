import React from "react";
import { TextInput, CheckBox, FilterLabel } from "@backslashbuild/pp-ui";
import InvoiceCategoryPicker from "./InvoiceCategoryPicker";
import AgencyPicker from "./AgencyPicker";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router";
import { PageHeadingAreaSpacer } from "@backslashbuild/pp-ui/dist/index.es";
import ClearAdornment from "../../components/ClearAdornment";

function InvoiceFiltering({ currentTaxWeek }) {
  const history = useHistory();
  const location = useLocation();
  const [state, setState] = React.useState(queryString.parse(location.search));

  React.useEffect(() => {
    setState(queryString.parse(location.search));
  }, [location.search]);

  function update(newState) {
    history.replace({
      search: queryString.stringify(
        {
          ...newState,
          skip: undefined,
        },
        { skipEmptyString: false, skipNull: false }
      ),
    });
  }

  return (
    <>
      <FilterLabel label="Product">
        <InvoiceCategoryPicker
          value={state.category}
          onChange={(e) => update({ ...state, category: e })}
        />
      </FilterLabel>
      <PageHeadingAreaSpacer />

      <FilterLabel label="Agency">
        <AgencyPicker value={state.agency} onChange={(v) => update({ ...state, agency: v })} />
      </FilterLabel>
      <PageHeadingAreaSpacer />
      <FilterLabel label="Week">
        <TextInput
          width={80}
          value={state.weekNumber ?? currentTaxWeek}
          onChange={(e) => setState({ ...state, weekNumber: e.target.value })}
          onBlur={() => update(state)}
          endAdornment={
            state.weekNumber != "" && (
              <ClearAdornment onClick={() => update({ ...state, weekNumber: "" })} />
            )
          }
        />
      </FilterLabel>
      <PageHeadingAreaSpacer />
      <FilterLabel label="Batch">
        <TextInput
          width={80}
          value={state.invoiceBatchNumber}
          onChange={(e) => setState({ ...state, invoiceBatchNumber: e.target.value })}
          onBlur={() => update(state)}
          endAdornment={
            Boolean(state.invoiceBatchNumber) && (
              <ClearAdornment onClick={() => update({ ...state, invoiceBatchNumber: "" })} />
            )
          }
        />
      </FilterLabel>
      <PageHeadingAreaSpacer />

      <FilterLabel label="Invoice Number">
        <TextInput
          width={120}
          value={state.invoiceNumber}
          onChange={(e) => {
            if (/^[a-zA-Z0-9]*$/.test(e.target.value)) {
              setState({ ...state, invoiceNumber: e.target.value?.trim().toUpperCase() });
            }
          }}
          onBlur={() => update(state)}
        />
      </FilterLabel>
      <PageHeadingAreaSpacer />

      <FilterLabel label="Options">
        <CheckBox
          label="Sage only"
          checked={state.isSageOnly !== "false"}
          onChange={(e) => update({ ...state, isSageOnly: e.target.checked })}
        />
      </FilterLabel>
      <FilterLabel>
        <CheckBox
          label="Invoices"
          checked={state.includeInvoices !== "false"}
          onChange={(e) => update({ ...state, includeInvoices: e.target.checked })}
        />
      </FilterLabel>
      <FilterLabel>
        <CheckBox
          label="Credit Notes"
          checked={state.includeCreditNotes !== "false"}
          onChange={(e) => update({ ...state, includeCreditNotes: e.target.checked })}
        />
      </FilterLabel>
      <FilterLabel>
        <CheckBox
          label="Furlough Only"
          checked={state.isFurloughOnly == "true"}
          onChange={(e) => update({ ...state, isFurloughOnly: e.target.checked })}
        />
      </FilterLabel>
    </>
  );
}

export default InvoiceFiltering;
