import React from "react";
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableFooter,
  PageLoading,
  PageHeadingArea,
  PageTitle,
  Button,
  StatusIndicator,
  PageHeadingAreaSpacer,
  DateFormatted,
  Link,
} from "@backslashbuild/pp-ui";
import { isTextNullOrEmpty } from "./utils";
import { useGetItems, usePost } from "./api";
import { useHistory } from "react-router-dom";
import getApiUrl from "../../getApiUrl";
import { publish } from "./pubSub";

function PureTable({ data, queryId }) {
  const history = useHistory();

  return React.useMemo(
    () =>
      data.value.items.map((template) => (
        <TableRow disableHover>
          <TableCell width="16%">
            <Link onClick={() => history.push(`/contract-editor/${template?.id}`)}>
              {template.name}
            </Link>
          </TableCell>
          <TableCell width="16%">
            <Link onClick={() => history.push(`/contract-editor/${template?.id}`)}>
              {template.type}
            </Link>
          </TableCell>
          <TableCell width="16%">
            {template?.dateCreated && (
              <Link onClick={() => history.push(`/contract-editor/${template?.id}`)}>
                <DateFormatted date={template?.dateCreated} />
              </Link>
            )}
          </TableCell>
          <TableCell width="16%">
            {template?.dateUpdated && (
              <Link onClick={() => history.push(`/contract-editor/${template?.id}`)}>
                <DateFormatted date={template?.dateUpdated} />
              </Link>
            )}
          </TableCell>
          <TableCell width="16%">
            <Link onClick={() => history.push(`/contract-editor/${template?.id}`)}>
              <StatusIndicator value={template.isActive} />
            </Link>
          </TableCell>
          <TableCell width="16%">
            <Link href={getApiUrl() + "/contracts/template/" + template?.id + "/render"}>
              {"Preview"}
            </Link>
          </TableCell>
        </TableRow>
      )),
    [queryId]
  );
}

function ContractTemplatesTable() {
  const { data, loading, error, queryId } = useGetItems("templates");
  const createTemplate = usePost("templates/create");

  if (loading || !data) return <PageLoading />;
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  function handleCreate() {
    createTemplate({
      name: "New template",
      type: "contract",
      content: "",
      isActive: true,
    }).then((r) => {
      if (r?.isSuccess) {
        publish("notification", "Template created");
        window.location = `/contract-editor/${r?.value}`;
      } else {
        publish(
          "notification",
          <div>
            Unable to create template
            <ul>
              {r?.errors?.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </div>
        );
      }
    });
  }

  return (
    <>
      <PageHeadingArea>
        <PageTitle>Contracts</PageTitle>
        <PageHeadingAreaSpacer grow />
        <Button
          onClick={() => {
            handleCreate();
          }}
        >
          Add a new template
        </Button>
      </PageHeadingArea>
      <Table standardTable>
        <TableHeader>
          <TableHeaderColumn width="16%">Template Name</TableHeaderColumn>
          <TableHeaderColumn width="16%">Type</TableHeaderColumn>
          <TableHeaderColumn width="16%">Date Created</TableHeaderColumn>
          <TableHeaderColumn width="16%">Last Updated</TableHeaderColumn>
          <TableHeaderColumn width="16%">Active</TableHeaderColumn>
          <TableHeaderColumn width="16%"></TableHeaderColumn>
        </TableHeader>
        <TableBody fullPageTable={true} footerHeight={TableFooter.height}>
          <PureTable data={data} queryId={queryId} />
        </TableBody>
      </Table>
    </>
  );
}

export default ContractTemplatesTable;
