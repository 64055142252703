import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  TableRow,
  Table,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableFooter,
  TableBody,
  BoldText,
  Pill,
  StatusIndicator,
} from "@backslashbuild/pp-ui";
import { Link as RouterLink } from "react-router-dom";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";
import { useGetMatches } from "./api";
import { utcToLocalText } from "../../utils";
import { MatchRowEditor } from "./MatchRowEditor";

const c = {
  contractorForenames: 70,
  contractorSurname: 70,
  amountPending: 70,
  suppliedContractorProductNumber: 70,
  agencysOwnId: 70,
  delete: 50,
  dropdown: 100,
  approve: 80,
};

const totalWidth = Object.keys(c).reduce((total, key) => (total += c[key]), 0);

function PureTable({ data, queryId }) {
  return React.useMemo(() =>
    data.value.items.map((match) => <MatchRowEditor match={match} />, [queryId])
  );
}

function TableSkeleton() {
  return (
    <TableRow>
      <TableCell width={c.contractorForenames}>-</TableCell>
      <TableCell width={c.contractorSurname}>-</TableCell>
      <TableCell width={c.amountPending}>-</TableCell>
      <TableCell width={c.suppliedContractorProductNumber}>-</TableCell>
      <TableCell width={c.agencysOwnId}>-</TableCell>
      <TableCell width={c.delete}>-</TableCell>
      <TableCell width={c.dropdown}>-</TableCell>
      <TableCell width={c.dropdown}>-</TableCell>
      <TableCell width={c.approve}>-</TableCell>
    </TableRow>
  );
}

function MatchesTable() {
  const location = useLocation();
  const search = usePagination(location.search);
  const { data, loading, error, queryId } = useGetMatches(search);

  const isLoaded = Boolean(data?.value);

  return (
    <Table standardTable width={totalWidth}>
      <TableHeader>
        <TableHeaderColumn width={c.contractorForenames}>Forenames</TableHeaderColumn>
        <TableHeaderColumn width={c.contractorSurname}>Surname</TableHeaderColumn>
        <TableHeaderColumn width={c.amountPending}>Amount Pending</TableHeaderColumn>
        <TableHeaderColumn width={c.suppliedContractorProductNumber}>
          Product Number
        </TableHeaderColumn>
        <TableHeaderColumn width={c.agencysOwnId}>Agencies Own ID</TableHeaderColumn>
        <TableHeaderColumn width={c.delete}>Delete</TableHeaderColumn>
        <TableHeaderColumn width={c.dropdown}>Contractor Product</TableHeaderColumn>
        <TableHeaderColumn width={c.dropdown}>Override Product</TableHeaderColumn>
        <TableHeaderColumn width={c.approve} />
      </TableHeader>
      <TableBody fullPageTable={true} footerHeight={TableFooter.height}>
        {isLoaded ? (
          <PureTable data={data} queryId={queryId} />
        ) : data?.errors ? (
          data?.errors.map((error) => <div>{error}</div>)
        ) : (
          <TableSkeleton />
        )}
      </TableBody>
      <TableFooter>
        {loading && <BoldText>Loading...</BoldText>}
        {error && <BoldText>A network error occured.</BoldText>}
        <QueryPaginator
          itemsTotal={data?.value?.itemsTotal}
          itemsRetrieved={data?.value?.itemsRetrieved}
        />
      </TableFooter>
    </Table>
  );
}

export default MatchesTable;
