import React from "react";
import { DateTime } from "luxon";

export default function EventPanel({ item }) {
  return (
    <>
      <div
        style={{
          padding: 10,
          border: "1px solid #CED8E2",
          margin: "4px",
          backgroundColor: "#F3F9FA",
        }}
      >
        <div
          style={{
            borderBottom: "1px solid rgba(224,224,224,1)",
            paddingBottom: "4px",
            marginBottom: "4px",
          }}
        >
          <small>
            Audit event: {item.type} &middot;{" "}
            {DateTime.fromISO(item.dateCreated).toFormat("dd/MM/yyyy HH:mm")}
          </small>
        </div>
        {item?.operations?.map((operation) => (
          <>
            <>{operation}</>
            <br />
          </>
        ))}
        {item?.fields?.map((field) => (
          <>
            {`${field.fieldName} - ${field.displayValue}`}
            <br />
          </>
        ))}
      </div>
    </>
  );
}
