import React from "react";
import { Content, PageHeadingArea, PageTitle } from "@backslashbuild/pp-ui";
import UploadAgenciesCsvDialog from "../modules/legacy-integration/UploadAgenciesCsvDialog";
import { PanelSpacer } from "@backslashbuild/pp-ui/dist/index.es";
import ResetAgencyIndexButton from "../modules/search/ResetAgencyIndexButton";
import UpdateAgencyMappingButton from "../modules/search/UpdateAgencyMappingButton";
import ResetInvoiceIndexButton from "../modules/search/ResetInvoiceIndexButton";
import CreateInvoiceIndexButton from "../modules/search/CreateInvoiceIndexButton";
import ReindexInvoicesButton from "../modules/invoicing/ReindexInvoicesButton";

export default function LegacyIntegration() {
  return (
    <Content>
      <PageHeadingArea>
        <PageTitle>Legacy Integration Admin</PageTitle>
      </PageHeadingArea>
      <UploadAgenciesCsvDialog />
      <PanelSpacer />
      <ResetAgencyIndexButton />
      <PanelSpacer />
      <UpdateAgencyMappingButton />
      <PanelSpacer />
      <CreateInvoiceIndexButton />
      <PanelSpacer />
      <ResetInvoiceIndexButton />
      <PanelSpacer />
      <ReindexInvoicesButton />
      <PanelSpacer />
    </Content>
  );
}
