import React from "react";
import {
  TableRow,
  Table,
  TableCell,
  TableHeaderColumn,
  TableBody,
  BoldText,
  Pill,
  StatusIndicator,
} from "@backslashbuild/pp-ui";
import { Link as RouterLink } from "react-router-dom";
import CustomPanelHeader from "../../components/CustomPanelHeader";
import FloatingFooter from "../../components/FloatingFooter";
import { useGet } from "./api";
import { utcToLocalText, formatCurrency, utcToYmd } from "../../utils";
import Paginator from "../../containers/Paginator";

const c = {
  dateCreated: 200,
  taxWeek: 100,
  taxCode: 100,
  hours: 100,
  days: 100,
  gross: 100,
  netPay: 100,
  totalPay: 100,
  holidayAccrued: 100,
  holidayClaimed: 100,
  payeTax: 100,
  employeePens: 100,
  banked: 100,
  issues: 100,
  issuesIgnored: 100,
  status: 100,
};

const totalWidth = Object.keys(c).reduce((total, key) => (total += c[key]), 0);

function PureTable({ data, queryId }) {
  return React.useMemo(() =>
    data.value.items.map(
      (payslip) => (
        <TableRow key={payslip.id} component={RouterLink} to={`/payslip/${payslip.id}`}>
          <TableCell width={c.dateCreated}>
            {payslip?.dateCreated && utcToYmd(payslip.dateCreated)}
          </TableCell>
          <TableCell width={c.taxWeek}>{payslip.taxWeek}</TableCell>
          <TableCell width={c.taxCode}>{payslip.taxCode}</TableCell>
          <TableCell width={c.hours}>{payslip.totalHours}</TableCell>
          <TableCell width={c.days}>{payslip.totalDays}</TableCell>
          <TableCell width={c.gross}>{formatCurrency(payslip.grossTaxablePay)}</TableCell>
          <TableCell width={c.netPay}>{formatCurrency(payslip.netPay)}</TableCell>
          <TableCell width={c.totalPay}>{formatCurrency(payslip.totalPay)}</TableCell>
          <TableCell width={c.holidayAccrued}>{payslip.holidayAccrued}</TableCell>
          <TableCell width={c.holidayClaimed}>{payslip.holidayClaimed}</TableCell>
          <TableCell width={c.payeTax}>{formatCurrency(payslip.payeTax)}</TableCell>
          <TableCell width={c.employeePens}>{formatCurrency(payslip.employeePension)}</TableCell>
          <TableCell width={c.banked}>{formatCurrency(payslip.banked)}</TableCell>
          <TableCell width={c.issues}>
            {payslip?.errors?.length > 0 && <Pill status="error">{payslip.errors.length}</Pill>}{" "}
            {payslip?.warnings?.length > 0 && (
              <Pill status="warning">{payslip.warnings.length}</Pill>
            )}
          </TableCell>
          <TableCell width={c.issuesIgnored}>
            {payslip?.warnings?.length > 0 && <StatusIndicator value={payslip.ignoreWarnings} />}
          </TableCell>
          <TableCell width={c.status}>
            {payslip?.isFinalised
              ? `Finalised at ${utcToLocalText(payslip?.finalisedAt)}`
              : payslip?.isCancelled
              ? `Cancelled at ${utcToLocalText(payslip?.cancelledAt)}`
              : "Staged"}
          </TableCell>
        </TableRow>
      ),
      [queryId]
    )
  );
}

function TableSkeleton() {
  return (
    <TableRow>
      <TableCell width={c.dateCreated}>-</TableCell>
      <TableCell width={c.taxWeek}>-</TableCell>
      <TableCell width={c.taxCode}>-</TableCell>
      <TableCell width={c.hours}>-</TableCell>
      <TableCell width={c.days}>-</TableCell>
      <TableCell width={c.gross}>-</TableCell>
      <TableCell width={c.netPay}>-</TableCell>
      <TableCell width={c.totalPay}>-</TableCell>
      <TableCell width={c.holidayAccrued}>-</TableCell>
      <TableCell width={c.holidayClaimed}>-</TableCell>
      <TableCell width={c.payeTax}>-</TableCell>
      <TableCell width={c.employeePens}>-</TableCell>
      <TableCell width={c.banked}>-</TableCell>
      <TableCell width={c.issues}>-</TableCell>
      <TableCell width={c.issuesIgnored}>-</TableCell>
      <TableCell width={c.status}>-</TableCell>
    </TableRow>
  );
}

export default function ContractorPayslipsTable({ id }) {
  const [take, setTake] = React.useState(10);
  const [skip, setSkip] = React.useState(0);
  const { data, loading, error, queryId } = useGet(
    `payslips?contractorId=${id}&take=${take}&skip=${skip}`
  );
  const isLoaded = Boolean(data?.value);

  return (
    <div style={{ maxWidth: "100%", flexGrow: 1, marginBottom: 46 }}>
      <CustomPanelHeader>Payslips</CustomPanelHeader>
      <Table standardTable width={totalWidth}>
        <TableBody>
          <TableRow>
            <TableHeaderColumn width={c.dateCreated}>Date Created</TableHeaderColumn>
            <TableHeaderColumn width={c.taxWeek}>Week</TableHeaderColumn>
            <TableHeaderColumn width={c.taxCode}>Tax Code</TableHeaderColumn>
            <TableHeaderColumn width={c.hours}>Hours</TableHeaderColumn>
            <TableHeaderColumn width={c.days}>Days</TableHeaderColumn>
            <TableHeaderColumn width={c.gross}>Gross</TableHeaderColumn>
            <TableHeaderColumn width={c.netPay}>Net</TableHeaderColumn>
            <TableHeaderColumn width={c.totalPay}>Total</TableHeaderColumn>
            <TableHeaderColumn width={c.holidayAccrued}>Holiday Accrued</TableHeaderColumn>
            <TableHeaderColumn width={c.holidayClaimed}>Holiday Taken</TableHeaderColumn>
            <TableHeaderColumn width={c.payeTax}>PAYE</TableHeaderColumn>
            <TableHeaderColumn width={c.employeePens}>EE Pens</TableHeaderColumn>
            <TableHeaderColumn width={c.banked}>Banked</TableHeaderColumn>
            <TableHeaderColumn width={c.issues}>Issues</TableHeaderColumn>
            <TableHeaderColumn width={c.issuesIgnored}>Ignore issues</TableHeaderColumn>
            <TableHeaderColumn width={c.status}>Status</TableHeaderColumn>
          </TableRow>
          {isLoaded ? (
            <PureTable data={data} queryId={queryId} />
          ) : data?.errors ? (
            data?.errors.map((error) => <div>{error}</div>)
          ) : (
            <TableSkeleton />
          )}
        </TableBody>
      </Table>
      <FloatingFooter>
        {loading && <BoldText>Loading...</BoldText>}
        {error && <BoldText>A network error occured.</BoldText>}
        <Paginator
          skip={skip}
          take={take}
          onNext={() => {
            setSkip((skip) => skip + 10);
          }}
          onPrev={() => {
            setSkip((skip) => skip - 10);
          }}
          itemsTotal={data?.value?.itemsTotal}
          itemsRetrieved={data?.value?.itemsRetrieved}
          spacers
        />
      </FloatingFooter>
    </div>
  );
}
