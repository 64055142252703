import { Button } from "@backslashbuild/pp-ui";
import React from "react";
import { usePost } from "../contractors/api";

export default function InviteUserButton({ contractorId }) {
  const createUser = usePost(`${contractorId}/create-user`);

  function handleInvite() {
    createUser({ contractorId });
  }

  return (
    <Button large onClick={() => handleInvite()}>
      Invite
    </Button>
  );
}
