import React from "react";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { subscribe, publish, unsubscribe } from "./pubSub";
import { useQuery, queryCache } from "react-query";
import getApiUrl from "../../getApiUrl";

const apiHost = getApiUrl();
const service = "emails";

export function useGet(cacheKey, path, serviceOverride) {
  if (!path) {
    path = cacheKey;
  }
  if (!serviceOverride) {
    serviceOverride = service;
  }

  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };

  const { data, error, isLoading: loading, updatedAt: queryId } = useQuery(
    cacheKey,
    async (key, id) => {
      return fetch(`${apiHost}/${serviceOverride}/${path}`, {
        headers: {
          ...authHeaders,
        },
      }).then((r) => r.json());
    }
  );

  const runQuery = () => queryCache.invalidateQueries(path);

  React.useEffect(() => {
    runQuery();
  }, [path]);

  return { data, loading, error, queryId, refetch: runQuery };
}

export function usePost(path) {
  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };

  return React.useCallback(
    (body) => {
      return fetch(`${apiHost}/${service}/${path}`, {
        method: "POST",
        headers: {
          ...authHeaders,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }).then((x) => {
        return x.json();
      });
    },
    [path, authHeaders.Authorization]
  );
}

export function useGetFields({ id, formName }) {
  const query = useGet(`${id}/fields/${formName}`);

  React.useEffect(() => {
    subscribe(`${service}.fields.update`, query.refetch);
    return () => unsubscribe(`${service}.fields.update`, query.refetch);
  });

  return query;
}

export function useUpdateField({ id, formName, subFormName }) {
  const post = usePost(`${id}/fields/${formName}`);

  return React.useCallback(
    (body) => {
      return post(body).then((x) => {
        publish(`${service}.fields.update`, x);
        publish(`contractor.${subFormName}.updated`, x);
        return x;
      });
    },
    [post]
  );
}

export function useUpdateFields({ id, formName, subFormName }) {
  const post = usePost(`${id}/fields-multi/${formName}`);

  return React.useCallback(
    (body) => {
      return post(body).then((x) => {
        publish(`contractor.${subFormName}.updated`, x);
        return x;
      });
    },
    [post]
  );
}

export function useGetItems(path = "") {
  return useGet(`${path}`);
}

export function useSearchItems(path = "") {
  return useGet(`index/search${path}`);
}
