import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { utcToYmd, makeStateParamCorrectionForUrl } from "./utils";

import {
  Panel,
  PanelRow,
  PanelSpacer,
  Label,
  PanelHeading,
  DatePicker,
  Button,
  PanelActions,
  TextInput,
} from "@backslashbuild/pp-ui";

export default function DraftFpsEmployeeFilter() {
  const history = useHistory();
  const location = useLocation();
  const getStateFromSearch = (s) => queryString.parse(s);
  const [state, setState] = React.useState(getStateFromSearch(location.search));

  function handleSubmit() {
    history.replace({
      search: queryString.stringify(state),
    });
  }

  return (
    <Panel>
      <PanelHeading>Filters</PanelHeading>
      <PanelSpacer />
      <PanelRow>
        <Label label="Account Number"></Label>
        <TextInput
          value={state?.accountNumber}
          placeholder="Example: CPL123456"
          onChange={(e) =>
            setState({ ...state, accountNumber: makeStateParamCorrectionForUrl(e.target.value) })
          }
        />
      </PanelRow>
      <PanelRow>
        <Label label="Week Number"></Label>
        <TextInput
          value={state?.weekNo}
          placeholder="Values can be 1 to 53"
          onChange={(e) =>
            setState({ ...state, weekNo: makeStateParamCorrectionForUrl(e.target.value) })
          }
        />
      </PanelRow>
      <PanelRow>
        <Label label="Month Number"></Label>
        <TextInput
          value={state?.monthNo}
          placeholder="Values can be 1 to 12"
          onChange={(e) =>
            setState({ ...state, monthNo: makeStateParamCorrectionForUrl(e.target.value) })
          }
        />
      </PanelRow>
      <PanelRow>
        <Label label="Create Date From"></Label>
        <DatePicker
          value={state?.dateCreatedFrom ?? null}
          onChange={(value) => {
            setState({ ...state, dateCreatedMin: utcToYmd(value) });
          }}
        />
      </PanelRow>
      <PanelRow>
        <Label label="Create Date To" long></Label>
        <DatePicker
          value={state?.dateCreatedTo ?? null}
          onChange={(value) => {
            setState({ ...state, dateCreatedMax: utcToYmd(value) });
          }}
        />
      </PanelRow>
      <PanelSpacer />
      <PanelSpacer />
      <PanelSpacer />
      <PanelActions>
        <Button onClick={handleSubmit}>Apply filters</Button>
      </PanelActions>
      <PanelSpacer large />
    </Panel>
  );
}
