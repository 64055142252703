import React from "react";
import { ReactQueryDevtools } from "react-query-devtools";

export function QueryDevtoolsProvider({ children }) {
  return (
    <>
      {children}
      <ReactQueryDevtools />
    </>
  );
}

// export function useLoad(get) {
//   const { write } = React.useContext(CacheContext);
//   return React.useCallback(
//     async (id) => {
//       await get(id, write);
//     },
//     [write, get]
//   );
// }

// export function useRead(id, get) {
//   const cache = React.useContext(CacheContext);
//   const load = useLoad(get);
//   const item = cache.read(id);

//   React.useEffect(() => {
//     if (!item) {
//       load(id);
//     }
//   }, [id]);
//   return item;
// }
