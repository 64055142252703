import React from "react";
import { useHistory } from "react-router-dom";
import {
  Content,
  PanelColumnSpacer,
  PanelColumn,
  PageHeadingArea,
  PanelColumnSet,
  SidebarWrapper,
  BackArrow,
  PageHeadingAreaSpacer,
  PageTitle,
} from "@backslashbuild/pp-ui";

import FpsEmployeesFilter from "../modules/hmrc/FpsEmployeesFilter";
import FpsEmployeeTable from "../modules/hmrc/FpsEmployeeTable";

export default function FPSEmployeeData() {
  const history = useHistory();

  return (
    <SidebarWrapper>
      <Content scrollable>
        <PageHeadingArea>
          <BackArrow onClick={() => history.goBack()} />
          <PageHeadingAreaSpacer />
          <PageTitle>FPS Employee Data</PageTitle>
          <PageHeadingAreaSpacer grow />
        </PageHeadingArea>
        <PanelColumnSet>
          <PanelColumn width={320}>
            <FpsEmployeesFilter />
          </PanelColumn>
          <PanelColumnSpacer />
          <FpsEmployeeTable />
        </PanelColumnSet>
      </Content>
    </SidebarWrapper>
  );
}
