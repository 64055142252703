import {
  PageLoading,
  Panel,
  PanelHeading,
  PanelSpacer,
  PanelRow,
  Label,
  BoldText,
  Link,
  PanelDialog,
} from "@backslashbuild/pp-ui";
import React from "react";
import { useGet } from "./api";
import FormField from "./FormField";
import { isTextNullOrEmpty } from "./utils";
import ContractorBankDetailsForm from "./ContractorBankDetailsForm";
import { subscribe, unsubscribe } from "./pubSub";

export default function ContractorBankPanel({ id }) {
  const [editBankDetails, setEditBankDetails] = React.useState(null);
  const { data, loading, error, refetch } = useGet(id);

  React.useEffect(() => {
    subscribe("contractor.bankDetails.updated", refetch);
    return () => unsubscribe("contractor.bankDetails.updated", refetch);
  }, [id]);

  if (loading) return <PageLoading />;

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    return (
      <>
        <PanelRow>
          <Label label="Bank/Building Society">
            <BoldText>{data?.value?.bankName}</BoldText>
          </Label>
        </PanelRow>

        <PanelRow>
          <Label label="Bank Branch Town">
            <BoldText>{data?.value?.bankBranchTown}</BoldText>
          </Label>
        </PanelRow>

        <PanelRow>
          <Label label="Account Name">
            <BoldText>{data?.value?.bankAccountName}</BoldText>
          </Label>
        </PanelRow>

        <PanelRow>
          <Label label="Account Number">
            <BoldText>{data?.value?.bankAccountNumber}</BoldText>
          </Label>
        </PanelRow>

        <PanelRow>
          <Label label="Sort Code">
            <BoldText>{data?.value?.sortCode}</BoldText>
          </Label>
        </PanelRow>
        {data?.value?.bsRollNumber?.length > 0 && (
          <PanelRow>
            <Label label="BS Roll Number">
              <BoldText>{data?.value?.bsRollNumber}</BoldText>
            </Label>
          </PanelRow>
        )}
        {data?.value?.bicSwift?.length > 0 && (
          <PanelRow>
            <Label label="BIC/SWIFT">
              <BoldText>{data?.value?.bicSwift}</BoldText>
            </Label>
          </PanelRow>
        )}
        {data?.value?.iban?.length > 0 && (
          <PanelRow>
            <Label label="IBAN">
              <BoldText>{data?.value?.iban}</BoldText>
            </Label>
          </PanelRow>
        )}
      </>
    );
  }

  return (
    <>
      <Panel>
        <PanelHeading rightAction={<Link onClick={() => setEditBankDetails(id)}>Edit</Link>}>
          Bank details
        </PanelHeading>
        <PanelSpacer />
        {handleRender()}
        <PanelSpacer />
      </Panel>
      <PanelDialog open={editBankDetails}>
        <PanelHeading>Edit Bank Details</PanelHeading>
        {editBankDetails && (
          <ContractorBankDetailsForm
            id={editBankDetails}
            onClose={() => setEditBankDetails(null)}
          />
        )}
      </PanelDialog>
    </>
  );
}
