import { useFlag } from "react-unleash-flags";
import { useReactOidc } from "@axa-fr/react-oidc-context";

export function useIsFlagEnabled(s) {
  const { oidcUser } = useReactOidc();
  const f = useFlag(s);

  if (f?.enabled !== true) return false;

  for (let strategy of f.strategies) {
    switch (strategy.name) {
      case "default":
        return f.enabled;
      case "userWithId": {
        if (strategy.parameters.userIds.includes(oidcUser?.profile.email)) return true;
        break;
      }
    }
  }

  return false;
}

export function FeatureFlag({ name, children }) {
  const enabled = useIsFlagEnabled(name);

  if (enabled) return children;
  return null;
}
