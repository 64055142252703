import React from "react";

import RecalculateButton from "./RecalculateButton";
import RecalculateDialog from "./RecalculateDialog";

import { PageHeadingAreaSpacer } from "@backslashbuild/pp-ui";

export default function RecalculateActions({ bacsRun, refetch }) {
  const [showDialog, setShowDialog] = React.useState(false);

  return (
    <>
      {bacsRun && !bacsRun?.isClosed && !bacsRun?.isPaid && !bacsRun.isLocked && (
        <>
          <RecalculateButton onClick={() => setShowDialog(true)} />
          <PageHeadingAreaSpacer />
          <RecalculateDialog
            open={showDialog}
            bacsRunId={bacsRun?.id}
            onClose={() => {
              setShowDialog(false);
              setTimeout(() => {
                refetch();
              }, 1000);
            }}
          />
        </>
      )}
    </>
  );
}
