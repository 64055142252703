import React from "react";
import PayButton from "./PayButton";
import PayDialog from "./PayDialog";

import { PageHeadingAreaSpacer } from "@backslashbuild/pp-ui";

export default function PayBACSRunActions({ bacsRun, refetch }) {
  const [showDialog, setShowDialog] = React.useState(false);

  return (
    <>
      {bacsRun && !bacsRun?.isClosed && bacsRun?.isLocked && !bacsRun?.isPaid && (
        <>
          <PayButton onClick={() => setShowDialog(true)} />
          <PageHeadingAreaSpacer />
          <PayDialog
            open={showDialog}
            bacsRunId={bacsRun?.id}
            onClose={() => {
              setShowDialog(false);
              setTimeout(() => {
                refetch();
              }, 1000);
            }}
          />
        </>
      )}
    </>
  );
}
