import { FormTitle, PageLoading, Panel, PanelHeading, PanelSpacer } from "@backslashbuild/pp-ui";
import React from "react";
import { useGetFields, useUpdateField } from "./api";
import FormField from "./FormField";
import { isTextNullOrEmpty } from "./utils";

export default function AwrForm({ id }) {
  const { data, loading, error } = useGetFields({ id, formName: "awr" });
  const updateField = useUpdateField({ id, formName: "" });

  if (loading) return <PageLoading />;

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
    if (data != null && !data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

    return data.value.map((field) => (
      <FormField
        key={field.key}
        field={field}
        onSave={getOnSaveHandler(field.key)}
        largeLabel={field?.schema?.type === "radio"}
      />
    ));
  }

  function getOnSaveHandler(key) {
    return (value) => {
      updateField({ key, value });
    };
  }

  return (
    <Panel>
      <PanelHeading>AWR Questions</PanelHeading>
      <PanelSpacer />
      {handleRender()}
      <PanelSpacer />
    </Panel>
  );
}
