import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { utcToYmd, makeStateParamCorrectionForUrl } from "./utils";

import {
  Panel,
  PanelRow,
  PanelSpacer,
  Label,
  PanelHeading,
  DatePicker,
  Button,
  PanelActions,
  TextInput,
  Radio,
  RadioGroup,
} from "@backslashbuild/pp-ui";

export default function BACSRunsFilter() {
  const history = useHistory();
  const location = useLocation();
  const getStateFromSearch = (s) => queryString.parse(s);
  const [state, setState] = React.useState(getStateFromSearch(location.search));

  function handleSubmit() {
    history.replace({
      search: queryString.stringify(state),
    });
  }

  return (
    <Panel maxWidth={320}>
      <PanelHeading>Filters</PanelHeading>
      <PanelSpacer />
      <PanelRow>
        <Label label="Locked" />
        <RadioGroup
          value={state.isLocked}
          onChange={(e) => setState({ ...state, isLocked: e.target.value })}
        >
          <Radio value="" label="Any" />
          <Radio value="true" label="Yes" />
          <Radio value="false" label="No" />
        </RadioGroup>
      </PanelRow>
      <PanelRow>
        <Label label="Paid" />
        <RadioGroup
          value={state.isPaid}
          onChange={(e) => setState({ ...state, isPaid: e.target.value })}
        >
          <Radio value="" label="Any" />
          <Radio value="true" label="Yes" />
          <Radio value="false" label="No" />
        </RadioGroup>
      </PanelRow>
      <PanelRow>
        <Label label="Closed" />
        <RadioGroup
          value={state.isClosed}
          onChange={(e) => setState({ ...state, isClosed: e.target.value })}
        >
          <Radio value="" label="Any" />
          <Radio value="true" label="Yes" />
          <Radio value="false" label="No" />
        </RadioGroup>
      </PanelRow>
      <PanelRow>
        <Label label="Create Date From" />
        <DatePicker
          value={state?.dateCreatedFrom ?? null}
          onChange={(value) => {
            setState({ ...state, dateCreatedFrom: utcToYmd(value) });
          }}
        />
      </PanelRow>
      <PanelRow>
        <Label label="Create Date To"></Label>
        <DatePicker
          value={state?.dateCreatedTo ?? null}
          onChange={(value) => {
            setState({ ...state, dateCreatedTo: utcToYmd(value) });
          }}
        />
      </PanelRow>
      <PanelRow>
        <Label label="Payroll Date"></Label>
        <DatePicker
          value={state?.payrollDate ?? null}
          onChange={(value) => {
            setState({ ...state, payrollDate: utcToYmd(value) });
          }}
        />
      </PanelRow>
      <PanelRow>
        <Label label="Week Number"></Label>
        <TextInput
          value={state?.weekNo}
          placeholder="Value can be 2001 to 2053"
          onChange={(e) =>
            setState({ ...state, weekNo: makeStateParamCorrectionForUrl(e.target.value) })
          }
        />
      </PanelRow>
      <PanelSpacer />
      <PanelSpacer />
      <PanelSpacer />
      <PanelActions>
        <Button onClick={handleSubmit}>Apply filters</Button>
      </PanelActions>
      <PanelSpacer large />
    </Panel>
  );
}
