import React from "react";

import { Content } from "@backslashbuild/pp-ui";

import AdvancesToggleModeTable from "../modules/advances/AdvancesToggleModeTable";

function AdvancesToggleMode() {
  return (
    <Content>
      <AdvancesToggleModeTable />
    </Content>
  );
}

export default AdvancesToggleMode;
