import React from "react";
import {
  Button,
  PanelDialog,
  PanelHeading,
  PanelRow,
  PanelSpacer,
  PanelActions,
  BoldText,
  Table,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableCell,
  TableBody,
  TextInput,
} from "@backslashbuild/pp-ui";
import { useCreateRows, useGetSchedule } from "./api";
import { publish } from "../../pubSub";
import { useHistory } from "react-router-dom";
import ContractorProductPicker from "./ContractorProductSearchPicker";
import ContractorPicker from "./ContractorPicker";

const c = {
  contractor: 200,
  forename: 150,
  surname: 150,
  dateOfBirth: 160,
  days: 60,
  hours: 60,
  rate: 60,
  total: 60,
  additions: 60,
  deductions: 60,
  timesheetDate: 160,
  totalHours: 80,
  additionalPeriods: 80,
};
const totalWidth = Object.keys(c).reduce((a, k) => a + c[k], 0);

function createEmptyRow() {
  return {
    isManualEntry: true,
    contractorProductId: null,
    contractorForenames: null,
    contractorSurname: null,
    dateOfBirth: null,
    workplaceName: null,
    days: 0,
    hours: 0,
    rate: 0,
    additions: 0,
    deductions: 0,
    rowTypeId: "salary",
    timesheetDate: null,
    totalHours: 160,
    additionalPeriods: 0,
  };
}

function ManualRowEditor({ id }) {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const query = useGetSchedule(id);
  const agencyId = query.data?.value?.agencyId;
  const addRows = useCreateRows();
  const [rows, setRows] = React.useState([createEmptyRow()]);

  function patchRow(index, patch) {
    const clone = [...rows];
    clone[index] = { ...rows[index], ...patch };
    setRows(clone);
  }

  async function handleSubmit() {
    try {
      const response = await addRows({
        scheduleId: id,
        rows,
      });

      if (response.isSuccess) {
        publish("notification", "Rows added");
        setDialogOpen(false);
        setRows([createEmptyRow()]);
      } else {
        publish(
          "notification",
          <div>
            Unable to add rows
            <ul>
              {response.errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </div>
        );
      }
    } catch (e) {
      console.error(e, "error cancelling pay run");
      publish("notification", "A network error occured.");
    }
  }

  return (
    <>
      <Button variant="secondary" onClick={() => setDialogOpen(true)}>
        Add rows
      </Button>
      <PanelDialog open={dialogOpen} onClose={() => setDialogOpen(false)} width={totalWidth}>
        {/* <PanelHeading>Add manual rows</PanelHeading>
        <PanelSpacer large /> */}

        <Table width={totalWidth}>
          <TableHeader>
            <TableHeaderColumn width={c.contractor}>
              <small>Contractor product</small>
            </TableHeaderColumn>
            <TableHeaderColumn width={c.forename}>
              <small>
                Forename(s)
                <br />
                <small>(if not found)</small>
              </small>
            </TableHeaderColumn>
            <TableHeaderColumn width={c.surname}>
              <small>
                Surname
                <br />
                <small>(if not found)</small>
              </small>
            </TableHeaderColumn>
            <TableHeaderColumn width={c.dateOfBirth}>
              <small>
                DOB
                <br />
                <small>(if not found)</small>
              </small>
            </TableHeaderColumn>
            <TableHeaderColumn width={c.days}>
              <small>Days</small>
            </TableHeaderColumn>
            <TableHeaderColumn width={c.hours}>
              <small>Hours</small>
            </TableHeaderColumn>
            <TableHeaderColumn width={c.rate}>
              <small>Rate</small>
            </TableHeaderColumn>
            <TableHeaderColumn width={c.total}>
              <small>Total</small>
            </TableHeaderColumn>
            <TableHeaderColumn width={c.additions}>
              <small>Additions</small>
            </TableHeaderColumn>
            <TableHeaderColumn width={c.deductions}>
              <small>Deductions</small>
            </TableHeaderColumn>
            <TableHeaderColumn width={c.timesheetDate}>
              <small>T/S Date</small>
            </TableHeaderColumn>
            <TableHeaderColumn width={c.totalHours}>
              <small>NMW Hours</small>
            </TableHeaderColumn>
            <TableHeaderColumn width={c.additionalPeriods}>
              <small>Additional Periods</small>
            </TableHeaderColumn>
          </TableHeader>
          <TableBody>
            {rows?.map((row, i) => (
              <TableRow disableHover>
                <TableCell width={c.contractor}>
                  <ContractorProductPicker
                    value={row.matchedContractorProductId}
                    agencyId={agencyId}
                    onChange={(value) =>
                      patchRow(i, { matchedContractorProductId: value, contractorName: null })
                    }
                  />
                  {/* <ContractorPicker
                    value={row.contractorId}
                    agencyId={agencyId}
                    onChange={(value) => patchRow(i, { contractorId: value, contractorName: null })}
                  /> */}
                </TableCell>
                <TableCell width={c.forename}>
                  <TextInput
                    width="100%"
                    value={row.contractorForenames}
                    onChange={(event) =>
                      patchRow(i, { contractorId: null, contractorForenames: event.target.value })
                    }
                  />
                </TableCell>
                <TableCell width={c.surname}>
                  <TextInput
                    width="100%"
                    value={row.contractorSurname}
                    onChange={(event) =>
                      patchRow(i, { contractorId: null, contractorSurname: event.target.value })
                    }
                  />
                </TableCell>
                <TableCell width={c.dateOfBirth}>
                  <TextInput
                    width="100%"
                    type="date"
                    value={row.dateOfBirth}
                    onChange={(event) => patchRow(i, { dateOfBirth: event.target.value })}
                  />
                </TableCell>
                <TableCell width={c.days}>
                  <TextInput
                    width="50px"
                    type="number"
                    value={row.days}
                    disabled={row.hours > 0}
                    onChange={(event) => patchRow(i, { days: Number(event.target.value) })}
                  />
                </TableCell>
                <TableCell width={c.hours}>
                  <TextInput
                    width="50px"
                    type="number"
                    value={row.hours}
                    disabled={row.days > 0}
                    onChange={(event) => patchRow(i, { hours: Number(event.target.value) })}
                  />
                </TableCell>
                <TableCell width={c.rate}>
                  <TextInput
                    width="50px"
                    type="number"
                    value={row.rate}
                    onChange={(event) => patchRow(i, { rate: Number(event.target.value) })}
                  />
                </TableCell>
                <TableCell width={c.total}>
                  {`£${
                    row.hours > 0 ? row.hours * row.rate : row.days > 0 ? row.days * row.rate : "0"
                  }`}
                </TableCell>
                <TableCell width={c.additions}>
                  <TextInput
                    width="50px"
                    type="number"
                    value={row.additions}
                    onChange={(event) => patchRow(i, { additions: Number(event.target.value) })}
                  />
                </TableCell>
                <TableCell width={c.deductions}>
                  <TextInput
                    width="50px"
                    type="number"
                    value={row.deductions}
                    onChange={(event) => patchRow(i, { deductions: Number(event.target.value) })}
                  />
                </TableCell>
                <TableCell width={c.timesheetDate}>
                  <TextInput
                    width="100%"
                    type="date"
                    value={row.timesheetDate}
                    onChange={(event) => patchRow(i, { timesheetDate: event.target.value })}
                  />
                </TableCell>
                <TableCell width={c.totalHours}>
                  <TextInput
                    width="100%"
                    type="number"
                    value={row.totalHours}
                    onChange={(event) => patchRow(i, { totalHours: Number(event.target.value) })}
                  />
                </TableCell>
                <TableCell width={c.additionalPeriods}>
                  <TextInput
                    width="100%"
                    type="number"
                    value={row.additionalPeriods}
                    onChange={(event) =>
                      patchRow(i, { additionalPeriods: Number(event.target.value) })
                    }
                  />
                </TableCell>
              </TableRow>
            ))}

            <TableCell colSpan={10}>
              <Button small onClick={() => setRows([...rows, createEmptyRow()])}>
                Add row
              </Button>
            </TableCell>
          </TableBody>
        </Table>

        <PanelSpacer large />
        <PanelActions>
          <Button variant="secondary" onClick={() => setDialogOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleSubmit}>Save rows</Button>
        </PanelActions>
        <PanelSpacer large />
      </PanelDialog>
    </>
  );
}

export default ManualRowEditor;
