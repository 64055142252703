import React from "react";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { publish } from "./pubSub";
import getApiUrl from "../../getApiUrl";
import { useQuery, queryCache } from "react-query";

const apiHost = getApiUrl();
const service = "bacs";

export function useGet(cacheKey, path, serviceOverride) {
  if (!path) {
    path = cacheKey;
  }
  if (!serviceOverride) {
    serviceOverride = service;
  }

  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };

  const {
    data,
    error,
    isLoading: loading,
    updatedAt: queryId,
  } = useQuery(cacheKey, async (key, id) => {
    return fetch(`${apiHost}/${serviceOverride}/${path}`, {
      headers: {
        ...authHeaders,
      },
    }).then((r) => r.json());
  });

  const runQuery = () => queryCache.invalidateQueries(cacheKey);

  return { data, loading, error, queryId, refetch: runQuery };
}

export function useGetBlob(path, filename) {
  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };

  return React.useCallback(() => {
    return fetch(`${apiHost}/${service}/${path}`, {
      method: "GET",
      headers: {
        ...authHeaders,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
  }, [path, filename]);
}

export function useDownloadCSV(id) {
  return useGetBlob(`bacs-rows/export/${id}`, `bacs-rows-${id}.csv`);
}

export function usePost(path) {
  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };

  return React.useCallback(
    (body) => {
      return fetch(`${apiHost}/${service}/${path}`, {
        method: "POST",
        headers: {
          ...authHeaders,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }).then((x) => {
        return x.json();
      });
    },
    [path, authHeaders.Authorization]
  );
}

export function useGetBACSRuns(path) {
  return useGet([`bacs-runs`, path], `bacs-runs${path}`);
}

export function useGetBACSRunById(id) {
  return useGet([`bacs-run`, id], `bacs-runs/${id}`);
}

export function useGetBACSRows(path) {
  return useGet([`bacs-rows`, path], `bacs-rows${path}`);
}

export function useGetPayslips(path) {
  return useGet([`payslips`, path], `payslips${path}`);
}

export function useCreateBACSRun() {
  const post = usePost(`bacs-runs/create`);

  return React.useCallback(
    (body) => {
      return post(body).then((x) => {
        publish(`${service}.bacsRuns.create`, x);
        return x;
      });
    },
    [post]
  );
}

export function useCloseBACSRun() {
  const post = usePost(`bacs-runs/close`);

  return React.useCallback(
    (body) => {
      return post(body).then((x) => {
        publish(`${service}.bacsRuns.close`, x);
        return x;
      });
    },
    [post]
  );
}

export function useLockBACSRun() {
  const post = usePost(`bacs-runs/lock`);

  return React.useCallback(
    (body) => {
      return post(body).then((x) => {
        publish(`${service}.bacsRuns.lock`, x);
        return x;
      });
    },
    [post]
  );
}

export function useUnlockBACSRun() {
  const post = usePost(`bacs-runs/unlock`);

  return React.useCallback(
    (body) => {
      return post(body).then((x) => {
        publish(`${service}.bacsRuns.unlock`, x);
        return x;
      });
    },
    [post]
  );
}

export function usePayBACSRun() {
  const post = usePost(`bacs-runs/pay`);

  return React.useCallback(
    (body) => {
      return post(body).then((x) => {
        publish(`${service}.bacsRuns.pay`, x);
        return x;
      });
    },
    [post]
  );
}

export function useLockPayslips() {
  const post = usePost(`payslips/lock`);

  return React.useCallback(
    (body) => {
      return post(body).then((x) => {
        publish(`${service}.payslips.lock`, x);
        return x;
      });
    },
    [post]
  );
}

export function useUnlockPayslips() {
  const post = usePost(`payslips/unlock`);

  return React.useCallback(
    (body) => {
      return post(body).then((x) => {
        publish(`${service}.payslips.unlock`, x);
        return x;
      });
    },
    [post]
  );
}

export function usePayPayslips() {
  const post = usePost(`payslips/pay`);

  return React.useCallback(
    (body) => {
      return post(body).then((x) => {
        publish(`${service}.payslips.pay`, x);
        return x;
      });
    },
    [post]
  );
}

export function useRemoveBACSRows() {
  const post = usePost(`bacs-rows/remove`);

  return React.useCallback(
    (body) => {
      return post(body).then((x) => {
        publish(`${service}.bacsRows.remove`, x);
        return x;
      });
    },
    [post]
  );
}

export function useRecalculateBACSRows() {
  const post = usePost(`bacs-rows/calculate`);

  return React.useCallback(
    (body) => {
      return post(body).then((x) => {
        publish(`${service}.bacsRows.add`, x);
        return x;
      });
    },
    [post]
  );
}

export function invalidateBACSRuns() {
  queryCache.invalidateQueries((query) => {
    const [cacheType] = query.queryKey;
    return cacheType === "bacs-runs";
  });
}

export function invalidateBACSRun() {
  queryCache.invalidateQueries((query) => {
    const [cacheType] = query.queryKey;
    return cacheType === `bacs-run`;
  });
}

export function invalidateBACSRows() {
  queryCache.invalidateQueries((query) => {
    const [cacheType] = query.queryKey;
    return cacheType === "bacs-rows";
  });
}

export function invalidatePayslips() {
  queryCache.invalidateQueries((query) => {
    const [cacheType] = query.queryKey;
    return cacheType === "payslips";
  });
}
