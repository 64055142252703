import React from "react";
import {
  TableRow,
  Table,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableBody,
} from "@backslashbuild/pp-ui";
import { useGet } from "./api";
import { utcToLocalText } from "../../utils";

const c = {
  yearNumber: 50,
  name: 70,
  shortName: 80,
  startDate: 120,
  endDate: 120,
  dateCreated: 120,
};

const totalWidth = Object.keys(c).reduce((total, key) => (total += c[key]), 0);

function PureTable({ data, queryId }) {
  return React.useMemo(() =>
    data.value.items.map(
      (item) => (
        <TableRow key={item.id}>
          <TableCell width={c.yearNumber}>{item.yearNumber}</TableCell>
          <TableCell width={c.name}>{item.name}</TableCell>
          <TableCell width={c.shortName}>{item.shortName}</TableCell>
          <TableCell width={c.startDate}>{utcToLocalText(item.startDate)}</TableCell>
          <TableCell width={c.endDate}>{utcToLocalText(item.endDate)}</TableCell>
          <TableCell width={c.dateCreated}>{utcToLocalText(item.dateCreated)}</TableCell>
        </TableRow>
      ),
      [queryId]
    )
  );
}

function TableSkeleton() {
  return (
    <TableRow>
      <TableCell width={c.name}>-</TableCell>
    </TableRow>
  );
}

function TaxYearsTable() {
  const { data, loading, error, queryId } = useGet("tax-years");

  const isLoaded = Boolean(data?.value);

  return (
    <Table width={totalWidth} standardTable>
      <TableHeader>
        <TableHeaderColumn width={c.yearNumber}>No.</TableHeaderColumn>
        <TableHeaderColumn width={c.name}>Name</TableHeaderColumn>
        <TableHeaderColumn width={c.shortName}>Short Name</TableHeaderColumn>
        <TableHeaderColumn width={c.startDate}>Start Date</TableHeaderColumn>
        <TableHeaderColumn width={c.endDate}>End Date</TableHeaderColumn>
        <TableHeaderColumn width={c.dateCreated}>Date Created</TableHeaderColumn>
      </TableHeader>
      <TableBody>
        {isLoaded ? (
          <PureTable data={data} queryId={queryId} />
        ) : data?.errors ? (
          data?.errors.map((error) => <div>{error}</div>)
        ) : (
          <TableSkeleton />
        )}
      </TableBody>
    </Table>
  );
}

export default TaxYearsTable;
