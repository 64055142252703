import React from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

import {
  Content,
  PanelHeading,
  PageHeadingArea,
  PanelDialog,
  PageHeadingAreaSpacer,
  Button,
  BackArrow,
} from "@backslashbuild/pp-ui";

import AnytimeWagesForm from "../modules/aws/AnytimeWagesForm";
import ContractorPageTitle from "../modules/contractors/ContractorPageTitle";
import AnytimeWagePromotionalCodeTable from "../modules/aws/AnytimeWagePromotionalCodeTable";

function ContractorAnytimeWages() {
  const { id } = useParams();
  const history = useHistory();

  return (
    <>
      <Content scrollable>
        <PageHeadingArea>
          <BackArrow onClick={() => history.goBack()} />
          <ContractorPageTitle id={id} title={"Anytime Wages"} />
        </PageHeadingArea>
        <div>
          Approved advances will appear in your bank account within an hour, 24 hours a day. Some
          banks may take longer to process the payment
        </div>
        <AnytimeWagesForm id={id} />
        <AnytimeWagePromotionalCodeTable contractorId={id} />
      </Content>
    </>
  );
}

export default ContractorAnytimeWages;
