import React from "react";
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableFooter,
  Link,
  PageHeadingArea,
  PageTitle,
  Pill,
  Icons,
  Rollover,
} from "@backslashbuild/pp-ui";
import { utcToLocalText, isTextNullOrEmpty } from "./utils";
import { useSearchItems } from "./api";
import { useLocation, useHistory } from "react-router-dom";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";
import queryString from "query-string";
import ContractorBusinessCard from "./ContractorBusinessCard";

const c = {
  products: 150,
  icon: 30,
  firstName: 180,
  surname: 180,
  dateOfBirth: 100,
  email: 250,
  nationality: 100,
  peopleId: 100,
  dateCreated: 100,
};
const totalWidth = Object.keys(c).reduce((total, key) => (total += c[key]), 0);

function PureTable({ data, queryId }) {
  let Icon = Icons.AccountBox;
  const history = useHistory();

  return React.useMemo(() => {
    if (data.value.items?.length < 1)
      return (
        <TableRow>
          <TableCell>
            <div>No contractors matching this query</div>
          </TableCell>
        </TableRow>
      );
    else if (data.value.items?.length == 1) {
      history.push(`/contractor/${data?.value?.items[0]?.userId}`);
      return <></>;
    } else {
      return data.value.items.map(
        (contractor) => (
          <>
            <TableRow
              onClick={() => {
                history.push(`/contractor/${contractor.userId}`);
              }}
            >
              <TableCell width={c.products}>
                {contractor?.contractorProducts?.map((product) => (
                  <Pill
                    status={
                      product?.leaveDate
                        ? "error"
                        : product?.leaveRequestedDate
                        ? "warning"
                        : product?.liveDate
                        ? "info"
                        : "success"
                    }
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ paddingRight: "10px" }}>{product?.accountNumber}</div>
                    </div>
                  </Pill>
                ))}
              </TableCell>
              <TableCell width={c.icon}>
                <Rollover renderAnchor={() => <Icon />}>
                  <ContractorBusinessCard id={contractor.userId} />
                </Rollover>
              </TableCell>
              <TableCell width={c.firstName}>{contractor.firstName}</TableCell>
              <TableCell width={c.surname}>{contractor.surname}</TableCell>
              <TableCell width={c.dateOfBirth}>{utcToLocalText(contractor.dateOfBirth)}</TableCell>
              <TableCell width={c.email}>
                <Link href={`mailto:${contractor?.email}`}>{contractor?.email}</Link>
              </TableCell>
              <TableCell width={c.nationality}>{contractor.nationality}</TableCell>
              <TableCell width={c.dateCreated}>{utcToLocalText(contractor.dateCreated)}</TableCell>
            </TableRow>
          </>
        ),
        [queryId]
      );
    }
  });
}

function TableSkeleton() {
  return (
    <TableRow>
      <TableCell colSpan={7} width="100%">
        Loading
      </TableCell>
    </TableRow>
  );
}

function ContractorsTable() {
  const location = useLocation();
  const search = usePagination(location.search);
  const parsedQueryString = queryString.parse(search);
  const { data, error, queryId } = useSearchItems(
    `?${queryString.stringify({ ...parsedQueryString, isActive: true })}`,
    false
  );

  const isLoaded = Boolean(data?.value);
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (data && !data.isSuccess && data?.errors) {
    return (
      <>
        {data?.errors?.map((error) => (
          <div>{error}</div>
        ))}
      </>
    );
  }

  return (
    <>
      <PageHeadingArea>
        <PageTitle>Contractors</PageTitle>
      </PageHeadingArea>
      <Table standardTable width={totalWidth}>
        <TableHeader>
          <TableHeaderColumn width={c.products}>Products</TableHeaderColumn>
          <TableHeaderColumn width={c.icon} />
          <TableHeaderColumn width={c.firstName}>First Name</TableHeaderColumn>
          <TableHeaderColumn width={c.surname}>Surname</TableHeaderColumn>
          <TableHeaderColumn width={c.dateOfBirth}>Date of Birth</TableHeaderColumn>
          <TableHeaderColumn width={c.email}>Email</TableHeaderColumn>
          <TableHeaderColumn width={c.nationality}>Nationality</TableHeaderColumn>
          <TableHeaderColumn width={c.dateCreated}>Signed Up</TableHeaderColumn>
        </TableHeader>
        <TableBody fullPageTable={true} footerHeight={TableFooter.height}>
          {isLoaded ? <PureTable data={data} queryId={queryId} /> : <TableSkeleton />}
        </TableBody>
        <TableFooter>
          <QueryPaginator
            itemsTotal={data?.value?.itemsTotal}
            itemsRetrieved={data?.value?.itemsRetrieved}
          />
        </TableFooter>
      </Table>
    </>
  );
}

export default ContractorsTable;
