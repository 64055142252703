import React from "react";
import { isTextNullOrEmpty, utcToLocalText } from "./utils";
import QueryPaginator from "../../containers/QueryPaginator";
import { useGet, useGetEnrolmentExports } from "./api";
import {
  TableRow,
  Table,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  PageLoading,
  PanelColumn,
  Button,
} from "@backslashbuild/pp-ui";
import FloatingFooter from "../../components/FloatingFooter";
import UploadUnsuccessfulEnrolmentsDialog from "./UploadUnsuccessfulEnrolmentsDialog";
import UploadEnrolmentsErrorLogDialog from "./UploadEnrolmentsErrorLogDialog";
import UnsuccessfulEnrolmentsDialog from "./UnsuccessfulEnrolmentsDialog";
import SuccessfulEnrolmentsDialog from "./SuccessfulEnrolmentsDialog";

const c = {
  dateCreated: 150,
  nestEmployerReferenceNumber: 150,
  actions: 500,
};

const totalWidth = Object.keys(c).reduce((total, key) => (total += c[key]), 0);

function PureTable({ data, queryId }) {
  return React.useMemo(() =>
    data?.value?.items?.map(
      (enrolmentExport) => (
        <TableRow disableHover key={enrolmentExport.id}>
          <TableCell width={c.dateCreated}>
            {utcToLocalText(enrolmentExport?.dateCreated, "dd/MM/yyyy HH:mm")}
          </TableCell>
          <TableCell width={c.nestEmployerReferenceNumber}>
            {enrolmentExport?.nestEmployerReferenceNumber}
          </TableCell>
          <TableCell width={c.actions}>
            <Button
              small
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_API_HOST}/nest/enrolments/${enrolmentExport.id}/download-csv`,
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
              style={{ marginRight: 10 }}
              variant="secondary"
            >
              Download CSV
            </Button>
            {enrolmentExport.errorsIdentified ? (
              <>
                <UnsuccessfulEnrolmentsDialog
                  enrolmentExport={enrolmentExport}
                  disabled={enrolmentExport?.errorCount < 1}
                />
                <SuccessfulEnrolmentsDialog enrolmentExport={enrolmentExport} />
              </>
            ) : enrolmentExport.unsuccessfulEnrolmentsIdentified ? (
              <UploadEnrolmentsErrorLogDialog
                enrolmentExport={enrolmentExport}
                disabled={enrolmentExport.errorsIdentified}
              />
            ) : (
              <UploadUnsuccessfulEnrolmentsDialog
                enrolmentExport={enrolmentExport}
                disabled={enrolmentExport.unsuccessfulEnrolmentsIdentified}
              />
            )}
          </TableCell>
        </TableRow>
      ),
      [queryId]
    )
  );
}

export default function EnrolmentExportsTable() {
  const { data, loading, error, queryId } = useGetEnrolmentExports();

  if (loading) return <PageLoading />;
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  return (
    <>
      <PanelColumn width={1150}>
        {/* Temporary fix for Safari not rendering ui components correctly */}
        <div style={{ width: "100%", height: "77vh" }}>
          <Table standardTable width={totalWidth}>
            <TableHeader>
              <TableHeaderColumn width={c.dateCreated}>Date Created</TableHeaderColumn>
              <TableHeaderColumn width={c.nestEmployerReferenceNumber}>
                Employer Ref
              </TableHeaderColumn>
              <TableHeaderColumn width={c.actions}>Actions</TableHeaderColumn>
            </TableHeader>
            <TableBody fullPageTable={true}>
              <PureTable data={data} queryId={queryId} />
            </TableBody>
          </Table>
        </div>
        <FloatingFooter>
          <QueryPaginator
            itemsTotal={data?.value?.itemsTotal}
            itemsRetrieved={data?.value?.itemsRetrieved}
            spacers
          />
        </FloatingFooter>
      </PanelColumn>
    </>
  );
}
