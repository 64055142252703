import React from "react";
import {
  PageHeadingArea,
  PageHeadingAreaSpacer,
  PageTitle,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableFooter,
  Button,
  Icons,
  Rollover,
} from "@backslashbuild/pp-ui";
import { formatCurrency } from "../../utils";
import { useGetItems } from "./api";
import { useLocation } from "react-router-dom";
import PayslipFiltering from "./PayslipFiltering";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";
import queryString from "query-string";
import QueryFilter from "../../containers/QueryFilter";
import { FeatureFlag } from "react-unleash-flags";

function PureTable({ data, queryId }) {
  return React.useMemo(
    () =>
      data.value.items.map((payslip) => {
        return (
          <>
            <TableRow
              key={payslip?.payslipNumber}
              onClick={() => {
                window.open(
                  `/payslip-redirect/${payslip?.payslipNumber}?remittanceAdvice=${
                    payslip.payslipCategory == "CIS"
                  }`,
                  "_blank"
                );
              }}
            >
              <TableCell>
                <QueryFilter query="employmentNumber" value={payslip?.employmentNumber} />
              </TableCell>
              <TableCell>{payslip.accountNumber}</TableCell>
              <TableCell>
                <QueryFilter query="weekNumber" value={payslip?.weekNumber} />
              </TableCell>
              <TableCell>{payslip.payslipNumber}</TableCell>
              <FeatureFlag name="payslips-debug-payslip">
                <TableCell>
                  <Button
                    icon
                    small
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(`/debug-payslip/${payslip?.payslipNumber}`, "_blank");
                    }}
                    style={{
                      margin: "5px 5px 5px 0px",
                      padding: 4,
                      backgroundColor: payslip?.legacyBanked == payslip.banked ? null : "red",
                    }}
                  >
                    <Icons.InfoIcon />
                  </Button>
                </TableCell>
              </FeatureFlag>
              <TableCell>
                {payslip.firstName} {payslip.surname}
              </TableCell>
              <TableCell>{payslip.taxCode}</TableCell>
              <TableCell alignRight>{formatCurrency(payslip.grossTaxablePay)}</TableCell>
              <TableCell>{payslip.totalDays}</TableCell>
              <TableCell alignRight>{formatCurrency(payslip.holidayAccrued)}</TableCell>
              <TableCell alignRight>{formatCurrency(payslip.holidayTaken)}</TableCell>
              <TableCell alignRight>{formatCurrency(payslip.statPay)}</TableCell>
              <TableCell alignRight>{formatCurrency(payslip.payeTax)}</TableCell>
              <TableCell>{payslip.niNumber}</TableCell>
              <TableCell alignRight>{formatCurrency(payslip.erPens)}</TableCell>
              <TableCell alignRight>{formatCurrency(payslip.eePens)}</TableCell>
              <TableCell alignRight>{formatCurrency(payslip.studentLoan)}</TableCell>
              <TableCell alignRight>{formatCurrency(payslip.emergencyPayments)}</TableCell>
              <TableCell alignRight>{formatCurrency(payslip.awsRecovery)}</TableCell>
              <TableCell alignRight>{formatCurrency(payslip.banked)}</TableCell>
              <TableCell alignRight>
                <Rollover renderAnchor={() => <div>{payslip.adjustments?.length}</div>}>
                  <div>
                    {payslip.adjustments?.map((adj) => (
                      <div>
                        {adj["description"]}: {formatCurrency(adj["value"])}
                      </div>
                    ))}
                  </div>
                </Rollover>
              </TableCell>
              <TableCell>
                {payslip.payslipCategory == "CIS" ? (
                  <Button
                    small
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(
                        `https://portal.peoplegroupservices.com/payslips-docs/payslip-recon.asp?payslipnum=${payslip?.payslipNumber}`,
                        "_blank"
                      );
                    }}
                    style={{ margin: "5px 5px 5px 0px" }}
                  >
                    Remittance Advice
                  </Button>
                ) : (
                  <>
                    <Button
                      small
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(
                          `https://portal.peoplegroupservices.com/payslips-docs/payslip-recon.asp?payslipnum=${payslip?.payslipNumber}&full=y`,
                          "_blank"
                        );
                      }}
                      style={{ margin: "5px 5px 5px 0px" }}
                    >
                      Legacy Payslip
                    </Button>
                    <Button
                      small
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(
                          `https://portal.peoplegroupservices.com/payslips-docs/payslip-recon.asp?payslipnum=${payslip?.payslipNumber}`,
                          "_blank"
                        );
                      }}
                      style={{ margin: "5px 5px 5px 0px" }}
                    >
                      Legacy AR
                    </Button>
                  </>
                )}
                <Button
                  small
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(
                      `/payslip-redirect/${payslip?.payslipNumber}?remittanceAdvice=${
                        payslip.payslipCategory == "CIS"
                      }&isScraped=true`,
                      "_blank"
                    );
                  }}
                  style={{ margin: "5px 5px 5px 0px" }}
                >
                  Scraped Payslip
                </Button>
              </TableCell>
            </TableRow>
          </>
        );
      }),
    [queryId]
  );
}

function TableSkeleton() {
  return (
    <TableRow disableHover={true}>
      <TableCell>-</TableCell>
      <TableCell>-</TableCell>
      <TableCell>-</TableCell>
      <TableCell>-</TableCell>
      <FeatureFlag name="payslips-debug-payslip">
        <TableCell></TableCell>
      </FeatureFlag>
      <TableCell>-</TableCell>
      <TableCell>-</TableCell>
      <TableCell alignRight>-</TableCell>
      <TableCell>-</TableCell>
      <TableCell alignRight>-</TableCell>
      <TableCell alignRight>-</TableCell>
      <TableCell>-</TableCell>
      <TableCell alignRight>-</TableCell>
      <TableCell>-</TableCell>
      <TableCell alignRight>-</TableCell>
      <TableCell alignRight>-</TableCell>
      <TableCell alignRight>-</TableCell>
      <TableCell alignRight>-</TableCell>
      <TableCell alignRight>-</TableCell>
      <TableCell alignRight>-</TableCell>
      <TableCell alignRight>-</TableCell>
      <TableCell>-</TableCell>
    </TableRow>
  );
}

export default function PayslipsTable() {
  const location = useLocation();
  const search = usePagination(location.search, 25);
  const parsedQueryString = queryString.parse(search);
  const { data, queryId } = useGetItems(`?${queryString.stringify({ ...parsedQueryString })}`);

  const isLoaded = Boolean(data?.value);

  return (
    <>
      <PageHeadingArea>
        <PageTitle>Payslips</PageTitle>
        <PageHeadingAreaSpacer grow />
        <PayslipFiltering />
      </PageHeadingArea>
      <Table
        sticky
        stickyFooter={
          <TableFooter sticky>
            <QueryPaginator
              defaultTake={25}
              itemsTotal={data?.value?.itemsTotal}
              itemsRetrieved={data?.value?.itemsRetrieved}
            />
          </TableFooter>
        }
        footerHeight={TableFooter.height}
      >
        <TableHeader sticky>
          <TableHeaderColumn sticky>Employment No.</TableHeaderColumn>
          <TableHeaderColumn sticky>Product.</TableHeaderColumn>
          <TableHeaderColumn sticky>Week No.</TableHeaderColumn>
          <TableHeaderColumn sticky>Payslip</TableHeaderColumn>
          <FeatureFlag name="payslips-debug-payslip">
            <TableHeaderColumn sticky></TableHeaderColumn>
          </FeatureFlag>
          <TableHeaderColumn sticky>Name</TableHeaderColumn>
          <TableHeaderColumn sticky>Tax Code</TableHeaderColumn>
          <TableHeaderColumn alignRight sticky>
            Gross
          </TableHeaderColumn>
          <TableHeaderColumn sticky>Days</TableHeaderColumn>
          <TableHeaderColumn alignRight sticky>
            Holiday accrued
          </TableHeaderColumn>
          <TableHeaderColumn alignRight sticky>
            Holiday taken
          </TableHeaderColumn>
          <TableHeaderColumn alignRight sticky>
            Stat Pay
          </TableHeaderColumn>
          <TableHeaderColumn alignRight sticky>
            PAYE Tax
          </TableHeaderColumn>
          <TableHeaderColumn sticky>NI Number</TableHeaderColumn>
          <TableHeaderColumn alignRight sticky>
            Employer Pension
          </TableHeaderColumn>
          <TableHeaderColumn alignRight sticky>
            Employee Pension
          </TableHeaderColumn>
          <TableHeaderColumn alignRight sticky>
            Student Loan
          </TableHeaderColumn>
          <TableHeaderColumn alignRight sticky>
            Emergency Payments
          </TableHeaderColumn>
          <TableHeaderColumn alignRight sticky>
            Advances
          </TableHeaderColumn>
          <TableHeaderColumn alignRight sticky>
            Banked
          </TableHeaderColumn>
          <TableHeaderColumn alignRight sticky>
            Adjustments
          </TableHeaderColumn>
          <TableHeaderColumn sticky>Actions</TableHeaderColumn>
        </TableHeader>
        <TableBody sticky fullPageTable={true} footerHeight={TableFooter.height}>
          {isLoaded ? (
            <PureTable data={data} queryId={queryId} />
          ) : data?.errors ? (
            data?.errors.map((error) => <div>{error}</div>)
          ) : (
            <TableSkeleton />
          )}
        </TableBody>
      </Table>
    </>
  );
}
