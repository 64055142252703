import React, { useState } from "react";

import {
  FormTitle,
  PageLoading,
  Panel,
  PanelActions,
  Button,
  PanelHeading,
} from "@backslashbuild/pp-ui";
import { useSendContractorSMS } from "./api";
import FormField from "./FormField";

export default function SendSMSForm({ id, onClose }) {
  const [messageValue, setMessageValue] = useState("");
  const sendSMS = useSendContractorSMS({ id });

  function handleSend() {
    sendSMS({ message: messageValue });
  }

  function updateMessage(key) {
    return (value) => {
      setMessageValue(value);
    };
  }

  return (
    <Panel>
      <PanelHeading>Send contractor SMS</PanelHeading>
      <FormField
        key={"message"}
        field={{
          displayName: "Message",
          schema: {
            type: "textbox",
          },
        }}
        onSave={updateMessage("message")}
      />
      <PanelActions>
        <Button variant="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleSend();
            onClose();
          }}
        >
          Send
        </Button>
      </PanelActions>
    </Panel>
  );
}
