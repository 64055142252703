import { Link } from "@backslashbuild/pp-ui";
import React from "react";
import { useGetAWSBalance } from "./api";
import { isTextNullOrEmpty } from "./utils";
import { Link as RouterLink } from "react-router-dom";

import { formatCurrency } from "../../utils";

export default function ContractorAWSBalance({ id }) {
  const { data, loading, error } = useGetAWSBalance({ id });

  if (loading) return <div>Loading</div>;

  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

  if (data != null && !data.isSuccess) {
    return data.errors.map((error) => <div>{error}</div>);
  }

  return (
    <>
      <Link
        component={RouterLink}
        to={`/contractor-anytime-wages/${id}`}
      >{`Advances Outstanding: ${formatCurrency(
        data?.value?.totalOutstandingAnytimeWageRequests
      )}`}</Link>
      <br />
      <Link
        component={RouterLink}
        to={`/contractor-anytime-wages/${id}`}
      >{`Emergency Pay: ${formatCurrency(data?.value?.totalEmergencyPayments)}`}</Link>
    </>
  );
}
