import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@backslashbuild/pp-ui";
import {
  Content,
  PanelColumn,
  PanelColumnSpacer,
  SidebarWrapper,
  Sidebar,
  CommentsIconButton,
  PanelSpacer,
  PanelDialog,
} from "@backslashbuild/pp-ui";

import ContractorStudentLoansPanel from "../modules/contractors/ContractorStudentLoansPanel";
import ContractorContactDetailsPanel from "../modules/contractors/ContractorContactDetailsPanel";
import ContractorBankPanel from "../modules/contractors/ContractorBankPanel";
import ContractorVisaPanel from "../modules/contractors/ContractorVisaPanel";
import ContractorLoginDetailsPanel from "../modules/contractors/ContractorLoginDetailsPanel";
import CommsPanelApp from "../modules/internal-comms/CommsPanelApp/CommsPanelApp";
import ContractorAgenciesTable from "../modules/contractors/ContractorAgenciesTable";
import ContractorAWSTable from "../modules/contractors/ContractorAWSTable";
import ContractorTimesheetsTable from "../modules/contractors/ContractorTimesheetsTable";
import ContractorContractsPanel from "../modules/contractors/ContractorContractsPanel";
import ContractorDocumentsPanel from "../modules/contractors/ContractorDocumentsPanel";
import AddEmergencyPaymentForm from "../modules/contractors/AddEmergencyPaymentForm";

import ContractorProductsTable from "../modules/contractors/ContractorProductsTable";
import ContractorBusinessCard from "../modules/contractors/ContractorBusinessCard";
import ContractorQuickLinks from "../modules/contractors/ContractorQuickLinks";

import PayslipsTable from "../modules/payroll/PayslipsTable";

import { useCheckUserPermission } from "../permissions";
import ContractorPayslipsTable from "../modules/payroll/ContractorPayslipsTable";

function Contractor() {
  const { id } = useParams();
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const [showAddEmergencyPayment, setShowAddEmergencyPayment] = useState(false);

  const hasPermissionToViewContractors = useCheckUserPermission("General.CanViewContractor");

  // if (!hasPermissionToViewContractors) {
  //   alert("You do not have permission to view this contractor");
  // }

  return (
    <SidebarWrapper
      panel={<CommsPanelApp open={sidebarOpen} itemId={id} configuration={"isContractor"} />}
      sidebar={
        <Sidebar>
          <CommentsIconButton onClick={() => setSidebarOpen(!sidebarOpen)} />
        </Sidebar>
      }
    >
      <Content scrollable>
        <div style={{ width: "100%", maxHeight: "275px", display: "flex" }}>
          <ContractorBusinessCard id={id} shrink={sidebarOpen} showEdit={true} />
        </div>
        <div style={{ maxHeight: "900px", display: "flex", flexWrap: "wrap" }}>
          <PanelColumn>
            <ContractorQuickLinks id={id} />
            <PanelSpacer large />
            <ContractorStudentLoansPanel id={id} />
            <PanelSpacer large />
            <ContractorContactDetailsPanel id={id} />
            <PanelSpacer large />
            <ContractorLoginDetailsPanel id={id} />
            <PanelSpacer large />
            <ContractorContractsPanel contractorId={id} />
            <PanelSpacer large />
            <ContractorDocumentsPanel contractorId={id} />
            <ContractorBankPanel id={id} />
            <PanelSpacer large />
            <ContractorVisaPanel id={id} />
          </PanelColumn>
          <PanelColumnSpacer />
          <PanelColumn grow>
            <ContractorProductsTable contractorId={id} />
            <PanelSpacer large />
            <ContractorAgenciesTable contractorId={id} />
            <PanelSpacer large />
            <ContractorPayslipsTable id={id} />
            <PanelSpacer large />
            <ContractorTimesheetsTable contractorId={id} />
            <ContractorAWSTable id={id} />
          </PanelColumn>
        </div>

        <PanelDialog open={showAddEmergencyPayment}>
          <AddEmergencyPaymentForm
            contractorId={id}
            onClose={() => setShowAddEmergencyPayment(false)}
          />
        </PanelDialog>
      </Content>
    </SidebarWrapper>
  );
}

export default Contractor;
