import {
  PageLoading,
  Panel,
  PanelHeading,
  PanelSpacer,
  PanelRow,
  Label,
  BoldText,
  Link,
  DateFormatted,
} from "@backslashbuild/pp-ui";
import React from "react";
import { useGet } from "./api";
import { isTextNullOrEmpty } from "./utils";
import { Link as RouterLink } from "react-router-dom";

export default function AgencyAccountOwnerPanel({ id }) {
  const { data, loading, error } = useGet(id);

  if (loading) return <PageLoading />;

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    return (
      <>
        <PanelRow>
          <Label label="Account Owner Name">
            <BoldText>{data?.value?.accountOwnerName}</BoldText>
          </Label>
        </PanelRow>
      </>
    );
  }

  return (
    <Panel>
      <PanelHeading
      // rightAction={
      //   <Link component={RouterLink} to={`/edit-agency-account-owner/${id}`}>
      //     Edit
      //   </Link>
      // }
      >
        Account Owner
      </PanelHeading>
      <PanelSpacer />
      {handleRender()}
      <PanelSpacer />
    </Panel>
  );
}
