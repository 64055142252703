import React from "react";
import {
  Content,
  PageHeadingArea,
  BackArrow,
  PageTitle,
  PageHeadingAreaSpacer,
} from "@backslashbuild/pp-ui";
import { useParams, useHistory } from "react-router-dom";
import ApproveMatchesButton from "../modules/schedules-core/ApproveMatchesButton";
import CSVImportMatchReview from "../modules/schedules-core/CSVImportMatchReview";

function ScheduleCoreCSVImport() {
  const { id } = useParams();
  const history = useHistory();
  return (
    <Content scrollable>
      <CSVImportMatchReview id={id} />
    </Content>
  );
}

export default ScheduleCoreCSVImport;
