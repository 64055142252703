import React from "react";
import { makeStyles } from "@backslashbuild/pp-ui";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "-46px",
    paddingBottom: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function CustomTableCell({ children }) {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
}
