import React from "react";
import { Content } from "@backslashbuild/pp-ui";
import CreateInvoiceForm from "../modules/invoicing/CreateInvoiceForm";

export default function CreateInvoice() {
  return (
    <Content>
      <CreateInvoiceForm />
    </Content>
  );
}
