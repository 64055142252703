import React from "react";
import { CheckBox } from "@backslashbuild/pp-ui";
import { useUpdateInvoiceIsPaid } from "./api";

export default function InvoicePaidCheckbox({ invoice }) {
  const [isPaid, setIsPaid] = React.useState(invoice.isPaid);
  const update = useUpdateInvoiceIsPaid();

  async function handleSave() {
    setIsPaid(!isPaid);

    await update(invoice.invoiceNumber, {
      isPaid: !invoice.isPaid,
    });
  }

  return <CheckBox checked={isPaid} onChange={handleSave} />;
}
