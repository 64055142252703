import React from "react";

import { Content } from "@backslashbuild/pp-ui";

import RegistrationsTable from "../modules/registrations/RegistrationsTable";

export default function Registrations() {
  return (
    <Content>
      <RegistrationsTable />
    </Content>
  );
}
