import React from "react";
import { useParams, Link, useHistory } from "react-router-dom";

import {
  Content,
  PanelDialog,
  PageHeadingArea,
  Table,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableRow,
  TableCell,
  PageLoading,
  DateFormatted,
  BackArrow,
  Button,
  PageHeadingAreaSpacer,
} from "@backslashbuild/pp-ui";
import { useGetWalletTransactions } from "../modules/wallet-points/api";
import { isTextNullOrEmpty } from "../modules/contractors/utils";

import ContractorPageTitle from "../modules/contractors/ContractorPageTitle";
import PimpMyPointsForm from "../modules/wallet-points/PimpMyPointsForm";

function ContractorPointsStatement() {
  const history = useHistory();
  const { id } = useParams();
  const { data, loading, error, refetch: refreshTransactions } = useGetWalletTransactions({ contractorId: id });
  const [pimpMyPoints, setPimpMyPoints] = React.useState(false);

  function handleRender() {
    if (loading) return <PageLoading />;

    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

    return (
      <Table width={1200}>
        <TableHeader>
          <TableHeaderColumn width="90%">Points</TableHeaderColumn>
          <TableHeaderColumn width="10%">
            <div style={{ width: "100%", textAlign: "right" }}></div>
          </TableHeaderColumn>
        </TableHeader>
        <TableHeader>
          <TableHeaderColumn width="300px">Date</TableHeaderColumn>
          <TableHeaderColumn width="300px">Description</TableHeaderColumn>
          <TableHeaderColumn width="300px">Points</TableHeaderColumn>
        </TableHeader>
        <TableBody fullPageTable={false} footerHeight={0}>
          {data?.value?.map((item, index) => {
            return (
              <TableRow key={index} disableHover>
                <TableCell width="300px">
                  <DateFormatted date={item?.dateCreated} />
                </TableCell>
                <TableCell width="300px">{item?.description}</TableCell>
                <TableCell width="300px">{item?.eventType === "redeemed" ? item?.numberOfPoints * -1 : item?.numberOfPoints}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }

  return (
    <Content scrollable>
      <PageHeadingArea>
        <BackArrow onClick={() => history.goBack()} />
        <ContractorPageTitle id={id} title={"Points Statement"} />
        <PageHeadingAreaSpacer grow />
        <Button onClick={() => setPimpMyPoints(id)}>Pimp my points</Button>
      </PageHeadingArea>
      {handleRender()}
      <PanelDialog open={pimpMyPoints} onClose={() => setPimpMyPoints(null)}>
        <PimpMyPointsForm contractorId={id} onClose={() => setPimpMyPoints(null)} refreshTransactions={() => refreshTransactions()} />
      </PanelDialog>
    </Content>
  );
}

export default ContractorPointsStatement;
