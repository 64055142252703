import React, { useEffect, useState } from "react";
import {
  Panel,
  PageLoading,
  PanelActions,
  PanelHeading,
  Button,
  makeStyles,
} from "@backslashbuild/pp-ui";
import FormField from "./FormField";
import { usePost, useGetFields } from "./api";
import { isTextNullOrEmpty } from "./utils";

export default function EditOutstandingAnytimeWageRequestForm({
  contractorOutstandingAnytimeWageRequestId,
  onClose,
}) {
  const [payload, storePayload] = useState({});
  const { data, loading, error } = useGetFields({
    id: contractorOutstandingAnytimeWageRequestId,
    formName: "contractor-outstandingAnytimeWageRequest",
  });
  const updateContractorOutstandingAnytimeWageRequest = usePost(
    "contractor-outstandingAnytimeWageRequest/create"
  );

  useEffect(() => {
    const setInitialValues = () => {
      if (data && data.value) {
        let initialPayload = {};
        data.value.forEach((field) => {
          if (field.schema.type === "dropdown") {
            initialPayload = { ...initialPayload, [field.key]: field.value };
          }
        });
        storePayload(initialPayload);
      }
    };
    setInitialValues();
  }, [data]);

  if (loading) return <PageLoading />;

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    return data.value.map((field) => (
      <FormField key={field.key} field={field} onSave={getOnSaveHandler(field.key)} />
    ));
  }

  function getOnSaveHandler(key) {
    return (value) => {
      storePayload({ ...payload, [key]: value });
    };
  }

  return (
    <Panel>
      <PanelHeading>Edit outstanding anytime wage</PanelHeading>
      {handleRender()}
      <PanelActions>
        <Button variant="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            updateContractorOutstandingAnytimeWageRequest(payload);
            onClose();
          }}
        >
          Save
        </Button>
      </PanelActions>
    </Panel>
  );
}
