import React from "react";
import { useGetRFP } from "./api";
import { Pill } from "@backslashbuild/pp-ui";
import { formatCurrency } from "../../utils";
function RFPStatusPill({ reference }) {
  const { data, loading, error, queryId } = useGetRFP(reference);

  // const rfp = useRead(reference, async function (ref, write) {
  //   const result = await (await fetch(`http://localhost:5000/payroll/rfps/${ref}`)).json();
  //   write({
  //     [ref]: result,
  //   });
  // });

  if (loading) {
    return <small>loading...</small>;
  }

  if (error) {
    return <small>Unable to load RFP</small>;
  }

  if (data?.value?.isFinalised) {
    return <Pill status="success">{data?.value?.type?.name} (Finalised)</Pill>;
  }

  if (data?.value?.isCancelled) {
    return <Pill status="cancelled">{data?.value?.type?.name} (Cancelled)</Pill>;
  }

  if (data?.value?.isLocked) {
    return <Pill status="info">{data?.value?.type?.name} (Locked)</Pill>;
  }

  return <Pill status="info">{data?.value?.type?.name} (Staged)</Pill>;
}

export default RFPStatusPill;
