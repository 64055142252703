import React from "react";
import { useGetCompanies } from "./api";
import { utcToLocalText, isTextNullOrEmpty } from "./utils";
import {
  PageLoading,
  Table,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableCell,
  TableRow,
} from "@backslashbuild/pp-ui";

const width = {
  name: "20%",
  taxOffNo: "16%",
  taxOffRef: "16%",
  accOffRef: "16%",
  senderId: "16%",
  lastModified: "16%",
};

export default function CompaniesTable() {
  const { data, loading, error, queryId, refetch } = useGetCompanies();

  if (loading) return <PageLoading />;
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  return (
    <>
      <Table standardTable>
        <TableHeader>
          <TableHeaderColumn width={width.name}>Name</TableHeaderColumn>
          <TableHeaderColumn width={width.taxOffNo}>Tax Office No.</TableHeaderColumn>
          <TableHeaderColumn width={width.taxOffRef}>Tax Office Ref.</TableHeaderColumn>
          <TableHeaderColumn width={width.accOffRef}>Accounts Office Ref.</TableHeaderColumn>
          <TableHeaderColumn width={width.senderId}>Sender ID</TableHeaderColumn>
          <TableHeaderColumn width={width.lastModified}>Last Modified</TableHeaderColumn>
        </TableHeader>
        <TableBody fullPageTable={true}>
          {data?.value?.items?.map((company) => (
            <TableRow>
              <TableCell width={width.name}>{company.name}</TableCell>
              <TableCell width={width.taxOffNo}>{company.taxOfficeNumber}</TableCell>
              <TableCell width={width.taxOffRef}>{company.taxOfficeReference}</TableCell>
              <TableCell width={width.accOffRef}>{company.accountsOfficeReference}</TableCell>
              <TableCell width={width.senderId}>{company.senderId}</TableCell>
              <TableCell width={width.lastModified}>
                {utcToLocalText(company.dateModified, "HH:mm dd/MM/yyyy")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
