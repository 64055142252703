import React from "react";
import {
  Button,
  PanelDialog,
  PanelHeading,
  PanelRow,
  PanelSpacer,
  PanelActions,
  BoldText,
} from "@backslashbuild/pp-ui";
import { useImportIntoSchedule, useGetCsvImport } from "./api";
import { publish } from "../../pubSub";
import { useHistory } from "react-router-dom";

function CreateRFPsForSchedule({ id }) {
  const history = useHistory();

  // you have to query to see if there are any currently staged pay runs and then disable the button.
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const approveMatches = useImportIntoSchedule();
  const query = useGetCsvImport(id);

  async function handleSubmit() {
    try {
      const response = await approveMatches({ csvImportId: id });

      if (response.isSuccess) {
        publish("notification", "Imported into schedule");
        history.push(`/schedule-c/${query?.data?.value?.scheduleId}`);
      } else {
        publish(
          "notification",
          <div>
            Unable to import into schedule
            <ul>
              {response.errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </div>
        );
      }
    } catch (e) {
      console.error(e, "error creating rows");
      publish("notification", "A network error occured.");
    }
  }

  return (
    <>
      <Button large onClick={() => setDialogOpen(true)}>
        Import into schedule
      </Button>
      <PanelDialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <PanelHeading>Import rows into schedule</PanelHeading>
        <PanelSpacer large />
        <PanelRow>
          <BoldText>All rows will be imported into the schedule.</BoldText>
        </PanelRow>
        <PanelSpacer large />
        <PanelActions>
          <Button variant="secondary" onClick={() => setDialogOpen(false)}>
            No, cancel
          </Button>
          <Button onClick={handleSubmit}>Yes, import rows</Button>
        </PanelActions>
        <PanelSpacer large />
      </PanelDialog>
    </>
  );
}

export default CreateRFPsForSchedule;
