import React from "react";
import {
  TableRow,
  Table,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  StatusIndicator,
  PanelActions,
  Panel,
  Button,
  DatePicker,
  PanelRow,
  TextInput,
  CheckBox,
  TextInputMultiline,
} from "@backslashbuild/pp-ui";
import { useGet, usePost } from "./api";
import { utcToYmd } from "../contractors/utils";
import { Dropdown, DropdownOption, Label } from "@backslashbuild/pp-ui/dist/index.es";
import { DateTime } from "luxon";
import LegalDeductionForm from "./LegalDeductionForm";
import { publish } from "../../pubSub";

/*
public bool IsEnabled { get; set; } 
*/

function EmploymentLegalDeductionsPanel({ employmentId, contractorId, onClose }) {
  const { data, loading, error, queryId } = useGet(`legal-deductions?employmentId=${employmentId}`);
  const post = usePost("legal-deductions/create");
  const [create, setCreate] = React.useState(false);
  const [editLegalDeduction, setEditLegalDeduction] = React.useState(null);

  const [fields, setFields] = React.useState({
    contractorId,
    employmentId,
    dateOfIssue: utcToYmd(DateTime.local),
    legalDeductionTypeId: "",
    courtCaseNumber: "",
    normalDeductionRate: 0,
    protectedEarningsRate: 0,
    protectedEarningsProportion: 0,
    totalOwed: 0,
    paymentRateTypeId: "",
    notes: "",
    isEnabled: true,
  });

  function handleCreate() {
    post(fields).then((res) => {
      if (res?.isSuccess) {
        publish("notification", "Created successfully.");
        onClose();
      } else {
        if (res?.errors?.length > 0) {
          res.errors.forEach((e) => {
            publish("notification", e);
          });
        } else {
          publish("notification", "Form incomplete.");
        }
      }
    });
  }

  if (create) {
    return (
      <Panel>
        <TableHeader>
          <TableHeaderColumn>Legal deductions</TableHeaderColumn>
        </TableHeader>
        <PanelRow>
          <Label label="Date of issue">
            <DatePicker
              value={fields?.dateOfIssue}
              onChange={(v) => {
                setFields({
                  ...fields,
                  dateOfIssue: utcToYmd(v),
                });
              }}
            ></DatePicker>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Type">
            <Dropdown
              value={fields?.legalDeductionTypeId}
              onChange={(e) => {
                setFields({
                  ...fields,
                  legalDeductionTypeId: e.target.value,
                });
              }}
            >
              <DropdownOption value={""}>Choose:</DropdownOption>
              <DropdownOption value={"deo"}>Deduction from Earnings Orders</DropdownOption>
              <DropdownOption value={"priority_aeo"}>
                Priority Attachment of Earnings Orders
              </DropdownOption>
              <DropdownOption value={"non_priority_aeo"}>
                Non-priority Attachment of Earnings Orders
              </DropdownOption>
            </Dropdown>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Court Case Number">
            <TextInput
              value={fields?.courtCaseNumber}
              onChange={(e) =>
                setFields({
                  ...fields,
                  courtCaseNumber: e.target.value,
                })
              }
            ></TextInput>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Normal Deduction Rate">
            <TextInput
              value={fields?.normalDeductionRate}
              type="number"
              onChange={(e) =>
                setFields({
                  ...fields,
                  normalDeductionRate: Number(e.target.value),
                })
              }
            ></TextInput>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Protected Earnings Rate">
            <TextInput
              value={fields?.protectedEarningsRate}
              type="number"
              onChange={(e) =>
                setFields({
                  ...fields,
                  protectedEarningsRate: Number(e.target.value),
                })
              }
            ></TextInput>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Protected Earnings Proportion">
            <TextInput
              value={fields?.protectedEarningsProportion}
              type="number"
              onChange={(e) =>
                setFields({
                  ...fields,
                  protectedEarningsProportion: Number(e.target.value),
                })
              }
            ></TextInput>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Total owed">
            <TextInput
              value={fields?.totalOwed}
              type="number"
              onChange={(e) =>
                setFields({
                  ...fields,
                  totalOwed: Number(e.target.value),
                })
              }
            ></TextInput>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Payment rate">
            <Dropdown
              value={fields?.paymentRateTypeId}
              onChange={(e) => {
                setFields({
                  ...fields,
                  paymentRateTypeId: e.target.value,
                });
              }}
            >
              <DropdownOption value={""}>Choose:</DropdownOption>
              <DropdownOption value={"weekly"}>Weekly</DropdownOption>
              <DropdownOption value={"monthly"}>Monthly</DropdownOption>
            </Dropdown>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Notes">
            <TextInputMultiline
              value={fields?.notes ?? data?.value?.notes}
              rows={10}
              onChange={(e) =>
                setFields({
                  ...fields,
                  notes: e.target.value,
                })
              }
            ></TextInputMultiline>
          </Label>
        </PanelRow>
        <PanelRow>
          <Label label="Is enabled">
            <CheckBox
              checked={fields?.isEnabled}
              onChange={(e) => {
                setFields({
                  ...fields,
                  isEnabled: !fields?.isEnabled,
                });
              }}
            ></CheckBox>
          </Label>
        </PanelRow>
        <PanelActions>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Button onClick={() => handleCreate()}>Create</Button>
        </PanelActions>
      </Panel>
    );
  }

  if (editLegalDeduction) {
    return (
      <LegalDeductionForm id={editLegalDeduction} onClose={() => setEditLegalDeduction(null)} />
    );
  }

  return (
    <Panel>
      <Table>
        <TableHeader>
          <TableHeaderColumn>Legal deductions</TableHeaderColumn>
        </TableHeader>
        <TableHeader>
          <TableHeaderColumn width={120}>Date issued</TableHeaderColumn>
          <TableHeaderColumn width={250}>Type</TableHeaderColumn>
          <TableHeaderColumn width={100}>Total owed</TableHeaderColumn>
          <TableHeaderColumn width={80}>Enabled</TableHeaderColumn>
        </TableHeader>
        {data?.value?.items?.length == 0 && (
          <TableCell width={550}>No legal deductions to display.</TableCell>
        )}
        {data?.value?.items?.map((x, i) => (
          <TableRow key={i} onClick={() => setEditLegalDeduction(x.id)}>
            <TableCell width={120}>
              <small>{utcToYmd(x.dateOfIssue)}</small>
            </TableCell>
            <TableCell width={250}>
              <small>{x.type}</small>
            </TableCell>
            <TableCell width={100}>
              <small>{x.totalOwed}</small>
            </TableCell>
            <TableCell width={80}>
              <StatusIndicator value={x.isEnabled} />
            </TableCell>
          </TableRow>
        ))}
      </Table>
      <PanelActions>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button onClick={() => setCreate(true)}>Add</Button>
      </PanelActions>
    </Panel>
  );
}

export default EmploymentLegalDeductionsPanel;
