import React from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import luxonUtils from "@date-io/luxon";
import { FlagsProvider } from "react-unleash-flags";
import { useIsFlagEnabled, FeatureFlag } from "./featureFlags";

import {
  StyleWrapper,
  StyleDefaults,
  Root,
  TopNavigationBar,
  TopNavigationBarDropdown,
  TopNavigationBarLink,
  SnackProvider,
  DateUtilsProvider,
  Link,
  PageLoading,
  Avatar,
  TopNavigationBarSpacer,
  PopoverMenu,
  PopoverMenuItem,
  Rollover,
} from "@backslashbuild/pp-ui";
import { AuthenticationProvider, oidcLog, useReactOidc } from "@axa-fr/react-oidc-context";
import { Link as RouterLink } from "react-router-dom";
import { QueryDevtoolsProvider } from "./containers/Cache";

import getApiUrl from "./getApiUrl";
import ContractorsFilter from "./modules/contractors/ContractorsFilter";

import Agency from "./pages/Agency";
import AnytimeWages from "./pages/AnytimeWages";
import AdvancesOutstanding from "./pages/AdvancesOutstanding";
import AdvanceFeedback from "./pages/AdvanceFeedback";
import AdvancesToggleMode from "./pages/AdvancesToggleMode";
import EmergencyPayments from "./pages/EmergencyPayments";
import EditAgencyDetails from "./pages/EditAgencyDetails";
import EditAgencyAccountOwner from "./pages/EditAgencyAccountOwner";
import Agencies from "./pages/Agencies";
import CreateBranch from "./pages/CreateBranch";
import CreateAgencyContact from "./pages/CreateAgencyContact";
import CreateAgencyConsultant from "./pages/CreateAgencyConsultant";
import CommsTopics from "./pages/CommsTopics";
import ContractManagement from "./pages/ContractManagement";
import CommsManagement from "./pages/CommsManagement";
import ContractsManagement from "./pages/ContractsManagement";
import EmailManagement from "./pages/EmailManagement";
import SMSManagement from "./pages/SMSManagement";
import ContractEditor from "./pages/ContractEditor";
import EmailEditor from "./pages/EmailEditor";
import SMSEditor from "./pages/SMSEditor";
import EditAgencyContact from "./pages/EditAgencyContact";
import EditAgencyBranch from "./pages/EditAgencyBranch";
import EditAgencyConsultant from "./pages/EditAgencyConsultant";
import Contractor from "./pages/Contractor";
import Contractors from "./pages/Contractors";
import ContractorPayslips from "./pages/ContractorPayslips";
import ContractorTimesheets from "./pages/ContractorTimesheets";
import EditContractorPersonalDetails from "./pages/EditContractorPersonalDetails";
import EditContractorBankDetails from "./pages/EditContractorBankDetails";
import EditCommsTopic from "./pages/EditCommsTopic";
import NewCommsTopic from "./pages/NewCommsTopic";
import NESTEmployments from "./pages/NESTEmployments";
import ContractorPointsStatement from "./pages/ContractorPointsStatement";
import HolidayEntitlement from "./pages/HolidayEntitlement";
import ContractorProduct from "./pages/ContractorProduct";
import Registrations from "./pages/Registrations";
import Registration from "./pages/Registration";
import P45Management from "./pages/P45Management";
import Product from "./pages/Product";
import Products from "./pages/Products";
import ProductRates from "./pages/ProductRates";
import ScheduleCore from "./pages/ScheduleCore";
import ScheduleCoreMatches from "./pages/ScheduleCoreMatches";
import ScheduleCores from "./pages/ScheduleCores";
import FPSs from "./pages/FPSs";
import FPS from "./pages/FPS";
import RFPs from "./pages/RFPs";
import PayRuns from "./pages/PayRuns";
import PayRun from "./pages/PayRun";
import Payslips from "./pages/Payslips";
import PayslipReportingPayslips from "./pages/PayslipReportingPayslips";
import StatutoryPayGroup from "./pages/StatutoryPayGroup";
import StatutoryPayments from "./pages/StatutoryPayments";
import Invoices from "./pages/Invoices";
import CreateInvoice from "./pages/CreateInvoice";
import InvoiceCreation from "./pages/InvoiceCreation";
import Users from "./pages/Users";
import User from "./pages/User";
import UsersCreation from "./pages/UsersCreation";
import TaxCalendar from "./pages/TaxCalendar";
import Payslip from "./pages/Payslip";
import RedirectToPayslip from "./pages/RedirectToPayslip";
import IllustrationCreation from "./pages/IllustrationCreation";
import Illustration from "./pages/Illustration";
import ViewHistory from "./pages/ViewHistory";

import { subscribe, unsubscribe } from "./pubSub";
import logo from "./icon.svg";
import ScheduleCoreCSVImport from "./pages/ScheduleCoreCSVImport";
import ScheduleCoreCSVMatching from "./pages/ScheduleCoreCSVMatching";

import { useCheckUserPermission } from "./permissions";
import TaxWeekPill from "./modules/tax-calendar/TaxWeekPill";
import ContractorProductStatutoryPayGroups from "./pages/ContractorProductStatutoryPayGroups";

import Companies from "./pages/Companies";
import DPS from "./pages/DPS";
import ViewFPSs from "./pages/ViewFPSs";
import FPSEmployeeData from "./pages/FPSEmployeeData";
import ViewEPSs from "./pages/ViewEPSs";
import DraftFPSEmployeeData from "./pages/DraftFPSEmployeeData";
import NESTEnrolments from "./pages/NESTEnrolments";
import NESTContributionSchedules from "./pages/NESTContributionSchedules";
import NESTContributionSchedule from "./pages/NESTContributionSchedule";
import NESTContributionScheduleExport from "./pages/NESTContributionScheduleExport";
import NESTEmployment from "./pages/NESTEmployment";

import BACSRuns from "./pages/BACSRuns";
import BACSRun from "./pages/BACSRun";
import BACSPayslips from "./pages/BACSPayslips";
import RedirectToInvoiceSchedule from "./pages/RedirectToInvoiceSchedule";

import Report from "./pages/Report";
import ScheduleInvoicesTable from "./component-pages/ScheduleInvoicesTable";
import LegacyIntegration from "./pages/LegacyIntegration";
import LegacySyncMessages from "./pages/LegacySyncMessages";
import RedirectToSelectedInvoices from "./pages/RedirectToSelectedInvoices";
import getIsDevelopment from "./getIsDevelopment";
import getBaseUrl from "./getBaseUrl";
import DebugPayslip from "./pages/DebugPayslip";
import DebugPayslips from "./pages/DebugPayslips";

const __DEV__ = process.env.NODE_ENV == "development";

const configuration = {
  client_id: "staff_portal",
  redirect_uri: `${window.location.origin}/authentication/callback`,
  response_type: "code",
  post_logout_redirect_uri: `${window.location.origin}`,
  scope: "openid user_info api1 IdentityServerApi offline_access",
  authority: `${getBaseUrl()}/identity`,
  silent_redirect_uri: `${window.location.origin}/authentication/silent_callback`,
  automaticSilentRenew: true,
  loadUserInfo: true,
};

const flagConfig = {
  appName: getIsDevelopment() ? "dev" : "prod",
  url: "https://gitlab.com/api/v4/feature_flags/unleash/24097185",
  instanceId: "H_nSraG6wrpsLjLkCmfg",
  extraHttpHeaders: {},
};

function Router() {
  const { oidcUser, login, logout } = useReactOidc();
  const unreleased = useIsFlagEnabled("unreleased");
  const hmrcRead = useIsFlagEnabled("hmrc-read");
  const bacs = useIsFlagEnabled("bacs");
  const invoicesRead = useIsFlagEnabled("invoices-read");
  const reports = useIsFlagEnabled("reports");

  const hasPermissionToViewUsers = useCheckUserPermission("Admin.CanViewUsers");
  const hasPermissionToViewCommLogTopics = useCheckUserPermission("Admin.CanViewLogTopics");
  const hasPermissionToViewFPS = useCheckUserPermission("Admin.CanViewFPS");

  React.useEffect(() => {
    if (!oidcUser) login();
  }, []);

  if (!oidcUser) {
    return <SessionLost />;
  }

  // if (oidcUser?.profile?.is_contractor === "True" ?? false) {
  //   return <div>YOU ARE CONTRACTOR. Please go to contractor portal.</div>;
  // }

  return (
    <QueryDevtoolsProvider>
      <BrowserRouter>
        <StyleWrapper>
          <StyleDefaults />
          <DateUtilsProvider utils={luxonUtils}>
            <SnackProvider
              subscribe={(f) => subscribe("notification", f)}
              unsubscribe={(f) => unsubscribe("notification", f)}
            >
              <Switch>
                <Route path="/components/schedule-invoices-table/:scheduleNumber">
                  <ScheduleInvoicesTable />
                </Route>

                <Route>
                  <Root>
                    <TopNavigationBar style={{ background: getIsDevelopment() ? "#e45050" : null }}>
                      <div
                        style={{
                          backgroundImage: `url(${logo})`,
                          width: 40,
                          height: 40,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          marginRight: 16,
                        }}
                      ></div>
                      <FeatureFlag name="unreleased">
                        <TopNavigationBarLink
                          component={RouterLink}
                          style={{ textDecoration: "none" }}
                          to="/contractors"
                        >
                          Contractors
                        </TopNavigationBarLink>
                      </FeatureFlag>
                      <FeatureFlag name="unreleased">
                        <TopNavigationBarLink
                          component={RouterLink}
                          style={{ textDecoration: "none" }}
                          to="/agencies"
                        >
                          Agencies
                        </TopNavigationBarLink>
                      </FeatureFlag>
                      <FeatureFlag name="unreleased">
                        <TopNavigationBarLink
                          component={RouterLink}
                          style={{ textDecoration: "none" }}
                          to={`/registrations?isDead=no&isImported=no&isLive=yes`}
                        >
                          Registrations
                        </TopNavigationBarLink>
                      </FeatureFlag>
                      <FeatureFlag name="unreleased">
                        <Rollover
                          renderAnchor={() => (
                            <TopNavigationBarLink
                              component={RouterLink}
                              style={{ textDecoration: "none", padding: "0px 10px" }}
                            >
                              Admin
                            </TopNavigationBarLink>
                          )}
                          placement="bottom"
                        >
                          <TopNavigationBarDropdown>
                            {/* {hasPermissionToViewUsers && ( */}
                            <TopNavigationBarLink
                              component={RouterLink}
                              style={{ textDecoration: "none" }}
                              to="/users?isContractor=no"
                              light
                            >
                              Users
                            </TopNavigationBarLink>
                            {/* )} */}
                            <TopNavigationBarLink
                              component={RouterLink}
                              style={{ textDecoration: "none" }}
                              to="/contracts-management"
                              light
                            >
                              Contracts Management
                            </TopNavigationBarLink>
                            <TopNavigationBarLink
                              component={RouterLink}
                              style={{ textDecoration: "none" }}
                              to="/contract-management"
                              light
                            >
                              Contract Templates
                            </TopNavigationBarLink>
                            <TopNavigationBarLink
                              component={RouterLink}
                              style={{ textDecoration: "none" }}
                              to="/comms-management"
                              light
                            >
                              Comms Management
                            </TopNavigationBarLink>
                            <TopNavigationBarLink
                              component={RouterLink}
                              style={{ textDecoration: "none" }}
                              to="/email-management"
                              light
                            >
                              Email Management
                            </TopNavigationBarLink>
                            <TopNavigationBarLink
                              component={RouterLink}
                              style={{ textDecoration: "none" }}
                              to="/sms-management"
                              light
                            >
                              SMS Management
                            </TopNavigationBarLink>
                            <TopNavigationBarLink
                              component={RouterLink}
                              style={{ textDecoration: "none" }}
                              to="/advances-outstanding"
                              light
                            >
                              Advances Outstanding
                            </TopNavigationBarLink>
                            <TopNavigationBarLink
                              component={RouterLink}
                              style={{ textDecoration: "none" }}
                              to="/advance-feedback"
                              light
                            >
                              Advances Feedback
                            </TopNavigationBarLink>
                            <TopNavigationBarLink
                              component={RouterLink}
                              style={{ textDecoration: "none" }}
                              to="/advances-toggle-mode"
                              light
                            >
                              Advances Toggle Mode
                            </TopNavigationBarLink>
                            <TopNavigationBarLink
                              component={RouterLink}
                              style={{ textDecoration: "none" }}
                              to="/emergency-payments"
                              light
                            >
                              Emergency Payments
                            </TopNavigationBarLink>
                            <TopNavigationBarLink
                              component={RouterLink}
                              style={{ textDecoration: "none" }}
                              to="/products"
                              light
                            >
                              Products
                            </TopNavigationBarLink>
                            <TopNavigationBarLink
                              component={RouterLink}
                              style={{ textDecoration: "none" }}
                              to="/tax-calendar"
                              light
                            >
                              Tax Calendar
                            </TopNavigationBarLink>
                            {/* {hasPermissionToViewCommLogTopics && ( */}
                            <TopNavigationBarLink
                              component={RouterLink}
                              style={{ textDecoration: "none" }}
                              to="/comms-topics"
                              light
                            >
                              Comms Topics
                            </TopNavigationBarLink>
                            {/* )} */}
                          </TopNavigationBarDropdown>
                        </Rollover>
                      </FeatureFlag>
                      <TopNavigationBarLink
                        component={RouterLink}
                        style={{ textDecoration: "none" }}
                        to="/invoices"
                      >
                        Invoices
                      </TopNavigationBarLink>
                      <TopNavigationBarLink
                        component={RouterLink}
                        style={{ textDecoration: "none" }}
                        to="/payslips"
                      >
                        Payslips
                      </TopNavigationBarLink>
                      <Rollover
                        renderAnchor={() => (
                          <TopNavigationBarLink
                            component={RouterLink}
                            style={{ textDecoration: "none", padding: "0px 10px" }}
                          >
                            Payroll
                          </TopNavigationBarLink>
                        )}
                        placement="bottom"
                      >
                        <TopNavigationBarDropdown>
                          <TopNavigationBarLink
                            component={RouterLink}
                            style={{ textDecoration: "none" }}
                            to="/create-illustration"
                            light
                          >
                            Test Illustrations
                          </TopNavigationBarLink>
                          <TopNavigationBarLink
                            component={RouterLink}
                            style={{ textDecoration: "none" }}
                            to="/schedules-c"
                            light
                          >
                            Schedules
                          </TopNavigationBarLink>
                          <TopNavigationBarLink
                            component={RouterLink}
                            style={{ textDecoration: "none" }}
                            to="/fps"
                            light
                          >
                            FPS
                          </TopNavigationBarLink>
                          <TopNavigationBarLink
                            component={RouterLink}
                            style={{ textDecoration: "none" }}
                            to="/rfps"
                            light
                          >
                            RFPs
                          </TopNavigationBarLink>
                          <TopNavigationBarLink
                            component={RouterLink}
                            style={{ textDecoration: "none" }}
                            to="/payruns"
                            light
                          >
                            Pay Runs
                          </TopNavigationBarLink>
                          <TopNavigationBarLink
                            component={RouterLink}
                            style={{ textDecoration: "none" }}
                            to="/p45-management"
                            light
                          >
                            P45 Management
                          </TopNavigationBarLink>
                          <TopNavigationBarLink
                            component={RouterLink}
                            style={{ textDecoration: "none" }}
                            to="/statutory-payments"
                            light
                          >
                            Statutory Pay
                          </TopNavigationBarLink>
                          <TopNavigationBarLink
                            component={RouterLink}
                            style={{ textDecoration: "none" }}
                            to="/nest-employments"
                            light
                          >
                            NEST Employments
                          </TopNavigationBarLink>
                          <TopNavigationBarLink
                            component={RouterLink}
                            style={{ textDecoration: "none" }}
                            to="/nest-enrolments"
                            light
                          >
                            NEST Enrolments
                          </TopNavigationBarLink>
                          <TopNavigationBarLink
                            component={RouterLink}
                            style={{ textDecoration: "none" }}
                            to="/nest-contributions"
                            light
                          >
                            NEST Contributions
                          </TopNavigationBarLink>
                        </TopNavigationBarDropdown>
                      </Rollover>

                      <FeatureFlag name="hmrc-read">
                        <Rollover
                          renderAnchor={() => (
                            <TopNavigationBarLink
                              component={RouterLink}
                              style={{ textDecoration: "none", padding: "0px 10px" }}
                            >
                              HMRC
                            </TopNavigationBarLink>
                          )}
                          placement="bottom"
                        >
                          <TopNavigationBarDropdown>
                            <TopNavigationBarLink
                              component={RouterLink}
                              style={{ textDecoration: "none" }}
                              to="/companies"
                              light
                            >
                              Companies
                            </TopNavigationBarLink>
                            <FeatureFlag name="hmrc-read">
                              <TopNavigationBarLink
                                component={RouterLink}
                                style={{ textDecoration: "none" }}
                                to="/hmrc-dps"
                                light
                              >
                                HMRC Notifications
                              </TopNavigationBarLink>
                            </FeatureFlag>
                            {hasPermissionToViewFPS && (
                              <TopNavigationBarLink
                                component={RouterLink}
                                style={{ textDecoration: "none" }}
                                to="/hmrc-fps"
                                light
                              >
                                Full Payment Submissions
                              </TopNavigationBarLink>
                            )}
                            <TopNavigationBarLink
                              component={RouterLink}
                              style={{ textDecoration: "none" }}
                              to="/hmrc-eps"
                              light
                            >
                              Employer Payment Summaries
                            </TopNavigationBarLink>
                          </TopNavigationBarDropdown>
                        </Rollover>
                      </FeatureFlag>
                      <FeatureFlag name="bacs">
                        <Rollover
                          renderAnchor={() => (
                            <TopNavigationBarLink
                              component={RouterLink}
                              style={{ textDecoration: "none", padding: "0px 10px" }}
                            >
                              BACS
                            </TopNavigationBarLink>
                          )}
                          placement="bottom"
                        >
                          <TopNavigationBarDropdown>
                            <TopNavigationBarLink
                              component={RouterLink}
                              style={{ textDecoration: "none" }}
                              to="/bacs-payslips"
                              light
                            >
                              Manage Payslips
                            </TopNavigationBarLink>
                          </TopNavigationBarDropdown>
                          <TopNavigationBarDropdown>
                            <TopNavigationBarLink
                              component={RouterLink}
                              style={{ textDecoration: "none" }}
                              to="/bacs-runs"
                              light
                            >
                              BACS Runs
                            </TopNavigationBarLink>
                          </TopNavigationBarDropdown>
                        </Rollover>
                      </FeatureFlag>
                      <FeatureFlag name="reports">
                        <Rollover
                          renderAnchor={() => (
                            <TopNavigationBarLink
                              component={RouterLink}
                              style={{ textDecoration: "none", padding: "0px 10px" }}
                            >
                              Reports
                            </TopNavigationBarLink>
                          )}
                          placement="bottom"
                        >
                          <TopNavigationBarDropdown>
                            <TopNavigationBarLink
                              component={RouterLink}
                              style={{ textDecoration: "none" }}
                              to="/reports/invoices"
                              light
                            >
                              Invoices
                            </TopNavigationBarLink>
                          </TopNavigationBarDropdown>
                          <TopNavigationBarDropdown>
                            <TopNavigationBarLink
                              component={RouterLink}
                              style={{ textDecoration: "none" }}
                              to="/reports/payslips"
                              light
                            >
                              Payslips
                            </TopNavigationBarLink>
                          </TopNavigationBarDropdown>
                        </Rollover>
                      </FeatureFlag>
                      <FeatureFlag name="unreleased">
                        <TopNavigationBarLink
                          component={RouterLink}
                          style={{ textDecoration: "none" }}
                          to="/view-history"
                        >
                          History
                        </TopNavigationBarLink>
                      </FeatureFlag>
                      <TopNavigationBarLink
                        component={RouterLink}
                        style={{ textDecoration: "none" }}
                        to="/legacy-integration"
                      >
                        Legacy Integration
                      </TopNavigationBarLink>
                      <TopNavigationBarLink
                        component={RouterLink}
                        style={{ textDecoration: "none" }}
                        to="/legacy-sync-messages"
                      >
                        Legacy Sync Messages
                      </TopNavigationBarLink>
                      <TopNavigationBarSpacer grow />
                      <TaxWeekPill />
                      <FeatureFlag name="unreleased">
                        <ContractorsFilter small />
                      </FeatureFlag>
                      <AvatarDropdown />
                    </TopNavigationBar>
                    <Switch>
                      {unreleased && (
                        <Route exact path="/registrations">
                          <Registrations />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/registration/:id">
                          <Registration isLead />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/registration-illustration/:id">
                          <Registration />
                        </Route>
                      )}
                      {unreleased && (
                        <Route exact path="/comms-topics">
                          <CommsTopics />
                        </Route>
                      )}
                      {unreleased && (
                        <Route exact path="/edit-comms-topic/:id">
                          <EditCommsTopic />
                        </Route>
                      )}
                      {unreleased && (
                        <Route exact path="/new-comms-topic">
                          <NewCommsTopic />
                        </Route>
                      )}
                      {unreleased && (
                        <Route exact path="/contractors">
                          <Contractors />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/contractor/:id">
                          <Contractor />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/contractor-payslips/:id">
                          <ContractorPayslips />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/contractor-timesheets/:id">
                          <ContractorTimesheets />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/edit-contractor-personal-details/:id">
                          <EditContractorPersonalDetails />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/edit-contractor-bank-details/:id">
                          <EditContractorBankDetails />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/contractor-points-statement/:id">
                          <ContractorPointsStatement />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/holiday-entitlement/:contractorProductId">
                          <HolidayEntitlement />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/statutory-pay-groups/:contractorProductId">
                          <ContractorProductStatutoryPayGroups />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/contractor-anytime-wages/:id">
                          <AnytimeWages />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/contractor-product/:contractorProductId">
                          <ContractorProduct />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/contract-management">
                          <ContractManagement />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/comms-management">
                          <CommsManagement />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/contracts-management">
                          <ContractsManagement />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/email-management">
                          <EmailManagement />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/sms-management">
                          <SMSManagement />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/p45-management">
                          <P45Management />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/statutory-pay-group/:statutoryPayGroupId">
                          <StatutoryPayGroup />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/statutory-payments">
                          <StatutoryPayments />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/agencies">
                          <Agencies />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/advances-outstanding">
                          <AdvancesOutstanding />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/advance-feedback">
                          <AdvanceFeedback />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/advances-toggle-mode">
                          <AdvancesToggleMode />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/emergency-payments">
                          <EmergencyPayments />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/agency/:id">
                          <Agency />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/edit-agency-details/:id">
                          <EditAgencyDetails />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/edit-agency-account-owner/:id">
                          <EditAgencyAccountOwner />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/create-branch/:agencyId">
                          <CreateBranch />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/create-agency-contact/:agencyId">
                          <CreateAgencyContact />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/create-agency-consultant/:agencyId">
                          <CreateAgencyConsultant />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/edit-agency-contact/:contactId">
                          <EditAgencyContact />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/edit-agency-branch/:branchId">
                          <EditAgencyBranch />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/edit-agency-consultant/:consultantId">
                          <EditAgencyConsultant />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/products">
                          <Products />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/product/:id">
                          <Product />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/product-rates">
                          <ProductRates />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/schedules">
                          <ScheduleCore />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/schedules-c-matches">
                          <ScheduleCoreMatches />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/schedules-c">
                          <ScheduleCores />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/schedule-c/:id">
                          <ScheduleCore />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/schedule-c-import/:id">
                          <ScheduleCoreCSVImport />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/schedule-c-matching/:id">
                          <ScheduleCoreCSVMatching />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/schedule/:id">
                          <ScheduleCore />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/rfps">
                          <RFPs />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/payruns">
                          <PayRuns />
                        </Route>
                      )}
                      <Route path="/payslips">
                        <PayslipReportingPayslips />
                      </Route>
                      {unreleased && (
                        <Route path="/payrun/:id">
                          <PayRun />
                        </Route>
                      )}
                      {unreleased && (
                        <Route exact path="/fps">
                          <FPSs />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/fps/:id">
                          <FPS />
                        </Route>
                      )}
                      <Route path="/payslip-redirect/:payslipNumber">
                        <RedirectToPayslip />
                      </Route>
                      {unreleased && (
                        <Route path="/illustration-redirect/:id">
                          <RedirectToPayslip isIllustration />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/payslip/:id">
                          <Payslip />
                        </Route>
                      )}

                      <Route path="/legacy-integration">
                        <LegacyIntegration />
                      </Route>

                      <Route path="/legacy-sync-messages">
                        <LegacySyncMessages />
                      </Route>

                      <Route path="/debug-payslip/:payslipNumber">
                        <DebugPayslip />
                      </Route>

                      <Route path="/debug-payslips">
                        <DebugPayslips />
                      </Route>

                      <Route path="/nest-employments">
                        <NESTEmployments />
                      </Route>

                      <Route path="/nest-employment/:accountNumber">
                        <NESTEmployment />
                      </Route>

                      <Route path="/nest-enrolments">
                        <NESTEnrolments />
                      </Route>

                      <Route path="/nest-contributions">
                        <NESTContributionSchedules />
                      </Route>

                      <Route path="/contribution-schedule/:contributionScheduleId">
                        <NESTContributionSchedule />
                      </Route>

                      <Route path="/contribution-schedule-export/:exportId">
                        <NESTContributionScheduleExport />
                      </Route>

                      {unreleased && (
                        <Route path="/create-illustration">
                          <IllustrationCreation />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/illustration/:id">
                          <Illustration />
                        </Route>
                      )}
                      {invoicesRead && (
                        <Route path="/invoices">
                          <Invoices />
                        </Route>
                      )}
                      {invoicesRead && (
                        <Route path="/invoice-schedule-redirect/:scheduleId">
                          <RedirectToInvoiceSchedule />
                        </Route>
                      )}
                      {invoicesRead && (
                        <Route path="/invoice-selected-redirect/:path">
                          <RedirectToSelectedInvoices />
                        </Route>
                      )}
                      {unreleased?.enabled && (
                        <Route path="/create-invoice">
                          <CreateInvoice />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/create-credit-note">
                          <InvoiceCreation isCreditNote />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/users">
                          <Users />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/tax-calendar">
                          <TaxCalendar />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/user/:id">
                          <User />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/create-user">
                          <UsersCreation />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/contract-editor/:id">
                          <ContractEditor />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/email-editor/:id">
                          <EmailEditor />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/sms-editor/:id">
                          <SMSEditor />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/view-history">
                          <ViewHistory />
                        </Route>
                      )}
                      {hmrcRead && (
                        <Route path="/companies">
                          <Companies />
                        </Route>
                      )}
                      {hmrcRead && (
                        <Route path="/hmrc-dps">
                          <DPS />
                        </Route>
                      )}
                      {hmrcRead && hasPermissionToViewFPS && (
                        <Route path="/hmrc-fps">
                          <ViewFPSs />
                        </Route>
                      )}
                      {hmrcRead && (
                        <Route path="/hmrc-fps-employees">
                          <FPSEmployeeData />
                        </Route>
                      )}
                      {hmrcRead && (
                        <Route path="/hmrc-eps">
                          <ViewEPSs />
                        </Route>
                      )}
                      {unreleased && (
                        <Route path="/hmrc-draft-fps-employees">
                          <DraftFPSEmployeeData />
                        </Route>
                      )}
                      {bacs && (
                        <Route path="/bacs-runs">
                          <BACSRuns />
                        </Route>
                      )}
                      {bacs && (
                        <Route path="/bacs-run/:id">
                          <BACSRun />
                        </Route>
                      )}
                      {bacs && (
                        <Route path="/bacs-payslips">
                          <BACSPayslips />
                        </Route>
                      )}
                      {reports && (
                        <Route path="/reports/:reportName">
                          <Report />
                        </Route>
                      )}
                      {invoicesRead && <Redirect from="*" to="/invoices" />}
                      <Route>
                        <div />
                      </Route>
                    </Switch>
                  </Root>
                </Route>
              </Switch>
            </SnackProvider>
          </DateUtilsProvider>
        </StyleWrapper>
      </BrowserRouter>
    </QueryDevtoolsProvider>
  );
}

function AvatarDropdown() {
  const [open, setOpen] = React.useState(false);
  const { oidcUser, login, logout } = useReactOidc();

  return (
    <PopoverMenu
      open={open}
      onClose={() => setOpen(false)}
      renderAnchor={(ref) => (
        <TopNavigationBarLink ref={ref} onClick={() => setOpen(true)}>
          <Avatar text={oidcUser?.profile?.name?.[0]} />
        </TopNavigationBarLink>
      )}
    >
      {/* TODO this can be designed and done better in the UI library */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 100,
          flexDirection: "column",
        }}
      >
        <div>{oidcUser?.profile?.name}</div>
        <div>{oidcUser?.profile?.email}</div>
      </div>
      <PopoverMenuItem href={`${getBaseUrl()}/identity/account/updatepassword`}>
        Change my password
      </PopoverMenuItem>
      <PopoverMenuItem onClick={logout}>Log out</PopoverMenuItem>
    </PopoverMenu>
  );
}

function Loader({ children }) {
  return (
    <div className="splash-screen">
      <div className="splash-logo"></div>
      <div className="children">{children || "Connecting..."}</div>
    </div>
  );
}

function SessionLost() {
  const { login, logout } = useReactOidc();

  return (
    <Loader>
      Session lost.
      <br />
      <br />
      <Link onClick={login}>Log back in.</Link>
      <Link onClick={logout}>Log out.</Link>
    </Loader>
  );
}
function NotLoggedIn({ children }) {
  return (
    <Loader>
      Not logged in.
      <br />
      {__DEV__ && children}
      <br />
      <Link href="/">Log in.</Link>
    </Loader>
  );
}

function App() {
  return (
    <FlagsProvider config={flagConfig}>
      <AuthenticationProvider
        configuration={configuration}
        loggerLevel={oidcLog.INFO}
        callbackComponentOverride={() => <PageLoading />}
        sessionLostComponent={SessionLost}
        noAuthorized={() => <NotLoggedIn>noAuthorized</NotLoggedIn>}
        notAuthenticated={() => <NotLoggedIn>notAuthenticated</NotLoggedIn>}
        authenticating={() => <PageLoading />}
      >
        <Router />
      </AuthenticationProvider>
    </FlagsProvider>
  );
}

export default App;
