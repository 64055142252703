import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  TableRow,
  Table,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableFooter,
  TableBody,
  BoldText,
  Pill,
  StatusIndicator,
} from "@backslashbuild/pp-ui";
import { Link as RouterLink } from "react-router-dom";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";
import { useGet, useGetSchedules } from "./api";
import { utcToLocalText } from "../../utils";

const c = {
  weekNumber: 100,
  agency: 500,
  status: 300,
  dateCreated: 100,
  checked: 100,
  locked: 100,
  closed: 100,
};
const totalWidth = Object.keys(c).reduce((total, key) => (total += c[key]), 0);

const getBackground = ({ schedule }) => {
  if (schedule?.isClosed) {
    return "rgb(90, 199, 117)";
  }

  if (schedule?.isLocked) {
    return "rgb(255, 178, 0)";
  }

  return "transparent";
};

function PureTable({ data, queryId }) {
  return React.useMemo(() =>
    data.value.items.map(
      (schedule) => (
        <>
          <TableRow
            key={schedule.id}
            component={RouterLink}
            to={`/schedule-c/${schedule.id}`}
            backgroundColor={getBackground({ schedule })}
            //style={{ display: "block" }}
          >
            <TableCell width={c.weekNumber}>{schedule.weekNumber}</TableCell>
            <TableCell width={c.agency}>
              {schedule.agencyNumber} {schedule.agencyName}
            </TableCell>
            <TableCell width={c.dateCreated}>{utcToLocalText(schedule.dateCreated)}</TableCell>
            <TableCell width={c.checked}>
              <StatusIndicator value={schedule.isChecked} />
            </TableCell>
            <TableCell width={c.locked}>
              <StatusIndicator value={schedule.isLocked} />
            </TableCell>
            <TableCell width={c.closed}>
              <StatusIndicator value={schedule.isClosed} />
            </TableCell>
          </TableRow>
        </>
      ),
      [queryId]
    )
  );
}

function TableSkeleton() {
  return (
    <TableRow>
      <TableCell width={c.weekNumber}>-</TableCell>
      <TableCell width={c.agency}>-</TableCell>
      <TableCell width={c.dateCreated}>-</TableCell>
      <TableCell width={c.checked}>-</TableCell>
      <TableCell width={c.locked}>-</TableCell>
      <TableCell width={c.closed}>-</TableCell>
    </TableRow>
  );
}

function PayRunsTable() {
  const location = useLocation();
  const search = usePagination(location.search);
  const { data, loading, error, queryId } = useGetSchedules(search);

  const isLoaded = Boolean(data?.value);

  return (
    <Table standardTable width={totalWidth}>
      <TableHeader>
        <TableHeaderColumn width={c.weekNumber}>Week Number</TableHeaderColumn>
        <TableHeaderColumn width={c.agency}>Agency</TableHeaderColumn>
        <TableHeaderColumn width={c.dateCreated}>Date Created</TableHeaderColumn>
        <TableHeaderColumn width={c.checked}>Checked</TableHeaderColumn>
        <TableHeaderColumn width={c.locked}>Locked</TableHeaderColumn>
        <TableHeaderColumn width={c.closed}>Closed</TableHeaderColumn>
      </TableHeader>
      <TableBody fullPageTable={true} footerHeight={TableFooter.height}>
        {isLoaded ? (
          <PureTable data={data} queryId={queryId} />
        ) : data?.errors ? (
          data?.errors.map((error) => <div>{error}</div>)
        ) : (
          <TableSkeleton />
        )}
      </TableBody>
      <TableFooter>
        {loading && <BoldText>Loading...</BoldText>}
        {error && <BoldText>A network error occured.</BoldText>}
        <QueryPaginator
          itemsTotal={data?.value?.itemsTotal}
          itemsRetrieved={data?.value?.itemsRetrieved}
        />
      </TableFooter>
    </Table>
  );
}

export default PayRunsTable;
