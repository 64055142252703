import React from "react";
import {
  PageLoading,
  Panel,
  Table,
  PanelDialog,
  TableBody,
  BoldText,
  TableRow,
  PanelHeading,
  Label,
  PanelRow,
  Link,
} from "@backslashbuild/pp-ui";
import { useGetHolidayYearOverrides } from "./api";
import { isTextNullOrEmpty, utcToYmd } from "./utils";

import NewHolidayYearOverrideForm from "./NewHolidayYearOverrideForm";
import DeleteHolidayYearOverrideButton from "./DeleteHolidayYearOverrideButton";

export default function EmploymentHolidayYearOverridesPanel({ employmentId }) {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const { data, loading, error } = useGetHolidayYearOverrides({ employmentId });

  if (loading) return <PageLoading />;
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (!data?.isSuccess) return data?.errors?.map((error) => <div>{error}</div>);

  console.log(data);
  return (
    <>
      <Panel>
        <PanelHeading rightAction={<Link onClick={() => setDialogOpen(true)}>Add</Link>}>
          Holiday Year Overrides
        </PanelHeading>
        <Table>
          <TableBody>
            {data?.value?.items?.length < 1 && <PanelRow>No overrides set.</PanelRow>}
            {data?.value?.items?.map((item) => (
              <TableRow disableHover>
                <PanelRow>
                  <Label label={`${item?.holidayYear} start date`}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <BoldText>{utcToYmd(item?.startDate)}</BoldText>
                      <DeleteHolidayYearOverrideButton
                        id={item?.id}
                        onClose={() => setDialogOpen(false)}
                      />
                    </div>
                  </Label>
                </PanelRow>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Panel>

      <PanelDialog open={dialogOpen}>
        <NewHolidayYearOverrideForm
          employmentId={employmentId}
          onClose={() => {
            setDialogOpen(false);
          }}
        />
      </PanelDialog>
    </>
  );
}
