import React from "react";

import {
  Content,
  PageHeadingArea,
  PageTitle,
  PageHeadingAreaSpacer,
  PanelColumnSet,
  PanelColumn,
  PanelColumnSpacer,
  PanelSpacer,
} from "@backslashbuild/pp-ui";

import RFPsTable from "../modules/payroll/RFPsTable";
import AddRFPDialog from "../modules/payroll/AddRFPDialog";
import AddRFPsToStagedPayrunDialog from "../modules/payroll/AddRFPsToStagedPayrunDialog";
import RFPGroupingTree from "../modules/payroll/RFPGroupingTree";
import RFPFilterPanel from "../modules/payroll/RFPFilterPanel";

function RFPs() {
  return (
    <Content scrollable>
      <PageHeadingArea>
        <PageTitle>Requests for processing</PageTitle>
        <PageHeadingAreaSpacer grow />
        <AddRFPDialog />
        <AddRFPsToStagedPayrunDialog />
      </PageHeadingArea>
      <PanelColumnSet>
        <PanelColumn>
          <RFPFilterPanel />
          <PanelSpacer large />
          {/* <RFPGroupingTree /> */}
        </PanelColumn>
        <PanelColumnSpacer />
        <RFPsTable />
      </PanelColumnSet>
    </Content>
  );
}

export default RFPs;
