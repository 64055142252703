import React from "react";
import { Button } from "@backslashbuild/pp-ui";
import { useParams } from "react-router-dom";
import { useGetContributionScheduleExport } from "./api";

export default function DownloadCSVButton() {
  const { exportId } = useParams();

  const { data, loading, error, queryId } = useGetContributionScheduleExport({
    exportId,
  });

  return (
    <Button
      onClick={() => {
        window.open(
          `${process.env.REACT_APP_API_HOST}/nest/contributions/schedule-exports/${exportId}/download-csv`,
          "_blank",
          "noopener,noreferrer"
        );
      }}
      style={{ marginRight: 10 }}
      variant="secondary"
      disabled={!data?.value?.readyToDownload}
    >
      Download CSV
    </Button>
  );
}
