import React from "react";
import { PopoverPicker, Link } from "@backslashbuild/pp-ui";
import { useLazyGetAddresses, useUpdateAddress } from "./api";

function AddressPicker({ id }) {
  const [open, setOpen] = React.useState(false);
  const [loadAddresses, { data, loading, error }] = useLazyGetAddresses({ id });
  const updateAddress = useUpdateAddress({ id });

  return (
    <PopoverPicker
      loading={loading}
      error={error || !data?.isSuccess ? JSON.stringify(error) : null}
      open={open}
      options={data?.value?.sort((a, b) => sortAddresses(a.name, b.name)) ?? []}
      onClose={() => setOpen(false)}
      onOptionClicked={(o) => {
        updateAddress(o);
        setOpen(false);
      }}
      renderOption={(o) => o.name}
      renderAnchor={(anchorRef) => (
        <Link
          ref={anchorRef}
          onClick={() => {
            setOpen(true);
            loadAddresses();
          }}
        >
          find
        </Link>
      )}
    />
  );
}

export default AddressPicker;

var reA = /[^a-zA-Z]/g;
var reN = /[^0-9]/g;
export function sortAddresses(a, b) {
  var AInt = parseInt(a, 10);
  var BInt = parseInt(b, 10);

  if (isNaN(AInt) && isNaN(BInt)) {
    var aA = a.replace(reA, "");
    var bA = b.replace(reA, "");
    if (aA === bA) {
      var aN = parseInt(a.replace(reN, ""), 10);
      var bN = parseInt(b.replace(reN, ""), 10);
      return aN === bN ? 0 : aN > bN ? 1 : -1;
    } else {
      return aA > bA ? 1 : -1;
    }
  } else if (isNaN(AInt)) {
    //A is not an Int
    return 1; //to make alphanumeric sort first return -1 here
  } else if (isNaN(BInt)) {
    //B is not an Int
    return -1; //to make alphanumeric sort first return 1 here
  } else {
    return AInt > BInt ? 1 : -1;
  }
}
