import React from "react";
import { Button } from "@backslashbuild/pp-ui";
import getApiUrl from "../../getApiUrl";
import { publish } from "../../pubSub";
import { usePost } from "./api";

const apiUrl = getApiUrl();

export default function PrintInvoicesButton({ invoices, children, large }) {
  const post = usePost();

  async function handleClick() {
    if (invoices?.length > 0) {
      const blank = window.open("", "_blank");

      const invoiceNumbers = invoices.map((invoice) => invoice.invoiceNumber);
      const path = `invoiceNumbers=${invoiceNumbers.join("&invoiceNumbers=")}`;

      const result = await post(`documents/sign-request-selected?${path}`);

      if (result?.isSuccess) {
        var { token, fileName } = result.value;

        blank.location = `${apiUrl}/invoicing/documents/view-selected/${fileName}?${path}&token=${token}`;
      } else {
        let error = `Error - could not open pdf.`;
        if (typeof result?.errors == "string") error = result.error;
        else if (Array.isArray(result?.errors)) error = result.errors;
        publish("notification", error);
        blank.close();
      }
    }
  }

  return (
    <Button large={large} onClick={handleClick}>
      {children}
    </Button>
  );
}
