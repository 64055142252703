import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  TableRow,
  Table,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableFooter,
  TableBody,
  BoldText,
  Pill,
} from "@backslashbuild/pp-ui";
import { Link as RouterLink } from "react-router-dom";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";
import { useGet } from "./api";
import { utcToLocalText, formatCurrency } from "../../utils";
import queryString from "query-string";
import Paginator from "../../containers/Paginator";
import { StatusIndicator } from "@backslashbuild/pp-ui/dist/index.es";

const c = {
  employee: 300,
  taxWeek: 100,
  taxCode: 100,
  hours: 100,
  days: 100,
  gross: 100,
  invoiceTotal: 100,
  holidayAccrued: 100,
  holidayClaimed: 100,
  payeTax: 100,
  niNumber: 100,
  employeePens: 100,
  banked: 100,
  issues: 100,
  issuesIgnored: 100,
  status: 100,
};

const totalWidth = Object.keys(c).reduce((total, key) => (total += c[key]), 0);

function PureTable({ data, queryId }) {
  return React.useMemo(() =>
    data.value.items.map(
      (payslip) => (
        <TableRow key={payslip.id} component={RouterLink} to={`/payslip/${payslip.id}`}>
          <TableCell width={c.employee}>
            {payslip.employment?.employeeNumber} {payslip.employment?.employeeName}
          </TableCell>
          <TableCell width={c.taxWeek}>{payslip.taxWeek}</TableCell>
          <TableCell width={c.taxCode}>{payslip.taxCode}</TableCell>
          <TableCell width={c.hours}>{payslip.totalHours}</TableCell>
          <TableCell width={c.days}>{payslip.totalDays}</TableCell>
          <TableCell width={c.gross}>{payslip.grossTaxablePay}</TableCell>
          <TableCell width={c.invoiceTotal}>{formatCurrency(payslip.invoiceTotal)}</TableCell>
          <TableCell width={c.holidayAccrued}>{payslip.holidayAccrued}</TableCell>
          <TableCell width={c.holidayClaimed}>{payslip.holidayClaimed}</TableCell>
          <TableCell width={c.payeTax}>{formatCurrency(payslip.payeTax)}</TableCell>
          <TableCell width={c.niNumber}>{payslip.niNumber}</TableCell>
          <TableCell width={c.employeePens}>{formatCurrency(payslip.employeePension)}</TableCell>
          <TableCell width={c.banked}>{formatCurrency(payslip.banked)}</TableCell>
          <TableCell width={c.issues}>
            {payslip?.errors?.length > 0 && <Pill status="error">{payslip.errors.length}</Pill>}{" "}
            {payslip?.warnings?.length > 0 && (
              <Pill status="warning">{payslip.warnings.length}</Pill>
            )}
          </TableCell>
          <TableCell width={c.issuesIgnored}>
            {payslip?.warnings?.length > 0 && <StatusIndicator value={payslip.ignoreWarnings} />}
          </TableCell>
          <TableCell width={c.status}>
            {payslip.isFinalised
              ? `Finalised at ${utcToLocalText(payslip.finalisedAt)}`
              : payslip.isCancelled
              ? `Cancelled at ${utcToLocalText(payslip.cancelledAt)}`
              : "Staged"}
          </TableCell>
        </TableRow>
      ),
      [queryId]
    )
  );
}

function TableSkeleton() {
  return (
    <TableRow>
      <TableCell width={c.employee}>-</TableCell>
      <TableCell width={c.taxWeek}>-</TableCell>
      <TableCell width={c.taxCode}>-</TableCell>
      <TableCell width={c.hours}>-</TableCell>
      <TableCell width={c.days}>-</TableCell>
      <TableCell width={c.gross}>-</TableCell>
      <TableCell width={c.invoiceTotal}>-</TableCell>
      <TableCell width={c.holidayAccrued}>-</TableCell>
      <TableCell width={c.holidayClaimed}>-</TableCell>
      <TableCell width={c.payeTax}>-</TableCell>
      <TableCell width={c.niNumber}>-</TableCell>
      <TableCell width={c.employeePens}>-</TableCell>
      <TableCell width={c.banked}>-</TableCell>
      <TableCell width={c.issues}>-</TableCell>
      <TableCell width={c.issuesIgnored}>-</TableCell>
      <TableCell width={c.status}>-</TableCell>
    </TableRow>
  );
}

function formatYesNo(yesno) {
  if (yesno === "yes") return true;
  if (yesno === "no") return false;
  return undefined;
}

function PayslipsTable({
  companyId,
  contractorId,
  employmentId,
  payRunId,
  useStatePaginator,
  take = 50,
}) {
  const location = useLocation();
  const queryPagingString = usePagination(location.search);
  const [statePaging, setStatePaging] = React.useState({ skip: 0, take });

  const queryPaging = queryString.parse(queryPagingString);

  const stringifiedQuery = queryString.stringify({
    companyId,
    employmentId,
    contractorId,
    payRunId,
    ...(useStatePaginator
      ? statePaging
      : {
          ...queryPaging,
          hasErrors: formatYesNo(queryPaging.hasErrors),
          hasWarnings: formatYesNo(queryPaging.hasWarnings),
        }),
  });

  const { data, loading, error, queryId } = useGet(`payslips?${stringifiedQuery}`);

  const isLoaded = Boolean(data?.value);

  const ScrollableHeaderRow = TableRow;

  return (
    <Table standardTable>
      <TableBody fullPageTable={true}>
        <ScrollableHeaderRow>
          <TableHeaderColumn width={c.employee}>Employee</TableHeaderColumn>
          <TableHeaderColumn width={c.taxWeek}>Week</TableHeaderColumn>
          <TableHeaderColumn width={c.taxCode}>Tax Code</TableHeaderColumn>
          <TableHeaderColumn width={c.hours}>Hours</TableHeaderColumn>
          <TableHeaderColumn width={c.days}>Days</TableHeaderColumn>
          <TableHeaderColumn width={c.gross}>Gross</TableHeaderColumn>
          <TableHeaderColumn width={c.invoiceTotal}>Invoice Total</TableHeaderColumn>
          <TableHeaderColumn width={c.holidayAccrued}>Holiday Accrued</TableHeaderColumn>
          <TableHeaderColumn width={c.holidayClaimed}>Holiday Claimed</TableHeaderColumn>
          <TableHeaderColumn width={c.payeTax}>PAYE</TableHeaderColumn>
          <TableHeaderColumn width={c.payeTax}>NI number</TableHeaderColumn>
          <TableHeaderColumn width={c.employeePens}>EE Pens</TableHeaderColumn>
          <TableHeaderColumn width={c.banked}>Banked</TableHeaderColumn>
          <TableHeaderColumn width={c.issues}>Issues</TableHeaderColumn>
          <TableHeaderColumn width={c.issuesIgnored}>Ignore Issues</TableHeaderColumn>
          <TableHeaderColumn width={c.status}>Status</TableHeaderColumn>
        </ScrollableHeaderRow>
        {isLoaded ? (
          <PureTable data={data} queryId={queryId} />
        ) : data?.errors ? (
          data?.errors.map((error) => <div>{error}</div>)
        ) : (
          <TableSkeleton />
        )}
      </TableBody>
      <TableFooter>
        {loading && <BoldText>Loading...</BoldText>}
        {error && <BoldText>A network error occured.</BoldText>}
        {useStatePaginator ? (
          <Paginator
            itemsTotal={data?.value?.itemsTotal}
            itemsRetrieved={data?.value?.itemsRetrieved}
            skip={statePaging.skip}
            take={statePaging.take}
            onPrev={() =>
              setStatePaging({
                ...statePaging,
                skip: Math.max(0, statePaging.skip - statePaging.take),
              })
            }
            onNext={() =>
              setStatePaging({ ...statePaging, skip: statePaging.skip + statePaging.take })
            }
            setStatePaging
          />
        ) : (
          <QueryPaginator
            itemsTotal={data?.value?.itemsTotal}
            itemsRetrieved={data?.value?.itemsRetrieved}
          />
        )}
      </TableFooter>
    </Table>
  );
}

export default PayslipsTable;
