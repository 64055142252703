import React from "react";
import {
  Panel,
  PageLoading,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from "@backslashbuild/pp-ui";
import { useGet, useGetStatPays } from "./api";
import DeleteStatutoryPayButton from "./DeleteStatutoryPayButton";
import { isTextNullOrEmpty, utcToYmd } from "./utils";
import { formatCurrency } from "../../utils";
import CreateStatPayRFPButton from "./CreateStatPayRFPButton";
import RFPStatusPill from "./RFPStatusPill";

export default function StatutoryPaysTable({ statutoryPayGroupId }) {
  const { data, loading, error, refetch } = useGetStatPays({ statutoryPayGroupId });

  if (loading) return <PageLoading />;

  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

  if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  return (
    <Panel maxWidth="100%">
      <Table>
        <TableHeader>
          <TableHeaderColumn width="100%">Statutory Payments</TableHeaderColumn>
        </TableHeader>
        <TableHeader>
          <TableHeaderColumn width="10%">Date Due</TableHeaderColumn>
          <TableHeaderColumn width="50%">Description</TableHeaderColumn>
          <TableHeaderColumn width="10%">Number of Days</TableHeaderColumn>
          <TableHeaderColumn width="10%">Amount</TableHeaderColumn>
          <TableHeaderColumn width="10%">RFP</TableHeaderColumn>
          <TableHeaderColumn width="10%">Actions</TableHeaderColumn>
        </TableHeader>
        <TableBody>
          {data.value.items.map((item) => (
            <TableRow>
              <TableCell width="10%">{utcToYmd(item?.dateDue)}</TableCell>
              <TableCell width="50%">{item?.description}</TableCell>
              <TableCell width="10%">{item?.numberOfDays}</TableCell>
              <TableCell width="10%">{formatCurrency(item?.amountInMoney)}</TableCell>
              <TableCell width="10%">
                {item?.requestForProcessingId ? (
                  <RFPStatusPill reference={item?.requestForProcessingId} />
                ) : (
                  <CreateStatPayRFPButton statutoryPayId={item?.id} />
                )}
              </TableCell>
              <TableCell width="10%">
                <DeleteStatutoryPayButton statutoryPayId={item?.id} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Panel>
  );
}
