import React from "react";
import { makeStyles, BoldText } from "@backslashbuild/pp-ui";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    flexGrow: 1,
    padding: "4px 16px",
    fontSize: 12,
    borderTop: "1px solid #d8d8d8",
    borderLeft: "1px solid #d8d8d8",
    borderRight: "1px solid #d8d8d8",
    background: "#f3f9fa",
    display: "flex",
    alignItems: "center",
    height: "46px",
  },
}));

export default function CustomTableCell({ children }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <BoldText>{children}</BoldText>
    </div>
  );
}
