import React from "react";
import { useHistory } from "react-router-dom";
import { FeatureFlag } from "react-unleash-flags";
import {
  Content,
  PanelColumnSpacer,
  PanelColumn,
  PageHeadingArea,
  PanelColumnSet,
  SidebarWrapper,
  BackArrow,
  PageHeadingAreaSpacer,
  PageTitle,
  PanelSpacer,
} from "@backslashbuild/pp-ui";

import FpsFilter from "../modules/hmrc/FpsFilter";
import FpsTable from "../modules/hmrc/FpsTable";
import DraftFpsTable from "../modules/hmrc/DraftFpsTable";

export default function ViewFPSs() {
  const history = useHistory();

  return (
    <SidebarWrapper>
      <Content scrollable>
        <PageHeadingArea>
          <BackArrow onClick={() => history.goBack()} />
          <PageHeadingAreaSpacer />
          <PageTitle>Full Payment Submissions</PageTitle>
          <PageHeadingAreaSpacer grow />
        </PageHeadingArea>
        <PanelColumnSet>
          <PanelColumn width={320}>
            <FpsFilter />
            <FeatureFlag name="unreleased">
              <PanelSpacer />
              <PanelSpacer />
              <DraftFpsTable />
            </FeatureFlag>
          </PanelColumn>
          <PanelColumnSpacer />
          <FpsTable />
        </PanelColumnSet>
      </Content>
    </SidebarWrapper>
  );
}
