import React from "react";
import {
  DropdownOption,
  PanelDialog,
  PanelHeading,
  PanelActions,
  PanelRow,
  Button,
  PanelSpacer,
  BoldText,
  Dropdown,
} from "@backslashbuild/pp-ui";
import { usePost, useGet } from "./api";
import { publish } from "../../pubSub";
import { useLocation, Link as RouterLink } from "react-router-dom";
import queryString from "query-string";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";
import { textToBoolean } from "./utils";

export default function AddRFPsToStagedPayrunDialog() {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  function handleCancel() {
    setDialogOpen(false);
  }

  return (
    <>
      <div style={{ marginLeft: 10 }}>
        <Button large onClick={() => setDialogOpen(true)}>
          Add to Payrun
        </Button>
      </div>
      <PanelDialog onClose={handleCancel} open={dialogOpen}>
        <PanelHeading>Add RFPs to Staged Payrun</PanelHeading>
        <PanelSpacer />
        <AddRFPsToStagedPayrunForm onCancel={handleCancel} />
        <PanelSpacer />
      </PanelDialog>
    </>
  );
}

function PayrunPicker({ payrunId, setPayrunId }) {
  const { data, loading, error, queryId } = useGet("payruns?staged=true");

  console.log("valuess", data);
  if (loading) return <div>Loading...</div>;
  if (data?.value?.items?.length > 0) {
    return (
      <Dropdown value={payrunId} onChange={(e) => setPayrunId(e.target.value)}>
        <DropdownOption value="">Choose:</DropdownOption>
        {data?.value?.items?.map((x) => (
          <DropdownOption
            value={x.id}
          >{`Pay run ${x.payRunNumber} - (${x.taxYear} w${x.taxWeek})`}</DropdownOption>
        ))}
      </Dropdown>
    );
  }

  return <div>No staged payrun available.</div>;
}

function AddRFPsToStagedPayrunForm({ onCancel }) {
  const post = usePost("rfps/add-to-payrun");
  const [payrunId, setPayrunId] = React.useState(null);

  const location = useLocation();
  const search = usePagination(location.search);
  const parsedQueryString = queryString.parse(search);
  const { data, loading, error, queryId } = useGet(
    `rfps?${queryString.stringify({ ...parsedQueryString })}`
  );

  async function handleSubmit() {
    const result = await post({
      payrunId,
      filters: {
        ...parsedQueryString,
        statuses: Array(parsedQueryString?.statuses),
        isDue: textToBoolean(parsedQueryString?.isDue),
      },
    });
    if (!result.isSuccess) {
      var errors = result.errors;
      publish(
        "notification",
        <ul>
          {errors.map((error) => {
            return <li>{error}</li>;
          })}
        </ul>
      );
      return;
    }
    const id = result.value;
    publish("notification", "RFPs added to payrun");
    onCancel();
  }

  console.log(parsedQueryString);
  return (
    <>
      <PanelRow>
        <BoldText>{`Select payrun to add ${data?.value?.items?.length} RFPs to:`}</BoldText>
      </PanelRow>
      <PanelRow>
        <PayrunPicker payrunId={payrunId} setPayrunId={setPayrunId} />
      </PanelRow>
      <PanelSpacer />
      <PanelActions>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="primary" disabled={!payrunId} onClick={handleSubmit}>
          {`Lock ${data?.value?.items?.length} RFPs to Payrun`}
        </Button>
      </PanelActions>
    </>
  );
}
