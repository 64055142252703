import React from "react";
import { useLocation, useHistory, Link as RouterLink } from "react-router-dom";
import {
  TableRow,
  Table,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableFooter,
  TableBody,
  BoldText,
  Link,
} from "@backslashbuild/pp-ui";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";
import { useGet } from "./api";
import { formatCurrency } from "../../utils";
import { DateTime } from "luxon";
import CancelRFPButton from "./CancelRFPButton";
import queryString from "query-string";

const c = {
  id: 50,
  taxWeek: 100,
  reference: 100,
  schedule: 100,
  employment: 100,
  type: 100,
  company: 120,
  adjustmentType: "taxable_pay",
  payRun: 100,
  payslip: 100,
  cancel: 140,
  isLocked: false,
  isFinalised: false,
  isPartialResolutionAllowed: false,
  finalisedByPayRunId: null,
  finalisedOnPayslipId: null,
  requestedAmount: 200,
  hours: 80,
  finalisedAmount: 200,
  description: 300,
  dateCreated: "2020-08-06T11:32:59.398244",
  onOrAfterDate: "2020-08-06T11:32:59.398244",
};
const totalWidth = Object.keys(c).reduce((total, key) => (total += c[key]), 0);

function PureTable({ data, queryId }) {
  return React.useMemo(() =>
    data.value.items.map(
      (rfp) => (
        <TableRow backgroundColor={rfp.isCancelled ? "rgb(208,223,223)" : "transparent"}>
          <TableCell width={c.id}>{rfp.id}</TableCell>
          <TableCell width={c.company}>{rfp.company?.name}</TableCell>
          <TableCell width={c.employment}>{rfp.employment?.employeeNumber}</TableCell>
          <TableCell width={c.schedule}>
            {rfp.scheduleId && (
              <Link component={RouterLink} to={`/schedule/${rfp.scheduleId}`}>
                Schedule
              </Link>
            )}
          </TableCell>
          <TableCell width={c.taxWeek}>-</TableCell>
          <TableCell width={c.onOrAfterDate}>
            {DateTime.fromISO(rfp.onOrAfterDate).toFormat("dd/MM/yyyy")}
          </TableCell>
          <TableCell width={c.type}>{rfp.type?.name}</TableCell>
          <TableCell width={c.description}>{rfp.description}</TableCell>
          <TableCell alignRight width={c.requestedAmount}>
            {formatCurrency(rfp.requestedAmount)}
          </TableCell>
          <TableCell alignRight width={c.finalisedAmount}>
            <BoldText>{rfp.isFinalised ? formatCurrency(rfp.finalisedAmount) : ""}</BoldText>
          </TableCell>
          <TableCell alignRight width={c.hours}>
            {rfp.hours || "-"}
          </TableCell>
          <TableCell width={c.payRun}>
            {rfp.lockedOnPayRunId ? (
              <Link component={RouterLink} to={`/payrun/${rfp.lockedOnPayRunId}`}>
                Pay Run
              </Link>
            ) : (
              "-"
            )}
          </TableCell>
          <TableCell width={c.payslip}>
            {rfp.lockedOnPayslipId ? (
              <Link component={RouterLink} to={`/payslip/${rfp.lockedOnPayslipId}`}>
                Payslip
              </Link>
            ) : (
              "-"
            )}
          </TableCell>
          <TableCell width={c.cancel}>
            {!rfp.lockedOnPayslipId &&
            !rfp.lockedOnPayRunId &&
            !rfp.isLocked &&
            !rfp.isCancelled ? (
              <CancelRFPButton reference={rfp.reference} />
            ) : rfp.isCancelled ? (
              "Cancelled"
            ) : (
              "-"
            )}
          </TableCell>
        </TableRow>
      ),
      [queryId]
    )
  );
}

function TableSkeleton() {
  return (
    <TableRow>
      <TableCell width={c.id}>-</TableCell>
      <TableCell width={c.company}>-</TableCell>
      <TableCell width={c.employment}>-</TableCell>
      <TableCell width={c.schedule}>-</TableCell>
      <TableCell width={c.taxWeek}>-</TableCell>
      <TableCell width={c.onOrAfterDate}>-</TableCell>
      <TableCell width={c.type}>-</TableCell>
      <TableCell width={c.description}>-</TableCell>
      <TableCell alignRight width={c.requestedAmount}>
        -
      </TableCell>
      <TableCell alignRight width={c.finalisedAmount}>
        -
      </TableCell>
      <TableCell alignRight width={c.hours}>
        -
      </TableCell>
      <TableCell width={c.payRun}>-</TableCell>
      <TableCell width={c.payslip}>-</TableCell>
      <TableCell width={c.cancel}>-</TableCell>
    </TableRow>
  );
}

function RFPsTable() {
  const location = useLocation();
  const search = usePagination(location.search);
  const parsedQueryString = queryString.parse(search);
  const { data, loading, error, queryId } = useGet(
    `rfps?${queryString.stringify({ ...parsedQueryString })}`
  );

  const isLoaded = Boolean(data?.value);

  return (
    <Table standardTable width={totalWidth}>
      <TableHeader>
        <TableHeaderColumn width={c.id}>ID</TableHeaderColumn>
        <TableHeaderColumn width={c.company}>Company</TableHeaderColumn>
        <TableHeaderColumn width={c.employment}>Employment</TableHeaderColumn>
        <TableHeaderColumn width={c.employment}>Schedule</TableHeaderColumn>
        <TableHeaderColumn width={c.taxWeek}>Week</TableHeaderColumn>
        <TableHeaderColumn width={c.onOrAfterDate}>Due</TableHeaderColumn>
        <TableHeaderColumn width={c.type}>Type</TableHeaderColumn>
        <TableHeaderColumn width={c.description}>Description</TableHeaderColumn>
        <TableHeaderColumn alignRight width={c.requestedAmount}>
          Requested
        </TableHeaderColumn>
        <TableHeaderColumn alignRight width={c.finalisedAmount}>
          Finalised
        </TableHeaderColumn>
        <TableHeaderColumn alignRight width={c.hours}>
          Hours
        </TableHeaderColumn>
        <TableHeaderColumn width={c.payRun}>Pay Run</TableHeaderColumn>
        <TableHeaderColumn width={c.payslip}>Payslip</TableHeaderColumn>
        <TableHeaderColumn width={c.cancel}>Cancel</TableHeaderColumn>
      </TableHeader>
      <TableBody fullPageTable={true} footerHeight={TableFooter.height}>
        {isLoaded ? (
          <PureTable data={data} queryId={queryId} />
        ) : data?.errors ? (
          data?.errors.map((error) => <div>{error}</div>)
        ) : (
          <TableSkeleton />
        )}
      </TableBody>
      <TableFooter>
        {loading && <BoldText>Loading...</BoldText>}
        {error && <BoldText>A network error occured.</BoldText>}
        <QueryPaginator
          itemsTotal={data?.value?.itemsTotal}
          itemsRetrieved={data?.value?.itemsRetrieved}
        />
      </TableFooter>
    </Table>
  );
}

export default RFPsTable;
