import { Label, Link, PanelRow } from "@backslashbuild/pp-ui";
import React from "react";
import { useGetCurrentHolidayYear } from "./api";
import { isTextNullOrEmpty } from "./utils";
import { Link as RouterLink } from "react-router-dom";
import { PanelDialog } from "@backslashbuild/pp-ui/dist/index.es";
import EmploymentHolidayYearOverridesPanel from "./EmploymentHolidayYearOverridesPanel";

export default function EmploymentCurrentHolidayYear({ contractorProductId }) {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const { data, loading, error } = useGetCurrentHolidayYear({ contractorProductId });

  if (loading) return <div>Loading</div>;

  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

  if (data != null && !data?.isSuccess) {
    return data?.errors?.map((error) => <div>{error}</div>);
  }

  return (
    <>
      <PanelRow>
        <Label label="Holiday Year">
          <Link onClick={() => setDialogOpen(true)}>{`${data?.value?.year}`}</Link>
        </Label>
      </PanelRow>
      <PanelDialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <EmploymentHolidayYearOverridesPanel employmentId={contractorProductId} />
      </PanelDialog>
    </>
  );
}
