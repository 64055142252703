import React from "react";
import { Content } from "@backslashbuild/pp-ui";
import PayslipsTable from "../modules/payslip-reporting/PayslipsTable";

export default function PayslipReportingPayslips() {
  return (
    <Content scrollable>
      <PayslipsTable />
    </Content>
  );
}
