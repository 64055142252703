import React, { useState } from "react";
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableFooter,
  PageLoading,
  PageHeadingArea,
  PageTitle,
  PageHeadingAreaSpacer,
  PanelDialog,
  StatusIndicator,
  Button,
} from "@backslashbuild/pp-ui";
import { utcToLocalText, isTextNullOrEmpty, formatCurrency } from "../../utils";
import { useGetItems } from "./api";
import { useLocation, useHistory } from "react-router-dom";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";

import AddAWSPromoCodeForm from "./AddAWSPromoCodeForm";
import EditAnytimeWagePromotionalCodeForm from "./EditAnytimeWagePromotionalCodeForm";

function PureTable({ data, queryId, onClick }) {
  const history = useHistory();

  const getStatus = (contractorAnytimePromotionalCode) => {
    if (contractorAnytimePromotionalCode.usedAt) {
      return "Used";
    } else if (new Date(contractorAnytimePromotionalCode.expiresAt) < new Date()) {
      return "Expired";
    } else {
      return utcToLocalText(contractorAnytimePromotionalCode.expiresAt);
    }
  };

  return React.useMemo(
    () =>
      data.value.items.map((contractorAnytimePromotionalCode) => (
        <TableRow onClick={() => onClick(contractorAnytimePromotionalCode)}>
          <TableCell width="7%">{contractorAnytimePromotionalCode.promotionalCode}</TableCell>
          <TableCell width="7%">
            {formatCurrency(contractorAnytimePromotionalCode.advanceCap)}
          </TableCell>
          <TableCell width="7%">{contractorAnytimePromotionalCode.feeType}</TableCell>
          <TableCell width="7%">
            <StatusIndicator
              value={contractorAnytimePromotionalCode.isIgnoreOutstandingAdvancesAndPayslipCount}
            />
          </TableCell>
          <TableCell width="7%">{getStatus(contractorAnytimePromotionalCode)}</TableCell>
        </TableRow>
      )),
    [queryId]
  );
}

function AnytimeWagePromotionalCodeTable({ contractorId }) {
  const location = useLocation();
  const search = usePagination(location.search);
  const { data, loading, error, queryId, refetch } = useGetItems(
    `contractor-anytimeWagePromotionalCodes?contractorId=${contractorId}`
  );
  const [showEditPromotionalCode, setShowEditPromotionalCode] = useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  if (loading || !data) return <PageLoading />;
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  return (
    <>
      <div style={{ height: "auto" }}>
        <PageHeadingArea>
          <PageTitle>Promotional Codes</PageTitle>
          <PageHeadingAreaSpacer grow />
          <Button onClick={() => setDialogOpen(true)}>Create promotional code</Button>
        </PageHeadingArea>
        <Table standardTable>
          <TableHeader>
            <TableHeaderColumn width="7%">Promotional code</TableHeaderColumn>
            <TableHeaderColumn width="7%">Advance cap</TableHeaderColumn>
            <TableHeaderColumn width="7%">Fee type</TableHeaderColumn>
            <TableHeaderColumn width="7%">
              Ignore Outstanding Advances and Payslip Count
            </TableHeaderColumn>
            <TableHeaderColumn width="7%">Status</TableHeaderColumn>
          </TableHeader>
          <TableBody>
            <PureTable
              data={data}
              queryId={queryId}
              onClick={(contractorAnytimePromotionalCode) =>
                setShowEditPromotionalCode(contractorAnytimePromotionalCode)
              }
            />
          </TableBody>
          <TableFooter>
            <QueryPaginator
              itemsTotal={data?.value?.itemsTotal}
              itemsRetrieved={data?.value?.itemsRetrieved}
            />
          </TableFooter>
        </Table>

        <PanelDialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
          {dialogOpen && (
            <AddAWSPromoCodeForm
              id={contractorId}
              onClose={() => {
                setDialogOpen(false);
                setTimeout(() => {
                  refetch();
                }, 1000);
              }}
            />
          )}
        </PanelDialog>

        <PanelDialog
          open={showEditPromotionalCode}
          onClose={() => setShowEditPromotionalCode(false)}
        >
          <EditAnytimeWagePromotionalCodeForm
            contractorPromotionalCodeId={showEditPromotionalCode.id}
            onClose={() => setShowEditPromotionalCode(false)}
          />
        </PanelDialog>
      </div>
    </>
  );
}

export default AnytimeWagePromotionalCodeTable;
