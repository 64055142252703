import React from "react";
import { DateTime } from "luxon";

export default function CommsItem({ name, dateCreated, topic, subject, description, action }) {
  return (
    <div style={{ padding: 10, border: "1px solid #CED8E2", margin: "4px" }}>
      <div
        style={{
          borderBottom: "1px solid rgba(224,224,224,1)",
          paddingBottom: "4px",
          marginBottom: "4px",
        }}
      >
        <small>
          {name} &middot; {DateTime.fromISO(dateCreated).toFormat("dd/MM/yyyy HH:mm")}
        </small>
      </div>
      {topic}
      {topic && <br />}
      {subject}
      {subject && <br />}
      {description}
      {description && <br />}
      {action}
    </div>
  );
}
