import React from "react";
import {
  Button,
  PanelDialog,
  PanelHeading,
  PanelRow,
  PanelSpacer,
  PanelActions,
  BoldText,
} from "@backslashbuild/pp-ui";
import { usePost, usePostBlob } from "./api";
import { publish } from "../../pubSub";
import { useHistory } from "react-router-dom";

function DownloadBACSFileButton({ id }) {
  const history = useHistory();

  // you have to query to see if there are any currently staged pay runs and then disable the button.
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const downloadBACS = usePostBlob("payruns/generate-bacs", `bacs-for-payrun-${id}.csv`);

  async function handleSubmit() {
    try {
      const response = await downloadBACS({ payRunId: id });
      publish("notification", "Saving BACS file");
    } catch (e) {
      console.error(e, "error downloading BACS file");
      publish("notification", "A network error occured.");
    }
  }

  return (
    <>
      <Button onClick={() => setDialogOpen(true)}>Download BACS File</Button>
      <PanelDialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <PanelHeading>Download BACS File</PanelHeading>
        <PanelSpacer large />
        <PanelRow>
          <BoldText>
            This downloads a BACS file for all payslips in this pay run that have not been
            cancelled. The file will be downloaded to your browser.
            <br />
            <br />
            You can do this action as many times as you want.
          </BoldText>
        </PanelRow>
        <PanelSpacer large />
        <PanelActions>
          <Button variant="secondary" onClick={() => setDialogOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleSubmit}>Download BACS File</Button>
        </PanelActions>
        <PanelSpacer large />
      </PanelDialog>
    </>
  );
}

export default DownloadBACSFileButton;
