import React from "react";
import { useLocation, useHistory, Link as RouterLink } from "react-router-dom";
import {
  TableRow,
  Table,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableFooter,
  TableBody,
  BoldText,
  Link,
} from "@backslashbuild/pp-ui";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";
import { useGet } from "./api";
import { formatCurrency } from "../../utils";

const c = {
  id: 50,
  taxWeek: 100,
  reference: 100,
  schedule: 100,
  employment: 100,
  type: 100,
  company: 120,
  adjustmentType: "taxable_pay",
  payRun: 100,
  payslip: 100,
  isLocked: false,
  isFinalised: false,
  isPartialResolutionAllowed: false,
  finalisedByPayRunId: null,
  finalisedOnPayslipId: null,
  requestedAmount: 200,
  hours: 80,
  finalisedAmount: 200,
  description: 300,
  dateCreated: "2020-08-06T11:32:59.398244",
};
const totalWidth = Object.keys(c).reduce((total, key) => (total += c[key]), 0);

function PureTable({ data, queryId }) {
  return React.useMemo(() =>
    data.value.items.map(
      (fps) => (
        <TableRow component={RouterLink} to={`/fps/${fps.id}`}>
          <TableCell width={c.id}>{fps.id}</TableCell>
          <TableCell width={c.company}>{fps.company?.name}</TableCell>
          <TableCell width={c.employment}>-</TableCell>
        </TableRow>
      ),
      [queryId]
    )
  );
}

function TableSkeleton() {
  return (
    <TableRow>
      <TableCell width={c.id}>-</TableCell>
      <TableCell width={c.company}>-</TableCell>
      <TableCell width={c.taxWeek}>-</TableCell>
    </TableRow>
  );
}

function RFPsTable() {
  const { data, loading, error, queryId } = useGet("fps");

  const isLoaded = Boolean(data?.value);

  return (
    <Table standardTable width={totalWidth}>
      <TableHeader>
        <TableHeaderColumn> AccNo </TableHeaderColumn>
        <TableHeaderColumn> NiNumber </TableHeaderColumn>
        <TableHeaderColumn> Title </TableHeaderColumn>
        <TableHeaderColumn> Surname </TableHeaderColumn>
        <TableHeaderColumn> FirstName </TableHeaderColumn>
        <TableHeaderColumn> MiddleNames </TableHeaderColumn>
        <TableHeaderColumn> DOB </TableHeaderColumn>
        <TableHeaderColumn> Gender </TableHeaderColumn>
        <TableHeaderColumn> Address1 </TableHeaderColumn>
        <TableHeaderColumn> Address2 </TableHeaderColumn>
        <TableHeaderColumn> Address3 </TableHeaderColumn>
        <TableHeaderColumn> Town </TableHeaderColumn>
        <TableHeaderColumn> PostCode </TableHeaderColumn>
        <TableHeaderColumn> StartDate </TableHeaderColumn>
        <TableHeaderColumn> StartDeclaration </TableHeaderColumn>
        <TableHeaderColumn> GotStudentLoan </TableHeaderColumn>
        <TableHeaderColumn> GotPostGradLoan </TableHeaderColumn>
        <TableHeaderColumn> PayrollID </TableHeaderColumn>
        <TableHeaderColumn> IrregularEmployment </TableHeaderColumn>
        <TableHeaderColumn> EndDate </TableHeaderColumn>
        <TableHeaderColumn> GrossYTD </TableHeaderColumn>
        <TableHeaderColumn> TaxYTD </TableHeaderColumn>
        <TableHeaderColumn> StudentLoanYTD </TableHeaderColumn>
        <TableHeaderColumn> PostGradYTD </TableHeaderColumn>
        <TableHeaderColumn> PayFrequency </TableHeaderColumn>
        <TableHeaderColumn> PaymentDate </TableHeaderColumn>
        <TableHeaderColumn> TaxWeek </TableHeaderColumn>
        <TableHeaderColumn> TaxMonth </TableHeaderColumn>
        <TableHeaderColumn> HoursWorked </TableHeaderColumn>
        <TableHeaderColumn> taxcode </TableHeaderColumn>
        <TableHeaderColumn> TaxRegime </TableHeaderColumn>
        <TableHeaderColumn> W1M1 </TableHeaderColumn>
        <TableHeaderColumn> TaxablePay </TableHeaderColumn>
        <TableHeaderColumn> NetPay </TableHeaderColumn>
        <TableHeaderColumn> Pension </TableHeaderColumn>
        <TableHeaderColumn> PensionNotNet </TableHeaderColumn>
        <TableHeaderColumn> StudentLoan </TableHeaderColumn>
        <TableHeaderColumn> PlanType </TableHeaderColumn>
        <TableHeaderColumn> PostGradLoan </TableHeaderColumn>
        <TableHeaderColumn> tax </TableHeaderColumn>
        <TableHeaderColumn> PensionYTD </TableHeaderColumn>
        <TableHeaderColumn> PensionNotNetYTD </TableHeaderColumn>
        <TableHeaderColumn> SMPYTD </TableHeaderColumn>
        <TableHeaderColumn> SPPYTD </TableHeaderColumn>
        <TableHeaderColumn> SAPYTD </TableHeaderColumn>
        <TableHeaderColumn> NOPayPeriods </TableHeaderColumn>
        <TableHeaderColumn> niletters </TableHeaderColumn>
        <TableHeaderColumn> NIGross </TableHeaderColumn>
        <TableHeaderColumn> NICategory </TableHeaderColumn>
        <TableHeaderColumn> NIGrossYTD </TableHeaderColumn>
        <TableHeaderColumn> NICLEL </TableHeaderColumn>
        <TableHeaderColumn> NICLELtoPT </TableHeaderColumn>
        <TableHeaderColumn> NICPTover </TableHeaderColumn>
        <TableHeaderColumn> EENIYTD </TableHeaderColumn>
        <TableHeaderColumn> ERNIYTD </TableHeaderColumn>
        <TableHeaderColumn> NIGross2 </TableHeaderColumn>
        <TableHeaderColumn> NICategory2 </TableHeaderColumn>
        <TableHeaderColumn> NIGrossYTD2 </TableHeaderColumn>
        <TableHeaderColumn> NICLEL2 </TableHeaderColumn>
        <TableHeaderColumn> NICLELtoPT2 </TableHeaderColumn>
        <TableHeaderColumn> NICPTover2 </TableHeaderColumn>
        <TableHeaderColumn> EENIYTD2 </TableHeaderColumn>
        <TableHeaderColumn> ERNIYTD2 </TableHeaderColumn>
        <TableHeaderColumn> EENI </TableHeaderColumn>
        <TableHeaderColumn> ERNI </TableHeaderColumn>
        <TableHeaderColumn> EENI2 </TableHeaderColumn>
        <TableHeaderColumn> ERNI2 </TableHeaderColumn>
      </TableHeader>
      <TableBody fullPageTable={true} footerHeight={TableFooter.height}>
        {isLoaded ? (
          <PureTable data={data} queryId={queryId} />
        ) : data?.errors ? (
          data?.errors.map((error) => <div>{error}</div>)
        ) : (
          <TableSkeleton />
        )}
      </TableBody>
      <TableFooter>
        {loading && <BoldText>Loading...</BoldText>}
        {error && <BoldText>A network error occured.</BoldText>}
        <QueryPaginator
          itemsTotal={data?.value?.itemsTotal}
          itemsRetrieved={data?.value?.itemsRetrieved}
        />
      </TableFooter>
    </Table>
  );
}

export default RFPsTable;
