import React from "react";
import LockButton from "./LockButton";
import UnlockButton from "./UnlockButton";
import LockDialog from "./LockDialog";
import UnlockDialog from "./UnlockDialog";

import { PageHeadingAreaSpacer } from "@backslashbuild/pp-ui";

export default function LockBACSRunActions({ bacsRun, refetch }) {
  const [showDialog, setShowDialog] = React.useState(false);

  return (
    <>
      {bacsRun && !bacsRun?.isClosed && !bacsRun?.isLocked && (
        <>
          <LockButton onClick={() => setShowDialog(true)} />
          <PageHeadingAreaSpacer />
          <LockDialog
            open={showDialog}
            bacsRunId={bacsRun?.id}
            onClose={() => {
              setShowDialog(false);
              setTimeout(() => {
                refetch();
              }, 1000);
            }}
          />
        </>
      )}
      {bacsRun && !bacsRun?.isClosed && bacsRun?.isLocked && !bacsRun?.isPaid && (
        <>
          <UnlockButton onClick={() => setShowDialog(true)} />
          <PageHeadingAreaSpacer />
          <UnlockDialog
            open={showDialog}
            bacsRunId={bacsRun?.id}
            onClose={() => {
              setShowDialog(false);
              setTimeout(() => {
                refetch();
              }, 1000);
            }}
          />
        </>
      )}
    </>
  );
}
