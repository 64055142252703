import React from "react";

import {
  Content,
  PanelColumnSet,
  PageTitle,
  PanelColumn,
  PageHeadingArea,
} from "@backslashbuild/pp-ui";

import CreateUserForm from "../modules/identity/CreateUserForm";

export default function UsersCreation() {
  return (
    <Content scrollable>
      <PageHeadingArea>
        <PageTitle>New User</PageTitle>
      </PageHeadingArea>
      <PanelColumnSet>
        <PanelColumn>
          <CreateUserForm />
        </PanelColumn>
      </PanelColumnSet>
    </Content>
  );
}
