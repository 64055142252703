import React from "react";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import getApiUrl from "../../getApiUrl";
import { useQuery, queryCache } from "react-query";
import getBaseUrl from "../../getBaseUrl";

const apiHost = getBaseUrl();
const service = "legacy-integration";

export function useGetItems(path = "") {
  return useGet(`${path}`);
}

export function useGet(cacheKey, path, serviceOverride) {
  if (!path) {
    path = cacheKey;
  }
  if (!serviceOverride) {
    serviceOverride = service;
  }

  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };

  const {
    data,
    error,
    isLoading: loading,
    updatedAt: queryId,
  } = useQuery(cacheKey, async (key, id) => {
    return fetch(`${apiHost}/${serviceOverride}/${path}`, {
      headers: {
        ...authHeaders,
      },
    }).then((r) => r.json());
  });

  const runQuery = () => queryCache.invalidateQueries(path);

  React.useEffect(() => {
    runQuery();
  }, [path]);

  return { data, loading, error, queryId, refetch: runQuery };
}

export function usePost(path) {
  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };

  return React.useCallback(
    (body) => {
      return fetch(`${apiHost}/${service}/${path}`, {
        method: "POST",
        headers: {
          ...authHeaders,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }).then((x) => {
        return x?.json();
      });
    },
    [path, authHeaders.Authorization]
  );
}

export function useUploadAgenciesCsv() {
  const post = usePostFile(`agencies`);

  return React.useCallback((body) => {
    return post(body).then((x) => {
      return x;
    });
  }, []);
}

export function usePostFile(path) {
  const { oidcUser } = useReactOidc();
  const authHeaders = { Authorization: `${oidcUser.token_type} ${oidcUser.access_token}` };

  return React.useCallback(
    (body) => {
      return fetch(`${apiHost}/${service}/${path}`, {
        method: "POST",
        headers: {
          ...authHeaders,
        },
        body,
      }).then((r) => r.json());
    },
    [path, authHeaders.Authorization]
  );
}
