import React from "react";
import QueryFiltering from "../../containers/QueryFiltering";
import { PanelRow, Label, TextInput, DatePicker } from "@backslashbuild/pp-ui";
import { utcToYmd } from "../../utils";

const textInputWidth = 160;

function SyncMessagesFiltering() {
  return (
    <QueryFiltering
      getTagsFromState={(state) => {
        const tags = [];
        if (state.messagePrefix) tags.push({ key: "prefix", value: state.messagePrefix });
        if (state.startDate) tags.push({ key: "from", value: state.startDate });
        if (state.endDate) tags.push({ key: "to", value: state.endDate });

        return tags;
      }}
      getNextStateFromDeleteTag={(state, key) => {
        if (key === "prefix") return { ...state, messagePrefix: undefined };
        if (key === "from") return { ...state, startDate: undefined };
        if (key === "to") return { ...state, endDate: undefined };
        return state;
      }}
      renderForm={(state, setState) => (
        <>
          <PanelRow>
            <Label label="Prefix">
              <TextInput
                value={state.messagePrefix}
                width={textInputWidth}
                onChange={(e) => setState({ ...state, messagePrefix: e.target.value })}
              />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Message Content">
              <TextInput
                value={state.search}
                width={textInputWidth}
                onChange={(e) => setState({ ...state, search: e.target.value })}
              />
            </Label>
          </PanelRow>

          <PanelRow>
            <Label label="Date From">
              <DatePicker
                value={state.startDate ?? ""}
                onChange={(e) => setState({ ...state, startDate: utcToYmd(e) })}
              />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Date To">
              <DatePicker
                value={state.endDate ?? ""}
                width={textInputWidth}
                onChange={(e) => setState({ ...state, endDate: utcToYmd(e) })}
              />
            </Label>
          </PanelRow>
        </>
      )}
    />
  );
}

export default SyncMessagesFiltering;
