import React from "react";

import { Content, PageHeadingArea, PageTitle, PageHeadingAreaSpacer } from "@backslashbuild/pp-ui";

import PayRunsTable from "../modules/payroll/PayRunsTable";
import NewPayRunButton from "../modules/payroll/NewPayRunButton";

function PayRuns() {
  return (
    <Content>
      <PageHeadingArea>
        <PageTitle>Pay Runs</PageTitle>
        <PageHeadingAreaSpacer grow />
        <NewPayRunButton />
      </PageHeadingArea>
      <PayRunsTable />
    </Content>
  );
}

export default PayRuns;
