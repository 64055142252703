import { DateTime, Interval } from "luxon";

/**
 * Generates luxon date times between two ISO formatted date time strings
 * @param {string} start
 * @param {string} end
 */
export default function generateDates(start, end, includeWeekends = false) {
  return Interval.fromDateTimes(
    DateTime.fromISO(start).startOf("day"),
    DateTime.fromISO(end).endOf("day")
  )
    .splitBy({ days: 1 })
    .map((d) => d.start)
    .filter(
      (d) =>
        includeWeekends ||
        (d.toFormat("EEE").toUpperCase() !== "SAT" && d.toFormat("EEE").toUpperCase() !== "SUN")
    );
}
