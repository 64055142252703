import React from "react";

import {
  Content,
  BackArrow,
  PageHeadingArea,
  PageTitle,
  PanelColumnSet,
  PanelColumn,
  Panel,
  BoldText,
  PanelRow,
  Link,
  Label,
  PanelColumnSpacer,
  Table,
  TableHeader,
  TableHeaderColumn,
  PageHeadingAreaSpacer,
  Button,
  PanelSpacer,
  PanelHeading,
  Tree,
  TreeItem,
  Icons,
} from "@backslashbuild/pp-ui";
import PayslipsTable from "../modules/payroll/PayslipsTable";
import PayRunInfoPanel from "../modules/payroll/PayRunInfoPanel";
import { useParams, useHistory, Link as RouterLink } from "react-router-dom";
import PayslipsFilter from "../modules/payroll/PayslipsFilter";
import HasWarningsFilterLink from "../modules/payroll/HasWarningsFilterLink";
import HasErrorsFilterLink from "../modules/payroll/HasErrorsFilterLink";
import PayslipTableFilterLinks from "../modules/payroll/PayslipTableFilterLinks";
import PayRunTitle from "../modules/payroll/PayRunTitle";

function PayRun() {
  const { id } = useParams();
  const history = useHistory();

  return (
    <Content>
      <PageHeadingArea>
        <BackArrow onClick={() => history.goBack()} />
        <PageHeadingAreaSpacer />
        <PageTitle>
          <PayRunTitle id={id} />
        </PageTitle>
        <PageHeadingAreaSpacer grow />
        <PayslipsFilter />
      </PageHeadingArea>
      <PanelColumnSet>
        <PanelColumn>
          <PayRunInfoPanel id={id} />
          <PanelSpacer />
          <Panel>
            <PanelHeading>Quick Filters </PanelHeading>

            <PanelSpacer large />

            <PayslipTableFilterLinks id={id} />
            <PanelSpacer large />
          </Panel>
        </PanelColumn>
        <PanelColumnSpacer />
        <PayslipsTable payRunId={id} />
      </PanelColumnSet>
    </Content>
  );
}

export default PayRun;
