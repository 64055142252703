import React from "react";

import {
  Content,
  PanelColumnSet,
  PageTitle,
  PanelColumn,
  PageHeadingArea,
  PageHeadingAreaSpacer,
  Button,
  BackArrow,
} from "@backslashbuild/pp-ui";
import { useParams, Link as RouterLink, useHistory } from "react-router-dom";
import CreateUserForm from "../modules/identity/CreateUserForm";

export default function UsersCreation() {
  const { id } = useParams();
  const history = useHistory();

  return (
    <Content scrollable>
      <PageHeadingArea>
        <BackArrow onClick={() => history.goBack()} />
        <PageHeadingAreaSpacer />
        <PageTitle>Edit User</PageTitle>
        <PageHeadingAreaSpacer grow />
        <Button large component={RouterLink} to="/create-user">
          Add another user
        </Button>
      </PageHeadingArea>
      <PanelColumnSet>
        <PanelColumn>
          <CreateUserForm isUpdate id={id} />
        </PanelColumn>
      </PanelColumnSet>
    </Content>
  );
}
