import React from "react";

import ExportButton from "./ExportButton";
import ExportDialog from "./ExportDialog";

import { PageHeadingAreaSpacer } from "@backslashbuild/pp-ui";

export default function ExportBACSRunActions({ bacsRun }) {
  const [showDialog, setShowDialog] = React.useState(false);

  return (
    <>
      {bacsRun && !bacsRun?.isClosed && !bacsRun?.isPaid && bacsRun?.isLocked && (
        <>
          <ExportButton onClick={() => setShowDialog(true)} />
          <PageHeadingAreaSpacer />
          <ExportDialog
            open={showDialog}
            bacsRunId={bacsRun?.id}
            onClose={() => {
              setShowDialog(false);
            }}
          />
        </>
      )}
    </>
  );
}
