import { Button } from "@backslashbuild/pp-ui";
import React from "react";

export default function CloseButton({ onClick }) {
  return (
    <Button
      large
      onClick={() => {
        onClick();
      }}
    >
      Close
    </Button>
  );
}
