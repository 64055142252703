import React from "react";
import { useHistory } from "react-router-dom";
import { publish } from "../../pubSub";
import { utcToYmd, makeStateParamCorrectionForUrl, isTextNullOrEmpty } from "./utils";
import { useGetCompanies, usePostDraftFps } from "./api";

import {
  Dropdown,
  DropdownOption,
  Panel,
  PanelRow,
  PanelSpacer,
  Label,
  PanelHeading,
  DatePicker,
  Button,
  PanelActions,
  CheckBox,
  PageLoading,
} from "@backslashbuild/pp-ui";

export default function DraftFpsDialog({ onClose }) {
  const history = useHistory();
  const [isDrafting, setIsDrafting] = React.useState(false);
  const [payload, setPayload] = React.useState({});
  const { data, loading, error, queryId, refetch } = useGetCompanies();
  const createDraftFps = usePostDraftFps();

  async function handleSubmit() {
    setIsDrafting(true);

    try {
      const response = await createDraftFps(payload);

      if (response.isSuccess) {
        publish("notification", "Draft FPS Created");
        history.push(`/hmrc-draft-fps-employees?draftId=${response.value}`);
      } else {
        publish(
          "notification",
          <>
            <ul>
              {response.errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </>
        );
      }
    } catch (error) {
      console.error(error, "error making new draft fps");
      publish("notification", "A network error occured.");
    } finally {
      setIsDrafting(false);
    }
  }

  return (
    <Panel>
      <PanelHeading>Draft a new submission</PanelHeading>
      <PanelSpacer />
      {loading && <PageLoading />}
      {!isTextNullOrEmpty(error) && <div>{String(error)}</div>}
      {!data?.isSuccess && data?.errors?.map((error) => <div>{error}</div>)}
      {data?.isSuccess && (
        <>
          <PanelRow>
            <Label label="Company">
              <Dropdown
                value={payload?.companyId}
                onChange={(e) =>
                  setPayload({
                    ...payload,
                    companyId: makeStateParamCorrectionForUrl(e.target.value),
                  })
                }
              >
                <DropdownOption value={""}>{"Select a company"} </DropdownOption>
                {data?.value?.items?.map((company) => (
                  <DropdownOption value={company.id}>{company.name} </DropdownOption>
                ))}
              </Dropdown>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Tax Date">
              <DatePicker
                value={payload?.taxDate ?? null}
                onChange={(value) => {
                  setPayload({ ...payload, taxDate: utcToYmd(value) });
                }}
              />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Is this a correction? (Late reason: H)">
              <CheckBox
                checked={payload?.isLate}
                onChange={(e) => {
                  setPayload({ ...payload, isLate: e.target.checked });
                }}
              ></CheckBox>
            </Label>
          </PanelRow>
          <PanelSpacer />
          <PanelRow>
            <p>
              A submission will be drafted for any payslips whose week number equals the the tax
              week number. The tax date is used to determine the tax week by matching it between the
              start and end payroll dates of the matching tax week.
            </p>
            <p>
              The draft will include null payslips (active contractors with no payslips in the
              current tax period). Both weekly and monthly payslips are accounted for if conditions
              are met. If a contractor has multiple payslips issued during the same tax period for
              the same employment, the payslip values will be summed up.
            </p>
            <p>You must select a company and a tax date.</p>
          </PanelRow>
          <PanelSpacer />
          {!isDrafting && (
            <PanelActions>
              <Button variant="secondary" onClick={() => onClose()}>
                Close
              </Button>
              <Button onClick={handleSubmit}>Submit</Button>
            </PanelActions>
          )}
          {isDrafting && <Label label="Drafting in progress..." />}
        </>
      )}
    </Panel>
  );
}
