import { Button } from "@backslashbuild/pp-ui";
import React from "react";
import { usePost } from "../contractors/api";
import { publish } from "../../pubSub";

export default function ResetPasswordButton({ contractorId }) {
  const sendEmail = usePost(`${contractorId}/send-reset-password-email`);
  const [submitting, setSubmitting] = React.useState(false);

  async function handleSubmit() {
    setSubmitting(true);
    try {
      const response = await sendEmail({ contractorId });

      if (response.isSuccess) {
        publish("notification", "Password reset instructions sent");
      } else {
        publish(
          "notification",
          <>
            <ul>
              {response.errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </>
        );
      }
    } catch (e) {
      console.error(e, "error sending email");
      publish("notification", "A network error occured.");
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Button onClick={() => handleSubmit()} submitting={submitting}>
      Reset password
    </Button>
  );
}
