import React from "react";
import { Dropdown, DropdownOption } from "@backslashbuild/pp-ui";
import { useGetCategories } from "./api";

function InvoiceCategoryPicker({ value, onChange, width = 100 }) {
  const optionsQuery = useGetCategories();
  const options = optionsQuery.data?.value ?? [];

  return (
    <Dropdown width={width} value={value} onChange={(e) => onChange(e.target.value)}>
      <DropdownOption value="">All</DropdownOption>
      {options.map((x) => (
        <DropdownOption key={x.id} value={x.id}>
          {x.displayName}
        </DropdownOption>
      ))}
    </Dropdown>
  );
}

export default InvoiceCategoryPicker;
