import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { utcToYmd, makeStateParamCorrectionForUrl } from "./utils";
import { useGetCompanies } from "./api";

import {
  Dropdown,
  DropdownOption,
  Panel,
  PanelRow,
  PanelSpacer,
  Label,
  PanelHeading,
  DatePicker,
  Button,
  PanelActions,
} from "@backslashbuild/pp-ui";

export default function FpsFilter() {
  const history = useHistory();
  const location = useLocation();
  const getStateFromSearch = (s) => queryString.parse(s);
  const [state, setState] = React.useState(getStateFromSearch(location.search));
  const { data, loading, error, queryId, refetch } = useGetCompanies();

  function handleSubmit() {
    history.replace({
      search: queryString.stringify(state),
    });
  }

  return (
    <Panel>
      <PanelHeading>Filters</PanelHeading>
      <PanelSpacer />
      <PanelRow>
        <Label label="Company"></Label>
        <Dropdown
          value={state?.companyId}
          onChange={(e) =>
            setState({ ...state, companyId: makeStateParamCorrectionForUrl(e.target.value) })
          }
        >
          <DropdownOption value={""}>{"Any"} </DropdownOption>
          {data?.value?.items?.map((company) => (
            <DropdownOption value={company.id}>{company.name} </DropdownOption>
          ))}
        </Dropdown>
      </PanelRow>
      <PanelRow>
        <Label label="Create Date From"></Label>
        <DatePicker
          value={state?.dateCreatedMin ?? null}
          onChange={(value) => {
            setState({ ...state, dateCreatedMin: utcToYmd(value) });
          }}
        />
      </PanelRow>
      <PanelRow>
        <Label label="Create Date To" long></Label>
        <DatePicker
          value={state?.dateCreatedMax ?? null}
          onChange={(value) => {
            setState({ ...state, dateCreatedMax: utcToYmd(value) });
          }}
        />
      </PanelRow>
      <PanelSpacer />
      <PanelSpacer />
      <PanelSpacer />
      <PanelActions>
        <Button onClick={handleSubmit}>Apply filters</Button>
      </PanelActions>
      <PanelSpacer large />
    </Panel>
  );
}
