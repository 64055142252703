import React from "react";
import { Content, PageHeadingArea, PageTitle } from "@backslashbuild/pp-ui";
import ContributionSchedulesTable from "../modules/nest/ContributionSchedulesTable";
import CreateContributionScheduleButton from "../modules/nest/CreateContributionScheduleButton";
import { PageHeadingAreaSpacer } from "@backslashbuild/pp-ui/dist/index.es";

export default function NESTContributionSchedules() {
  return (
    <Content>
      <PageHeadingArea>
        <PageTitle>NEST Contribution Schedules</PageTitle>
        <PageHeadingAreaSpacer grow />
        <CreateContributionScheduleButton />
      </PageHeadingArea>
      <ContributionSchedulesTable />
    </Content>
  );
}
