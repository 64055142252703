import React from "react";

import {
  Content,
  PanelColumnSet,
  PageTitle,
  PanelColumn,
  PageHeadingArea,
  PageHeadingAreaSpacer,
  Button,
  PanelRow,
  BoldText,
  PanelHeading,
  Panel,
  Link,
  PanelColumnSpacer,
} from "@backslashbuild/pp-ui";
import TaxYearsTable from "../modules/tax-calendar/TaxYearsTable";
import GenerateButton from "../modules/tax-calendar/GenerateButton";
import TaxWeeksTable from "../modules/tax-calendar/TaxWeeksTable";
import TaxMonthsTable from "../modules/tax-calendar/TaxMonthsTable";

export default function TaxCalendar() {
  return (
    <Content scrollable>
      <PageHeadingArea>
        <PageTitle>Tax Calendar</PageTitle>
      </PageHeadingArea>
      <PanelColumnSet>
        <PanelColumn width={560}>
          <Panel maxWidth={560}>
            <PanelHeading rightAction={<GenerateButton type="tax-years" />}>Tax Years</PanelHeading>
            <TaxYearsTable />
          </Panel>
        </PanelColumn>
        <PanelColumnSpacer />
        <PanelColumn width={620}>
          <Panel maxWidth={620}>
            <PanelHeading rightAction={<GenerateButton type="tax-months" />}>
              Tax Months
            </PanelHeading>
            <TaxMonthsTable />
          </Panel>
        </PanelColumn>
        <PanelColumnSpacer />
        <PanelColumn width={620}>
          <Panel maxWidth={620}>
            <PanelHeading rightAction={<GenerateButton type="tax-weeks" />}>Tax Weeks</PanelHeading>
            <TaxWeeksTable />
          </Panel>
        </PanelColumn>
      </PanelColumnSet>
    </Content>
  );
}
