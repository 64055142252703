import { Icons } from "@backslashbuild/pp-ui";
import React from "react";

export default function ViewCombinedErrorsButton({ onClick }) {
  return (
    <Icons.ErrorIcon
      onClick={() => {
        onClick();
      }}
    />
  );
}
