import React from "react";
import { Panel, PanelActions, PanelHeading, Button } from "@backslashbuild/pp-ui";
import ObjectMap from "../../components/ObjectMap";

export default function LegacySAGERowsDialog({ invoice, onClose }) {
  return (
    <>
      <Panel maxWidth={1000}>
        <PanelHeading>Legacy SAGE Exports</PanelHeading>
        <div style={{ maxHeight: 600, overflow: "scroll" }}>
          {invoice?.legacySAGEExports?.map((item, i) => (
            <>
              <ObjectMap data={item} />
              {invoice?.sageExports?.[i] && <ObjectMap data={invoice?.sageExports[i]} />}
            </>
          ))}
        </div>
        <PanelActions>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
        </PanelActions>
      </Panel>
    </>
  );
}
