import React from "react";
import ProcessButton from "./ProcessButton";
import ProcessDialog from "./ProcessDialog";

import { PanelDialog } from "@backslashbuild/pp-ui";

export default function ProcessNotifications() {
  const [showProcessDialog, setShowProcessDialog] = React.useState(false);

  return (
    <>
      <ProcessButton onClick={() => setShowProcessDialog(true)} />
      <PanelDialog
        open={showProcessDialog}
        onClose={() => {
          setShowProcessDialog(false);
        }}
      >
        <ProcessDialog
          onClose={() => {
            setShowProcessDialog(false);
          }}
        />
      </PanelDialog>
    </>
  );
}
