import React from "react";
import { Content, PageHeadingArea, PageTitle } from "@backslashbuild/pp-ui";
import { PanelSpacer } from "@backslashbuild/pp-ui/dist/index.es";
import SyncMessagesTable from "../modules/legacy-integration/SyncMessagesTable";

export default function LegacySyncMessages() {
  return (
    <Content>
      <SyncMessagesTable />
    </Content>
  );
}
