import React from "react";
import { useDownloadCSV } from "./api";
import {
  PanelDialog,
  PanelActions,
  Button,
  PanelRow,
  PanelHeading,
  Link,
} from "@backslashbuild/pp-ui";

export default function ExportDialog({ open, onClose, bacsRunId }) {
  function DownloadLink({ id }) {
    const download = useDownloadCSV(id);

    return (
      <Button
        onClick={(event) => {
          event.preventDefault();
          download();
        }}
      >
        Download
      </Button>
    );
  }

  return (
    <PanelDialog open={open} onClose={onClose}>
      <PanelHeading>Close BACS Run</PanelHeading>
      <PanelRow>
        <p>
          Click on 'Download' to export this BACS Run to CSV. You can export again as long as the
          BACS Run is not unlocked, paid or closed.
        </p>
      </PanelRow>
      <PanelActions>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <DownloadLink id={bacsRunId} />
      </PanelActions>
    </PanelDialog>
  );
}
