import React from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableHeaderColumn,
  PanelRow,
  Label,
  PanelSpacer,
  PanelHeading,
  TableCell,
} from "@backslashbuild/pp-ui";
import { formatCurrency } from "../../utils";

function PEOBandDetailsView({ band }) {
  return (
    <>
      <PanelHeading>PEO Band Info</PanelHeading>
      <PanelSpacer />
      <PanelRow>
        <Label label="Description">
          {band.description || "untitiled"} - ({band.agencyId ? "Agency specific" : "Default"})
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Is add holiday to supplied gross">
          {band.isAddHolidayToSuppliedGross ? "yes" : "-"}
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Is start from gross and work down">
          {band.isStartFromGrossAndWorkDown ? "yes" : "-"}
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Downshift gross by this first">
          {formatCurrency(band.downshiftGrossByThisFirst)}
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Is calculate employment costs plus fixed margin">
          {band.isCalculateEmploymentCostsPlusFixedMargin ? "yes" : "-"}
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Is individual invoices">{band.isIndividualInvoices ? "yes" : "-"}</Label>
      </PanelRow>
      <PanelRow>
        <Label label="Is PEO joint employment model">
          {band.isPEOJointEmploymentModel ? "yes" : "-"}
        </Label>
      </PanelRow>
      <PanelRow>
        <Label label="Is PEO VAT free model">{band.isPEOVATFreeModel ? "yes" : "-"}</Label>
      </PanelRow>
      <PanelRow>
        <Label label="Is PEO top band increases">{band.isPEOTopBandIncreases ? "yes" : "-"}</Label>
      </PanelRow>
      <PanelRow>
        <Label label="Rebate">{formatCurrency(band.rebate)}</Label>
      </PanelRow>

      <PanelSpacer large />

      <Table>
        <TableBody>
          <TableRow>
            <TableHeaderColumn></TableHeaderColumn>
            <TableHeaderColumn>From</TableHeaderColumn>
            <TableHeaderColumn>To</TableHeaderColumn>
            <TableHeaderColumn>%Rate</TableHeaderColumn>
            <TableHeaderColumn>Fixed</TableHeaderColumn>
          </TableRow>
          {[...Array(9).keys()].map((n) => (
            <TableRow key={n}>
              <TableCell></TableCell>
              <TableCell>{band[`band${n}`]}</TableCell>
              <TableCell>{band[`band${n}To`]}</TableCell>
              <TableCell>{band[`band${n}From`]}</TableCell>
              <TableCell>{band[`band${n}Fixed`]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <PanelSpacer large />
    </>
  );
}

export default PEOBandDetailsView;

// peoBandId: "9cb0a183-6303-4ef7-9915-de9c948c1c4a";
// agencyId: null;
// legacyProductId: 11;
// description: "";
// band1: 0.125;
// band1Fixed: 7;
// band1From: 0;
// band1To: 166;
// band2: 0.123;
// band2Fixed: 0;
// band2From: 166.01;
// band2To: 250;
// band3: 0.14;
// band3Fixed: 0;
// band3From: 250.01;
// band3To: 350;
// band4: 0.15;
// band4Fixed: 0;
// band4From: 350.01;
// band4To: 450;
// band5: 0.155;
// band5Fixed: 0;
// band5From: 450.01;
// band5To: 550;
// band6: 0.16;
// band6Fixed: 0;
// band6From: 550.01;
// band6To: 750;
// band7: 0.165;
// band7Fixed: 0;
// band7From: 750.01;
// band7To: 900;
// band8: 0.17;
// band8Fixed: 0;
// band8From: 900.01;
// band8To: 999999.99;
// band9: 0;
// band9Fixed: 0;
// band9From: 0;
// band9To: 0;
// isAddHolidayToSuppliedGross: false;
// isStartFromGrossAndWorkDown: false;
// downshiftGrossByThisFirst: 0;
// isCalculateEmploymentCostsPlusFixedMargin: false;
// isIndividualInvoices: false;
// isPEOJointEmploymentModel: true;
// isPEOVATFreeModel: false;
// isPEOTopBandIncreases: true;
// rebate: 0;
