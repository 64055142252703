import React from "react";

import { Content, PageHeadingArea, PageTitle, PageHeadingAreaSpacer } from "@backslashbuild/pp-ui";

import FPSsTable from "../modules/payroll/FPSsTable";
import AddFPSDialog from "../modules/payroll/AddFPSDialog";

function FPSs() {
  return (
    <Content>
      <PageHeadingArea>
        <PageTitle>RTI - Full Payment Submissions (FPS)</PageTitle>
        <PageHeadingAreaSpacer grow />
        <AddFPSDialog />
      </PageHeadingArea>
      <FPSsTable />
    </Content>
  );
}

export default FPSs;
