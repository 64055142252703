import React from "react";
import { useGetItems } from "../products/api";
import { Dropdown, DropdownOption } from "@backslashbuild/pp-ui/dist/index.es";
import { isTextNullOrEmpty } from "./utils";

function OverrideProductPicker({ value, onChange }) {
  const { data, loading, error } = useGetItems();

  if (loading || !data) return <div>Loading</div>;
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  return (
    <Dropdown value={value} onChange={onChange}>
      <DropdownOption value="">Choose:</DropdownOption>
      {data?.value?.items?.map((option) => (
        <DropdownOption value={option?.id}>{option.name}</DropdownOption>
      ))}
    </Dropdown>
  );
}

export default OverrideProductPicker;
