import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { utcToYmd, makeStateParamCorrectionForUrl } from "./utils";

import {
  Dropdown,
  DropdownOption,
  Panel,
  PanelRow,
  PanelSpacer,
  Label,
  PanelHeading,
  DatePicker,
  Button,
  PanelActions,
} from "@backslashbuild/pp-ui";

const formTypeOptions = [
  { displayName: "Any", value: "" },
  { displayName: "P6", value: "P6" },
  { displayName: "P6B", value: "P6B" },
  { displayName: "P9", value: "P9" },
  { displayName: "SL1", value: "SL1" },
  { displayName: "SL2", value: "SL2" },
  { displayName: "PGL1", value: "PGL1" },
  { displayName: "PGL2", value: "PGL2" },
  { displayName: "NVR", value: "NVR" },
  { displayName: "NOT", value: "NOT" },
  { displayName: "GEN", value: "GEN" },
  { displayName: "RTINOT", value: "RTINOT" },
];

export default function DpsFilter() {
  const history = useHistory();
  const location = useLocation();
  const getStateFromSearch = (s) => queryString.parse(s);
  const [state, setState] = React.useState(getStateFromSearch(location.search));

  function handleSubmit() {
    history.replace({
      search: queryString.stringify(state),
    });
  }

  return (
    <Panel>
      <PanelHeading>Filters</PanelHeading>
      <PanelSpacer />
      <PanelRow>
        <Label label="Form Type"></Label>
        <Dropdown
          value={state?.formType}
          onChange={(e) =>
            setState({ ...state, formType: makeStateParamCorrectionForUrl(e.target.value) })
          }
        >
          {formTypeOptions.map((option) => (
            <DropdownOption value={option.value}>{option.displayName} </DropdownOption>
          ))}
        </Dropdown>
      </PanelRow>
      <PanelRow>
        <Label label="Issue Date From"></Label>
        <DatePicker
          value={state?.issueDateFrom ?? null}
          onChange={(value) => {
            setState({ ...state, issueDateFrom: utcToYmd(value) });
          }}
        />
      </PanelRow>
      <PanelRow>
        <Label label="Issue Date To" long></Label>
        <DatePicker
          value={state?.issueDateTo ?? null}
          onChange={(value) => {
            setState({ ...state, issueDateTo: utcToYmd(value) });
          }}
        />
      </PanelRow>
      <PanelRow>
        <Label label="Create Date From"></Label>
        <DatePicker
          value={state?.dateCreatedFrom ?? null}
          onChange={(value) => {
            setState({ ...state, dateCreatedFrom: utcToYmd(value) });
          }}
        />
      </PanelRow>
      <PanelRow>
        <Label label="Create Date To" long></Label>
        <DatePicker
          value={state?.dateCreatedTo ?? null}
          onChange={(value) => {
            setState({ ...state, dateCreatedTo: utcToYmd(value) });
          }}
        />
      </PanelRow>
      <PanelSpacer />
      <PanelSpacer />
      <PanelSpacer />
      <PanelActions>
        <Button onClick={handleSubmit}>Apply filters</Button>
      </PanelActions>
      <PanelSpacer large />
    </Panel>
  );
}
