import React from "react";
import { Content, PageHeadingArea, BackArrow, PanelColumnSet } from "@backslashbuild/pp-ui";
import PayslipsTable from "../modules/payroll/PayslipsTable";
import { useParams, useHistory } from "react-router-dom";
import ContractorPageTitle from "../modules/contractors/ContractorPageTitle";

function ContractorPayslips() {
  const { id } = useParams();
  const history = useHistory();

  return (
    <Content>
      <PageHeadingArea>
        <BackArrow onClick={() => history.goBack()} />
        <ContractorPageTitle id={id} title="Payslips" />
      </PageHeadingArea>
      <PanelColumnSet>
        <PayslipsTable contractorId={id} />
      </PanelColumnSet>
    </Content>
  );
}

export default ContractorPayslips;
