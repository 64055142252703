import React from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useGetFpsEmployeeData } from "./api";
import { isTextNullOrEmpty, utcToLocalText } from "./utils";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";
import ObjectMap from "../../components/ObjectMap";
import GreenTick from "../../components/GreenTick";

import {
  TableRow,
  Table,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableFooter,
  PageLoading,
  Panel,
  PanelColumn,
  PanelHeading,
  PanelColumnSpacer,
} from "@backslashbuild/pp-ui";

function ParseQuery(search) {
  const parsedQueryString = queryString.parse(search);

  return `?${queryString.stringify({ ...parsedQueryString })}`;
}

const c = {
  dateCreated: "25%",
  accNo: "20%",
  weekNo: "15%",
  monthNo: "15%",
  taxYear: "15%",
  isValid: "10%",
};

const totalWidth = Object.keys(c).reduce((total, key) => (total += c[key]), 0);

function PureTable({ data, queryId, onItemClick }) {
  return React.useMemo(() =>
    data?.value?.items?.map(
      (submission) => (
        <TableRow onClick={() => onItemClick(submission)}>
          <TableCell width={c.accNo}>{submission?.accountNumber}</TableCell>
          <TableCell width={c.dateCreated}>
            {utcToLocalText(submission?.dateCreated, "dd/MM/yyyy HH:mm")}
          </TableCell>
          <TableCell width={c.weekNo}>{submission?.weekNo}</TableCell>
          <TableCell width={c.monthNo}>{submission?.monthNo}</TableCell>
          <TableCell width={c.taxYear}>{submission?.relatedTaxYear}</TableCell>
          <TableCell width={c.isValid}>{submission?.isValid && <GreenTick />}</TableCell>
        </TableRow>
      ),
      [queryId]
    )
  );
}

export default function FpsEmployeeTable() {
  const [selectedItem, setSelectedItem] = React.useState(null);
  const location = useLocation();
  const search = usePagination(location.search);
  const query = ParseQuery(search);
  const { data, loading, error, queryId, refetch } = useGetFpsEmployeeData(query);

  if (loading) return <PageLoading />;
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  return (
    <>
      <PanelColumn width={750}>
        {/* Temporary fix for Safari not rendering ui components correctly */}
        <div style={{ width: "100%", height: "77vh" }}>
          <Table standardTable width={totalWidth}>
            <TableHeader>
              <TableHeaderColumn width={c.accNo}>Account Number</TableHeaderColumn>
              <TableHeaderColumn width={c.dateCreated}>Date Created</TableHeaderColumn>
              <TableHeaderColumn width={c.weekNo}>Week No.</TableHeaderColumn>
              <TableHeaderColumn width={c.monthNo}>Month No.</TableHeaderColumn>
              <TableHeaderColumn width={c.taxYear}>Tax year</TableHeaderColumn>
              <TableHeaderColumn width={c.isValid}>Valid</TableHeaderColumn>
            </TableHeader>
            <TableBody fullPageTable={true}>
              <PureTable
                data={data}
                queryId={queryId}
                onItemClick={(item) => setSelectedItem(item)}
              />
            </TableBody>
            <TableFooter>
              <QueryPaginator
                itemsTotal={data?.value?.itemsTotal}
                itemsRetrieved={data?.value?.itemsRetrieved}
              />
            </TableFooter>
          </Table>
        </div>
      </PanelColumn>
      <PanelColumnSpacer />
      <PanelColumn width={750}>
        <Panel maxWidth={750}>
          <PanelHeading>Employee Data View</PanelHeading>
          <ObjectMap data={selectedItem} hideEmptyValues />
          {!Boolean(selectedItem) && "Select an item to view the data."}
        </Panel>
      </PanelColumn>
    </>
  );
}
