import React from "react";
import QueryFiltering from "../../containers/QueryFiltering";
import { PanelRow, Label, TextInput } from "@backslashbuild/pp-ui";
import { Radio, RadioGroup } from "@backslashbuild/pp-ui/dist/index.es";

function StatutoryPayGroupsFiltering() {
  return (
    <QueryFiltering
      searchStringKey="search"
      getTagsFromState={(state) => {
        const tags = [];
        if (state.type === "statutory_sick_pay") tags.push({ key: "Type", value: "Sick" });
        if (state.type === "statutory_maternity_pay")
          tags.push({ key: "Type", value: "Maternity" });
        if (state.type === "statutory_paternity_pay")
          tags.push({ key: "Type", value: "Paternity" });
        if (state.type === "statutory_adoption_pay") tags.push({ key: "Type", value: "Adoption" });
        return tags;
      }}
      getNextStateFromDeleteTag={(state, key) => {
        if (key === "Type") return { ...state, type: undefined };
        return state;
      }}
      renderForm={(state, setState) => (
        <>
          <PanelRow>
            <Label label="Search string">
              <TextInput
                value={state.search}
                onChange={(e) => setState({ ...state, search: e.target.value })}
              />
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Type">
              <RadioGroup
                value={state?.type}
                onChange={(e) => setState({ ...state, type: e.target.value })}
              >
                <Radio value="statutory_sick_pay" label="Sick" />
                <Radio value="statutory_maternity_pay" label="Maternity" />
                <Radio value="statutory_paternity_pay" label="Paternity" />
                <Radio value="statutory_adoption_pay" label="Adoption" />
              </RadioGroup>
            </Label>
          </PanelRow>
        </>
      )}
    />
  );
}

export default StatutoryPayGroupsFiltering;
