import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { publish } from "../pubSub";

import {
  Button,
  Content,
  PageHeadingArea,
  PageTitle,
  PanelColumnSet,
  PageHeadingAreaSpacer,
} from "@backslashbuild/pp-ui";

import SchedulesCoresTable from "../modules/schedules-core/ScheduleCoresTable";
import SchedulesFiltering from "../modules/schedules-core/ScheduleCoresFiltering";
import CreateScheduleDialog from "../modules/schedules-core/CreateScheduleDialog";

export default function Schedules() {
  return (
    <Content>
      <PageHeadingArea>
        <PageTitle>Schedules</PageTitle>
        <PageHeadingAreaSpacer grow />

        <SchedulesFiltering />
        <PageHeadingAreaSpacer />

        <CreateScheduleDialog />
      </PageHeadingArea>
      <SchedulesCoresTable />
    </Content>
  );
}
