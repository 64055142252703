import React from "react";
import {
  PanelDialog,
  PanelHeading,
  PanelActions,
  TableHeaderColumn,
  TableHeader,
  TableBody,
  TableRow,
  Table,
  Button,
  TableCell,
  Panel,
} from "@backslashbuild/pp-ui";
import { useGet } from "./api";
import UpdateEnrolmentStatusDialog from "./UpdateEnrolmentStatusDialog";
import MarkAllEnrolledDialog from "./MarkAllEnrolledDialog";

export default function SuccessfulEnrolmentsDialog({ enrolmentExport }) {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const { data, loading, error } = useGet(`enrolments/${enrolmentExport?.id}/successful`);
  const numberOfItems = data?.value?.length;

  if (error) {
    return <Button disabled>Unable to load</Button>;
  }

  if (!numberOfItems > 0) {
    return <Button small>No Successful Enrolments</Button>;
  }

  return (
    <>
      <Button small onClick={() => setDialogOpen(true)}>
        {numberOfItems > 1 ? `View ${numberOfItems} Enrolments` : `View ${numberOfItems} Enrolment`}
      </Button>

      <PanelDialog width={900} onClose={() => setDialogOpen(false)} open={dialogOpen}>
        <Panel maxWidth={900}>
          <PanelHeading>Enrolments without Errors</PanelHeading>
          <div style={{ height: 500, overflow: "scroll" }}>
            <Table width={900}>
              <TableHeader>
                <TableHeaderColumn width="20%">NI Number</TableHeaderColumn>
                <TableHeaderColumn width="20%">Name</TableHeaderColumn>
                <TableHeaderColumn width="60%">Actions</TableHeaderColumn>
              </TableHeader>
              <TableBody>
                {data?.value?.map((item) => (
                  <TableRow disableHover>
                    <TableCell width="20%">{item.niNumber}</TableCell>
                    <TableCell width="20%">{`${item.forename} ${item.surname}`}</TableCell>
                    <TableCell width="60%">
                      <UpdateEnrolmentStatusDialog accountNumber={item.accountNumber} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <PanelActions>
            <Button variant="secondary" onClick={() => setDialogOpen(false)}>
              Close
            </Button>
            <MarkAllEnrolledDialog enrolmentExport={enrolmentExport} />
          </PanelActions>
        </Panel>
      </PanelDialog>
    </>
  );
}
