import React from "react";
import { useGet, usePost, invalidateEnrolmentExports } from "./api";
import { publish } from "./pubSub";
import { Panel, PanelRow, PanelSpacer, PanelHeading } from "@backslashbuild/pp-ui";
import {
  BoldText,
  Button,
  CheckBox,
  Label,
  PanelActions,
  PanelDialog,
  TextInput,
} from "@backslashbuild/pp-ui/dist/index.es";

export default function NeedsEnrollingPanel() {
  const [dialogOpen, setDialogOpen] = React.useState(null);
  const [allNINumbersAvailable, setAllNINumbersAvailable] = React.useState(false);
  const [allWorkersUKResident, setAllWorkersUKResident] = React.useState(false);
  const [
    enrolmentInformationRecievedByWorkers,
    setEnrolmentInformationRecievedByWorkers,
  ] = React.useState(false);
  const [groupByProduct, setGroupByProduct] = React.useState(false);
  const [employerReferenceNumber, setEmployerReferenceNumber] = React.useState("");
  const { data } = useGet(`employments?needsEnrolling=True`);

  const create = usePost("enrolments");

  function handleSubmit() {
    create({
      employerReferenceNumber,
      allNINumbersAvailable,
      allWorkersUKResident,
      enrolmentInformationRecievedByWorkers,
      groupByProduct,
    }).then((r) => {
      if (r?.isSuccess) {
        publish("notification", "Created successfully");
        onClose();
        invalidateEnrolmentExports();
      } else {
        publish(
          "notification",
          <div>
            Failed to update
            <ul>
              {r?.errors?.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </div>
        );
      }
    });
  }

  function onClose() {
    setDialogOpen(false);
    setAllNINumbersAvailable(false);
    setEnrolmentInformationRecievedByWorkers(false);
    setAllWorkersUKResident(false);
    setGroupByProduct(false);
    setEmployerReferenceNumber("");
  }

  function handleRender() {
    if (data?.isSuccess) {
      return (
        <>
          {data?.value?.items?.length < 1 ? (
            <PanelRow>No employments need enrolling</PanelRow>
          ) : (
            <>
              {data?.value?.items?.map((employment) => (
                <PanelRow>
                  {employment?.employeeNumber} {employment?.forename} {employment?.surname}
                </PanelRow>
              ))}
              <PanelSpacer />
              <PanelActions>
                <Button onClick={() => setDialogOpen(true)}>Create New Enrolment Schedule</Button>
              </PanelActions>
            </>
          )}
        </>
      );
    }
  }

  const disabled =
    !allNINumbersAvailable ||
    !allWorkersUKResident ||
    !enrolmentInformationRecievedByWorkers ||
    employerReferenceNumber == "";

  return (
    <>
      <Panel>
        <PanelHeading>Needs Enrolling</PanelHeading>
        <PanelSpacer />
        {handleRender()}
      </Panel>
      <PanelDialog open={dialogOpen} onClose={onClose}>
        <PanelHeading>Create new Enrolment Schedule</PanelHeading>
        <PanelRow>
          <BoldText>
            This will create an enrolment schedule containing the following employments:
          </BoldText>
        </PanelRow>
        {data?.value?.items?.map((employment) => (
          <PanelRow>
            {employment?.employeeNumber} {employment?.forename} {employment?.surname}{" "}
            {employment?.niNumber}
          </PanelRow>
        ))}
        <PanelRow>
          <Label label="Employer Reference Number">
            <TextInput
              value={employerReferenceNumber}
              onChange={(e) => setEmployerReferenceNumber(e.target.value)}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label long label="Group by product (Umbrella: Weekly. All other products: PAYE)">
            <CheckBox
              checked={groupByProduct}
              onChange={() => setGroupByProduct(!groupByProduct)}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label long label="I confirm that the above individuals have the correct NI numbers">
            <CheckBox
              checked={allNINumbersAvailable}
              onChange={() => setAllNINumbersAvailable(!allNINumbersAvailable)}
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label
            long
            label="I confirm that the above individuals have been notified about NEST and enrolment"
          >
            <CheckBox
              checked={enrolmentInformationRecievedByWorkers}
              onChange={() =>
                setEnrolmentInformationRecievedByWorkers(!enrolmentInformationRecievedByWorkers)
              }
            />
          </Label>
        </PanelRow>
        <PanelRow>
          <Label long label="I confirm that the above individuals are resident in the UK">
            <CheckBox
              checked={allWorkersUKResident}
              onChange={() => setAllWorkersUKResident(!allWorkersUKResident)}
            />
          </Label>
        </PanelRow>
        <PanelActions>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={disabled} onClick={handleSubmit}>
            Create
          </Button>
        </PanelActions>
      </PanelDialog>
    </>
  );
}
