import React from "react";
import { useGet } from "./api";
import { isTextNullOrEmpty } from "./utils";
import { subscribe, unsubscribe } from "./pubSub";

export default function AgencyBranchName({ id }) {
  const { data, loading, error, refetch } = useGet("branch/" + id);

  React.useEffect(() => {
    subscribe("agency.agencyDetails.updated", refetch);
    return () => unsubscribe("agency.agencyDetails.updated", refetch);
  }, [id]);

  if (loading) return <>Loading...</>;

  console.log(data);
  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <>{String(error)}</>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <>{error}</>);
    }

    return <>{data?.value?.name}</>;
  }

  return <>{handleRender()}</>;
}
