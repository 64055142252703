import React from "react";
import { useHistory } from "react-router-dom";
import {
  Content,
  PageHeadingArea,
  SidebarWrapper,
  BackArrow,
  PageHeadingAreaSpacer,
  PageTitle,
} from "@backslashbuild/pp-ui";

import CompaniesTable from "../modules/hmrc/CompaniesTable";

export default function Companies() {
  const history = useHistory();

  return (
    <Content>
      <PageHeadingArea>
        <BackArrow onClick={() => history.goBack()} />
        <PageHeadingAreaSpacer />
        <PageTitle>Companies</PageTitle>
      </PageHeadingArea>
      <CompaniesTable />
    </Content>
  );
}
