import React from "react";
import {
  Button,
  PanelDialog,
  PanelHeading,
  PanelRow,
  PanelSpacer,
  PanelActions,
  BoldText,
} from "@backslashbuild/pp-ui";
import { usePost } from "./api";
import { publish } from "../../pubSub";
import { useHistory } from "react-router-dom";

function FinalisePayRunButton({ id }) {
  const history = useHistory();

  // you have to query to see if there are any currently staged pay runs and then disable the button.
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const cancelPayRun = usePost("payruns/finalise");

  async function handleSubmit() {
    try {
      const response = await cancelPayRun({ payRunId: id });

      if (response.isSuccess) {
        publish("notification", "Pay Run Finalised");
        publish("payrun.updated", id);
      } else {
        publish(
          "notification",
          <>
            Unable to finalise pay run
            <ul>
              {response.errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </>
        );
      }
    } catch (e) {
      console.error(e, "error finalising pay run");
      publish("notification", "A network error occured.");
    }
  }

  return (
    <>
      <Button onClick={() => setDialogOpen(true)}>Finalise pay run</Button>
      <PanelDialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <PanelHeading>Finalise Pay Run</PanelHeading>
        <PanelSpacer large />
        <PanelRow>
          <BoldText>
            Are you sure you want to finalise this pay run?
            <br />
            <br />
            All payslips will be finalised and the locked RFPs will be resolved.
            <br />
            <br />
            You will then be able to generate a BACS file from this pay run.
          </BoldText>
        </PanelRow>
        <PanelSpacer large />
        <PanelActions>
          <Button variant="secondary" onClick={() => setDialogOpen(false)}>
            No, Don't finalise
          </Button>
          <Button onClick={handleSubmit}>Yes, finalise pay run</Button>
        </PanelActions>
        <PanelSpacer large />
      </PanelDialog>
    </>
  );
}

export default FinalisePayRunButton;
