import React from "react";

import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useGetBACSRows } from "./api";
import { isTextNullOrEmpty } from "./utils";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";

import {
  TableRow,
  Table,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableFooter,
  PageLoading,
} from "@backslashbuild/pp-ui";

export default function BACSRowsTable() {
  const location = useLocation();
  const search = usePagination(location.search);
  const query = ParseQuery(search);
  const { data, loading, error, queryId, refetch } = useGetBACSRows(query);

  if (loading) return <PageLoading />;
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  return (
    <>
      <Table
        sticky
        stickyFooter={
          <TableFooter sticky>
            <QueryPaginator
              itemsTotal={data?.value?.itemsTotal}
              itemsRetrieved={data?.value?.itemsRetrieved}
            />
          </TableFooter>
        }
        footerHeight={TableFooter.height}
      >
        <TableHeader sticky>
          <TableHeaderColumn sticky>Surname</TableHeaderColumn>
          <TableHeaderColumn sticky>Forenames</TableHeaderColumn>
          <TableHeaderColumn sticky>Amount</TableHeaderColumn>
          <TableHeaderColumn sticky>Ref</TableHeaderColumn>
          <TableHeaderColumn sticky>Bank</TableHeaderColumn>
          <TableHeaderColumn sticky>Account Name</TableHeaderColumn>
          <TableHeaderColumn sticky>Account No.</TableHeaderColumn>
          <TableHeaderColumn sticky>Sort Code</TableHeaderColumn>
          <TableHeaderColumn sticky>BS Roll No.</TableHeaderColumn>
        </TableHeader>
        <TableBody sticky footerHeight={TableFooter.height}>
          <PureTable data={data} queryId={queryId} />
        </TableBody>
      </Table>
    </>
  );
}

function PureTable({ data, queryId }) {
  return React.useMemo(() =>
    data?.value?.items?.map(
      (bacsRow) => (
        <TableRow>
          <TableCell>{bacsRow.surname}</TableCell>
          <TableCell>{bacsRow.firstName}</TableCell>
          <TableCell>£{bacsRow.amount.toFixed(2)}</TableCell>
          <TableCell>{bacsRow.ref}</TableCell>
          <TableCell>{bacsRow.bankName}</TableCell>
          <TableCell>{bacsRow.accountName}</TableCell>
          <TableCell>{bacsRow.accountNumber}</TableCell>
          <TableCell>{bacsRow.sortCode}</TableCell>
          <TableCell>{bacsRow.rollNumber}</TableCell>
        </TableRow>
      ),
      [queryId]
    )
  );
}

function ParseQuery(search) {
  const parsedQueryString = queryString.parse(search);

  return `?${queryString.stringify({ ...parsedQueryString })}`;
}
