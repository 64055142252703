import React from "react";
import {
  Table,
  TableBody,
  TableCell as TableCellPP,
  TableHeader,
  TableHeaderColumn as TableHeaderColumnPP,
  TableRow,
  Button,
  Link,
  PanelDialog,
  TableFooter,
  makeStyles,
  PageLoading,
  StatusIndicator,
} from "@backslashbuild/pp-ui";
import { utcToLocalText, isTextNullOrEmpty } from "./utils";
import { usePost, useGetInvoices } from "./api";
import { useLocation } from "react-router-dom";
import { publish } from "../../pubSub";
import EditInvoiceForm from "./EditInvoiceForm";
import ClearInvoiceForm from "./ClearInvoiceForm";
import CreditInvoiceForm from "./CreditInvoiceForm";
import { FeatureFlag, useFlag } from "react-unleash-flags";
import QueryPaginator, { usePagination } from "../../containers/QueryPaginator";
import queryString from "query-string";
import QueryFilter from "../../containers/QueryFilter";
import clsx from "clsx";
import InvoicePaidCheckbox from "./InvoicePaidCheckbox";
import useDebounce, { formatCurrency } from "../../utils";
import { CheckBox } from "@backslashbuild/pp-ui/dist/index.es";
import LegacySAGERowsDialog from "./LegacySAGERowsDialog";
import { useIsFlagEnabled } from "../../featureFlags";
import getApiUrl from "../../getApiUrl";

const useStyles = makeStyles({
  marginLeft: {
    marginLeft: 8,
  },
  flex: {
    display: "flex",
  },
  redText: {
    color: "red",
  },
  greyText: {
    color: "grey",
  },
  contractor: {
    padding: 0,
    margin: 0,
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
  },
  action: {
    padding: 5,
    margin: 5,
  },
});

const TableCell = ({ children, width, alignRight, ...rest }) => (
  <TableCellPP mini narrow width={width} alignRight={alignRight} {...rest}>
    <small>{children}</small>
  </TableCellPP>
);

const TableHeaderColumn = ({ children, width, alignRight }) => (
  <TableHeaderColumnPP mini narrow sticky width={width} alignRight={alignRight}>
    <small>{children}</small>
  </TableHeaderColumnPP>
);

const formatContractorName = (invoice) => {
  if (!isTextNullOrEmpty(invoice?.forenames) && !isTextNullOrEmpty(invoice?.surname))
    return `${invoice?.forenames[0]}. ${invoice?.surname}`;
  if (!isTextNullOrEmpty(invoice?.contractorName)) return invoice?.contractorName;
  return "";
};

function PureTable({
  data,
  queryId,
  generatingInvoiceView,
  sendInvoice,
  sendingInvoice,
  viewInvoices,
  classes,
  onClick,
  setEditInvoice,
  setClearInvoice,
  setCreditInvoice,
  setViewSageExports,
  checked = [],
  onChange,
}) {
  return React.useMemo(
    () =>
      data.value.items.map((invoice) => {
        return (
          <TableRow
            key={invoice.invoiceNumber}
            backgroundColor={getBackgroundColor(invoice.invoiceCategoryId)}
            onClick={() => {
              if (viewInvoices) {
                onClick(invoice.invoiceNumber);
              }
            }}
          >
            <TableCell>
              <CheckBox
                checked={checked.map((x) => x.invoiceNumber).includes(invoice.invoiceNumber)}
                onChange={() => onChange(invoice)}
              />
            </TableCell>
            <TableCell>
              {invoice.weekNumber && <QueryFilter query="weekNumber" value={invoice.weekNumber} />}
            </TableCell>
            <TableCell>
              <span
                className={clsx(classes.flex, {
                  [classes.redText]: invoice?.isCreditNote,
                })}
              >
                {`${invoice.invoiceNumber}`}
                {invoice.isCreditNote && <b className={classes.marginLeft}>{`C`}</b>}
              </span>
            </TableCell>
            <TableCell alignRight>
              <span className={clsx({ [classes.greyText]: invoice.isCreditNote })}>
                {invoice.isCreditNote && "-"}
                {formatCurrency(invoice.totalInvoiceNet)}
              </span>
            </TableCell>
            <TableCell alignRight>
              <span className={clsx({ [classes.greyText]: invoice.isCreditNote })}>
                {invoice.isCreditNote && "-"}
                {formatCurrency(invoice.totalInvoiceVat)}
              </span>
            </TableCell>
            <TableCell alignRight>
              <span className={clsx({ [classes.greyText]: invoice.isCreditNote })}>
                {invoice.isCreditNote && "-"}
                {formatCurrency(invoice.totalInvoiceValue)}
              </span>
            </TableCell>
            <TableCell>
              <QueryFilter
                query="agency"
                value={invoice?.agencyNumber}
                display={`${invoice.client}`}
              />
            </TableCell>
            <TableCell>
              <span
                className={clsx(classes.contractor, { [classes.greyText]: invoice.isCreditNote })}
              >
                {formatContractorName(invoice)}
              </span>
            </TableCell>
            <TableCell>
              {invoice.scheduleId && invoice.scheduleId != "0" && (
                <Link
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(
                      `https://portal.peoplegroupservices.com/payroll/payroll-import.asp?impurn=${invoice.scheduleId}&step=5`,
                      "_blank"
                    );
                  }}
                >
                  <>{`Schedule: ${invoice.scheduleId}`}</>
                </Link>
              )}
            </TableCell>
            <TableCell>
              {invoice?.hasGroup && (
                <div>
                  <Link
                    onClick={(e) => {
                      e.stopPropagation();
                      if (viewInvoices) {
                        window.open(`/invoice-schedule-redirect/${invoice?.scheduleId}`, "_blank");
                      }
                    }}
                  >
                    Grouped
                  </Link>
                </div>
              )}
            </TableCell>
            <TableCell>
              {invoice.invoiceBatchNumber === "-1" ? (
                "Cancelled"
              ) : invoice.invoiceBatchNumber !== "0" ? (
                <QueryFilter query="invoiceBatchNumber" value={invoice.invoiceBatchNumber} />
              ) : (
                ""
              )}
            </TableCell>
            <TableCell>
              <span className={clsx({ [classes.greyText]: invoice.isCreditNote })}>
                {utcToLocalText(invoice.dateCreated)} {utcToLocalText(invoice.dateCreated, "HH:mm")}
              </span>
              <span className={clsx({ [classes.greyText]: invoice.isCreditNote })}>
                &nbsp;{invoice?.author?.initials}
              </span>
            </TableCell>
            <TableCell>
              <span className={clsx({ [classes.greyText]: invoice.isCreditNote })}>
                {invoice?.creditedByInvoiceNumber != "0" && invoice?.creditedByInvoiceNumber}
              </span>
              <span className={clsx({ [classes.greyText]: invoice.isCreditNote })}>
                {invoice?.originalCreditedInvoiceNumber != "0" &&
                  invoice?.originalCreditedInvoiceNumber}
              </span>
            </TableCell>
            <TableCell>
              <InvoicePaidCheckbox invoice={invoice} />
            </TableCell>
            <TableCell>
              <StatusIndicator value={invoice?.isFurlough} />
            </TableCell>
            <TableCell>
              <div className={classes.actions}>
                {!invoice.isCancelled && (
                  <FeatureFlag name="invoices-create-credit-note">
                    <Link
                      className={classes.action}
                      onClick={(e) => {
                        e.stopPropagation();
                        setCreditInvoice(invoice.invoiceNumber);
                      }}
                    >
                      Credit
                    </Link>
                  </FeatureFlag>
                )}
                <FeatureFlag name="invoices-view-invoice">
                  <Link
                    className={classes.action}
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(
                        `https://portal.peoplegroupservices.com/agency/covid-19/invoices/?ajax=%7Cprinting%7Cscheduleinvoice2~1~0~A002512~2046~~~~${invoice?.legacyInvoiceId}~CPI066669%20Invoice~1%7C`,
                        "_blank"
                      );
                    }}
                  >
                    View Legacy
                  </Link>
                </FeatureFlag>
                <FeatureFlag name="invoices-edit-invoice">
                  <Link
                    className={classes.action}
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditInvoice(invoice);
                    }}
                  >
                    Edit
                  </Link>
                </FeatureFlag>
                <FeatureFlag name="invoices-clear-invoice">
                  <Link
                    className={classes.action}
                    onClick={(e) => {
                      e.stopPropagation();
                      setClearInvoice(invoice);
                    }}
                  >
                    Clear
                  </Link>
                </FeatureFlag>
                <FeatureFlag name="invoices-send-invoice">
                  <Button
                    submitting={invoice.invoiceNumber === sendingInvoice}
                    onClick={() => sendInvoice(invoice.invoiceNumber)}
                    className={classes.action}
                  >
                    Send
                  </Button>
                </FeatureFlag>
                <FeatureFlag name="invoices-view-sage">
                  {invoice.legacySAGEExports && invoice.legacySAGEExports != [] && (
                    <Link
                      onClick={(e) => {
                        e.stopPropagation();
                        setViewSageExports(invoice);
                      }}
                      className={classes.action}
                    >
                      Legacy SAGE
                    </Link>
                  )}
                </FeatureFlag>
              </div>
            </TableCell>
          </TableRow>
        );
      }),
    [queryId, generatingInvoiceView, viewInvoices, checked, onClick]
  );
}

const getBackgroundColor = (invoiceCategoryId) => {
  switch (invoiceCategoryId) {
    case "PEO":
      return "#D8FFF5";
    case "CIS":
      return "#E2D8E7";
    case "PsPro":
      return "#EEF4E5";
    case "Umbrella":
    case "None":
    default:
      return "";
  }
};

export default function InvoicesTable({ selectedInvoices, setSelectedInvoices, currentTaxWeek }) {
  const [sendingInvoice] = React.useState(null);
  const [editInvoice, setEditInvoice] = React.useState(null);
  const [clearInvoice, setClearInvoice] = React.useState(null);
  const [creditInvoice, setCreditInvoice] = React.useState(null);
  const [viewSageExports, setViewSageExports] = React.useState(null);

  const signRequest = usePost();
  const location = useLocation();
  const search = usePagination(location.search, 250);

  const parsedQueryString = queryString.parse(search);

  const query = `?${queryString.stringify({
    ...parsedQueryString,
    weekNumber: parsedQueryString?.weekNumber ?? currentTaxWeek,
  })}`;

  const debouncedQuery = useDebounce(query, 200);
  const isIndexSearch = useIsFlagEnabled("invoices-index-search");
  const debouncedQueryWithOldPath = isIndexSearch ? debouncedQuery : `old${debouncedQuery}`;

  const { data, loading, error, queryId, refetch } = useGetInvoices(debouncedQueryWithOldPath);

  async function sendInvoice() {
    publish("notification", "Invoice sent!");
    publish("notification", "(No invoice was actually sent, debug message)");
  }

  const viewInvoicesFF = useFlag("invoices-view-invoice");
  const classes = useStyles();

  if (loading) return <PageLoading />;
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;
  if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  const invoices = data?.value?.items ?? [];

  function handleCheckAll(isSelected) {
    if (isSelected) {
      setSelectedInvoices([]);
    } else {
      setSelectedInvoices(invoices);
    }
  }

  function addOrRemoveInvoice(invoice) {
    if (selectedInvoices.map((x) => x.invoiceNumber).includes(invoice.invoiceNumber)) {
      setSelectedInvoices(
        selectedInvoices?.filter((e) => e.invoiceNumber !== invoice.invoiceNumber)
      );
    } else {
      setSelectedInvoices(selectedInvoices?.concat([invoice]));
    }
  }

  const apiUrl = getApiUrl();

  async function handleClickRow(invoiceNumber) {
    const blank = window.open("", "_blank");

    signRequest(`documents/sign-request/${invoiceNumber}`).then((result) => {
      if (result?.isSuccess) {
        var { token, fileName } = result.value;

        blank.location = `${apiUrl}/invoicing/documents/${invoiceNumber}/view/${fileName}?token=${token}`;
      } else {
        let error = `Error - could not open invoice.`;
        if (typeof result?.errors == "string") error = result.error;
        else if (Array.isArray(result?.errors)) error = result.errors;
        publish("notification", error);
      }
    });
  }

  return (
    <>
      <Table
        sticky
        stickyFooter={
          <TableFooter sticky>
            <QueryPaginator
              defaultTake={250}
              itemsTotal={data?.value?.itemsTotal}
              itemsRetrieved={data?.value?.itemsRetrieved}
            />
          </TableFooter>
        }
        footerHeight={TableFooter.height}
      >
        <TableHeader sticky>
          <TableHeaderColumn sticky>
            <CheckBox
              indeterminate={
                selectedInvoices?.length > 0 && selectedInvoices?.length != invoices?.length
              }
              checked={selectedInvoices?.length > 0}
              onChange={() => handleCheckAll(selectedInvoices?.length > 0)}
            />
          </TableHeaderColumn>
          <TableHeaderColumn sticky>Week</TableHeaderColumn>
          <TableHeaderColumn sticky>Invoice No.</TableHeaderColumn>
          <TableHeaderColumn alignRight sticky>
            Net
          </TableHeaderColumn>
          <TableHeaderColumn alignRight sticky>
            VAT
          </TableHeaderColumn>
          <TableHeaderColumn alignRight sticky>
            Total
          </TableHeaderColumn>
          <TableHeaderColumn sticky>Agency</TableHeaderColumn>
          <TableHeaderColumn sticky>Contractor</TableHeaderColumn>
          <TableHeaderColumn sticky>Schedule</TableHeaderColumn>
          <TableHeaderColumn sticky></TableHeaderColumn>
          <TableHeaderColumn sticky>Batch</TableHeaderColumn>
          <TableHeaderColumn sticky>Created</TableHeaderColumn>
          <TableHeaderColumn sticky>Related item</TableHeaderColumn>
          <TableHeaderColumn sticky>Paid</TableHeaderColumn>
          <TableHeaderColumn sticky>Furlough</TableHeaderColumn>
          <TableHeaderColumn sticky>Actions</TableHeaderColumn>
        </TableHeader>
        <TableBody sticky footerHeight={TableFooter.height}>
          <PureTable
            data={data}
            queryId={queryId}
            sendInvoice={() => sendInvoice()}
            sendingInvoice={sendingInvoice}
            viewInvoices={viewInvoicesFF}
            classes={classes}
            onClick={handleClickRow}
            setEditInvoice={setEditInvoice}
            setClearInvoice={setClearInvoice}
            setCreditInvoice={setCreditInvoice}
            setViewSageExports={setViewSageExports}
            onChange={addOrRemoveInvoice}
            checked={selectedInvoices}
          />
        </TableBody>
      </Table>
      <PanelDialog open={!!editInvoice} width={1000}>
        {editInvoice && (
          <EditInvoiceForm
            invoice={editInvoice}
            onClose={() => {
              setEditInvoice(null);
              refetch();
            }}
          />
        )}
      </PanelDialog>
      <PanelDialog open={!!clearInvoice}>
        {clearInvoice && (
          <ClearInvoiceForm
            invoice={clearInvoice}
            onClose={() => {
              setClearInvoice(null);
              refetch();
            }}
          />
        )}
      </PanelDialog>
      <PanelDialog open={!!creditInvoice}>
        {creditInvoice && (
          <CreditInvoiceForm
            invoice={creditInvoice}
            onClose={() => {
              setCreditInvoice(null);
              refetch();
            }}
          />
        )}
      </PanelDialog>
      <PanelDialog open={!!viewSageExports} width={1000}>
        <LegacySAGERowsDialog invoice={viewSageExports} onClose={() => setViewSageExports(null)} />
      </PanelDialog>
    </>
  );
}
