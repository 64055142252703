import React from "react";
import { useGet } from "./api";
import { isTextNullOrEmpty } from "./utils";
import ContractorProductPageTitle from "../contractors/ContractorProductPageTitle";

export default function StatutoryPayGroupPageTitle({ statutoryPayGroupId, title }) {
  const { data, loading, error } = useGet(`statutory-pay-groups/${statutoryPayGroupId}`);

  if (loading) return <div>Loading...</div>;

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    return (
      <>
        <ContractorProductPageTitle
          contractorProductId={data?.value?.employmentId}
          title={`Statutory Pay: ${data?.value?.type?.name}`}
        />
      </>
    );
  }

  return <>{handleRender()}</>;
}
