import React from "react";

import { Content } from "@backslashbuild/pp-ui";

import AgenciesTable from "../modules/agencies/AgenciesTable";

function Agencies() {
  return (
    <Content>
      <AgenciesTable />
    </Content>
  );
}

export default Agencies;
