import React from "react";

import BACSRunHeading from "../modules/bacs/BACSRunHeading";
import BACSRowsFilter from "../modules/bacs/BACSRowsFilter";
import BACSRowsTable from "../modules/bacs/BACSRowsTable";
import BACSRunPanel from "../modules/bacs/BACSRunPanel";

import {
  Content,
  PanelColumnSet,
  PanelColumn,
  PanelColumnSpacer,
  SidebarWrapper,
  PanelSpacer,
} from "@backslashbuild/pp-ui";

export default function BACSRun() {
  return (
    <SidebarWrapper>
      <Content scrollable>
        <BACSRunHeading />
        <PanelColumnSet>
          <PanelColumn width={320}>
            <BACSRunPanel />
            <PanelSpacer />
            <PanelSpacer />
            <BACSRowsFilter />
          </PanelColumn>
          <PanelColumnSpacer />
          <BACSRowsTable />
        </PanelColumnSet>
      </Content>
    </SidebarWrapper>
  );
}
