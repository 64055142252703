import { DateTime, Settings } from "luxon";

Settings.defaultZoneName = "Europe/London";
Settings.defaultLocale = "en-GB";

export function currentDateUtc() {
  return DateTime.utc;
}

export function utcToLocalText(date, format) {
  if (date === undefined || isTextNullOrEmpty(date)) return "";
  if (isTextNullOrEmpty(format)) format = "dd/MM/yyyy";

  return DateTime.fromISO(date, { zone: "utc" }).toLocal().toFormat(format);
}

export function utcToLocal(date) {
  if (date === undefined) return undefined;

  return DateTime.fromISO(date).toLocal();
}

export function localToUtcIso(date, format) {
  if (date === undefined) return undefined;
  if (isTextNullOrEmpty(format)) format = "dd/MM/yyyy";

  return DateTime.fromFormat(date, format).toUTC().toISO();
}

export function isTextNullOrEmpty(text) {
  return !Boolean(text);
}

export function textToBoolean(text) {
  let result = null;

  if (!isTextNullOrEmpty(text) && typeof text == "string") {
    result = text?.toLowerCase() == "true";
  }

  return result;
}

export function buildQuery(dict) {
  let beginQuery = true;
  let query = "";

  if (dict != null) {
    Object.keys(dict).map((key) => {
      if (!isTextNullOrEmpty(dict[key])) {
        if (beginQuery) {
          query += "?";
          beginQuery = false;
        } else query += "&";

        query += `${key}=${dict[key]}`;
      }
    });

    return query;
  }
}

export function formatCurrency(numeric) {
  if (numeric === 0) return "£0.00";
  var value = Number(numeric);
  return value && !isNaN(value)
    ? "£" + value.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    : "";
}
