import {
  FormTitle,
  PageLoading,
  Panel,
  PanelHeading,
  PanelSpacer,
  CheckBox,
  PageHeadingAreaSpacer,
} from "@backslashbuild/pp-ui";
import React from "react";
import { useGetFields, useUpdateField } from "./api";
import FormField from "./FormField";
import { isTextNullOrEmpty } from "./utils";

export default function CompareUmbrellaForm({ id }) {
  const [show, setShow] = React.useState(false);
  const [query, setQuery] = React.useState("");
  const { data, loading, error } = useGetFields({
    id,
    formName: "compare-umbrella",
    queryParams: query,
  });
  const updateField = useUpdateField({ id, formName: "" });

  if (loading) return <PageLoading />;

  function getOnSaveHandler(key) {
    return (value) => {
      updateField({ key, value });
    };
  }

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    return data.value.map((field) => (
      <FormField key={field.key} field={field} onSave={getOnSaveHandler(field.key)} />
    ));
  }

  return (
    <Panel>
      <PanelHeading onClick={() => setShow(!show)}>
        <span style={{ flexGrow: 1 }}>Compare against another umbrella</span>{" "}
        <CheckBox checked={show} onChange={(e) => setShow(e.target.checked)} />
      </PanelHeading>
      {show && (
        <>
          <PanelSpacer />
          {handleRender()}
          <PanelSpacer />
        </>
      )}
    </Panel>
  );
}
