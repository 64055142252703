import React from "react";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { useQuery, queryCache } from "react-query";
import getApiUrl from "../../getApiUrl";

const apiHost = getApiUrl();
const service = "payslip-reporting";

export function useGet(cacheKey, path, serviceOverride) {
  if (!path) {
    path = cacheKey;
  }
  if (!serviceOverride) {
    serviceOverride = service;
  }

  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };

  const { data, error, isLoading: loading, updatedAt: queryId } = useQuery(cacheKey, async () => {
    return fetch(`${apiHost}/${serviceOverride}/${path}`, {
      headers: {
        ...authHeaders,
      },
    }).then((r) => r.json());
  });

  const runQuery = () => queryCache.invalidateQueries(path);

  React.useEffect(() => {
    runQuery();
  }, [path]);

  return { data, loading, error, queryId, refetch: runQuery };
}

export function useGetItems(path = "") {
  return useGet(`${path}`);
}

export function useGetBlob(path, filename) {
  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };

  return React.useCallback(() => {
    return fetch(`${apiHost}/${service}/${path}`, {
      method: "GET",
      headers: {
        ...authHeaders,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
  }, [path, filename]);
}

export function usePost() {
  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };

  return React.useCallback(
    (path, body) => {
      return fetch(`${apiHost}/${service}/${path}`, {
        method: "POST",
        headers: {
          ...authHeaders,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }).then((x) => {
        return x.json();
      });
    },
    [authHeaders.Authorization]
  );
}
