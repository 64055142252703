import React from "react";
import { Dropdown, DropdownOption } from "@backslashbuild/pp-ui";
import { useGet } from "./api";

export default function SMSTemplatePicker({ value, onChange }) {
  const { data, loading, error } = useGet(`sms-templates`);
  const options = data?.value?.items ?? [];

  return (
    <Dropdown value={value} onChange={onChange}>
      <DropdownOption value="">Choose:</DropdownOption>
      {options?.map((x) => (
        <DropdownOption value={x.id}>{x.name}</DropdownOption>
      ))}
    </Dropdown>
  );
}
