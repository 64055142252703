import React from "react";
import { Button } from "@backslashbuild/pp-ui";
import { usePost } from "./api";
import { publish } from "../../pubSub";

function ReProcessPayslipButton({ id }) {
  const process = usePost("payslips/process");

  async function handleSubmit() {
    const result = await process({ payslipId: id });

    if (result?.isSuccess) {
      publish("notification", "Completed");
      publish("payslip.updated", id);
    } else {
      publish(
        "notification",
        <div>
          Failed:
          <ul>
            {result.errors.map((error) => (
              <li>{error}</li>
            ))}
          </ul>
        </div>
      );
    }
  }

  return (
    <Button large onClick={handleSubmit}>
      Re process
    </Button>
  );
}

export default ReProcessPayslipButton;
