import React from "react";
import { PageLoading } from "@backslashbuild/pp-ui";
import { useParams } from "react-router-dom";
import useRedirectToInvoiceSchedule from "../modules/invoicing/useRedirectToInvoiceSchedule";

function RedirectToInvoiceSchedule() {
  const { scheduleId } = useParams();
  const open = useRedirectToInvoiceSchedule(scheduleId);

  React.useEffect(() => {
    open();
  }, []);

  return <PageLoading />;
}

export default RedirectToInvoiceSchedule;
