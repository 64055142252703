import React from "react";
import { makeStyles } from "@backslashbuild/pp-ui";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    padding: 0,
    margin: 0,
    height: 38,
    position: "relative",
  },
}));

export default function CustomTableCell({
  children,
  width,
  backgroundColour,
  white,
  onClick,
  borderBottom,
  fontFamily,
  fontSize,
}) {
  const classes = useStyles();

  return (
    <td
      onClick={() => onClick && onClick()}
      className={classes.tableCell}
      style={width ? { maxWidth: width, width } : {}}
    >
      <div
        style={{
          backgroundColor: backgroundColour ?? "#FFFFFF",
          width: width ?? "100%",
          height: "100%",
          padding: "4px 16px",
          color: white ? "#FFFFFF" : "#000000",
          fontSize: 18,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          cursor: "pointer",
          borderBottom,
          fontFamily,
          fontSize,
        }}
      >
        {children}
      </div>
    </td>
  );
}
