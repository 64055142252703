import React from "react";
import {
  PageLoading,
  PanelDialog,
  PanelHeading,
  PanelActions,
  PanelRow,
  Button,
  Label,
  QueryDropdown,
  TextInput,
} from "@backslashbuild/pp-ui";
import { useUploadCsv } from "./api";
import { publish } from "../../pubSub";
import { useHistory } from "react-router-dom";

export default function UploadCsvDialog({ id }) {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const history = useHistory();

  const upload = useUploadCsv(id);

  function handleCancel() {
    // setFile(null);
    setDialogOpen(false);
  }

  async function handleSubmit() {
    publish("notification", "Uploading file...");

    const result = await upload(file);
    publish("notification", "Upload complete");

    const id = result.value;

    history.push(`/schedule-c-import/${id}`);
    handleCancel();
  }

  return (
    <>
      <Button onClick={() => setDialogOpen(true)}>Upload File</Button>
      <PanelDialog onClose={handleCancel} open={dialogOpen}>
        <PanelHeading>Upload new CSV</PanelHeading>
        <PanelRow>
          <Label label="Choose file">
            <TextInput type="file" onChange={(e) => setFile(e.target.files[0])} />
          </Label>
        </PanelRow>
        <PanelActions>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="primary" disabled={!file} onClick={handleSubmit}>
            Submit
          </Button>
        </PanelActions>
      </PanelDialog>
    </>
  );
}
