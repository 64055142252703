import { useReactOidc } from "@axa-fr/react-oidc-context";

import permissionsSettings from "./permissions.json";

export function useCheckUserPermission(actionType) {
  const { oidcUser } = useReactOidc();

  if (oidcUser && oidcUser.profile && oidcUser.profile.role) {
    let roleArray = oidcUser.profile.role.split(",");
    for (let i = 0; i < roleArray.length; i++) {
      if (
        permissionsSettings[actionType] &&
        permissionsSettings[actionType].indexOf(roleArray[i]) !== -1
      ) {
        return true;
      }
    }
  }
  return false;
}
