import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { utcToYmd, makeStateParamCorrectionForUrl } from "./utils";
import { useGetCompanies } from "./api";

import {
  Dropdown,
  DropdownOption,
  Panel,
  PanelRow,
  PanelSpacer,
  Label,
  PanelHeading,
  DatePicker,
  Button,
  PanelActions,
  TextInput,
  Radio,
  RadioGroup,
} from "@backslashbuild/pp-ui";

export default function FpsEmployeesFilter() {
  const history = useHistory();
  const location = useLocation();
  const getStateFromSearch = (s) => queryString.parse(s);
  const [state, setState] = React.useState(getStateFromSearch(location.search));
  const { data, loading, error, queryId, refetch } = useGetCompanies();

  function handleSubmit() {
    history.replace({
      search: queryString.stringify(state),
    });
  }

  return (
    <Panel>
      <PanelHeading>Filters</PanelHeading>
      <PanelSpacer />
      <PanelRow>
        <Label label="Company"></Label>
        <Dropdown
          value={state?.companyId}
          onChange={(e) =>
            setState({ ...state, companyId: makeStateParamCorrectionForUrl(e.target.value) })
          }
        >
          <DropdownOption value={""}>{"Any"} </DropdownOption>
          {data?.value?.items?.map((company) => (
            <DropdownOption value={company.id}>{company.name} </DropdownOption>
          ))}
        </Dropdown>
      </PanelRow>
      <PanelRow>
        <Label label="Request ID"></Label>
        <TextInput
          value={state?.requestId}
          placeholder="Use the GUID format"
          onChange={(e) =>
            setState({ ...state, requestId: makeStateParamCorrectionForUrl(e.target.value) })
          }
        />
      </PanelRow>
      <PanelRow>
        <Label label="Account Number"></Label>
        <TextInput
          value={state?.peopleId}
          placeholder="Example: CPL123456"
          onChange={(e) =>
            setState({ ...state, peopleId: makeStateParamCorrectionForUrl(e.target.value) })
          }
        />
      </PanelRow>
      <PanelRow>
        <Label label="Week Number"></Label>
        <TextInput
          value={state?.weekNo}
          placeholder="Values can be 1 to 53"
          onChange={(e) =>
            setState({ ...state, weekNo: makeStateParamCorrectionForUrl(e.target.value) })
          }
        />
      </PanelRow>
      <PanelRow>
        <Label label="Month Number"></Label>
        <TextInput
          value={state?.monthNo}
          placeholder="Values can be 1 to 12"
          onChange={(e) =>
            setState({ ...state, monthNo: makeStateParamCorrectionForUrl(e.target.value) })
          }
        />
      </PanelRow>
      <PanelRow>
        <Label label="Related Tax Year"></Label>
        <TextInput
          value={state?.relatedTaxYear}
          placeholder="Example: 20-21"
          onChange={(e) =>
            setState({ ...state, relatedTaxYear: makeStateParamCorrectionForUrl(e.target.value) })
          }
        />
      </PanelRow>
      <PanelRow>
        <Label label="Valid Submissions"></Label>
        <RadioGroup
          value={state?.isValid}
          onChange={(e) =>
            setState({ ...state, isValid: makeStateParamCorrectionForUrl(e.target.value) })
          }
        >
          <Radio value={"true"} label="Yes" />
          <Radio value={"false"} label="No" />
          <Radio value={""} label="Any" />
        </RadioGroup>
      </PanelRow>
      <PanelRow>
        <Label label="Create Date From"></Label>
        <DatePicker
          value={state?.dateCreatedFrom ?? null}
          onChange={(value) => {
            setState({ ...state, dateCreatedMin: utcToYmd(value) });
          }}
        />
      </PanelRow>
      <PanelRow>
        <Label label="Create Date To" long></Label>
        <DatePicker
          value={state?.dateCreatedTo ?? null}
          onChange={(value) => {
            setState({ ...state, dateCreatedMax: utcToYmd(value) });
          }}
        />
      </PanelRow>
      <PanelSpacer />
      <PanelSpacer />
      <PanelSpacer />
      <PanelActions>
        <Button onClick={handleSubmit}>Apply filters</Button>
      </PanelActions>
      <PanelSpacer large />
    </Panel>
  );
}
