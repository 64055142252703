import React from "react";

import { Content } from "@backslashbuild/pp-ui";

import P45Table from "../modules/contractors/P45Table";

function P45Management() {
  return (
    <Content>
      <P45Table />
    </Content>
  );
}

export default P45Management;
