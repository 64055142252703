import React from "react";
import { useGet } from "./api";
import { isTextNullOrEmpty } from "./utils";
import ContractorPageTitle from "./ContractorPageTitle";
import { Rollover } from "@backslashbuild/pp-ui";
import ContractorBusinessCard from "./ContractorBusinessCard";

export default function ContractorProductPageTitle({ contractorProductId, title }) {
  const { data, loading, error } = useGet(`contractor-products/${contractorProductId}`);

  if (loading) return <div>Loading...</div>;

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data?.isSuccess) {
      return data?.errors?.map((error) => <div>{error}</div>);
    }

    return (
      <Rollover
        renderAnchor={() => (
          <div>
            <ContractorPageTitle
              id={data?.value?.contractorUserId}
              title={title ? `${data?.value?.accountNumber} ${title}` : data?.value?.accountNumber}
            />
          </div>
        )}
      >
        <ContractorBusinessCard id={data?.value?.contractorUserId} />
      </Rollover>
    );
  }

  return <>{handleRender()}</>;
}
