import { useState } from "react";
import {
  Panel,
  PageLoading,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from "@backslashbuild/pp-ui";
import React from "react";

export default function PaidContractorsTable({ id }) {
  // const { data, loading, error } = useGet(`branches/${agencyId}`);
  // const [editBranch, setEditBranch] = useState(null);
  // const [createNewBranch, setCreateNewBranch] = useState(false);

  // if (loading) return <PageLoading />;

  // if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

  // if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  let dummyData = [0, 0, 0, 0, 0];
  return (
    <>
      <Table standardTable height={600} width={1800}>
        <TableHeader>
          <TableHeaderColumn width="100%">Paid Contractors</TableHeaderColumn>
        </TableHeader>
        <TableHeader>
          <TableHeaderColumn width="100px">Week</TableHeaderColumn>
          <TableHeaderColumn width="100px">Total</TableHeaderColumn>
          <TableHeaderColumn width="100px">Product</TableHeaderColumn>
          <TableHeaderColumn width="100px">Product</TableHeaderColumn>
          <TableHeaderColumn width="100px">Product</TableHeaderColumn>
          <TableHeaderColumn width="100px">Product</TableHeaderColumn>
        </TableHeader>
        <TableBody fullPageTable={false} footerHeight={0}>
          {dummyData.map((branch) => (
            <TableRow disableHover>
              <TableCell width="100px">TO-SYNC</TableCell>
              <TableCell width="100px">TO-SYNC</TableCell>
              <TableCell width="100px">TO-SYNC</TableCell>
              <TableCell width="100px">TO-SYNC</TableCell>
              <TableCell width="100px">TO-SYNC</TableCell>
              <TableCell width="100px">TO-SYNC</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
