import React from "react";

import { Content } from "@backslashbuild/pp-ui";

import ContractTemplatesTable from "../modules/contracts/ContractTemplatesTable";

function ContractManagement() {
  return (
    <Content>
      <ContractTemplatesTable />
    </Content>
  );
}

export default ContractManagement;
